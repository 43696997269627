import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback } from '../grpc'
import { toDataRequest } from '../grpc/converters'
import { guid } from '../lib/guid'
import { enrichOpportunity } from '../reducers/common'

const DealsContext = React.createContext()

export function DealsProvider({ skip = 0, take = 20, sort, id, children }) {
  const [key, setKey] = useState(guid())
  const [error, setError] = useState(undefined)
  const [deals, setDeals] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const apiParams = useMemo(() => {
    return {
      headers: {
        skip,
        take,
        ...sort && { sort }
      },
      id
    }
  }, [skip, take, sort, id])

  const getOpportunitiesByTeam = useGrpcCallback({
    headers: apiParams.headers,
    onSuccess: (obj) => {
      const { opportunitiesList = [] } = obj
      const deals = opportunitiesList.map((opp) => enrichOpportunity(opp))
      setDeals(deals)
      setError(undefined)
      setIsFetching(false)
    },
    onError: (err) => {
      setError({ error: err })
      setIsFetching(false)
    },
    onFetch: () => {
      setError(undefined)
      setIsFetching(true)
    },
    grpcMethod: 'getOpportunitiesByTeam',
    debug: false,
  }, [apiParams])

  useEffect(() => {
    const request = toDataRequest({
      ...apiParams.id && { id: apiParams.id }
    })
    getOpportunitiesByTeam(request)
  }, [key, getOpportunitiesByTeam])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      error,
      deals,
      invalidate,
    }
  }, [isFetching, error, deals])

  return <DealsContext.Provider value={contextValue}>{children}</DealsContext.Provider>
}

export function useDeals() {
  const context = React.useContext(DealsContext)
  if (context === undefined) {
    throw new Error('useDeals must be used within a DealsProvider')
  }
  return context
}
