import React from 'react'

const Delete = (props) => {
  const {
    fill = '#000000',
    transform,
  } = props

  return (
    <svg height="24px" viewBox="0 0 24 24" width="24px" fill={fill} transform={transform}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
    </svg>
  )
}

export default Delete
