import { createReducer } from '@reduxjs/toolkit'
import { setGrpcPrefix } from '../actions'

const initialState = {}

export const debug = createReducer(initialState, {
  [setGrpcPrefix.type]: (state, action) => {
    return {
      ...state,
      grpcPrefix: action.payload
    }
  },
})
