import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSignals } from '../../actions'
import { getSignalsByOpportunity } from '../../services/signalService'
import classNames from 'classnames'
import { Close } from '@getoutreach/react-icons'
import { uniqBy } from 'lodash'
import { useParams } from 'react-router-dom'
import { useDebug } from '../../context/debug'
import GenericModal from '../modal/genericModal'
import GMLRenderer, { controlTypes } from '../../gml/renderer/gmlRenderer'
import { useTreeHasType } from '../../gml/hooks/useTreeHasType'
import ErrorBoundary from '../../gml/controls/errorBoundary'

const DealInsights = (props) => {
  const {
    className = 'px-6 py-4 bg-color-ffffff rounded-lg border border-color-2e5bff-08',
    showHeader = true,
    showHover = true,
  } = props

  const [signalDetail, setSignalDetail] = useState(null)

  const dispatch = useDispatch()

  const { debug } = useDebug()

  const params = useParams()

  const opportunityId = useMemo(() => {
    return params.opportunityId || props.opportunityId
  }, [params])

  const chartTypes = useMemo(() => {
    return [
      controlTypes.BarChart,
      controlTypes.FunnelChart,
      controlTypes.LineChart,
      controlTypes.DonutChart
    ]
  }, [])

  useEffect(() => {
    dispatch(clearSignals())
    dispatch(getSignalsByOpportunity(opportunityId, 0, -1))
  }, [opportunityId])

  const signalsList = useSelector((state) => state.signalsByOpportunity)

  const { hasType } = useTreeHasType(chartTypes)

  const treeHasType = useCallback((tree) => {
    return hasType(tree)
  }, [])

  const signals = useMemo(() => {
    return uniqBy(signalsList, (s) => s.id)
  }, [signalsList])

  const signalCount = useMemo(() => {
    return signals.length
  }, [signals])

  return (
    <div className={className}>

      {showHeader
        && (
          <>
            <div className="flex items-center">
              <div
                style={{ width: 20, height: 20 }}
                className="inline-block text-center text-size-12px font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-color-5951FF">
                <div style={{ transform: 'translateY(-2px)' }}>{signalCount}</div>
              </div>
              <div className="ml-2 text-size-24px text-color-09242f font-weight-700">Signals</div>
            </div>
            <div className="text-size-14px text-color-51636a font-normal leading-tight">Signals and noteworthy updates that should be known about this deal.</div>
            <div className="border-t border-color-d6d9e6 mt-4 mb-3" />
          </>
        )}

      {signalCount > 0
        ? (
          <div className="flex flex-col">

            {signals.map((s, index) => {
              const showMore = treeHasType(s.presentation.resolved)
              return (
                <div
                  key={`signal-${s.id}-${index}`}
                  className="my-3">
                  <div className="text-color-818e93 text-size-16px font-normal leading-tight">
                    <ErrorBoundary>
                      <GMLRenderer
                        key={`GMLRenderer-signal-${s.id}`}
                        tree={s.presentation.resolved}
                        contentType="app::basic"
                        debug={debug}
                        showMore={true}
                        animate={true} />
                      {showMore && (
                        <div className={classNames('mt-1', { 'cursor-pointer': showHover })}>
                          <button onClick={() => setSignalDetail(s)} className="font-weight-500 text-color-2e5bff text-size-14px">Show More</button>
                        </div>
                      )}
                    </ErrorBoundary>
                  </div>
                </div>
              )
            })}
            {signalDetail && (
              <GenericModal
                handleClose={() => setSignalDetail(null)}
                open={true}>
                <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
                  <div className="text-size-22px text-color-ffffff font-bold">Signal Detail</div>
                  <button onClick={() => setSignalDetail(null)} className="focus:outline-none"><Close htmlColor="#ffffff" /></button>
                </div>
                <div className="signal dealInsight" style={{ margin: 0 }}>
                  <ErrorBoundary>
                    <GMLRenderer
                      key={`GMLRenderer-signal-${signalDetail.id}`}
                      tree={signalDetail.presentation.resolved}
                      debug={debug}
                      animate={true} />
                  </ErrorBoundary>
                </div>
              </GenericModal>
            )}
          </div>
        )
        : (
          <div className="my-4">
            <div className="font-weight-400">No signals available for this deal</div>
          </div>
        )}
    </div>
  )
}

export default DealInsights
