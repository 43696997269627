export const roles = {
  MANAGER: 'manager',
  IC: 'ic',
}

// team roles
export const teamRoles = {
  AE: 'ae',
  DR: 'dr',
  CX: 'cx',
  Manager: 'manager',
}
