import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { apiSuccessSelector, strengthsAndWeaknessesSelector } from '../../selectors'
import { getSignalStrengthsByUserKey, getSignalWeaknessesByUserKey } from '../../actions/signalService'
import debounce from 'lodash/debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cardScrollViewSettings } from '../../constants/cardScrollViewSettings'
import classNames from 'classnames'
import SignalsList from './signalsList'

const StrengthsAndWeaknessesLegacy = (props) => {
  const { userId, title, subTitle } = props

  const { cardOffsetWidth } = cardScrollViewSettings
  const nextPrevBtnStyle = {
    width: '48px',
    height: '48px',
    marginTop: 125,
    fontSize: '13px',
    border: '1px solid rgb(227, 232, 250)',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
  }

  const isStrengthsApiSuccess = useSelector((state) => apiSuccessSelector(state, getSignalStrengthsByUserKey))
  const isWeaknessesApiSuccess = useSelector((state) => apiSuccessSelector(state, getSignalWeaknessesByUserKey))

  const signals = useSelector(strengthsAndWeaknessesSelector)

  const [visible, setVisible] = useState(true)
  useEffect(() => {
    setVisible(isStrengthsApiSuccess && isWeaknessesApiSuccess && signals.length > 0)
  }, [isStrengthsApiSuccess, isWeaknessesApiSuccess, signals])

  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [enableNextBtn, setEnableNextBtn] = useState(false)
  const scrollViewRef = useRef({})
  const [canShowNextPrevBtns, setCanShowNextPrevBtns] = useState(false)

  const getNearestCardIndex = useCallback(() => {
    if (scrollViewRef && scrollViewRef.current) {
      const { scrollLeft } = scrollViewRef.current
      return Math.round(scrollLeft / cardOffsetWidth)
    } else {
      return 0
    }
  }, [scrollViewRef.current])

  const scrollToCardIndex = useCallback((index) => {
    if (scrollViewRef && scrollViewRef.current) {
      scrollViewRef.current.scroll({ left: index * cardOffsetWidth })
    }
  }, [scrollViewRef.current])

  const debounceScrollEnded = debounce(scrollToCardIndex, 750)

  const handleScroll = useCallback((e) => {
    if (scrollViewRef && scrollViewRef.current) {
      const { scrollLeft, offsetWidth, scrollWidth } = scrollViewRef.current
      const enableNext = offsetWidth + scrollLeft < scrollWidth
      setEnablePrevBtn(scrollLeft > 0)
      setEnableNextBtn(enableNext)
      if (enableNext) {
        debounceScrollEnded(getNearestCardIndex())
      }
    }
  }, [scrollViewRef.current])

  useEffect(() => {
    if (scrollViewRef.current && signals.length > 0) {
      setCanShowNextPrevBtns(isStrengthsApiSuccess && isWeaknessesApiSuccess && (scrollViewRef.current && scrollViewRef.current.scrollWidth > scrollViewRef.current.clientWidth))
      handleScroll()
    }
  }, [signals, isStrengthsApiSuccess, isWeaknessesApiSuccess, scrollViewRef.current])

  const prev = useCallback(() => {
    window.analytics.track('Strength Weakness Back')
    scrollToCardIndex(getNearestCardIndex() - 1)
  }, [])

  const next = useCallback(() => {
    window.analytics.track('Strength Weakness Forward')
    scrollToCardIndex(getNearestCardIndex() + 1)
  }, [])

  const sharedBtnClasses = 'flex items-center bg-color-ffffff rounded-full cursor-pointer pointer-events-auto'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, '-mr-13', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  const configOverride = {
    config: {
      strengthChart: {
        padding: {
          top: 10,
          bottom: 10,
          left: 0,
          right: 0
        }
      },
      weaknessChart: {
        padding: {
          top: 10,
          bottom: 10,
          left: 0,
          right: 0
        }
      }
    }
  }

  return (
    <div className={classNames('relative', { hidden: !visible })}>

      {canShowNextPrevBtns && (
        <div className="-ml-10 -mr-6 z-20 absolute flex justify-between w-full pointer-events-none select-none">
          <div className={prevBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={prev}>
            <FontAwesomeIcon icon="chevron-left" size="2x" color="#c9ced0" className="mx-auto" />
          </div>
          <div className={nextBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={next}>
            <FontAwesomeIcon icon="chevron-right" size="2x" color="#c9ced0" className="mx-auto" />
          </div>
        </div>
      )}

      <div className="-ml-10 -mr-6">
        {isStrengthsApiSuccess && isWeaknessesApiSuccess && signals.length > 0
          && (
            <>
              {title && <h1 className="ml-10">{title}</h1>}
              {subTitle && <div className="ml-10 text-size-14px text-color-4e5d7e">{subTitle}</div>}
            </>
          )}
        <div ref={scrollViewRef}
          className="mt-3 pb-2 inline-flex max-w-full overflow-y-hidden no-scrollbar"
          style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}
          onScroll={handleScroll}>
          {isStrengthsApiSuccess && isWeaknessesApiSuccess
            && (
              <SignalsList
                signals={signals}
                keyPrefix={`sw-${userId}`}
                itemClassName="strengthWeakness ml-4 first:ml-10 flex-none"
                itemStyle={{ width: 325 }}
                configOverride={configOverride} />
            )}
        </div>
      </div>

    </div>
  )
}

export default StrengthsAndWeaknessesLegacy
