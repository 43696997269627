import { createReducer } from '@reduxjs/toolkit'
import { findIndex, forEach, remove } from 'lodash'
import { clearOppDetail, clearSearchedOpps, updateOppAfterPatch, clearDealHealthById } from '../actions'
import { getOppDetailByIdSuccess, searchOppsSuccess, dealHealthDataSuccess, dealHealthDataByIdSuccess } from '../actions/searchService'
import { getFieldFromObject, decodeString } from '../components/fieldRenderers/helpers'
import { parseDate } from '../lib/dateFns'
import { captureException } from '../lib/sentry'

export function enrichFields(opp) {
  opp.fields = {}
  forEach(opp.fieldsMap, (field) => {
    const [fieldKey, data] = field
    if (fieldKey && data && data.instancesList) {
      opp.fields[fieldKey.toLowerCase()] = data.instancesList.map((i) => {
        const f = {
          name: i.name,
          type: i.type,
          source: i.source,
          value: undefined,
          originalValue: undefined
        }
        if (i.value) {
          try {
            f.originalValue = decodeString(i.value)
          } catch (err) {
            captureException(new Error(`Error in parse of value ${i.value}`), undefined, 'enrichFields')
          }
        }
        if (i.displayValue) {
          try {
            f.value = decodeString(i.displayValue)
          } catch (err) {
            captureException(new Error(`Error in parse of displayValue ${i.displayValue}`), undefined, 'enrichFields')
          }
        } else {
          f.value = f.originalValue
        }
        if (data.versionsList) {
          f.versions = []
          forEach(data.versionsList, (v) => {
            const version = {
              ts: v.ts,
              value: ''
            }
            if (v.instancesList && v.instancesList.length > 0) {
              if (v.instancesList[0].displayValue) {
                try {
                  version.value = v.instancesList[0].displayValue ? decodeString(v.instancesList[0].displayValue) : ''
                } catch (err) {
                  captureException(new Error(`Error in parse of displayValue ${JSON.stringify(v.instancesList)}`), undefined, 'enrichFields')
                }
              } else if (v.instancesList[0].value) {
                try {
                  version.value = v.instancesList[0].value ? decodeString(v.instancesList[0].value) : ''
                } catch (err) {
                  captureException(new Error(`Error in parse of value ${JSON.stringify(v.instancesList)}`), undefined, 'enrichFields')
                }
              }
            }
            if (version.value !== '') {
              f.versions.push(version)
            }
          })
        }
        return f
      })
    }
  })
  if (opp.fields.lastmodifieddate) {
    opp.fields._internalupdatedat = opp.fields.lastmodifieddate
  }
  return opp
}

const initialState = {
  skip: undefined,
  take: undefined,
  total: undefined,
  sumAmount: undefined,
  valuesList: [],
}
export const searchedOpps = createReducer(initialState, {
  [clearSearchedOpps.type]: (state, action) => initialState,
  [searchOppsSuccess.type]: (state, action) => {
    const { total, sumAmount, valuesList, skip, take } = action.payload
    state.total = total
    state.sumAmount = sumAmount
    state.valuesList = [...valuesList.map((opp) => enrichFields(opp))]
    state.skip = skip
    state.take = take
    return state
  },
  [updateOppAfterPatch.type]: (state, action) => {
    const opp = action.payload
    const index = findIndex(state.valuesList, (o) => o.id === opp.id)
    if (index !== -1) {
      state.valuesList[index] = enrichFields(opp)
    }
    let removeOpp = false
    if (opp.isCloseDateChange && opp.searchFilters && opp.searchFilters.appliedFilters && opp.searchFilters.appliedFilters.closedate
      && opp.searchFilters.appliedFilters.closedate.data) {
      const { startDate, endDate } = opp.searchFilters.appliedFilters.closedate.data
      const field = getFieldFromObject(opp, 'closedate')
      const closeDate = (field && field.value) || ''
      if (closeDate && startDate && endDate) {
        const closeDateTime = parseDate(closeDate).getTime()
        const startDateTime = parseDate(startDate).getTime()
        const endDateTime = parseDate(endDate).getTime()
        removeOpp = closeDateTime < startDateTime || closeDateTime > endDateTime
      }
    }
    if (removeOpp) {
      remove(state.valuesList, (o) => o.id === opp.id)
    }
    return state
  },
})

const dealHealthInitialState = {}
export const dealHealth = createReducer(dealHealthInitialState, {
  [dealHealthDataSuccess.type]: (state, action) => {
    const { opportunitiesList } = action.payload
    state.outreachOpportunities = opportunitiesList
    return state
  }
})

const dealHealthByIdInitialState = {}
export const dealHealthById = createReducer(dealHealthByIdInitialState, {
  [clearDealHealthById.type]: (state, action) => dealHealthByIdInitialState,
  [dealHealthDataByIdSuccess.type]: (state, action) => {
    const { opportunitiesList } = action.payload
    state.currentOppDealHealth = opportunitiesList[0]
    return state
  }
})

const oppDetailInitialState = {}
export const oppDetail = createReducer(oppDetailInitialState, {
  [clearOppDetail.type]: (state, action) => oppDetailInitialState,
  [getOppDetailByIdSuccess.type]: (state, action) => {
    state = enrichFields(action.payload)
    return state
  },
  [updateOppAfterPatch.type]: (state, action) => {
    return {
      ...state,
      ...enrichFields(action.payload)
    }
  }
})

const pipelineGridFieldsInitialState = {}
export const pipelineGridFields = createReducer(pipelineGridFieldsInitialState, {
  [searchOppsSuccess.type]: (state, action) => {
    const { storeFields, valuesList } = action.payload
    if (storeFields && valuesList.length > 0) {
      const opp = enrichFields(valuesList[0])
      state = {
        ...opp.fields
      }
    }
    return state
  },
})
