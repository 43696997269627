import React from 'react'
import { permissionNames } from '../../constants/permissionNames'
import { usePermissions } from '../../context/permissions'
import useRoles from '../../hooks/useRoles'
import ManagerSideMenu from './managerSideMenu'
import RepSideMenu from './repSideMenu'

const Summary = (props) => {
  const {
    isManagerRole,
    isIndividualContributorRole,
  } = useRoles()

  const { checkPermission } = usePermissions()

  if (isManagerRole) {
    return <ManagerSideMenu {...props} />
  } else if (isIndividualContributorRole) {
    return <RepSideMenu {...props} />
  } else if (checkPermission(permissionNames.CanAccessInternalAdmin)) {
    return <ManagerSideMenu {...props} />
  } else {
    return <></>
  }
}

export default Summary
