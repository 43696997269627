import React, { useState, useRef, useEffect } from 'react'
import Popover from '../popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const MenuItem = ({ text, handleClick, width }) => {
  return (
    <a className="cursor-pointer select-none" onClick={handleClick}>
      <div style={{ width }} className="bg-color-fafafa hover:bg-gradient-green hover:text-color-fafafa px-6 py-2 whitespace-nowrap truncate">{text}</div>
    </a>
  )
}

const DealDropDownFilter = (props) => {
  const { label, menuItems = [], handleMenuItemClick, selectedLabel } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const buttonRef = useRef(null)

  const defaultMenuItem = { label: 'Any' }
  const [selectedItem, setSelectedItem] = useState({ ...defaultMenuItem, label: selectedLabel })

  useEffect(() => {
    setSelectedItem({ ...defaultMenuItem, label: selectedLabel })
  }, [selectedLabel])

  const [menuItemWidth, setMenuItemWidth] = useState(100)
  function handleClick(event) {
    setAnchorEl(event.currentTarget)
    setMenuItemWidth(buttonRef.current.clientWidth)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleMenuItemClickInternal(menuItem) {
    handleMenuItemClick(menuItem)
    setSelectedItem(menuItem)
    handleClose()
  }

  const labelClasses = classNames('px-4 py-2 text-size-16px font-bold text-color-151d49 truncate', { 'text-color-2e5bff': selectedItem.label !== 'Any' })

  return (
    <div className="flex flex-col">
      <div className="px-4 py-1 text-color-4e5d7e text-size-16px">{label}</div>
      <div>
        <button ref={buttonRef}
          className="w-full flex justify-between items-center bg-color-fafafa rounded-full shadow-btn overflow-hidden truncate focus:outline-none"
          onClick={handleClick}>
          <span className={labelClasses}>{selectedItem.label || '-'}</span>
          <FontAwesomeIcon icon="chevron-down" className="mx-6" />
        </button>
        <Popover
          style={{ zIndex: 10000 }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}>
          <MenuItem text="Any" handleClick={() => handleMenuItemClickInternal(defaultMenuItem)} width={menuItemWidth} />
          {menuItems.map((m, i) => {
            return <MenuItem key={i} text={m.label || '-'} handleClick={() => handleMenuItemClickInternal(m)} width={menuItemWidth} />
          })}
        </Popover>
      </div>
    </div>
  )
}

export default DealDropDownFilter
