import React, { useCallback, useEffect, useRef, useState } from 'react'
import Call from '../../lib/call'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import CountUp from 'react-countup'
import { has } from 'lodash'
import classNames from 'classnames'

const ForecastCall = (props) => {
  const {
    animate = false,
    className,
    style,
    inputClassName,
    inputStyle = {},
    labelClassName,
    forecastCategory,
    value,
    validator,
    validatorMessage,
    onCallChange,
    labelDecorator,
    notifyError,
    tooltip,
    readonly,
  } = props

  const inputRef = useRef()
  const [isEditMode, setIsEditMode] = useState(false)
  const [prevInputValue, setPrevInputValue] = useState(value)
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onLabelClick = useCallback(() => {
    setIsEditMode(true)
  }, [])

  const onChange = useCallback((e) => {
    inputValue.update(e.target.value)
    setInputValue(new Call(inputValue))
  }, [inputValue])

  const onBlur = useCallback((e) => {
    setIsEditMode(false)
    if (e.cancelled) {
      return
    }
    if (inputValue === '') {
      setInputValue(value)
      return
    }
    if (inputValue.value !== value.value) {
      if (validator && !validator(inputValue)) {
        setIsEditMode(true)
        notifyError && notifyError(validatorMessage || 'An error occurred')
        return
      }

      onCallChange?.({
        [forecastCategory.id]: inputValue
      })
    }
  }, [forecastCategory.id, inputValue, notifyError, onCallChange, validator, validatorMessage, value])

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setInputValue(value)
      onBlur({ cancelled: true })
    } else if (e.key === 'Enter') {
      onBlur({ cancelled: false })
    }
  }, [onBlur, value])

  return (
    <div className={className} style={style}>
      {isEditMode
        ? (
          <input
            autoFocus
            ref={inputRef}
            className={inputClassName}
            style={inputStyle}
            type="number"
            value={inputValue?.initial}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown} />
        )
        : (
          <div>
            <Tippy
              offset={[0, 4]}
              duration={[0, 0]}
              maxWidth={800}
              content={tooltip}
              arrow={roundArrow}
              theme="canopy">
              <div
                {...!readonly && { onClick: onLabelClick }}
                className={classNames(labelClassName, { 'pointer-events-none cursor-default': readonly })}>
                {animate
                  ? (
                    <CountUp
                      decimals={4}
                      start={prevInputValue.value}
                      end={inputValue.value}
                      duration={0.5}
                      formattingFn={inputValue.formatter} />
                  )
                  : inputValue.toString()}
              </div>
            </Tippy>
            {labelDecorator && labelDecorator}
          </div>
        )}
    </div>
  )
}

export default ForecastCall
