import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { apiSuccessSelector } from '../../selectors'
import SignalsList from './signalsList'
import EmptyState from '../empty/emptyState'
import Chart from '../svg/chart'
import { getKeyMetricsByUserKey } from '../../actions/signalService'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Waypoint } from 'react-waypoint'
// import { getKeyMetricsByUser } from '../../services/signalService'
// import { keyMetricsPersonFilterHeaders } from './constants'
import { uniqBy } from 'lodash'

const RepKeyMetrics = (props) => {
  const {
    id,
    title,
    subTitle,
  } = props

  // const dispatch = useDispatch()

  const keyMetrics = useSelector((state) => state.keyMetricsByUser)

  const signals = useMemo(() => {
    return uniqBy(keyMetrics, (s) => s.id)
  }, [keyMetrics])

  // const count = useMemo(() => {
  //   return signals.length
  // }, [signals])

  const userApiSuccess = useSelector((state) => apiSuccessSelector(state, getKeyMetricsByUserKey))

  // const totalCount = useSelector(state => keyMetricsTotalCountByIdSelector(state, id))

  // const showWaypoint = useMemo(() => {
  //   return count < totalCount
  // }, [count, totalCount])

  const showEmptyMessage = useMemo(() => {
    return userApiSuccess && signals.length === 0
  }, [userApiSuccess, signals])

  // const handleWaypointEntered = useCallback(() => {
  //   const skip = count
  //   const take = 2
  //   dispatch(getKeyMetricsByUser(id, skip, take, keyMetricsPersonFilterHeaders, true, true))
  // }, [id, count])

  return (
    <div className="w-full">
      {title && <h1>{title}</h1>}
      {subTitle && <div className="text-size-14px text-color-4e5d7e">{subTitle}</div>}

      {showEmptyMessage
        ? (
          <div className="flex justify-center my-4">
            <EmptyState iconControl={<Chart />} header="No Metrics" subHeader="Not enough data" />
          </div>
        )
        : (
          <>

            <SignalsList
              id={id}
              level="person"
              signals={signals}
              distinct={true} />

            {/* {showWaypoint && <React.Fragment>
            <div className="flex justify-center my-4">
              <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
            </div>
            <Waypoint onEnter={handleWaypointEntered} />
          </React.Fragment>} */}

          </>
        )}
    </div>
  )
}

export default RepKeyMetrics
