import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useOpportunityDefinition() {
  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const fieldsList = useMemo(() => {
    return objectDefinitions?.opportunity?.fieldsList ?? []
  }, [objectDefinitions])

  return {
    opportunityDefinition: objectDefinitions?.opportunity,
    fieldsList
  }
}
