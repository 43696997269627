import React, { useCallback, useMemo } from 'react'
import { usePopover } from '../../hooks/usePopover'
import Popover from '../popover'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../common/tooltip'

const DropDown = (props) => {
  const { disabled, selectedOption, children } = props

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const className = useMemo(() => {
    return classNames('w-full px-6 py-2 flex items-center justify-between bg-color-ffffff',
      'border border-color-edeeee rounded shadow-btn font-weight-600 text-size-16px focus:outline-none',
      { 'text-color-4f575a': selectedOption.key === -1 },
      { 'text-color-09242f': selectedOption.key !== -1 },
      { 'opacity-50 pointer-events-none': disabled })
  }, [disabled, selectedOption])

  return (
    <div className="w-full py-2">
      <button onClick={onClick} className={className}>
        <span className="whitespace-nowrap truncate">{selectedOption.value}</span>
        <div className="flex items-center">
          {selectedOption.secondaryValue && (
            <div
              className="text-color-a6aebe text-size-12px font-weight-600 whitespace-nowrap mx-2"
              style={{ transform: 'translateY(2px)' }}>
              <Tooltip
                disabled={!selectedOption.secondaryValueTooltip}
                placement="top"
                arrow={true}
                title={selectedOption.secondaryValueTooltip || ''}>
                <span>{selectedOption.secondaryValue}</span>
              </Tooltip>
            </div>
          )}
          <FontAwesomeIcon icon={open ? 'chevron-up' : 'chevron-down'} color="#151d49" size="sm" />
        </div>
      </button>
      <Popover
        style={{ zIndex: 9999999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        disablePortal={true}
        timeout={0}>
        {React.cloneElement(children, { onClose })}
      </Popover>
    </div>
  )
}

export default DropDown
