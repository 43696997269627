import { grpcInvoke } from '../grpc'
import { DataRequest } from '../protos/src/common/data_request_pb'
import {
  getAllPeopleRequested,
  getAllPeopleSuccess,
  getAllPeopleFailed,
} from '../actions/personService'

export const getAllPeople = (id) => {
  return async (dispatch) => {
    const request = new DataRequest()
    request.setId(id)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getAllPeopleRequested())
      },
      onSuccess: (obj) => {
        dispatch(getAllPeopleSuccess(obj))
      },
      onError: (err) => {
        dispatch(getAllPeopleFailed(err))
      },
      grpcMethod: 'getAllPeople',
      debug: false,
    })
  }
}
