import React, { useMemo } from 'react'
import FieldDebug from './fieldDebug'
import { format, parseISO } from 'date-fns'

const LastModifiedFieldRowItem = (props) => {
  const {
    debug,
    opportunity,
    fieldDefinition,
    field,
  } = props

  const { lastModifiedDateString } = opportunity

  const value = useMemo(() => {
    if (lastModifiedDateString) {
      try {
        const d = parseISO(lastModifiedDateString)
        return format(d, 'M/d/yyyy')
      } catch (err) {
        console.log(err)
      }
    }

    return '-'
  }, [lastModifiedDateString])

  return (
    <div className="LastModifiedFieldRowItem">
      <div className="text-color-2e384d text-size-15px font-normal">{value}</div>
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </div>
  )
}

export default LastModifiedFieldRowItem
