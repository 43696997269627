import { createTheme } from '@material-ui/core'

export default createTheme({
  palette: {
    primary: {
      main: '#02cf7d',
    },
    secondary: {
      main: '#2e5bff',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true
    }
  }
})
