import { DndProvider } from 'react-dnd'
import { guid } from '../lib/guid'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { forEach, orderBy } from 'lodash'
import { toGetSavedFiltersByKeyRequest } from '../grpc/converters'
import { useGrpcEffect } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import React, { useCallback, useMemo, useState } from 'react'
import { useUserInput } from '../hooks/useUserInput'

const ViewsContext = React.createContext()

export function ViewsProvider({ filterKey, children }) {
  const { notifyError } = useNotification()
  const { decodeString } = useUserInput()

  const [isFetching, setIsFetching] = useState(false)
  const [key, setKey] = useState(guid())
  const [views, setViews] = useState([])

  useGrpcEffect({
    request: toGetSavedFiltersByKeyRequest({
      key: filterKey
    }),
    onError: () => {
      notifyError('Error fetching views!')
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      if (obj.valueMap) {
        const views = orderBy(obj.valueMap.map((arr) => {
          if (arr.length > 1) {
            try {
              const value = arr[1]
              const payload = decodeString(value.payload)
              return {
                ...value,
                payload
              }
            } catch (err) {
              return {}
            }
          } else {
            return {}
          }
        }), (v) => v.order)
        // to support elastic search migration, we need to rename certain saved filter names
        forEach(views, (v) => {
          const valuesList = v?.payload?.query?.children?.valuesList ?? []
          forEach(valuesList, (value) => {
            switch (value?.node?.item?.name) {
              case 'status':
                value.node.item.name = '_status'
                break
              default:
            }
            switch (value?.node?._filter?.fieldDefinition?.key) {
              case 'canopy_status':
                value.node._filter.fieldDefinition.key = '_status'
                break
              default:
            }
          })
        })
        setViews(views)
      }
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getSavedFiltersByKey',
    debug: false,
  }, [key, filterKey])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      setIsFetching,
      views,
      setViews,
      key,
      invalidate,
    }
  }, [isFetching, views])

  return (
    <ViewsContext.Provider value={contextValue}>
      <DndProvider backend={HTML5Backend}>
        {children}
      </DndProvider>
    </ViewsContext.Provider>
  )
}

export function useViews() {
  const context = React.useContext(ViewsContext)
  if (context === undefined) {
    throw new Error('useViews must be used within a ViewsProvider')
  }
  return context
}
