import { grpcInvoke } from '../grpc'
import { grpcCodes } from '../grpc/grpcCodes'
import { EmptyMessage } from '../protos/src/common/empty_message_pb'
import { GetPipelineReportOptionsRequest, GetUserLastReportInputRequest } from '../protos/src/services/api/api_pb'
import {
  GetOpportunitiesSnapshotRequest,
  GetForecastSimulationsAvailableRequest,
  GetForecastSimulationReportOptionsMessage,
  RunForecastSimulationMessage
} from '../protos/src/services/reporting/reporting_pb'
import {
  getPipelineReportRequested,
  getPipelineReportSuccess,
  getPipelineReportFailed,
  getPipelineReportOptionsRequested,
  getPipelineReportOptionsSuccess,
  getPipelineReportOptionsFailed,
  getOpportunitiesSnapshotRequested,
  getOpportunitiesSnapshotSuccess,
  getOpportunitiesSnapshotFailed,
  getUserLastReportInputRequested,
  getUserLastReportInputSuccess,
  getUserLastReportInputFailed,
  getForecastSimulationsAvailableRequested,
  getForecastSimulationsAvailableSuccess,
  getForecastSimulationsAvailableFailed,
  getForecastSimulationLevelOptionsRequested,
  getForecastSimulationLevelOptionsSuccess,
  getForecastSimulationLevelOptionsFailed,
  getForecastSimulationReportOptionsRequested,
  getForecastSimulationReportOptionsSuccess,
  getForecastSimulationReportOptionsFailed,
  runForecastSimulationRequested,
  runForecastSimulationSuccess,
  runForecastSimulationFailed,
  getScenarioPlannerOptionsRequested,
  getScenarioPlannerOptionsSuccess,
  getScenarioPlannerOptionsFailed
} from '../actions/reportingService'
import { toFutureForecastRunContainer, toIdentificationParams, toPipelineReport, toQueryParam, toTimestamp, toTimeWindowedReportInput } from '../grpc/converters'
import { clearForecastSimulationWorkspace, setActiveScenarioKey, setForecastSimulationWorkspace, setRunFailed, upsertScenario } from '../actions'
import { findIndex, get, keys, orderBy } from 'lodash'
import moment from 'moment'

export const getPipelineReport = (input) => {
  return async (dispatch, getState) => {
    const request = toPipelineReport({ input })
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getPipelineReportRequested())
      },
      onSuccess: (obj) => {
        const { oppSettings } = getState()
        const { normalizedStages, normalizedForecastCategories } = oppSettings
        const selectedGroupBy = get(obj, 'input.groupByList[0]')
        const pipelineGroupsList = get(obj, 'output.pipelineGroupsList', []).map((x) => {
          let sort = -1
          if (selectedGroupBy === 0) {
            sort = findIndex(normalizedForecastCategories, (fc) => fc === x.id)
          } else if (selectedGroupBy === 1) {
            sort = findIndex(normalizedStages, (stageName) => stageName === x.id)
          }
          return {
            sort,
            ...x
          }
        })
        if (!obj.output) {
          obj.output = {
            pipelineGroupsList: []
          }
        }
        obj.output.pipelineGroupsList = orderBy(pipelineGroupsList, ['sort'], ['desc'])
        dispatch(getPipelineReportSuccess(obj))
      },
      onError: (err) => {
        dispatch(getPipelineReportFailed(err))
      },
      grpcMethod: 'getPipelineReport',
      debug: false
    })
  }
}

export const getPipelineReportOptions = ({ canonicalOnly = false, useCache = false }) => {
  return async (dispatch, getState) => {
    const { pipelineReportOptions } = getState()
    if (useCache && pipelineReportOptions && keys(pipelineReportOptions).length > 0) {
      return
    }
    const request = new GetPipelineReportOptionsRequest()
    request.setCanonicalOnly(canonicalOnly)
    grpcInvoke({
      headers: {
        ...canonicalOnly && { canonicalOnly }
      },
      request,
      onFetch: () => {
        dispatch(getPipelineReportOptionsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getPipelineReportOptionsSuccess(obj))
      },
      onError: (err) => {
        if (err.code === grpcCodes.NOT_FOUND) {
          dispatch(getPipelineReportOptionsSuccess({}))
        } else {
          dispatch(getPipelineReportOptionsFailed(err))
        }
      },
      grpcMethod: 'getPipelineReportOptions',
      debug: false
    })
  }
}

export const getScenarioPlannerOptions = ({ canonicalOnly = false, useCache = false }) => {
  return async (dispatch, getState) => {
    const { scenarioPlannerOptions } = getState()
    if (useCache && scenarioPlannerOptions && keys(scenarioPlannerOptions).length > 0) {
      return
    }
    const request = new GetPipelineReportOptionsRequest()
    request.setCanonicalOnly(canonicalOnly)
    grpcInvoke({
      headers: {
        ...canonicalOnly && { canonicalOnly }
      },
      request,
      onFetch: () => {
        dispatch(getScenarioPlannerOptionsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getScenarioPlannerOptionsSuccess(obj))
      },
      onError: (err) => {
        if (err.code === grpcCodes.NOT_FOUND) {
          dispatch(getScenarioPlannerOptionsSuccess({}))
        } else {
          dispatch(getScenarioPlannerOptionsFailed(err))
        }
      },
      grpcMethod: 'getPipelineReportOptions',
      grpcMethodName: 'getScenarioPlannerOptions',
      debug: false
    })
  }
}

export const getOpportunitiesSnapshot = (ids, limit, offset, inputParams, startBucket = '', endBucket = '') => {
  return async (dispatch, getState) => {
    const state = getState()
    const snapshotStart = get(state, 'pipelineReport.input.startDatetime')
    const snapshotEnd = get(state, 'pipelineReport.input.endDatetime')
    const request = new GetOpportunitiesSnapshotRequest()
    request.setIdsList(ids)
    request.setPageToken(state.pipelineReportOpportunities.nextPage)
    request.setLimit(limit)
    request.setInputParams(toTimeWindowedReportInput(inputParams))
    request.setSelectedStartBucket(startBucket)
    request.setSelectedEndBucket(endBucket)
    request.setOffset(offset)
    snapshotStart && request.setSnapshotStart(toTimestamp(snapshotStart))
    snapshotEnd && request.setSnapshotEnd(toTimestamp(snapshotEnd))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getOpportunitiesSnapshotRequested())
      },
      onSuccess: (obj) => {
        dispatch(getOpportunitiesSnapshotSuccess(obj))
      },
      onError: (err) => {
        dispatch(getOpportunitiesSnapshotFailed(err))
      },
      grpcMethod: 'getOpportunitiesSnapshot',
      debug: false
    })
  }
}

export const getUserLastReportInput = () => {
  return async (dispatch, getState) => {
    const request = new GetUserLastReportInputRequest()
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getUserLastReportInputRequested())
      },
      onSuccess: (obj) => {
        dispatch(getUserLastReportInputSuccess(obj))
      },
      onError: (err) => {
        if (err.code !== grpcCodes.NOT_FOUND) {
          dispatch(getUserLastReportInputFailed(err))
        }
      },
      grpcMethod: 'getUserLastReportInput',
      debug: false
    })
  }
}

export const getForecastSimulationsAvailable = (identificationParams) => {
  return async (dispatch, getState) => {
    const request = new GetForecastSimulationsAvailableRequest()
    request.setIdentity(toIdentificationParams(identificationParams))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getForecastSimulationsAvailableRequested())
      },
      onSuccess: (obj) => {
        dispatch(getForecastSimulationsAvailableSuccess(obj))
      },
      onError: (err) => {
        dispatch(getForecastSimulationsAvailableFailed(err))
      },
      grpcMethod: 'getForecastSimulationsAvailable',
      debug: false
    })
  }
}

export const getForecastSimulationLevelOptions = () => {
  return async (dispatch, getState) => {
    const request = new EmptyMessage()
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getForecastSimulationLevelOptionsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getForecastSimulationLevelOptionsSuccess(obj))
      },
      onError: (err) => {
        dispatch(getForecastSimulationLevelOptionsFailed(err))
      },
      grpcMethod: 'getForecastSimulationLevelOptions',
      debug: false
    })
  }
}

export const getForecastSimulationReportOptions = (identificationParams, workspace, filtersList) => {
  return async (dispatch, getState) => {
    const request = new GetForecastSimulationReportOptionsMessage()
    request.setIdentity(toIdentificationParams(identificationParams))
    request.setItem(toFutureForecastRunContainer(workspace))
    if (filtersList?.length > 0) {
      request.setFiltersList(filtersList.map((f) => toQueryParam(f)))
    }
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getForecastSimulationReportOptionsRequested())
      },
      onSuccess: (obj) => {
        dispatch(clearForecastSimulationWorkspace())
        dispatch(getForecastSimulationReportOptionsSuccess(obj))
        const workspace = obj.item
        const scenario = workspace.scenario
        scenario.baseline = {}
        scenario.input.categoriesList = scenario.input.categoriesList.map((categoriesList) => {
          return {
            key: categoriesList.key,
            fieldsList: orderBy(categoriesList.fieldsList, (o) => o.sort),
          }
        })
        scenario.createdAt = {
          value: {
            seconds: parseInt(moment().format('X')),
            nanos: 0
          },
          isValid: true,
        }
        if (scenario?.output?.numericValuesList) {
          scenario.output.numericValuesList.forEach((val) => {
            if (val?.callout) {
              val.callout = val.callout.toUpperCase()
            }
          })
        }
        workspace.scenariosList = [scenario]
        delete workspace.scenario
        // console.log('setForecastSimulationWorkspace', workspace)
        dispatch(setForecastSimulationWorkspace(workspace))
        dispatch(setActiveScenarioKey(scenario.key))
      },
      onError: (err) => {
        dispatch(getForecastSimulationReportOptionsFailed(err))
      },
      grpcMethod: 'getForecastSimulationReportOptions',
      debug: false
    })
  }
}

export const runForecastSimulation = (identificationParams, workspace) => {
  return async (dispatch, getState) => {
    const request = new RunForecastSimulationMessage()
    request.setIdentity(toIdentificationParams(identificationParams))
    request.setItem(toFutureForecastRunContainer(workspace))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(runForecastSimulationRequested())
      },
      onSuccess: (obj) => {
        dispatch(runForecastSimulationSuccess(obj))
        const scenario = obj.item.scenario
        scenario.runFailed = false
        if (scenario?.output?.numericValuesList) {
          scenario.output.numericValuesList.forEach((val) => {
            if (val?.callout) {
              val.callout = val.callout.toUpperCase()
            }
          })
        }
        // console.log('runForecastSimulationSuccess', scenario)
        dispatch(upsertScenario(scenario))
        dispatch(setActiveScenarioKey(scenario.key))
      },
      onError: (err) => {
        dispatch(runForecastSimulationFailed(err))
        dispatch(setRunFailed({ key: workspace.scenario.key, failed: true }))
      },
      grpcMethod: 'runForecastSimulation',
      debug: false
    })
  }
}
