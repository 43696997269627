import React, { useLayoutEffect, useCallback, useEffect, useMemo, useState } from 'react'
import GenericModal from '../modal/genericModal'
import { Close } from '@getoutreach/react-icons'
import { secondsToFormattedDate } from '../../lib/dateFns'
import classNames from 'classnames'
import { diffWords } from 'diff'
import AnimatedLoader from '../loaders/animatedLoader'

const UpdateModal = (props) => {
  const {
    modal,
    update
  } = props

  const { open, handleClose } = modal

  const [date, setDate] = useState('')
  const [diff, setDiff] = useState([])

  const handleCloseInternal = useCallback(() => {
    setDiff([])
    handleClose && handleClose()
  }, [handleClose])

  useEffect(() => {
    if (update && open) {
      const { updateDiff, date } = update
      const { oldValue, newValue } = updateDiff
      setDate(secondsToFormattedDate(date.seconds, 'M/d/yyyy h:mmaaa'))
      // ensure the modal is viewable and animation is showing
      window.setTimeout(() => {
        setDiff(diffWords(oldValue, newValue))
      }, 500)
    }
  }, [update, open])

  return (
    <GenericModal
      maxWidth="md"
      handleClose={handleCloseInternal}
      open={open}>
      <div className="w-full flex flex-col font-normal">
        <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
          <div className="text-size-22px text-color-ffffff font-bold">Activity</div>
          <button onClick={handleCloseInternal} className="focus:outline-none"><Close htmlColor="#ffffff" /></button>
        </div>
        <div className="w-full h-full px-10 py-10 leading-tight overflow-y-auto overflow-x-hidden" style={{ maxHeight: 'calc(100vh * 0.85)' }}>
          <div className="text-size-14px text-color-818e93 font-weight-500">{date}</div>
          <div className="text-size-16px text-color-09242f font-weight-600 mt-1 mb-5">
            {'NEXT STEP '}
            <span className="text-color-818e93 font-weight-500">(Viewing Changes)</span>
          </div>
          <div className="text-size-16px text-color-818e93 font-weight-500 my-2 whitespace-pre-line">
            {diff.length
              ? (
                <>
                  {diff.map((d, i) => {
                    const { added, removed, value } = d
                    const classes = classNames(
                      { 'bg-color-4de587-70': added },
                      { 'line-through': removed }
                    )
                    return (<span key={`span-${i}`} className={classes}>{value}</span>)
                  })}
                </>
              )
              : (
                <div
                  className="w-full h-full flex justify-center items-center top-0"
                  style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                  <AnimatedLoader />
                </div>
              )}
          </div>
        </div>
        <div className="px-10 py-5 border-t border-color-c9ced0 text-size-16px text-color-818e93 font-weight-500 my-2">
          <span className="bg-color-4de587-70">Added</span>
          {' | '}
          <span className="line-through">Removed</span>
          {' | '}
          <span>Unchanged</span>
        </div>
      </div>
    </GenericModal>
  )
}

export default UpdateModal
