import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDock } from '../../actions'
import { useForecastingFeature } from './hooks'
import classNames from 'classnames'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useForecasting } from '../../context/forecasting'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { keys } from 'lodash'
import { useLoggedInUser } from '../../context/loggedInUser'

const ViewForecast = (props) => {
  const { loggedInUser } = useLoggedInUser()
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadForecast
    )
  }, [checkPermissions])

  const dispatch = useDispatch()

  const { hasData, enabled } = useForecastingFeature()

  const dock = useSelector((state) => state.dock)

  const isOpen = useMemo(() => {
    return dock && dock.forecasting && dock.forecasting.enabled
  }, [dock])

  const onViewForecastClick = useCallback(() => {
    dispatch(setDock({ forecasting: { enabled: !isOpen } }))
    window.analytics.track('forecasting.v2.viewForecast.showHideToggleClicked')
  }, [dispatch, isOpen])

  const { selectedConfig } = useForecastConfigs()

  const { forecast, uniqOppCount, getCurrentRepForecastByConfig } = useForecasting()

  useEffect(() => {
    if (!selectedConfig) {
      return
    }
    getCurrentRepForecastByConfig(selectedConfig)
  }, [getCurrentRepForecastByConfig, selectedConfig])

  return permissions.CanReadForecast && hasData && enabled && loggedInUser?.teamId !== '' ? (
    <div
      onClick={onViewForecastClick}
      className={classNames('py-2 px-2 ml-6 flex items-center justify-between cursor-pointer rounded-full',
        { 'bg-gradient-green': isOpen },
        { 'border border-color-7771FC': !isOpen },
        { 'pointer-events-none opacity-50': keys(forecast).length === 0 })}>
      <div
        style={{ minWidth: 26, minHeight: 26, marginRight: 5 }}
        className={classNames('flex items-center justify-center text-center text-size-13px font-weight-600 rounded-full flex-shrink-0',
          { 'text-color-7771FC bg-color-ffffff': isOpen },
          { 'text-color-ffffff bg-gradient-green': !isOpen })}>
        <div className="text-size-14px px-1">
          {uniqOppCount}
        </div>
      </div>
      <div className={classNames('text-size-13px font-weight-600 leading-none pr-2',
        { 'text-color-ffffff': isOpen },
        { 'text-color-7771FC': !isOpen })}>
        {isOpen ? 'Hide' : 'View'}
        <br />
        Forecast
      </div>
    </div>
  ) : null
}

export default ViewForecast
