import React from 'react'
import FilterParameter from './filterParameter'
import ValueParameter from './valueParameter'
import ResolvedQueryParameter from './resolvedQueryParameter'
import { ParameterType } from '../../grpc/enums'
import classNames from 'classnames'

const ParameterRow = (props) => {
  const {
    user,
    available
  } = props

  return (
    <div className={classNames('parameter-cluster mb-6 mr-6 last:mr-0',
      { 'w-full': available?.type === ParameterType.PARAMETER_TYPE_FILTER })}>
      <div className="parameter-group">
        {available.type === ParameterType.PARAMETER_TYPE_FILTER && (
          <FilterParameter
            user={user}
            parameter={available} />
        )}
        {available.type === ParameterType.PARAMETER_TYPE_RESOLVED_QUERY && (
          <ResolvedQueryParameter
            user={user}
            parameter={available} />
        )}
        {available.type === ParameterType.PARAMETER_TYPE_VALUE && (
          <ValueParameter
            user={user}
            parameter={available} />
        )}
      </div>
    </div>
  )
}

export default ParameterRow
