import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalCallbackContainer } from './modals'
import Icon, { iconType } from '../icon'
import classNames from 'classnames'
import emailRegex from 'email-regex'
import filter from 'lodash/filter'
import some from 'lodash/some'
import { deliverNote } from '../../services/noteService'
import { clearApiActivityByKey } from '../../actions'
import { deliverNoteKey } from '../../actions/noteService'
import { apiSuccessSelector } from '../../selectors'
import { useLoggedInUser } from '../../context/loggedInUser'

const DeliverNoteModal = (props) => {
  const { loggedInUser } = useLoggedInUser()

  const { note, user, closeModal } = props
  const { email = '' } = user

  const dispatch = useDispatch()

  const [recipients, setRecipients] = useState(`${email}, `)
  const [comments, setComments] = useState('')

  const handleRecipientsChange = useCallback((e) => {
    setRecipients(e.target.value)
  }, [])

  const handleCommentsChange = useCallback((e) => {
    setComments(e.target.value)
  }, [])

  const handleCloseInternal = useCallback(() => {
    dispatch(clearApiActivityByKey(deliverNoteKey))

    modalCallbackContainer.callback = undefined
    closeModal && closeModal()
  }, [modalCallbackContainer, closeModal])

  const apiSuccess = useSelector((state) => apiSuccessSelector(state, deliverNoteKey))

  useEffect(() => {
    if (apiSuccess) {
      handleCloseInternal()
    }
  }, [apiSuccess])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [invalidRecipients, setInvalidRecipients] = useState(false)

  const [showError, setShowError] = useState(false)

  const handleSend = useCallback(() => {
    const recipientsList = filter(recipients.split(',').map((r) => r.trim()), (r) => r !== '')
    const invalid = some(recipientsList, (r) => !emailRegex({ exact: true }).test(r))
    setInvalidRecipients(invalid)

    if (!invalid) {
      try {
        const customMessage = comments.trim()
        dispatch(deliverNote(note, recipientsList, customMessage, loggedInUser))

        setIsSubmitting(true)
      } catch (err) {
        setIsSubmitting(false)
        setShowError(true)
        throw err
      }
    }
  }, [note, modalCallbackContainer, closeModal, recipients, comments, loggedInUser])

  return (
    <div className="static mx-auto rounded-lg overflow-hidden" style={{ maxWidth: '700px', maxHeight: window.innerHeight - 80, marginTop: 40 }}>
      <div className="fixed w-full flex items-center justify-between bg-gradient-dark-gray text-color-ffffff rounded-t-lg" style={{ maxWidth: '700px', marginTop: -1 }}>
        <div className="px-6 py-3 text-size-22px">Share 1:1 Session</div>
        <button onClick={handleCloseInternal} className={classNames('focus:outline-none', { 'pointer-events-none': isSubmitting })}>
          <Icon type={iconType.FONTAWESOME} iconName="times" size="sm" className="mr-6 text-color-ffffff" />
        </button>
      </div>
      <div className="pt-20 px-10 pb-10 py-4 bg-color-ffffff rounded-b-lg overflow-y-auto">
        <textarea
          disabled={isSubmitting}
          value={recipients}
          onChange={handleRecipientsChange}
          className={classNames('w-full rounded-lg border bg-color-fafafa border border-color-edeeee focus:outline-none p-4 mt-4 mb-4',
            { 'border-color-fb6c6a border-2': invalidRecipients })}
          placeholder="Recipients"
          rows="1" />
        <textarea
          disabled={isSubmitting}
          value={comments}
          onChange={handleCommentsChange}
          className={classNames('w-full rounded-lg border bg-color-fafafa border border-color-edeeee focus:outline-none p-4 mt-4 mb-4',
            { 'border-color-fb6c6a border-2': invalidRecipients })}
          placeholder="Additional comments (optional)"
          rows="10" />
        <div className="flex justify-center items-center mt-6" style={{ height: 50 }}>
          {isSubmitting
            ? <Icon type={iconType.FONTAWESOME} iconName="spinner" className="spinner" size="2x" />
            : (
              <button
                onClick={handleSend}
                className="px-10 py-3 text-size-14px text-color-ffffff font-weight-600 rounded-full bg-gradient-green shadow-btn focus:outline-none">
                Send Email
              </button>
            )}
        </div>
        {showError && (
          <div className="flex justify-center items-center mt-4 text-color-ff6177 text-size-14px font-normal">
            <Icon type={iconType.FONTAWESOME} iconName="exclamation-triangle" className="mx-2 text-color-ff6177" />
            There was an error sending your email!
          </div>
        )}
      </div>
    </div>
  )
}

export default DeliverNoteModal
