import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Popover from '../popover'
import { useRoutes } from '../../context/routes'
import Avatar from '../avatar/avatar'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { getClaims } from '../../lib/auth0'
import { claimNames } from '../../constants/claimNames'
import { some } from 'lodash'

const adminUrl = process.env.ADMIN_URL

const UserMenu = (props) => {
  const claims = getClaims()

  const avatarText = useMemo(() => {
    const txt = claims?.given_name || claims?.email || '-'
    return txt.toUpperCase().charAt(0)
  }, [claims])

  const { routes, routeTenantId } = useRoutes()

  const { checkPermission } = usePermissions()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const onAdminClick = useCallback(() => {
    const claims = getClaims()
    const tenantContexts = claims[claimNames.tenantContexts] || []
    let url = adminUrl
    if (routeTenantId && some(tenantContexts, (c) => c.tenant_id === routeTenantId)) {
      url += `/${routeTenantId}`
    }
    url += `/?nocache=${new Date().getTime()}`
    window.location = url
  }, [routeTenantId])

  return (
    <>
      <button className="flex flex-shrink-0 focus:outline-none" onClick={handleClick}>
        <Avatar className="w-10 h-10" imageUrl={claims.picture} text={avatarText} isRound={true} />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}>
        <div className="py-2">

          {(checkPermission(permissionNames.CanAccessAdmin) || checkPermission(permissionNames.CanAccessInternalAdmin))
            && <a onClick={onAdminClick}><div className="px-6 py-2 font-weight-600 focus:outline-none cursor-pointer">Admin</div></a>}

          <Link to={routes.logout}><div className="px-6 py-4 focus:outline-none">Logout</div></Link>

        </div>
      </Popover>
    </>
  )
}

export default UserMenu
