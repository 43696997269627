import { createAction } from '@reduxjs/toolkit'

export const searchOppsKey = 'api/search/searchOpps'
export const searchOppsRequested = createAction(`${searchOppsKey}/requested`)
export const searchOppsSuccess = createAction(`${searchOppsKey}/success`)
export const searchOppsFailed = createAction(`${searchOppsKey}/failed`)

export const dealHealthDataKey = 'api/search/searchOutreachOpportunies'
export const dealHealthDataRequested = createAction(`${dealHealthDataKey}/requested`)
export const dealHealthDataSuccess = createAction(`${dealHealthDataKey}/success`)
export const dealHealthDataFailed = createAction(`${dealHealthDataKey}/failed`)

export const dealHealthDataByIdKey = 'api/search/searchOutreachOpportuniesById'
export const dealHealthDataByIdRequested = createAction(`${dealHealthDataByIdKey}/requested`)
export const dealHealthDataByIdSuccess = createAction(`${dealHealthDataByIdKey}/success`)
export const dealHealthDataByIdFailed = createAction(`${dealHealthDataByIdKey}/failed`)

export const getOppDetailByIdKey = 'api/search/getOppDetailById'
export const getOppDetailByIdRequested = createAction(`${getOppDetailByIdKey}/requested`)
export const getOppDetailByIdSuccess = createAction(`${getOppDetailByIdKey}/success`)
export const getOppDetailByIdFailed = createAction(`${getOppDetailByIdKey}/failed`)

export const patchSearchItemKey = 'api/search/patchSearchItem'
export const patchSearchItemRequested = createAction(`${patchSearchItemKey}/requested`)
export const patchSearchItemSuccess = createAction(`${patchSearchItemKey}/success`)
export const patchSearchItemFailed = createAction(`${patchSearchItemKey}/failed`)

export const getForecastOppsKey = 'api/forecast/getForecastOpps'
export const getForecastOppsRequested = createAction(`${getForecastOppsKey}/requested`)
export const getForecastOppsSuccess = createAction(`${getForecastOppsKey}/success`)
export const getForecastOppsFailed = createAction(`${getForecastOppsKey}/failed`)
