import React, { useMemo } from 'react'
import editIcon from '../../assets/icon-edit.png'
import checkmarkIcon from '../../assets/icon-checkmark.png'
import classNames from 'classnames'
import Marker from './marker'
import { TimePeriod, UNSYNCED_ACTIVITY_COLUMN_WIDTH } from './constants'
import { addDays, format, isThisWeek, isToday } from 'date-fns'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import ActivitiesPreview from './activitiesPreview'
import OppUpdates from './oppUpdates'

const circleDiameter = 40
const circleRadius = circleDiameter / 2
const top = [20, 80, 140]

const UnsyncedActivityColumn = (props) => {
  const {
    data,
    selectedTimePeriod,
    activityTimeline,
    height,
    loading = false,
    showActivityDetails,
    showUpdateDetails
  } = props

  const { date, activities, opportunities } = data

  const changesList = useMemo(() => {
    if (opportunities && opportunities.length > 0) {
      const opp = opportunities[0] || {}
      const { diff = { changesList: [] } } = opp
      return diff.changesList
    }
    return []
  }, [opportunities])

  const updateCount = useMemo(() => {
    return changesList.length
  }, [changesList])

  const showUpdates = useMemo(() => {
    return (updateCount > 0) && !loading
  }, [updateCount, loading])

  const activityCount = useMemo(() => {
    return activities ? activities.length : 0
  }, [activities])

  const showActivity = useMemo(() => {
    return (activityCount > 0) && !loading
  }, [activityCount, loading])

  const dateDisplay = useMemo(() => {
    if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
      const thisWeek = isThisWeek(date)
      const classes = classNames(
        'mx-auto flex flex-col text-center py-2 leading-tight font-weight-500',
        { 'text-color-818e93': !thisWeek },
        { 'text-color-09242f': thisWeek }
      )
      return (
        <div className={classes}>
          <div className="text-size-16px">
            {format(date, 'M/d')}
            {' '}
            -
          </div>
          <div className="text-size-15px">{format(addDays(date, 6), 'M/d')}</div>
        </div>
      )
    } else { // default to TimePeriod.DAILY
      const today = isToday(date)
      const dayAbbrev = format(date, 'EEEEEE')
      const isWeekend = dayAbbrev === 'Su' || dayAbbrev === 'Sa'
      const classes = classNames(
        'mx-auto flex flex-col text-center py-2 leading-tight font-weight-500',
        { 'text-color-c9ced0': !today && isWeekend },
        { 'text-color-818e93': !today && !isWeekend },
        { 'text-color-09242f': today }
      )
      return (
        <div className={classes}>
          <div className="text-size-16px">{today ? 'Today' : dayAbbrev}</div>
          <div className="text-size-15px">{format(date, 'M/d')}</div>
        </div>
      )
    }
  }, [selectedTimePeriod, date])

  const className = useMemo(() => {
    return classNames(
      'flex flex-col items-start',
      { 'bg-color-f4f5f6 rounded-lg': selectedTimePeriod.value === TimePeriod.WEEKLY ? isThisWeek(date) : isToday(date) }
    )
  }, [date])

  return (
    <div className={className} style={{ paddingTop: circleRadius }}>

      <div className="w-full relative" style={{ width: UNSYNCED_ACTIVITY_COLUMN_WIDTH, height }}>

        <div className="absolute z-0 w-full border-t border-color-edeeee" style={{ top: top[0] }} />
        <div className="absolute z-0 w-full border-t border-color-edeeee" style={{ top: top[1] }} />

        {showUpdates
          && (
            <div className="absolute w-full" style={{ top: top[0], transform: `translate(0px, -${circleRadius}px)` }}>
              <Tippy
                interactive={true}
                offset={[0, 0]}
                delay={[0, 0]}
                maxWidth={500}
                duration={[300, 0]}
                zIndex={999}
                arrow={roundArrow}
                appendTo={document.body}
                content={<OppUpdates selectedTimePeriod={selectedTimePeriod} showUpdateDetails={showUpdateDetails} opportunity={opportunities[0] || {}} />}
                theme="canopy">
                <div className="mx-auto pointer-events-auto" style={{ width: circleDiameter, height: circleDiameter }}>
                  <div className="w-full h-full flex justify-center items-center rounded-full bg-color-a6b2cf border border-color-ffffff">
                    <div className="relative">
                      <img src={editIcon} style={{ width: 10, height: 9, transform: 'scale(1.5)' }} />
                      {updateCount > 1 && <Marker count={updateCount} color="text-color-a6b2cf" />}
                    </div>
                  </div>
                </div>
              </Tippy>
            </div>
          )}

        {showActivity
          && (
            <div className="absolute w-full" style={{ top: top[1], transform: `translate(0px, -${circleRadius}px)` }}>
              <Tippy
                interactive={true}
                offset={[0, 0]}
                delay={[0, 0]}
                arrow={roundArrow}
                maxWidth={500}
                duration={[300, 0]}
                zIndex={999}
                appendTo={document.body}
                content={<ActivitiesPreview activityTimeline={activityTimeline} selectedTimePeriod={selectedTimePeriod} data={data} showActivityDetails={showActivityDetails} />}
                theme="canopy">
                <div className="mx-auto pointer-events-auto" style={{ width: circleDiameter, height: circleDiameter }}>
                  <div className="w-full h-full flex justify-center items-center rounded-full bg-color-2e5bff border border-color-ffffff">
                    <div className="relative z-50">
                      <img src={checkmarkIcon} style={{ width: 14, height: 12 }} />
                      {activityCount > 1 && <Marker count={activityCount} />}
                    </div>
                  </div>
                </div>
              </Tippy>
            </div>
          )}

      </div>

      <div className="w-full mt-2 border-t border-color-c9ced0" />

      {dateDisplay}

    </div>
  )
}

export default UnsyncedActivityColumn
