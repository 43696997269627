import { PropTypesError } from '../errors'

global.console.err = global.console.error

console.error = (...args) => {
  if (args.length > 0 && /(Failed prop type)/.test(args[0])) {
    throw new PropTypesError(args[0])
  }

  console.err(...args)
}
