import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImpersonatingUserMenu from './impersonatingUserMenu'
import UserMenu from './userMenu'
import Avatar from '../avatar/avatar'
import classNames from 'classnames'
import MobileMenu from './mobileMenu'
import SearchDealsOrPeople from '../search/searchDealsOrPeople'
import Icon, { iconType } from '../icon'
import chevronLeft from '../../assets/chevron-left.png'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'
import { useTenantInfo } from '../../context/tenantInfo'

const Header = (props) => {
  const {
    children,
    stickyChildren,
    childrenPaddingTop,
    setPagePadding,
    breadcrumbControl,
    headerControl,
    showImage,
    imageUrl,
    text,
    subText,
    subTextBulletColor,
    subControl,
    showError,
    showSpinner,
    showBackButton,
    onBackClick,
    isModal,
    removeChildrenPadding,
    showSearch = true,
    onHeaderTextClick,
  } = props

  const { isImpersonatingUser } = useAuth()
  const { isPreview } = useRoutes()

  const location = useLocation()
  const isDealPath = useMemo(() => {
    return location.pathname.startsWith('/deal/')
  }, [location])

  const mainWidth = useSelector((state) => state.mainWidth)

  const { isSandbox } = useTenantInfo()

  const containerRef = React.useRef(null)

  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    const handleScroll = () => setScrollY(window.pageYOffset)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const hasChildren = !!children
  const containerHeight = containerRef.current ? containerRef.current.children[0].clientHeight : 0

  const [childrenStyle, setChildrenStyle] = useState({})
  useEffect(() => {
    setChildrenStyle(hasChildren ? { paddingTop: childrenPaddingTop || containerHeight } : { height: containerHeight - 2 })
    if (setPagePadding) {
      setPagePadding(containerHeight)
    }
  }, [hasChildren, containerHeight, containerRef, childrenPaddingTop])

  const [showHeaderBorder, setShowHeaderBorder] = useState(false)
  useEffect(() => {
    setShowHeaderBorder(!hasChildren || (scrollY > 0))
  }, [hasChildren, scrollY])

  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick()
    } else {
      window.history.back()
    }
  }, [onBackClick])

  return (
    <>
      <div id="header" ref={containerRef}>
        <div id="header-container" className={classNames({ fixed: !isModal }, { 'border-b border-color-eaeaea': showHeaderBorder })} style={{ width: mainWidth }}>
          <MobileMenu />
          <div className="bg-color-ffffff flex px-10 justify-between items-center" style={{ minHeight: 88 }}>
            <div className="flex flex-1 flex-shrink justify-between items-center min-w-0">
              <div className="flex items-center text-3xl font-bold relative min-w-0">
                {showBackButton && (
                  <button className="absolute -ml-5 bottom-0 focus:outline-none"
                    style={{ marginBottom: showImage ? 20 : 11, transform: 'translateY(4px)' }}
                    onClick={handleBackClick}>
                    <Icon type={iconType.IMAGE} src={chevronLeft} />
                  </button>
                )}
                <div className="flex flex-col min-w-0">
                  {breadcrumbControl && breadcrumbControl}
                  <div
                    className={
                      classNames('whitespace-nowrap truncate',
                        { 'cursor-pointer': onHeaderTextClick },
                        { 'pointer-events-none': !onHeaderTextClick })
                    }
                    style={{ height: '32px', lineHeight: '32px', ...subControl && { transform: 'translateY(-1px)' } }}
                    onClick={onHeaderTextClick}>
                    {text}
                  </div>
                  {subText && (
                    <div className="flex flex-row flex-nowrap items-center">
                      <div className="w-2 h-2 mr-1 rounded-full flex-shrink-0" style={{ backgroundColor: subTextBulletColor }} />
                      <span className="leading-tight text-size-12px text-color-91959f font-normal">{subText}</span>
                    </div>
                  )}
                  {subControl && subControl}
                </div>
                <FontAwesomeIcon icon="spinner" size="xs" className={classNames('spinner mx-2', { visible: showSpinner }, { invisible: !showSpinner })} />
                {showError && <FontAwesomeIcon icon="exclamation-circle" size="xs" className="mx-2" />}
              </div>
              {headerControl !== null && headerControl}
            </div>
            {!isModal && !isPreview && (
              <div className="flex items-center">
                {showSearch && <SearchDealsOrPeople />}
                {isImpersonatingUser && !isSandbox ? <ImpersonatingUserMenu /> : <UserMenu />}
              </div>
            )}
          </div>

          {stickyChildren && children && (
            <div className={classNames('bg-color-ffffff border-b border-color-eaeaea pb-8', { 'px-10': !removeChildrenPadding })}>
              {children}
            </div>
          )}
        </div>
      </div>

      {!stickyChildren && (
        <div className={classNames('bg-color-ffffff border-b border-color-eaeaea py-8', { 'px-10': !removeChildrenPadding })} style={isModal ? {} : childrenStyle}>
          {children}
        </div>
      )}

    </>
  )
}

export default Header
