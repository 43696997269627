import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Popover from '../popover'
import { usePopover } from '../../hooks/usePopover'
import { DateRange } from 'react-date-range'
import { format, parse, parseISO } from 'date-fns'
import classNames from 'classnames'
import { formatTypes } from './constants'
import FieldDebug from './fieldDebug'
import pencilSm from '../../assets/pencil-sm.png'
import Icon, { iconType } from '../icon'

const DateFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
  } = props

  const { label, canEdit } = fieldDefinition

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  const date = useMemo(() => {
    try {
      const { value } = field
      if (fieldDefinition.format === formatTypes.timestamp) {
        const d = parseISO(value)
        return isNaN(d) ? undefined : d
      } else {
        const d = parse(value, 'yyyy-MM-dd', new Date())
        return isNaN(d) ? undefined : d
      }
    } catch (err) {
      return undefined
    }
  }, [fieldDefinition, field])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure])

  const [actualDate, setActualDate] = useState('')

  useEffect(() => {
    setActualDate(date ? format(date, 'M/d/yyyy') : '')
  }, [date])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const [dateRange, setDateRange] = useState([{
    startDate: actualDate ? new Date(actualDate) : new Date(),
    endDate: actualDate ? new Date(actualDate) : new Date(),
    key: 'selection',
  }])

  useEffect(() => {
    setDateRange([{
      startDate: new Date(actualDate),
      endDate: new Date(actualDate),
      key: 'selection',
    }])
  }, [actualDate])

  const onDateRangeChange = useCallback((dateRange) => {
    const startDate = dateRange.selection.startDate.toLocaleDateString('en-US')
    const endDate = dateRange.selection.endDate.toLocaleDateString('en-US')
    if (startDate !== endDate) {
      return
    }

    setActualDate(startDate)

    onFieldChangedInternal(format(new Date(startDate), 'yyyy-MM-dd'))

    // close the popover to prevent a selection of a range since we want this component to only select a single date
    onClose()
  }, [onFieldChangedInternal])

  return (
    <>
      {!actualDate && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('DateFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
              {label}
              <FieldDebug
                debug={debug}
                data={{ opportunity, fieldDefinition, field }} />
            </div>
            <div
              style={{ transform: 'translateY(-1px)' }}
              onClick={onClick}
              className={classNames('flex flex-row items-center group cursor-pointer', { 'pointer-events-none': readonly })}>
              <div className={classNames('text-size-16px font-weight-300',
                { 'text-color-09242f': !readonly },
                { 'text-color-09242f': readonly })}>
                {actualDate || '-'}
              </div>
              <Icon type={iconType.IMAGE} src={pencilSm} className="invisible group-hover:visible" style={{ transform: 'translate(4px, -2px)' }} />
            </div>
            <Popover
              style={{ zIndex: 999999999 }}
              open={open}
              anchorEl={anchorEl}
              onClose={onClose}
              placement="bottom-start">
              <div>
                <div className="flex items-center justify-between px-3">
                  <div className="py-2 text-size-16px">Choose a Date</div>
                </div>
                <DateRange
                  editableDateInputs={false}
                  onChange={onDateRangeChange}
                  moveRangeOnFirstSelection={true}
                  ranges={dateRange}
                  staticRanges={[]}
                  inputRanges={[]}
                  showPreview={false}
                  showDateDisplay={false} />
              </div>
            </Popover>
          </div>
        )}
    </>
  )
}

export default DateFieldDetailItem
