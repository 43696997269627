import React, { useMemo } from 'react'
import filter from 'lodash/filter'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'
import PipelineGroupItem from './pipelineGroupItem'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const PipelineGroup = (props) => {
  const { pipelineGroups, containerWidth, inPipeline, selectedGroup, handleItemClick } = props
  const { id } = selectedGroup

  const { formatCurrency } = useFormatCurrency({ shortCurrency: true })

  const isGroupInPipeline = (g) => {
    return g.id !== 'isNew' && g.id !== 'isPulledIn' && g.id !== 'isOverdue'
  }

  const amountSum = sumBy(pipelineGroups, (g) => g.totalAmount)

  const group = useMemo(() => {
    if (inPipeline) {
      return orderBy(filter(pipelineGroups, (g) => isGroupInPipeline(g)), (x) => x.sort)
    } else {
      return orderBy(filter(pipelineGroups, (g) => !isGroupInPipeline(g)), (x) => x.sort)
    }
  }, [pipelineGroups, inPipeline])

  const totals = useMemo(() => {
    return {
      amount: sumBy(group, (g) => get(g, 'totalAmount', 0)),
      deals: sumBy(group, (g) => get(g, 'totalDeals', 0)),
    }
  }, [group])

  if (get(totals, 'amount', 0) === 0 && get(totals, 'deals', 0) === 0) {
    return <></>
  } else {
    return (
      <div className="flex-grow text-color-151d49">
        <div className="flex">
          {group.map((g) => (
            <PipelineGroupItem
              key={`PipelineGroupItem-${g.id}`}
              data={g}
              containerWidth={containerWidth}
              itemCount={pipelineGroups.length}
              amountSum={amountSum}
              inPipeline={inPipeline}
              isSelected={id === g.id}
              handleClick={() => handleItemClick(g)} />
          ))}
        </div>
        <div className="mt-3 pt-1 border-r border-l border-b border-color-a6aebe mr-1" />
        <div className="mt-1 text-center text-size-12px text-color-a6aebe font-weight-500 mr-1">
          {formatCurrency(totals.amount)}
          {' '}
          (
          {totals.deals}
          )
        </div>
      </div>
    )
  }
}

export default PipelineGroup
