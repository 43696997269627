import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetTenantsRequest } from '../grpc/converters'
import { orderBy, uniqBy } from 'lodash'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'

const TenantsContext = React.createContext()

const pageSize = 10

export function TenantsProvider({ children }) {
  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [key, setKey] = useState(guid())
  const [tenants, setTenants] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [region, setRegion] = useState(undefined)
  const [search, setSearch] = useState('')

  const setSearchInternal = useCallback((searchValue) => {
    setSearch(searchValue)
    setPage(1)
  }, [])

  useGrpcEffect({
    request: toGetTenantsRequest({
      statusFilter: 'active,pending',
      nameFilter: search,
      page,
      pageSize,
    }),
    onError: () => {
      notifyError('Error fetching tenants!')
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      const { tenantsList, total: totalTenants = 0 } = obj
      setIsFetching(false)
      setTenants(tenantsList)
      setTotal(totalTenants)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getTenants',
    debug: false,
    useApiRegion: region
  }, [key, page, pageSize, search, region])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      tenants,
      page,
      setPage,
      setRegion,
      pageSize,
      total,
      setSearch: setSearchInternal,
      invalidate,
    }
  }, [isFetching, tenants, page, total, setRegion])

  return <TenantsContext.Provider value={contextValue}>{children}</TenantsContext.Provider>
}

export function useTenants() {
  const context = React.useContext(TenantsContext)
  if (context === undefined) {
    throw new Error('useTenants must be used within a TenantsProvider')
  }
  return context
}
