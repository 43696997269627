import React, { useMemo } from 'react'
import { getFieldFromObject } from '../fieldRenderers/helpers'
import DealContactItem from './dealContactItem'
import ShowHideContainer from '../showHideContainer'

const DealContacts = (props) => {
  const {
    opportunity,
  } = props

  const contacts = useMemo(() => {
    const field = getFieldFromObject(opportunity, '_contacts')
    let value
    if (field?.value) {
      try {
        value = JSON.parse(field.value)
        return value
      } catch {
        return []
      }
    }
    return []
  }, [opportunity])

  const showNoContactsMessage = useMemo(() => {
    return contacts.length === 0
  }, [contacts])

  const showContacts = useMemo(() => {
    return contacts.length > 0
  }, [contacts])

  return (
    <div>
      <div>
        <div className="text-color-09242f text-size-18px font-bold mt-4">Deal Contacts</div>

        {showNoContactsMessage
          && (
            <div className="text-size-16px font-weight-600 text-color-09242f">
              There are no contacts for this deal.
            </div>
          )}
      </div>

      <ShowHideContainer maxHeight={220}>
        {showContacts
          && contacts.map((contact, index) => (
            <DealContactItem
              key={`DealContactItem-${index}`}
              contact={contact} />
          ))}
      </ShowHideContainer>
    </div>
  )
}

export default DealContacts
