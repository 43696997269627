// source: src/services/orchard/orchard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_orchard_system_role_pb = require('../../../src/common/orchard/system_role_pb.js');
goog.object.extend(proto, src_common_orchard_system_role_pb);
var src_common_orchard_crm_role_pb = require('../../../src/common/orchard/crm_role_pb.js');
goog.object.extend(proto, src_common_orchard_crm_role_pb);
var src_common_orchard_group_viewer_pb = require('../../../src/common/orchard/group_viewer_pb.js');
goog.object.extend(proto, src_common_orchard_group_viewer_pb);
var src_common_orchard_person_pb = require('../../../src/common/orchard/person_pb.js');
goog.object.extend(proto, src_common_orchard_person_pb);
var src_common_orchard_group_pb = require('../../../src/common/orchard/group_pb.js');
goog.object.extend(proto, src_common_orchard_group_pb);
var src_common_tenant_tenant_pb = require('../../../src/common/tenant/tenant_pb.js');
goog.object.extend(proto, src_common_tenant_tenant_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.services.orchard.ClonePersonRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ClonePersonResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CloneSystemRoleRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CloneSystemRoleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ConvertVirtualUsersRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ConvertVirtualUsersResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CreateGroupRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CreateGroupResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CreatePersonRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CreatePersonResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CreateSystemRoleRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.CreateSystemRoleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.Empty', null, global);
goog.exportSymbol('proto.loupe.services.orchard.FilterOp', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetCRMRolesByIdsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetCRMRolesRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetCRMRolesResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupMembersRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupMembersResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupSubTreeRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupSubTreeResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupSyncSettingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupSyncSettingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupViewersResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupsRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetGroupsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetLegacyTeamStructureRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetLegacyTeamStructureResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetPeopleByEmailRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetPeopleByEmailResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetPersonViewableGroupsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetSystemRolesRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetSystemRolesResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetTenantPersonCountRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetTenantPersonCountResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetUngroupedPeopleRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetUngroupedPeopleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetUserTeamRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetUserTeamResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetVirtualUsersRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GetVirtualUsersResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GroupSubtreeRoot', null, global);
goog.exportSymbol('proto.loupe.services.orchard.GroupWithMembers', null, global);
goog.exportSymbol('proto.loupe.services.orchard.IdRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.IdsRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.InsertGroupViewerRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.InsertGroupViewerResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.IsHierarchySyncedRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.IsHierarchySyncedResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.PersonFilter', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ReSyncCRMRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ReSyncCRMResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ReprovisionPeopleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ResetHierarchyRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.ResetHierarchyResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.SearchPeopleRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.SearchPeopleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.SetPersonViewableGroupsRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.SetPersonViewableGroupsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.SyncRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.SyncResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupSyncStateRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupSyncStateResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupTypesRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupTypesResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupViewerRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateGroupViewerResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdatePersonGroupsRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdatePersonGroupsResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdatePersonRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdatePersonResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateSystemRoleRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpdateSystemRoleResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpsertCRMRolesRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpsertCRMRolesResponse', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpsertPeopleRequest', null, global);
goog.exportSymbol('proto.loupe.services.orchard.UpsertPeopleResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.Empty.displayName = 'proto.loupe.services.orchard.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.IdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.IdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.IdRequest.displayName = 'proto.loupe.services.orchard.IdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.IdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.IdsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.IdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.IdsRequest.displayName = 'proto.loupe.services.orchard.IdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetUserTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetUserTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetUserTeamRequest.displayName = 'proto.loupe.services.orchard.GetUserTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetUserTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetUserTeamResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetUserTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetUserTeamResponse.displayName = 'proto.loupe.services.orchard.GetUserTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.SyncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.SyncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.SyncRequest.displayName = 'proto.loupe.services.orchard.SyncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.SyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.SyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.SyncResponse.displayName = 'proto.loupe.services.orchard.SyncResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.IsHierarchySyncedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.IsHierarchySyncedRequest.displayName = 'proto.loupe.services.orchard.IsHierarchySyncedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.IsHierarchySyncedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.IsHierarchySyncedResponse.displayName = 'proto.loupe.services.orchard.IsHierarchySyncedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ResetHierarchyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ResetHierarchyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ResetHierarchyRequest.displayName = 'proto.loupe.services.orchard.ResetHierarchyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ResetHierarchyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ResetHierarchyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ResetHierarchyResponse.displayName = 'proto.loupe.services.orchard.ResetHierarchyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupSyncSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupSyncSettingsRequest.displayName = 'proto.loupe.services.orchard.GetGroupSyncSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupSyncSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupSyncSettingsResponse.displayName = 'proto.loupe.services.orchard.GetGroupSyncSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ReSyncCRMRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ReSyncCRMRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ReSyncCRMRequest.displayName = 'proto.loupe.services.orchard.ReSyncCRMRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ReSyncCRMResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ReSyncCRMResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ReSyncCRMResponse.displayName = 'proto.loupe.services.orchard.ReSyncCRMResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupSyncStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupSyncStateRequest.displayName = 'proto.loupe.services.orchard.UpdateGroupSyncStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupSyncStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupSyncStateResponse.displayName = 'proto.loupe.services.orchard.UpdateGroupSyncStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.displayName = 'proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.displayName = 'proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CreateSystemRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CreateSystemRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CreateSystemRoleRequest.displayName = 'proto.loupe.services.orchard.CreateSystemRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CreateSystemRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CreateSystemRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CreateSystemRoleResponse.displayName = 'proto.loupe.services.orchard.CreateSystemRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CloneSystemRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CloneSystemRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CloneSystemRoleRequest.displayName = 'proto.loupe.services.orchard.CloneSystemRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CloneSystemRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CloneSystemRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CloneSystemRoleResponse.displayName = 'proto.loupe.services.orchard.CloneSystemRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.displayName = 'proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetSystemRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetSystemRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetSystemRolesRequest.displayName = 'proto.loupe.services.orchard.GetSystemRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetSystemRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetSystemRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetSystemRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetSystemRolesResponse.displayName = 'proto.loupe.services.orchard.GetSystemRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.UpdateSystemRoleRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateSystemRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateSystemRoleRequest.displayName = 'proto.loupe.services.orchard.UpdateSystemRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateSystemRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateSystemRoleResponse.displayName = 'proto.loupe.services.orchard.UpdateSystemRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.UpsertCRMRolesRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.UpsertCRMRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpsertCRMRolesRequest.displayName = 'proto.loupe.services.orchard.UpsertCRMRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpsertCRMRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpsertCRMRolesResponse.displayName = 'proto.loupe.services.orchard.UpsertCRMRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetCRMRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetCRMRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetCRMRolesRequest.displayName = 'proto.loupe.services.orchard.GetCRMRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetCRMRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetCRMRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetCRMRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetCRMRolesResponse.displayName = 'proto.loupe.services.orchard.GetCRMRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.displayName = 'proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.displayName = 'proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.InsertGroupViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.InsertGroupViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.InsertGroupViewerRequest.displayName = 'proto.loupe.services.orchard.InsertGroupViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.InsertGroupViewerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.InsertGroupViewerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.InsertGroupViewerResponse.displayName = 'proto.loupe.services.orchard.InsertGroupViewerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupViewersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetGroupViewersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupViewersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupViewersResponse.displayName = 'proto.loupe.services.orchard.GetGroupViewersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetPersonViewableGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetPersonViewableGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetPersonViewableGroupsResponse.displayName = 'proto.loupe.services.orchard.GetPersonViewableGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupViewerRequest.displayName = 'proto.loupe.services.orchard.UpdateGroupViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupViewerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupViewerResponse.displayName = 'proto.loupe.services.orchard.UpdateGroupViewerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CreatePersonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CreatePersonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CreatePersonRequest.displayName = 'proto.loupe.services.orchard.CreatePersonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CreatePersonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CreatePersonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CreatePersonResponse.displayName = 'proto.loupe.services.orchard.CreatePersonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpsertPeopleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.UpsertPeopleRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.UpsertPeopleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpsertPeopleRequest.displayName = 'proto.loupe.services.orchard.UpsertPeopleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpsertPeopleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpsertPeopleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpsertPeopleResponse.displayName = 'proto.loupe.services.orchard.UpsertPeopleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.SearchPeopleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.SearchPeopleRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.SearchPeopleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.SearchPeopleRequest.displayName = 'proto.loupe.services.orchard.SearchPeopleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.PersonFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.PersonFilter.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.PersonFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.PersonFilter.displayName = 'proto.loupe.services.orchard.PersonFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.SearchPeopleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.SearchPeopleResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.SearchPeopleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.SearchPeopleResponse.displayName = 'proto.loupe.services.orchard.SearchPeopleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupMembersRequest.displayName = 'proto.loupe.services.orchard.GetGroupMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupMembersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetGroupMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupMembersResponse.displayName = 'proto.loupe.services.orchard.GetGroupMembersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetUngroupedPeopleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetUngroupedPeopleRequest.displayName = 'proto.loupe.services.orchard.GetUngroupedPeopleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetUngroupedPeopleResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetUngroupedPeopleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetUngroupedPeopleResponse.displayName = 'proto.loupe.services.orchard.GetUngroupedPeopleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetVirtualUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetVirtualUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetVirtualUsersRequest.displayName = 'proto.loupe.services.orchard.GetVirtualUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetVirtualUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetVirtualUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetVirtualUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetVirtualUsersResponse.displayName = 'proto.loupe.services.orchard.GetVirtualUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ConvertVirtualUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ConvertVirtualUsersRequest.displayName = 'proto.loupe.services.orchard.ConvertVirtualUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.ConvertVirtualUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.ConvertVirtualUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ConvertVirtualUsersResponse.displayName = 'proto.loupe.services.orchard.ConvertVirtualUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdatePersonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.UpdatePersonRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.UpdatePersonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdatePersonRequest.displayName = 'proto.loupe.services.orchard.UpdatePersonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdatePersonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdatePersonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdatePersonResponse.displayName = 'proto.loupe.services.orchard.UpdatePersonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdatePersonGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdatePersonGroupsRequest.displayName = 'proto.loupe.services.orchard.UpdatePersonGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdatePersonGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdatePersonGroupsResponse.displayName = 'proto.loupe.services.orchard.UpdatePersonGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CreateGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CreateGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CreateGroupRequest.displayName = 'proto.loupe.services.orchard.CreateGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.CreateGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.CreateGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.CreateGroupResponse.displayName = 'proto.loupe.services.orchard.CreateGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupsRequest.displayName = 'proto.loupe.services.orchard.GetGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupsResponse.displayName = 'proto.loupe.services.orchard.GetGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetGroupSubTreeRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupSubTreeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupSubTreeRequest.displayName = 'proto.loupe.services.orchard.GetGroupSubTreeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GroupWithMembers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GroupWithMembers.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GroupWithMembers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GroupWithMembers.displayName = 'proto.loupe.services.orchard.GroupWithMembers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GroupSubtreeRoot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GroupSubtreeRoot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GroupSubtreeRoot.displayName = 'proto.loupe.services.orchard.GroupSubtreeRoot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetGroupSubTreeResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetGroupSubTreeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetGroupSubTreeResponse.displayName = 'proto.loupe.services.orchard.GetGroupSubTreeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.UpdateGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupRequest.displayName = 'proto.loupe.services.orchard.UpdateGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupResponse.displayName = 'proto.loupe.services.orchard.UpdateGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupTypesRequest.displayName = 'proto.loupe.services.orchard.UpdateGroupTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.UpdateGroupTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.UpdateGroupTypesResponse.displayName = 'proto.loupe.services.orchard.UpdateGroupTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetLegacyTeamStructureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetLegacyTeamStructureRequest.displayName = 'proto.loupe.services.orchard.GetLegacyTeamStructureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetLegacyTeamStructureResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetLegacyTeamStructureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetLegacyTeamStructureResponse.displayName = 'proto.loupe.services.orchard.GetLegacyTeamStructureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.displayName = 'proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.displayName = 'proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ClonePersonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ClonePersonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ClonePersonRequest.displayName = 'proto.loupe.services.orchard.ClonePersonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ClonePersonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ClonePersonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ClonePersonResponse.displayName = 'proto.loupe.services.orchard.ClonePersonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.ReprovisionPeopleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.ReprovisionPeopleResponse.displayName = 'proto.loupe.services.orchard.ReprovisionPeopleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.SetPersonViewableGroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.SetPersonViewableGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.SetPersonViewableGroupsRequest.displayName = 'proto.loupe.services.orchard.SetPersonViewableGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.SetPersonViewableGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.SetPersonViewableGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.SetPersonViewableGroupsResponse.displayName = 'proto.loupe.services.orchard.SetPersonViewableGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetTenantPersonCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetTenantPersonCountRequest.displayName = 'proto.loupe.services.orchard.GetTenantPersonCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetTenantPersonCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetTenantPersonCountResponse.displayName = 'proto.loupe.services.orchard.GetTenantPersonCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetPeopleByEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetPeopleByEmailRequest.displayName = 'proto.loupe.services.orchard.GetPeopleByEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetPeopleByEmailResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetPeopleByEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetPeopleByEmailResponse.displayName = 'proto.loupe.services.orchard.GetPeopleByEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.displayName = 'proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.displayName = 'proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.orchard.GetCRMRolesByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.orchard.GetCRMRolesByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.orchard.GetCRMRolesByIdsResponse.displayName = 'proto.loupe.services.orchard.GetCRMRolesByIdsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.Empty}
 */
proto.loupe.services.orchard.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.Empty;
  return proto.loupe.services.orchard.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.Empty}
 */
proto.loupe.services.orchard.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.IdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.IdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.IdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.IdRequest}
 */
proto.loupe.services.orchard.IdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.IdRequest;
  return proto.loupe.services.orchard.IdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.IdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.IdRequest}
 */
proto.loupe.services.orchard.IdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.IdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.IdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.IdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.IdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdRequest} returns this
 */
proto.loupe.services.orchard.IdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.IdRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdRequest} returns this
 */
proto.loupe.services.orchard.IdRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.orchard.IdRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdRequest} returns this
 */
proto.loupe.services.orchard.IdRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string person_id = 4;
 * @return {string}
 */
proto.loupe.services.orchard.IdRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdRequest} returns this
 */
proto.loupe.services.orchard.IdRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.loupe.services.orchard.IdRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdRequest} returns this
 */
proto.loupe.services.orchard.IdRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.IdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.IdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.IdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.IdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.IdsRequest}
 */
proto.loupe.services.orchard.IdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.IdsRequest;
  return proto.loupe.services.orchard.IdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.IdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.IdsRequest}
 */
proto.loupe.services.orchard.IdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.IdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.IdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.IdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.IdsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.IdsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.orchard.IdsRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string person_id = 4;
 * @return {string}
 */
proto.loupe.services.orchard.IdsRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.loupe.services.orchard.IdsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IdsRequest} returns this
 */
proto.loupe.services.orchard.IdsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetUserTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetUserTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUserTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxDepth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ownTeam: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetUserTeamRequest}
 */
proto.loupe.services.orchard.GetUserTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetUserTeamRequest;
  return proto.loupe.services.orchard.GetUserTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetUserTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetUserTeamRequest}
 */
proto.loupe.services.orchard.GetUserTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDepth(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetUserTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetUserTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUserTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxDepth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOwnTeam();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetUserTeamRequest} returns this
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetUserTeamRequest} returns this
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 max_depth = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.getMaxDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetUserTeamRequest} returns this
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.setMaxDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool own_team = 4;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.getOwnTeam = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetUserTeamRequest} returns this
 */
proto.loupe.services.orchard.GetUserTeamRequest.prototype.setOwnTeam = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetUserTeamResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetUserTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetUserTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetUserTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUserTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetUserTeamResponse}
 */
proto.loupe.services.orchard.GetUserTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetUserTeamResponse;
  return proto.loupe.services.orchard.GetUserTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetUserTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetUserTeamResponse}
 */
proto.loupe.services.orchard.GetUserTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTeamIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetUserTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetUserTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetUserTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUserTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string team_ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.GetUserTeamResponse.prototype.getTeamIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.GetUserTeamResponse} returns this
 */
proto.loupe.services.orchard.GetUserTeamResponse.prototype.setTeamIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GetUserTeamResponse} returns this
 */
proto.loupe.services.orchard.GetUserTeamResponse.prototype.addTeamIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetUserTeamResponse} returns this
 */
proto.loupe.services.orchard.GetUserTeamResponse.prototype.clearTeamIdsList = function() {
  return this.setTeamIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.SyncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.SyncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.SyncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SyncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    syncSince: (f = msg.getSyncSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.SyncRequest}
 */
proto.loupe.services.orchard.SyncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.SyncRequest;
  return proto.loupe.services.orchard.SyncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.SyncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.SyncRequest}
 */
proto.loupe.services.orchard.SyncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSyncSince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.SyncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.SyncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.SyncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SyncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSyncSince();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.SyncRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SyncRequest} returns this
 */
proto.loupe.services.orchard.SyncRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp sync_since = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.orchard.SyncRequest.prototype.getSyncSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.orchard.SyncRequest} returns this
*/
proto.loupe.services.orchard.SyncRequest.prototype.setSyncSince = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.SyncRequest} returns this
 */
proto.loupe.services.orchard.SyncRequest.prototype.clearSyncSince = function() {
  return this.setSyncSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.SyncRequest.prototype.hasSyncSince = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.SyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.SyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.SyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.SyncResponse}
 */
proto.loupe.services.orchard.SyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.SyncResponse;
  return proto.loupe.services.orchard.SyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.SyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.SyncResponse}
 */
proto.loupe.services.orchard.SyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.SyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.SyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.SyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.IsHierarchySyncedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.IsHierarchySyncedRequest}
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.IsHierarchySyncedRequest;
  return proto.loupe.services.orchard.IsHierarchySyncedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.IsHierarchySyncedRequest}
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.IsHierarchySyncedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.IsHierarchySyncedRequest} returns this
 */
proto.loupe.services.orchard.IsHierarchySyncedRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.IsHierarchySyncedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSynced: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.IsHierarchySyncedResponse}
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.IsHierarchySyncedResponse;
  return proto.loupe.services.orchard.IsHierarchySyncedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.IsHierarchySyncedResponse}
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSynced(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.IsHierarchySyncedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSynced();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_synced = 1;
 * @return {boolean}
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.prototype.getIsSynced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.IsHierarchySyncedResponse} returns this
 */
proto.loupe.services.orchard.IsHierarchySyncedResponse.prototype.setIsSynced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ResetHierarchyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ResetHierarchyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ResetHierarchyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ResetHierarchyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ResetHierarchyRequest}
 */
proto.loupe.services.orchard.ResetHierarchyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ResetHierarchyRequest;
  return proto.loupe.services.orchard.ResetHierarchyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ResetHierarchyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ResetHierarchyRequest}
 */
proto.loupe.services.orchard.ResetHierarchyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ResetHierarchyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ResetHierarchyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ResetHierarchyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ResetHierarchyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.ResetHierarchyRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.ResetHierarchyRequest} returns this
 */
proto.loupe.services.orchard.ResetHierarchyRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ResetHierarchyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ResetHierarchyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ResetHierarchyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ResetHierarchyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ResetHierarchyResponse}
 */
proto.loupe.services.orchard.ResetHierarchyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ResetHierarchyResponse;
  return proto.loupe.services.orchard.ResetHierarchyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ResetHierarchyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ResetHierarchyResponse}
 */
proto.loupe.services.orchard.ResetHierarchyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ResetHierarchyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ResetHierarchyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ResetHierarchyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ResetHierarchyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupSyncSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupSyncSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsRequest}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupSyncSettingsRequest;
  return proto.loupe.services.orchard.GetGroupSyncSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupSyncSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsRequest}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupSyncSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupSyncSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSyncSettingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupSyncSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && src_common_tenant_tenant_pb.GroupSyncMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupSyncSettingsResponse;
  return proto.loupe.services.orchard.GetGroupSyncSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new src_common_tenant_tenant_pb.GroupSyncMetadata;
      reader.readMessage(value,src_common_tenant_tenant_pb.GroupSyncMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupSyncSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_tenant_tenant_pb.GroupSyncMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.GroupSyncStatus status = 1;
 * @return {!proto.loupe.common.tenant.GroupSyncStatus}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.tenant.GroupSyncStatus} value
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse} returns this
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.tenant.GroupSyncMetadata metadata = 2;
 * @return {?proto.loupe.common.tenant.GroupSyncMetadata}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.getMetadata = function() {
  return /** @type{?proto.loupe.common.tenant.GroupSyncMetadata} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.GroupSyncMetadata, 2));
};


/**
 * @param {?proto.loupe.common.tenant.GroupSyncMetadata|undefined} value
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse} returns this
*/
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.GetGroupSyncSettingsResponse} returns this
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSyncSettingsResponse.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ReSyncCRMRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ReSyncCRMRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ReSyncCRMRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ReSyncCRMRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ReSyncCRMRequest}
 */
proto.loupe.services.orchard.ReSyncCRMRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ReSyncCRMRequest;
  return proto.loupe.services.orchard.ReSyncCRMRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ReSyncCRMRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ReSyncCRMRequest}
 */
proto.loupe.services.orchard.ReSyncCRMRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ReSyncCRMRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ReSyncCRMRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ReSyncCRMRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ReSyncCRMRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.ReSyncCRMRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.ReSyncCRMRequest} returns this
 */
proto.loupe.services.orchard.ReSyncCRMRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ReSyncCRMResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ReSyncCRMResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ReSyncCRMResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ReSyncCRMResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ReSyncCRMResponse}
 */
proto.loupe.services.orchard.ReSyncCRMResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ReSyncCRMResponse;
  return proto.loupe.services.orchard.ReSyncCRMResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ReSyncCRMResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ReSyncCRMResponse}
 */
proto.loupe.services.orchard.ReSyncCRMResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ReSyncCRMResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ReSyncCRMResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ReSyncCRMResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ReSyncCRMResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional loupe.common.tenant.GroupSyncStatus status = 1;
 * @return {!proto.loupe.common.tenant.GroupSyncStatus}
 */
proto.loupe.services.orchard.ReSyncCRMResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.tenant.GroupSyncStatus} value
 * @return {!proto.loupe.services.orchard.ReSyncCRMResponse} returns this
 */
proto.loupe.services.orchard.ReSyncCRMResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupSyncStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupSyncStateRequest;
  return proto.loupe.services.orchard.UpdateGroupSyncStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupSyncStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.tenant.GroupSyncStatus status = 2;
 * @return {!proto.loupe.common.tenant.GroupSyncStatus}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.tenant.GroupSyncStatus} value
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupSyncStateRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupSyncStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncStateResponse}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupSyncStateResponse;
  return proto.loupe.services.orchard.UpdateGroupSyncStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncStateResponse}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupSyncStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && src_common_tenant_tenant_pb.GroupSyncMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest;
  return proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_tenant_tenant_pb.GroupSyncMetadata;
      reader.readMessage(value,src_common_tenant_tenant_pb.GroupSyncMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_tenant_tenant_pb.GroupSyncMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.tenant.GroupSyncMetadata metadata = 2;
 * @return {?proto.loupe.common.tenant.GroupSyncMetadata}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.getMetadata = function() {
  return /** @type{?proto.loupe.common.tenant.GroupSyncMetadata} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.GroupSyncMetadata, 2));
};


/**
 * @param {?proto.loupe.common.tenant.GroupSyncMetadata|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} returns this
*/
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse;
  return proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CreateSystemRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CreateSystemRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemRole: (f = msg.getSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CreateSystemRoleRequest}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CreateSystemRoleRequest;
  return proto.loupe.services.orchard.CreateSystemRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CreateSystemRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CreateSystemRoleRequest}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setSystemRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CreateSystemRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CreateSystemRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.CreateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.orchard.SystemRole system_role = 2;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.getSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 2));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.CreateSystemRoleRequest} returns this
*/
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.setSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CreateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.clearSystemRole = function() {
  return this.setSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CreateSystemRoleRequest.prototype.hasSystemRole = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CreateSystemRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CreateSystemRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemRole: (f = msg.getSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CreateSystemRoleResponse}
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CreateSystemRoleResponse;
  return proto.loupe.services.orchard.CreateSystemRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CreateSystemRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CreateSystemRoleResponse}
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setSystemRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CreateSystemRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CreateSystemRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.SystemRole system_role = 1;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.prototype.getSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 1));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.CreateSystemRoleResponse} returns this
*/
proto.loupe.services.orchard.CreateSystemRoleResponse.prototype.setSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CreateSystemRoleResponse} returns this
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.prototype.clearSystemRole = function() {
  return this.setSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CreateSystemRoleResponse.prototype.hasSystemRole = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CloneSystemRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CloneSystemRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseRoleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newSystemRole: (f = msg.getNewSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CloneSystemRoleRequest}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CloneSystemRoleRequest;
  return proto.loupe.services.orchard.CloneSystemRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CloneSystemRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CloneSystemRoleRequest}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseRoleId(value);
      break;
    case 3:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setNewSystemRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CloneSystemRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CloneSystemRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseRoleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewSystemRole();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.CloneSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string base_role_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.getBaseRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.CloneSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.setBaseRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.orchard.SystemRole new_system_role = 3;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.getNewSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 3));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.CloneSystemRoleRequest} returns this
*/
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.setNewSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CloneSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.clearNewSystemRole = function() {
  return this.setNewSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CloneSystemRoleRequest.prototype.hasNewSystemRole = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CloneSystemRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CloneSystemRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemRole: (f = msg.getSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CloneSystemRoleResponse}
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CloneSystemRoleResponse;
  return proto.loupe.services.orchard.CloneSystemRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CloneSystemRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CloneSystemRoleResponse}
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setSystemRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CloneSystemRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CloneSystemRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.SystemRole system_role = 1;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.prototype.getSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 1));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.CloneSystemRoleResponse} returns this
*/
proto.loupe.services.orchard.CloneSystemRoleResponse.prototype.setSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CloneSystemRoleResponse} returns this
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.prototype.clearSystemRole = function() {
  return this.setSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CloneSystemRoleResponse.prototype.hasSystemRole = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemRole: (f = msg.getSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f),
    baseRole: (f = msg.getBaseRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse;
  return proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setSystemRole(value);
      break;
    case 2:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setBaseRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
  f = message.getBaseRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.SystemRole system_role = 1;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.getSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 1));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} returns this
*/
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.setSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} returns this
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.clearSystemRole = function() {
  return this.setSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.hasSystemRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.orchard.SystemRole base_role = 2;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.getBaseRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 2));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} returns this
*/
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.setBaseRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse} returns this
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.clearBaseRole = function() {
  return this.setBaseRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.GetSystemRoleWithBaseRoleResponse.prototype.hasBaseRole = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetSystemRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetSystemRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetSystemRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetSystemRolesRequest}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetSystemRolesRequest;
  return proto.loupe.services.orchard.GetSystemRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetSystemRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetSystemRolesRequest}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetSystemRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetSystemRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetSystemRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetSystemRolesRequest} returns this
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetSystemRolesRequest} returns this
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetSystemRolesRequest} returns this
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetSystemRolesRequest} returns this
 */
proto.loupe.services.orchard.GetSystemRolesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetSystemRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetSystemRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetSystemRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetSystemRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemRolesList: jspb.Message.toObjectList(msg.getSystemRolesList(),
    src_common_orchard_system_role_pb.SystemRole.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetSystemRolesResponse}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetSystemRolesResponse;
  return proto.loupe.services.orchard.GetSystemRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetSystemRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetSystemRolesResponse}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.addSystemRoles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetSystemRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetSystemRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetSystemRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.orchard.SystemRole system_roles = 1;
 * @return {!Array<!proto.loupe.common.orchard.SystemRole>}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.getSystemRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.SystemRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.SystemRole>} value
 * @return {!proto.loupe.services.orchard.GetSystemRolesResponse} returns this
*/
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.setSystemRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.SystemRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.addSystemRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.SystemRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetSystemRolesResponse} returns this
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.clearSystemRolesList = function() {
  return this.setSystemRolesList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetSystemRolesResponse} returns this
 */
proto.loupe.services.orchard.GetSystemRolesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateSystemRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateSystemRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemRole: (f = msg.getSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f),
    onlyFieldsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateSystemRoleRequest;
  return proto.loupe.services.orchard.UpdateSystemRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateSystemRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setSystemRole(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnlyFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateSystemRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateSystemRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
  f = message.getOnlyFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.orchard.SystemRole system_role = 2;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.getSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 2));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest} returns this
*/
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.setSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.clearSystemRole = function() {
  return this.setSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.hasSystemRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string only_fields = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.getOnlyFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.setOnlyFieldsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.addOnlyFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleRequest} returns this
 */
proto.loupe.services.orchard.UpdateSystemRoleRequest.prototype.clearOnlyFieldsList = function() {
  return this.setOnlyFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateSystemRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateSystemRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemRole: (f = msg.getSystemRole()) && src_common_orchard_system_role_pb.SystemRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleResponse}
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateSystemRoleResponse;
  return proto.loupe.services.orchard.UpdateSystemRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateSystemRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleResponse}
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.setSystemRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateSystemRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateSystemRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.SystemRole system_role = 1;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.prototype.getSystemRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 1));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleResponse} returns this
*/
proto.loupe.services.orchard.UpdateSystemRoleResponse.prototype.setSystemRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateSystemRoleResponse} returns this
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.prototype.clearSystemRole = function() {
  return this.setSystemRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateSystemRoleResponse.prototype.hasSystemRole = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpsertCRMRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpsertCRMRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmRolesList: jspb.Message.toObjectList(msg.getCrmRolesList(),
    src_common_orchard_crm_role_pb.CRMRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesRequest}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpsertCRMRolesRequest;
  return proto.loupe.services.orchard.UpsertCRMRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpsertCRMRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesRequest}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_orchard_crm_role_pb.CRMRole;
      reader.readMessage(value,src_common_orchard_crm_role_pb.CRMRole.deserializeBinaryFromReader);
      msg.addCrmRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpsertCRMRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpsertCRMRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_orchard_crm_role_pb.CRMRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated loupe.common.orchard.CRMRole crm_roles = 2;
 * @return {!Array<!proto.loupe.common.orchard.CRMRole>}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.getCrmRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.CRMRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_crm_role_pb.CRMRole, 2));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.CRMRole>} value
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesRequest} returns this
*/
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.setCrmRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.orchard.CRMRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.CRMRole}
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.addCrmRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.orchard.CRMRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.UpsertCRMRolesRequest.prototype.clearCrmRolesList = function() {
  return this.setCrmRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpsertCRMRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpsertCRMRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesResponse}
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpsertCRMRolesResponse;
  return proto.loupe.services.orchard.UpsertCRMRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpsertCRMRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpsertCRMRolesResponse}
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpsertCRMRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpsertCRMRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertCRMRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetCRMRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetCRMRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetCRMRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetCRMRolesRequest}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetCRMRolesRequest;
  return proto.loupe.services.orchard.GetCRMRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetCRMRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetCRMRolesRequest}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetCRMRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetCRMRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetCRMRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.GetCRMRolesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetCRMRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetCRMRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetCRMRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetCRMRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    crmRolesList: jspb.Message.toObjectList(msg.getCrmRolesList(),
    src_common_orchard_crm_role_pb.CRMRole.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetCRMRolesResponse}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetCRMRolesResponse;
  return proto.loupe.services.orchard.GetCRMRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetCRMRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetCRMRolesResponse}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_crm_role_pb.CRMRole;
      reader.readMessage(value,src_common_orchard_crm_role_pb.CRMRole.deserializeBinaryFromReader);
      msg.addCrmRoles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetCRMRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetCRMRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetCRMRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrmRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_crm_role_pb.CRMRole.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.orchard.CRMRole crm_roles = 1;
 * @return {!Array<!proto.loupe.common.orchard.CRMRole>}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.getCrmRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.CRMRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_crm_role_pb.CRMRole, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.CRMRole>} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesResponse} returns this
*/
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.setCrmRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.CRMRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.CRMRole}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.addCrmRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.CRMRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetCRMRolesResponse} returns this
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.clearCrmRolesList = function() {
  return this.setCrmRolesList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesResponse} returns this
 */
proto.loupe.services.orchard.GetCRMRolesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest;
  return proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} returns this
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    crmRolesList: jspb.Message.toObjectList(msg.getCrmRolesList(),
    src_common_orchard_crm_role_pb.CRMRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse;
  return proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_crm_role_pb.CRMRole;
      reader.readMessage(value,src_common_orchard_crm_role_pb.CRMRole.deserializeBinaryFromReader);
      msg.addCrmRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrmRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_crm_role_pb.CRMRole.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.CRMRole crm_roles = 1;
 * @return {!Array<!proto.loupe.common.orchard.CRMRole>}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.prototype.getCrmRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.CRMRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_crm_role_pb.CRMRole, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.CRMRole>} value
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse} returns this
*/
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.prototype.setCrmRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.CRMRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.CRMRole}
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.prototype.addCrmRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.CRMRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse} returns this
 */
proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse.prototype.clearCrmRolesList = function() {
  return this.setCrmRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.InsertGroupViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.InsertGroupViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupViewer: (f = msg.getGroupViewer()) && src_common_orchard_group_viewer_pb.GroupViewer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.InsertGroupViewerRequest;
  return proto.loupe.services.orchard.InsertGroupViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.InsertGroupViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 4:
      var value = new src_common_orchard_group_viewer_pb.GroupViewer;
      reader.readMessage(value,src_common_orchard_group_viewer_pb.GroupViewer.deserializeBinaryFromReader);
      msg.setGroupViewer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.InsertGroupViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.InsertGroupViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupViewer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_orchard_group_viewer_pb.GroupViewer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string person_id = 3;
 * @return {string}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional loupe.common.orchard.GroupViewer group_viewer = 4;
 * @return {?proto.loupe.common.orchard.GroupViewer}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.getGroupViewer = function() {
  return /** @type{?proto.loupe.common.orchard.GroupViewer} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_viewer_pb.GroupViewer, 4));
};


/**
 * @param {?proto.loupe.common.orchard.GroupViewer|undefined} value
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest} returns this
*/
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.setGroupViewer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.InsertGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.clearGroupViewer = function() {
  return this.setGroupViewer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.InsertGroupViewerRequest.prototype.hasGroupViewer = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.InsertGroupViewerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.InsertGroupViewerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupViewer: (f = msg.getGroupViewer()) && src_common_orchard_group_viewer_pb.GroupViewer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.InsertGroupViewerResponse}
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.InsertGroupViewerResponse;
  return proto.loupe.services.orchard.InsertGroupViewerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.InsertGroupViewerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.InsertGroupViewerResponse}
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_viewer_pb.GroupViewer;
      reader.readMessage(value,src_common_orchard_group_viewer_pb.GroupViewer.deserializeBinaryFromReader);
      msg.setGroupViewer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.InsertGroupViewerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.InsertGroupViewerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupViewer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_group_viewer_pb.GroupViewer.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.GroupViewer group_viewer = 1;
 * @return {?proto.loupe.common.orchard.GroupViewer}
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.prototype.getGroupViewer = function() {
  return /** @type{?proto.loupe.common.orchard.GroupViewer} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_viewer_pb.GroupViewer, 1));
};


/**
 * @param {?proto.loupe.common.orchard.GroupViewer|undefined} value
 * @return {!proto.loupe.services.orchard.InsertGroupViewerResponse} returns this
*/
proto.loupe.services.orchard.InsertGroupViewerResponse.prototype.setGroupViewer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.InsertGroupViewerResponse} returns this
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.prototype.clearGroupViewer = function() {
  return this.setGroupViewer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.InsertGroupViewerResponse.prototype.hasGroupViewer = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetGroupViewersResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupViewersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupViewersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupViewersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    viewerIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    viewersList: jspb.Message.toObjectList(msg.getViewersList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupViewersResponse;
  return proto.loupe.services.orchard.GetGroupViewersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupViewersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addViewerIds(value);
      break;
    case 3:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addViewers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupViewersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupViewersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupViewersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getViewerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getViewersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string viewer_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.getViewerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.setViewerIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.addViewerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.clearViewerIdsList = function() {
  return this.setViewerIdsList([]);
};


/**
 * repeated loupe.common.orchard.Person viewers = 3;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.getViewersList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 3));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse} returns this
*/
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.setViewersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.addViewers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetGroupViewersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupViewersResponse.prototype.clearViewersList = function() {
  return this.setViewersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetPersonViewableGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    src_common_orchard_group_pb.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetPersonViewableGroupsResponse;
  return proto.loupe.services.orchard.GetPersonViewableGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupIds(value);
      break;
    case 3:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetPersonViewableGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional string person_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string group_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.getGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.setGroupIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.addGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.clearGroupIdsList = function() {
  return this.setGroupIdsList([]);
};


/**
 * repeated loupe.common.orchard.Group groups = 3;
 * @return {!Array<!proto.loupe.common.orchard.Group>}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_group_pb.Group, 3));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Group>} value
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} returns this
*/
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.orchard.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.orchard.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetPersonViewableGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetPersonViewableGroupsResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupViewer: (f = msg.getGroupViewer()) && src_common_orchard_group_viewer_pb.GroupViewer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupViewerRequest;
  return proto.loupe.services.orchard.UpdateGroupViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 4:
      var value = new src_common_orchard_group_viewer_pb.GroupViewer;
      reader.readMessage(value,src_common_orchard_group_viewer_pb.GroupViewer.deserializeBinaryFromReader);
      msg.setGroupViewer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupViewer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_orchard_group_viewer_pb.GroupViewer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string person_id = 3;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional loupe.common.orchard.GroupViewer group_viewer = 4;
 * @return {?proto.loupe.common.orchard.GroupViewer}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.getGroupViewer = function() {
  return /** @type{?proto.loupe.common.orchard.GroupViewer} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_viewer_pb.GroupViewer, 4));
};


/**
 * @param {?proto.loupe.common.orchard.GroupViewer|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest} returns this
*/
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.setGroupViewer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.clearGroupViewer = function() {
  return this.setGroupViewer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateGroupViewerRequest.prototype.hasGroupViewer = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupViewerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupViewerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupViewer: (f = msg.getGroupViewer()) && src_common_orchard_group_viewer_pb.GroupViewer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerResponse}
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupViewerResponse;
  return proto.loupe.services.orchard.UpdateGroupViewerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupViewerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerResponse}
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_viewer_pb.GroupViewer;
      reader.readMessage(value,src_common_orchard_group_viewer_pb.GroupViewer.deserializeBinaryFromReader);
      msg.setGroupViewer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupViewerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupViewerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupViewer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_group_viewer_pb.GroupViewer.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.GroupViewer group_viewer = 1;
 * @return {?proto.loupe.common.orchard.GroupViewer}
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.prototype.getGroupViewer = function() {
  return /** @type{?proto.loupe.common.orchard.GroupViewer} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_viewer_pb.GroupViewer, 1));
};


/**
 * @param {?proto.loupe.common.orchard.GroupViewer|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerResponse} returns this
*/
proto.loupe.services.orchard.UpdateGroupViewerResponse.prototype.setGroupViewer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateGroupViewerResponse} returns this
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.prototype.clearGroupViewer = function() {
  return this.setGroupViewer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateGroupViewerResponse.prototype.hasGroupViewer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CreatePersonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CreatePersonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreatePersonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CreatePersonRequest}
 */
proto.loupe.services.orchard.CreatePersonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CreatePersonRequest;
  return proto.loupe.services.orchard.CreatePersonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CreatePersonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CreatePersonRequest}
 */
proto.loupe.services.orchard.CreatePersonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CreatePersonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CreatePersonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreatePersonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.CreatePersonRequest} returns this
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.orchard.Person person = 2;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 2));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.orchard.CreatePersonRequest} returns this
*/
proto.loupe.services.orchard.CreatePersonRequest.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CreatePersonRequest} returns this
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CreatePersonRequest.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CreatePersonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CreatePersonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CreatePersonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreatePersonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CreatePersonResponse}
 */
proto.loupe.services.orchard.CreatePersonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CreatePersonResponse;
  return proto.loupe.services.orchard.CreatePersonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CreatePersonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CreatePersonResponse}
 */
proto.loupe.services.orchard.CreatePersonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CreatePersonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CreatePersonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CreatePersonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreatePersonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Person person = 1;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.CreatePersonResponse.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.orchard.CreatePersonResponse} returns this
*/
proto.loupe.services.orchard.CreatePersonResponse.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CreatePersonResponse} returns this
 */
proto.loupe.services.orchard.CreatePersonResponse.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CreatePersonResponse.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.UpsertPeopleRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpsertPeopleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpsertPeopleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertPeopleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpsertPeopleRequest}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpsertPeopleRequest;
  return proto.loupe.services.orchard.UpsertPeopleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpsertPeopleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpsertPeopleRequest}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpsertPeopleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpsertPeopleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertPeopleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpsertPeopleRequest} returns this
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated loupe.common.orchard.Person people = 2;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 2));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.UpsertPeopleRequest} returns this
*/
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.UpsertPeopleRequest} returns this
 */
proto.loupe.services.orchard.UpsertPeopleRequest.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpsertPeopleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpsertPeopleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpsertPeopleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertPeopleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpsertPeopleResponse}
 */
proto.loupe.services.orchard.UpsertPeopleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpsertPeopleResponse;
  return proto.loupe.services.orchard.UpsertPeopleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpsertPeopleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpsertPeopleResponse}
 */
proto.loupe.services.orchard.UpsertPeopleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpsertPeopleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpsertPeopleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpsertPeopleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpsertPeopleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.SearchPeopleRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.SearchPeopleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.SearchPeopleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SearchPeopleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.orchard.PersonFilter.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hydrateCrmRoles: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hydrateRoles: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest}
 */
proto.loupe.services.orchard.SearchPeopleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.SearchPeopleRequest;
  return proto.loupe.services.orchard.SearchPeopleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.SearchPeopleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest}
 */
proto.loupe.services.orchard.SearchPeopleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var value = new proto.loupe.services.orchard.PersonFilter;
      reader.readMessage(value,proto.loupe.services.orchard.PersonFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHydrateCrmRoles(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHydrateRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.SearchPeopleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.SearchPeopleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SearchPeopleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.orchard.PersonFilter.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHydrateCrmRoles();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHydrateRoles();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PersonFilter filters = 3;
 * @return {!Array<!proto.loupe.services.orchard.PersonFilter>}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.orchard.PersonFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.orchard.PersonFilter, 3));
};


/**
 * @param {!Array<!proto.loupe.services.orchard.PersonFilter>} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
*/
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.orchard.PersonFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.PersonFilter}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.orchard.PersonFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool hydrate_crm_roles = 6;
 * @return {boolean}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getHydrateCrmRoles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setHydrateCrmRoles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool hydrate_roles = 7;
 * @return {boolean}
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.getHydrateRoles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.SearchPeopleRequest} returns this
 */
proto.loupe.services.orchard.SearchPeopleRequest.prototype.setHydrateRoles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.PersonFilter.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.PersonFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.PersonFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.PersonFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.PersonFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valuesList: msg.getValuesList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.PersonFilter}
 */
proto.loupe.services.orchard.PersonFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.PersonFilter;
  return proto.loupe.services.orchard.PersonFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.PersonFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.PersonFilter}
 */
proto.loupe.services.orchard.PersonFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.orchard.PersonField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.orchard.FilterOp} */ (reader.readEnum());
      msg.setOp(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.PersonFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.PersonFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.PersonFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.PersonFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValuesList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.orchard.PersonField field = 1;
 * @return {!proto.loupe.common.orchard.PersonField}
 */
proto.loupe.services.orchard.PersonFilter.prototype.getField = function() {
  return /** @type {!proto.loupe.common.orchard.PersonField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.orchard.PersonField} value
 * @return {!proto.loupe.services.orchard.PersonFilter} returns this
 */
proto.loupe.services.orchard.PersonFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional FilterOp op = 2;
 * @return {!proto.loupe.services.orchard.FilterOp}
 */
proto.loupe.services.orchard.PersonFilter.prototype.getOp = function() {
  return /** @type {!proto.loupe.services.orchard.FilterOp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.orchard.FilterOp} value
 * @return {!proto.loupe.services.orchard.PersonFilter} returns this
 */
proto.loupe.services.orchard.PersonFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated bytes values = 3;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.loupe.services.orchard.PersonFilter.prototype.getValuesList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * repeated bytes values = 3;
 * This is a type-conversion wrapper around `getValuesList()`
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.PersonFilter.prototype.getValuesList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getValuesList()));
};


/**
 * repeated bytes values = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValuesList()`
 * @return {!Array<!Uint8Array>}
 */
proto.loupe.services.orchard.PersonFilter.prototype.getValuesList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getValuesList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.loupe.services.orchard.PersonFilter} returns this
 */
proto.loupe.services.orchard.PersonFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.PersonFilter} returns this
 */
proto.loupe.services.orchard.PersonFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.PersonFilter} returns this
 */
proto.loupe.services.orchard.PersonFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.SearchPeopleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.SearchPeopleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.SearchPeopleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SearchPeopleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.SearchPeopleResponse}
 */
proto.loupe.services.orchard.SearchPeopleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.SearchPeopleResponse;
  return proto.loupe.services.orchard.SearchPeopleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.SearchPeopleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.SearchPeopleResponse}
 */
proto.loupe.services.orchard.SearchPeopleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.SearchPeopleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.SearchPeopleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SearchPeopleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.orchard.Person people = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.SearchPeopleResponse} returns this
*/
proto.loupe.services.orchard.SearchPeopleResponse.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.SearchPeopleResponse} returns this
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.SearchPeopleResponse} returns this
 */
proto.loupe.services.orchard.SearchPeopleResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupMembersRequest}
 */
proto.loupe.services.orchard.GetGroupMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupMembersRequest;
  return proto.loupe.services.orchard.GetGroupMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupMembersRequest}
 */
proto.loupe.services.orchard.GetGroupMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupMembersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupMembersRequest} returns this
 */
proto.loupe.services.orchard.GetGroupMembersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupMembersRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupMembersRequest} returns this
 */
proto.loupe.services.orchard.GetGroupMembersRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetGroupMembersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupMembersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupMembersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupMembersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupMembersResponse}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupMembersResponse;
  return proto.loupe.services.orchard.GetGroupMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupMembersResponse}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupMembersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupMembersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupMembersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated loupe.common.orchard.Person members = 2;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 2));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.GetGroupMembersResponse} returns this
*/
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetGroupMembersResponse} returns this
 */
proto.loupe.services.orchard.GetGroupMembersResponse.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetUngroupedPeopleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleRequest}
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetUngroupedPeopleRequest;
  return proto.loupe.services.orchard.GetUngroupedPeopleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleRequest}
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetUngroupedPeopleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} returns this
 */
proto.loupe.services.orchard.GetUngroupedPeopleRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetUngroupedPeopleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleResponse}
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetUngroupedPeopleResponse;
  return proto.loupe.services.orchard.GetUngroupedPeopleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleResponse}
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetUngroupedPeopleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.Person people = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleResponse} returns this
*/
proto.loupe.services.orchard.GetUngroupedPeopleResponse.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetUngroupedPeopleResponse} returns this
 */
proto.loupe.services.orchard.GetUngroupedPeopleResponse.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetVirtualUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetVirtualUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetVirtualUsersRequest}
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetVirtualUsersRequest;
  return proto.loupe.services.orchard.GetVirtualUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetVirtualUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetVirtualUsersRequest}
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetVirtualUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetVirtualUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetVirtualUsersRequest} returns this
 */
proto.loupe.services.orchard.GetVirtualUsersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetVirtualUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetVirtualUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetVirtualUsersResponse}
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetVirtualUsersResponse;
  return proto.loupe.services.orchard.GetVirtualUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetVirtualUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetVirtualUsersResponse}
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetVirtualUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetVirtualUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.Person people = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.GetVirtualUsersResponse} returns this
*/
proto.loupe.services.orchard.GetVirtualUsersResponse.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetVirtualUsersResponse} returns this
 */
proto.loupe.services.orchard.GetVirtualUsersResponse.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ConvertVirtualUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ConvertVirtualUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersRequest}
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ConvertVirtualUsersRequest;
  return proto.loupe.services.orchard.ConvertVirtualUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ConvertVirtualUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersRequest}
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ConvertVirtualUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ConvertVirtualUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersRequest} returns this
 */
proto.loupe.services.orchard.ConvertVirtualUsersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ConvertVirtualUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ConvertVirtualUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersResponse}
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ConvertVirtualUsersResponse;
  return proto.loupe.services.orchard.ConvertVirtualUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ConvertVirtualUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersResponse}
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ConvertVirtualUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ConvertVirtualUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.Person people = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersResponse} returns this
*/
proto.loupe.services.orchard.ConvertVirtualUsersResponse.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.ConvertVirtualUsersResponse} returns this
 */
proto.loupe.services.orchard.ConvertVirtualUsersResponse.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.UpdatePersonRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdatePersonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdatePersonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f),
    onlyFieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest}
 */
proto.loupe.services.orchard.UpdatePersonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdatePersonRequest;
  return proto.loupe.services.orchard.UpdatePersonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdatePersonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest}
 */
proto.loupe.services.orchard.UpdatePersonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 3:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnlyFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdatePersonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdatePersonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getOnlyFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string person_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.orchard.Person person = 3;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 3));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
*/
proto.loupe.services.orchard.UpdatePersonRequest.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string only_fields = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.getOnlyFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.setOnlyFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.addOnlyFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.UpdatePersonRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonRequest.prototype.clearOnlyFieldsList = function() {
  return this.setOnlyFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdatePersonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdatePersonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdatePersonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdatePersonResponse}
 */
proto.loupe.services.orchard.UpdatePersonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdatePersonResponse;
  return proto.loupe.services.orchard.UpdatePersonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdatePersonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdatePersonResponse}
 */
proto.loupe.services.orchard.UpdatePersonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdatePersonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdatePersonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdatePersonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Person person = 1;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.UpdatePersonResponse.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.orchard.UpdatePersonResponse} returns this
*/
proto.loupe.services.orchard.UpdatePersonResponse.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdatePersonResponse} returns this
 */
proto.loupe.services.orchard.UpdatePersonResponse.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdatePersonResponse.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdatePersonGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdatePersonGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdatePersonGroupsRequest}
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdatePersonGroupsRequest;
  return proto.loupe.services.orchard.UpdatePersonGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdatePersonGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdatePersonGroupsRequest}
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdatePersonGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdatePersonGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdatePersonGroupsRequest} returns this
 */
proto.loupe.services.orchard.UpdatePersonGroupsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdatePersonGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdatePersonGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdatePersonGroupsResponse}
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdatePersonGroupsResponse;
  return proto.loupe.services.orchard.UpdatePersonGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdatePersonGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdatePersonGroupsResponse}
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdatePersonGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdatePersonGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdatePersonGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CreateGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CreateGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    group: (f = msg.getGroup()) && src_common_orchard_group_pb.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CreateGroupRequest}
 */
proto.loupe.services.orchard.CreateGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CreateGroupRequest;
  return proto.loupe.services.orchard.CreateGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CreateGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CreateGroupRequest}
 */
proto.loupe.services.orchard.CreateGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CreateGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CreateGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.CreateGroupRequest} returns this
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.orchard.Group group = 2;
 * @return {?proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.getGroup = function() {
  return /** @type{?proto.loupe.common.orchard.Group} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_pb.Group, 2));
};


/**
 * @param {?proto.loupe.common.orchard.Group|undefined} value
 * @return {!proto.loupe.services.orchard.CreateGroupRequest} returns this
*/
proto.loupe.services.orchard.CreateGroupRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CreateGroupRequest} returns this
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CreateGroupRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.CreateGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.CreateGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.CreateGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && src_common_orchard_group_pb.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.CreateGroupResponse}
 */
proto.loupe.services.orchard.CreateGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.CreateGroupResponse;
  return proto.loupe.services.orchard.CreateGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.CreateGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.CreateGroupResponse}
 */
proto.loupe.services.orchard.CreateGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.CreateGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.CreateGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.CreateGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.CreateGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Group group = 1;
 * @return {?proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.CreateGroupResponse.prototype.getGroup = function() {
  return /** @type{?proto.loupe.common.orchard.Group} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_pb.Group, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Group|undefined} value
 * @return {!proto.loupe.services.orchard.CreateGroupResponse} returns this
*/
proto.loupe.services.orchard.CreateGroupResponse.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.CreateGroupResponse} returns this
 */
proto.loupe.services.orchard.CreateGroupResponse.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.CreateGroupResponse.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupsRequest}
 */
proto.loupe.services.orchard.GetGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupsRequest;
  return proto.loupe.services.orchard.GetGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupsRequest}
 */
proto.loupe.services.orchard.GetGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupsRequest} returns this
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupsRequest} returns this
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetGroupsRequest} returns this
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetGroupsRequest} returns this
 */
proto.loupe.services.orchard.GetGroupsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    src_common_orchard_group_pb.Group.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupsResponse}
 */
proto.loupe.services.orchard.GetGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupsResponse;
  return proto.loupe.services.orchard.GetGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupsResponse}
 */
proto.loupe.services.orchard.GetGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.orchard.Group groups = 1;
 * @return {!Array<!proto.loupe.common.orchard.Group>}
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_group_pb.Group, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Group>} value
 * @return {!proto.loupe.services.orchard.GetGroupsResponse} returns this
*/
proto.loupe.services.orchard.GetGroupsResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetGroupsResponse} returns this
 */
proto.loupe.services.orchard.GetGroupsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupSubTreeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxDepth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hydrateUsers: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hydrateCrmRoles: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    simplify: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    activeUsers: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    useManagerNames: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    excludeManagerUsers: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    viewableGroupsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupSubTreeRequest;
  return proto.loupe.services.orchard.GetGroupSubTreeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDepth(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHydrateUsers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHydrateCrmRoles(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSimplify(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveUsers(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseManagerNames(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeManagerUsers(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addViewableGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupSubTreeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxDepth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHydrateUsers();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHydrateCrmRoles();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSimplify();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getActiveUsers();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getUseManagerNames();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getExcludeManagerUsers();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getViewableGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 max_depth = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getMaxDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setMaxDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool hydrate_users = 4;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getHydrateUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setHydrateUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool hydrate_crm_roles = 5;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getHydrateCrmRoles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setHydrateCrmRoles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool simplify = 6;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getSimplify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setSimplify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool active_users = 7;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getActiveUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setActiveUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool use_manager_names = 8;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getUseManagerNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setUseManagerNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool exclude_manager_users = 9;
 * @return {boolean}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getExcludeManagerUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setExcludeManagerUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated string viewable_groups = 10;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.getViewableGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.setViewableGroupsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.addViewableGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeRequest} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeRequest.prototype.clearViewableGroupsList = function() {
  return this.setViewableGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GroupWithMembers.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GroupWithMembers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GroupWithMembers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GroupWithMembers.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && src_common_orchard_group_pb.Group.toObject(includeInstance, f),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.services.orchard.GroupWithMembers.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GroupWithMembers}
 */
proto.loupe.services.orchard.GroupWithMembers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GroupWithMembers;
  return proto.loupe.services.orchard.GroupWithMembers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GroupWithMembers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GroupWithMembers}
 */
proto.loupe.services.orchard.GroupWithMembers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 2:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 3:
      var value = new proto.loupe.services.orchard.GroupWithMembers;
      reader.readMessage(value,proto.loupe.services.orchard.GroupWithMembers.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GroupWithMembers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GroupWithMembers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GroupWithMembers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.orchard.GroupWithMembers.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Group group = 1;
 * @return {?proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.getGroup = function() {
  return /** @type{?proto.loupe.common.orchard.Group} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_pb.Group, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Group|undefined} value
 * @return {!proto.loupe.services.orchard.GroupWithMembers} returns this
*/
proto.loupe.services.orchard.GroupWithMembers.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.GroupWithMembers} returns this
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.orchard.Person members = 2;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 2));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.GroupWithMembers} returns this
*/
proto.loupe.services.orchard.GroupWithMembers.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GroupWithMembers} returns this
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * repeated GroupWithMembers children = 3;
 * @return {!Array<!proto.loupe.services.orchard.GroupWithMembers>}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.services.orchard.GroupWithMembers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.orchard.GroupWithMembers, 3));
};


/**
 * @param {!Array<!proto.loupe.services.orchard.GroupWithMembers>} value
 * @return {!proto.loupe.services.orchard.GroupWithMembers} returns this
*/
proto.loupe.services.orchard.GroupWithMembers.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.orchard.GroupWithMembers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GroupWithMembers}
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.orchard.GroupWithMembers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GroupWithMembers} returns this
 */
proto.loupe.services.orchard.GroupWithMembers.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GroupSubtreeRoot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GroupSubtreeRoot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GroupSubtreeRoot.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subTree: (f = msg.getSubTree()) && proto.loupe.services.orchard.GroupWithMembers.toObject(includeInstance, f),
    depth: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GroupSubtreeRoot;
  return proto.loupe.services.orchard.GroupSubtreeRoot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GroupSubtreeRoot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = new proto.loupe.services.orchard.GroupWithMembers;
      reader.readMessage(value,proto.loupe.services.orchard.GroupWithMembers.deserializeBinaryFromReader);
      msg.setSubTree(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GroupSubtreeRoot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GroupSubtreeRoot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GroupSubtreeRoot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubTree();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.orchard.GroupWithMembers.serializeBinaryToWriter
    );
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot} returns this
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GroupWithMembers sub_tree = 2;
 * @return {?proto.loupe.services.orchard.GroupWithMembers}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.getSubTree = function() {
  return /** @type{?proto.loupe.services.orchard.GroupWithMembers} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.orchard.GroupWithMembers, 2));
};


/**
 * @param {?proto.loupe.services.orchard.GroupWithMembers|undefined} value
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot} returns this
*/
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.setSubTree = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot} returns this
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.clearSubTree = function() {
  return this.setSubTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.hasSubTree = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 depth = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot} returns this
 */
proto.loupe.services.orchard.GroupSubtreeRoot.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetGroupSubTreeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootsList: jspb.Message.toObjectList(msg.getRootsList(),
    proto.loupe.services.orchard.GroupSubtreeRoot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeResponse}
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetGroupSubTreeResponse;
  return proto.loupe.services.orchard.GetGroupSubTreeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeResponse}
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.orchard.GroupSubtreeRoot;
      reader.readMessage(value,proto.loupe.services.orchard.GroupSubtreeRoot.deserializeBinaryFromReader);
      msg.addRoots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetGroupSubTreeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.orchard.GroupSubtreeRoot.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupSubtreeRoot roots = 1;
 * @return {!Array<!proto.loupe.services.orchard.GroupSubtreeRoot>}
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.prototype.getRootsList = function() {
  return /** @type{!Array<!proto.loupe.services.orchard.GroupSubtreeRoot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.orchard.GroupSubtreeRoot, 1));
};


/**
 * @param {!Array<!proto.loupe.services.orchard.GroupSubtreeRoot>} value
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeResponse} returns this
*/
proto.loupe.services.orchard.GetGroupSubTreeResponse.prototype.setRootsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.orchard.GroupSubtreeRoot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GroupSubtreeRoot}
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.prototype.addRoots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.orchard.GroupSubtreeRoot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetGroupSubTreeResponse} returns this
 */
proto.loupe.services.orchard.GetGroupSubTreeResponse.prototype.clearRootsList = function() {
  return this.setRootsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.UpdateGroupRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    group: (f = msg.getGroup()) && src_common_orchard_group_pb.Group.toObject(includeInstance, f),
    onlyFieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest}
 */
proto.loupe.services.orchard.UpdateGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupRequest;
  return proto.loupe.services.orchard.UpdateGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest}
 */
proto.loupe.services.orchard.UpdateGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnlyFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
  f = message.getOnlyFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.orchard.Group group = 3;
 * @return {?proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.getGroup = function() {
  return /** @type{?proto.loupe.common.orchard.Group} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_pb.Group, 3));
};


/**
 * @param {?proto.loupe.common.orchard.Group|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
*/
proto.loupe.services.orchard.UpdateGroupRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string only_fields = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.getOnlyFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.setOnlyFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.addOnlyFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.UpdateGroupRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupRequest.prototype.clearOnlyFieldsList = function() {
  return this.setOnlyFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && src_common_orchard_group_pb.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupResponse}
 */
proto.loupe.services.orchard.UpdateGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupResponse;
  return proto.loupe.services.orchard.UpdateGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupResponse}
 */
proto.loupe.services.orchard.UpdateGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Group group = 1;
 * @return {?proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.UpdateGroupResponse.prototype.getGroup = function() {
  return /** @type{?proto.loupe.common.orchard.Group} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_group_pb.Group, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Group|undefined} value
 * @return {!proto.loupe.services.orchard.UpdateGroupResponse} returns this
*/
proto.loupe.services.orchard.UpdateGroupResponse.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.UpdateGroupResponse} returns this
 */
proto.loupe.services.orchard.UpdateGroupResponse.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.UpdateGroupResponse.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupTypesRequest}
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupTypesRequest;
  return proto.loupe.services.orchard.UpdateGroupTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupTypesRequest}
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupTypesRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_type = 2;
 * @return {string}
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.prototype.getGroupType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.UpdateGroupTypesRequest} returns this
 */
proto.loupe.services.orchard.UpdateGroupTypesRequest.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.UpdateGroupTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.UpdateGroupTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.UpdateGroupTypesResponse}
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.UpdateGroupTypesResponse;
  return proto.loupe.services.orchard.UpdateGroupTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.UpdateGroupTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.UpdateGroupTypesResponse}
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.UpdateGroupTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.UpdateGroupTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.UpdateGroupTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetLegacyTeamStructureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetLegacyTeamStructureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureRequest}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetLegacyTeamStructureRequest;
  return proto.loupe.services.orchard.GetLegacyTeamStructureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetLegacyTeamStructureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureRequest}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetLegacyTeamStructureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetLegacyTeamStructureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureRequest} returns this
 */
proto.loupe.services.orchard.GetLegacyTeamStructureRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetLegacyTeamStructureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    legacyTeamList: jspb.Message.toObjectList(msg.getLegacyTeamList(),
    proto.loupe.services.orchard.GroupWithMembers.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetLegacyTeamStructureResponse;
  return proto.loupe.services.orchard.GetLegacyTeamStructureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.orchard.GroupWithMembers;
      reader.readMessage(value,proto.loupe.services.orchard.GroupWithMembers.deserializeBinaryFromReader);
      msg.addLegacyTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetLegacyTeamStructureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLegacyTeamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.orchard.GroupWithMembers.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupWithMembers legacy_team = 1;
 * @return {!Array<!proto.loupe.services.orchard.GroupWithMembers>}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.prototype.getLegacyTeamList = function() {
  return /** @type{!Array<!proto.loupe.services.orchard.GroupWithMembers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.orchard.GroupWithMembers, 1));
};


/**
 * @param {!Array<!proto.loupe.services.orchard.GroupWithMembers>} value
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse} returns this
*/
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.prototype.setLegacyTeamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.orchard.GroupWithMembers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GroupWithMembers}
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.prototype.addLegacyTeam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.orchard.GroupWithMembers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetLegacyTeamStructureResponse} returns this
 */
proto.loupe.services.orchard.GetLegacyTeamStructureResponse.prototype.clearLegacyTeamList = function() {
  return this.setLegacyTeamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest;
  return proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest} returns this
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastModifiedTs: (f = msg.getLastModifiedTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse;
  return proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModifiedTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastModifiedTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_modified_ts = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.prototype.getLastModifiedTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse} returns this
*/
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.prototype.setLastModifiedTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse} returns this
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.prototype.clearLastModifiedTs = function() {
  return this.setLastModifiedTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.GetTenantGroupsLastModifiedTSResponse.prototype.hasLastModifiedTs = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ClonePersonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ClonePersonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ClonePersonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentTenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newTenantId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ClonePersonRequest}
 */
proto.loupe.services.orchard.ClonePersonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ClonePersonRequest;
  return proto.loupe.services.orchard.ClonePersonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ClonePersonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ClonePersonRequest}
 */
proto.loupe.services.orchard.ClonePersonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ClonePersonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ClonePersonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ClonePersonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string person_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.ClonePersonRequest} returns this
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string current_tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.getCurrentTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.ClonePersonRequest} returns this
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.setCurrentTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.getNewTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.ClonePersonRequest} returns this
 */
proto.loupe.services.orchard.ClonePersonRequest.prototype.setNewTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ClonePersonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ClonePersonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ClonePersonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ClonePersonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ClonePersonResponse}
 */
proto.loupe.services.orchard.ClonePersonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ClonePersonResponse;
  return proto.loupe.services.orchard.ClonePersonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ClonePersonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ClonePersonResponse}
 */
proto.loupe.services.orchard.ClonePersonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ClonePersonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ClonePersonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ClonePersonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ClonePersonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Person person = 1;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.ClonePersonResponse.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.orchard.ClonePersonResponse} returns this
*/
proto.loupe.services.orchard.ClonePersonResponse.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.orchard.ClonePersonResponse} returns this
 */
proto.loupe.services.orchard.ClonePersonResponse.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.orchard.ClonePersonResponse.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.ReprovisionPeopleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.ReprovisionPeopleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    provisionedCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unprovisionedCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.ReprovisionPeopleResponse}
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.ReprovisionPeopleResponse;
  return proto.loupe.services.orchard.ReprovisionPeopleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.ReprovisionPeopleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.ReprovisionPeopleResponse}
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProvisionedCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnprovisionedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.ReprovisionPeopleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.ReprovisionPeopleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvisionedCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUnprovisionedCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 provisioned_count = 1;
 * @return {number}
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.prototype.getProvisionedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.ReprovisionPeopleResponse} returns this
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.prototype.setProvisionedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 unprovisioned_count = 2;
 * @return {number}
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.prototype.getUnprovisionedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.ReprovisionPeopleResponse} returns this
 */
proto.loupe.services.orchard.ReprovisionPeopleResponse.prototype.setUnprovisionedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.SetPersonViewableGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupViewerIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    createdBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.SetPersonViewableGroupsRequest;
  return proto.loupe.services.orchard.SetPersonViewableGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupViewerIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.SetPersonViewableGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupViewerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string person_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string group_viewer_ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.getGroupViewerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.setGroupViewerIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.addGroupViewerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.clearGroupViewerIdsList = function() {
  return this.setGroupViewerIdsList([]);
};


/**
 * optional string created_by = 4;
 * @return {string}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated_by = 5;
 * @return {string}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsRequest} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsRequest.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.SetPersonViewableGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    src_common_orchard_group_pb.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.SetPersonViewableGroupsResponse;
  return proto.loupe.services.orchard.SetPersonViewableGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.SetPersonViewableGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.Group groups = 1;
 * @return {!Array<!proto.loupe.common.orchard.Group>}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_group_pb.Group, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Group>} value
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse} returns this
*/
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Group}
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.SetPersonViewableGroupsResponse} returns this
 */
proto.loupe.services.orchard.SetPersonViewableGroupsResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetTenantPersonCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetTenantPersonCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountRequest}
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetTenantPersonCountRequest;
  return proto.loupe.services.orchard.GetTenantPersonCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetTenantPersonCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountRequest}
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetTenantPersonCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetTenantPersonCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountRequest} returns this
 */
proto.loupe.services.orchard.GetTenantPersonCountRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetTenantPersonCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetTenantPersonCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeInGroup: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activeNoGroup: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inactive: jspb.Message.getFieldWithDefault(msg, 3, 0),
    provisioned: jspb.Message.getFieldWithDefault(msg, 4, 0),
    total: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetTenantPersonCountResponse;
  return proto.loupe.services.orchard.GetTenantPersonCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetTenantPersonCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveInGroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveNoGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInactive(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProvisioned(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetTenantPersonCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetTenantPersonCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveInGroup();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActiveNoGroup();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInactive();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getProvisioned();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 active_in_group = 1;
 * @return {number}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.getActiveInGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse} returns this
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.setActiveInGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 active_no_group = 2;
 * @return {number}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.getActiveNoGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse} returns this
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.setActiveNoGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 inactive = 3;
 * @return {number}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.getInactive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse} returns this
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.setInactive = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 provisioned = 4;
 * @return {number}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.getProvisioned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse} returns this
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.setProvisioned = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 total = 5;
 * @return {number}
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.orchard.GetTenantPersonCountResponse} returns this
 */
proto.loupe.services.orchard.GetTenantPersonCountResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetPeopleByEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetPeopleByEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailRequest}
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetPeopleByEmailRequest;
  return proto.loupe.services.orchard.GetPeopleByEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetPeopleByEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailRequest}
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetPeopleByEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetPeopleByEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailRequest} returns this
 */
proto.loupe.services.orchard.GetPeopleByEmailRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetPeopleByEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetPeopleByEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailResponse}
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetPeopleByEmailResponse;
  return proto.loupe.services.orchard.GetPeopleByEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetPeopleByEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailResponse}
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetPeopleByEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetPeopleByEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.Person people = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailResponse} returns this
*/
proto.loupe.services.orchard.GetPeopleByEmailResponse.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetPeopleByEmailResponse} returns this
 */
proto.loupe.services.orchard.GetPeopleByEmailResponse.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commitId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest;
  return proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommitId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommitId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string commit_id = 1;
 * @return {string}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.prototype.getCommitId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest} returns this
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.prototype.setCommitId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest} returns this
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outreachIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse;
  return proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutreachIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutreachIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string outreach_ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.prototype.getOutreachIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse} returns this
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.prototype.setOutreachIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse} returns this
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.prototype.addOutreachIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse} returns this
 */
proto.loupe.services.orchard.GetOutreachUserCommitMappingsResponse.prototype.clearOutreachIdsList = function() {
  return this.setOutreachIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.orchard.GetCRMRolesByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    src_common_orchard_crm_role_pb.CRMRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse}
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.orchard.GetCRMRolesByIdsResponse;
  return proto.loupe.services.orchard.GetCRMRolesByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse}
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_crm_role_pb.CRMRole;
      reader.readMessage(value,src_common_orchard_crm_role_pb.CRMRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.orchard.GetCRMRolesByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_crm_role_pb.CRMRole.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.CRMRole roles = 1;
 * @return {!Array<!proto.loupe.common.orchard.CRMRole>}
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.CRMRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_crm_role_pb.CRMRole, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.CRMRole>} value
 * @return {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse} returns this
*/
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.CRMRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.CRMRole}
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.CRMRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.orchard.GetCRMRolesByIdsResponse} returns this
 */
proto.loupe.services.orchard.GetCRMRolesByIdsResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * @enum {number}
 */
proto.loupe.services.orchard.FilterOp = {
  EQ: 0,
  NEQ: 1,
  IN: 2,
  NIN: 3,
  GT: 4,
  GTE: 5,
  LT: 6,
  LTE: 7,
  EQANY: 8
};

goog.object.extend(exports, proto.loupe.services.orchard);
