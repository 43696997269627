import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import RepByUser from './repByUser'
import RepHome from './repHome'
import { useLoggedInUser } from '../../context/loggedInUser'
import useRoles from '../../hooks/useRoles'
import { useRoutes } from '../../context/routes'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'

const Rep = (props) => {
  const params = useParams()
  const { userId } = params || {}
  const { routes } = useRoutes()
  const { loggedInUser } = useLoggedInUser()
  const { id } = loggedInUser
  const { isPlayerCoach } = useRoles()

  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames } = useNearlineFeatures()

  if (!isAccessNearlineCheckReady) {
    return <></>
  } else if (!userId && isPlayerCoach) {
    return <Redirect to={routes.rep.replace(':userId', id)} />
  } else if (userId) {
    if (canAccessNearlineFeature({ featureName: featureNames.performance })) {
      return <Redirect to={routes.performanceByUser.replace(':userId', userId)} />
    } else {
      return <RepByUser {...props} />
    }
  } else {
    return <RepHome />
  }
}

export default Rep
