import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const FormulaSelect = (props, ref) => {
  const { selectedOption, onSelect, onClose } = props

  const onSelectInternal = useCallback((option) => {
    onSelect && onSelect(option)
    onClose()
  }, [onSelect])

  const forecastSimulationsAvailable = useSelector((state) => state.forecastSimulationsAvailable)

  const options = useMemo(() => {
    return forecastSimulationsAvailable.map((x) => ({ key: x.key, value: x.label }))
  }, [forecastSimulationsAvailable])

  return (
    <div className="flex flex-col" style={{ minWidth: 400 }} ref={ref}>
      {options.map((o) => (
        <button
          key={`option-${o.key}`}
          onClick={() => onSelectInternal(o)}
          className={classNames('px-4 py-2 text-size-16px text-left focus:outline-none hover:bg-color-edeeee',
            { 'font-weight-700': selectedOption.key === o.key })}>
          {o.value}
        </button>
      ))}
    </div>
  )
}

export default React.forwardRef(FormulaSelect)
