const cardWidth = 325
const cardHeight = 200
const cardMarginRight = 16

export const cardScrollViewSettings = {
  cardWidth,
  cardHeight,
  cardMarginRight,
  cardOffsetWidth: cardWidth + cardMarginRight
}
