import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../header/header'
import { getOpportunitiesByTeamKey } from '../../actions/opportunityService'
import { apiRequestedSelector, apiFailedSelector } from '../../selectors'
import TeamDeals from './teamDeals'
import DealFilters from './dealFilters'
import DealFiltersToggle from './dealFiltersToggle'
import { getOppSettingsByTeam } from '../../services/opportunityService'
import { getAllPeople } from '../../services/personService'
import { keys } from 'lodash'

const Deals = (props) => {
  const filteredDealCount = useSelector((state) => state.filteredDealCount)
  const apiRequested = useSelector((state) => apiRequestedSelector(state, getOpportunitiesByTeamKey))
  const apiFailed = useSelector((state) => apiFailedSelector(state, getOpportunitiesByTeamKey))

  const location = useLocation()
  const hash = queryString.parse(location.hash)
  const hasFilters = keys(hash).length > 0

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOppSettingsByTeam())
    dispatch(getAllPeople())
  }, [])

  const [showFilters, setShowFilters] = useState(hasFilters)
  const handleFilterClick = useCallback(() => {
    window.analytics.track('Show Filters', { isShowing: !showFilters })
    setShowFilters(!showFilters)
  }, [showFilters])

  const [mainBodyStyle, setMainBodyStyle] = useState({})
  const setPagePadding = useCallback((containerHeight) => {
    setMainBodyStyle({ paddingTop: containerHeight + 25 })
  }, [])

  return (
    <>

      <Header
        text={filteredDealCount > 0 || !apiRequested ? `Deals (${filteredDealCount})` : 'Deals'}
        showSpinner={apiRequested}
        showError={apiFailed}
        stickyChildren={true}
        setPagePadding={setPagePadding}
        headerControl={<DealFiltersToggle className="mx-6" showFilters={showFilters} handleFilterClick={handleFilterClick} hasFilters={hasFilters} />}>
        {showFilters && <DealFilters enableOwnerFilter={true} />}
      </Header>

      <div className="main-body" style={mainBodyStyle}>
        <TeamDeals />
      </div>

    </>
  )
}

export default Deals
