import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import hubspotLogo from '../../assets/hubspot-logo.png'
import salesforceLogo from '../../assets/salesforce-logo.svg'

const providers = {
  hubspot: 'hubspot',
  salesforce: 'salesforce'
}

const actions = {
  connect: 'connect',
  logout: 'logout'
}

const Connected = (props) => {
  const location = useLocation()
  const search = queryString.parse(location.search)

  let image
  let action
  let message

  switch (search.action) {
    case actions.logout:
      action = 'logged out of'
      break
    default:
      action = 'connected'
      break
  }

  switch (search.provider) {
    case providers.hubspot:
      image = hubspotLogo
      message = `You have successfully ${action} your Hubspot account with Outreach Commit.`
      break
    default:
      image = salesforceLogo
      message = `You have successfully ${action} your Salesforce account with Outreach Commit.`
      break
  }

  return (
    <div className="flex justify-center items-center content-center">

      <div className="text-center p-4 my-4">
        <img src={image} className="mx-auto my-4" style={{ width: 150 }} />
        <p className="text-lg my-2">{message}</p>
        <p className="text-sm my-2">(You may now close this window)</p>
      </div>

    </div>
  )
}

export default Connected
