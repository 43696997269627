import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns'
import { getFieldFromObject } from '../components/fieldRenderers/helpers'

export function useDealDetail(opp) {
  const oppDetail = useSelector((state) => state.oppDetail)
  const deal = opp || oppDetail

  const dealProperties = useMemo(() => {
    return Object.keys(deal)
  }, [deal])

  const lastModified = useMemo(() => {
    const modified = deal?.lastModifiedDateString ?? ''
    if (modified) {
      return format(parseISO(modified), 'M/d/yyyy')
    }
    return modified
  }, [deal])

  const getField = useCallback((fieldName) => {
    return deal?.fields?.[fieldName]?.[0] ?? {}
  }, [deal])

  const getValueOrDefault = useCallback((fieldName, defaultValue) => {
    const field = getFieldFromObject(deal, fieldName)
    return field?.value ?? defaultValue
  }, [deal])

  return {
    deal,
    dealProperties,
    lastModified,
    getField,
    getValueOrDefault
  }
}
