import React, { useCallback } from 'react'
import classNames from 'classnames'
import GenericModal from '../modal/genericModal'
import errorIcon from '../../assets/error-triangle-icon.png'

const PatchErrorModal = (props) => {
  const { modal } = props

  const { open, handleClose } = modal

  const onRefreshClick = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <GenericModal
      maxWidth="sm"
      title="Start New Bulk Export"
      actionBtnText="Start"
      handleClose={handleClose}
      open={open}>
      <div className="w-full flex flex-col font-normal px-10 py-6 rounded-lg overflow-hidden">

        <div className="flex items-center mt-2 mb-4">
          <img src={errorIcon} style={{ width: 36 }} />
          <div className="ml-3 text-size-30px text-color-09242f font-bold">Oops! Something went wrong.</div>
        </div>

        <div className="text-size-20px text-color-09242f my-4">We've run into a problem and can't update that record right now.  Please try again.</div>

        <div className="text-size-20px text-color-09242f my-4">
          If the problem persists, don't worry.  Our team has been notified and will work to fix it as quickly as they can.
        </div>

        <div className="flex justify-center mt-4 mb-2">
          <button
            className={classNames('px-10 py-2 text-size-20px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none')}
            onClick={onRefreshClick}>
            Retry
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default PatchErrorModal
