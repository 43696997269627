import React from 'react'
import PropTypes from 'prop-types/prop-types'
import ChartText from '../controls/chartText'
import { pluginTypes } from '.'
import { strokePropTypes, offsetPropTypes, svgTextPropTypes } from '../propTypes/common'
import get from 'lodash/get'
import merge from 'lodash/merge'

export const lineIntersectConfig = {
  line: {
    offset: {
      x: 0,
      y: -10,
      width: 0,
      height: 0,
    },
    style: {
      stroke: '#d6d9e6',
      strokeOpacity: 1,
      strokeLinecap: 'round',
      strokeWidth: 1,
    },
  },
  label: {
    offset: {
      x: 0,
      y: 0,
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
  dot: {
    radius: 3.5,
  },
}

const pluginConfigPropTypes = {
  line: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(strokePropTypes),
  }),
  label: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  hide: PropTypes.bool,
}

export const LineIntersectPlugin = (config) => {
  const { chartRect, lines, colors } = config

  const c = { ...lineIntersectConfig }
  const pluginConfig = merge(c, get(config, `plugins.${pluginTypes.lineIntersect}`, {}))
  PropTypes.checkPropTypes(pluginConfigPropTypes, pluginConfig, 'prop', 'LineIntersectPlugin')

  const index = get(pluginConfig, 'config.index', -1)
  const label = get(pluginConfig, 'config.label', '')

  const _x = get(lines, `[0].points[${index}].x`, 0)
  const x = _x + pluginConfig.line.offset.x
  const y1 = chartRect.y + pluginConfig.line.offset.y
  const y2 = chartRect.y + chartRect.height + pluginConfig.line.offset.height

  const textAttributes = {
    x: _x + pluginConfig.label.offset.x,
    y: y1 - (pluginConfig.label.style.fontSize / 2) + pluginConfig.label.offset.y,
    style: {
      ...pluginConfig.label.style,
      textAnchor: (_x + pluginConfig.label.offset.x) > 0 ? pluginConfig.label.style.textAnchor : 'start'
    }
  }

  return (
    <g key="lineintersect" className="g-plugin-lineintersect">
      <line
        x1={x}
        y1={y1}
        x2={x}
        y2={y2}
        className="focus:outline-none"
        style={{ ...pluginConfig.line.style && pluginConfig.line.style }} />
      {lines.map((line, i) => {
        const p = get(line, `points[${index}]`)
        const dotStyle = {}
        if (typeof colors === 'string') {
          dotStyle.fill = colors
        } else if (Array.isArray(colors)) {
          dotStyle.fill = get(colors, `[${get(p, 'seriesIndex', 0)}]`, '#000000')
        }
        if (p) {
          return (
            <ellipse
              key={`lineintersect-dot-${i}`}
              className={`lineintersect-dot series-${get(p, 'seriesIndex', 0)} data-${p.dataIndex}`}
              cx={p.x}
              cy={p.y}
              rx={7}
              ry={7}
              style={dotStyle} />
          )
        }
      })}
      <ChartText {...textAttributes}>{label}</ChartText>
    </g>
  )
}
