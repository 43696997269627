import { ClickAwayListener, Grow, Paper, Popper as MuiPopper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { noop } from 'lodash'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useKeyPressEffect from '../hooks/useKeyPress'

const useStyles = makeStyles((theme) => ({
  root: {
    '&[x-placement*="bottom"] $arrow': {
      height: '1.5em',
      left: 0,
      marginTop: '-1.4em',
      top: 0,
      width: '3em',
      '&::before': {
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        borderWidth: '0 1.5em 1.5em 1.5em'
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      height: '1.5em',
      left: 0,
      marginBottom: '-1.4em',
      width: '3em',
      '&::before': {
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        borderWidth: '1.5em 1.5em 0 1.5em'
      },
    },
    '&[x-placement*="right"] $arrow': {
      height: '3em',
      left: 0,
      marginLeft: '-1.4em',
      width: '1.5em',
      '&::before': {
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        borderWidth: '1.5em 1.5em 1.5em 0'
      },
    },
    '&[x-placement*="left"] $arrow': {
      height: '3em',
      marginRight: '-1.4em',
      right: 0,
      width: '1.5em',
      '&::before': {
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        borderWidth: '1.5em 0 1.5em 1.5em'
      },
    },
  },
  arrow: {
    fontSize: 7,
    height: '3em',
    position: 'absolute',
    width: '3em',
    '&::before': {
      content: '""',
      borderStyle: 'solid',
      display: 'block',
      height: 0,
      margin: 'auto',
      width: 0
    },
  },
  paper: {
    maxHeight: ({ maxHeight }) => maxHeight || '90vh',
    overflowY: 'auto'
  }
}))

const Popover = ({
  anchorEl,
  arrow,
  children,
  disablePortal,
  elevation,
  fullHeight,
  maxHeight,
  onClose,
  open,
  placement,
  timeout,
  ...props
}) => {
  const classes = useStyles({ maxHeight })
  const [arrowRef, setArrowRef] = useState(null)

  useKeyPressEffect({
    targetKey: 'Escape',
    eventTarget: ['.search-box', '#popper button'],
    useCapture: true,
    onDown: onClose
  }, [onClose])

  return (
    <MuiPopper
      id="popper"
      className={classes.root}
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      onClose={onClose}
      open={open}
      placement={placement}
      transition
      {...arrow && {
        modifiers: {
          arrow: {
            enabled: true,
            element: arrowRef
          },
          ...fullHeight && {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
              padding: 32
            }
          },
        }
      }}
      {...props}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={timeout}>
          <Paper className={classes.paper} elevation={elevation}>
            {arrow && <span className={classes.arrow} ref={setArrowRef} />}
            <ClickAwayListener onClickAway={onClose}>
              {React.Children.count(children) > 1 ? <div>{children}</div> : children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </MuiPopper>
  )
}

Popover.defaultProps = {
  anchorEl: null,
  arrow: false,
  children: null,
  disablePortal: false,
  elevation: 8,
  fullHeight: false,
  onClose: noop,
  open: false,
  placement: 'bottom',
  timeout: 350,
}

Popover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  arrow: PropTypes.bool,
  children: PropTypes.node,
  disablePortal: PropTypes.bool,
  elevation: PropTypes.number,
  fullHeight: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  placement: PropTypes.string,
  timeout: PropTypes.number,
}

export default Popover
