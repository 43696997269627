import { useEffect } from 'react'

export default function useDisableWheel(ref) {
  const onWheel = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  useEffect(() => {
    ref && ref.current && ref.current.addEventListener('wheel', onWheel, { passive: false })
    return () => {
      ref && ref.current && ref.current.removeEventListener('wheel', onWheel)
    }
  }, [ref])
}
