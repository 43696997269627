import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { featureSettingSelector } from '../../selectors'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import orderBy from 'lodash/orderBy'
import TrendList from './trendList'
import moment from 'moment'
import ToggleMenu from '../toggleMenu/toggleMenu'
import { featureNames } from '../../constants/featureNames'
import { useGrpcCallback } from '../../grpc'
import { DataRequest } from '../../protos/src/common/data_request_pb'

const toggleMenuData = [
  { text: '30 Day Trend', trendRange: '30 days', aggregateBy: 'daily' },
  { text: '8 Week Trend', trendRange: '8 weeks', aggregateBy: 'weekly' },
  { text: '3 Month Trend', trendRange: '3 months', aggregateBy: 'monthly' }
]

const dateFormat = 'MMM D'

const ActivityTrends = (props) => {
  const { user } = props
  const { firstName } = user
  const [fullPerson, setFullPerson] = useState()

  const dataRequest = useMemo(() => {
    if (user && user.id) {
      const request = new DataRequest()
      request.setId(user.id)
      return request
    }
  }, [user])

  const getFullPersonRequest = useGrpcCallback({
    onSuccess: setFullPerson,
    grpcMethod: 'getFullPersonPayload',
    debug: false,
  }, [])

  useEffect(() => {
    if (dataRequest && getFullPersonRequest) {
      getFullPersonRequest(dataRequest)
    }
  }, [dataRequest, getFullPersonRequest])

  const activityTrend = useMemo(() => {
    if (fullPerson) {
      return fullPerson.activityTrend
    }
    return { trendsList: [] }
  }, [fullPerson])

  const activityTrendsFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.activityTrends }))

  const trends = useMemo(() => {
    if (!activityTrend) return []
    const activityTrendsSort = get(activityTrendsFeature, 'payload.sort', [])
    if (activityTrendsFeature && activityTrendsSort.length > 0) {
      return orderBy(activityTrend.trendsList.map((t) => {
        return { ...t, sort: indexOf(activityTrendsSort, t.name) }
      }), ['sort'])
    } else {
      return activityTrend.trendsList
    }
  }, [activityTrend, activityTrendsFeature])

  const [aggregateBy, setAggregateBy] = useState(toggleMenuData[0].aggregateBy)
  const [trendRange, setTrendRange] = useState(toggleMenuData[0].trendRange)
  const [trendStartEndPeriod, setTrendStartEndPeriod] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (trends && trends.length > 0) {
      let seriesData
      switch (selectedIndex) {
        case 1:
          seriesData = orderBy(trends[0].weeklyValuesList, (v) => v.date)
          if (seriesData[0] && seriesData[seriesData.length - 1]) {
            const firstDayInPeriod = moment(seriesData[0].date)
            const lastDayInPeriod = moment(seriesData[seriesData.length - 1].date).add(1, 'week')
            setTrendStartEndPeriod(` from ${firstDayInPeriod.format(dateFormat)} - ${lastDayInPeriod.format(dateFormat)}`)
          }
          break
        case 2:
          seriesData = orderBy(trends[0].monthlyValuesList, (v) => v.date)
          if (seriesData[0] && seriesData[seriesData.length - 1]) {
            const firstDayInPeriod = moment(seriesData[0].date)
            const lastDayInPeriod = moment(seriesData[seriesData.length - 1].date).add(1, 'month').subtract(1, 'day')
            setTrendStartEndPeriod(` from ${firstDayInPeriod.format(dateFormat)} - ${lastDayInPeriod.format(dateFormat)}`)
          }
          break
        default:
          seriesData = orderBy(trends[0].dailyValuesList, (v) => v.date)
          if (seriesData[0] && seriesData[seriesData.length - 1]) {
            const firstDayInPeriod = moment(seriesData[0].date)
            const lastDayInPeriod = moment(seriesData[seriesData.length - 1].date)
            setTrendStartEndPeriod(` from ${firstDayInPeriod.format(dateFormat)} - ${lastDayInPeriod.format(dateFormat)}`)
          }
      }
    }
  }, [trends, selectedIndex])

  const onSelectedIndexChanged = useCallback((index) => {
    setSelectedIndex(index)
    setTrendRange(toggleMenuData[index].trendRange)
    setAggregateBy(toggleMenuData[index].aggregateBy)
    window.analytics.track('Activity Trend Toggle', { trend: toggleMenuData[index].aggregateBy })
  }, [])

  return (
    <div className="mb-6">

      <h1>Activity Trends</h1>
      <div className="text-size-14px text-color-4e5d7e">
        {firstName}
        's activity trends in the last
        {' '}
        {trendRange}
        {trendStartEndPeriod}
        .
      </div>

      <div className="mb-5">
        {firstName && (
          <>
            <ToggleMenu
              id="activityTrendToggleMenu"
              className="mt-3"
              data={toggleMenuData}
              itemWidthClass="w-1/3"
              onSelectedIndexChanged={onSelectedIndexChanged} />
          </>
        )}

        {user.id && <TrendList userId={user.id} trends={trends} aggregateBy={aggregateBy} />}
      </div>

    </div>
  )
}

export default ActivityTrends
