import { get } from 'lodash'
import React, { useLayoutEffect, useMemo } from 'react'
import { typeCheck } from 'type-check'
import FieldDebug from './fieldDebug'
import classNames from 'classnames'

const ReadonlyFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    fieldDefinition,
    hideWhenEmpty = false,
    field,
    path,
    className = '',
    labelClassName = '',
  } = props

  const { label } = fieldDefinition
  const { value } = field

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure])

  const renderedValue = useMemo(() => {
    if (typeCheck('Object', value)) {
      return get(value, path, '-')
    } else {
      return value || '-'
    }
  }, [value, path])

  return (
    <>
      {!value && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('ReadonlyFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
              {label}
              <FieldDebug
                debug={debug}
                data={{ fieldDefinition, field }} />
            </div>
            <div
              style={{ transform: 'translateY(-1px)' }}
              className="text-color-09242f text-size-16px font-weight-300">
              {renderedValue}
            </div>
          </div>
        )}
    </>
  )
}

export default ReadonlyFieldDetailItem
