import React, { useCallback } from 'react'
import { has } from 'lodash'
import { learnMore } from '../canvas/constants'

const RealtimeViewContainer = ({ className, menuBar, name, tileKey, children }) => {
  const onClick = useCallback(() => {
    window.analytics.track('realtimeMetrics.learnMoreClicked', { name })
  }, [name])

  return (
    <div className={`border border-color-2e5bff-08 rounded-lg overflow-hidden ${className}`}>
      <div className="px-4 flex items-center justify-between bg-color-ffffff">
        <div className="py-4 leading-tight">
          <div className="pr-1 text-size-18px font-weight-600 whitespace-nowrap truncate">{name}</div>
          {has(learnMore, tileKey) && (
            <a
              onClick={onClick}
              href={learnMore[tileKey]}
              target="_blank"
              className="text-color-2e5bff text-size-14px font-weight-500 cursor-pointer"
              rel="noreferrer">
              Learn More
            </a>
          )}
        </div>
        <div className="flex items-center">
          {menuBar && menuBar}
        </div>
      </div>
      <div className="p-2">
        {children}
      </div>
    </div>
  )
}

export default RealtimeViewContainer
