import { createReducer } from '@reduxjs/toolkit'
import { getIntegrationObjectDefinitionSuccess } from '../actions/integrationService'
import { filter, orderBy } from 'lodash'
import { clearIntegrationObject } from '../actions'

const initialState = {}

export const objectDefinitions = createReducer(initialState, {
  [clearIntegrationObject.type]: (state, action) => initialState,
  [getIntegrationObjectDefinitionSuccess.type]: (state, action) => {
    const { objectDefinition } = action.payload
    const { to } = objectDefinition
    const fields = objectDefinition.fieldsList.map((f) => {
      f.key = f.to.toLowerCase()
      return f
    })
    objectDefinition.fieldsList = filter(orderBy(fields, ['integrationSourceType', 'sort'], ['asc', 'asc']), (f) => f.visible)
    state[to] = objectDefinition
    return state
  }
})
