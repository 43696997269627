import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useRoutes } from '../context/routes'
import { useAuth } from '../context/auth'
import { usePermissions } from '../context/permissions'
import { every } from 'lodash'
import { useLoggedInUser } from '../context/loggedInUser'

import queryString from 'query-string'
import { captureException, setUser } from '../lib/sentry'
import { useDispatch } from 'react-redux'
import { handleAuth } from '../services/authService'
import { getTokenSilently, getIdTokenClaims, setAccessToken, setClaims } from '../lib/auth0'

const ProtectedRoute = ({ template: Template, component: Component, dock: Dock, roleChecks = [], checkPermissions = [], ...rest }) => {
  const { isAuthenticated, setIsAuthenticated } = useAuth()

  const dispatch = useDispatch()

  const [isFetchingToken, setIsFetchingToken] = useState(true)

  const { isFetching: isFetchingPermissions, permissions, checkPermission } = usePermissions()

  const { isFetching: isFetchingUser } = useLoggedInUser()

  const { routes, routeTenantId, isPreview } = useRoutes()

  const location = useLocation()

  const isFetching = useMemo(() => {
    return isFetchingPermissions || isFetchingUser || isFetchingToken
  }, [isFetchingPermissions, isFetchingUser, isFetchingToken])

  const canAccess = useMemo(() => {
    const rolesValid = isPreview || every(roleChecks, (check) => check)
    return rolesValid && every(checkPermissions, (p) => checkPermission(p))
  }, [roleChecks, checkPermissions, checkPermission, isPreview])

  const redirectTo = useMemo(() => {
    let redirectUrl = routes.login
    const searchParams = {}

    // return '/login?redirect=/performance&sandbox=c4445a86-6726-11ec-8bd1-c698e5778c51'

    if (location.pathname !== '/') {
      if (routeTenantId) {
        const [, basePath = '/'] = location.pathname.split(`/${routeTenantId}`)
        searchParams.redirect = basePath
      } else {
        searchParams.redirect = location.pathname
      }
    }

    if (routeTenantId) {
      searchParams.sandbox = routeTenantId
    }

    if (Object.keys(searchParams).length) {
      redirectUrl += `?${queryString.stringify(searchParams)}`
    }

    redirectUrl += encodeURIComponent(location.hash)

    return redirectUrl
  }, [location, routeTenantId])

  useEffect(() => {
    getTokenSilently({
      onSuccess: ({ accessToken }) => {
        getIdTokenClaims({
          onSuccess: ({ idTokenClaims }) => {
            // console.log('getTokenSilently', accessToken)
            setAccessToken(accessToken)

            // console.log('idTokenClaims', idTokenClaims)
            setClaims(idTokenClaims)

            dispatch(handleAuth(idTokenClaims))

            setUser(idTokenClaims)
            setIsAuthenticated(true)
            setIsFetchingToken(false)
          },
          onError: (err) => {
            setIsAuthenticated(false)
            setIsFetchingToken(false)
            captureException(err, undefined, 'getIdTokenClaims')
          },
        })
      },
      onError: (err) => {
        setIsAuthenticated(false)
        setIsFetchingToken(false)
      },
    })
  }, [])

  // console.log('ProtectedRoute',
  //   `\nisAuthenticated=${isAuthenticated}`,
  //   `\nisFetching=${isFetching}`,
  //   `\ncanAccess=${canAccess}`,
  //   `\nredirectTo=${redirectTo}`)

  const render = useCallback((props) => {
    if (isFetching) {
      return <></>
    }
    if (isAuthenticated) {
      if (canAccess) {
        return <Template component={Component} dock={Dock} canAccess={true} {...props} />
      } else {
        return <Redirect to={routes.unauthorized} />
      }
    } else {
      return <Redirect to={redirectTo} />
    }
  }, [isAuthenticated, isFetching, canAccess, redirectTo])

  return <Route {...rest} render={render} />
}

export default ProtectedRoute
