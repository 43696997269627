import React, { useRef, useState, useCallback, useEffect } from 'react'
import ToggleMenuItem from './toggleMenuItem'
import classNames from 'classnames'

const ToggleMenu = (props) => {
  const { id, data = [], index = 0, onSelectedIndexChanged, className, itemWidthClass } = props

  const menuHeight = 32
  const menuBgMargin = 2
  const menuBgRef = useRef(null)
  const [left, setLeft] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(index)
  const [transitionProp, setTransitionProp] = useState('none')

  useEffect(() => {
    setSelectedIndex(index)
    if (menuBgRef && menuBgRef.current) {
      setLeft((menuBgRef.current.getBoundingClientRect().width * index) - (index === data.length - 1 ? (menuBgMargin * 2) : 0))
    }
  }, [index])

  const onClick = useCallback((index) => {
    setTransitionProp('left')
    setSelectedIndex(index)
    if (menuBgRef && menuBgRef.current) {
      setLeft((menuBgRef.current.getBoundingClientRect().width * index) - (index === data.length - 1 ? (menuBgMargin * 2) : 0))
    }
    onSelectedIndexChanged(index)
  }, [])

  const updateTabIndexPosition = useCallback((index) => {
    if (menuBgRef && menuBgRef.current) {
      setLeft((menuBgRef.current.getBoundingClientRect().width * selectedIndex) - (selectedIndex === data.length - 1 ? (menuBgMargin * 2) : 0))
    }
  }, [selectedIndex])

  useEffect(() => {
    window.addEventListener('resize', updateTabIndexPosition)
    return () => window.removeEventListener('resize', updateTabIndexPosition)
  }, [selectedIndex])

  return (
    <div className={classNames('relative bg-color-e9eaf2 rounded', className)} style={{ height: menuHeight }}>
      <div
        ref={menuBgRef}
        className={classNames('absolute bg-color-ffffff rounded', itemWidthClass)}
        style={{
          margin: menuBgMargin,
          height: menuHeight - (menuBgMargin * 2),
          transitionTimingFunction: 'ease-in-out',
          transitionProperty: transitionProp,
          transitionDuration: '300ms',
          left
        }} />
      <div className="absolute flex flex-nowrap w-full" style={{ marginTop: 1 }}>
        {data.map((d, index) => <ToggleMenuItem key={`${id}-${index}`} index={index} text={d.text} onClick={onClick} itemWidthClass={itemWidthClass} />)}
      </div>
    </div>
  )
}

export default ToggleMenu
