import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcCallback } from '../grpc'
import { useAuth } from './auth'
import { cloneDeep, filter, find, orderBy } from 'lodash'
import { toRunRealtimeQueriesRequest } from '../grpc/converters'
import { useCanvas } from './canvas'

const RepSignalsContext = React.createContext()

export function RepSignalsProvider({ userId, headers, children }) {
  const { actingTenantId } = useAuth()
  const { canvas, tenantStandardParams } = useCanvas()

  const [key, setKey] = useState(guid())
  const [repSignals, setRepSignals] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const realtimeQueriesMap = useMemo(() => {
    if (!canvas) {
      return []
    }
    const canvasTilesList = cloneDeep(canvas?.canvasLayout?.canvasTilesList ?? [])
    const orderedTiles = orderBy(canvasTilesList, (t, i) => t?.layout?.rowIdx ?? i)
    const tileContainersList = canvas?.tileContainersList ?? []
    const orderedTileContainersList = filter(orderedTiles.map((tile) => {
      tile.tile = find(tileContainersList, (t) => tile.tileKey === t.tileKey)?.tile ?? undefined
      return tile
    }), (tile) => tile.tile !== undefined)
    const map = orderedTileContainersList.map((tile) => {
      const parametersList = tile?.tile?.realtimeView?.view?.query?.availableParameters?.parametersList ?? []
      return [
        tile.tileKey,
        {
          queryKey: tile?.tileKey,
          params: {
            parametersList
          },
          nlgMap: tile?.tile?.realtimeView?.view?.nlgMap ?? [],
          gml: tile?.tile?.realtimeView?.view?.view
        }
      ]
    })
    return map
  }, [canvas])

  const runRealtimeQueriesForRepSignals = useGrpcCallback({
    ...headers && { headers },
    onSuccess: (obj) => {
      setIsFetching(false)
      const { responsesMap = [] } = obj
      const signals = filter(responsesMap.map((m) => {
        const response = m[1]
        response.name = find(canvas?.tileContainersList ?? [], (t) => t.tileKey === response.queryKey)?.tile?.name ?? ''
        const realtimeQuery = find(realtimeQueriesMap, (rqMap) => rqMap[0] === m[0])
        response.query = {
          params: realtimeQuery?.[1]?.params ?? {}
        }
        return response
      }), (value) => value !== undefined)
      setRepSignals(signals)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'runRealtimeQueries',
    grpcMethodName: 'runRealtimeQueriesForRepSignals',
    debug: false
  }, [canvas, realtimeQueriesMap, headers])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useEffect(() => {
    if (key && canvas && userId) {
      const request = toRunRealtimeQueriesRequest({
        tenantId: actingTenantId,
        personId: userId,
        realtimeQueriesMap,
        ...tenantStandardParams && { tenantStandardParams }
      })
      runRealtimeQueriesForRepSignals(request)
    }
  }, [key, runRealtimeQueriesForRepSignals, canvas, userId, realtimeQueriesMap])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      repSignals,
      invalidate
    }
  }, [isFetching, repSignals])

  return <RepSignalsContext.Provider value={contextValue}>{children}</RepSignalsContext.Provider>
}

export function useRepSignals() {
  const context = React.useContext(RepSignalsContext)
  if (context === undefined) {
    throw new Error('useRepSignals must be used within a RepSignalsProvider')
  }
  return context
}
