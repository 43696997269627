import React from 'react'
import classNames from 'classnames'

const DealTabs = (props) => {
  const {
    tabIndex = 0,
    onTabIndexChange,
  } = props

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap items-center">

        <div
          onClick={() => onTabIndexChange(0)}
          className={
            classNames(
              'flex flex-col text-size-24px text-color-151d49 font-bold py-1 mt-2 ml-8 first:ml-0 whitespace-nowrap leading-tight focus:outline-none cursor-pointer',
              { 'text-color-8097b1': tabIndex === 0 }
            )
          }>
          <div className={classNames('text-size-24px font-bold', { 'text-color-151d49': tabIndex === 0 }, { 'text-color-818e93': tabIndex !== 0 })}>Summary</div>
          <div className={classNames('w-full rounded-full bg-color-1d4dcf', { invisible: tabIndex !== 0 })} style={{ height: 4 }} />
        </div>

        <div
          onClick={() => onTabIndexChange(1)}
          className={
            classNames(
              'flex flex-col text-size-24px text-color-151d49 font-bold py-1 mt-2 ml-8 first:ml-0 whitespace-nowrap leading-tight focus:outline-none cursor-pointer',
              { 'text-color-8097b1': tabIndex === 1 }
            )
          }>
          <div className={classNames('text-size-24px font-bold', { 'text-color-151d49': tabIndex === 1 }, { 'text-color-818e93': tabIndex !== 1 })}>Other</div>
          <div className={classNames('w-full rounded-full bg-color-1d4dcf', { invisible: tabIndex !== 1 })} style={{ height: 4 }} />
        </div>

      </div>

    </div>
  )
}

export default DealTabs
