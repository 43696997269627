import React from 'react'
import { TenantUsersProvider } from '../../../context/tenantUsers'
import TenantUsersMain from './tenantUsersMain'

const Users = (props) => {
  return (
    <TenantUsersProvider>
      <TenantUsersMain />
    </TenantUsersProvider>
  )
}

export default Users
