import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import DealFiltersAndSearch from '../pipeline/dealFiltersAndSearch'
import PipelineGrid from '../pipeline/pipelineGrid'
import { useDealChangeWindowFeature } from '../../hooks/useDealChangeWindowFeature'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'

const RepDeals = (props) => {
  const { userId: uId, match } = props

  const { params } = useParams()

  const userId = useMemo(() => {
    return uId || (params?.userId ?? '') || (match?.params?.userId ?? '')
  }, [uId, params, match])

  const { changeSince } = useDealChangeWindowFeature()

  const { hasFeatureSettings, isDealHealthEnabled } = useOutreachFeatures()

  const showPipeline = useMemo(() => {
    return hasFeatureSettings
  }, [hasFeatureSettings])

  return (
    <div className="w-full h-full flex flex-col flex-grow">

      {showPipeline && (
        <>
          <div className="w-full px-10">
            <DealFiltersAndSearch
              key="RepDeals"
              showOwnerFilter={false}
              userIds={[userId]}
              tipPlacement="right" />
          </div>

          <div className="w-full h-full flex-grow" style={{ marginLeft: 40 }}>
            <PipelineGrid
              key="RepDeals"
              style={{ marginLeft: 40 }}
              enableDealClick={false}
              changeSince={changeSince}
              isDealHealthEnabled={isDealHealthEnabled}
              useTotalRowsHeight={true} />
          </div>
        </>
      )}

    </div>
  )
}

export default RepDeals
