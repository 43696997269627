import numeral from 'numeral'

export const number = (val, format) => {
  if (format) {
    if (format === 'percent') {
      return numeral(val).format('0%')
    } else {
      return numeral(val).format(format)
    }
  } else {
    return val
  }
}
