import classNames from 'classnames'
import React, { useCallback } from 'react'

const TabMenu = (props) => {
  const {
    tabIndex = 0,
    options = [],
    trackingPrefix = 'tabMenu'
  } = props

  const onTabIndexChange = useCallback((option) => {
    const { onClick } = option
    window.analytics.track(`${trackingPrefix}.tabChanged`, { name: option.name })
    onClick && onClick()
  }, [])

  return (
    <div className="flex flex-col mt-0 mb-2">
      <div className="flex flex-wrap items-center">
        {options.map((option) => {
          const { index, name, canShow } = option
          return canShow && canShow() ? (
            <div
              key={`TabMenu-Option-${index}`}
              onClick={() => onTabIndexChange(option)}
              className={
                classNames('flex flex-col text-size-24px text-color-151d49 font-bold py-1 mt-2 ml-8 first:ml-0',
                  'whitespace-nowrap leading-tight focus:outline-none cursor-pointer',
                  { 'text-color-8097b1': index === tabIndex })
              }>
              <div
                className={classNames('text-size-24px font-bold',
                  { 'text-color-151d49': index === tabIndex },
                  { 'text-color-818e93': index !== tabIndex })}>
                {name}
              </div>
              <div
                className={classNames('w-full rounded-full bg-color-5951FF',
                  { invisible: index !== tabIndex })}
                style={{ height: 4 }} />
            </div>
          ) : undefined
        })}
      </div>
    </div>
  )
}

export default TabMenu
