import { dealStatus } from '../constants/dealStatus'
import { formatDate } from '../formatters'
import { getPeriod } from '../lib/forecasting'
import get from 'lodash/get'
import moment from 'moment'

export const enrichOpportunity = (opportunity) => {
  if (opportunity.status === '') {
    opportunity.status = 'offTrack'
  }
  const status = dealStatus[opportunity.status]
  if (status) {
    opportunity.statusLabel = status.label
    opportunity.statusColor = status.color
  }

  opportunity.hasCloseDate = get(opportunity, 'closeDate.seconds', 0) !== 0
  opportunity.closeDateLabel = opportunity.hasCloseDate ? formatDate(opportunity.closeDate.seconds) : '-'
  opportunity.daysUntilClose = moment({ hours: 0 }).utc().diff(moment(opportunity.closeDate.seconds * 1000).utc(), 'days') * -1

  const durDiff = moment.duration(moment({ hours: 0 }).utc().diff(moment(opportunity.closeDate.seconds * 1000).utc(), 'hours'), 'hours')
  const humanDate = durDiff.humanize(false)
  // eslint-disable-next-line no-nested-ternary
  opportunity.daysUntilCloseRelativeLabel = opportunity.daysUntilClose === 0 ? 'Today'
    // eslint-disable-next-line no-nested-ternary
    : (opportunity.daysUntilClose < 0
      ? (durDiff.asDays() < 1 && durDiff.asSeconds() > 0 ? 'yesterday' : `${humanDate} past`)
      : (durDiff.asDays() < 1 && durDiff.asSeconds() > 0 ? 'tomorrow' : `${humanDate} left`))
  opportunity.daysUntilCloseRelativeLabelColor = opportunity.daysUntilClose <= 0 ? 'text-color-ff4947' : 'text-color-8097b1'

  const { amount = 0 } = opportunity.change || {}
  opportunity.changeAmount = amount
  opportunity.changeAmountIcon = amount < 0 ? 'arrow-down' : 'arrow-up'
  opportunity.changeAmountMovement = amount < 0 ? 'Decrease' : 'Increase'

  return opportunity
}

export const forecastReducer = (state, action, forecastEntry) => {
  const p = getPeriod(forecastEntry)
  state = {
    ...forecastEntry,
    name: (p.start && p.end) ? `${p.start.format('MMM D')} - ${p.end.format('MMM D')}` : '',
    reducerTimestamp: Math.floor(Date.now())
  }
  return state
}
