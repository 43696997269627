import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback } from '../grpc'
import { toSearchPeopleRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { FilterOp, PersonField } from '../grpc/enums'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'

const TenantUsersContext = React.createContext()

const pageSize = 10

export function TenantUsersProvider({ children }) {
  const params = useParams()
  const { tenantId } = params

  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [key, setKey] = useState(guid())
  const [tenantUsers, setTenantUsers] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const setSearchInternal = useCallback((searchValue) => {
    setSearch(searchValue)
    setPage(1)
  }, [])

  const hash = queryString.parse(location.hash)

  const searchPeople = useGrpcCallback({
    onError: () => {
      notifyError('Error fetching tenantUsers!')
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      const { peopleList, total = 0 } = obj
      setIsFetching(false)
      setTenantUsers(peopleList)
      setTotal(total)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'searchPeople',
    grpcMethodName: 'getImpersonationUsers',
    canImpersonate: false,
    debug: false,
    useApiRegion: hash.region
  }, [])

  useEffect(() => {
    if (search === '' || search.length > 2) {
      const request = toSearchPeopleRequest({
        tenantId,
        search,
        page,
        pageSize,
        filtersList: [
          {
            field: PersonField.ISPROVISIONED,
            op: FilterOp.EQ,
            valuesList: [
              btoa(JSON.stringify(true)),
            ],
          }
        ],
      })
      searchPeople(request)
    }
  }, [key, page, pageSize, search])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      tenantUsers,
      page,
      setPage,
      pageSize,
      total,
      setSearch: setSearchInternal,
      invalidate,
    }
  }, [isFetching, tenantUsers, page, total])

  return <TenantUsersContext.Provider value={contextValue}>{children}</TenantUsersContext.Provider>
}

export function useTenantUsers() {
  const context = React.useContext(TenantUsersContext)
  if (context === undefined) {
    throw new Error('useTenantUsers must be used within a TenantUsersProvider')
  }
  return context
}
