import React, { useCallback, useMemo } from 'react'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import { Close } from '@getoutreach/react-icons'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const FilterModalHeader = (props) => {
  const {
    confirmationModal,
    onClose
  } = props

  const { workingFilter, canSave: canSaveFilter } = useRealtimeFilterEditor()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageSignal
    )
  }, [checkPermissions])

  const isSharedFilter = useMemo(() => {
    return workingFilter?.userId === ''
  }, [workingFilter])

  const canSave = useMemo(() => {
    if (isSharedFilter) {
      return permissions.CanManageSignal && canSaveFilter
    } else {
      return canSaveFilter
    }
  }, [isSharedFilter, canSaveFilter, permissions])

  const onCloseInternal = useCallback(() => {
    if (canSave) {
      confirmationModal?.setOpen(true)
      return
    }
    onClose && onClose()
  }, [canSave, confirmationModal, onClose])

  return (
    <>
      <div className="pl-12 pr-7 py-8 flex justify-between items-start">
        <div>
          <h1>Filter Your Data</h1>
        </div>
        <button onClick={onCloseInternal}><Close htmlColor="#000000" /></button>
      </div>
    </>
  )
}

export default FilterModalHeader
