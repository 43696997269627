import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { has } from 'lodash'

export function useLocationData() {
  const location = useLocation()
  const params = useParams()

  const search = useMemo(() => {
    return queryString.parse(location.search)
  }, [location])

  const hash = useMemo(() => {
    return queryString.parse(location.hash)
  }, [location])

  const isDebug = useMemo(() => {
    return has(hash, 'debug')
  }, [hash])

  // console.log('useLocationData', location, params, search, hash, isDebug)

  return {
    location,
    params,
    search,
    hash,
    isDebug,
  }
}
