import React, { useCallback, useEffect, useMemo, useState } from 'react'
import GenericModal from '../modal/genericModal'
import { Close } from '@getoutreach/react-icons'
import { secondsToFormattedDate } from '../../lib/dateFns'
import { toGetActivityDetailsRequest } from '../../grpc/converters'
import { useGrpcCallback } from '../../grpc'
import AnimatedLoader from '../loaders/animatedLoader'

const ActivityModal = (props) => {
  const {
    activityId,
    partitionTs,
    crmType,
    modal,
  } = props

  const [activityDetails, setActivityDetails] = useState()
  const [apiRequested, setApiRequested] = useState(false)

  const { open, handleClose } = modal

  const getActivityDetails = useGrpcCallback({
    onFetch: () => setApiRequested(true),
    onSuccess: (res) => {
      setApiRequested(false)
      setActivityDetails(res)
    },
    onError: console.log,
    grpcMethod: 'getActivityDetails',
    debug: false
  }, [])

  useEffect(() => {
    if (open && activityId) {
      const request = toGetActivityDetailsRequest({
        activityId,
        crmType,
        withFullThread: false,
        limit: -1,
        partitionTs
      })
      getActivityDetails(request)
    }
  }, [open, activityId, partitionTs, crmType, getActivityDetails])

  const handleCloseInternal = useCallback(() => {
    handleClose && handleClose()
  }, [handleClose])

  const activity = useMemo(() => {
    if (activityDetails && open) {
      return activityDetails
    }
  }, [activityDetails, open])

  const date = useMemo(() => {
    const seconds = activity?.activity?.completedAt?.value?.seconds ?? 0
    return secondsToFormattedDate(seconds, 'M/d/yyyy h:mmaaa')
  }, [activity])

  const subject = useMemo(() => {
    return (activity && activity.activity && activity.activity.subject) || '(No Subject)'
  }, [activity])

  const description = useMemo(() => {
    if (apiRequested) {
      return 'Loading...'
    } else if (activity && activity.activity && activity.activity.description) {
      return activity.activity.description.replace(/<(http.*?)>\s+?([\w+|.|\s]{1,})/mg, '<a href="$1">$2</a>')
    }
    return '(No Message)'
  }, [activity, apiRequested])

  return (
    <GenericModal
      maxWidth="md"
      handleClose={handleCloseInternal}
      open={open}>
      <div className="w-full flex flex-col font-normal">
        <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
          <div className="text-size-22px text-color-ffffff font-bold">Activity</div>
          <button onClick={handleCloseInternal} className="focus:outline-none"><Close htmlColor="#ffffff" /></button>
        </div>
        <div className="w-full h-full px-10 py-10 leading-tight overflow-y-auto overflow-x-hidden" style={{ maxHeight: 'calc(100vh * 0.85)' }}>
          {apiRequested
            ? (
              <div
                className="w-full h-full flex justify-center items-center top-0"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                <AnimatedLoader />
              </div>
            )
            : (
              <>
                <div className="text-size-14px text-color-818e93 font-weight-500">{date}</div>
                <div className="text-size-16px text-color-09242f font-weight-600 mt-1">{subject}</div>
                <div
                  className="text-size-16px text-color-818e93 font-weight-500 my-2 whitespace-pre-line"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }} />
              </>
            )}
        </div>
      </div>
    </GenericModal>
  )
}

export default ActivityModal
