import React from 'react'

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    console.log(error)
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
