import { createReducer } from '@reduxjs/toolkit'
import { getCrmSettingsSuccess } from '../actions/settingsService'

const initialState = []

export const crmSettings = createReducer(initialState, {
  [getCrmSettingsSuccess.type]: (state, action) => {
    return [...action.payload.settingsList.map((s) => {
      const data = JSON.parse(atob(s.payload))
      s.payload = data
      return s
    })]
  }
})
