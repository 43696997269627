import React, { useEffect, useState } from 'react'
import { useCanvas } from '../../context/canvas'
import { useRealtimeDraftView } from '../../context/realtimeDraftView'
import { useRealtimeView } from '../../context/realtimeView'
import RealtimeViewErrorBoundary from './realtimeViewErrorBoundary'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import AnimatedLoader from '../loaders/animatedLoader'
import RealtimeViewError from './realtimeViewError'
import RealtimeViewEmpty from './realtimeViewEmpty'
import { emptyStateErrorMessages } from './constants'

const RealtimeDraftView = () => {
  const { tenantStandardParams } = useCanvas()

  const { parsedGML: defaultParsedGML } = useRealtimeView()

  const { isFetching, error, parsedGML } = useRealtimeDraftView()

  const [gml, setGML] = useState(undefined)

  useEffect(() => {
    setGML(parsedGML || defaultParsedGML)
  }, [parsedGML, defaultParsedGML])

  if (error) {
    if (emptyStateErrorMessages.includes(error?.errorMessage)) {
      return <RealtimeViewEmpty />
    } else {
      return <RealtimeViewError title="Error" subTitle="Error loading metric" error={error} />
    }
  }

  return (
    <div className="w-full h-full">
      {isFetching ? (
        <AnimatedLoader
          className="my-12"
          title="Loading Metric"
          subTitle="Please wait..." />
      ) : (
        <div style={{ minHeight: 232 }}>
          {!isFetching && gml && (
            <RealtimeViewErrorBoundary>
              <GMLRenderer
                tree={gml}
                debug={false}
                useContentType={false}
                channel="web"
                size="xl"
                registeredData={{
                  ...tenantStandardParams
                }}
              />
            </RealtimeViewErrorBoundary>
          )}
        </div>
      )}
    </div>
  )
}

export default RealtimeDraftView
