import { v4 as uuid } from 'uuid'

const subscriptions = {}

export const subscribe = (eventType, callback) => {
  const id = uuid()

  if (!subscriptions[eventType]) {
    subscriptions[eventType] = {}
  }

  subscriptions[eventType][id] = callback

  return {
    unsubscribe: () => {
      delete subscriptions[eventType][id]
    }
  }
}

export const publish = (eventType, arg) => {
  if (!subscriptions[eventType]) return

  Object.keys(subscriptions[eventType]).forEach((key) => subscriptions[eventType][key](arg))
}

export default {
  subscribe,
  publish,
}
