import React from 'react'
import get from 'lodash/get'
import GenericError from './genericError'

class GenericErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      info: undefined
    }
  }

  componentDidCatch(error, info) {
    this.setState({ error, info })
  }

  render() {
    if (this.state.error) {
      const error = {
        errorMessage: get(this.state, 'error.message'),
        errorStack: get(this.state, 'error.stack'),
        componentStack: get(this.state, 'info.componentStack'),
        wrappedErrorMessage: get(this.state, 'error.err.message'),
        wrappedErrorStack: get(this.state, 'error.err.stack')
      }
      return (
        <div className={this.props.className || ''}>
          <GenericError
            title={this.props.title}
            subTitle={this.props.subTitle}
            error={error} />
        </div>
      )
    }
    return this.props.children
  }
}

export default GenericErrorBoundary
