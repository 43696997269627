import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchString } from '../hooks/useSearchString'

const DebugContext = React.createContext()

export function DebugProvider({ children }) {
  const { searchParams } = useSearchString()

  const [debug, setDebug] = useState(true)
  const [canDebug, setCanDebug] = useState(false)
  const [prefix, setPrefix] = useState(null)

  const onChange = useCallback(() => {
    setDebug(window.location.href.indexOf('debug') !== -1)
  }, [])

  const grpcPrefix = useMemo(() => {
    if (!canDebug) {
      return null
    }

    if (prefix) {
      return prefix
    }

    const _prefix = searchParams.get('grpc_prefix')
    if (_prefix && prefix === null) {
      setPrefix(_prefix)
      return prefix
    }
  }, [canDebug, prefix, searchParams])

  useEffect(() => {
    setDebug(window.location.href.indexOf('debug') !== -1)
    window.addEventListener('hashchange', onChange)
    return () => {
      window.removeEventListener('hashchange', onChange)
    }
  }, [onChange])

  const contextValue = useMemo(() => {
    return {
      debug: debug && canDebug,
      grpcPrefix,
      canDebug,
      setCanDebug,
    }
  }, [debug, canDebug, grpcPrefix])

  return <DebugContext.Provider value={contextValue}>{children}</DebugContext.Provider>
}

export function useDebug() {
  const context = React.useContext(DebugContext)
  if (context === undefined) {
    throw new Error('useDebug must be used within a DebugProvider')
  }
  return context
}
