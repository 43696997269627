import React from 'react'
import PropTypes from 'prop-types/prop-types'
import ChartText from '../controls/chartText'
import { pluginTypes } from '.'
import { offsetPropTypes, svgTextPropTypes } from '../propTypes/common'
import get from 'lodash/get'

export const defaultConfig = {
  label: {
    offset: {
      x: 0,
      y: -15,
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'normal',
      fontSize: 12,
      fontFamily: 'Proxima Nova',
      fill: '#09242f',
    },
  },
  value: {
    offset: {
      x: 0,
      y: 10,
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'bold',
      fontSize: 30,
      fontFamily: 'Proxima Nova',
      fill: '#3c45d9',
    },
  },
}

const pluginConfigPropTypes = {
  label: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  value: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  hide: PropTypes.bool,
}

export const DonutHolePlugin = (config) => {
  const { chartRect } = config

  const pluginConfig = {
    ...defaultConfig,
    ...get(config, `plugins.${pluginTypes.donutHole}`, {})
  }
  PropTypes.checkPropTypes(pluginConfigPropTypes, pluginConfig, 'prop', 'DonutHolePlugin')

  const { label, value } = pluginConfig

  const labelAttributes = {
    style: {
      ...label.style
    },
    x: chartRect.x + label.offset.x + (chartRect.width / 2),
    y: chartRect.y + label.offset.y + (chartRect.height / 2),
  }

  const valueAttributes = {
    style: {
      ...value.style
    },
    x: chartRect.x + value.offset.x + (chartRect.width / 2),
    y: chartRect.y + value.offset.y + (chartRect.height / 2),
  }

  return (
    <g key="donuthole" className="g-plugin-donuthole">
      <ChartText {...labelAttributes}>{pluginConfig.config.label}</ChartText>
      <ChartText {...valueAttributes}>{pluginConfig.config.value}</ChartText>
    </g>
  )
}
