import React, { useCallback, useEffect, useMemo } from 'react'
import { matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, batch } from 'react-redux'
import Button from './button'
import { useRoutes } from '../../context/routes'
import { clearApiActivityByKey, clearSignals } from '../../actions'
import { getSignalStrengthsByUserKey, getSignalWeaknessesByUserKey, getSignalsByUserKey } from '../../actions/signalService'
import { getNotesByAttachmentKey } from '../../actions/noteService'
import { getOpportunitiesByTeamKey } from '../../actions/opportunityService'
import iconScorecard from '../../assets/icon-scorecard.png'
import iconScorecardActive from '../../assets/icon-scorecard-active.png'
import iconSignals from '../../assets/icon-signals.png'
import iconSignalsActive from '../../assets/icon-signals-active.png'
import iconCoach from '../../assets/icon-coach.png'
import iconCoachActive from '../../assets/icon-coach-active.png'
import iconDeals from '../../assets/icon-deals.png'
import iconDealsActive from '../../assets/icon-deals-active.png'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'

const RepButtonMenu = (props) => {
  const { id, coachingFeatureEnabled } = props

  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames } = useNearlineFeatures()

  const { checkPermissions } = usePermissions()

  const { routes } = useRoutes()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessNote
    )
  }, [checkPermissions])

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const repPath = routes.rep.replace(':userId', id)
  const scorecardRoute = `${repPath}`
  const signalsRoute = `${repPath}/signals`
  const coachingRoute = `${repPath}/coaching`
  const dealsRoute = `${repPath}/deals`

  const scorecardMatch = useRouteMatch(scorecardRoute)
  const signalsMatch = useRouteMatch(signalsRoute)
  const coachingMatch = useRouteMatch(coachingRoute)
  const dealsMatch = useRouteMatch(dealsRoute)

  const onClick = useCallback((route, buttonName) => {
    batch(() => {
      dispatch(clearApiActivityByKey(getOpportunitiesByTeamKey))
      dispatch(clearApiActivityByKey(getNotesByAttachmentKey))
      dispatch(clearApiActivityByKey(getSignalStrengthsByUserKey))
      dispatch(clearApiActivityByKey(getSignalWeaknessesByUserKey))
      dispatch(clearApiActivityByKey(getSignalsByUserKey))
      dispatch(clearSignals())
    })

    window.analytics.track('rep.buttonClicked', { buttonName })

    history.push(route)
  }, [id])

  const path = useMemo(() => {
    return location?.pathname ?? ''
  }, [location])

  useEffect(() => {
    if (path) {
      if (matchPath(path, { path: scorecardRoute, exact: true, strict: false })) {
        window.analytics.track('rep.pageLoaded', { pageName: 'Scorecard' })
      } else if (matchPath(path, { path: signalsRoute, exact: false, strict: false })) {
        window.analytics.track('rep.pageLoaded', { pageName: 'Signals' })
      } else if (matchPath(path, { path: coachingRoute, exact: false, strict: false })) {
        window.analytics.track('rep.pageLoaded', { pageName: 'Coach' })
      } else if (matchPath(path, { path: dealsRoute, exact: false, strict: false })) {
        window.analytics.track('rep.pageLoaded', { pageName: 'Deals' })
      }
    }
  }, [path])

  return (
    <div className="flex my-8">
      <div className="flex mx-auto">
        <Button
          text="Scorecard"
          isCurrent={scorecardMatch && scorecardMatch.isExact}
          icon={<img src={scorecardMatch && scorecardMatch.isExact ? iconScorecardActive : iconScorecard} />}
          handleClick={() => onClick(scorecardRoute, 'Scorecard')} />

        {isAccessNearlineCheckReady && !canAccessNearlineFeature({ featureName: featureNames.deals }) && (
          <Button
            text="Signals"
            isCurrent={signalsMatch}
            icon={<img src={signalsMatch ? iconSignalsActive : iconSignals} />}
            handleClick={() => onClick(signalsRoute, 'Signals')} />
        )}

        {coachingFeatureEnabled && permissions.CanAccessNote
          && (
            <Button
              text="Coach"
              isCurrent={coachingMatch}
              icon={<img src={coachingMatch ? iconCoachActive : iconCoach} />}
              handleClick={() => onClick(coachingRoute, 'Coach')} />
          )}

        <Button
          text="Deals"
          isCurrent={dealsMatch}
          icon={<img src={dealsMatch ? iconDealsActive : iconDeals} />}
          handleClick={() => onClick(dealsRoute, 'Deals')} />
      </div>
    </div>
  )
}

export default RepButtonMenu
