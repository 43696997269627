import React from 'react'

const StarLg = () => {
  return (
    <svg width="50px" height="50px" viewBox="0 0 50 50">
      <defs>
        <linearGradient x1="44.2116477%" y1="-36.8652344%" x2="54.0056818%" y2="124.902344%" id="linearGradient-1">
          <stop offset="0%" style={{ stopColor: '#9B97FF', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#7771FC', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g id="Empty-States" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Rep-Highlights" transform="translate(-847.000000, -515.000000)">
          <g id="Group-3" transform="translate(847.000000, 515.000000)">
            <circle id="Oval" fill="url(#linearGradient-1)" cx="25" cy="25" r="25" />
            <polygon id="Star-Copy-6" fill="#FFFFFF" transform="translate(26.785714, 23.660714) rotate(45.000000) translate(-26.785714, -23.660714) " points="26.7857143 33.0658459 17.9566573 37.9464286 19.6428571 27.6091886 12.5 20.2883146 22.3711858 18.7801316 26.7857143 9.375 31.2002428 18.7801316 41.0714286 20.2883146 33.9285714 27.6091886 35.6147713 37.9464286" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StarLg
