import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { featureNames } from '../../constants/featureNames'
import { featureSettingSelector } from '../../selectors'
import { filter, find, forEach, has, cloneDeep } from 'lodash'
import { FilterOperation, SearchFieldType } from '../../grpc/enums'
import { canonicalFieldKeys, canopyFields, fieldRenderTypes, formatTypes } from '../fieldRenderers/constants'
import { useForecastingFeature } from '../forecasting/hooks'
import { useNotification } from '../../hooks/useNotification'
import { useGrpcCallback } from '../../grpc'
import { useViews } from '../../context/views'
import { toUpsertSavedFiltersByKeyRequest } from '../../grpc/converters'

export function usePipelineFeature() {
  const pipelineFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.pipeline }))

  const enabled = useMemo(() => {
    return pipelineFeature?.payload?.enabled ?? false
  }, [pipelineFeature])

  const primaryFields = useMemo(() => {
    return pipelineFeature?.payload?.primaryFields ?? []
  }, [pipelineFeature])

  const readonlyOverride = useMemo(() => {
    return pipelineFeature?.payload?.readonly ?? false
  }, [pipelineFeature])

  const version = useMemo(() => {
    return pipelineFeature?.payload?.version ?? ''
  }, [pipelineFeature])

  return {
    payload: pipelineFeature && pipelineFeature.payload,
    hasData: pipelineFeature && has(pipelineFeature, 'payload'),
    enabled,
    primaryFields,
    readonlyOverride,
    version,
  }
}

export function useExplicitViews() {
  const organization = useSelector((state) => state.organization)
  const { periodStart, periodEnd } = organization

  const { forecastCategories } = useForecastingFeature()
  const objectDefinitions = useSelector((state) => state.objectDefinitions)
  const fieldList = (objectDefinitions.opportunity && objectDefinitions.opportunity.fieldsList) || []
  const fieldDefinition = find(fieldList, (f) => f.key === 'forecastcategoryname')

  const forecastCategoryNames = useMemo(() => {
    const crmForecastCategories = []
    if (fieldDefinition && fieldDefinition.options && fieldDefinition.options.itemsList) {
      forEach(fieldDefinition.options.itemsList, (i) => {
        try {
          const forecastCategory = JSON.parse(atob(i.value))
          crmForecastCategories.push(forecastCategory)
          return {
            label: i.label,
            value: JSON.parse(atob(i.value)),
          }
        } catch (err) {
          console.log(`JSON.parse error on ${i.value}`)
        }
      })
    }
    return filter(forecastCategories.map((fc) => fc.key), (fc) => crmForecastCategories.includes(fc))
  }, [forecastCategories, fieldDefinition])

  return periodStart && periodEnd ? [
    {
      key: 'currentPipeline',
      label: 'Current Pipeline',
      order: 1,
      readonly: true,
      explicit: true,
      payload: {
        query: {
          children: {
            combineAction: 1,
            valuesList: [
              {
                node: {
                  item: {
                    name: canopyFields._status.key,
                    type: SearchFieldType.STRING,
                    source: 'canopy'
                  },
                  valuesList: [
                    'offTrack',
                    'onTrack',
                    'stalled'
                  ],
                  operation: FilterOperation.IN,
                  _filter: {
                    fieldDefinition: {
                      key: canopyFields._status.key,
                      label: 'Status',
                      format: formatTypes._status,
                      toType: fieldRenderTypes.STRING,
                    },
                    type: 'MultiSelectFilter',
                    data: {
                      selected: [
                        {
                          label: 'Off Track',
                          value: 'offTrack',
                          checked: true
                        },
                        {
                          label: 'On Track',
                          value: 'onTrack',
                          checked: true
                        },
                        {
                          label: 'Stalled',
                          value: 'stalled',
                          checked: true
                        }
                      ]
                    }
                  }
                }
              },
              {
                node: {
                  item: {
                    name: canonicalFieldKeys.closedate,
                    type: SearchFieldType.DATE,
                    source: 'canonical'
                  },
                  valuesList: [
                    periodStart,
                    periodEnd
                  ],
                  operation: FilterOperation.BETWEENIN,
                  _filter: {
                    fieldDefinition: {
                      key: canonicalFieldKeys.closedate,
                      label: 'Close Date',
                      format: formatTypes.date,
                      toType: fieldRenderTypes.DATE,
                    },
                    type: 'DateRangeFilter',
                    data: {
                      startDate: `${periodStart}T00:00:00.000`,
                      endDate: `${periodEnd}T23:59:59.999`,
                      isTimestamp: false
                    }
                  }
                }
              },
            ]
          }
        }
      }
    },
  ] : []
}

export function useSaveUserViews({ filterKey }) {
  const { invalidate } = useViews()

  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(false)

  const upsertSavedFilters = useGrpcCallback({
    onSuccess: (obj, data) => {
      setIsFetching(false)
      invalidate()
      if (data && data.onSuccess) {
        data.onSuccess()
      }
    },
    onError: (err) => {
      setIsFetching(false)
      invalidate()
      notifyError('Error saving views!')
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'upsertSavedFiltersByKey',
    grpcMethodName: 'upsertSavedFilters',
    debug: false,
  }, [])

  const saveUserViews = useCallback((userViews, data) => {
    const views = filter(cloneDeep(userViews), (v) => v.order > -1 && !v.explicit)
    forEach(views, (v, index) => {
      v.order = index
    })

    const valueMap = views.map((v, index) => {
      return [
        v.key,
        {
          key: v.key,
          label: v.label,
          order: index + 1,
          payload: btoa(JSON.stringify(v.payload))
        }
      ]
    })
    const request = toUpsertSavedFiltersByKeyRequest({
      key: filterKey,
      setting: {
        valueMap,
      },
    })
    upsertSavedFilters(request, data)
  }, [filterKey, upsertSavedFilters])

  return {
    isFetching,
    saveUserViews,
  }
}
