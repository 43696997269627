import React, { useEffect, useCallback, useMemo } from 'react'
import { useNotification } from '../../../hooks/useNotification'
import { getCurrentForecastCategoryCall, getPreviousForecastCategoryCall } from '../helpers'
import ForecastCall from '../forecastCall'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react'
import { useFormatCurrency } from '../../../hooks/useFormatCurrency'
import { usePermissions } from '../../../context/permissions'
import { permissionNames } from '../../../constants/permissionNames'
import { useForecasting } from '../../../context/forecasting'

const ForecastCalls = (props) => {
  const {
    forecastCategories
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateForecast
    )
  }, [checkPermissions])

  const { formatCurrency } = useFormatCurrency()

  const { notifyError } = useNotification()

  const { forecast, saveForecastedCalls, error, isPastForecastPeriod } = useForecasting()

  const updatedAt = useMemo(() => {
    return forecast?.current?.updatedAt?.seconds
  }, [forecast])

  useEffect(() => {
    if (error) {
      notifyError(error)
    }
  }, [notifyError, error])

  const onCallChange = useCallback((forecastCalls) => {
    saveForecastedCalls(forecastCalls)
    window.analytics.track('forecasting.dock.forecastCalls.callChanged')
  }, [saveForecastedCalls])

  const onRemoveCall = useCallback((forecastCategory) => {
    saveForecastedCalls({ [forecastCategory.id]: getPreviousForecastCategoryCall(forecastCategory.id, forecast) }, false)
    window.analytics.track('forecasting.dock.forecastCalls.callRemoved')
  }, [forecast, saveForecastedCalls])

  const categoryCalls = useMemo(() => {
    return forecastCategories.map((forecastCategory, index) => {
      const { id, name, readOnly, autoCalculate } = forecastCategory
      const value = getCurrentForecastCategoryCall(id, forecast, null, true)
      const previousValue = getPreviousForecastCategoryCall(id, forecast)
      const pipelineValue = getCurrentForecastCategoryCall(id, forecast, null, true)
      const validator = (newValue) => {
        return newValue >= 0
      }
      const validatorMessage = `Amount needs to be greater than or equal to ${formatCurrency(0)}`
      const readonly = !permissions.CanUpdateForecast || readOnly || isPastForecastPeriod
      const showLabelDecorator = forecast.previous && Math.round(value) !== Math.round(previousValue) && !autoCalculate && !readonly && !isPastForecastPeriod
      const labelDecorator = !showLabelDecorator ? undefined : (
        <div
          onClick={() => onRemoveCall(forecastCategory)}
          className="absolute right-0 cursor-pointer"
          style={{ transform: 'translate(-8px, -16px)' }}>
          <FontAwesomeIcon icon="trash" size="xs" className="text-color-a0a8bb hover:text-color-151d49" style={{ width: 12, height: 12 }} />
        </div>
      )
      const showTooltip = !readonly && Math.round(value) !== Math.round(pipelineValue) && !isPastForecastPeriod
      const tooltip = (
        <div className="px-4 py-3">
          <div>
            <div className="text-size-18px text-color-09242f font-bold my-2">Pipeline Difference</div>
          </div>
          <div className="text-size-14px text-color-09242f font-weight-400 leading-snug">
            Based on the
            {' '}
            <span className="font-bold">{formatCurrency(pipelineValue)}</span>
            {' '}
            in your
            {' '}
            {forecastCategory.name}
            {' '}
            pipeline, your call would be
            {' '}
            <span className="font-bold">{formatCurrency(value)}</span>
            .  Would you like to use this pipeline-based call instead?
          </div>
          <div className="flex flex-col justify-center">
            <button
              onClick={() => onCallChange({ [id]: pipelineValue })}
              className="my-3 mx-auto px-8 py-3 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none">
              Use Pipeline
            </button>
          </div>
        </div>
      )
      return (
        <div
          key={`forecastCategory-${id}-${index}-${updatedAt}`}
          className="flex items-start justify-between pt-2 pb-2 first:pt-1">
          <div className="text-size-16px text-color-09242f flex-1 whitespace-nowrap overflow-hidden truncate pr-1">{name}</div>
          <div className="flex items-start justify-between">
            {showTooltip
              && (
                <Tippy
                  offset={[0, 2]}
                  duration={[0, 0]}
                  maxWidth={800}
                  content={tooltip}
                  interactive={true}
                  theme="canopy">
                  <div className="mx-1">
                    <FontAwesomeIcon icon="info-circle" color="#A6AEBE" />
                  </div>
                </Tippy>
              )}
            <ForecastCall
              className="inline-block"
              style={{ height: 26 }}
              inputClassName="w-full px-2 py-1 bg-color-fafafa border border-color-d6d9e6 rounded focus:outline-none text-size-15px font-normal text-right"
              inputStyle={{ transform: 'translateY(-5px)' }}
              labelClassName={classNames('text-size-16px text-color-09242f', { 'underline-dashed cursor-pointer': !readonly }, { 'pointer-events-none cursor-default': readonly })}
              forecastCategory={forecastCategory}
              value={value}
              validator={validator}
              validatorMessage={validatorMessage}
              readonly={readonly}
              onCallChange={onCallChange}
              labelDecorator={labelDecorator}
              notifyError={notifyError} />
          </div>
        </div>
      )
    })
  }, [forecastCategories, forecast, formatCurrency, permissions.CanUpdateForecast, isPastForecastPeriod, updatedAt, onCallChange, notifyError, onRemoveCall])

  return (
    <>
      {categoryCalls}
    </>
  )
}

export default ForecastCalls
