import React, { useCallback, useMemo, useState } from 'react'
import { useTextField } from '../hooks/useTextField'
import { useCanvasItem } from './canvasItem'
import { useRealtimeDraftView } from './realtimeDraftView'
import { useRealtimeView } from './realtimeView'
import { find } from 'lodash'

const RealtimeViewModalContext = React.createContext()

export function RealtimeViewModalProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)
  const nameTextField = useTextField()

  const {
    setPendingFilter,
    userQueryParametersList,
    defaultUserFilter,
    defaultOrgFilter
  } = useCanvasItem()

  const {
    selectedFilter,
    setSelectedFilter
  } = useRealtimeView()

  const {
    selectedFilter: selectedDraftFilter,
    setSelectedFilter: setSelectedDraftFilter,
    invalidate,
    reset
  } = useRealtimeDraftView()

  const openModal = useCallback(() => {
    setSelectedDraftFilter(selectedFilter)
    invalidate()
    setIsModalOpen(true)
  }, [selectedFilter, setSelectedDraftFilter])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    const filter = find(userQueryParametersList, (uqp) => uqp.id === selectedDraftFilter?.id && !uqp.isPending)
    if (filter) {
      setSelectedFilter(filter)
    } else if (defaultUserFilter) {
      setSelectedFilter(defaultUserFilter)
    } else if (defaultOrgFilter) {
      setSelectedFilter(defaultOrgFilter)
    } else {
      setSelectedFilter(userQueryParametersList[0])
    }
    reset()
    setPendingFilter(undefined)
  }, [selectedDraftFilter, setSelectedFilter, setPendingFilter, reset, userQueryParametersList, defaultUserFilter, defaultOrgFilter])

  const contextValue = useMemo(() => {
    return {
      isModalOpen,
      openModal,
      closeModal,
      isEditingName,
      setIsEditingName,
      nameTextField
    }
  }, [isModalOpen, openModal, closeModal, isEditingName, nameTextField])

  return <RealtimeViewModalContext.Provider value={contextValue}>{children}</RealtimeViewModalContext.Provider>
}

export function useRealtimeViewModal() {
  const context = React.useContext(RealtimeViewModalContext)
  if (context === undefined) {
    throw new Error('useRealtimeViewModal must be used within a RealtimeViewModalProvider')
  }
  return context
}
