import React from 'react'
import classNames from 'classnames'

const SideMenuItem = (props) => {
  const {
    label,
    icon,
    onClick,
    isCurrent
  } = props

  const highlightClass = classNames('sidemenu-item-highlight z-10 absolute w-full h-full', { 'sidemenu-highlight': isCurrent })

  const linkClass = classNames(
    'sidemenu-item-link',
    'cursor-pointer',
    'w-full',
    'z-20',
    'font-medium',
    'hidden',
    'md:flex',
    'items-center',
    'py-1',
    'border-color-transparent',
    'select-none',
    { 'sidemenu-hover': !isCurrent },
    { 'sidemenu-selected': isCurrent },
    { 'pointer-events-none': isCurrent }
  )

  return (
    <div className="sidemenu-item flex relative">
      <div className={highlightClass} />
      <a
        className={linkClass}
        onClick={onClick}>
        {icon && <div className="flex items-center justify-center flex-shrink-0 w-18 h-9">{icon}</div>}
        <span className="pr-2">{label}</span>
      </a>
    </div>
  )
}

export default SideMenuItem
