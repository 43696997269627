import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Popover } from '@material-ui/core'
import { usePopover } from '../../hooks/usePopover'
import { find } from 'lodash'
import { useFieldDefinitionOptions } from './hooks'
import FieldDebug from './fieldDebug'
import SingleSelectFieldRowItemDisplay from './singleSelectFieldRowItemDisplay'
import { ReferenceFieldOptionsLabelsProvider } from '../../context/referenceFieldOptionsLabels'
import SingleSelectFieldRowItemOptions from './singleSelectFieldRowItemOptions'
import { ReferenceFieldOptionsListProvider } from '../../context/referenceFieldOptionsList'

const SingleSelectFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
    readonlyOverride = false,
    onFieldChanged,
    fieldReference
  } = props

  const popoverActions = useRef()

  const [search, setSearch] = useState('')

  const options = useFieldDefinitionOptions(fieldDefinition)

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    setSelectedOption(find(options, (o) => o.value === field.originalValue))
  }, [field, options])

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  const handleClearSearch = useCallback(() => {
    setSearch('')
  }, [])

  const lazyLoad = useMemo(() => {
    return !!fieldReference?.objectName
  }, [fieldReference])

  const lazyLoadValues = useMemo(() => {
    return [field?.originalValue ?? '']
  }, [field])

  const lazyLoadDisplayValue = useMemo(() => {
    // if lazy loading is enabled for this field,
    // then field.value holds the resolved value...
    // so there is no need to try and resolve the display value
    return false
  }, [])

  return (
    <div className="SingleSelectFieldRowItem">
      <ReferenceFieldOptionsLabelsProvider
        isEnabled={lazyLoadDisplayValue}
        fieldReference={fieldReference}
        lazyLoadValues={lazyLoadValues}>
        <SingleSelectFieldRowItemDisplay
          fieldDefinition={fieldDefinition}
          field={field}
          readonlyOverride={readonlyOverride}
          selectedOption={selectedOption}
          onClick={onClick}
          lazyLoad={lazyLoadDisplayValue} />
      </ReferenceFieldOptionsLabelsProvider>
      <ReferenceFieldOptionsListProvider
        limit={50}
        isEnabled={open && lazyLoad}
        fieldReference={fieldReference}
        search={search}>
        <Popover
          action={popoverActions}
          style={{ zIndex: 999999999 }}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          TransitionProps={{
            onExited: handleClearSearch
          }}>
          <div className="flex flex-col">
            <SingleSelectFieldRowItemOptions
              search={search}
              setSearch={setSearch}
              opportunity={opportunity}
              fieldDefinition={fieldDefinition}
              field={field}
              onFieldChanged={onFieldChanged}
              setSelectedOption={setSelectedOption}
              closePopover={onClose}
              popoverActions={popoverActions}
              lazyLoad={lazyLoad} />
          </div>
        </Popover>
      </ReferenceFieldOptionsListProvider>
      <FieldDebug
        debug={debug}
        data={{ fieldDefinition, field }} />
    </div>
  )
}

export default SingleSelectFieldRowItem
