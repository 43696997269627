import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patchSearchItem } from '../../services/searchService'
import { find, forEach, forOwn } from 'lodash'
import { usePipelineFeature } from './hooks'
import EmptyState from '../empty/emptyState'
import { apiFailedSelector, apiRequestedSelector } from '../../selectors'
import { patchSearchItemKey, searchOppsKey } from '../../actions/searchService'
import { useNotification } from '../../hooks/useNotification'
import PatchErrorModal from './patchErrorModal'
import { useModal } from '../../hooks/useModal'
import pageIcon from '../../assets/pageIcon.png'
import { defaultTake } from './constants'
import { Column } from 'react-base-table'
import { canopyFields, fieldRenderTypes } from '../fieldRenderers/constants'
import { getFieldFromObject, getFieldRenderType } from '../fieldRenderers/helpers'
import DealMenu from './dealMenu'
import DealNameCell from './dealNameCell'
import BoolFieldRowItem from '../fieldRenderers/boolFieldRowItem'
import SingleSelectFieldRowItem from '../fieldRenderers/singleSelectFieldRowItem'
import StatusFieldRowItem from '../fieldRenderers/statusFieldRowItem'
import DateFieldRowItem from '../fieldRenderers/dateFieldRowItem'
import MultiSelectFieldRowItem from '../fieldRenderers/multiSelectFieldRowItem'
import NumberFieldRowItem from '../fieldRenderers/numberFieldRowItem'
import StringFieldRowItem from '../fieldRenderers/stringFieldRowItem'
import TimestampFieldRowItem from '../fieldRenderers/timestampFieldRowItem'
import LastModifiedFieldRowItem from '../fieldRenderers/lastModifiedFieldRowItem'
import UnknownFieldRowItem from '../fieldRenderers/unknownFieldRowItem'
import { clearOppDetail, setDock } from '../../actions'
import SignalCountCell from './signalCountCell'
import DealHealthCell from './dealHealthCell'
import { useLocationData } from '../location/hooks'
import { useHistory } from 'react-router-dom'
import { useUserPrefs } from '../../context/userPrefs'
import { useTenantInfo } from '../../context/tenantInfo'
import BaseTableExtended from '../tables/baseTableExtended'
import useIsReadOnly from '../../hooks/useIsReadOnly'
import { useUserInput } from '../../hooks/useUserInput'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { ViewDealInCrmProvider } from '../../context/viewDealInCrm'
import { secondsToDate } from '../../lib/dateFns'
import { format } from 'date-fns'

const PipelineGrid = (props) => {
  const {
    style,
    enableDealClick = true,
    changeSince,
    useTotalRowsHeight = false,
    onWriteBackSuccess,
    isDealHealthEnabled = false,
    debug = false
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadDeal
    )
  }, [checkPermissions])

  const { savePref, getPref, deletePref } = useUserPrefs()

  const dispatch = useDispatch()
  const { notifyError } = useNotification()

  const { location, hash } = useLocationData()
  const history = useHistory()

  const { isFetching: tenantInfoLoading } = useTenantInfo()

  const { encodeString } = useUserInput()

  const dock = useSelector((state) => state.dock)

  const { primaryFields, readonlyOverride } = usePipelineFeature()

  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const { isReadOnly, canWrite } = useIsReadOnly()

  useEffect(() => {
    return () => {
      dispatch(clearOppDetail())
      dispatch(setDock({ dealDetail: { enabled: false } }))
    }
  }, [dispatch])

  const searchFilters = useSelector((state) => state.searchFilters)
  const opps = useSelector((state) => state.searchedOpps)
  const apiRequested = useSelector((state) => apiRequestedSelector(state, searchOppsKey))
  const apiFailed = useSelector((state) => apiFailedSelector(state, searchOppsKey))
  const patchApiFailed = useSelector((state) => apiFailedSelector(state, patchSearchItemKey))
  const apiActivity = useSelector((state) => state.apiActivity)

  const { total = 0 } = opps
  const { searchText, viewInitialized } = searchFilters

  const [sortKey, setSortKey] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [oppsLoading, setOppsLoading] = useState(false)
  const [updatingRows, setUpdatingRows] = useState([])

  const modal = useModal()

  useEffect(() => {
    if (patchApiFailed) {
      modal.setOpen(true)
    }
  }, [patchApiFailed, modal])

  useEffect(() => {
    setOppsLoading(apiActivity[searchOppsKey] === 'requested' || tenantInfoLoading)
  }, [apiActivity, tenantInfoLoading])

  const changeSinceRenderer = useCallback(() => {
    if (!oppsLoading && changeSince) {
      const { seconds = 0 } = changeSince
      const date = format(secondsToDate(seconds), 'M/d/yyyy')
      const nowSeconds = Math.floor(Date.now() / 1000)
      const isFuture = seconds > nowSeconds
      return (
        <div className="ml-2 text-color-818e93 text-size-14px font-weight-400">
          {isFuture
            ? 'Showing no change history'
            : (
              <>
                Showing changes since
                {' '}
                {date}
              </>
            )}
        </div>
      )
    }
  }, [oppsLoading, changeSince])

  const onDealClick = useCallback((opportunity) => {
    return () => {
      if (!dock.dealDetail || !dock.dealDetail.enabled || !dock.dealDetail.opportunity || dock.dealDetail.opportunity.id !== opportunity.id) {
        dispatch(clearOppDetail())
        dispatch(setDock({ dealDetail: { enabled: true, fetchOppDetail: true, opportunity } }))
      } else {
        dispatch(clearOppDetail())
        dispatch(setDock({ dealDetail: { enabled: false } }))
      }
    }
  }, [dispatch, dock])

  const showEmptyState = useMemo(() => {
    return total === 0 && !apiRequested && !apiFailed
  }, [total, apiRequested, apiFailed])

  const onFieldChanged = useCallback((opportunity, field) => {
    debug && console.log('onFieldChanged', opportunity, field)

    const { name, value } = field

    const obj = {
      objectName: 'opportunity',
      item: {
        id: opportunity.id,
        lastModifiedDateString: opportunity.lastModifiedDateString,
        fieldsMap: [
          [
            name,
            {
              instancesList: [
                {
                  ...field,
                  value: encodeString(value)
                }
              ]
            }
          ]
        ]
      },
      changeSince
    }
    // Add the id to the updatingRows array
    setUpdatingRows((prev) => ([...prev, opportunity.id]))
    dispatch(patchSearchItem(opportunity, obj, notifyError, field.name === 'closeDate', debug, () => {
      // Remove the id from the updatingRows array on complete
      setUpdatingRows((prev) => prev.filter((p) => p !== opportunity.id))
      onWriteBackSuccess && onWriteBackSuccess()
      window.analytics.track('dealGrid.fieldChanged', { label: field.label })
    }))
  }, [dispatch, notifyError, debug, changeSince, onWriteBackSuccess, encodeString])

  const dynamicCellRenderer = useCallback((opportunity, fieldData) => {
    const { fieldKey, field, fieldDefinition } = fieldData
    let component = <></>
    if (fieldKey === '_internalupdatedat') {
      component = (
        <TimestampFieldRowItem
          debug={debug}
          opportunity={opportunity}
          fieldDefinition={canopyFields._internalupdatedat}
          field={field}
          showDaysRelative={true} />
      )
    } else if (fieldKey && fieldDefinition) {
      const fieldIsReadonly = readonlyOverride || isReadOnly(opportunity, fieldDefinition)
      const fieldReference = fieldDefinition.referencesList?.[0]
      if (!field) {
        if (fieldKey === 'lastmodifieddate' || fieldKey === 'systemmodstamp') {
          component = (
            <LastModifiedFieldRowItem
              debug={debug}
              opportunity={opportunity}
              field={{ field }} />
          )
        } else {
          component = (
            <UnknownFieldRowItem
              debug={debug}
              opportunity={opportunity}
              field={{ field }} />
          )
        }
      } else if (fieldReference) {
        component = (
          <SingleSelectFieldRowItem
            debug={debug}
            opportunity={opportunity}
            fieldDefinition={fieldDefinition}
            field={field}
            readonlyOverride={fieldIsReadonly}
            onFieldChanged={onFieldChanged}
            fieldReference={fieldReference} />
        )
      } else if (fieldDefinition) {
        const renderType = getFieldRenderType(fieldDefinition)
        switch (renderType) {
          case fieldRenderTypes.DATE:
            component = (
              <DateFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged}
                showDaysRelative={fieldKey === 'closedate'} />
            )
            break
          case fieldRenderTypes.TIMESTAMP:
            component = (
              <TimestampFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged}
                showDaysRelative={true} />
            )
            break
          case fieldRenderTypes.CHECKBOX:
            component = (
              <BoolFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged} />
            )
            break
          case fieldRenderTypes.NUMBER:
            component = (
              <NumberFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged} />
            )
            break
          case fieldRenderTypes.SINGLE_SELECT:
            component = (
              <SingleSelectFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged} />
            )
            break
          case fieldRenderTypes.MULTI_SELECT:
            component = (
              <MultiSelectFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged} />
            )
            break
          case fieldRenderTypes.STRING:
            component = (
              <StringFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={fieldIsReadonly}
                onFieldChanged={onFieldChanged} />
            )
            break
          case fieldRenderTypes._STATUS:
            component = (
              <StatusFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field} />
            )
            break
          case fieldRenderTypes.UNKNOWN:
            component = (
              <UnknownFieldRowItem
                debug={debug}
                opportunity={opportunity}
                fieldDefinition={fieldDefinition}
                field={field} />
            )
            break
          default:
        }
      }
    }

    return (
      <div className="w-full py-3 px-2 leading-tight text-left">
        {component}
      </div>
    )
  }, [debug, onFieldChanged, isReadOnly, readonlyOverride])

  const cellRenderer = useCallback((_props) => {
    const { rowData, column = {} } = _props
    const { opportunity, columns: cols = {} } = rowData
    const { key } = column
    return dynamicCellRenderer(opportunity, cols[key.toLowerCase()])
  }, [dynamicCellRenderer])

  const columns = useMemo(() => {
    if (objectDefinitions.opportunity) {
      return [
        {
          key: '_dealMenu_',
          dataKey: '_dealMenu_',
          width: 30,
          resizable: false,
          sortable: false,
          align: Column.Alignment.CENTER,
          cellRenderer: (_props) => {
            const { rowData } = _props
            const { opportunity } = rowData
            return (
              <ViewDealInCrmProvider
                opportunityId={opportunity?.id}
                analyticsTrackingArgs={['deal.viewInCrmClicked', { feature: 'dealMenu' }]}>
                <DealMenu opportunity={opportunity} />
              </ViewDealInCrmProvider>
            )
          }
        },
        {
          key: 'name',
          dataKey: 'name',
          title: 'Name',
          width: 250,
          resizable: true,
          sortable: true,
          cellRenderer: (_props) => {
            const { rowData } = _props
            const { opportunity } = rowData
            return (
              <DealNameCell
                opportunity={opportunity}
                enableDealClick={enableDealClick} />
            )
          }
        },
        {
          key: '_signalCount',
          dataKey: '_signalCount',
          title: 'Signals',
          width: 120,
          resizable: true,
          sortable: true,
          cellRenderer: (_props) => {
            const { rowData } = _props
            const { opportunity } = rowData
            return (
              <SignalCountCell
                icon="ellipsis-v"
                opportunity={opportunity} />
            )
          }
        },
        ...isDealHealthEnabled ? [
          {
            key: 'dealHealth',
            dataKey: 'dealHealth',
            title: 'Health',
            width: 120,
            resizable: true,
            sortable: false,
            cellRenderer: (_props) => {
              const { rowData } = _props
              const { opportunity } = rowData
              return (
                <DealHealthCell
                  opportunity={opportunity} />
              )
            }
          }
        ] : [],
        ...primaryFields.map((fieldName) => {
          const f = fieldName.startsWith('canopy_') ? fieldName.replace('canopy', '') : fieldName
          let label
          if (f.toLowerCase() === canopyFields._internalupdatedat.key) {
            label = canopyFields._internalupdatedat.label || f
          } else if (canopyFields[f]) {
            label = canopyFields[f].label || f
          } else {
            const fieldDef = find(objectDefinitions.opportunity.fieldsList, (field) => f && field && field.to && f.toLowerCase() === field.to.toLowerCase())
            label = fieldDef ? fieldDef.label : f
          }
          return {
            key: f,
            dataKey: f,
            title: label,
            canWrite,
            width: 150,
            resizable: true,
            sortable: true,
            cellRenderer
          }
        })
      ]
    }
    return []
  }, [objectDefinitions, primaryFields, cellRenderer, canWrite, enableDealClick, isDealHealthEnabled])

  const fixedColumns = useMemo(() => {
    const maxFrozenColumnIndex = 1
    return columns.map((column, columnIndex) => ({
      ...column,
      frozen: columnIndex <= maxFrozenColumnIndex ? Column.FrozenDirection.LEFT : Column.FrozenDirection.NONE
    }))
  }, [columns])

  const fieldsList = useMemo(() => {
    return objectDefinitions?.opportunity?.fieldsList ?? []
  }, [objectDefinitions])

  const data = useMemo(() => {
    return opps.valuesList.map((opportunity, rowIndex) => {
      const cols = {}
      forEach(columns, (col) => {
        const fieldKey = col.key.toLowerCase()
        cols[fieldKey] = {
          fieldKey,
          field: getFieldFromObject(opportunity, fieldKey),
          fieldDefinition: canopyFields[fieldKey] || find(fieldsList, (f) => f.key === fieldKey)
        }
      })
      return {
        id: `row-${rowIndex}`,
        isUpdating: updatingRows.includes(opportunity.id),
        parentId: null,
        enterAction: onDealClick(opportunity),
        opportunity,
        columns: cols
      }
    })
  }, [opps.valuesList, fieldsList, columns, updatingRows, onDealClick])

  const onSetPage = useCallback((page) => {
    const skip = (page - 1) * defaultTake

    let route = location.pathname
    let i = 0
    const h = {
      ...hash
    }
    h.skip = skip
    h.take = defaultTake
    if (skip === 0) {
      delete h.skip
      delete h.take
    }
    forOwn(h, (value, key) => {
      if (i === 0) {
        route += '#'
      } else {
        route += '&'
      }
      if (value) {
        route += `${key}=${value}`
      } else {
        route += key
      }
      i += 1
    })

    history.push(route)
  }, [history, location, hash])

  const onColumnSort = useCallback((sort) => {
    let sKey = sort.key || ''
    let sDirection = (sort.order || '').toUpperCase()
    if (sKey === sortKey && sortDirection === 'DESC') {
      sKey = ''
      sDirection = ''
    }

    setSortKey(sKey)
    setSortDirection(sDirection)
    if (sKey && sDirection) {
      savePref('pipelineGrid', 'sort', { sKey, sDirection })
    } else {
      deletePref('pipelineGrid', 'sort', sKey)
    }

    let route = location.pathname
    let i = 0
    const h = {
      ...hash
    }
    h.sortDirection = sDirection
    h.sortKey = sKey
    if (!sKey) {
      delete h.sortKey
    }
    if (!sDirection) {
      delete h.sortDirection
    }
    delete h.skip
    delete h.take
    forOwn(h, (value, key) => {
      if (i === 0) {
        route += '#'
      } else {
        route += '&'
      }
      if (value) {
        route += `${key}=${value}`
      } else {
        route += key
      }
      i += 1
    })

    // console.log('onColumnSort', sKey, sDirection, route)
    history.push(route)
  }, [history, sortKey, sortDirection, location, hash, deletePref, savePref])

  const sortBy = useMemo(() => {
    const { sKey, sDirection } = getPref('pipelineGrid', 'sort') || {}

    return {
      key: hash.sortKey || sKey || '',
      order: hash.sortDirection?.toLowerCase() || sDirection?.toLowerCase() || 'asc'
    }
  }, [hash, getPref])

  const rowClassName = useCallback(({ rowData, rowIndex }) => {
    if (rowData.isUpdating) {
      return 'animate-pulse-fast pointer-events-none'
    }
  }, [])

  useEffect(() => {
    if (!viewInitialized) return

    let route = location.pathname
    let i = 0
    const h = {
      ...hash
    }
    if ((h.search || searchText) && (h.search !== searchText)) {
      delete h.skip
      delete h.take
    }
    h.search = searchText
    if (!searchText) {
      delete h.search
    }
    forOwn(h, (value, key) => {
      if (i === 0) {
        route += '#'
      } else {
        route += '&'
      }
      if (value) {
        route += `${key}=${value}`
      } else {
        route += key
      }
      i += 1
    })

    history.push(route)
    // do not add more dependencies here or you will get undesired results when switching pages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, viewInitialized])

  return (
    <>
      <PatchErrorModal
        modal={modal} />

      {permissions.CanReadDeal && showEmptyState && (
        <div className="flex justify-center pt-10">
          <EmptyState
            iconControl={<img alt="Empty page" src={pageIcon} />}
            header="No Deals"
            subHeader="There are no deals to show." />
        </div>
      )}

      <BaseTableExtended
        data={data}
        records={opps}
        loading={oppsLoading}
        fixedColumns={fixedColumns}
        onColumnSort={onColumnSort}
        onSetPage={onSetPage}
        rowClassName={rowClassName}
        scrollDisabled={showEmptyState}
        sortBy={sortBy}
        style={style}
        total={total}
        useTotalRowsHeight={useTotalRowsHeight}
        changeSinceRenderer={changeSinceRenderer}
      />
    </>
  )
}

export default PipelineGrid
