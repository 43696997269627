import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { removeHeader, setHeader } from '../actions'
import { featureNames } from '../constants/featureNames'
import { featureSettingSelector } from '../selectors'
import { useUserPrefs } from '../context/userPrefs'
import Icon from './icon'
import { get } from 'lodash'

const availability = 'availability'
const alpha = 'alpha'
const beta = 'beta'
const availabilities = [alpha, beta]

const AvailabilityMode = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { hash, pathname } = location

  const { savePref, getPref, deletePref } = useUserPrefs()

  const accessFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.access }))
  const allowedAvailabilities = useMemo(() => {
    if (accessFeature && accessFeature.status === 'enabled') {
      return get(accessFeature, 'payload.availabilities', [])
    } else {
      return []
    }
  }, [accessFeature])

  const [mode, setMode] = useState('')

  const setAvailabilityMode = useCallback((m) => {
    if (!allowedAvailabilities.includes(m)) {
      return
    }
    setMode(m)
    savePref('availabilityMode', availability, m)
    dispatch(setHeader({
      name: availability,
      value: m,
    }))
  }, [allowedAvailabilities])

  const unsetAvailabilityMode = useCallback(() => {
    setMode('')
    deletePref('availabilityMode', availability)
    dispatch(removeHeader({
      name: availability,
    }))
    if (hash === `#${alpha}` || hash === `#${beta}`) {
      history.push(pathname)
    }
  }, [hash, pathname])

  useEffect(() => {
    const m = getPref('availabilityMode', availability)
    if (m && availabilities.includes(m)) {
      setAvailabilityMode(m)
    } else if (hash === `#${alpha}`) {
      setAvailabilityMode(alpha)
    } else if (hash === `#${beta}`) {
      setAvailabilityMode(beta)
    }
  }, [hash, setAvailabilityMode])

  return mode && (
    <div className="fixed top-0 w-full flex items-center justify-end slide-from-top pointer-events-none" style={{ zIndex: 99999999999 }}>
      <div className="h-full flex items-center bg-color-ffffff rounded-b shadow pl-4 pr-2 py-2 mr-4">
        <div className="flex items-center justify-between">
          <div className="leading-none mr-2">
            <div className="text-size-12px uppercase text-color-2e5bff">{mode}</div>
            <div className="text-size-8px tracking-widest uppercase text-color-b0bac9">mode</div>
          </div>
          <button onClick={unsetAvailabilityMode} className="focus:outline-none pointer-events-auto">
            <Icon iconName="times-circle" color="#2e5bff" size="sm" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(AvailabilityMode)
