import React, { useState, useEffect, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import has from 'lodash/has'

const DealFiltersToggle = (props) => {
  const { className, showFilters, handleFilterClick, hasFilters } = props

  const location = useLocation()
  const { pathname } = location
  const hash = queryString.parse(location.hash)

  const filterCount = useMemo(() => {
    let filterCount = 0
    if (has(hash, 'status')) filterCount += 1
    if (has(hash, 'stage')) filterCount += 1
    if (has(hash, 'forecast')) filterCount += 1
    if (has(hash, 'dealSize')) filterCount += 1
    if (has(hash, 'owner')) filterCount += 1
    if (has(hash, 'currentPeriod')) filterCount += 1
    return filterCount
  }, [location])

  const showFilterCount = !showFilters && filterCount > 0

  const buttonClasses = classNames(
    'flex justify-between items-center rounded-full shadow-btn focus:outline-none',
    { 'text-color-ffffff bg-gradient-green': showFilters },
    { 'text-color-151d49 bg-color-fafafa': !showFilters }
  )

  const linkClasses = classNames(
    'mx-4 whitespace-nowrap',
    { 'text-color-7771FC': hasFilters },
    { 'text-color-151d49 opacity-25 pointer-events-none': !hasFilters }
  )

  function onClick() {
    window.analytics.track('Deal Filters Clear All')
  }

  const [path, setPath] = useState('')
  useEffect(() => {
    let p = `${pathname}#`
    if (has(hash, 'sort')) {
      p += `sort=${hash.sort}&`
    }
    p = p.replace(/&$/, '')
    p = p.replace(/#$/, '')
    setPath(p)
  }, [location])

  return (
    <div className={classNames('flex items-center justify-between', className)}>
      <button className={buttonClasses} onClick={handleFilterClick}>
        {showFilterCount
          ? <div className="flex items-center justify-center ml-3 w-5 h-5 text-xs rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-gradient-green">{filterCount}</div>
          : <FontAwesomeIcon icon="filter" className="ml-4" />}
        <span className="pl-3 pr-6 py-2 text-size-16px font-bold">Filter</span>
      </button>
      <Link to={path} className={linkClasses} onClick={onClick}>Clear All</Link>
    </div>
  )
}

export default DealFiltersToggle
