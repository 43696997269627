import { createReducer } from '@reduxjs/toolkit'
import { cloneDeep, findIndex, forEach, has, remove, uniqBy } from 'lodash'
import {
  clearForecastOpps,
  updateOppAfterPatch,
} from '../actions'
import { getForecastOppsSuccess } from '../actions/searchService'
import { getFieldFromObject } from '../components/fieldRenderers/helpers'
import { parseDate } from '../lib/dateFns'
import { enrichFields } from './searchedOpps'

const forecastOppsInitialState = []

export const forecastOpps = createReducer(forecastOppsInitialState, {
  [clearForecastOpps.type]: (state, action) => forecastOppsInitialState,
  [getForecastOppsSuccess.type]: (state, action) => {
    const { valuesList } = action.payload
    let newState = [
      ...state,
      ...valuesList.map((opp) => enrichFields(opp)),
    ]
    newState = uniqBy(newState, (o) => o.id)
    return newState
  },
  [updateOppAfterPatch.type]: (state, action) => {
    const opp = cloneDeep(action.payload)
    const index = findIndex(state, (o) => o.id === opp.id)
    if (index !== -1) {
      state[index] = enrichFields(opp)
    }
    let removeOpp = false
    if (opp.isCloseDateChange && opp.searchFilters && opp.searchFilters.appliedFilters && opp.searchFilters.appliedFilters.closedate
      && opp.searchFilters.appliedFilters.closedate.data) {
      const { startDate, endDate } = opp.searchFilters.appliedFilters.closedate.data
      const field = getFieldFromObject(opp, 'closedate')
      const closeDate = (field && field.value) || ''
      if (closeDate && startDate && endDate) {
        const closeDateTime = parseDate(closeDate).getTime()
        const startDateTime = parseDate(startDate).getTime()
        const endDateTime = parseDate(endDate).getTime()
        removeOpp = closeDateTime < startDateTime || closeDateTime > endDateTime
      }
    }
    if (removeOpp) {
      remove(state, (o) => o.id === opp.id)
    }
    return state
  },
})
