import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FilterSelectGroupItem from './filterSelectGroupItem'

const minWidth = 200
const maxWidth = 350

const FilterSelectGroup = (props) => {
  const {
    title,
    selectedOption,
    onChange,
    options = [],
    onOptions,
    popoverOption
  } = props

  const [isOpen, setIsOpen] = useState(true)

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <>
      <div
        onClick={toggleOpen}
        style={{ minWidth, maxWidth }}
        className="pl-4 py-2 flex items-center justify-between cursor-pointer">
        <div className="text-color-818e93 text-size-14px font-weight-500">{title}</div>
        <FontAwesomeIcon icon={`chevron-${isOpen ? 'down' : 'up'}`} className="mx-3 text-color-a0a8bb" size="sm" />
      </div>
      {isOpen && options.map((o, index) => (
        <FilterSelectGroupItem
          key={`FilterSelectGroupItem-${selectedOption?.value}-${o.value}-${index}`}
          selectedOption={selectedOption}
          option={o}
          onChange={onChange}
          onOptions={onOptions}
          popoverOption={popoverOption} />
      ))}
    </>
  )
}

export default FilterSelectGroup
