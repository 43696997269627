import { find } from 'lodash'
import { canopyFields, fieldRenderTypes, fieldTypes, formatTypes } from './constants'

export function getFieldRenderType(fieldDefinition) {
  const { format, toType, options } = fieldDefinition
  if (format) {
    const formatParts = format.split('::')
    switch (formatParts[0]) {
      case formatTypes.text:
      case formatTypes.plaintextarea:
      case formatTypes.richtextarea:
      case formatTypes.url:
      case formatTypes.email:
        return fieldRenderTypes.STRING
      case formatTypes.currency:
      case formatTypes.float:
      case formatTypes.int:
      case formatTypes.percent:
        return fieldRenderTypes.NUMBER
      case formatTypes.date:
        return fieldRenderTypes.DATE
      case formatTypes.timestamp:
        return fieldRenderTypes.TIMESTAMP
      case formatTypes.select:
        return fieldRenderTypes.SINGLE_SELECT
      case formatTypes.multiselect:
        return fieldRenderTypes.MULTI_SELECT
      case formatTypes.canopy_status:
        return fieldRenderTypes.CANOPY_STATUS
      case formatTypes.canopy_signalcount:
        return fieldRenderTypes.CANOPY_SIGNAL_COUNT
      case formatTypes.canopy_owner:
        return fieldRenderTypes.CANOPY_OWNER
      case formatTypes._status:
        return fieldRenderTypes._STATUS
      case formatTypes._signalcount:
        return fieldRenderTypes._SIGNAL_COUNT
      default:
        return fieldRenderTypes.UNKNOWN
    }
  } else {
    switch (toType) {
      case fieldTypes.STRING:
        if (options && options.itemsList && options.itemsList.length > 0) {
          return fieldRenderTypes.SINGLE_SELECT
        } else {
          return fieldRenderTypes.STRING
        }
      case fieldTypes.FLOAT:
      case fieldTypes.INTEGER:
        return fieldRenderTypes.NUMBER
      case fieldTypes.DATE:
        return fieldRenderTypes.DATE
      case fieldTypes.BOOLEAN:
        return fieldRenderTypes.CHECKBOX
      default:
        return fieldRenderTypes.UNKNOWN
    }
  }
}

export function getFieldDefinition(fieldList, fieldName) {
  const fieldKey = fieldName.toLowerCase()
  if (fieldKey) {
    if (fieldKey.startsWith('canopy_') || [canopyFields._status.key, canopyFields._owner.key].includes(fieldKey)) {
      return canopyFields[fieldKey]
    } else {
      return find(fieldList, (f) => f.key === fieldKey) || { key: fieldKey, label: fieldKey }
    }
  }
}

export function getFieldFromObject(obj, fieldName) {
  const fieldKey = fieldName.toLowerCase()
  return fieldKey
    && obj
    && obj.fields
    && obj.fields[fieldKey]
    && obj.fields[fieldKey].length > 0
    ? obj.fields[fieldKey][0]
    : undefined
}

export function getFormatType(format) {
  const formatParts = format.split('::')
  return formatParts[0]
}

export function getFormatLength(format) {
  const formatParts = format.split('::')
  if (formatParts.length > 1 && formatParts[1] && formatParts[1].startsWith('length(')) {
    try {
      const len = formatParts[1].replace('length(', '').replace(')', '')
      return parseInt(len)
    } catch (err) {
      console.log(err)
    }
  }
  return null
}

export function getDelimiter(format) {
  const formatParts = format.split('::')
  if (formatParts.length > 1 && formatParts[1] && formatParts[1].startsWith('delim(')) {
    try {
      return eval(formatParts[1].replace('delim(', '').replace(')', ''))
    } catch (err) {
      console.log(err)
    }
  }
  return null
}

export const decodeString = (str) => {
  let decoded = str
  try {
    decoded = JSON.parse(decodeURIComponent(escape(atob(decoded))))
  } catch (e) {
    console.log('Unable to decode string from provider', e)
  }
  return decoded
}
