import { createAction } from '@reduxjs/toolkit'

export const getTeamKey = 'api/person/getTeam'
export const getTeamRequested = createAction(`${getTeamKey}/requested`)
export const getTeamSuccess = createAction(`${getTeamKey}/success`)
export const getTeamFailed = createAction(`${getTeamKey}/failed`)

export const getAllPeopleKey = 'api/person/getAllPeople'
export const getAllPeopleRequested = createAction(`${getAllPeopleKey}/requested`)
export const getAllPeopleSuccess = createAction(`${getAllPeopleKey}/success`)
export const getAllPeopleFailed = createAction(`${getAllPeopleKey}/failed`)
