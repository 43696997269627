import React, { useCallback, useMemo } from 'react'
import { filter } from 'lodash'
import { TimePeriod } from './constants'
import { format, fromUnixTime } from 'date-fns'

const ActivitiesPreview = (props) => {
  const {
    activityTimeline,
    selectedTimePeriod,
    data,
    showActivityDetails,
  } = props

  const onViewClick = useCallback((activity) => {
    showActivityDetails && showActivityDetails(activity)
  }, [])

  const activityData = useMemo(() => {
    const { activities } = data
    const { activitiesList = [] } = activityTimeline
    const ids = activities ? activities.map((a) => a.id) : []
    return filter(activitiesList, (a) => ids.includes(a.id))
  }, [data, activityTimeline])

  const activitiesList = useMemo(() => {
    return (
      <>
        {activityData.length > 0 ? activityData.map((activity, index) => {
          const { completedAt = { valid: false }, subject } = activity
          if (!completedAt.valid) {
            return (<></>)
          }
          return (
            <div key={`ActivityItem-${index}`} className="mt-5 first:mt-1 mb-5 last:mb-1 leading-tight">
              <div
                className="text-size-14px text-color-818e93 font-weight-500">
                {format(fromUnixTime(completedAt.value.seconds), `${selectedTimePeriod.value === TimePeriod.WEEKLY ? 'EEEE, LLL do ' : ''}h:mmaaa`)}
              </div>
              <div className="text-size-16px text-color-09242f font-weight-600 mt-1">{subject || '(No Subject)'}</div>
              {/* <div className="text-size-16px text-color-818e93 font-weight-600 my-1 max-lines-2">{description.replace(/\n/g, '')}</div> */}
              <div className="mt-1 w-full">
                <div onClick={() => onViewClick(activity)} className="text-size-13px text-color-2e5bff font-bold focus:outline-none cursor-pointer">View Activity</div>
              </div>
            </div>
          )
        })
          : <div className="text-size-16px text-color-09242f font-weight-600">...</div>}
      </>
    )
  }, [activityData])

  return (
    <div className="px-4 py-3 overflow-auto" style={{ maxWidth: 600, maxHeight: 'calc(100vh * 0.5)' }}>
      {activitiesList}
    </div>
  )
}

export default ActivitiesPreview
