import React, { useCallback } from 'react'
import { Dialog } from '@material-ui/core'

const ActionModal = (props) => {
  const {
    actionText = 'Continue',
    cancelText = 'Cancel',
    maxWidth = 'xs',
    modal,
    onAction,
    onCancel,
    subText = 'Click cancel to go back and save your work.',
    text = 'You have unsaved changes that will be lost if you continue.'
  } = props

  const { open, handleClose } = modal

  const onCancelInternal = useCallback(() => {
    onCancel && onCancel()
    handleClose()
  }, [onCancel])

  const onActionInternal = useCallback(() => {
    onAction && onAction()
    handleClose()
  }, [onAction])

  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={onCancelInternal}
      open={open}>
      <div className="w-full max-w-88 flex flex-col font-normal px-10 py-6 rounded-lg overflow-hidden">
        <div className="text-size-16px text-color-09242f my-2 font-bold">
          {text}
        </div>
        <div className="text-size-14px text-color-818e93">
          {subText}
        </div>
        <div className="flex justify-center mt-6 mb-2">
          <button
            className="px-5 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-color-a6b2cf shadow-btn focus:outline-none mr-5"
            onClick={onCancelInternal}>
            {cancelText}
          </button>
          <button
            className="px-5 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none"
            onClick={onActionInternal}>
            {actionText}
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default ActionModal
