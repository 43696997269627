import { capitalize } from 'lodash'
import { getHistoricalForecastCategoryCall, getForecastSubmittedAt } from './helpers'
import { roundArrow } from 'tippy.js'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useForecasting } from '../../context/forecasting'
import { useForecastingFeature } from './hooks'
import { useHistory } from 'react-router-dom'
import { useLocationData } from '../location/hooks'
import classNames from 'classnames'
import EmptyState from '../empty/emptyState'
import noteIcon from '../../assets/note-icon.png'
import pageIcon from '../../assets/pageIcon.png'
import Paging from '../paging/paging'
import React, { useCallback, useEffect, useMemo } from 'react'
import Tippy from '@tippyjs/react'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'

const tableLimit = 20

const ForecastingHistoryTable = (props) => {
  const {
    user,
    userId,
  } = props

  const { location, hash } = useLocationData()

  const { selectedConfig } = useForecastConfigs()
  const forecastingFeature = useForecastingFeature()
  const history = useHistory()

  const { forecastCategories } = forecastingFeature

  const cols = forecastCategories.length

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: repeat(2, 175px) repeat(${cols}, minmax(min-content, ${100 / (cols)}%));
      `,
      HeaderRow: '',
      HeaderCell: `
        font-size: 12px;
        letter-spacing: 0.1em;
        font-weight: 600;
        text-transform: uppercase;
        background-color: initial;
        padding-right: 15px;
        &:last {
          padding-right: 0;
        }
      `,
      BaseCell: `
        font-weight: 400;
        line-height: 1.25;
        padding: 10px 5px 10px 0;
      `,
      Row: `
        background-color: initial;
        font-size: 15px;
        color: #141414;
      `
    }
  ])

  const {
    cursor,
    searchForecasts,
    forecastHistory,
    clearForecastHistory,
    successHistory } = useForecasting()

  useEffect(() => {
    return () => {
      clearForecastHistory()
    }
  }, [clearForecastHistory])

  useEffect(() => {
    if (!selectedConfig?.id) {
      return
    }
    searchForecasts({
      period: {
        cursor,
        type: selectedConfig.periodLength
      },
      configId: selectedConfig.id,
      ownerId: userId,
      limit: tableLimit,
      page: (hash && hash.page) || 1,
    })
  }, [userId, hash, selectedConfig, searchForecasts, cursor])

  const { total, limit, page } = forecastHistory

  const forecastHistoryList = useMemo(() => {
    return forecastHistory.forecastsList || []
  }, [forecastHistory])

  const data = useMemo(() => {
    return {
      nodes: forecastHistoryList.map((item) => {
        return {
          ...item,
          id: item?.current?.id
        }
      })
    }
  }, [forecastHistoryList])

  const renderSubmittedBy = useCallback((forecast) => {
    const { submittedByName = 'System', id } = forecast.current

    return (
      <div className="flex items-start" key={`SubmittedBy-${id}`}>
        <div>
          <div>{submittedByName}</div>
        </div>
      </div>
    )
  }, [])

  const renderSubmittedAt = useCallback((forecast) => {
    const submittedAt = capitalize(getForecastSubmittedAt(forecast.current, 'LLL do, yyyy'))
    const { comment } = forecast.current
    return (
      <div className="flex items-start">
        <div>
          <div>{submittedAt}</div>
          <div className="text-size-11px text-color-b0bac9">{getForecastSubmittedAt(forecast.current, 'h:mmaaa')}</div>
        </div>
        {comment
          && (
            <Tippy
              offset={[0, 2]}
              duration={[0, 0]}
              maxWidth={300}
              content={(
                <div className="px-4 py-3">
                  <pre className="forecast-comment">
                    {comment}
                  </pre>
                </div>
              )}
              arrow={roundArrow}
              theme="canopy">
              <img src={noteIcon} style={{ transform: 'translate(10px, 3px)' }} />
            </Tippy>
          )}
      </div>
    )
  }, [])

  const getCall = useCallback((forecastCategory, forecast, useDefault = false) => {
    return getHistoricalForecastCategoryCall(forecastCategory.id, forecast, useDefault)
  }, [])

  const forecastColumns = useMemo(() => {
    return forecastCategories.map((fc) => ({
      label: fc.name,
      renderCell: (forecast) => {
        const call = getCall(fc, forecast)
        const pipelineAmount = getCall(fc, forecast, true)
        const isDifferent = pipelineAmount.value > 0 && call.value !== pipelineAmount.value
        const pipelineLabel = !user || user.hasChildren ? 'Rollup Amount' : 'Pipeline Amount'

        return (
          <div>
            <Tippy
              onShow={() => isDifferent}
              offset={[0, 6]}
              duration={[0, 0]}
              content={(
                <div className="flex justify-between px-4 py-3 leading-tight">
                  <div className="flex flex-col">
                    <div className="text-size-12px text-color-2e384d">Call</div>
                    <div className="text-size-14px text-color-2e384d font-bold">{call.toString()}</div>
                  </div>
                  <div className="flex flex-col ml-6">
                    <div className="text-size-12px text-color-a0a8bb">{pipelineLabel}</div>
                    <div className="text-size-14px text-color-a0a8bb font-bold">{pipelineAmount.toString()}</div>
                  </div>
                </div>
              )}
              arrow={roundArrow}
              theme="canopy">
              <div className={classNames('inline', { 'font-weight-700': isDifferent })}>
                {call.toString()}
              </div>
            </Tippy>
          </div>
        )
      }
    }
    ))
  }, [forecastCategories, getCall, user])

  const COLUMNS = useMemo(() => {
    return [
      { label: 'Submitted', renderCell: renderSubmittedAt },
      { label: 'Submitted By', renderCell: renderSubmittedBy },
      ...forecastColumns
    ]
  }, [forecastColumns, renderSubmittedAt, renderSubmittedBy])

  const onSetPage = useCallback((page) => {
    const { pathname } = location
    history.push(`${pathname}${page === 1 ? '' : `#page=${page}`}`)
  }, [history, location])

  const showEmptyState = useMemo(() => {
    return successHistory && forecastHistoryList.length === 0
  }, [successHistory, forecastHistoryList])

  // eslint-disable-next-line no-nested-ternary
  return showEmptyState
    ? (
      <div className="w-full flex justify-center">
        <EmptyState
          iconControl={<img src={pageIcon} />}
          header="No History"
          subHeader="There are no forecasts to show." />
      </div>
    )
    : (
      successHistory
        ? (
          <>
            <CompactTable
              columns={COLUMNS}
              data={data}
              layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}
              theme={theme} />
            <Paging
              className="flex w-full items-center justify-between py-3"
              textClassName="text-color-151d49 text-size-15px font-weight-600"
              navigationClassName="flex items-center"
              total={total}
              limit={limit}
              page={page}
              onSetPage={onSetPage}
              textFormatter={(start, end, total) => `${start} - ${end} of ${total}`} />
          </>
        )
        : <></>
    )
}

export default ForecastingHistoryTable
