import { Transforms, Node } from 'slate'

export const withTitleLayout = (editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      for (const [child, childPath] of Node.children(editor, path)) {
        let type
        if (childPath[0] === 0) {
          type = 'title'
        } else {
          type = child.type === 'title' ? 'paragraph' : child.type
        }
        // force title element on the first child
        Transforms.setNodes(editor, { type }, { at: childPath })
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}
