import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Header from '../header/header'
import UnsyncedActivity from '../activity/unsyncedActivity'
import { DealSignalsProvider } from '../../context/dealSignals'
import DealSignals from './dealSignals'
import DealSignalsHeader from './dealSignalsHeader'
import DealInsights from './dealInsights'
import { useDispatch, useSelector } from 'react-redux'
import DealTabs from './dealTabs'
import DealSummary from './dealSummary'
import { useParams } from 'react-router-dom'
import { useDebug } from '../../context/debug'
import DealOtherFields from './dealOtherFields'
import SearchBox from '../search/searchBox'
import { useTextField } from '../../hooks/useTextField'
import { clearOppDetail } from '../../actions'
import { getOppDetailById, patchSearchItem } from '../../services/searchService'
import { useNotification } from '../../hooks/useNotification'
import { getFieldFromObject, getFieldDefinition } from '../fieldRenderers/helpers'
import { getMomentDaysAgo, getSeconds } from '../../lib/moment'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import { useUserInput } from '../../hooks/useUserInput'
import { ViewDealInCrmProvider } from '../../context/viewDealInCrm'
import ViewDealInCrm from './viewDealInCrm'
import { CanvasProvider } from '../../context/canvas'
import { canvasKey } from '../canvas/constants'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'
import { useAuth } from '../../context/auth'
import DealHealthDetails from '../pipeline/dealHealthDetails'
import { apiRequestedSelector } from '../../selectors'
import { dealHealthDataByIdKey } from '../../actions/searchService'
import { Skeleton } from '@material-ui/lab'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'
import useIsReadOnly from '../../hooks/useIsReadOnly'
import ActivityDetailItem from '../fieldRenderers/activityDetailItem'
import { getFeatureSettingsKey } from '../../actions/settingsService'
import DealHealthDetailItem from '../fieldRenderers/dealHealthDetailItem'

const DealDetail = (props) => {
  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames } = useNearlineFeatures()

  const { hasFeatureSettings, isDealHealthEnabled, isSuccessPlansEnabled, isKaiaRecordingsEnabled } = useOutreachFeatures()

  const isFetchingFeatureSettings = useSelector((state) => apiRequestedSelector(state, getFeatureSettingsKey))

  const { actingTenantId } = useAuth()

  const { encodeString } = useUserInput()

  const { checkPermissions } = usePermissions()

  const { currentOppDealHealth } = useSelector((state) => state.dealHealthById)
  const isFetchingDealHealth = useSelector((state) => apiRequestedSelector(state, dealHealthDataByIdKey))

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadDeal
    )
  }, [checkPermissions])

  const fetchDealHealthData = useMemo(() => {
    return hasFeatureSettings && (isDealHealthEnabled || isSuccessPlansEnabled || isKaiaRecordingsEnabled)
  }, [hasFeatureSettings, isDealHealthEnabled, isKaiaRecordingsEnabled, isSuccessPlansEnabled])

  const dispatch = useDispatch()

  const { debug } = useDebug()

  const { notifyError } = useNotification()

  const params = useParams()

  const { isReadOnly } = useIsReadOnly()

  const opportunityId = useMemo(() => {
    return params.opportunityId || props.opportunityId
  }, [params])

  useEffect(() => {
    if (opportunityId && !isFetchingFeatureSettings) {
      dispatch(clearOppDetail())
      dispatch(getOppDetailById(opportunityId, debug, fetchDealHealthData, actingTenantId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId, isFetchingFeatureSettings])

  const oppDetail = useSelector((state) => state.oppDetail)

  const name = useMemo(() => {
    const field = getFieldFromObject(oppDetail, 'name')
    return (field && field.value) || ''
  }, [oppDetail])

  const dealOwnerId = useMemo(() => {
    const field = getFieldFromObject(oppDetail, 'ownerId')
    return field?.value ?? ''
  }, [oppDetail])

  const search = useTextField({
    defaultValue: ''
  })

  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const fieldsList = useMemo(() => {
    const { opportunity = { fieldsList: [] } } = objectDefinitions
    return opportunity.fieldsList
  }, [objectDefinitions])

  const getParams = useCallback((fieldName) => {
    const fieldDefinition = cloneDeep(getFieldDefinition(fieldsList, fieldName))
    return {
      fieldDefinition,
      field: (oppDetail.fields && oppDetail.fields[fieldName] && oppDetail.fields[fieldName][0]) || {},
      readonlyOverride: isReadOnly(oppDetail, fieldDefinition)
    }
  }, [fieldsList, isReadOnly, oppDetail])

  const lastActivityDate = useMemo(() => {
    return getParams('lastactivitydate')
  }, [getParams])

  const userActivitySynced = useSelector((state) => state.userActivitySynced)

  const isSynced = useMemo(() => {
    return userActivitySynced?.isSynced ?? false
  }, [userActivitySynced])

  const [tabIndex, setTabIndex] = useState(0)

  const onTabIndexChange = useCallback((i) => {
    setTabIndex(i)
  }, [])

  const [isUpdating, setIsUpdating] = useState(false)

  const onFieldChanged = useCallback((opportunity, field) => {
    debug && console.log('onFieldChanged', opportunity, field)

    const { name, value } = field

    const changeSince = {
      seconds: getSeconds(getMomentDaysAgo(7)),
      nanos: 0,
    }
    const obj = {
      objectName: 'opportunity',
      item: {
        id: opportunity.id,
        lastModifiedDateString: opportunity.lastModifiedDateString,
        fieldsMap: [
          [
            name,
            {
              instancesList: [
                {
                  ...field,
                  value: encodeString(value)
                },
              ],
            },
          ],
        ],
      },
      changeSince,
    }
    setIsUpdating(true)
    dispatch(patchSearchItem(opportunity, obj, notifyError, field.name === 'closeDate', debug, () => {
      setIsUpdating(false)
      window.analytics.track('deal.fieldChanged', { label: field.label })
    }))
  }, [debug, dispatch, notifyError])

  const dealSignals = useMemo(() => {
    if (!isAccessNearlineCheckReady) {
      return <></>
    } else if (canAccessNearlineFeature({ featureName: featureNames.deals })) {
      return (
        <CanvasProvider
          key={`CanvasProvider-${canvasKey.DEAL_SIGNALS}-${opportunityId}`}
          canvasKey={canvasKey.DEAL_SIGNALS}>
          <DealSignalsProvider
            opportunityId={opportunityId}>
            <DealSignalsHeader />
            <DealSignals
              className=""
              showHeader={false} />
          </DealSignalsProvider>
        </CanvasProvider>
      )
    } else {
      return <DealInsights />
    }
  }, [opportunityId, isAccessNearlineCheckReady, canAccessNearlineFeature])

  return (
    <div className="flex flex-col">

      <Header
        text={name}
        subControl={(
          <ViewDealInCrmProvider
            opportunityId={opportunityId}
            analyticsTrackingArgs={['deal.viewInCrmClicked', { feature: 'detailPageHeader' }]}>
            <ViewDealInCrm />
          </ViewDealInCrmProvider>
        )}
        showBackButton={true} />

      {permissions.CanReadDeal && isSynced !== undefined
        && (
          <>
            <div className="p-8">
              {dealOwnerId && (<UnsyncedActivity />)}
            </div>

            <div className="flex flex-row px-8">

              <div className="w-8/12">
                <div className="flex items-center justify-between">

                  <DealTabs
                    tabIndex={tabIndex}
                    onTabIndexChange={onTabIndexChange} />

                  <div className="flex items-center">
                    {tabIndex === 1
                      && (
                        <SearchBox
                          className="mt-2 ml-2 mx-10"
                          placeholder="Search Other Fields"
                          value={search.value}
                          onChange={search.onChange}
                          onClear={search.reset}
                          autoFocus={false} />
                      )}
                  </div>
                </div>

                {tabIndex === 0
                  && (
                    <DealSummary
                      opportunityId={opportunityId}
                      onFieldChanged={onFieldChanged}
                      isUpdating={isUpdating}
                      debug={debug} />
                  )}
                {tabIndex === 1
                  && (
                    <DealOtherFields
                      opportunityId={opportunityId}
                      onFieldChanged={onFieldChanged}
                      isUpdating={isUpdating}
                      search={search}
                      debug={debug} />
                  )}
              </div>

              <div className="w-4/12">
                <div className="px-6 py-4 bg-color-ffffff rounded-lg border border-color-2e5bff-08">
                  {hasFeatureSettings && isDealHealthEnabled && isFetchingDealHealth
                    && (
                      <>
                        <div className="mt-4 mb-3">
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" height={16} />
                          <Skeleton animation="wave" height={16} />
                        </div>
                        <div className="mt-4 mb-3">
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" height={16} />
                          <Skeleton animation="wave" height={16} />
                          <Skeleton animation="wave" height={16} />
                        </div>
                        <div className="mt-4 mb-3">
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" height={16} />
                          <Skeleton animation="wave" height={16} />
                        </div>
                      </>
                    )}
                  {hasFeatureSettings && (
                    <>
                      {isDealHealthEnabled && !isFetchingDealHealth && (
                        <DealHealthDetailItem />
                      )}
                      {(isSuccessPlansEnabled || isKaiaRecordingsEnabled) && !isFetchingDealHealth && (
                        <ActivityDetailItem
                          debug={debug}
                          opportunity={oppDetail}
                          lastActivityDate={lastActivityDate}
                          onFieldChanged={onFieldChanged} />
                      )}
                    </>
                  )}
                  {dealSignals}
                </div>
                <div className="mb-3">&nbsp;</div>
              </div>
            </div>
          </>
        )}

    </div>
  )
}

export default DealDetail
