import React, { lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Popover } from '@material-ui/core'
import { usePopover } from '../../hooks/usePopover'
import { find } from 'lodash'
import { useFieldDefinitionOptions } from './hooks'
import classNames from 'classnames'
import SingleSelectFieldDetailItemDisplay from './singleSelectFieldDetailItemDisplay'
import { ReferenceFieldOptionsLabelsProvider } from '../../context/referenceFieldOptionsLabels'
import { ReferenceFieldOptionsListProvider } from '../../context/referenceFieldOptionsList'
import SingleSelectFieldDetailItemOptions from './singleSelectFieldDetailItemOptions'

const SingleSelectFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
    fieldReference,
    lazyLoad = false
  } = props

  const popoverActions = useRef()

  const [search, setSearch] = useState('')

  const options = useFieldDefinitionOptions(fieldDefinition)

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    setSelectedOption(find(options, (o) => o.value === field.originalValue))
  }, [field, options])

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  const handleClearSearch = useCallback(() => {
    setSearch('')
  }, [])

  const lazyLoadValues = useMemo(() => {
    return [field?.originalValue ?? '']
  }, [field])

  const lazyLoadDisplayValue = useMemo(() => {
    // if lazy loading is enabled for this field,
    // then field.value holds the resolved value...
    // so there is no need to try and resolve the display value
    return false
  }, [])

  return (
    <>
      {(!selectedOption && !lazyLoad) && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('SingleSelectFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <ReferenceFieldOptionsLabelsProvider
              isEnabled={lazyLoadDisplayValue}
              fieldReference={fieldReference}
              lazyLoadValues={lazyLoadValues}>
              <SingleSelectFieldDetailItemDisplay
                debug={debug}
                measure={measure}
                onClick={onClick}
                selectedOption={selectedOption}
                fieldDefinition={fieldDefinition}
                field={field}
                readonlyOverride={readonlyOverride}
                labelClassName={labelClassName}
                lazyLoad={lazyLoadDisplayValue} />
            </ReferenceFieldOptionsLabelsProvider>
            <ReferenceFieldOptionsListProvider
              limit={50}
              isEnabled={open && lazyLoad}
              fieldReference={fieldReference}
              search={search}>
              <Popover
                action={popoverActions}
                style={{ zIndex: 999999999 }}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                TransitionProps={{
                  onExited: handleClearSearch
                }}>
                <div className="flex flex-col">
                  <SingleSelectFieldDetailItemOptions
                    search={search}
                    setSearch={setSearch}
                    opportunity={opportunity}
                    fieldDefinition={fieldDefinition}
                    field={field}
                    onFieldChanged={onFieldChanged}
                    setSelectedOption={setSelectedOption}
                    closePopover={onClose}
                    popoverActions={popoverActions}
                    lazyLoad={lazyLoad} />
                </div>
              </Popover>
            </ReferenceFieldOptionsListProvider>
          </div>
        )}
    </>
  )
}

export default SingleSelectFieldDetailItem
