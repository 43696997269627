import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useCanvasItem } from './canvasItem'
import { useGrpcCallback } from '../grpc'
import { toRunRealtimeQueriesRequest } from '../grpc/converters'
import { useAuth } from './auth'
import { useCanvas } from './canvas'
import { Waypoint } from 'react-waypoint'
import { updateDynamicDateRanges } from './realtimeViewHelpers'

const RealtimeViewContext = React.createContext()

export function RealtimeViewProvider({ lazy = false, userId, headers, children }) {
  const { actingTenantId, actingUserId } = useAuth()
  const { tenantStandardParams } = useCanvas()
  const { tileKey, rawGML, nlgMap } = useCanvasItem()

  const [key, setKey] = useState(lazy ? undefined : guid())
  const [error, setError] = useState(undefined)
  const [response, setResponse] = useState(undefined)
  const [isFetching, setIsFetching] = useState(true)
  const [parsedGML, setParsedGML] = useState(undefined)
  const [activeUserQueryId, setActiveUserQueryId] = useState(undefined)
  const [selectedFilter, setSelectedFilter] = useState(undefined)
  const [isLazyLoaded, setIsLazyLoaded] = useState(false)

  const id = useMemo(() => {
    return userId || actingUserId
  }, [actingUserId, userId])

  const runRealtimeQueries = useGrpcCallback({
    ...headers && { headers },
    onSuccess: (obj) => {
      let err
      let gml
      const { responsesMap } = obj
      if (responsesMap[0] && responsesMap[0][1] && responsesMap[0][1]) {
        const responseContainer = responsesMap[0][1]
        const { status, code, errorMessage, queryKey, gmlResponse } = responseContainer
        if (status === 1) {
          err = {
            status,
            code,
            errorMessage,
            queryKey
          }
        } else if (gmlResponse) {
          err = undefined
          gml = gmlResponse
        } else {
          err = {
            status: 1,
            code,
            errorMessage: 'gmlResponse is empty',
            queryKey
          }
        }
      }
      if (!err && !gml) {
        err = {
          status: 1,
          errorMessage: 'responseContainer is empty',
          queryKey: tileKey
        }
      }
      setError(err)
      setResponse(obj)
      setParsedGML(gml)
      setIsFetching(false)
    },
    onError: (err) => {
      setError({ error: err })
      setResponse(undefined)
      setIsFetching(false)
    },
    onFetch: () => {
      setError(undefined)
      setResponse(undefined)
      setIsFetching(true)
    },
    grpcMethod: 'runRealtimeQueries',
    debug: false,
  }, [headers])

  useEffect(() => {
    if (key && selectedFilter) {
      const parametersList = selectedFilter?.parameters?.parametersList ?? []
      const { fiscalYearStartMonth } = tenantStandardParams
      updateDynamicDateRanges({ parametersList, fiscalYearStartMonth })
      const request = toRunRealtimeQueriesRequest({
        tenantId: actingTenantId,
        personId: id,
        realtimeQueriesMap: [
          [
            tileKey,
            {
              queryKey: tileKey,
              params: {
                parametersList
              },
              nlgMap,
              gml: rawGML
            }
          ]
        ],
        ...tenantStandardParams && { tenantStandardParams }
      })
      runRealtimeQueries(request)
    }
  }, [key, runRealtimeQueries, id, selectedFilter])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const clearError = useCallback(() => {
    setError(undefined)
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      error,
      setError,
      clearError,
      response,
      parsedGML,
      setParsedGML,
      activeUserQueryId,
      setActiveUserQueryId,
      selectedFilter,
      setSelectedFilter,
      invalidate
    }
  }, [isFetching, error, response, parsedGML, activeUserQueryId, selectedFilter])

  const onWaypointEnter = useCallback(() => {
    if (!isLazyLoaded) {
      setIsLazyLoaded(true)
      invalidate()
    }
  }, [isLazyLoaded])

  return (
    <>
      {lazy
        ? (
          <Waypoint
            topOffset={100}
            onEnter={onWaypointEnter}>
            <div className="waypoint-realtimeviewprovider">
              <RealtimeViewContext.Provider value={contextValue}>{children}</RealtimeViewContext.Provider>
            </div>
          </Waypoint>
        ) : (
          <RealtimeViewContext.Provider value={contextValue}>{children}</RealtimeViewContext.Provider>
        )}
    </>
  )
}

export function useRealtimeView() {
  const context = React.useContext(RealtimeViewContext)
  if (context === undefined) {
    throw new Error('useRealtimeView must be used within a RealtimeViewProvider')
  }
  return context
}
