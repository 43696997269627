import React, { useEffect, useMemo } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { find } from 'lodash'
import { resolveQueryParamFieldName } from './helpers'

const FilterGroupItem = (props) => {
  const {
    measure,
    filterGroups,
    filterGroup,
    option,
    selectedFilters,
    onFilterSelected
  } = props

  useEffect(() => {
    measure && measure()
  }, [measure])

  const checked = useMemo(() => {
    const filters = selectedFilters.key === -1 ? [] : selectedFilters.key
    const selectedFilter = find(filters, (f) => resolveQueryParamFieldName(f.fieldName) === resolveQueryParamFieldName(filterGroup.field))
    if (selectedFilter) {
      return selectedFilter.comparisonList[0].valuesList.includes(option.key)
    }
    return false
  }, [selectedFilters, filterGroup, option])

  return (
    <div>
      <FormControlLabel
        className="w-full"
        control={(
          <Checkbox
            checked={checked}
            onChange={(e) => onFilterSelected({ filterGroups, filterGroup, option, checked: e.target.checked })}
            style={{
              transform: 'scale(0.8)',
              color: '#5951FF'
            }} />
        )}
        label={option?.value ?? ''} />
    </div>
  )
}

export default FilterGroupItem
