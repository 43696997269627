import { createReducer } from '@reduxjs/toolkit'
import { setLoadingScreen } from '../actions'
import has from 'lodash/has'

const initialState = {
  enabled: false,
  isEmpty: true
}

export const loadingScreen = createReducer(initialState, {
  [setLoadingScreen.type]: (state, action) => {
    if (has(action.payload, 'enabled')) {
      state.enabled = action.payload.enabled
    }
    if (has(action.payload, 'isEmpty')) {
      state.isEmpty = action.payload.isEmpty
    }
    return state
  }
})
