import React from 'react'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  variantSuccess: { backgroundColor: '#7771FC !important' },
}))

const SnackBar = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      classes={classes}
      {...rest}>
      {children}
    </SnackbarProvider>
  )
}

export default SnackBar
