import React, { useEffect, useMemo, useState } from 'react'
import CountUp from 'react-countup'
import { useCategoryRollup } from './hooks'
import classNames from 'classnames'
import { useForecasting } from '../../context/forecasting'
import TeamCallTooltip from './teamCallTooltip'

const ManagersTeamCall = (props) => {
  const {
    className,
    ownerId,
    forecastCategory,
    currentCall
  } = props

  const { teamForecasts } = useForecasting()

  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)

  const { teamRollupCall } = useCategoryRollup(forecastCategory, teamForecasts)

  useEffect(() => {
    setStart(end)
    setEnd(teamRollupCall)
  }, [end, teamRollupCall])

  const classes = useMemo(() => {
    return classNames(
      className,
      'flex items-center text-size-16px font-weight-600 mb-1',
      { 'text-color-818e93': teamRollupCall.raw === currentCall.raw },
      { 'text-color-151d49': teamRollupCall.raw !== currentCall.raw }
    )
  }, [className, teamRollupCall, currentCall])

  const showIcon = useMemo(() => {
    return teamRollupCall.raw !== currentCall.raw
  }, [teamRollupCall, currentCall])

  return (
    <div className={classes}>
      <CountUp
        start={start.value}
        end={end.value}
        duration={0.5}
        decimals={4}
        formattingFn={currentCall.formatter} />
      <span style={{ marginLeft: 4, marginRight: 5 }}>rollup</span>
      {showIcon && (
        <TeamCallTooltip
          currentCall={currentCall}
          forecastCategory={forecastCategory}
          ownerId={ownerId} />
      )}
    </div>
  )
}

export default ManagersTeamCall
