import { grpcInvoke } from '../grpc'
import { DataRequest } from '../protos/src/common/data_request_pb'
import { GetOpportunitiesByIdRequest } from '../protos/src/services/crm-data-access/crm-data-access_pb'
import {
  getOpportunitiesByTeamRequested,
  getOpportunitiesByTeamSuccess,
  getOpportunitiesByTeamFailed,
  getPipelineOpportunitiesRequested,
  getPipelineOpportunitiesSuccess,
  getPipelineOpportunitiesFailed,
  getOppSettingsByTeamRequested,
  getOppSettingsByTeamSuccess,
  getOppSettingsByTeamFailed,
  getModalOpportunitiesRequested,
  getModalOpportunitiesSuccess,
  getModalOpportunitiesFailed,
  noteSearchOpportunitiesRequested,
  noteSearchOpportunitiesSuccess,
  noteSearchOpportunitiesFailed,
} from '../actions/opportunityService'
import { get } from 'lodash'
import { toSearchRequest } from '../grpc/converters'
import { FilterOperation, SearchCombineAction } from '../grpc/enums'
import { toSortItem } from './searchService'
import { enrichFields } from '../reducers/searchedOpps'
import { getFieldFromObject } from '../components/fieldRenderers/helpers'

export const getOpportunitiesByTeam = (skip, take, filterHeaders, id) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    filterHeaders.map((h) => { headers[h.key] = h.value })
    const request = new DataRequest()
    id && request.setId(id)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getOpportunitiesByTeamRequested())
      },
      onSuccess: (obj) => {
        obj.teamId = id
        obj.pageContext = getState().pageContext
        dispatch(getOpportunitiesByTeamSuccess(obj))
      },
      onError: (err) => {
        dispatch(getOpportunitiesByTeamFailed(err))
      },
      grpcMethod: 'getOpportunitiesByTeam',
      debug: false,
    })
  }
}

export const getPipelineOpportunities = (skip, take, filterHeaders, id) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    filterHeaders.map((h) => { headers[h.key] = h.value })
    const request = new DataRequest()
    id && request.setId(id)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getPipelineOpportunitiesRequested())
      },
      onSuccess: (obj) => {
        dispatch(getPipelineOpportunitiesSuccess(obj))
      },
      onError: (err) => {
        dispatch(getPipelineOpportunitiesFailed(err))
      },
      grpcMethod: 'getOpportunitiesByTeam',
      grpcMethodName: 'getPipelineOpportunities',
      debug: false,
    })
  }
}

export const getOppSettingsByTeam = (id) => {
  return async (dispatch, getState) => {
    const headers = {
      skip: 0,
      take: -1,
    }
    const request = new DataRequest()
    id && request.setId(id)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getOppSettingsByTeamRequested())
      },
      onSuccess: (obj) => {
        dispatch(getOppSettingsByTeamSuccess(obj))
      },
      onError: (err) => {
        dispatch(getOppSettingsByTeamFailed(err))
      },
      grpcMethod: 'getOppSettingsByTeam',
      debug: false,
    })
  }
}

export const getModalOpportunities = (ids, limit) => {
  return async (dispatch, getState) => {
    const state = getState()
    const request = new GetOpportunitiesByIdRequest()
    request.setIdsList(ids)
    request.setLimit(limit)
    request.setPageToken(get(state, 'modalOpportunities.nextPage', ''))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getModalOpportunitiesRequested())
      },
      onSuccess: (obj) => {
        obj.totalCount = ids.length
        dispatch(getModalOpportunitiesSuccess(obj))
      },
      onError: (err) => {
        dispatch(getModalOpportunitiesFailed(err))
      },
      grpcMethod: 'getOpportunitiesById',
      grpcMethodName: 'getModalOpportunities',
      debug: false,
    })
  }
}

export const noteSearchOpportunities = (skip, take, searchText) => {
  return async (dispatch, getState) => {
    const { objectDefinitions } = getState()
    if (objectDefinitions.opportunity) {
      const obj = {
        objectName: 'opportunity',
        fieldsList: [
          'name',
        ],
        paging: {
          sortList: [],
          skip,
          take,
        },
        query: {
          children: {
            combineAction: SearchCombineAction.AND,
            valuesList: [
              {
                node: {
                  item: toSortItem(objectDefinitions.opportunity.fieldsList, 'name'),
                  valuesList: [`.*${searchText}.*`],
                  operation: FilterOperation.LIKE,
                },
              }
            ]
          }
        },
      }
      const request = toSearchRequest(obj)
      grpcInvoke({
        request,
        onFetch: () => {
          dispatch(noteSearchOpportunitiesRequested())
        },
        onSuccess: (obj) => {
          if (obj.valuesList) {
            obj.valuesList = [...obj.valuesList.map((opp) => enrichFields(opp))].map((o) => {
              const field = getFieldFromObject(o, 'name')
              o.name = (field && field.value) || ''
              return o
            })
            dispatch(noteSearchOpportunitiesSuccess(obj.valuesList.map((o) => ({ id: o.id, name: o.name }))))
          }
        },
        onError: (err) => {
          dispatch(noteSearchOpportunitiesFailed(err))
        },
        grpcMethod: 'search',
        grpcMethodName: 'noteSearchOpportunities',
        debug: false,
      })
    }
  }
}
