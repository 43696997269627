import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import ConditionalWrapper from './conditionalWrapper'

const Repeat = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    wrapper = true,
    children
  } = props

  PropTypes.checkPropTypes(Repeat.propTypes, props, 'prop', 'Repeat')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-repeat',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  return (
    <ConditionalWrapper
      condition={wrapper}
      wrapper={(c) => <div {...attributes}>{c}</div>}>
      {children}
    </ConditionalWrapper>
  )
}

Repeat.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  wrapper: PropTypes.bool,
  children: PropTypes.node
}

export default Repeat
