import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import classNames from 'classnames'
import FieldDebug from './fieldDebug'
import pencilSm from '../../assets/pencil-sm.png'
import Icon, { iconType } from '../icon'
import { useReferenceFieldOptionsLabels } from '../../context/referenceFieldOptionsLabels'

const SingleSelectFieldDetailItemDisplay = (props) => {
  const {
    debug = false,
    measure,
    onClick,
    selectedOption,
    fieldDefinition,
    field,
    readonlyOverride = false,
    labelClassName = '',
    lazyLoad = false
  } = props

  const { referenceFields } = useReferenceFieldOptionsLabels()

  const { label, canEdit } = fieldDefinition

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const displayValue = useMemo(() => {
    if (lazyLoad) {
      return referenceFields.find((f) => f.key === (field?.originalValue ?? ''))?.label ?? ''
    } else if (field?.value) {
      return field.value
    } else {
      return selectedOption?.label ?? '-'
    }
  }, [lazyLoad, referenceFields, field, selectedOption])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure, displayValue])

  return (
    <>
      <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
        {label}
        <FieldDebug
          debug={debug}
          data={{ fieldDefinition, field }} />
      </div>
      <div
        style={{ transform: 'translateY(-1px)' }}
        onClick={onClick}
        className={classNames('flex items-center group cursor-pointer', { 'pointer-events-none': readonly })}>
        <div className={classNames('text-size-16px font-weight-300',
          { 'text-color-09242f': !readonly },
          { 'text-color-09242f': readonly })}>
          {displayValue}
        </div>
        <Icon type={iconType.IMAGE} src={pencilSm} className="invisible group-hover:visible" style={{ transform: 'translate(4px, -2px)' }} />
      </div>
    </>
  )
}

export default SingleSelectFieldDetailItemDisplay
