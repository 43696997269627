import { useMemo } from 'react'
import { useForecasting } from '../context/forecasting'
import { daysSince, getMomentDaysAgo, getSeconds } from '../lib/moment'

export function useDealChangeSinceForecastStart() {
  const { forecast } = useForecasting()

  const { current = {} } = forecast
  const { periodStart } = current

  const changeSince = useMemo(() => {
    const daysAgo = daysSince(periodStart)

    return {
      seconds: getSeconds(getMomentDaysAgo(daysAgo)),
      nanos: 0,
    }
  }, [periodStart])

  return {
    changeSince
  }
}
