import { createReducer } from '@reduxjs/toolkit'
import { clearOpportunities } from '../actions'
import { getOpportunitiesByTeamSuccess } from '../actions/opportunityService'
import { enrichOpportunity } from './common'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

const initialState = []

export const opportunities = createReducer(initialState, {
  [clearOpportunities.type]: (state, action) => initialState,
  [getOpportunitiesByTeamSuccess.type]: (state, action) => {
    const { opportunitiesList, teamId, pageContext } = action.payload
    if (!teamId || teamId === pageContext.id) {
      state = uniqBy([...state, ...opportunitiesList.map((o) => enrichOpportunity(o))], (o) => o.id)
    }
    return state
  }
})
