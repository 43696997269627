import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useVirtualizedList } from '../virtualized/hooks'
import VirtualizedList from '../virtualized/virtualizedList'
import FilterGroupItem from './filterGroupItem'
import { filter, find, orderBy } from 'lodash'
import { resolveQueryParamFieldName } from './helpers'

const defaultRowHeight = 42

const FilterGroup = (props) => {
  const {
    showActiveFilters,
    searchText = '',
    filterGroups,
    filterGroup = {},
    groupsOpened,
    onGroupClicked,
    selectedFilters,
    onFilterSelected
  } = props

  const {
    delimiter,
    field,
    fieldType,
    label,
    optionItemsList,
    optionsList
  } = filterGroup

  const [open, setOpen] = useState(false)

  const filteredItems = useMemo(() => {
    if (searchText.trim().length > 0) {
      const items = filter(optionItemsList, (o) => {
        const s = searchText.trim().toLowerCase()
        return o.value.toLowerCase().startsWith(s) || (label && label.toLowerCase().startsWith(s))
      })
      return orderBy(items, (option) => option.value)
    }
    return orderBy(optionItemsList, (option) => option.value)
  }, [searchText, optionItemsList])

  const selectedFilterCount = useMemo(() => {
    if (selectedFilters.key !== -1) {
      const fg = find(selectedFilters.key, (f) => resolveQueryParamFieldName(f.fieldName) === resolveQueryParamFieldName(filterGroup.field))
      if (fg) {
        return fg.comparisonList[0].valuesList.length
      }
    }
    return 0
  }, [selectedFilters, filterGroup])

  const hasSearchText = useMemo(() => {
    return searchText.trim().length > 0
  }, [searchText])

  const hasSearchMatch = useMemo(() => {
    return hasSearchText && filteredItems.length > 0
  }, [hasSearchText, filteredItems])

  const canRenderGroup = useMemo(() => {
    return (!showActiveFilters || selectedFilterCount > 0) && (!hasSearchText || hasSearchMatch)
  }, [showActiveFilters, selectedFilterCount, hasSearchText, hasSearchMatch])

  const isGroupOpen = useMemo(() => {
    const groupOpen = groupsOpened[field] === true
    return ((groupOpen && !hasSearchText) || (groupOpen && hasSearchMatch)) || open
  }, [groupsOpened, field, hasSearchText, hasSearchMatch, open])

  const onGroupClickedInternal = useCallback(() => {
    setOpen(false)
    onGroupClicked && onGroupClicked({ field, isOpen: !isGroupOpen })
  }, [onGroupClicked, field, isGroupOpen])

  useEffect(() => {
    setOpen(hasSearchMatch)
  }, [hasSearchMatch])

  useEffect(() => {
    if (showActiveFilters) {
      setOpen(true)
    }
  }, [showActiveFilters])

  const renderFilterGroupItem = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { registerChild, measure } = cellMeasurerArgs
    const option = filteredItems[index]
    return (
      <div ref={registerChild} style={style}>
        <FilterGroupItem
          measure={measure}
          filterGroups={filterGroups}
          filterGroup={filterGroup}
          option={option}
          selectedFilters={selectedFilters}
          onFilterSelected={onFilterSelected} />
      </div>
    )
  }, [filterGroups, filterGroup, filteredItems, selectedFilters, onFilterSelected])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: defaultRowHeight,
    rowRenderer: renderFilterGroupItem,
  })

  return canRenderGroup ? (
    <div className="px-5 mb-4">
      <div
        onClick={onGroupClickedInternal}
        className="flex items-center justify-between leading-tight cursor-pointer mb-1">
        <span className="text-size-16px text-color-09242f font-weight-700">{label}</span>
        <div className="flex items-center">
          {selectedFilterCount > 0 && (
            <div className="w-5 h-5 rounded-full h bg-color-5951FF flex items-center justify-center text-color-ffffff text-size-12px mr-3">
              {selectedFilterCount}
            </div>
          )}
          <FontAwesomeIcon icon={isGroupOpen ? 'chevron-up' : 'chevron-down'} color="#09242f" size="sm" />
        </div>
      </div>
      {isGroupOpen && (
        <div
          className="flex flex-grow flex-col overflow-auto"
          style={{ minWidth: 360, minHeight: Math.min(5 * defaultRowHeight, filteredItems.length * defaultRowHeight) }}>
          <VirtualizedList
            className="focus:outline-none"
            deferredMeasurementCache={cellMeasurerCache}
            rowHeight={cellMeasurerCache.rowHeight}
            rowCount={filteredItems.length}
            rowRenderer={rowRenderer} />
        </div>
      )}
    </div>
  ) : null
}

export default FilterGroup
