import { createReducer } from '@reduxjs/toolkit'
import { clearPipelineOpportunities } from '../actions'
import { getPipelineOpportunitiesSuccess } from '../actions/opportunityService'
import { enrichOpportunity } from './common'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

const initialState = []

export const pipelineOpportunities = createReducer(initialState, {
  [clearPipelineOpportunities.type]: (state, action) => initialState,
  [getPipelineOpportunitiesSuccess.type]: (state, action) => {
    state = uniqBy([...state, ...get(action, 'payload.opportunitiesList', []).map((o) => enrichOpportunity(o))], (o) => o.id)
    return state
  }
})
