import { createReducer } from '@reduxjs/toolkit'
import { getSignalWeaknessesByUserSuccess } from '../actions/signalService'
import { clearWeaknesses } from '../actions'

const initialState = []

export const weaknesses = createReducer(initialState, {
  [clearWeaknesses.type]: (state, action) => initialState,
  [getSignalWeaknessesByUserSuccess.type]: (state, action) => {
    const { signalsList, userId, pageContext } = action.payload
    if (userId === pageContext.id) {
      state.push(...signalsList)
    }
    return state
  },
})
