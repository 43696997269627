import React, { useMemo } from 'react'
import numeral from 'numeral'

const PercentOfQuota = (props) => {
  const {
    className,
    forecast,
    value,
  } = props

  const { goalAmount } = forecast?.current

  const quotaPerc = useMemo(() => {
    const percent = (value) / goalAmount
    if (!isFinite) {
      return '--'
    }
    return (goalAmount > 0 ? `${numeral(percent).format('0%')} of quota` : 'No quota')
  }, [value, goalAmount])

  return (
    <>
      <div className={className}>{quotaPerc}</div>
    </>
  )
}

export default PercentOfQuota
