import { ExpandMoreRounded } from '@getoutreach/react-icons'
import { usePopover } from '../../hooks/usePopover'
import { useTextField } from '../../hooks/useTextField'
import Popover from '../popover'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import SearchBox from './searchBox'

const SearchMenu = ({ button, children, isDropdown = false, noOptionsText = 'No options found', PopoverProps = {}, SearchBoxProps = {}, value = '', }) => {
  const popover = usePopover()
  const search = useTextField()

  const { anchorEl, open, setAnchorEl, onClose } = popover

  const filteredOptions = useMemo(() => (
    React.Children.toArray(children).filter((option) => {
      return option.props.label.toLowerCase().includes(search?.value?.toLowerCase())
    })
  ), [children, search?.value])

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  return (
    <>
      <div onClick={onClick} className="min-w-0 cursor-pointer">
        {isDropdown ? (
          <div className="min-w-36 flex items-center justify-between border border-color-d6d9e6 bg-color-ffffff rounded">
            <div className="whitespace-nowrap truncate text-color-09242f text-size-14px font-weight-500 mx-3 py-1">{value}</div>
            <ExpandMoreRounded className="mx-1 text-color-a0a8bb" />
          </div>
        ) : (
          <div className="flex items-center text-color-09242f text-size-24px font-weight-700">
            <span className="truncate">
              {value}
            </span>
            <ExpandMoreRounded style={{ width: 28, height: 28 }} />
          </div>
        )}
      </div>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        placement="top"
        timeout={0}
        {...PopoverProps}
      >
        <div className="flex flex-col pt-2">
          <SearchBox
            className="mb-2 mx-2"
            value={search.value}
            onChange={search.onChange}
            onClear={search.reset}
            autoFocus
            disableHint
            dense
            {...SearchBoxProps}
          />
          {filteredOptions}
          {!filteredOptions.length && (
            <div className="px-4 py-2 text-color-818e93">{noOptionsText}</div>
          )}
        </div>
      </Popover>
    </>
  )
}

SearchMenu.propTypes = {
  children: PropTypes.node,
  isDropdown: PropTypes.bool,
  noOptionsText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  PopoverProps: PropTypes.object,
  SearchBoxProps: PropTypes.object,
  value: PropTypes.string
}

export default SearchMenu
