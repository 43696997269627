import React from 'react'
import { useCanvas } from '../../context/canvas'
import { useRepSignals } from '../../context/repSignals'
import { TrendingUpRounded } from '@getoutreach/react-icons'
import SectionHeader from '../common/sectionHeader'
import AnimatedLoader from '../loaders/animatedLoader'
import EmptyState from '../empty/emptyState'
import RealtimeViewContainer from '../realtimeView/realtimeViewContainer'
import GenericErrorBoundary from '../errors/genericErrorBoundary'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import SignalMenu from './signalMenu'

const RepSignals = (props) => {
  const {
    userId
  } = props

  const { canvasKey, tenantStandardParams } = useCanvas()
  const { isFetching, repSignals } = useRepSignals()

  return (
    <>
      <SectionHeader
        title="Signals"
        description="Insights about performance based on historical data." />

      {isFetching
        ? (
          <AnimatedLoader
            className="my-10"
            title="Loading Signals"
            subTitle="Please wait..."
            maxWidth={300} />
        ) : (
          <>
            {repSignals.length === 0
              ? (
                <div className="flex items-center justify-center w-full my-6">
                  <EmptyState
                    iconControl={(
                      <div className="flex items-center justify-center w-12 h-12 rounded-full text-color-ffffff bg-gradient-green">
                        <TrendingUpRounded fontSize="large" />
                      </div>
                    )}
                    header="No Signals"
                    subHeader="There are no signals to show" />
                </div>
              ) : (
                <>
                  {repSignals.map((s, i) => {
                    const { name, gmlResponse, queryKey } = s
                    const gmlInstanceId = `gml-${userId}-${canvasKey}-${s.queryKey}-${i}`
                    return (
                      <RealtimeViewContainer
                        key={gmlInstanceId}
                        className="mt-6 bg-gradient-blue-to-white"
                        menuBar={(
                          <>
                            <SignalMenu
                              signal={s} />
                          </>
                        )}
                        name={name}
                        tileKey={queryKey}>
                        <GenericErrorBoundary
                          title="Error"
                          subTitle="Error rendering signal">
                          <GMLRenderer
                            tree={gmlResponse}
                            useContentType={false}
                            channel="web"
                            size="xs"
                            registeredData={{
                              gmlInstanceId,
                              ...tenantStandardParams
                            }}
                          />
                        </GenericErrorBoundary>
                      </RealtimeViewContainer>
                    )
                  })}
                </>
              )}
          </>
        )}
    </>
  )
}

export default RepSignals
