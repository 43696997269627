import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import LoginTemplate from './loginTemplate'
import { useRoutes } from '../../context/routes'
import ExclamationTriangle from '../icons/exclamationTriangle'

const LoginError = (props) => {
  const {
    title = 'Login Error',
    description = '',
  } = props

  const history = useHistory()

  const { routes } = useRoutes()

  const onLoginClick = useCallback(() => {
    history.push(routes.login)
  }, [])

  return (
    <LoginTemplate>
      <div className="text-left px-18">
        <div className="flex items-center mb-2">
          <div className="mr-2 text-size-36px text-color-151d49 font-bold leading-none">{title}</div>
          <ExclamationTriangle className="flex-shrink-0" fill="#5951FF" transform="scale(1.4)" />
        </div>
        <div className="text-size-16px text-color-4e5d7e font-normal">{description}</div>
        <div className="text-size-13px text-color-4e5d7e font-normal mt-10">
          (If you think this is a mistake, you can try to
          {' '}
          <a className="cursor-pointer text-color-2e5bff" onClick={onLoginClick}>login</a>
          {' '}
          again)
        </div>
      </div>
    </LoginTemplate>
  )
}

export default LoginError
