import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import pencilSm from '../../assets/pencil-sm.png'
import { moveCursorToEnd } from '../../lib/input'
import Icon, { iconType } from '../icon'
import { useFormatValue, useVersionsTooltip } from './hooks'
import classNames from 'classnames'
import FieldDebug from './fieldDebug'
import { getFormatLength } from './helpers'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'

const NumberFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
    readonlyOverride = false,
    onFieldChanged,
  } = props

  const { label, canEdit, required, format } = fieldDefinition
  const { versions = [] } = field

  const maxLength = useMemo(() => {
    const len = getFormatLength(format)
    return !len ? {} : { maxLength: len }
  }, [format])

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const containerRef = useRef()
  const inputRef = useRef()
  const [inputValue, setInputValue] = useState(field.value || '')
  const [prevInputValue, setPrevInputValue] = useState(field.value || '')
  const [containerWidth, setContainerWidth] = useState(0)

  const { formatValue } = useFormatValue()

  const formattedValue = formatValue(inputValue, fieldDefinition.format)

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  useEffect(() => {
    const { value = '' } = field
    setPrevInputValue(value)
    setInputValue(value)
  }, [field])

  const [isEditMode, setIsEditMode] = useState(false)

  const onClick = useCallback(() => {
    if (readonly) {
      return
    }
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
    setPrevInputValue(inputValue)
    setIsEditMode(true)
  }, [readonly, inputValue, containerRef])

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  const handleInputClick = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }, [])

  const handleInputBlur = useCallback((e) => {
    setIsEditMode(false)
    if (e.cancelled) {
      return
    }
    if (inputValue === '' && required) {
      setInputValue(prevInputValue)
      return
    }
    if (inputValue !== prevInputValue) {
      onFieldChangedInternal(inputValue)
    }
  }, [inputValue, prevInputValue, onFieldChangedInternal, required])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setInputValue(prevInputValue)
      handleInputBlur({ cancelled: true })
    } else if (e.key === 'Enter') {
      handleInputBlur({ cancelled: false })
    }
  }, [handleInputBlur, prevInputValue])

  useEffect(() => {
    if (isEditMode) {
      const inputEl = inputRef.current
      inputEl.focus()
      moveCursorToEnd(inputEl)
    }
  }, [isEditMode, inputRef])

  const className = useMemo(() => {
    const movingUp = versions.length > 1 && versions[0].value > versions[1].value
    const movingDown = versions.length > 1 && versions[0].value < versions[1].value
    return classNames('text-color-2e384d text-size-15px font-normal',
      { 'text-color-1dcf83 font-bold': movingUp },
      { 'text-color-fb6c6a font-bold': movingDown })
  }, [versions])

  const versionsTooltip = useVersionsTooltip(versions, (val) => (formatValue(val, fieldDefinition.format)))

  return (
    <div className="NumberFieldRowItem">
      {isEditMode
        ? (
          <div className="relative">
            <input
              ref={inputRef}
              value={inputValue}
              type="number"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onClick={handleInputClick}
              onKeyDown={handleKeyDown}
              className="absolute px-3 py-1 text-size-15px font-normal border border-color-b0bac9 rounded focus:outline-none"
              style={{ width: containerWidth, transform: 'translateY(-50%)' }}
              {...maxLength} />
          </div>
        )
        : (
          <div
            ref={containerRef}
            className={classNames('flex items-center text-right', { 'group cursor-pointer': !readonly })}>
            <Tippy
              onShow={() => versions.length > 1}
              offset={[0, 4]}
              duration={[0, 0]}
              maxWidth={800}
              content={versionsTooltip}
              arrow={roundArrow}
              theme="canopy">
              <div
                {...!readonly && { onClick }}
                className={className}>
                {field.value === undefined ? '-' : formattedValue}
              </div>
            </Tippy>
            <div {...!readonly && { onClick }} className="invisible group-hover:visible" style={{ transform: 'translate(4px, 0px)' }}>
              <Icon type={iconType.IMAGE} src={pencilSm} />
            </div>
          </div>
        )}
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </div>
  )
}

export default NumberFieldRowItem
