export const permissionNames = {
  CanAccessInternalAdmin: 'CanAccessInternalAdmin',
  CanAccessWebApp: 'CanAccessWebApp',
  CanAccessAdmin: 'CanAccessAdmin',

  CanAccessTenant: 'CanAccessTenant',
  CanReadTenant: 'CanReadTenant',

  CanAccessDeal: 'CanAccessDeal',
  CanReadDeal: 'CanReadDeal',
  CanUpdateDeal: 'CanUpdateDeal',

  CanAccessAnalytic: 'CanAccessAnalytic',
  CanReadAnalytic: 'CanReadAnalytic',
  CanCreateAnalytic: 'CanCreateAnalytic',

  CanAccessScenarioPlanner: 'CanAccessScenarioPlanner',
  CanReadScenarioPlanner: 'CanReadScenarioPlanner',
  CanCreateScenarioPlanner: 'CanCreateScenarioPlanner',
  CanUpdateScenarioPlanner: 'CanUpdateScenarioPlanner',

  CanAccessForecast: 'CanAccessForecast',
  CanReadForecast: 'CanReadForecast',
  CanCreateForecast: 'CanCreateForecast',
  CanUpdateForecast: 'CanUpdateForecast',

  CanAccessNote: 'CanAccessNote',
  CanReadNote: 'CanReadNote',
  CanCreateNote: 'CanCreateNote',
  CanUpdateNote: 'CanUpdateNote',
  CanDeleteNote: 'CanDeleteNote',
  CanShareNote: 'CanShareNote',

  CanManageSignal: 'CanManageSignal',
}
