import React from 'react'
import { TrendingUpRounded } from '@getoutreach/react-icons'
import EmptyState from '../empty/emptyState'

const RealtimeViewEmpty = (props) => {
  return (
    <div className="flex items-center justify-center w-full py-8 px-6" {...props}>
      <EmptyState
        iconControl={(
          <div className="flex items-center justify-center w-12 h-12 rounded-full text-color-ffffff bg-gradient-green">
            <TrendingUpRounded fontSize="large" />
          </div>
        )}
        header="No Data"
        subHeader={(
          <div className="text-center">
            Not enough data to support
            <br />
            this metric
          </div>
        )}
      />
    </div>
  )
}

export default RealtimeViewEmpty
