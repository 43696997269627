import { useRef, useCallback } from 'react'

export function useDelay() {
  const timeouts = useRef({})

  const cancelDelay = useCallback((id) => {
    clearTimeout(timeouts.current[id])
  }, [timeouts.current])

  const delay = useCallback((id, toDo, waitFor) => {
    cancelDelay(id)
    timeouts.current[id] = setTimeout(toDo, waitFor)
  }, [cancelDelay])

  return {
    delay,
    cancelDelay
  }
}
