export const metricNames = {
  pipelineByStage: 'metric_pipelineByStage',
  pipelineByForecast: 'metric_pipelineByForecast',
  goals: 'metric_goals',
}

export const headerMetricsGroupFilterHeaders = [
  {
    key: 'filter|definition.level|eq',
    value: 'group'
  },
  {
    key: 'filter|definition.name|startswith',
    value: `${metricNames.pipelineByStage}|${metricNames.pipelineByForecast}|${metricNames.goals}`
  },
]

export const headerMetricsPersonFilterHeaders = [
  {
    key: 'filter|definition.level|eq',
    value: 'person'
  },
  {
    key: 'filter|definition.name|startswith',
    value: `${metricNames.pipelineByStage}|${metricNames.pipelineByForecast}|${metricNames.goals}`
  },
]
