import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'

const useKeyPressEffect = ({
  targetKey,
  onDown,
  onUp,
  onDoubleTap,
  preventDefault = true,
  doubleTimeout = 300,
  eventTarget = [],
  useCapture = false
}, dependencyList = []) => {
  const keyPressTimeout = useRef()

  const isValIdTarget = useCallback((_target) => {
    if ([window.document.body, ...eventTarget].find((t) => {
      if (typeof t === 'string') {
        return document.querySelector(t) === _target
      }
      return t === _target
    })) {
      return true
    }
  }, [eventTarget])

  const monitorDouble = useCallback(() => {
    if (keyPressTimeout.current) {
      keyPressTimeout.current = null
      onDoubleTap?.()
    } else {
      keyPressTimeout.current = window.setTimeout(() => {
        clearTimeout(keyPressTimeout.current)
        keyPressTimeout.current = null
      }, doubleTimeout)
    }
  }, [doubleTimeout, onDoubleTap])

  const downHandler = useCallback((e) => {
    const { key, target } = e

    if (key !== targetKey) {
      return
    }

    if (!isValIdTarget(target)) {
      return
    }

    if (preventDefault) {
      e.preventDefault()
    }

    onDown?.(e)
  }, [onDown, targetKey, preventDefault, isValIdTarget])

  const upHandler = useCallback((e) => {
    const { key, target } = e

    if (key !== targetKey) {
      return
    }

    if (!isValIdTarget(target)) {
      return
    }

    if (preventDefault) {
      e.preventDefault()
    }

    onUp?.(e)
    if (onDoubleTap) {
      monitorDouble(e)
    }
  }, [targetKey, isValIdTarget, preventDefault, onUp, onDoubleTap, monitorDouble])

  useEffect(() => {
    window.removeEventListener('keydown', downHandler, useCapture)
    window.removeEventListener('keyup', upHandler, useCapture)

    window.addEventListener('keydown', downHandler, useCapture)
    window.addEventListener('keyup', upHandler, useCapture)

    return () => {
      window.removeEventListener('keydown', downHandler, useCapture)
      window.removeEventListener('keyup', upHandler, useCapture)
    }
  }, [upHandler, downHandler, ...dependencyList, useCapture])
}

export default useKeyPressEffect

export const KeyPressHandler = (props) => {
  const { children, keyPressProps } = props

  const wrapper = useRef()

  const [targetAnchor, setTargetAnchor] = useState()

  const handleClick = useCallback(() => {
    targetAnchor?.click?.()
  }, [targetAnchor])

  const _keyPressProps = useMemo(() => {
    const { doubleTap } = keyPressProps
    return {
      ...keyPressProps,
      [doubleTap ? 'onDoubleTap' : 'onDown']: handleClick
    }
  }, [handleClick, keyPressProps])

  useEffect(() => {
    if (!wrapper.current) {
      return
    }

    const target = wrapper.current.querySelector('a, button')
    if (target) {
      setTargetAnchor(target)
    }

    return () => setTargetAnchor(null)
  }, [])

  useKeyPressEffect(_keyPressProps, [_keyPressProps])

  if (!children) {
    console.warn('KeyPressHandler must wrap at least one element')
    return (<></>)
  }

  return (
    <div ref={wrapper}>
      {children}
    </div>
  )
}
