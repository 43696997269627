import { Link, useRouteMatch } from 'react-router-dom'
import logo from '../../assets/outreach-logo.png'
import logoMark from '../../assets/outreach-mark-color.svg'
import React from 'react'

const SideMenuLogo = ({ href = '', maxHeight = 94, sideMenuIsOpen = false, ...props }) => (
  <div className="flex flex-row items-center pt-6 pb-8" style={{ maxHeight }} {...props}>
    <Link to={href}>
      {sideMenuIsOpen ? (
        <img className="ml-4" src={logo} style={{ width: 200, maxWidth: 'initial' }} />
      ) : (
        <img className="ml-4 w-10" src={logoMark} />
      )}
    </Link>
  </div>
)

export default SideMenuLogo
