import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Waypoint } from 'react-waypoint'
import { useReferenceFieldOptionsList } from '../../context/referenceFieldOptionsList'
import SearchBox from '../search/searchBox'
import { useVirtualizedList } from '../virtualized/hooks'
import VirtualizedList from '../virtualized/virtualizedList'
import { useFieldDefinitionOptions } from './hooks'
import SingleSelectFieldDetailItemOptionItem from './singleSelectFieldDetailItemOptionItem'

const DEFAULT_HEIGHT = 40

const SingleSelectFieldDetailItemOptions = (props) => {
  const {
    search,
    setSearch,
    opportunity,
    fieldDefinition,
    field,
    onFieldChanged,
    setSelectedOption,
    closePopover,
    popoverActions,
    lazyLoad = false
  } = props

  const { isFetching, referenceFieldOptions, hasMore, nextPage } = useReferenceFieldOptionsList()

  const options = useFieldDefinitionOptions(fieldDefinition)

  const onFieldChangedInternal = useCallback((value) => {
    const { label } = fieldDefinition
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [fieldDefinition, opportunity, field, onFieldChanged])

  const onOptionClick = useCallback((e, option) => {
    e.preventDefault()
    setSelectedOption(option)
    onFieldChangedInternal(option.value)
    closePopover()
  }, [setSelectedOption, onFieldChangedInternal, closePopover])

  const match = useCallback(({ label = '' }) => {
    return !search || [label, label.toLowerCase()].some((str) => str.includes(search))
  }, [search])

  const filteredOptions = useMemo(() => {
    let opts
    if (lazyLoad) {
      opts = referenceFieldOptions.map((o) => (
        {
          label: o.label,
          value: o.key
        }
      ))
      if (hasMore) {
        opts.push({ autoLoad: true })
      }
    } else {
      opts = options.filter(match)
    }
    return opts
  }, [options, lazyLoad, hasMore, referenceFieldOptions, match])

  const handleClearSearch = useCallback(() => {
    setSearch('')
  }, [setSearch])

  const handleSearchChange = useCallback((event) => {
    setSearch(event.target.value)
  }, [setSearch])

  const handleWaypointEntered = useCallback(() => {
    nextPage()
  }, [nextPage])

  const renderItem = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { measure, registerChild } = cellMeasurerArgs
    const option = filteredOptions[index]
    const showWaypoint = hasMore && !isFetching && option.autoLoad
    const selected = option.value === (field?.originalValue ?? '')

    return (
      <div ref={registerChild} style={style}>
        {showWaypoint ? (
          <Waypoint onEnter={handleWaypointEntered}>
            <div className="flex justify-center py-2">
              <FontAwesomeIcon icon="spinner" size="sm" className="spinner mx-2" />
            </div>
          </Waypoint>
        ) : (
          <SingleSelectFieldDetailItemOptionItem
            measure={measure}
            onClick={onOptionClick}
            option={option}
            selected={selected} />
        )}
      </div>
    )
  }, [filteredOptions, onOptionClick, handleWaypointEntered, isFetching, hasMore, field])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: DEFAULT_HEIGHT,
    rowRenderer: renderItem,
  })

  useEffect(() => {
    if (popoverActions?.current) {
      popoverActions.current.updatePosition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptions])

  return (
    <>
      <SearchBox
        className="p-3"
        value={search}
        onClear={handleClearSearch}
        onChange={handleSearchChange}
        autoFocus
        disableHint
        dense
      />
      {isFetching && filteredOptions.length === 0 ? (
        <div className="flex justify-center py-2">
          <FontAwesomeIcon icon="spinner" size="sm" className="spinner mx-2" />
        </div>
      ) : (
        <>
          {!filteredOptions.length && (
            <div className="select-item hover:bg-color-edeeee">No results</div>
          )}
          <div className="w-full flex-grow" style={{ height: (filteredOptions.length * DEFAULT_HEIGHT), maxHeight: 'calc(100vh - 20vh)' }}>
            <VirtualizedList
              className="focus:outline-none"
              deferredMeasurementCache={cellMeasurerCache}
              rowHeight={cellMeasurerCache.rowHeight}
              rowCount={filteredOptions.length}
              rowRenderer={rowRenderer} />
          </div>
        </>
      )}
    </>
  )
}

export default SingleSelectFieldDetailItemOptions
