import PropTypes from 'prop-types/prop-types'

export const paddingPropTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number
}

export const marginPropTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number
}

export const rectPropTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

export const sizePropTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export const offsetPropTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

export const strokePropTypes = {
  stroke: PropTypes.string,
  strokeOpacity: PropTypes.number,
  strokeLinecap: PropTypes.oneOf(['butt', 'round', 'square']),
  strokeLinejoin: PropTypes.oneOf(['arcs', 'bevel', 'miter', 'miter-clip', 'round']),
  strokeWidth: PropTypes.number,
  fill: PropTypes.string,
}

export const svgTextPropTypes = {
  textAnchor: PropTypes.oneOf(['start', 'middle', 'end']),
  fontWeight: PropTypes.oneOf(['normal', 'bold', 'bolder', 'lighter', 100, 200, 300, 400, 500, 600, 700, 800, 900, 'inherit']),
  fontSize: PropTypes.number,
  fontFamily: PropTypes.string,
  fill: PropTypes.string,
}
