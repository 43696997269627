import React, { useMemo } from 'react'
import { Error } from '@getoutreach/react-icons'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import { copyToClipboard } from '../../lib/clipboard'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import EmptyState from '../empty/emptyState'

const RealtimeViewError = ({ title, subTitle, error }) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin
    )
  }, [checkPermissions])

  return (
    <div className="flex items-center justify-center w-full py-8 px-6 mb-6">
      <EmptyState
        iconControl={(
          <Tippy
            onShow={() => error && permissions.CanAccessInternalAdmin}
            className="focus:outline-none"
            interactive={true}
            offset={[0, 0]}
            delay={[0, 0]}
            maxWidth={500}
            duration={[300, 0]}
            zIndex={9999999}
            arrow={roundArrow}
            appendTo={document.body}
            content={(
              <div className="px-6 py-4 flex flex-col justify-center">
                <pre
                  className="text-size-10px text-color-151d49-90 font-normal leading-tight"
                  style={{ whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(error, null, 2)}
                </pre>
                <button onClick={() => copyToClipboard(error, true)} className="text-color-2e5bff uppercase text-size-13px font-bold mt-2">
                  Copy to Clipboard
                </button>
              </div>
            )}
            theme="canopy">
            <div className="flex items-center justify-center w-12 h-12 rounded-full">
              <Error className="focus:outline-none" htmlColor="#5951FF" transform="scale(2.5)" />
            </div>
          </Tippy>
        )}
        header={title}
        subHeader={subTitle}
      />
    </div>
  )
}

export default RealtimeViewError
