import { clearSearchFilters } from '../../actions'
import { featureNames } from '../../constants/featureNames'
import { featureSettingSelector } from '../../selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { permissionNames } from '../../constants/permissionNames'
import { useAuth } from '../../context/auth'
import { useDelay } from '../../hooks/useDelay'
import { useDispatch, useSelector } from 'react-redux'
import { KeyPressHandler } from '../../hooks/useKeyPress'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import { usePermissions } from '../../context/permissions'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useTenantInfo } from '../../context/tenantInfo'
import { useUserPrefs } from '../../context/userPrefs'
import classNames from 'classnames'
import iconAnalytics from '../../assets/menu-icon-analytics.png'
import iconAnalyticsActive from '../../assets/menu-icon-analytics-active.png'
import iconDeals from '../../assets/menu-icon-deals.png'
import iconDealsActive from '../../assets/menu-icon-deals-active.png'
import iconForecasting from '../../assets/menu-icon-forecasting.png'
import iconForecastingActive from '../../assets/menu-icon-forecasting-active.png'
import iconRepPerformance from '../../assets/menu-icon-teams.png'
import iconRepPerformanceActive from '../../assets/menu-icon-teams-active.png'
import iconScenario from '../../assets/menu-icon-scenario.png'
import iconScenarioActive from '../../assets/menu-icon-scenario-active.png'
import iconSummary from '../../assets/menu-icon-summary.png'
import iconSummaryActive from '../../assets/menu-icon-summary-active.png'
import React, { useEffect, useMemo, useCallback, useState } from 'react'
import SideMenuFooter from './sideMenuFooter'
import SideMenuItem from './sideMenuItem'
import SideMenuLogo from './sideMenuLogo'
import SystemAdminMenu from './systemAdminMenu'

const ManagerSideMenu = () => {
  const { isAuthenticated, isImpersonatingUser, tenantId, personId } = useAuth()

  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames: nearlineFeatureNames } = useNearlineFeatures()

  const { checkPermissions } = usePermissions()

  const { routes } = useRoutes()

  const { isSandbox } = useTenantInfo()

  const { delay, cancelDelay } = useDelay()

  const { getPref, invalidate, savePref } = useUserPrefs()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanAccessForecast,
      permissionNames.CanAccessAnalytic,
      permissionNames.CanAccessScenarioPlanner,
      permissionNames.CanAccessDeal
    )
  }, [checkPermissions])

  const dispatch = useDispatch()

  const [open, setOpen] = useState(getPref('sidebarMenu', 'isOpen') ?? true)
  const [hoverOpen, setHoverOpen] = useState(false)

  const showMenu = useMemo(() => {
    return (tenantId && personId) || isImpersonatingUser
  }, [isImpersonatingUser, tenantId, personId])

  const history = useHistory()

  const summaryMatch = useRouteMatch(routes.summary)
  const userSummary = useRouteMatch(routes.userSummary)

  const repHomeMatch = useRouteMatch(routes.repHome)
  const performanceMatch = useRouteMatch(routes.performance)
  const forecastingMatch = useRouteMatch(routes.forecasting)
  const analyticsMatch = useRouteMatch(routes.analytics)
  const scenarioPlannerMatch = useRouteMatch(routes.scenarioPlanner)
  const pipelineMatch = useRouteMatch(routes.pipeline)

  const forecastingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.forecasting }))
  const reportingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.reporting }))
  const scenarioPlannerFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.scenarioPlanner }))

  const toggleOpen = useCallback(() => {
    if (open) {
      setHoverOpen(false)
    }
    setOpen(!open)
    savePref('sidebarMenu', 'isOpen', !open)
    invalidate()
    window.analytics.track('sideMenu.toggleClicked', { isOpen: !open })
  }, [invalidate, open, savePref])

  const onHover = useCallback(() => {
    delay('hoverOpen', () => setHoverOpen(true), 200)
  }, [delay])

  const onTouchOut = useCallback(() => {
    setHoverOpen(false)
  }, [])

  const onHoverOut = useCallback((e) => {
    cancelDelay('hoverOpen')
    delay('hoverClose', () => {
      setHoverOpen(false)
    }, 200)
  }, [delay, cancelDelay])

  const onTouch = useCallback((e) => {
    setHoverOpen(!hoverOpen)
  }, [hoverOpen])

  useEffect(() => {
    if (hoverOpen) {
      document.addEventListener('touchstart', onTouchOut)
    } else {
      document.removeEventListener('touchstart', onTouchOut)
    }
    return () => {
      document.removeEventListener('touchstart', onTouchOut)
    }
  }, [hoverOpen, onTouchOut])

  const stopProp = useCallback((e) => e.stopPropagation(), [])

  return (
    <div
      className={
        classNames(
          `sidemenu
          relative
          w-full
          hidden
          md:inline
          md:w-18`,
          { 'md:w-64': open || hoverOpen },
          { fixed: !open }
        )
      }
      style={{
        zIndex: 999
      }}
      onTouchStart={onTouch}
      onMouseOver={onHover}
      onMouseLeave={onHoverOut}>
      <div
        onMouseOver={stopProp}
        onClick={toggleOpen}
        className={
          classNames(
            `md:left-18
            group
            p-4
            flex
            items-center
            justify-center
            fixed
            cursor-pointer`,
            { 'md:left-64': open || hoverOpen }
          )
        }
        style={{
          zIndex: 100000,
          top: '60px',
          marginLeft: '-28px'
        }}>
        <div
          className={
            `w-6
            h-6
            border
            border-color-d6d9e6
            rounded-full
            p-1
            flex items-center
            justify-center
            bg-color-ffffff
            group-hover:bg-color-5951FF`
          }>
          <FontAwesomeIcon
            icon="chevron-right"
            className={
              classNames(
                'accordion-icon transform scale-75',
                { 'rotate-180': open }
              )
            }
            color="#a6b2cf" />
        </div>
      </div>
      <div
        className={
          classNames(
            `bg-color-ffffff
            fixed
            md:w-18
            flex
            flex-col
            bottom-0
            border-r
            border-color-edeeee`,
            { 'md:w-64': open || hoverOpen }
          )
        }
        style={{ zIndex: 101, top: isSandbox ? 60 : 0 }}>
        <SideMenuLogo href={routes.summary} sideMenuIsOpen={open || hoverOpen} />
        {showMenu && (
          <>
            <div className="flex flex-col flex-1 mt-4">
              <SideMenuItem
                label="Team Summary"
                isCurrent={summaryMatch?.isExact || userSummary?.isExact}
                icon={<img src={summaryMatch ? iconSummaryActive : iconSummary} />}
                onClick={() => {
                  window.analytics.track('commit.sideMenuClicked', { action: 'Summary' })
                  history.push(routes.summary)
                }} />

              {!isAccessNearlineCheckReady ? <></> : (
                <>
                  {canAccessNearlineFeature({ featureName: nearlineFeatureNames.performance }) ? (
                    <SideMenuItem
                      label="Rep Performance"
                      isCurrent={performanceMatch}
                      icon={(
                        <img src={performanceMatch ? iconRepPerformanceActive : iconRepPerformance} />
                      )}
                      onClick={() => {
                        window.analytics.track('commit.sideMenuClicked', { action: 'Rep Performance' })
                        history.push(routes.performance)
                      }} />
                  ) : (
                    <SideMenuItem
                      label="Rep Performance"
                      isCurrent={repHomeMatch}
                      icon={<img src={repHomeMatch ? iconRepPerformanceActive : iconRepPerformance} />}
                      onClick={() => {
                        window.analytics.track('commit.sideMenuClicked', { action: 'Rep Performance' })
                        history.push(routes.repHome)
                      }} />
                  )}
                </>
              )}

              {permissions.CanAccessForecast && forecastingFeature && forecastingFeature.payload.enabled && (
                <KeyPressHandler
                  keyPressProps={{
                    doubleTap: true,
                    targetKey: 'f'
                  }}>
                  <SideMenuItem
                    label="Forecasting"
                    isCurrent={forecastingMatch}
                    icon={<img src={forecastingMatch ? iconForecastingActive : iconForecasting} />}
                    onClick={() => {
                      window.analytics.track('commit.sideMenuClicked', { action: 'Forecasting' })
                      history.push(routes.forecasting)
                    }} />
                </KeyPressHandler>
              )}

              {permissions.CanAccessAnalytic && reportingFeature && reportingFeature.payload.enabled && (
                <SideMenuItem
                  link={routes.analytics}
                  label="Analytics"
                  isCurrent={analyticsMatch}
                  icon={<img src={analyticsMatch ? iconAnalyticsActive : iconAnalytics} />}
                  onClick={() => {
                    window.analytics.track('commit.sideMenuClicked', { action: 'Analytics' })
                    history.push(routes.analytics)
                  }} />
              )}

              {permissions.CanAccessScenarioPlanner && scenarioPlannerFeature && scenarioPlannerFeature.payload.enabled && (
                <SideMenuItem
                  label="Scenario Planner"
                  isCurrent={scenarioPlannerMatch}
                  icon={<img src={scenarioPlannerMatch ? iconScenarioActive : iconScenario} />}
                  onClick={() => {
                    window.analytics.track('commit.sideMenuClicked', { action: 'Scenario Planner' })
                    history.push(routes.scenarioPlanner)
                  }} />
              )}

              {permissions.CanAccessDeal && (
                <SideMenuItem
                  label="Deals"
                  isCurrent={pipelineMatch}
                  icon={<img src={pipelineMatch ? iconDealsActive : iconDeals} />}
                  onClick={() => {
                    window.analytics.track('commit.sideMenuClicked', { action: 'Deals' })
                    dispatch(clearSearchFilters())
                    history.push(routes.pipeline)
                  }} />
              )}

              {permissions.CanAccessInternalAdmin && (
                <SystemAdminMenu />
              )}

              <SideMenuFooter open={open || hoverOpen} />
            </div>
          </>
        )}

      </div>
    </div>
  )
}

export default ManagerSideMenu
