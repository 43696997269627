import React, { useCallback } from 'react'
import { useTenantInfo } from '../../context/tenantInfo'
import { useAuth } from '../../context/auth'

const SandboxBanner = () => {
  const { isSandbox, tenantInfo } = useTenantInfo()

  const { stopImpersonating } = useAuth()

  const { name } = tenantInfo

  const onExit = useCallback(() => {
    stopImpersonating()
    window.location.href = '/'
  }, [stopImpersonating])

  return (
    <>
      {isSandbox && (
        <div style={{ zIndex: 300, top: 0 }} className="flex justify-between items-center p-4 bg-color-5951FF text-color-ffffff fixed w-full">
          <div>
            <span className="font-weight-200">{'Sandbox name: '}</span>
            <span>{name}</span>
          </div>
          <span>SANDBOX MODE</span>
          <button onClick={onExit}>Exit Sandbox</button>
        </div>
      )}
    </>
  )
}

export default SandboxBanner
