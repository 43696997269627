import React, { useMemo } from 'react'
import { useFormatCurrency } from '../../../hooks/useFormatCurrency'

const DealListItem = (props) => {
  const {
    opp
  } = props

  const { formatCurrency } = useFormatCurrency()

  const name = useMemo(() => {
    return opp?.opportunity?.name ?? ''
  }, [opp])

  const amount = useMemo(() => {
    return formatCurrency(opp?.opportunity?.amount ?? 0)
  }, [opp, formatCurrency])

  return (
    <div
      className="flex justify-between my-4">
      <div className="w-1/2">
        <div className="w-full text-size-14px text-color-09242f">{name}</div>
      </div>
      <div className="text-size-14px text-color-818e93 font-weight-400">{amount}</div>
    </div>
  )
}

export default DealListItem
