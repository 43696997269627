import React from 'react'
import { ForecastConfigsProvider } from '../../context/forecastConfigs'
import { ForecastingProvider } from '../../context/forecasting'
import MainTemplate from './mainTemplate'

const ForecastingTemplate = (props) => {
  return (
    <ForecastConfigsProvider>
      <ForecastingProvider>
        <MainTemplate {...props} />
      </ForecastingProvider>
    </ForecastConfigsProvider>
  )
}

export default ForecastingTemplate
