import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import Container from '../controls/container'
import Repeat from '../controls/repeat'
import Text from '../controls/text'
import Plural from '../controls/plural'
import Link from '../controls/link'
import Tooltip from '../controls/tooltip'
import DateTime from '../controls/dateTime'
import Number from '../controls/number'
import CalloutItem from '../controls/calloutItem'
import LegendItem from '../controls/legendItem'
import BarChart from '../controls/barChart'
import Button from '../controls/button'
import FunnelChart from '../controls/funnelChart'
import LineChart from '../controls/lineChart'
import DonutChart from '../controls/donutChart'
import Row from '../controls/row'
import Column from '../controls/column'
import Icon from '../controls/icon'
import Fragment from '../controls/fragment'
import pluralize from 'pluralize'
import { DonutHolePlugin } from '../plugins/donutHolePlugin'
import { LineIntersectPlugin } from '../plugins/lineIntersectPlugin'
import { LineMarkerPlugin } from '../plugins/lineMarkerPlugin'
import { LineChartMarkerPlugin } from '../plugins/lineChartMarkerPlugin'
import { pluginTypes } from '../plugins'
import { number } from '../lib/number'
import { FunctionEvalError, EvalControlError, AttributeEvalError, PluginEvalError, RegisteredFunctionError } from '../../errors'
import { formatDate, salesPeriodName, salesPeriod } from '../functions'
import { getSeriesColor, getSeriesColorsRange } from '../colors'
import ErrorBoundary from '../controls/errorBoundary'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { sizeOptions } from './helpers'
import { useGMLContentModal } from '../context/gmlContentModal'

export const controlTypes = {
  Container: 1,
  Repeat: 2,
  If: 3,
  Text: 4,
  Plural: 5,
  DatetimeMask: 6,
  NumberMask: 7,
  CalloutItem: 8,
  LegendItem: 9,
  BarChart: 10,
  PlainText: 11,
  Eval: 12,
  Button: 13,
  Link: 14,
  ChartText: 15,
  Tooltip: 16,
  Plugin: 17,
  FunnelChart: 18,
  LineChart: 19,
  DonutChart: 20,
  Row: 21,
  Column: 22,
  TooltipContent: 23,
  Icon: 24,
  Fragment: 25,
}

const GMLRenderer = (props) => {
  const {
    tree,
    config,
    init = {},
    registeredData = {},
    registeredFunctions = {},
    contentType = 'app::default',
    useContentType = true,
    contentName,
    channel = 'web',
    size = 'md',
    debug,
    animate = false
  } = props

  const data = useMemo(() => {
    // TODO: get the data object from the parsed gml and merge in props.d
    const d = merge(get(tree, 'dataList', get(tree, 'data')), { ...init.data && init.data })
    // debug && console.log(d)
    return d
  }, [tree])

  const { formatCurrency } = useFormatCurrency()
  const { formatCurrency: formatShortCurrency } = useFormatCurrency({ shortCurrency: true })

  const contentModal = useGMLContentModal()

  const functions = useMemo(() => {
    // functions are accumulated from the gml file as well as pre-defined ones
    const funcs = {
      orderBy,
      plural: pluralize,
      number: (val, format) => {
        if (format === 'currency') {
          return formatCurrency(val)
        } else if (format === 'shortCurrency') {
          return formatShortCurrency(val)
        } else {
          return number(val, format)
        }
      },
      currency: (val) => formatCurrency(val),
      shortCurrency: (val) => formatShortCurrency(val),
      percent: (val) => number(val, 'percent'),
      formatDate,
      salesPeriodName,
      salesPeriod,
      getSeriesColor,
      getSeriesColorsRange,
      openContentModal: ({ contentName, size, title }) => {
        contentModal.setTree(tree)
        contentModal.setContentName(contentName)
        contentModal.setSize(size)
        contentModal.setTitle(title)
        contentModal.setOpen(true)
      },
      getRegisteredData: (key) => registeredData[key],
      getRegisteredFunction: (key) => {
        return (...args) => {
          try {
            const func = registeredFunctions && registeredFunctions[key]
            if (!func || !(typeof func === 'function')) {
              throw new RegisteredFunctionError(`Function does not exist by name "${key}"`)
            }

            return func(...args)
          } catch (err) {
            console.error(err)
          }
        }
      },
      ...init.functions && init.functions
    }
    forEach(get(tree, 'functionsList', get(tree, 'functions', [])), (f) => {
      try {
        // debug && console.log(f.raw)
        const func = eval(f.raw)
        if (func && typeof func === 'function') {
          funcs[f.name] = func
        }
      } catch (err) {
        throw new FunctionEvalError(`${f.name} ${f.raw}`, err)
      }
    })
    // debug && console.log('funcs', funcs)
    return funcs
  }, [tree, formatCurrency, formatShortCurrency])

  const obj = useMemo(() => {
    let contents = []
    if (has(tree, 'contentsList')) {
      contents = tree.contentsList
    } else if (has(tree, 'contents')) {
      contents = tree.contents
    }
    if (useContentType) {
      return find(contents, (c) => c.type === contentType) || contents[0]
    }
    if (contentName) {
      const contentByName = find(contents, (c) => c.name === contentName)
      if (contentByName) {
        return contentByName
      }
    }
    const contentByChannel = filter(contents, (c) => c.channel === channel) || []
    const sizeIndex = findIndex(sizeOptions, (s) => s === size)
    // debug && console.log('REQUESTED - channel', channel, 'size', size)
    let contentByChannelAndSize = find(contentByChannel, (c) => c.size === sizeOptions[sizeIndex])
    if (contentByChannelAndSize) {
      // debug && console.log('RETURNED - channel', channel, 'size', size)
      return contentByChannelAndSize
    } else {
      let i = sizeIndex - 1
      // try and get the next down recusively
      while (i > 0) {
        // eslint-disable-next-line no-loop-func
        contentByChannelAndSize = find(contentByChannel, (c) => c.size === sizeOptions[i])
        if (contentByChannelAndSize) {
          // debug && console.log('RETURNED - channel', channel, 'size', sizeOptions[i])
          return contentByChannelAndSize
        }
        i -= 1
      }
      // if we are here, we couldn't find any lower sizes so find the next highest size recursively
      i += 1
      while (i < sizeOptions.length) {
        // eslint-disable-next-line no-loop-func
        contentByChannelAndSize = find(contentByChannel, (c) => c.size === sizeOptions[i])
        if (contentByChannelAndSize) {
          // debug && console.log('RETURNED - channel', channel, 'size', sizeOptions[i])
          return contentByChannelAndSize
        }
        i += 1
      }
      // debug && console.log('RETURNED - channel', channel, 'size', 'first content node')
      // if you couldn't find the size then default to the first content node
      return contents[0]
    }
  }, [tree, contentType, contentName, channel, size])

  // return a copy of all the props passed into the renderer
  const getRendererProps = useCallback((obj) => {
    return {
      tree,
      config,
      data,
      functions,
      currentObject: obj,
      animate,
    }
  }, [props])

  const getObjectType = useCallback((type, isRoot) => {
    // console.log('getObjectType', type, isRoot)
    if (isRoot) {
      return React.Fragment
    }
    switch (type) {
      case controlTypes.Container: return Container
      case controlTypes.Repeat: return Repeat
      // case controlTypes.If: return If
      case controlTypes.Text: return Text
      case controlTypes.Plural: return Plural
      case controlTypes.DatetimeMask: return DateTime
      case controlTypes.NumberMask: return Number
      case controlTypes.CalloutItem: return CalloutItem
      case controlTypes.LegendItem: return LegendItem
      case controlTypes.BarChart: return BarChart
      case controlTypes.Button: return Button
      case controlTypes.Link: return Link
      // case controlTypes.ChartText: return ChartText
      case controlTypes.Tooltip: return Tooltip
      case controlTypes.Plugin: return Plugin
      case controlTypes.FunnelChart: return FunnelChart
      case controlTypes.LineChart: return LineChart
      case controlTypes.DonutChart: return DonutChart
      case controlTypes.Row: return Row
      case controlTypes.Column: return Column
      case controlTypes.Icon: return Icon
      case controlTypes.Fragment: return Fragment
      default:
    }
  }, [])

  const createElement = useCallback((obj, config, isRoot, childIndex) => {
    const controlType = get(obj, 'type', 0)
    // debug && console.log('controlType', controlType, obj)
    if (get(obj, 'text') === '{<nil>}') {
      return ''
    }
    if (controlType === controlTypes.PlainText) {
      return get(obj, 'text', '')
    } else if (controlType === controlTypes.Eval) {
      try {
        // debug && console.log('text', get(obj, 'text'))
        const text = eval(get(obj, 'text'))
        return text
      } catch (err) {
        throw new EvalControlError(get(obj, 'text'), err)
      }
    } else if (controlType === controlTypes.TooltipContent) {
      // don't render the Tooltip GML
      // each Gambit UI control should create a GMLRenderer for its tooltip content
      return
    } else if (controlType === controlTypes.Plugin) {
      // don't render the Plugin GML
      return
    }

    const objType = getObjectType(controlType, isRoot)
    if (!objType) {
      return `{unsupportedControlType: ${controlType}}`
    }

    let children
    if (has(obj, 'contentsList') || has(obj, 'contents')) {
      if (has(obj, 'contentsList')) {
        children = get(obj, 'contentsList')
      } else if (has(obj, 'contents')) {
        children = get(obj, 'contents')
      }
    } else if (has(obj, 'childrenList')) {
      children = get(obj, 'childrenList')
    } else if (has(obj, 'children')) {
      children = get(obj, 'children')
    }
    // debug && console.log('children', children, data)

    const props = {}
    if (objType.name) {
      props.getRendererProps = () => getRendererProps(obj)
    }
    const attributes = get(obj, 'attributesList', get(obj, 'attributes', []))
    forEach(attributes, (a) => {
      if (a.evaluate) {
        try {
          // debug && console.log('value', a.value)
          const value = eval(a.value)
          props[a.key] = value
        } catch (err) {
          throw new AttributeEvalError(a.value, err)
        }
      } else {
        props[a.key] = a.value
      }
    })

    const childNodes = (children || []).map((child, index) => createElement(child, config, false, index))

    if (has(props, 'name')) {
      const { name } = props
      props.className = classNames(get(props, 'className'), get(config, `className.${name}`))

      const cfg = get(config, `config.${name}`)
      // debug && console.log(name, cfg)
      if (cfg) {
        props.config = cfg
      }
    }

    if (typeof objType === 'function') {
      props.childIndex = childIndex

      if (debug) {
        props.debug = true
      }
    }

    return React.createElement(
      objType,
      props,
      ...childNodes
    )
  }, [obj, config])

  return (
    <ErrorBoundary>
      {obj && createElement(obj, config, true)}
    </ErrorBoundary>
  )
}

export const getPlugins = (getRendererProps) => {
  const rendererProps = getRendererProps()
  const { currentObject, functions, data } = rendererProps
  const children = get(currentObject, 'childrenList', get(currentObject, 'children', []))
  const p = filter(children, (c) => c.type === controlTypes.Plugin)
  const plugins = p.map((plugin) => {
    const attributes = get(plugin, 'attributesList', get(plugin, 'attributes', []))
    const type = get(find(attributes, (a) => a.key === 'type'), 'value')
    const obj = { type }
    switch (type) {
      case pluginTypes.donutHole:
        obj.plugin = DonutHolePlugin
        break
      case pluginTypes.lineIntersect:
        obj.plugin = LineIntersectPlugin
        break
      case pluginTypes.lineMarker:
        obj.plugin = LineMarkerPlugin
        break
      case pluginTypes.lineChartMarker:
        obj.plugin = LineChartMarkerPlugin
        break
      default:
    }
    const config = find(attributes, (a) => a.key === 'config')
    const value = get(config, 'value', '')
    if (get(config, 'evaluate', false)) {
      try {
        obj.config = eval(value)
      } catch (err) {
        throw new PluginEvalError(JSON.stringify(plugin), err)
      }
    } else {
      obj.config = value
    }

    const hideAttr = find(attributes, (a) => a.key === 'hide')
    if (get(hideAttr, 'evaluate', false)) {
      try {
        obj.config.hide = eval(get(hideAttr, 'value', '{false}'))
      } catch (err) {
        throw new PluginEvalError(JSON.stringify(plugin), err)
      }
    }

    return obj
  })
  return plugins
}

export const createTooltipContent = (getRendererProps, optionalData) => {
  const rendererProps = getRendererProps()
  const { currentObject, config, functions, animate } = rendererProps
  const children = get(currentObject, 'childrenList', get(currentObject, 'children', []))
  const tooltipObj = find(children, (c) => c.type === controlTypes.TooltipContent)
  if (tooltipObj) {
    const attributesList = get(tooltipObj, 'attributesList', get(tooltipObj, 'attributes', [])).map((a) => {
      if (a.evaluate) {
        try {
          const data = optionalData || {} // this is so the eval has scope to a data object with _data, _seriesIndex, _dataIndex
          return {
            ...a,
            value: eval(a.value)
          }
        } catch (err) {
          throw new EvalControlError(a.value, err)
        }
      }
    })
    const tooltipTree = {
      attributesList,
      contentsList: [
        {
          children: get(tooltipObj, 'childrenList', get(tooltipObj, 'children', []))
        }
      ]
    }
    const init = {
      data: {
        ...rendererProps.data,
        ...optionalData
      },
      functions
    }
    return {
      tree: tooltipTree,
      content: (
        <div>
          <ErrorBoundary>
            <GMLRenderer tree={tooltipTree} config={config} init={init} animate={animate} />
          </ErrorBoundary>
        </div>
      )
    }
  }
  return {
    tree: {},
  }
}

GMLRenderer.propTypes = {
  tree: PropTypes.object,
  config: PropTypes.object,
  registeredData: PropTypes.object,
  registeredFunctions: PropTypes.objectOf(PropTypes.func),
  contentType: PropTypes.string,
  useContentType: PropTypes.bool,
  channel: PropTypes.oneOf(['web', 'email', 'slack']),
  size: PropTypes.oneOf(sizeOptions),
  debug: PropTypes.bool,
  animate: PropTypes.bool,
}

export default GMLRenderer
