export const signalConfig = {
  // className: {
  //   myContainer: classNames('card'),
  //   header: classNames('card-header'),
  //   footer: classNames('card-footer')
  // },
  config: {
    averageCycleTimePresentAndPastChart: {
      bar: {
        margin: {
          top: 0,
          bottom: 0,
          left: 6,
          right: 6,
        },
      },
    },
    metric_averageSalesCycleChart: {
      padding: {
        top: 40,
        bottom: 20,
        left: 0,
        right: 0
      },
    },
    metric_winLossModelChart: {
      padding: {
        top: 20,
        bottom: 0,
        left: 30,
        right: 40
      },
      bar: {
        margin: {
          top: 3,
          bottom: 3,
          left: 0,
          right: 0,
        },
        group: {
          margin: {
            top: 35,
            bottom: 35,
            left: 0,
            right: 0,
          },
        },
      },
      barLabel: {
        offset: {
          x: 5,
          y: 1,
          width: 0,
          height: 0
        },
        style: {
          textAnchor: 'start',
          fontWeight: 'bold',
          fontSize: 12,
          fontFamily: 'Proxima Nova',
          fill: '#a6aebe',
        },
      },
      axisBarLabel: {
        offset: {
          x: 20,
          y: 0,
          width: 0,
          height: 0
        },
      },
      groupBarLabel: {
        offset: {
          x: -4,
          y: -2,
          width: 0,
          height: 0
        },
      },
    },
    metric_sourceConversionModelChart: {
      padding: {
        top: 20,
        bottom: 0,
        left: 30,
        right: 40
      },
      bar: {
        margin: {
          top: 3,
          bottom: 3,
          left: 0,
          right: 0,
        },
        group: {
          margin: {
            top: 35,
            bottom: 35,
            left: 0,
            right: 0,
          },
        },
      },
      barLabel: {
        offset: {
          x: 5,
          y: 1,
          width: 0,
          height: 0
        },
        style: {
          textAnchor: 'start',
          fontWeight: 'bold',
          fontSize: 12,
          fontFamily: 'Proxima Nova',
          fill: '#a6aebe',
        },
      },
      axisBarLabel: {
        offset: {
          x: 20,
          y: 0,
          width: 0,
          height: 0
        },
      },
      groupBarLabel: {
        offset: {
          x: -4,
          y: -2,
          width: 0,
          height: 0
        },
      },
    },
    metric_dealPacingChart: {
      padding: {
        top: 20,
        bottom: 35,
        left: 0,
        right: 0
      },
      bar: {
        style: {
          strokeWidth: 14,
          strokeLinecap: 'round'
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 10,
          right: 10,
        },
        stack: {
          style: {
            strokeWidth: 28,
            strokeLinecap: 'butt'
          },
          margin: {
            top: 0,
            bottom: 0,
            left: 60,
            right: 60,
          }
        },
      },
    },
  }
}
