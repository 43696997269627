import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { featureNames } from '../constants/featureNames'
import { getMomentDaysAgo, getSeconds } from '../lib/moment'
import { featureSettingSelector } from '../selectors'

const defaultDurationInDays = 7

export function useDealChangeWindowFeature() {
  const dealChangeWindowFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.dealChangeWindow }))

  const durationInDays = useMemo(() => {
    return (dealChangeWindowFeature !== undefined && dealChangeWindowFeature.payload && dealChangeWindowFeature.payload.durationInDays) || defaultDurationInDays
  }, [dealChangeWindowFeature])

  const changeSince = useMemo(() => {
    return {
      seconds: getSeconds(getMomentDaysAgo(durationInDays)),
      nanos: 0
    }
  }, [durationInDays])

  return {
    durationInDays,
    changeSince
  }
}
