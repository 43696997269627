import React, { useEffect, useCallback, useState } from 'react'
import FieldSelect from './fields/fieldSelect'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import { ParameterType } from '../../grpc/enums'

const ValueParameter = (props) => {
  const {
    user,
    parameter
  } = props

  const { setValue, addNewParameter } = useRealtimeFilterEditor()

  const { options, key: parameterKey, label } = parameter
  const { value = {} } = user ?? {}
  const { fieldType } = value

  const [source] = options?.sourcesList ?? [{}]
  const { fieldsList } = source
  const [fieldList] = fieldsList ?? [{}]
  const { valuesList = [] } = fieldList

  const [inputValue, setInputValue] = useState()

  useEffect(() => {
    if (!valuesList?.length) {
      return
    }

    const selected = valuesList.find((vl) => vl.value === value?.value)
    if (!selected) {
      return
    }
    setInputValue([selected])
  }, [value, valuesList])

  const handleAddValue = useCallback(() => {
    addNewParameter(parameter, ParameterType.PARAMETER_TYPE_VALUE)
  }, [parameter, addNewParameter])

  const handleValueChange = useCallback((options) => {
    const [option] = options

    setInputValue(options)
    setValue(parameterKey, {
      value: option?.value,
      fieldType
    })
  }, [setValue, fieldType])

  return (
    <div className="self-start -ml-4">
      {user
        ? (
          <div className="flex flex-col mr-4 last:mr-0">
            <div className="mb-1 pl-4 text-color-51636a text-size-16px font-weight-500">{label}</div>
            <FieldSelect
              fullWidth
              onChange={handleValueChange}
              selectedDisplay={inputValue?.map(({ label }) => label).join(', ')}
              getIsSelected={({ value }) => inputValue?.find((iv) => iv.value === value)}
              values={valuesList} />
          </div>
        )
        : (
          <div className="filters-actions self-start">
            <button onClick={handleAddValue}>+ Add Value</button>
          </div>
        )}
    </div>
  )
}

export default ValueParameter
