import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { permissionNames } from '../../constants/permissionNames'
import { useAuth } from '../../context/auth'
import { useDelay } from '../../hooks/useDelay'
import { usePermissions } from '../../context/permissions'
import { usePipelineFeature } from '../pipeline/hooks'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useTenantInfo } from '../../context/tenantInfo'
import { useUserPrefs } from '../../context/userPrefs'
import classNames from 'classnames'
import iconDeals from '../../assets/menu-icon-deals.png'
import iconDealsActive from '../../assets/menu-icon-deals-active.png'
import iconSummary from '../../assets/menu-icon-summary.png'
import iconSummaryActive from '../../assets/menu-icon-summary-active.png'
import React, { useState, useMemo, useCallback } from 'react'
import SideMenuFooter from './sideMenuFooter'
import SideMenuItem from './sideMenuItem'
import SideMenuLogo from './sideMenuLogo'
import SystemAdminMenu from './systemAdminMenu'

const RepSideMenu = (props) => {
  const { isAuthenticated, isImpersonatingUser, tenantId, personId } = useAuth()

  const { checkPermissions } = usePermissions()

  const { delay, cancelDelay } = useDelay()

  const { getPref, invalidate, savePref } = useUserPrefs()

  const { routes } = useRoutes()

  const { isSandbox } = useTenantInfo()

  const [hoverOpen, setHoverOpen] = useState(false)

  const [open, setOpen] = useState(getPref('sidebarMenu', 'isOpen') ?? true)

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanAccessDeal
    )
  }, [checkPermissions])

  const toggleOpen = useCallback(() => {
    if (open) {
      setHoverOpen(false)
    }

    setOpen(!open)
    savePref('sidebarMenu', 'isOpen', !open)
    invalidate()
    window.analytics.track('sideMenu.toggleClicked', { isOpen: !open })
  }, [invalidate, open, savePref])

  const onHover = useCallback(() => {
    delay('hoverOpen', () => setHoverOpen(true), 200)
  }, [delay])

  const onHoverOut = useCallback((e) => {
    cancelDelay('hoverOpen')
    delay('hoverClose', () => {
      setHoverOpen(false)
    }, 200)
  }, [delay, cancelDelay])

  const onTouch = useCallback((e) => {
    setHoverOpen(!hoverOpen)
  }, [hoverOpen])

  const showMenu = useMemo(() => {
    return (tenantId && personId) || isImpersonatingUser
  }, [isImpersonatingUser, tenantId, personId])

  const history = useHistory()

  const performanceMatch = useRouteMatch(routes.performance)
  const pipelineMatch = useRouteMatch(routes.pipeline)

  const pipelineFeature = usePipelineFeature()

  const stopProp = useCallback((e) => e.stopPropagation(), [])

  return (
    <div
      className={
        classNames(
          `relative
          sidemenu
          w-full
          hidden
          md:inline
          md:w-18
          md:hover:w-2/5
          lg:hover:w-1/5`,
          { 'md:64': open || hoverOpen },
          { fixed: !open }
        )
      }
      style={{
        zIndex: 999
      }}
      onTouchStart={onTouch}
      onMouseOver={onHover}
      onMouseLeave={onHoverOut}>
      <div
        onMouseOver={stopProp}
        onClick={toggleOpen}
        className={
          classNames(
            `md:left-18
            group
            p-4
            flex
            items-center
            justify-center
            fixed
            cursor-pointer`,
            { 'md:left-64': open || hoverOpen }
          )
        }
        style={{
          zIndex: 100000,
          top: '60px',
          marginLeft: '-28px'
        }}>
        <div
          className={
            `w-6
            h-6
            border
            border-color-d6d9e6
            rounded-full
            p-1
            flex items-center
            justify-center
            bg-color-ffffff
            group-hover:bg-color-5951FF`
          }>
          <FontAwesomeIcon
            icon="chevron-right"
            className={
              classNames(
                'accordion-icon transform scale-75',
                { 'rotate-180': open }
              )
            }
            color="#a6b2cf" />
        </div>
      </div>
      <div
        className={
          classNames(
            `bg-color-ffffff
            fixed
            md:w-18
            flex
            flex-col
            bottom-0
            pb-12
            border-r
            border-color-edeeee`,
            { 'md:w-64': open || hoverOpen }
          )
        }
        style={{ zIndex: 101, top: isSandbox ? 60 : 0 }}>
        <SideMenuLogo href={routes.summary} sideMenuIsOpen={open || hoverOpen} />
        {showMenu && (
          <div className="flex flex-col flex-1 mt-4">

            <SideMenuItem
              label="Performance"
              isCurrent={performanceMatch}
              icon={<img src={performanceMatch ? iconSummaryActive : iconSummary} />}
              onClick={() => {
                window.analytics.track('commit.sideMenuClicked', { action: 'Performance' })
                history.push(routes.performance)
              }} />

            {permissions.CanAccessDeal && pipelineFeature.enabled && (
              <SideMenuItem
                label="Pipeline"
                isCurrent={pipelineMatch}
                icon={<img src={pipelineMatch ? iconDealsActive : iconDeals} />}
                onClick={() => {
                  window.analytics.track('commit.sideMenuClicked', { action: 'Pipeline' })
                  history.push(routes.pipeline)
                }} />
            )}

            {permissions.CanAccessInternalAdmin && (
              <SystemAdminMenu />
            )}

            <SideMenuFooter open={open || hoverOpen} />
          </div>

        )}
      </div>
    </div>
  )
}

export default RepSideMenu
