import { createAction } from '@reduxjs/toolkit'

export const getTenantsKey = 'api/tenant/getTenants'
export const getTenantsRequested = createAction(`${getTenantsKey}/requested`)
export const getTenantsSuccess = createAction(`${getTenantsKey}/success`)
export const getTenantsFailed = createAction(`${getTenantsKey}/failed`)

export const getImpersonationUsersKey = 'api/tenant/getImpersonationUsers'
export const getImpersonationUsersRequested = createAction(`${getImpersonationUsersKey}/requested`)
export const getImpersonationUsersSuccess = createAction(`${getImpersonationUsersKey}/success`)
export const getImpersonationUsersFailed = createAction(`${getImpersonationUsersKey}/failed`)

export const getTenantOrganizationKey = 'api/tenant/getTenantOrganization'
export const getTenantOrganizationRequested = createAction(`${getTenantOrganizationKey}/requested`)
export const getTenantOrganizationSuccess = createAction(`${getTenantOrganizationKey}/success`)
export const getTenantOrganizationFailed = createAction(`${getTenantOrganizationKey}/failed`)
