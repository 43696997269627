import React from 'react'
import PropTypes from 'prop-types/prop-types'
import ChartText from '../controls/chartText'
import { pluginTypes } from '.'
import { strokePropTypes, offsetPropTypes, svgTextPropTypes } from '../propTypes/common'
import get from 'lodash/get'
import { getTextWidth } from '../lib/text'

export const defaultConfig = {
  marker: {
    offset: {
      x: 0,
      y: 0,
      width: 20,
      height: 0,
    },
    style: {
      stroke: '#333333',
      strokeOpacity: 0.5,
      strokeLinecap: 'round',
      strokeWidth: 5,
    },
  },
  label: {
    offset: {
      x: 10,
      y: 1,
    },
    style: {
      textAnchor: 'start',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#000',
    },
  },
}

const pluginConfigPropTypes = {
  marker: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(strokePropTypes),
  }),
  label: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  hide: PropTypes.bool,
}

export const LineChartMarkerPlugin = (config) => {
  const { props, chartRect, axisData } = config

  const { name } = props

  const c = { ...defaultConfig }
  const pluginConfig = {
    ...c,
    ...get(config, `plugins.${pluginTypes.lineChartMarker}`, {})
  }
  PropTypes.checkPropTypes(pluginConfigPropTypes, pluginConfig, 'prop', 'LineChartMarkerPlugin')

  const { marker, label } = pluginConfig

  const lineMarkerValue = get(pluginConfig, 'config.value')

  // console.log('lineMarkerValue', lineMarkerValue)
  // console.log('config', config)

  const textAttributes = {
    style: {
      ...label.style
    }
  }

  const hide = get(pluginConfig, 'config.hide', false)

  if (hide) {
    return <React.Fragment key={`linechartmarker-${name}`} />
  } else {
    let textWidth = 0
    try {
      const { fontWeight, fontSize, fontFamily } = label.style
      const text = pluginConfig.config.label
      textWidth = getTextWidth(text, fontWeight, fontSize, fontFamily)
    } catch (err) {
      console.log(err)
    }

    const x1 = chartRect.x + textWidth + 10
    const x2 = chartRect.x + chartRect.width

    let range = config.props.axisDataLabels ? config.axisData.max : config.seriesMinMax.max
    range = range === 0 ? 1 : range
    let y = (lineMarkerValue / range) * chartRect.height
    if (isNaN(y)) y = 0

    const y1 = chartRect.y + config.axisDataLabel.offset.y + (1 - y) + chartRect.height
    const y2 = y1

    textAttributes.x = chartRect.x
    textAttributes.y = y1 + label.offset.y

    return (
      <g key={`linechartmarker-${name}-${lineMarkerValue}`} className="g-plugin-linechartmarker">
        <line
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          className="focus:outline-none"
          style={{ ...marker.style && marker.style }} />
        <ChartText {...textAttributes}>{pluginConfig.config.label}</ChartText>
      </g>
    )
  }
}
