import React, { useEffect } from 'react'
import classNames from 'classnames'

const TargetSelectOption = (props) => {
  const {
    measure,
    selectedOption,
    onSelect,
    option,
  } = props

  useEffect(() => {
    measure && measure()
  }, [option])

  return (
    <button
      onClick={() => onSelect(option)}
      className={classNames('w-full px-4 py-2 text-size-16px text-left focus:outline-none hover:bg-color-edeeee',
        { 'font-weight-700': selectedOption.key === option.key })}>
      {option.value}
    </button>
  )
}

export default TargetSelectOption
