import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DropDown from './dropDown'
import FormulaSelect from './formulaSelect'
import TargetSelect from './targetSelect'
import TimePeriodSelect from './timePeriodSelect'
import FiltersSelect from './filtersSelect'
import { useDispatch, useSelector } from 'react-redux'
import { apiSuccessSelector } from '../../selectors'
import {
  getForecastSimulationLevelOptions,
  getForecastSimulationsAvailable,
  getScenarioPlannerOptions
} from '../../services/reportingService'
import { DateRangeOption } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import { defaultTimePeriod } from './constants'
import { DateRange } from 'react-date-range'
import {
  getForecastSimulationsAvailableKey,
  getForecastSimulationLevelOptionsKey,
  getScenarioPlannerOptionsKey
} from '../../actions/reportingService'
import { useTextField } from '../../hooks/useTextField'

const FormulaInputs = (props) => {
  const {
    tenantId,
    groupId,
    selectedFormula,
    onFormulaSelect,
    selectedTimePeriod,
    onTimePeriodSelect,
    selectedTarget,
    onTargetSelect,
    onClearFilters,
    selectedFilters,
    onFilterSelected,
    start = new Date(),
    end = new Date(),
  } = props

  const [startDate, setStartDate] = useState(start.toLocaleDateString('en-US'))
  const [endDate, setEndDate] = useState(end.toLocaleDateString('en-US'))
  const [filterGroupsOpened, setFilterGroupsOpened] = useState({})

  const filterSearch = useTextField()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getForecastSimulationsAvailable({ tenantId, groupId }))
    dispatch(getForecastSimulationLevelOptions())
    dispatch(getScenarioPlannerOptions({ canonicalOnly: true, useCache: true }))
  }, [])

  const apiSimulationsAvailableSuccess = useSelector((state) => apiSuccessSelector(state, getForecastSimulationsAvailableKey))
  const apiLevelOptionsSuccess = useSelector((state) => apiSuccessSelector(state, getForecastSimulationLevelOptionsKey))
  const apiScenarioPlannerOptionsSuccess = useSelector((state) => apiSuccessSelector(state, getScenarioPlannerOptionsKey))

  const disabled = useMemo(() => {
    return !apiSimulationsAvailableSuccess || !apiLevelOptionsSuccess || !apiScenarioPlannerOptionsSuccess
  }, [apiSimulationsAvailableSuccess, apiLevelOptionsSuccess, apiScenarioPlannerOptionsSuccess])

  const moreOptionsClicked = useCallback(() => {
    setStartDate(new Date().toLocaleDateString('en-US'))
    setEndDate(new Date().toLocaleDateString('en-US'))
    onTimePeriodSelect(defaultTimePeriod)
  }, [])

  const selectionRange = useMemo(() => {
    return {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection',
    }
  }, [startDate, endDate])

  const onDateRangeChange = useCallback((dateRange) => {
    const start = dateRange.selection.startDate.toLocaleDateString('en-US')
    const end = dateRange.selection.endDate.toLocaleDateString('en-US')
    setStartDate(start)
    setEndDate(end)
    onTimePeriodSelect({
      key: DateRangeOption.CUSTOM,
      value: `Between ${start} - ${end}`,
      start,
      end,
    })
  }, [])

  const onFilterGroupClicked = useCallback(({ field, isOpen }) => {
    if (field) {
      setFilterGroupsOpened({
        ...filterGroupsOpened,
        [field]: isOpen
      })
    }
  }, [filterGroupsOpened])

  return (
    <>
      <DropDown
        tabIndex="0"
        disabled={disabled}
        selectedOption={selectedFormula}>
        <FormulaSelect
          selectedOption={selectedFormula}
          onSelect={onFormulaSelect} />
      </DropDown>
      <DropDown
        disabled={disabled}
        selectedOption={selectedTimePeriod}>
        {selectedTimePeriod.key === DateRangeOption.CUSTOM
          ? (
            <div style={{ minWidth: 350 }}>
              <div className="flex items-center justify-between px-3">
                <div className="py-2 text-size-16px">Select a Range</div>
                <div className="text-size-12px text-color-2e5bff underline cursor-pointer" onClick={moreOptionsClicked}>More Options</div>
              </div>
              <DateRange
                editableDateInputs={false}
                onChange={onDateRangeChange}
                ranges={[selectionRange]}
                minDate={new Date()}
                moveRangeOnFirstSelection={false} />
            </div>
          )
          : (
            <TimePeriodSelect
              selectedOption={selectedTimePeriod}
              onSelect={onTimePeriodSelect} />
          )}
      </DropDown>
      <DropDown
        disabled={disabled}
        selectedOption={selectedTarget}>
        <TargetSelect
          selectedOption={selectedTarget}
          onSelect={onTargetSelect} />
      </DropDown>
      <DropDown
        disabled={disabled}
        selectedOption={selectedFilters}>
        <FiltersSelect
          onClearFilters={onClearFilters}
          onSelect={onFilterSelected}
          search={filterSearch}
          groupsOpened={filterGroupsOpened}
          onGroupClicked={onFilterGroupClicked}
          selectedFilters={selectedFilters} />
      </DropDown>
    </>
  )
}

export default FormulaInputs
