import { createReducer } from '@reduxjs/toolkit'
import { clearPipelineReportOpportunities } from '../actions'
import { getOpportunitiesSnapshotSuccess } from '../actions/reportingService'

const initialState = {
  opportunitiesList: [],
  totalCount: 0,
  nextPage: ''
}

export const pipelineReportOpportunities = createReducer(initialState, {
  [clearPipelineReportOpportunities.type]: (state, action) => initialState,
  [getOpportunitiesSnapshotSuccess.type]: (state, action) => {
    const data = action.payload
    const { opportunitiesList, totalCount, nextPage } = data
    state.opportunitiesList.push(...opportunitiesList)
    state.totalCount = totalCount
    state.nextPage = nextPage
    return state
  }
})
