import { ForecastPeriodType } from '../../grpc/enums'

export const totalCallKey = '__totalCall__'

export const wonCategory = { key: 'Won', name: 'Won' }

export const forecastPeriodSelections = {
  CURRENT_MINUS_ONE: -1,
  CURRENT: 0,
  CURRENT_PLUS_ONE: 1,
  CURRENT_PLUS_TWO: 2,
  CURRENT_PLUS_THREE: 3
}

const getForecastPeriodLabels = (label) => {
  return [
    { id: forecastPeriodSelections.CURRENT_MINUS_ONE, name: `Last ${label}` },
    { id: forecastPeriodSelections.CURRENT, name: `This ${label}` },
    { id: forecastPeriodSelections.CURRENT_PLUS_ONE, name: `Next ${label}` },
    { id: forecastPeriodSelections.CURRENT_PLUS_TWO, name: `+ 2 ${label}s` },
    { id: forecastPeriodSelections.CURRENT_PLUS_THREE, name: `+ 3 ${label}s` }
  ]
}

export const forecastPeriods = {
  [ForecastPeriodType.MONTH]: getForecastPeriodLabels('Month'),
  [ForecastPeriodType.QUARTER]: getForecastPeriodLabels('Quarter'),
  [ForecastPeriodType.HALF_YEAR]: getForecastPeriodLabels('Half Year'),
  [ForecastPeriodType.YEAR]: getForecastPeriodLabels('Year'),
  default: { id: forecastPeriodSelections.CURRENT, name: 'Sales Period' }
}
