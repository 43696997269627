export const canvasKey = {
  SUMMARY_METRICS: 'summary.metrics',
  SUMMARY_PIPELINE: 'summary.pipeline',
  DEAL_SIGNALS: 'deal.signals',
  REP_RATINGS: 'rep.ratings',
  REP_METRICS: 'rep.metrics',
  REP_PIPELINE: 'rep.pipeline',
  REP_SIGNALS: 'rep.signals',
}

export const learnMore = {
  'commit.metric.totalProjectedFinish': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Total_Projected_Finish ',
  'commit.metric.revenueToDate': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Revenue_Attainment_Metric',
  'commit.metric.scorecard': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Scorecard',
  'commit.metric.opportunityPacing': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Deal_Pacing',
  'commit.metric.weightedPipeline': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Weighted_Pipeline',
  'commit.metric.weightedPipelineNextPeriod': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Weighted_Pipeline',
  'commit.metric.nextPeriodCoverage': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Next_Period_Coverage',
  'commit.metric.historicalCoverage': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Historical_Coverage',
  'commit.metric.winLossModel': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Win_Loss_Model',
  'commit.metric.lostReasons': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Closed_Lost_Reason',
  'commit.metric.pipelineBySource': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Pipeline_by_Source',
  'commit.metric.salesCycleByStageOverTime': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#Sales_Cycle_Over_Time',
  'commit.metric.rollingPipelineBySource': 'https://support.outreach.io/hc/en-us/articles/4696635761179-Outreach-Commit-Metrics-Overview#12_Month_Rolling_Funnel'
}
