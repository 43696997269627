import React, { useRef, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import { getLinecapSize, getMinMax } from '../lib/gChart'
import { LineChartConfig, lineChartConfigPropTypes } from '../config/lineChartConfig'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import max from 'lodash/max'
import maxBy from 'lodash/maxBy'
import merge from 'lodash/merge'
import sum from 'lodash/sum'
import take from 'lodash/take'
import { getPlugins } from '../renderer/gmlRenderer'
import ChartText from './chartText'
import { getTextWidth } from '../lib/text'
import useRefResize from '../../hooks/useRefResize'
import { filter, has } from 'lodash'

const LineChart = (props) => {
  const {
    getRendererProps,
    config = {},
    height = 280,
    name,
    styleNames,
    childIndex,
    hide = false,
    axisLabelDivisor = 1,
    axisLabelFormatter,
    axisDataLabels = false,
    axisDataLabelFormatter,
    accumulateSeriesData = false,
    colors,
    minData,
    maxData,
    minAxisData,
    maxAxisData,
  } = props

  PropTypes.checkPropTypes(LineChart.propTypes, props, 'prop', 'LineChart')

  const containerRef = useRef()
  const svgRef = useRef()
  const [svgWidth, setSvgWidth] = useState(0)
  const [svgHeight, setSvgHeight] = useState(0)

  const series = useMemo(() => {
    if (accumulateSeriesData) {
      return props.series.map((s) => {
        return s.map((data, index) => sum(take(s, index + 1)))
      })
    } else {
      return props.series || []
    }
  }, [])

  const seriesMinMax = useMemo(() => {
    const minMax = getMinMax(series)
    return {
      min: minData || minMax.min,
      max: maxData || minMax.max
    }
  }, [series])

  const getBounds = useCallback((max, min) => {
    const maxStr = String(maxData || max)
    const maxBoundDigit = Number(maxStr.charAt(0)) + 1
    const maxBound = Math.floor(Number(maxBoundDigit + maxStr.substr(1).replace(/[0-9]/g, '0')))

    let minBound = 0
    let minBoundDigit = 0
    if (min < 0) {
      const minStr = String(Math.abs(minData || min))
      minBoundDigit = Number(minStr.charAt(0)) + 1
      minBound = Math.floor(Number(minBoundDigit + minStr.substr(1).replace(/[0-9]/g, '0'))) * -1
    }
    return {
      maxBound,
      maxBoundDigit,
      minBound,
      minBoundDigit,
    }
  }, [])

  const axisData = useMemo(() => {
    const seriesDataMax = seriesMinMax.max

    let max = seriesDataMax
    const min = seriesMinMax.min

    const bounds = getBounds(max, min)

    const data = []

    let divisor = 1
    switch (bounds.maxBoundDigit) {
      case 2:
        divisor = 4
        break
      case 4:
        divisor = 8
        break
      case 3:
      case 6:
        divisor = 6
        break
      case 5:
      case 10:
        divisor = 5
        break
      case 7:
        divisor = 7
        break
      case 8:
        divisor = 8
        break
      case 9:
        divisor = 9
        break
      default:
    }

    const axisIncrement = bounds.maxBound / divisor

    for (let i = 0; i < divisor; i += 1) {
      const dataPoint = Math.floor(axisIncrement * i)
      data.push(dataPoint)
    }

    if (data.length > 0) {
      const lastDataPt = data[data.length - 1]
      if (seriesDataMax > lastDataPt && seriesDataMax < bounds.maxBound
        && Math.abs(bounds.maxBound - seriesDataMax) < Math.abs(data[data.length - 1], seriesDataMax)
      ) {
        data.push(bounds.maxBound)
      }

      if (axisDataLabels) {
        // check the max against the axis labels that need to render
        max = Math.max(max, data[data.length - 1])
      }
    }

    const d = {
      data,
      seriesDataMax,
      max,
      min,
      ...bounds,
    }

    return d
  }, [seriesMinMax])

  const mergedConfig = useMemo(() => {
    const c = cloneDeep(LineChartConfig)
    merge(c, config)

    setSvgHeight(height)

    return c
  }, [])

  const linecapSize = useMemo(() => {
    return getLinecapSize(mergedConfig.line.style)
  }, [mergedConfig])

  const measured = useMemo(() => {
    const c = mergedConfig
    const m = {
      axisDataLabels: [],
      axisDataLabelMaxWidth: 0,
    }

    if (axisDataLabels) {
      const { fontWeight, fontSize, fontFamily } = c.axisDataLabel
      const labels = axisData.data.map((data, seriesIndex) => {
        const text = axisDataLabelFormatter ? axisDataLabelFormatter(data) : `${data}`
        const width = getTextWidth(text, fontWeight, fontSize, fontFamily)
        return {
          text,
          width,
          data
        }
      })
      m.axisDataLabels = labels
      m.axisDataLabelMaxWidth = labels.length > 0 ? maxBy(labels, 'width').width + c.axisDataLabel.offset.width : 0
    }

    return m
  }, [axisData, mergedConfig, seriesMinMax])

  const handleResize = useCallback(({ width }) => {
    setSvgWidth(width)
  }, [])

  useRefResize(containerRef, handleResize)

  const chartRect = useMemo(() => {
    const rect = {
      x: mergedConfig.padding.left + linecapSize,
      y: mergedConfig.padding.top + linecapSize,
      width: svgWidth - (mergedConfig.padding.left + mergedConfig.padding.right) - (linecapSize * 2),
      height: height - (mergedConfig.padding.top + mergedConfig.padding.bottom) - (linecapSize * 2),
    }
    if (axisDataLabels) rect.width -= measured.axisDataLabelMaxWidth
    return rect
  }, [svgWidth, mergedConfig, measured])

  const lineData = useMemo(() => {
    const lines = []
    const maxLength = maxAxisData || max(series.map((s) => s.length))

    series.map((arr, seriesIndex) => {
      const l = {
        points: [],
        style: { ...mergedConfig.line.style }
      }

      if (typeof colors === 'string') {
        l.style.stroke = colors
      } else if (Array.isArray(colors)) {
        l.style.stroke = get(colors, `[${seriesIndex}]`, '#000000')
      }

      arr.map((data, dataIndex) => {
        let percX = dataIndex / (maxLength - 1)
        let percY = data / axisData.max
        if (isNaN(percX)) percX = 0
        if (isNaN(percY)) percY = 0
        l.points.push({
          data,
          x: chartRect.x + (percX * chartRect.width),
          y: chartRect.y + chartRect.height - (percY * chartRect.height),
          seriesIndex,
          dataIndex,
        })
      })
      lines.push(l)
    })

    return {
      lines: lines.reverse()
    }
  }, [svgWidth, mergedConfig, chartRect, axisData])

  // the main config object
  const cfg = useMemo(() => {
    const c = mergedConfig
    c.props = props
    c.seriesMinMax = seriesMinMax
    c.axisData = axisData
    c.measured = measured
    c.lines = lineData.lines
    c.chartRect = chartRect
    c.colors = colors

    PropTypes.checkPropTypes(lineChartConfigPropTypes, c, 'prop', 'LineChart')

    // console.log(c)
    return c
  }, [svgWidth, mergedConfig, seriesMinMax, axisData, measured, chartRect, lineData])

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-linechart',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const renderLines = useCallback(() => {
    return lineData.lines.map((line, i) => {
      const { points = [], style } = line
      const pts = filter(points, (p) => has(p, 'data'))
      const seriesIndex = get(pts, '[0].seriesIndex', 0)
      const pathData = pts.map((p, i) => {
        if (i === 0) return `M${p.x},${p.y} `
        else return `L${p.x},${p.y} `
      })
      return (
        <g key={`line-${i}-${seriesIndex}`} className="g-linechart-line-group">
          <path
            d={pathData}
            className={`g-linechart-line series-${seriesIndex} focus:outline-none`}
            style={style} />
          {pts.map((p) => {
            return (
              <ellipse
                key={`pt-${p.seriesIndex}-${p.dataIndex}`}
                className={`pt series-${p.seriesIndex} data-${p.dataIndex} focus:outline-none`}
                cx={p.x}
                cy={p.y}
                rx={linecapSize}
                ry={linecapSize}
                style={{ ...style.stroke && { fill: style.stroke } }} />
            )
          })}
        </g>
      )
    })
  }, [lineData])

  const renderAxisLabels = useCallback(() => {
    if (axisLabelFormatter) {
      const maxLine = maxBy(cfg.lines, (line) => get(line, 'points', []).length)
      const maxLineLength = get(maxLine, 'points.length', 0)
      const maxLength = maxAxisData || maxLineLength
      const divisor = Math.max(1, axisLabelDivisor)
      const interval = divisor === 1 ? 1 : Math.round(maxLength / divisor / 10) * 10

      const points = get(maxLine, 'points', [])
      for (let i = points.length; i < maxLength; i += 1) {
        const percX = i / (maxLength - 1)
        const x = cfg.chartRect.x + (percX * cfg.chartRect.width)
        points.push({ x })
      }

      return points.map((pt, index) => {
        if ((index + 1) % interval === 0) {
          const label = axisLabelFormatter(index)
          const labelAttributes = {
            x: pt.x + cfg.label.offset.x,
            y: cfg.chartRect.y + cfg.chartRect.height + cfg.label.offset.y,
            style: {
              ...cfg.label.style
            }
          }

          const tickAttributes = {
            x1: pt.x,
            y1: cfg.chartRect.y + cfg.chartRect.height + cfg.tick.offset.y,
            x2: pt.x,
            y2: cfg.chartRect.y + cfg.chartRect.height + cfg.tick.offset.y + cfg.tick.offset.height,
            style: {
              ...cfg.tick.style
            }
          }

          return (
            <React.Fragment key={`linechart-axislabel-${index}`}>
              {label && (
                <g className="linechart-axislabel-group">
                  <line {...tickAttributes} />
                  <ChartText {...labelAttributes}>{label}</ChartText>
                </g>
              )}
            </React.Fragment>
          )
        } else {
          return <React.Fragment key={`linechart-axislabel-${index}`} />
        }
      })
    }
  }, [cfg])

  const renderAxisDataLabels = useCallback(() => {
    if (svgRef.current && axisDataLabels) {
      const labels = cfg.measured.axisDataLabels
      return (
        labels.map((labelData, i) => {
          const { text, data } = labelData

          const tickAttributes = {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            style: {
              strokeWidth: 1,
              strokeLinecap: 'round',
              stroke: '#d6d9e6',
              fill: 'none',
            },
          }

          const attributes = {
            style: {
              ...cfg.axisDataLabel.style
            }
          }
          let range = cfg.props.axisDataLabels ? cfg.axisData.max : cfg.seriesMinMax.max
          range = range === 0 ? 1 : range
          let y = (data / range) * cfg.chartRect.height
          if (isNaN(y)) y = 0

          attributes.x = cfg.chartRect.x + cfg.axisDataLabel.offset.x + cfg.chartRect.width
          attributes.y = cfg.chartRect.y + cfg.axisDataLabel.offset.y + (1 - y) + cfg.chartRect.height

          tickAttributes.x1 = cfg.chartRect.x
          tickAttributes.y1 = attributes.y
          tickAttributes.x2 = cfg.chartRect.x + cfg.chartRect.width
          tickAttributes.y2 = attributes.y

          return (
            <g key={`axisDataLabel-${data}-${i}`} className="g-charttext-group axis-data-label">
              <line {...tickAttributes} />
              <ChartText {...attributes}>{text}</ChartText>
            </g>
          )
        })
      )
    }
  }, [svgRef, cfg])

  const renderPlugins = useCallback((size) => {
    if (svgRef.current) {
      const plugins = getPlugins(getRendererProps)
      return plugins.map((p) => {
        merge(cfg, { plugins: { [p.type]: { config: p.config } } })
        return (
          p.plugin(cfg)
        )
      })
    }
  }, [cfg])

  return (
    <div ref={containerRef} {...attributes} style={{ height: svgHeight }}>
      <svg ref={svgRef} width="100%" height={svgHeight}>
        {props.debug && (
          <rect
            x={cfg.chartRect.x}
            y={cfg.chartRect.y}
            width={Math.max(cfg.chartRect.width, 0)}
            height={Math.max(cfg.chartRect.height, 0)}
            style={{ fill: 'red', opacity: 0.5 }} />
        )}

        {renderAxisDataLabels()}
        {renderLines()}
        {renderAxisLabels()}
        {renderPlugins()}
      </svg>
    </div>
  )
}

LineChart.propTypes = {
  getRendererProps: PropTypes.func,
  height: PropTypes.number,
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  axisLabelDivisor: PropTypes.number,
  axisLabelFormatter: PropTypes.func,
  axisDataLabels: PropTypes.bool,
  axisDataLabelFormatter: PropTypes.func,
  accumulateSeriesData: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  colors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  minData: PropTypes.number,
  maxData: PropTypes.number,
  minAxisData: PropTypes.number,
  maxAxisData: PropTypes.number,
}

export default LineChart
