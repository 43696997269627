import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import pluralize from 'pluralize'
import { apiFailedSelector, apiRequestedSelector } from '../../selectors'
import { searchOppsKey } from '../../actions/searchService'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const DealSearchSummary = (props) => {
  const { formatCurrency } = useFormatCurrency()

  const searchedOpps = useSelector((state) => state.searchedOpps)
  const { total = 0, sumAmount = 0 } = searchedOpps

  const apiRequested = useSelector((state) => apiRequestedSelector(state, searchOppsKey))
  const apiFailed = useSelector((state) => apiFailedSelector(state, searchOppsKey))

  const showEmptyState = useMemo(() => {
    return total === 0 && !apiRequested && !apiFailed
  }, [total, apiRequested, apiFailed])

  return (
    <div className="leading-none" style={{ minHeight: 60 }}>
      {showEmptyState || apiRequested ? null
        : (
          <>
            <div className="text-color-151d49 text-size-14px font-weight-500">Total</div>
            <div className="text-color-151d49 text-size-22px font-weight-700 mb-1">{formatCurrency(sumAmount)}</div>
            <div className="text-color-818e93 text-size-14px font-weight-500 whitespace-nowrap">
              {numeral(total).format('0,0')}
              {' '}
              {pluralize('deal', total)}
            </div>
          </>
        )}
    </div>
  )
}

export default DealSearchSummary
