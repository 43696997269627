import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setDock } from '../../actions'
import DateFieldDetailItem from '../fieldRenderers/dateFieldDetailItem'
import NumberFieldDetailItem from '../fieldRenderers/numberFieldDetailItem'
import StatusFieldDetailItem from '../fieldRenderers/statusFieldDetailItem'
import StageNameFieldDetailItem from '../fieldRenderers/stageNameFieldDetailItem'
import SingleSelectFieldDetailItem from '../fieldRenderers/singleSelectFieldDetailItem'
import { getOppDetailById, patchSearchItem } from '../../services/searchService'
import { cloneDeep, forEach, uniqBy } from 'lodash'
import { getFieldDefinition, getFieldFromObject } from '../fieldRenderers/helpers'
import { useNotification } from '../../hooks/useNotification'
import { useDebug } from '../../context/debug'
import useIsReadOnly from '../../hooks/useIsReadOnly'
import DealInsights from '../deals/dealInsights'
import { useRoutes } from '../../context/routes'
import classNames from 'classnames'
import useKeyPressEffect from '../../hooks/useKeyPress'
import { useDealChangeWindowFeature } from '../../hooks/useDealChangeWindowFeature'
import { ViewDealInCrmProvider } from '../../context/viewDealInCrm'
import ViewDealInCrm from '../deals/viewDealInCrm'
import { CanvasProvider } from '../../context/canvas'
import { DealSignalsProvider } from '../../context/dealSignals'
import DealSignals from '../deals/dealSignals'
import { canvasKey } from '../canvas/constants'
import DealSignalsHeader from '../deals/dealSignalsHeader'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import DealHealthDetailItem from '../fieldRenderers/dealHealthDetailItem'
import ActivityDetailItem from '../fieldRenderers/activityDetailItem'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'
import { useUserInput } from '../../hooks/useUserInput'
import { canonicalFieldKeys, canopyFields } from '../fieldRenderers/constants'
import { useAuth } from '../../context/auth'

const DealDetailDock = (props) => {
  const {
    fetchOppDetail = false,
    opportunity,
  } = props

  const opportunityId = useMemo(() => {
    return opportunity?.id?.replace('opportunity+', '')
  }, [opportunity])

  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames } = useNearlineFeatures()

  const { hasFeatureSettings, isDealHealthEnabled, isSuccessPlansEnabled, isKaiaRecordingsEnabled } = useOutreachFeatures()

  const { encodeString } = useUserInput()

  const { actingTenantId } = useAuth()

  const fetchDealHealthData = useMemo(() => {
    return hasFeatureSettings && (isDealHealthEnabled || isSuccessPlansEnabled || isKaiaRecordingsEnabled)
  }, [hasFeatureSettings, isDealHealthEnabled, isKaiaRecordingsEnabled, isSuccessPlansEnabled])

  const { debug } = useDebug()

  const { routes } = useRoutes()

  const history = useHistory()

  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const { changeSince } = useDealChangeWindowFeature()

  const containerRef = useRef()
  const dispatch = useDispatch()
  const { notifyError } = useNotification()

  useEffect(() => {
    fetchOppDetail && dispatch(getOppDetailById(opportunity.id, debug, fetchDealHealthData, actingTenantId))
  }, [actingTenantId, debug, dispatch, fetchDealHealthData, fetchOppDetail, opportunity])

  const oppDetail = useSelector((state) => state.oppDetail)

  const { isReadOnly } = useIsReadOnly()

  const fieldsList = useMemo(() => {
    const { opportunity = { fieldsList: [] } } = objectDefinitions
    return opportunity.fieldsList
  }, [objectDefinitions])

  const getParams = useCallback((fieldName) => {
    const fieldDefinition = cloneDeep(getFieldDefinition(fieldsList, fieldName))
    const fieldReference = fieldDefinition?.referencesList?.[0]
    const lazyLoad = !!fieldReference
    return {
      fieldDefinition,
      field: (oppDetail.fields && oppDetail.fields[fieldName] && oppDetail.fields[fieldName][0]) || {},
      readonlyOverride: isReadOnly(oppDetail, fieldDefinition),
      fieldReference,
      lazyLoad
    }
  }, [fieldsList, isReadOnly, oppDetail])

  const name = useMemo(() => {
    const field = getFieldFromObject(oppDetail, 'name')
    if (field) {
      return field.value
    } else {
      return opportunity.fields.name[0].value
    }
  }, [oppDetail, opportunity])

  useEffect(() => {
    if (objectDefinitions.opportunity && oppDetail.fields) {
      forEach(objectDefinitions.opportunity.fieldsList, (fieldDefinition) => {
        const field = oppDetail.fields[fieldDefinition.key]
        if (!field && !['lastmodifieddate'].includes(fieldDefinition.key)) {
          debug && console.log(`Opportunity field data does not include key ${fieldDefinition.key}`, fieldDefinition)
        }
      })
    }
  }, [debug, objectDefinitions, oppDetail])

  const handleClose = useCallback(() => {
    dispatch(setDock({ dealDetail: { enabled: false } }))
  }, [dispatch])

  const [isUpdating, setIsUpdating] = useState(false)

  const onFieldChanged = useCallback((opportunity, field) => {
    debug && console.log('onFieldChanged', opportunity, field)

    const { name, value } = field

    const obj = {
      objectName: 'opportunity',
      item: {
        id: opportunity.id,
        lastModifiedDateString: opportunity.lastModifiedDateString,
        fieldsMap: [
          [
            name,
            {
              instancesList: [
                {
                  ...field,
                  value: encodeString(value)
                },
              ],
            },
          ],
        ],
      },
      changeSince,
    }
    setIsUpdating(true)
    dispatch(patchSearchItem(oppDetail, obj, notifyError, field.name === 'closeDate', debug, () => {
      setIsUpdating(false)
      window.analytics.track('dealDock.fieldChanged', { label: field.label })
    }))
  }, [debug, oppDetail, changeSince, dispatch, notifyError, encodeString])

  const onViewMoreClick = useCallback(() => {
    if (opportunityId) {
      window.analytics.track('dealDock.viewMoreClicked')
      history.push(routes.deal.replace(':opportunityId', opportunityId))
    }
  }, [history, opportunityId, routes.deal])

  useKeyPressEffect({
    targetKey: 'Escape',
    eventTarget: ['#searchBox'],
    onDown: handleClose
  }, [handleClose])

  useKeyPressEffect({
    targetKey: 'Enter',
    onDown: onViewMoreClick
  }, [onViewMoreClick])

  const signalsList = useSelector((state) => state.signalsByOpportunity)

  const signals = useMemo(() => {
    return uniqBy(signalsList, (s) => s.id)
  }, [signalsList])

  const signalCount = useMemo(() => {
    return signals.length
  }, [signals])

  const lastActivityDate = useMemo(() => {
    return getParams('lastactivitydate')
  }, [getParams])

  const dealSignals = useMemo(() => {
    if (!isAccessNearlineCheckReady) {
      return <></>
    } else if (canAccessNearlineFeature({ featureName: featureNames.deals })) {
      return (
        <>
          {opportunityId && (
            <CanvasProvider
              key={`CanvasProvider-${canvasKey.DEAL_SIGNALS}-${opportunityId}`}
              canvasKey={canvasKey.DEAL_SIGNALS}>
              <DealSignalsProvider
                opportunityId={opportunityId}>
                <DealSignalsHeader />
                <DealSignals
                  className="mb-1"
                  showHeader={false} />
              </DealSignalsProvider>
            </CanvasProvider>
          )}
        </>
      )
    } else {
      return (
        <>
          {signalCount > 0 && (
            <>
              <div className="mt-3 mb-1 flex items-center">
                <div
                  style={{ width: 28, height: 28 }}
                  className={classNames('inline-block text-center text-size-12px font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-color-5951FF',
                    { invisible: signalCount === 0 })}>
                  <div className="font-weight-700 mt-1" style={{ transform: 'translateY(-2px)' }}>{signalCount}</div>
                </div>
                <div style={{ transform: 'translateY(1px)' }} className="ml-2 text-color-151d49 text-size-18px font-weight-700">Signals</div>
              </div>
              <div className="border-t border-color-d6d9e6 my-2" />
            </>
          )}

          {opportunityId && (
            <DealInsights
              className="mb-1"
              showHeader={false}
              showHover={false}
              opportunityId={opportunityId} />
          )}
        </>
      )
    }
  }, [isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames.deals, opportunityId, signalCount])

  return (
    <div ref={containerRef} className="flex flex-col w-full h-full bg-color-ffffff">

      <div className="sticky pt-6 px-6">
        <div className="flex items-start justify-between">
          {/* <Avatar className="w-10 h-10 mr-2" imageUrl={companyImageUrl} text={name.charAt(0)} /> */}
          <div className="w-full flex items-start justify-between">
            <div className="flex items-start text-color-09242f text-size-20px font-bold leading-none">{name}</div>
            <div className="flex items-center justify-between">
              {/* <DealMenu className="mx-2" opportunity={opportunity} caretPosition="top-right" /> */}
              <button onClick={handleClose} className="focus:outline-none">
                <FontAwesomeIcon icon="times" className="text-color-151d49" style={{ width: 16, height: 16 }} />
              </button>
            </div>
          </div>
        </div>

        <div>
          <a
            onClick={onViewMoreClick}
            className="text-color-2e5bff text-size-14px font-weight-500 focus:outline-none cursor-pointer">
            View Deal
          </a>
          <span className="mx-2 text-size-14px text-color-91959f">|</span>
          <ViewDealInCrmProvider
            opportunityId={opportunity?.id?.replace('opportunity+', '')}
            analyticsTrackingArgs={['deal.viewInCrmClicked', { feature: 'detailDock' }]}>
            <ViewDealInCrm />
          </ViewDealInCrmProvider>
        </div>
      </div>

      {oppDetail.id
        && (
          <div className="flex flex-col px-6 py-2 overflow-auto">
            <div className="mb-1 text-color-151d49 text-size-18px font-weight-700">Summary</div>
            <div className="border-t border-color-d6d9e6 my-2" />

            <div className={classNames({ 'animate-pulse-fast pointer-events-none': isUpdating })}>
              <StatusFieldDetailItem
                debug={debug}
                opportunity={oppDetail}
                {...getParams(canopyFields._status.key)}
                className="flex flex-row flex-wrap my-1"
                labelClassName="mr-2" />

              <SingleSelectFieldDetailItem
                debug={debug}
                opportunity={oppDetail}
                {...getParams(canopyFields.ownerId.key.toLowerCase())}
                onFieldChanged={onFieldChanged}
                path="name"
                className="flex flex-row flex-wrap my-1"
                labelClassName="mr-2" />

              <DateFieldDetailItem
                debug={debug}
                opportunity={oppDetail}
                {...getParams(canonicalFieldKeys.closedate)}
                onFieldChanged={onFieldChanged}
                enableChangeHistory={true}
                className="flex flex-row flex-wrap my-1"
                labelClassName="mr-2" />

              <NumberFieldDetailItem
                debug={debug}
                opportunity={oppDetail}
                {...getParams(canonicalFieldKeys.amount)}
                onFieldChanged={onFieldChanged}
                enableChangeHistory={true}
                className="flex flex-row flex-wrap my-1"
                labelClassName="mr-2" />

              <SingleSelectFieldDetailItem
                debug={debug}
                opportunity={oppDetail}
                {...getParams(canonicalFieldKeys.forecastcategoryname)}
                onFieldChanged={onFieldChanged}
                enableChangeHistory={true}
                className="flex flex-row flex-wrap my-1"
                labelClassName="mr-2" />

              <StageNameFieldDetailItem
                debug={debug}
                opportunity={oppDetail}
                {...getParams(canonicalFieldKeys.stagename)}
                onFieldChanged={onFieldChanged}
                enableChangeHistory={true}
                className="flex flex-row flex-wrap my-1"
                labelClassName="mr-2" />
            </div>

            {hasFeatureSettings && isDealHealthEnabled && (
              <DealHealthDetailItem />
            )}
            {hasFeatureSettings && (isSuccessPlansEnabled || isKaiaRecordingsEnabled) && (
              <ActivityDetailItem
                debug={debug}
                opportunity={oppDetail}
                lastActivityDate={lastActivityDate}
                onFieldChanged={onFieldChanged} />
            )}

            {dealSignals}

          </div>
        )}

    </div>
  )
}

export default DealDetailDock
