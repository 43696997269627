import { has } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getFeatureSettingsKey } from '../actions/settingsService'
import { useLocationData } from '../components/location/hooks'
import { featureNames } from '../constants/featureNames'
import { permissionNames } from '../constants/permissionNames'
import { usePermissions } from '../context/permissions'
import { featureSettingSelector } from '../selectors'

export function useNearlineFeatures() {
  const { hash } = useLocationData()

  const { isFetching: isFetchingPermissions, checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin
    )
  }, [checkPermissions])

  const apiActivity = useSelector((state) => state.apiActivity)

  const isFetchingFeatureSettings = useMemo(() => {
    return !has(apiActivity, getFeatureSettingsKey) || apiActivity[getFeatureSettingsKey] === 'requested'
  }, [apiActivity])

  const nearlineFeatures = useSelector((state) => featureSettingSelector(state, { key: featureNames.nearlineFeatures }))

  const isAccessNearlineCheckReady = useMemo(() => {
    return !isFetchingPermissions && !isFetchingFeatureSettings
  }, [isFetchingPermissions, isFetchingFeatureSettings])

  const canAccessNearlineFeature = useCallback(({ featureName }) => {
    const accessFeature = nearlineFeatures?.payload?.[featureName]
    // a user can access the nearline feature if any of the conditions are met:
    // - the setting for the featureName is true and there isn't a forceLegacy param in the url hash
    // - the setting for the featureName is false and the user has the permission CanAccessInternalAdmin and a forceNearline param is in the url hash
    return ((accessFeature && !has(hash, 'forceLegacy')) || (!accessFeature && permissions.CanAccessInternalAdmin && has(hash, 'forceNearline')))
  }, [nearlineFeatures, permissions, hash])

  return {
    featureNames: {
      summary: 'summary',
      performance: 'performance',
      deals: 'deals'
    },
    isAccessNearlineCheckReady,
    canAccessNearlineFeature
  }
}
