import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import EventBus from '../eventBus'
import { eventTypes } from '../eventBus/eventTypes'
import get from 'lodash/get'

const Button = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    publish,
    clickData,
    children,
    onClick
  } = props

  PropTypes.checkPropTypes(Button.propTypes, props, 'prop', 'Button')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-button focus:outline-none cursor-pointer',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const handleClick = useCallback((e) => {
    e.preventDefault()

    if (onClick) {
      onClick()
    } else if (publish) {
      const names = get(publish, 'names', [])
      const eventName = get(publish, 'eventName')
      const eventArgs = {
        ...publish,
        names
      }
      // eslint-disable-next-line import/no-named-as-default-member
      eventName && EventBus.publish(eventName, eventArgs)
    } else {
      // eslint-disable-next-line import/no-named-as-default-member
      clickData && EventBus.publish(eventTypes.linkClicked, { name, clickData })
    }
  }, [onClick])

  return (
    <button {...attributes} onClick={handleClick}>{children}</button>
  )
}

Button.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  publish: PropTypes.object,
  clickData: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func
}

export default Button
