import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import Popover from '../popover'
import { usePopover } from '../../hooks/usePopover'
import { Visibility } from '@getoutreach/react-icons'
import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useDispatch } from 'react-redux'
import { setDock } from '../../actions'
import MoreVertical from '../icons/moreVertical'
import { useViewDealInCrm } from '../../context/viewDealInCrm'
import salesforceIcon from '../../assets/salesforce-icon-gray.png'

const DealMenu = (props) => {
  const { className, opportunity, placement = 'right' } = props

  const { crmName, onClick } = useViewDealInCrm()

  const dispatch = useDispatch()

  const history = useHistory()

  const { routes } = useRoutes()

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onMenuClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const onViewDealClick = useCallback(() => {
    if (opportunity.id) {
      dispatch(setDock({ dealDetail: { enabled: false } }))

      history.push(routes.deal.replace(':opportunityId', opportunity.id))

      onClose()
    }
  }, [onClose, opportunity])

  const onViewInCrmClick = useCallback(() => {
    onClick()
    onClose()
  }, [onClick, onClose])

  return (
    <>
      <button onClick={onMenuClick} className={classNames('flex items-center focus:outline-none', className)}>
        <MoreVertical fill="#51636a" fontSize="small" />
      </button>
      <Popover
        arrow
        style={{ zIndex: 999999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement={placement}
        timeout={0}>
        <div className="py-2" style={{ width: 300 }}>
          <button onClick={onViewDealClick} className="w-full py-2 text-left focus:outline-none hover:bg-color-edeeee">
            <div className="flex items-start px-6">
              <div className="flex justify-center items-center" style={{ width: 21, height: 21 }}>
                <Visibility style={{ width: 21, height: 21, color: '#757575' }} />
              </div>
              <div className="ml-2">
                <div className="text-color-151d49 text-size-16px font-weight-500">View Deal</div>
                <div className="text-color-4e5d7e text-size-13px font-weight-500">View more details of this deal</div>
              </div>
            </div>
          </button>
          <button onClick={onViewInCrmClick} className="w-full py-2 text-left focus:outline-none hover:bg-color-edeeee">
            <div className="flex items-start px-6">
              <div className="flex justify-center items-center" style={{ width: 21, height: 14, transform: 'translateY(3px)' }}>
                <img src={salesforceIcon} />
              </div>
              <div className="ml-2">
                <div className="text-color-151d49 text-size-16px font-weight-500">
                  View in
                  {' '}
                  {crmName}
                </div>
                <div className="text-color-4e5d7e text-size-13px font-weight-500">
                  View this deal in
                  {' '}
                  {crmName}
                </div>
              </div>
            </div>
          </button>
        </div>
      </Popover>
    </>
  )
}

export default DealMenu
