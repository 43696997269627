import React from 'react'
import get from 'lodash/get'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      info: undefined
    }
  }

  componentDidCatch(error, info) {
    this.setState({ error, info })
  }

  render() {
    if (this.state.error) {
      const errorMessage = get(this.state, 'error.message')
      const errorStack = get(this.state, 'error.stack')
      const componentStack = get(this.state, 'info.componentStack')
      const wrappedErrorMessage = get(this.state, 'error.err.message')
      const wrappedErrorStack = get(this.state, 'error.err.stack')
      return (
        <div className="p-4 text-size-13px">
          {errorMessage && <div className="font-bold">{errorMessage}</div>}
          {errorStack && <div className="ml-4 font-normal">{errorStack}</div>}
          {componentStack && <div className="font-normal">{componentStack}</div>}
          {(wrappedErrorMessage || wrappedErrorStack) && (
            <div className="mt-2 pt-2 border-t border-color-eaeaea">
              {wrappedErrorMessage && (
                <div className="font-bold">
                  WrappedError:
                  {wrappedErrorMessage}
                </div>
              )}
              {wrappedErrorStack && <div className="ml-4 font-normal">{wrappedErrorStack}</div>}
            </div>
          )}
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
