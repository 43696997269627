import React, { useMemo } from 'react'
import Header from '../header/header'
import ForecastingHistoryTable from './forecastingHistoryTable'
import { useParams } from 'react-router-dom'
import { find } from 'lodash'
import { useGroups } from '../../context/groups'

const ForecastingHistory = (props) => {
  const params = useParams()

  const { flattened: teamFlattened } = useGroups()

  const user = useMemo(() => {
    if (params.userId) {
      return find(teamFlattened, (u) => u.id === params.userId)
    }
  }, [params, teamFlattened])

  const header = useMemo(() => {
    if (params.userId) {
      return user ? `${user.firstName || user.name}'s Forecasting History` : ' '
    } else {
      return 'Your Forecasting History'
    }
  }, [params, user])

  return (
    <>
      <Header
        text={header}
        showBackButton={true}
        showSearch={false} />

      <div className="py-8 px-10">
        <ForecastingHistoryTable
          user={user} />
      </div>
    </>
  )
}

export default ForecastingHistory
