import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import TeamMember from './teamMember'
import { peopleContextOrderedByRankSelector } from '../../selectors'
import { filter } from 'lodash'

const MyTeamLegacy = (props) => {
  const {
    children = null,
    header,
    showTopTen = false,
    toggleHideTeam,
    userId,
  } = props

  const teamOrdered = useSelector(peopleContextOrderedByRankSelector)

  const team = useMemo(() => {
    const filtered = filter(teamOrdered, (u) => u.username).filter(({ isActive }) => isActive)
    if (showTopTen) {
      return filtered.slice(0, 10)
    }
    return filtered
  }, [teamOrdered, showTopTen])

  return (
    <div className="w-full">
      {header && (
        <>
          <div className="flex justify-between">
            <div>
              <h1 className="leading-tight pb-4">{header}</h1>
            </div>
            <a onClick={toggleHideTeam} className="text-color-4e5d7e text-size-12px font-weight-500 cursor-pointer my-2">Hide Team</a>
          </div>
          {children || (
            team.map((user, index) => (
              <TeamMember
                key={`teamMember-${userId}-${user.id}`}
                rank={index + 1}
                user={user} />
            ))
          )}
        </>
      )}
    </div>
  )
}

export default MyTeamLegacy
