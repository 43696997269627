import { isString } from 'lodash'

export const copyToClipboard = (content, log = false) => {
  log && console.log(content)
  if (isString(content)) {
    navigator.clipboard.writeText(content)
  } else {
    navigator.clipboard.writeText(JSON.stringify(content, null, 2))
  }
}
