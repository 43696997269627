import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { setViewInitialized } from '../../actions'
import { useDispatch } from 'react-redux'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const ForecastingTabs = (props) => {
  const {
    tabIndex = 0,
    user,
    userIdsParam,
    forecastConfigId,
    isLoggedInUsersTeam,
  } = props

  const { routes, isPreview } = useRoutes()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessDeal
    )
  }, [checkPermissions])

  const dispatch = useDispatch()

  const history = useHistory()

  const myDealsText = useMemo(() => {
    if (user.isLoggedInUser) {
      return 'Your Deals'
    } else {
      return user.info && user.info.firstName ? `${user.info.firstName}'s Deals` : ''
    }
  }, [user])

  const isManager = useMemo(() => {
    return user.isLoggedInUser || (user.info && user.info.hasChildren)
  }, [user])

  const onTabIndexChange = useCallback((tabIndex) => {
    window.analytics.track('forecasting.v2.forecastingTabs.tabChanged', { tabIndex })

    dispatch(setViewInitialized(false))

    if (isPreview) {
      return
    }

    switch (tabIndex) {
      case 0:
        history.push(!isLoggedInUsersTeam
          ? routes.forecastingByTeam
            .replace(':userIds', userIdsParam)
            .replace(':forecastConfigId', forecastConfigId)
          : routes.forecastingByConfig
            .replace(':forecastConfigId', forecastConfigId))
        break
      case 1:
        history.push(routes.forecastingByTeam_TeamDeals
          .replace(':userIds', userIdsParam)
          .replace(':forecastConfigId', forecastConfigId))
        break
      case 2:
        history.push(routes.forecastingByTeam_Deals
          .replace(':userIds', userIdsParam)
          .replace(':forecastConfigId', forecastConfigId))
        break
      default:
    }
  }, [
    dispatch,
    forecastConfigId,
    history,
    isLoggedInUsersTeam,
    isPreview,
    routes.forecasting,
    routes.forecastingByTeam,
    routes.forecastingByTeam_Deals,
    routes.forecastingByTeam_TeamDeals,
    userIdsParam
  ])

  return (
    <div className="flex flex-col my-4">
      <div className="flex flex-wrap items-center px-10">

        {isManager
          && (
            <>
              <div
                onClick={() => onTabIndexChange(0)}
                className={
                  classNames('flex flex-col text-size-20px text-color-151d49 font-bold py-1 mt-2 ml-8 first:ml-0',
                    'whitespace-nowrap leading-tight focus:outline-none cursor-pointer',
                    { 'text-color-8097b1': tabIndex === 0 })
                }>
                <div className={classNames('text-size-20px font-bold', { 'text-color-151d49': tabIndex === 0 }, { 'text-color-818e93': tabIndex !== 0 })}>Team</div>
                <div className={classNames('w-full rounded-full bg-color-5951FF', { invisible: tabIndex !== 0 })} style={{ height: 4 }} />
              </div>

              {permissions.CanAccessDeal && !isPreview && (
                <div
                  onClick={() => onTabIndexChange(1)}
                  className={
                    classNames('flex flex-col text-size-20px text-color-151d49 font-bold py-1 mt-2 ml-8 first:ml-0',
                      'whitespace-nowrap leading-tight focus:outline-none cursor-pointer',
                      { 'text-color-8097b1': tabIndex === 1 })
                  }>
                  <div className={classNames('text-size-20px font-bold', { 'text-color-151d49': tabIndex === 1 }, { 'text-color-818e93': tabIndex !== 1 })}>Deals</div>
                  <div className={classNames('w-full rounded-full bg-color-5951FF', { invisible: tabIndex !== 1 })} style={{ height: 4 }} />
                </div>
              )}
            </>
          )}

        {permissions.CanAccessDeal && (
          <div
            onClick={() => onTabIndexChange(2)}
            className={
              classNames('flex flex-col text-size-20px text-color-151d49 font-bold py-1 mt-2 ml-8 first:ml-0',
                'whitespace-nowrap leading-tight focus:outline-none cursor-pointer',
                { 'text-color-8097b1': tabIndex === 2 })
            }>
            <div className={classNames('text-size-20px font-bold', { 'text-color-151d49': tabIndex === 2 }, { 'text-color-818e93': tabIndex !== 2 })}>{myDealsText}</div>
            <div className={classNames('w-full rounded-full bg-color-5951FF', { invisible: tabIndex !== 2 })} style={{ height: 4 }} />
          </div>
        )}

      </div>

    </div>
  )
}

export default ForecastingTabs
