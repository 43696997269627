import React from 'react'
import Icon, { iconType } from '../icon'
import imageSrc from '../../assets/analytics-icon.png'
import classNames from 'classnames'

const ResultPlaceholder = (props) => {
  const { className, title, subTitle, maxWidth } = props

  return (
    <div className={classNames('flex items-center h-full', className)}>
      <div className="mx-auto text-center" style={{ maxWidth: maxWidth || 300 }}>
        <div className="flex justify-center">
          <Icon type={iconType.IMAGE} src={imageSrc} />
        </div>
        <div className="text-size-30px text-color-91959f font-bold mt-4 mb-1">{title}</div>
        <div className="text-size-14px text-color-91959f font-weight-500">{subTitle}</div>
      </div>
    </div>
  )
}

export default ResultPlaceholder
