import { useCallback, useEffect, useRef, useState } from 'react'
import { moveCursorToEnd } from '../lib/input'

export function useEditableLabel({ defaultValue, onChangeCommitted }) {
  const inputRef = useRef()
  const [isEditMode, setIsEditMode] = useState(false)
  const [previousValue, setPreviousValue] = useState(defaultValue)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setPreviousValue(value)
    setValue(value)
  }, [value])

  useEffect(() => {
    if (isEditMode) {
      const inputEl = inputRef.current
      inputEl.focus()
      moveCursorToEnd(inputEl)
    }
  }, [isEditMode, inputRef])

  const handleChange = useCallback((e) => {
    setValue(e.target.value)
  }, [])

  const handleBlur = useCallback((e) => {
    setIsEditMode(false)
    if (e.cancelled) {
      return
    }
    onChangeCommitted && onChangeCommitted(value)
  }, [value])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setValue(previousValue)
      handleBlur({ cancelled: true })
    } else if (e.key === 'Enter') {
      handleBlur({ cancelled: false })
    }
  }, [value])

  return {
    inputRef,
    isEditMode,
    setIsEditMode,
    value,
    setValue,
    handleChange,
    handleBlur,
    handleKeyDown,
  }
}
