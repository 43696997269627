import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DealDetail from '../deals/dealDetail'
import useDisableWheel from '../../hooks/useDisableWheel'

const DealDetailModal = (props) => {
  const { id, closeModal } = props

  const containerRef = useRef(null)
  const headerRef = useRef(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [windowSize, setWindowSize] = useState({ width: window.innerHeight, height: window.innerHeight })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerHeight, height: window.innerHeight })
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current)
        setContainerSize({
          width: computedStyle.width.replace('px', '') * 1,
          height: computedStyle.height.replace('px', '') * 1
        })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const buttonRef = useRef()
  useDisableWheel(buttonRef)

  return (
    <div ref={containerRef} className="static mx-auto bg-color-ffffff rounded overflow-hidden" style={{ maxWidth: '1200px', maxHeight: windowSize.height - 80, marginTop: 40 }}>
      <div className="overflow-x-hidden overflow-y-auto" style={{ maxWidth: '1200px', maxHeight: windowSize.height - 80 }}>
        <div ref={headerRef} className="fixed rounded pt-9 pb-6 pointer-events-none" style={{ width: containerSize.width - 20 }}>
          <div className="w-full flex justify-end">
            <button ref={buttonRef} onClick={closeModal} className="focus:outline-none pointer-events-auto">
              <FontAwesomeIcon icon="times" size="lg" className="mr-6 text-color-151d49" />
            </button>
          </div>
        </div>
        <div className="pointer-events-auto">
          <DealDetail opportunityId={id} isModal={true} />
        </div>
      </div>
    </div>
  )
}

export default DealDetailModal
