import React, { useMemo, useContext, useCallback, useEffect, useState } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import FilterExpander from './filterExpander'
import { useVirtualizedList } from '../virtualized/hooks'
import VirtualizedList from '../virtualized/virtualizedList'
import { filter, orderBy, some } from 'lodash'
import { useFieldDefinitionOptions } from './hooks'
import { useSelector } from 'react-redux'
import { OpenCategories } from '../pipeline/dealFilters'
import MultiSelectFilterItem from './multiSelectFilterItem'
import { Waypoint } from 'react-waypoint'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MultiSelectFilterVirtualized = (props) => {
  const {
    fieldDefinition,
    options,
    open = false,
    onChange,
    maxHeight,
    minHeight = 42,
    onToggle,
    lazyLoad = false,
    isFetching = false,
    hasMore = false,
    nextPage
  } = props

  const { label } = fieldDefinition

  const { activeOnly } = useContext(OpenCategories)

  const fieldDefOptions = useFieldDefinitionOptions(fieldDefinition)
  const opts = options || fieldDefOptions

  const [selected, setSelected] = useState([])
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const filteredOpts = useMemo(() => {
    if (lazyLoad) {
      return opts
    } else if (activeOnly) {
      return selected
    } else {
      return opts
    }
  }, [opts, activeOnly, selected, lazyLoad])

  const searchFilters = useSelector((state) => state.searchFilters)
  const { pendingFilters } = searchFilters

  useEffect(() => {
    const currentFilter = fieldDefinition.key && pendingFilters[fieldDefinition.key]
    if (currentFilter) {
      const { data = {} } = currentFilter
      const { selected = [] } = data
      setSelected(selected)
    } else {
      setSelected([])
    }
  }, [fieldDefinition, pendingFilters])

  const onChangeInternal = useCallback((option) => {
    const items = [
      ...filter(selected, (o) => o.value !== option.value),
      option,
    ]
    const s = filter(items, (i) => i.checked)
    setSelected(s)

    onChange && onChange({
      fieldDefinition,
      type: 'MultiSelectFilter',
      data: {
        selected: orderBy(s, (o) => o.label),
      },
    })
  }, [fieldDefinition, selected, onChange])

  const handleWaypointEntered = useCallback(() => {
    nextPage && nextPage()
  }, [nextPage])

  const renderFilter = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { registerChild, measure } = cellMeasurerArgs
    const option = filteredOpts[index]
    const showWaypoint = hasMore && !isFetching && option.autoLoad

    return (
      <div className="block" ref={registerChild} style={style}>
        {showWaypoint ? (
          <Waypoint onEnter={handleWaypointEntered}>
            <div className="flex justify-center py-2">
              <FontAwesomeIcon icon="spinner" size="sm" className="spinner mx-2" />
            </div>
          </Waypoint>
        ) : (
          <MultiSelectFilterItem
            measure={measure}
            index={index}
            option={option}
            onChange={onChangeInternal}
            selected={selected} />
        )}
      </div>
    )
  }, [filteredOpts, selected, onChangeInternal, isFetching, hasMore, handleWaypointEntered])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: 42,
    rowRenderer: renderFilter,
  })

  return (
    <FilterExpander
      showCount={true}
      count={selected.length}
      onToggle={onToggle}
      open={isOpen}
      name={label}>
      <div className="w-full flex-grow" style={{ height: maxHeight - 60, maxHeight: filteredOpts.length * 42, minHeight }}>
        <VirtualizedList
          className="focus:outline-none"
          deferredMeasurementCache={cellMeasurerCache}
          rowHeight={cellMeasurerCache.rowHeight}
          rowCount={filteredOpts.length}
          rowRenderer={rowRenderer} />
      </div>
    </FilterExpander>
  )
}

export default MultiSelectFilterVirtualized
