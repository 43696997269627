import { createAction } from '@reduxjs/toolkit'

export const saveNoteKey = 'api/note/saveNote'
export const saveNoteRequested = createAction(`${saveNoteKey}/requested`)
export const saveNoteSuccess = createAction(`${saveNoteKey}/success`)
export const saveNoteFailed = createAction(`${saveNoteKey}/failed`)

export const getNotesByAttachmentKey = 'api/note/getNotesByAttachment'
export const getNotesByAttachmentRequested = createAction(`${getNotesByAttachmentKey}/requested`)
export const getNotesByAttachmentSuccess = createAction(`${getNotesByAttachmentKey}/success`)
export const getNotesByAttachmentFailed = createAction(`${getNotesByAttachmentKey}/failed`)

export const deleteNoteKey = 'api/note/deleteNote'
export const deleteNoteRequested = createAction(`${deleteNoteKey}/requested`)
export const deleteNoteSuccess = createAction(`${deleteNoteKey}/success`)
export const deleteNoteFailed = createAction(`${deleteNoteKey}/failed`)

export const deliverNoteKey = 'api/note/deliverNote'
export const deliverNoteRequested = createAction(`${deliverNoteKey}/requested`)
export const deliverNoteSuccess = createAction(`${deliverNoteKey}/success`)
export const deliverNoteFailed = createAction(`${deliverNoteKey}/failed`)
