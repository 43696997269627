import { featureNames } from '../../constants/featureNames'
import { featureSettingSelector } from '../../selectors'
import { getForecastSubmittedAt } from './helpers'
import { permissionNames } from '../../constants/permissionNames'
import { useForecasting } from '../../context/forecasting'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useHistory } from 'react-router-dom'
import { useModal } from '../../hooks/useModal'
import { usePermissions } from '../../context/permissions'
import { useRoutes } from '../../context/routes'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import ForecastingHistoryModal from './forecastingHistoryModal'
import React, { useMemo, useCallback } from 'react'
import SearchMenu from '../search/searchMenu'
import SearchMenuItem from '../search/searchMenuItem'
import SubmitForecastModal from './submitForecastModal'
import ForecastPeriodSelector from './forecastPeriodSelector'

const ForecastSelector = (props) => {
  const { group } = props
  const { checkPermissions } = usePermissions()
  const { configsList, selectedConfig } = useForecastConfigs()
  const { routes, isPreview } = useRoutes()
  const history = useHistory()
  const submitModal = useModal()
  const historyModal = useModal()
  const { isPastForecastPeriod } = useForecasting()

  const forecastingPeriodFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.forecastingPeriod }))

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadForecast,
      permissionNames.CanCreateForecast
    )
  }, [checkPermissions])

  const forecastPeriodEnabled = useMemo(() => {
    return forecastingPeriodFeature?.payload?.enabled ?? false
  }, [forecastingPeriodFeature])

  const canSubmitForecast = useMemo(() => {
    return permissions.CanCreateForecast && !isPreview
  }, [permissions, isPreview])

  const onSubmit = useCallback(() => {
    submitModal.setOpen(true)
  }, [submitModal])

  const onViewHistoryClick = useCallback(() => {
    window.analytics.track('forecasting.v3.viewHistoryClicked')
    historyModal.setOpen(true)
  }, [historyModal])

  const setSelectedForecast = useCallback((config) => {
    return () => {
      history.replace(routes.forecastingByConfig.replace(':forecastConfigId', config?.id))
    }
  }, [history, routes])

  const forecastTitles = useMemo(() => {
    if (!selectedConfig) {
      return ''
    }

    if (configsList.length === 1) {
      return (
        <div className="text-size-22px text-color-09242f font-bold">Current Forecast</div>
      )
    }

    if (configsList.length > 5) {
      return (
        <SearchMenu noOptionsText="No forecasts found" PopoverProps={{ style: { zIndex: 999999999 } }} value={selectedConfig?.name}>
          {configsList.map((config) => (
            <SearchMenuItem
              key={`configListItem-${config.id}`}
              label={config.name}
              onClick={setSelectedForecast(config)}
              selected={selectedConfig?.id === config.id}>
              {config.name}
            </SearchMenuItem>
          ))}
        </SearchMenu>
      )
    }

    return configsList.map((config) => {
      const { id, name } = config
      const selected = selectedConfig?.id === id

      return (
        <button
          key={`ConfigsListItem-${id}`}
          onClick={setSelectedForecast(config)}
          title={name}
          className={
            classNames(
              'text-size-24px mr-6',
              { 'text-color-09242F font-bold': selected },
              { 'text-color-818e93 min-w-16 w-auto truncate': !selected }
            )
          }>
          {name}
          {selected && (
            <div className="w-full rounded-full bg-color-5951FF" style={{ height: '4px' }} />
          )}
        </button>
      )
    })
  }, [configsList, selectedConfig, setSelectedForecast])

  return (
    <>
      {!isPreview && (
        <div className="flex leading-tight mr-auto mx-10 truncate">
          {forecastTitles}
        </div>
      )}
      {permissions.CanReadForecast && !isPreview && (
        <>
          <div className="flex-shrink-0 ml-10 mx-4 text-right">
            <button
              className="text-color-818e93 text-size-14px font-weight-500 tracking-wider cursor-pointer"
              onClick={onViewHistoryClick}>
              View Submission History
            </button>
          </div>
          <ForecastingHistoryModal group={group} modal={historyModal} />
        </>
      )}
      {forecastPeriodEnabled && !isPreview && (
        <div className="mr-2">
          <ForecastPeriodSelector fieldset={true} />
        </div>
      )}
      {canSubmitForecast
        && (
          <div className="flex-shrink-0 mr-10">
            <button
              disabled={isPastForecastPeriod}
              onClick={onSubmit}
              className={
                classNames(
                  'px-8 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none',
                  { 'opacity-25 cursor-default': isPastForecastPeriod }
                )
              }>
              Submit
            </button>
            <SubmitForecastModal modal={submitModal} isManager={true} />
          </div>
        )}
    </>
  )
}

export default ForecastSelector
