import React from 'react'
import classNames from 'classnames'

const ToggleMenuItem = (props) => {
  const { index, text, onClick, itemWidthClass } = props

  return (
    <div onClick={() => onClick(index)}
      className={classNames(itemWidthClass, 'px-1 py-2 truncate text-size-10px text-center bg-color-transparent select-none cursor-pointer')}>
      {text}
    </div>
  )
}

export default ToggleMenuItem
