import React, { useMemo } from 'react'
import PipelineGroupResultItem from './pipelineGroupResultItem'
import find from 'lodash/find'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'

const PipelineGroupResults = (props) => {
  const { selectedGroup, prevSelectedGroup, containerWidth, inputParams } = props
  const { endGroupsList = [] } = selectedGroup
  const prevEndGroupsList = prevSelectedGroup ? prevSelectedGroup.endGroupsList : []

  const amountSum = useMemo(() => {
    if (selectedGroup.pipelineGroupsList) {
      return sumBy(selectedGroup.pipelineGroupsList, (x) => get(x, 'totalAmount', 0))
    } else {
      return get(selectedGroup, 'totalAmount', 0)
    }
  }, [selectedGroup])

  const prevAmountSum = useMemo(() => {
    if (prevSelectedGroup.pipelineGroupsList) {
      return sumBy(prevSelectedGroup.pipelineGroupsList, (x) => get(x, 'totalAmount', 0))
    } else {
      return get(prevSelectedGroup, 'totalAmount', 0)
    }
  }, [prevSelectedGroup])

  const style = {
    width: (containerWidth / endGroupsList.length)
  }

  return (
    <div className="flex w-full">
      {endGroupsList.map((d, i) => (
        <PipelineGroupResultItem
          key={`EndDateBucket-${d.id}`}
          data={d}
          prevData={find(prevEndGroupsList, (x) => x.id === d.id)}
          amountSum={amountSum}
          prevAmountSum={prevAmountSum}
          style={style}
          selectedGroup={selectedGroup}
          inputParams={inputParams} />
      ))}
    </div>
  )
}

export default PipelineGroupResults
