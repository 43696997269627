import React, { useCallback, useMemo } from 'react'
import PipelineGrid from '../pipeline/pipelineGrid'
import { useDebug } from '../../context/debug'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'
import { useForecasting } from '../../context/forecasting'
import { has } from 'lodash'

const Deals = (props) => {
  const {
    name,
    changeSince,
    fetchForecast
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadDeal
    )
  }, [checkPermissions])

  const { debug } = useDebug()
  const { forecast } = useForecasting()

  const onWriteBackSuccess = useCallback(() => {
    fetchForecast && fetchForecast()
  }, [fetchForecast])

  const { hasFeatureSettings, isDealHealthEnabled } = useOutreachFeatures()

  const showPipeline = useMemo(() => {
    return permissions.CanReadDeal && hasFeatureSettings && has(forecast, 'forecastConfigId')
  }, [permissions.CanReadDeal, hasFeatureSettings, forecast])

  return (
    <div className="w-full h-full flex-grow pt-6" style={{ marginLeft: 40 }}>
      {showPipeline && (
        <PipelineGrid
          key={`PipelineGrid-${name}`}
          style={{ marginLeft: 40 }}
          changeSince={changeSince}
          useTotalRowsHeight={true}
          onWriteBackSuccess={onWriteBackSuccess}
          isDealHealthEnabled={isDealHealthEnabled}
          debug={debug} />
      )}
    </div>
  )
}

export default Deals
