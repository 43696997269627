import { QueryDateRangeType } from '../../grpc/enums'
import {
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns'

export function now(fiscalYearStartMonth = 1) {
  return subMonths(new Date(), fiscalYearStartMonth - 1)
}

export function adjustRangeForFiscalYearStartMonth(range, fiscalYearStartMonth) {
  const { startDate, endDate } = range
  return {
    startDate: addMonths(startDate, fiscalYearStartMonth - 1),
    endDate: endOfMonth(addMonths(endDate, fiscalYearStartMonth - 1))
  }
}

export function getDateRangeByQueryDateRangeType({ queryDateRangeType, fiscalYearStartMonth, defaultRange }) {
  let startDate = defaultRange?.startDate ?? new Date()
  let endDate = defaultRange?.endDate ?? new Date()
  if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_WEEK) {
    startDate = startOfWeek(now())
    endDate = endOfWeek(now())
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_WEEK) {
    startDate = addWeeks(startOfWeek(now()), 1)
    endDate = addWeeks(endOfWeek(now()), 1)
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_WEEK) {
    startDate = subWeeks(startOfWeek(now()), 1)
    endDate = subWeeks(endOfWeek(now()), 1)
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_MONTH) {
    startDate = startOfMonth(now())
    endDate = endOfMonth(now())
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_MONTH) {
    startDate = startOfMonth(addMonths(now(), 1))
    endDate = endOfMonth(addMonths(now(), 1))
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_MONTH) {
    startDate = startOfMonth(subMonths(now(), 1))
    endDate = endOfMonth(subMonths(now(), 1))
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_QUARTER) {
    const range = adjustRangeForFiscalYearStartMonth({
      startDate: startOfQuarter(now(fiscalYearStartMonth)),
      endDate: endOfQuarter(now(fiscalYearStartMonth)),
    }, fiscalYearStartMonth)
    startDate = range.startDate
    endDate = range.endDate
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_QUARTER) {
    const range = adjustRangeForFiscalYearStartMonth({
      startDate: startOfQuarter(addQuarters(now(fiscalYearStartMonth), 1)),
      endDate: endOfQuarter(addQuarters(now(fiscalYearStartMonth), 1)),
    }, fiscalYearStartMonth)
    startDate = range.startDate
    endDate = range.endDate
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_QUARTER) {
    const range = adjustRangeForFiscalYearStartMonth({
      startDate: startOfQuarter(subQuarters(now(fiscalYearStartMonth), 1)),
      endDate: endOfQuarter(subQuarters(now(fiscalYearStartMonth), 1)),
    }, fiscalYearStartMonth)
    startDate = range.startDate
    endDate = range.endDate
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_FISCAL_YEAR) {
    const range = adjustRangeForFiscalYearStartMonth({
      startDate: startOfYear(now(fiscalYearStartMonth)),
      endDate: endOfYear(now(fiscalYearStartMonth)),
    }, fiscalYearStartMonth)
    startDate = range.startDate
    endDate = range.endDate
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_FISCAL_YEAR) {
    const range = adjustRangeForFiscalYearStartMonth({
      startDate: startOfYear(addYears(now(fiscalYearStartMonth), 1)),
      endDate: endOfYear(addYears(now(fiscalYearStartMonth), 1)),
    }, fiscalYearStartMonth)
    startDate = range.startDate
    endDate = range.endDate
  } else if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_FISCAL_YEAR) {
    const range = adjustRangeForFiscalYearStartMonth({
      startDate: startOfYear(subYears(now(fiscalYearStartMonth), 1)),
      endDate: endOfYear(subYears(now(fiscalYearStartMonth), 1)),
    }, fiscalYearStartMonth)
    startDate = range.startDate
    endDate = range.endDate
  }
  return {
    startDate,
    endDate
  }
}
