import React, { useCallback, useMemo, useRef } from 'react'
import useRefSize from '../../hooks/useRefSize'
import { filter, findIndex, flatten, get, has, max, min } from 'lodash'
import simplify from 'simplify-js'
import fitCurve from 'fit-curve'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTextWidth } from '../../gml/lib/text'
import { useDispatch, useSelector } from 'react-redux'
import { calloutNames } from './constants'
import { activeScenarioSelector } from '../../selectors'
import { setActiveScenarioKey } from '../../actions'
import classNames from 'classnames'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const LineChart = (props) => {
  const dispatch = useDispatch()

  const { formatCurrency } = useFormatCurrency({ shortCurrency: true })

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)

  const hiddenScenarios = useSelector((state) => state.hiddenScenarios)

  const activeScenario = useSelector(activeScenarioSelector)

  const isActiveHidden = useMemo(() => {
    const { key } = activeScenario
    return hiddenScenarios[key] === true
  }, [activeScenario, hiddenScenarios])

  const otherScenarios = useMemo(() => {
    return filter(forecastSimulationWorkspace.scenariosList, (s) => s.key !== activeScenario.key && has(s, 'output'))
  }, [forecastSimulationWorkspace, activeScenario])

  const svgContainerRef = useRef(null)
  const size = useRefSize(svgContainerRef)

  const chart = useMemo(() => {
    const padding = {
      top: 60,
      bottom: 0,
    }
    return {
      padding,
      width: size.width,
      height: size.height - (padding.top + padding.bottom),
    }
  }, [size])

  const dataRange = useMemo(() => {
    const visibleScenarios = filter(forecastSimulationWorkspace.scenariosList, (s) => !hiddenScenarios[s.key])
    const allNumericValues = flatten(visibleScenarios.map((scenario) => get(scenario.output, 'numericValuesList', [])))
    const data = allNumericValues.map((item) => item.count)
    return {
      min: min(data),
      max: max(data),
    }
  }, [forecastSimulationWorkspace, hiddenScenarios])

  const xRange = useMemo(() => {
    const visibleScenarios = filter(forecastSimulationWorkspace.scenariosList, (s) => !hiddenScenarios[s.key])
    const allNumericValues = flatten(visibleScenarios.map((scenario) => get(scenario.output, 'numericValuesList', [])))
    const data = allNumericValues.map((item) => item.value)
    return {
      min: min(data),
      max: max(data),
    }
  }, [forecastSimulationWorkspace, hiddenScenarios])

  const mapDataToPoints = useCallback((numericValuesList) => {
    return numericValuesList.map((item, index) => {
      let x = chart.width * ((item.value - xRange.min) / (xRange.max - xRange.min))
      if (isNaN(x)) x = 0
      let y = chart.padding.top + chart.height - (chart.height * (item.count / dataRange.max))
      if (isNaN(y)) y = 0
      return { x, y }
    })
  }, [size, dataRange, xRange])

  const curvesToSvgPath = useCallback((bezierCurves) => {
    let path = ''
    bezierCurves.map((bezier, i) => {
      if (i === 0) {
        path += `M ${bezier[0][0]} ${bezier[0][1]}`
      }
      path += `C ${bezier[1][0]} ${bezier[1][1]}, ${bezier[2][0]} ${bezier[2][1]}, ${bezier[3][0]} ${bezier[3][1]} `
    })
    return path
  }, [])

  const onMarkerClicked = useCallback((key) => {
    dispatch(setActiveScenarioKey(key))
    window.analytics.track('scenarioPlanner.lineChart.scenarioClicked')
  }, [])

  const renderLine = useCallback((scenario, isActive) => {
    const { key, color, isBaseline, output = {} } = scenario
    const { numericValuesList = [], calloutValues = {} } = output
    const isHidden = hiddenScenarios[key] === true

    if (isHidden || numericValuesList.length < 2) {
      return
    }

    const points = mapDataToPoints(numericValuesList)

    const pixelTolerance = 5
    const simplifiedPoints = simplify(points, pixelTolerance, true)

    const fitCurveError = 1
    const bezierCurves = fitCurve(simplifiedPoints.map((p) => [p.x, p.y]), fitCurveError)
    const svgPath = curvesToSvgPath(bezierCurves)

    // console.log('points', points.length)
    // console.log('simplifiedPoints', simplifiedPoints.length)

    const pathAttributes = {
      d: svgPath,
      strokeLinecap: 'round',
      strokeWidth: isActive ? 4 : 3,
      stroke: isActive ? '#2e5bff' : color,
      fill: isActive ? 'url(#activeFill)' : 'none',
      ...isBaseline && { strokeDasharray: '5 10' },
    }

    return (
      <g key={`line-${key}`} className={classNames({ 'cursor-pointer': !isActive })} onClick={() => onMarkerClicked(key)}>
        <path {...pathAttributes} />
        {isActive && <path {...{ ...pathAttributes, fill: 'none' }} style={{ filter: 'url(#dropShadow)' }} />}
        {/* {data.map((d, index) =>
          <ellipse key={`ellipse-${id}-${index}`} cx={chart.width * (index / data.length)}
            cy={chart.padding.top + chart.height - (chart.height * (d / dataRange.max))}
            rx={7}
            ry={7} />)} */}
      </g>
    )
  }, [size, mapDataToPoints, hiddenScenarios])

  const renderLines = useCallback(() => {
    const { width, height } = size
    if (width > 0 && height > 0) {
      return (
        <>
          {otherScenarios.map((scenario) => renderLine(scenario, false))}
          {renderLine(activeScenario, true)}
        </>
      )
    }
  }, [size, activeScenario, otherScenarios, renderLine])

  const renderLegendItem = useCallback((scenario, isActive) => {
    const { key, label, color, isBaseline } = scenario

    const isHidden = hiddenScenarios[key] === true
    if (isHidden) {
      return
    }

    return (
      <div key={`legend-${key}`} className="flex flex-row items-center mx-4">
        <div className="flex justify-center items-center" style={{ width: 21 }}>
          {isBaseline
            ? (
              <>
                <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
                <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
                <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
              </>
            )
            : <FontAwesomeIcon icon="circle" color={isActive ? '#2e5bff' : color} style={{ width: 8, height: 8, transform: 'translate(1px, -1px)' }} />}
        </div>
        <div className="pl-1 text-size-12px text-color-151d49 whitespace-nowrap truncate">{label}</div>
      </div>
    )
  }, [hiddenScenarios])

  const renderLegend = useCallback(() => {
    return (
      <div className="flex flex-wrap items-center py-4">
        {renderLegendItem(activeScenario, true)}
        {otherScenarios.map((scenario) => renderLegendItem(scenario, false))}
      </div>
    )
  }, [forecastSimulationWorkspace, activeScenario, otherScenarios, renderLegendItem])

  const renderMarkers = useCallback(() => {
    if (isActiveHidden) {
      return
    }

    const { width, height } = size
    if (width > 0 && height > 0) {
      const { output = {} } = activeScenario
      const { calloutValues = {} } = output

      const fairValue = get(calloutValues, `[${calloutNames.fairValue}]`, { value: 0 })
      const bearCase = calloutValues[calloutNames.bearCase]
      const bullCase = calloutValues[calloutNames.bullCase]

      let bearCaseX = !bearCase ? 0 : chart.width * ((bearCase.value - xRange.min) / (xRange.max - xRange.min))
      if (isNaN(bearCaseX)) bearCaseX = 0

      let fairValueX = !fairValue ? 0 : chart.width * ((fairValue.value - xRange.min) / (xRange.max - xRange.min))
      if (isNaN(fairValueX)) fairValueX = 0

      let bullCaseX = !bullCase ? 0 : chart.width * ((bullCase.value - xRange.min) / (xRange.max - xRange.min))
      if (isNaN(bullCaseX)) bullCaseX = 0

      const lineAttributes = {
        y1: 30,
        y2: chart.padding.top + chart.height,
        strokeWidth: 1,
        stroke: '#c9ced0',
      }

      const lineLabelAttributes = {
        style: {
          textAnchor: 'middle',
          fontWeight: 'normal',
          fontSize: 16,
          fontFamily: 'Proxima Nova',
          letterSpacing: 1.5,
          fill: '#a0a8bb',
        },
      }

      const bearCaseLabel = calloutNames.bearCase ? calloutNames.bearCase.toUpperCase() : ''
      const fairValueLabel = calloutNames.fairValue ? calloutNames.fairValue.toUpperCase() : ''
      const bullCaseLabel = calloutNames.bullCase ? calloutNames.bullCase.toUpperCase() : ''

      const bearCaseLabelWidth = getTextWidth(bearCaseLabel, lineLabelAttributes.style.fontWeight, lineLabelAttributes.style.fontSize, lineLabelAttributes.style.fontFamily)
      const fairValueLabelWidth = getTextWidth(fairValueLabel, lineLabelAttributes.style.fontWeight, lineLabelAttributes.style.fontSize, lineLabelAttributes.style.fontFamily)
      const bullCaseLabelWidth = getTextWidth(bullCaseLabel, lineLabelAttributes.style.fontWeight, lineLabelAttributes.style.fontSize, lineLabelAttributes.style.fontFamily)

      const bearLabelAttributes = { style: { ...lineLabelAttributes.style } }
      const fairLabelAttributes = { style: { ...lineLabelAttributes.style } }
      const bullLabelAttributes = { style: { ...lineLabelAttributes.style } }

      const labelOffset = {
        x: 25,
        y: 15,
      }

      const bearCasePos = {
        x: bearCase ? bearCaseX - labelOffset.x : 0,
        y: bearCase ? 15 + labelOffset.y : 0,
      }

      if ((bearCasePos.x + (bearCaseLabelWidth / 2) - labelOffset.x) > width) {
        bearLabelAttributes.style.textAnchor = 'end'
      } else if ((bearCasePos.x - (bearCaseLabelWidth / 2)) < 0) {
        bearLabelAttributes.style.textAnchor = 'start'
      }

      const fairValuePos = {
        x: fairValue ? fairValueX : 0,
        y: fairValue ? 15 : 0,
      }

      if (fairValuePos.x === width || (fairValuePos.x + (fairValueLabelWidth / 2)) > width) {
        fairLabelAttributes.style.textAnchor = 'end'
      } else if ((fairValuePos.x - (fairValueLabelWidth / 2)) < 0) {
        fairLabelAttributes.style.textAnchor = 'start'
      }

      const bullCasePos = {
        x: bullCase ? bullCaseX + labelOffset.x : 0,
        y: bullCase ? 15 + labelOffset.y : 0,
      }

      if ((bullCasePos.x + (bullCaseLabelWidth / 2) + labelOffset.x) > width) {
        bullLabelAttributes.style.textAnchor = 'end'
      } else if ((bullCasePos.x - (bullCaseLabelWidth / 2)) < 0) {
        bullLabelAttributes.style.textAnchor = 'start'
      }

      return (
        <>
          {bearCase && <line x1={bearCaseX} x2={bearCaseX} {...{ ...lineAttributes, y1: lineAttributes.y1 + labelOffset.y }} />}
          {fairValue && <line x1={fairValueX} x2={fairValueX} {...lineAttributes} />}
          {bullCase && <line x1={bullCaseX} x2={bullCaseX} {...{ ...lineAttributes, y1: lineAttributes.y1 + labelOffset.y }} />}

          {bearCase && <text {...bearCasePos} {...bearLabelAttributes}>{bearCaseLabel}</text>}
          {fairValue && <text {...fairValuePos} {...fairLabelAttributes}>{fairValueLabel}</text>}
          {bullCase && <text {...bullCasePos} {...bullLabelAttributes}>{bullCaseLabel}</text>}
        </>
      )
    }
  }, [size, xRange, forecastSimulationWorkspace, activeScenario, isActiveHidden, dataRange])

  const renderDataLabels = useCallback(() => {
    if (isActiveHidden) {
      return
    }

    const { width, height } = size
    if (width > 0 && height > 0) {
      const { output = {} } = activeScenario
      const { numericValuesList = [], calloutValues = {} } = output

      const bearCaseIndex = findIndex(numericValuesList, (item) => item.callout === calloutNames.bearCase)
      const fairValueIndex = findIndex(numericValuesList, (item) => item.callout === calloutNames.fairValue)
      const bullCaseIndex = findIndex(numericValuesList, (item) => item.callout === calloutNames.bullCase)

      const points = mapDataToPoints(numericValuesList).map((p) => [p.x, p.y])

      const dataLabelAttributes = {
        style: {
          textAnchor: 'middle',
          fontWeight: 'bold',
          fontSize: 24,
          fontFamily: 'Proxima Nova',
          letterSpacing: 1.5,
          fill: '#09242f',
        },
      }

      const bearLabelAttributes = { style: { ...dataLabelAttributes.style } }
      const fairLabelAttributes = { style: { ...dataLabelAttributes.style } }
      const bullLabelAttributes = { style: { ...dataLabelAttributes.style } }

      const fairValue = get(calloutValues, `[${calloutNames.fairValue}]`, { value: 0 })
      const bearCase = calloutValues[calloutNames.bearCase]
      const bullCase = calloutValues[calloutNames.bullCase]

      const bearCaseLabel = bearCase ? formatCurrency(bearCase.value) : ''
      const fairValueLabel = fairValue ? formatCurrency(fairValue.value) : ''
      const bullCaseLabel = bullCase ? formatCurrency(bullCase.value) : ''

      const bearCaseLabelWidth = getTextWidth(bearCaseLabel, dataLabelAttributes.style.fontWeight, dataLabelAttributes.style.fontSize, dataLabelAttributes.style.fontFamily)
      const fairValueLabelWidth = getTextWidth(fairValueLabel, dataLabelAttributes.style.fontWeight, dataLabelAttributes.style.fontSize, dataLabelAttributes.style.fontFamily)
      const bullCaseLabelWidth = getTextWidth(bullCaseLabel, dataLabelAttributes.style.fontWeight, dataLabelAttributes.style.fontSize, dataLabelAttributes.style.fontFamily)

      const bearCasePos = {
        x: bearCaseIndex !== -1 ? points[bearCaseIndex][0] - (bearCaseLabelWidth / 2) : 0,
        y: bearCaseIndex !== -1 ? points[bearCaseIndex][1] - dataLabelAttributes.style.fontSize / 2 : 0,
      }

      if ((bearCasePos.x + (bearCaseLabelWidth / 2)) > width) {
        bearLabelAttributes.style.textAnchor = 'end'
      } else if ((bearCasePos.x - (bearCaseLabelWidth / 2)) < 0) {
        bearLabelAttributes.style.textAnchor = 'start'
      }

      const fairValuePos = {
        x: fairValueIndex !== -1 ? points[fairValueIndex][0] : 0,
        y: fairValueIndex !== -1 ? points[fairValueIndex][1] - dataLabelAttributes.style.fontSize / 2 : 0,
      }

      if ((fairValuePos.x + (fairValueLabelWidth / 2)) > width) {
        fairLabelAttributes.style.textAnchor = 'end'
      } else if ((fairValuePos.x - (fairValueLabelWidth / 2)) < 0) {
        fairLabelAttributes.style.textAnchor = 'start'
      }

      const bullCasePos = {
        x: bullCaseIndex !== -1 ? points[bullCaseIndex][0] + (bullCaseLabelWidth / 2) : 0,
        y: bullCaseIndex !== -1 ? points[bullCaseIndex][1] - dataLabelAttributes.style.fontSize / 2 : 0,
      }

      if ((bullCasePos.x + (bullCaseLabelWidth / 2)) > width) {
        bullLabelAttributes.style.textAnchor = 'end'
      } else if ((bullCasePos.x - (bullCaseLabelWidth / 2)) < 0) {
        bullLabelAttributes.style.textAnchor = 'start'
      }

      return (
        <>
          {bearCase && bearCaseIndex !== -1 && <text {...bearCasePos} {...bearLabelAttributes}>{bearCaseLabel}</text>}
          {fairValue && fairValueIndex !== -1 && <text {...fairValuePos} {...fairLabelAttributes}>{fairValueLabel}</text>}
          {bullCase && bullCaseIndex !== -1 && <text {...bullCasePos} {...bullLabelAttributes}>{bullCaseLabel}</text>}
        </>
      )
    }
  }, [size, forecastSimulationWorkspace, activeScenario, isActiveHidden, dataRange, formatCurrency])

  return (
    <>
      <div ref={svgContainerRef} className="w-full mt-8 border-l-2 border-b-2 border-color-09242f" style={{ height: 520 }}>
        <svg width="100%" height="100%">
          <defs>
            <linearGradient id="activeFill" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop stopColor="rgba(91, 148, 255, 0.3)" offset="0%" />
              <stop stopColor="rgba(255, 255, 255, 0.08)" offset="100%" />
            </linearGradient>
            <filter id="dropShadow">
              <feDropShadow dx="2" dy="2" stdDeviation="1" floodColor="black" floodOpacity="0.5" />
            </filter>
          </defs>
          {renderMarkers()}
          {renderLines()}
          {renderDataLabels()}
        </svg>
      </div>
      <div className="flex justify-center">
        {renderLegend()}
      </div>
    </>
  )
}

export default LineChart
