import React, { useCallback, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { toGetCanvasRequest } from '../grpc/converters'
import { useGrpcEffect } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import { useAuth } from './auth'
import { forEach, orderBy } from 'lodash'

const CanvasContext = React.createContext()

export function CanvasProvider({ canvasKey, userId, children }) {
  const { actingTenantId, actingUserId } = useAuth()
  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [canvas, setCanvas] = useState(undefined)
  const [isFetching, setIsFetching] = useState(true)

  useGrpcEffect({
    request: toGetCanvasRequest({
      tenantId: actingTenantId,
      userId: userId || actingUserId,
      canvasKey,
    }),
    onSuccess: (obj) => {
      forEach(obj?.tileContainersList ?? [], (t) => {
        if (t.tile?.realtimeView?.userQueryParametersList) {
          t.tile.realtimeView.userQueryParametersList = orderBy(t.tile.realtimeView.userQueryParametersList, (p) => p.sort)
        }
      })
      setCanvas(obj)
      setIsFetching(false)
    },
    onError: () => {
      setIsFetching(false)
      notifyError('Error getting canvas data')
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getCanvas',
    debug: false,
  }, [canvasKey, userId, key])

  const tenantStandardParams = useMemo(() => {
    return canvas?.canvasStandardParams?.tenantStandardParams
  }, [canvas])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      canvasKey,
      canvas,
      tenantStandardParams,
      invalidate
    }
  }, [isFetching, canvasKey, canvas, tenantStandardParams])

  return <CanvasContext.Provider value={contextValue}>{children}</CanvasContext.Provider>
}

export function useCanvas() {
  const context = React.useContext(CanvasContext)
  if (context === undefined) {
    throw new Error('useCanvas must be used within a CanvasProvider')
  }
  return context
}
