import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FilterExpander from './filterExpander'
import { DateRange } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'
import { parseDate } from '../../lib/dateFns'
import { isString } from 'lodash'
import { removeSearchFilter } from '../../actions'
import { addDays, addMonths, addYears, endOfMonth, getMonth, getYear, startOfMonth } from 'date-fns'

const DateRangeFilter = (props) => {
  const {
    fieldDefinition,
    onChange,
    open = false,
    isTimestamp = false,
    onToggle
  } = props

  const dispatch = useDispatch()

  const { label } = fieldDefinition

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const searchFilters = useSelector((state) => state.searchFilters)
  const { pendingFilters } = searchFilters

  const organization = useSelector((state) => state.organization)

  const applied = useMemo(() => {
    return !!pendingFilters[fieldDefinition.key]
  }, [fieldDefinition, pendingFilters])

  const salesPeriodLength = useMemo(() => {
    return (organization && organization.organization && organization.organization.salesPeriodLength) || 1
  }, [organization])

  useEffect(() => {
    const currentFilter = fieldDefinition.key && pendingFilters[fieldDefinition.key]
    if (currentFilter) {
      const { data = {} } = currentFilter
      let { startDate = new Date(), endDate = new Date() } = data
      const { rangeByName } = data
      if (rangeByName) {
        if (rangeByName === 'thisMonth') {
          startDate = startOfMonth(new Date())
          endDate = endOfMonth(new Date())
        } else if (rangeByName === 'nextMonth') {
          startDate = startOfMonth(addMonths(new Date(), 1))
          endDate = endOfMonth(addMonths(new Date(), 1))
        } else if (rangeByName === 'thisPeriod') {
          const { periodStart, periodEnd } = organization
          startDate = parseDate(periodStart)
          endDate = parseDate(periodEnd)
        } else if (rangeByName === 'nextPeriod') {
          const { periodStart, periodEnd } = organization
          const salesPeriodLength = (organization && organization.organization && organization.organization.salesPeriodLength) || 1
          startDate = addMonths(parseDate(periodStart), salesPeriodLength)
          endDate = endOfMonth(addMonths(parseDate(periodEnd), salesPeriodLength))
        } else if (rangeByName === 'thisYear') {
          const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
          const month = getMonth(new Date()) + 1
          const year = getYear(new Date())
          if (month >= fiscalYearStartMonth) {
            startDate = parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d')
            endDate = addDays(addYears(startDate, 1), -1)
          } else {
            startDate = parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d')
            endDate = addDays(addYears(startDate, 1), -1)
          }
        } else if (rangeByName === 'nextYear') {
          const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
          const month = getMonth(new Date()) + 1
          const year = getYear(new Date())
          if (month >= fiscalYearStartMonth) {
            startDate = addYears(parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), 1)
            endDate = addDays(addYears(startDate, 1), -1)
          } else {
            startDate = addYears(parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), 1)
            endDate = addDays(addYears(startDate, 1), -1)
          }
        }
      } else {
        if (isString(startDate) && startDate.length >= 10) {
          startDate = parseDate(startDate.substring(0, 10))
        }
        if (isString(endDate) && endDate.length >= 10) {
          endDate = parseDate(endDate.substring(0, 10))
        }
      }
      setStartDate(startDate)
      setEndDate(endDate)
    } else {
      setStartDate(new Date())
      setEndDate(new Date())
    }
  }, [fieldDefinition, organization, pendingFilters])

  const dateRange = useMemo(() => {
    return [
      {
        startDate,
        endDate,
        key: 'selection'
      }
    ]
  }, [startDate, endDate])

  const onChangeInternal = useCallback((e) => {
    setStartDate(e.selection.startDate)
    setEndDate(e.selection.endDate)

    onChange && onChange({
      fieldDefinition,
      type: 'DateRangeFilter',
      data: {
        startDate: e.selection.startDate,
        endDate: e.selection.endDate,
        isTimestamp,
      },
    })
  }, [fieldDefinition, startDate, endDate])

  const onClear = useCallback(() => {
    setStartDate(new Date())
    setEndDate(new Date())
    dispatch(removeSearchFilter({ key: fieldDefinition.key, pending: true, applied: false }))
  }, [])

  const onMonthClick = useCallback((rangeByName, offset = 0) => {
    const start = startOfMonth(addMonths(new Date(), offset))
    const end = endOfMonth(addMonths(new Date(), offset))
    setStartDate(start)
    setEndDate(end)

    onChange && onChange({
      fieldDefinition,
      type: 'DateRangeFilter',
      data: {
        startDate: start,
        endDate: end,
        isTimestamp,
        rangeByName,
      },
    })
  }, [])

  const onPeriodClick = useCallback((rangeByName, offset = 0) => {
    const { periodStart, periodEnd } = organization
    const salesPeriodLength = (organization && organization.organization && organization.organization.salesPeriodLength) || 1
    const start = addMonths(parseDate(periodStart), salesPeriodLength * offset)
    const end = endOfMonth(addMonths(parseDate(periodEnd), salesPeriodLength * offset))
    setStartDate(start)
    setEndDate(end)

    onChange && onChange({
      fieldDefinition,
      type: 'DateRangeFilter',
      data: {
        startDate: start,
        endDate: end,
        isTimestamp,
        rangeByName,
      },
    })
  }, [organization])

  const onYearClick = useCallback((rangeByName, offset = 0) => {
    const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
    const month = getMonth(new Date())
    const year = getYear(new Date())
    let start
    let end
    if (month >= fiscalYearStartMonth) {
      start = addYears(parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), offset)
      end = addDays(addYears(start, 1), -1)
    } else {
      start = addYears(parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), offset)
      end = addDays(addYears(start, 1), -1)
    }
    setStartDate(start)
    setEndDate(end)

    onChange && onChange({
      fieldDefinition,
      type: 'DateRangeFilter',
      data: {
        startDate: start,
        endDate: end,
        isTimestamp,
        rangeByName,
      },
    })
  }, [organization])

  return (
    <FilterExpander
      count={1}
      showCount={applied}
      onToggle={onToggle}
      open={open}
      name={label}>
      <div className="flex justify-between items-center py-2">
        <div className="flex items-center flex-wrap">
          <div className="flex flex-col leading-snug">
            <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap mr-2" onClick={() => onMonthClick('thisMonth', 0)}>This Month</div>
            <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap mr-2" onClick={() => onMonthClick('nextMonth', 1)}>Next Month</div>
          </div>
          {salesPeriodLength !== 1 && (
            <div className="flex flex-col leading-snug">
              <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap mr-2" onClick={() => onPeriodClick('thisPeriod', 0)}>
                This
                {' '}
                {salesPeriodLength === 3 ? 'Quarter' : 'Period'}
              </div>
              <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap mr-2" onClick={() => onPeriodClick('nextPeriod', 1)}>
                Next
                {' '}
                {salesPeriodLength === 3 ? 'Quarter' : 'Period'}
              </div>
            </div>
          )}
          <div className="flex flex-col leading-snug">
            <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap mr-2" onClick={() => onYearClick('thisYear', 0)}>This Year</div>
            <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap mr-2" onClick={() => onYearClick('nextYear', 1)}>Next Year</div>
          </div>
        </div>
        <div className="text-size-12px text-color-2e5bff underline cursor-pointer whitespace-nowrap" onClick={onClear}>Clear</div>
      </div>
      <div className="DateRangeFilter-DateRangeWrapper">
        <DateRange
          editableDateInputs={false}
          onChange={onChangeInternal}
          moveRangeOnFirstSelection={false}
          ranges={dateRange} />
      </div>
    </FilterExpander>
  )
}

export default DateRangeFilter
