import { Column } from 'react-base-table'
import { defaultTake } from '../pipeline/constants'
import { DisplayOption, VisibilityOption } from '../../grpc/enums'
import { uniqBy, find, sortBy, slice, reverse, filter, forOwn, forEach } from 'lodash'
import {
  getForecastSubmittedAt,
  getCurrentForecastCategoryCall
} from './helpers'
import { permissionNames } from '../../constants/permissionNames'
import { roundArrow } from 'tippy.js'
import { useForecasting } from '../../context/forecasting'
import { useForecastingFeature } from './hooks'
import { useHistory } from 'react-router-dom'
import { useLocationData } from '../location/hooks'
import { usePermissions } from '../../context/permissions'
import { useRoutes } from '../../context/routes'
import BaseTableExtended from '../tables/baseTableExtended'
import classNames from 'classnames'
import moment from 'moment'
import noteIcon from '../../assets/note-icon.png'
import pluralize from 'pluralize'
import React, { useCallback, useMemo, useState } from 'react'
import TeamListItemCall from './teamListItemCall'
import Tippy from '@tippyjs/react'
import TruncatedText from '../common/truncatedText'

const cellSharedClassNames = 'table-cell py-3 px-2 align-middle leading-tight text-left text-size-15px font-weight-400'

const Team = (props) => {
  const {
    forecastConfigIdParam,
    group,
    isLoggedInUsersTeam,
    isIcManagerAndDirectReport,
    search,
    user,
    userIdsParam,
  } = props

  const { checkPermissions } = usePermissions()
  const { forecastCategories } = useForecastingFeature()
  const { location, hash } = useLocationData()
  const { routes } = useRoutes()
  const history = useHistory()

  const {
    forecast,
    forecastByUserLocal,
    isFetching: forecastFetching,
    success: forecastSuccess,
    teamForecasts,
    updateForecastByUserLocal,
  } = useForecasting()

  const [sortDirection, setSortDirection] = useState('')
  const [sortKey, setSortKey] = useState('')

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadForecast
    )
  }, [checkPermissions])

  const ready = useMemo(() => {
    return !forecastFetching && forecastSuccess
  }, [forecastFetching, forecastSuccess])

  const isManager = useMemo(() => {
    return user.info && user.info.hasChildren
  }, [user.info])

  const lastSubmitRelative = useCallback((current) => {
    if (current.submittedAt && current.submittedAt.value && current.submittedAt.value.seconds) {
      return moment(current.submittedAt.value.seconds * 1000).fromNow()
    }
  }, [])

  const onClick = useCallback((user) => {
    const { hasChildren, id } = user
    const userIds = userIdsParam ? `${userIdsParam}|${id}` : id
    hasChildren
      ? history.push(routes.forecastingByTeam
        .replace(':userIds', userIds)
        .replace(':forecastConfigId', forecastConfigIdParam))
      : history.push(routes.forecastingByTeam_Deals
        .replace(':userIds', userIds)
        .replace(':forecastConfigId', forecastConfigIdParam))
  }, [forecastConfigIdParam, history, routes.forecastingByTeam, routes.forecastingByTeam_Deals, userIdsParam])

  const visibleCategories = useMemo(() => {
    return forecastCategories
      .filter((fc) => [DisplayOption.ALL, DisplayOption.GRID, DisplayOption.BANNER_GRID].includes(fc.displayOption))
      .filter((fc) => [VisibilityOption.ALL, VisibilityOption.MANAGER].includes(fc.visibilityOption))
  }, [forecastCategories])

  const onCallChange = useCallback(({ ownerId, forecastCategory, inputValue }) => {
    updateForecastByUserLocal({
      ownerId,
      forecastCategory,
      call: inputValue,
    })
    window.analytics.track('forecasting.v2.teamListItem.callChanged')
  }, [updateForecastByUserLocal])

  const getValue = useCallback((forecast, forecastCategory) => {
    const { current } = forecast
    const { ownerId } = current
    return getCurrentForecastCategoryCall(forecastCategory.name, forecast, forecastByUserLocal[ownerId])
  }, [forecastByUserLocal])

  const getCommentTooltip = useCallback((forecastComment) => {
    return (
      <div className="px-4 py-3">
        <pre className="forecast-comment">
          {forecastComment}
        </pre>
      </div>
    )
  }, [])

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        dataKey: 'user.name',
        title: 'Name',
        width: 250,
        resizable: true,
        sortable: true,
        frozen: Column.FrozenDirection.LEFT,
        className: 'overflow-hidden',
        cellRenderer: ({ rowData }) => {
          const { user = {}, info, isTeamRow, id } = rowData

          const { name = '' } = user

          return (
            <div className={classNames('overflow-hidden', { 'ml-4': !isTeamRow })}>
              {isTeamRow
                ? (
                  <div className="text-color-151d49 text-size-15px font-weight-700 text-left ">
                    <TruncatedText text={name} />
                  </div>
                )
                : (
                  <div
                    onClick={() => onClick(user)}
                    className="text-color-151d49 text-size-15px font-weight-700 focus:outline-none text-left cursor-pointer">
                    <TruncatedText text={name} />
                  </div>
                )}
              <div className="text-color-91959f text-size-14px font-normal text-left">{dealCount(id, info)}</div>
            </div>
          )
        }
      },
      {
        key: 'last-submit',
        dataKey: 'current.submittedAt.value.seconds',
        title: 'Last Submission',
        width: 150,
        resizable: true,
        sortable: true,
        cellRenderer: ({ rowData }) => {
          const { info } = rowData
          const { current } = info
          const lastSubmit = getForecastSubmittedAt(current)
          const { comment } = current
          const commentTooltip = getCommentTooltip(comment)

          return (
            <>
              <div className="px-2">
                <div>{lastSubmit || '-'}</div>
                <div className="text-color-91959f text-size-12px font-normal text-left">{lastSubmitRelative(current)}</div>
              </div>
              {comment
                && (
                  <Tippy
                    offset={[0, 2]}
                    duration={[0, 0]}
                    maxWidth={300}
                    content={commentTooltip}
                    arrow={roundArrow}
                    theme="canopy">
                    <img src={noteIcon} style={{ transform: 'translate(-2px, 3px)' }} />
                  </Tippy>
                )}
            </>
          )
        }
      },
      ...visibleCategories.map((fc, index) => {
        const { id, name } = fc
        return {
          key: id,
          dataKey: id,
          title: name,
          width: 150,
          resizable: true,
          sortable: true,
          cellRenderer: ({ rowData }) => {
            const { info, isTeamRow } = rowData
            const { current } = info
            const { goalAmount } = current

            return (
              <TeamListItemCall
                key={`TeamListItemCall-${fc.id}-${index}`}
                isLoggedInUsersTeam={isLoggedInUsersTeam}
                isIcManagerAndDirectReport={isIcManagerAndDirectReport}
                className={cellSharedClassNames}
                forecastCategory={fc}
                forecast={info}
                isTeamRow={isTeamRow}
                isManager={isManager}
                goalAmount={goalAmount}
                onCallChange={onCallChange} />
            )
          }
        }
      })
    ]
  }, [
    dealCount,
    getCommentTooltip,
    isIcManagerAndDirectReport,
    isLoggedInUsersTeam,
    isManager,
    lastSubmitRelative,
    onCallChange,
    onClick,
    visibleCategories,
  ])

  const records = useMemo(() => {
    return {
      skip: parseInt(hash.skip) || 0,
      take: parseInt(hash.take) || defaultTake,
      total: teamForecasts?.length || 0
    }
  }, [teamForecasts, hash])

  const onSetPage = useCallback((page) => {
    const skip = (page - 1) * defaultTake

    let route = location.pathname
    let i = 0
    const h = {
      ...hash
    }
    h.skip = skip
    h.take = defaultTake
    if (skip === 0) {
      delete h.skip
      delete h.take
    }
    forOwn(h, (value, key) => {
      if (i === 0) {
        route += '#'
      } else {
        route += '&'
      }
      if (value) {
        route += `${key}=${value}`
      } else {
        route += key
      }
      i += 1
    })

    history.push(route)
  }, [location.pathname, hash, history])

  const mapData = useCallback(({ info, user }, rowIndex) => {
    const cols = {}
    forEach(columns, (col) => {
      const fieldKey = col.key
      cols[fieldKey] = {
        fieldKey,
        info,
        user
      }
    })
    return {
      id: `row-${rowIndex}`,
      columns: cols,
      parentId: null,
      forecastByUserLocal,
      info,
      user
    }
  }, [columns, forecastByUserLocal])

  const data = useMemo(() => {
    return teamForecasts.map(mapData)
  }, [teamForecasts, mapData])

  const teamRow = useMemo(() => {
    const row = mapData({
      info: forecast,
      user: group
    }, 999)
    row.isTeamRow = true
    return row
  }, [forecast, group, mapData])

  const sortByProps = useMemo(() => {
    return {
      key: hash.sortKey || '',
      order: hash.sortDirection ? hash.sortDirection.toLowerCase() : 'asc'
    }
  }, [hash])

  const getForecastCategory = useCallback((id) => {
    return find(visibleCategories, { id })
  }, [visibleCategories])

  const dataSorted = useMemo(() => {
    if (!data?.length) {
      return []
    }
    const { key, order } = sortByProps
    if (!key) {
      return data
    }
    let accessor
    switch (key) {
      case 'name':
        accessor = 'user.name'
        break
      case 'last-submit':
        accessor = 'current.submittedAt.value.seconds'
        break
      default:
        accessor = ({ info }) => {
          const category = getForecastCategory(key)
          return getValue(info, category)
        }
    }
    const sorted = sortBy(data, accessor)

    return order.toLowerCase() === 'desc' ? reverse(sorted) : sorted
  }, [sortByProps, data, getValue, getForecastCategory])

  const dataSortedAndFiltered = useMemo(() => {
    const filtered = !search
      ? dataSorted
      : filter(dataSorted, ({ user }) => {
        const { name } = user
        return name.toLowerCase().includes(search.trim().toLowerCase())
      })
    return filtered
  }, [dataSorted, search])

  const dataPaged = useMemo(() => {
    const { take, skip } = records
    return slice(dataSortedAndFiltered, skip, skip + take)
  }, [dataSortedAndFiltered, records])

  const getDealCountsByTeam = useCallback((info) => {
    const count = (info) => {
      const { current = {} } = info
      const { objectsList = [], objectCount } = current
      if (parseInt(objectCount)) {
        return objectCount
      }
      const uniqueByObjectId = uniqBy(objectsList, 'objectId')
      return uniqueByObjectId.length
    }

    return count(info)
  }, [])

  const dealCount = useCallback((id, info) => {
    const count = getDealCountsByTeam(info) ?? 0
    return pluralize('deal', count, true)
  }, [getDealCountsByTeam])

  const onColumnSort = useCallback((sort) => {
    let sKey = sort.key || ''
    let sDirection = (sort.order || '').toUpperCase()
    if (sKey === sortKey && sortDirection === 'DESC') {
      sKey = ''
      sDirection = ''
    }

    setSortKey(sKey)
    setSortDirection(sDirection)

    let route = location.pathname
    let i = 0
    const h = {
      ...hash
    }
    h.sortDirection = sDirection
    h.sortKey = sKey
    if (!sKey) {
      delete h.sortKey
    }
    if (!sDirection) {
      delete h.sortDirection
    }
    delete h.skip
    delete h.take
    forOwn(h, (value, key) => {
      if (i === 0) {
        route += '#'
      } else {
        route += '&'
      }
      if (value) {
        route += `${key}=${value}`
      } else {
        route += key
      }
      i += 1
    })

    history.push(route)
  }, [sortKey, sortDirection, location.pathname, hash, history])

  return (
    <div className="w-full h-full flex-grow">

      {permissions.CanReadForecast && ready && (
        <div className="table w-full pr-10" style={{ marginLeft: 40 }}>
          <BaseTableExtended
            data={dataPaged}
            frozenData={[teamRow]}
            records={records}
            loading={false}
            fixedColumns={columns}
            onColumnSort={onColumnSort}
            onSetPage={onSetPage}
            scrollDisabled={false}
            sortBy={sortByProps}
            style={{ marginLeft: 40 }}
            total={records.total}
            useTotalRowsHeight={true}
            rowHeight={60} />
        </div>
      )}

    </div>
  )
}

export default Team
