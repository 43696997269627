import React, { useMemo } from 'react'
import Avatar from '../avatar/avatar'
import take from 'lodash/take'
import { useUserImageUrl } from '../../hooks/useUserImageUrl'

const PersonPreview = (props) => {
  const { person } = props
  const { id, name, photoUrl, goals = [] } = person || {}

  const { userImageUrl } = useUserImageUrl(photoUrl)

  const metrics = useMemo(() => {
    return take(goals, 3)
  }, [goals])

  return (
    <>
      {person && (
        <div className="w-full mx-auto text-center">

          <Avatar key={`Avatar-${id}`} className="w-15 h-15 text-size-36px mx-auto" imageUrl={userImageUrl} text={name.charAt(0)} isRound={true} />

          <div className="text-size-24px font-bold text-color-151d49 leading-tight mt-3 mb-2">{name}</div>

          <div className="mt-4">
            {metrics.map((metric) => (
              <React.Fragment key={`PersonPreview-${metric.key}`}>
                <div className="py-2 flex justify-between items-center w-full border-t border-color-e3e8ee">
                  <div className="text-size-15px text-color-2e384d font-bold px-1">{metric.label}</div>
                  <div className="text-size-15px text-color-4e5d7e font-normal px-1">{metric.value}</div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default PersonPreview
