import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { getFieldRenderType } from '../fieldRenderers/helpers'
import { canopyFields, fieldRenderTypes } from '../fieldRenderers/constants'
import { cloneDeep, filter, find, forEach, forOwn, has, isDate, isString, values } from 'lodash'
import { clearSearchFilters, removeSearchFilter, removeSearchOptionFilter, setSearchFilters } from '../../actions'
import { addDays, addMonths, addYears, endOfMonth, getMonth, getYear, parseISO, startOfMonth } from 'date-fns'
import ViewOption, { viewOptionRenderer } from './viewOption'
import ViewMenu from './viewMenu'
import { useLocationData } from '../location/hooks'
import { usePipelineFeature, useSaveUserViews } from './hooks'
import { searchOpps, getSearchObject } from '../../services/searchService'
import { mapFiltersToQuery } from './helpers'
import { useDebug } from '../../context/debug'
import objectHash from 'object-hash'
import { useGrpcCallback } from '../../grpc'
import { defaultSkip, defaultTake, defaultView } from './constants'
import { toExportSearchResultsRequest } from '../../grpc/converters'
import { parseDate } from '../../lib/dateFns'
import { useHistory } from 'react-router-dom'
import { useFormatValue } from '../fieldRenderers/hooks'
import { useModal } from '../../hooks/useModal'
import DownloadModal from './downloadModal'
import { downloadFile } from '../../lib/gCloudStorage'
import { useTenantInfo } from '../../context/tenantInfo'
import { useViews } from '../../context/views'
import CustomDragLayer from '../dragAndDrop/customDragLayer'
import { useAuth } from '../../context/auth'

const ViewsAndFilters = (props) => {
  const {
    filterKey,
    className,
    groupIds,
    userIds,
    excludeUserIds,
    changeSince,
    explicitViews = [],
    fetchDealHealthData
  } = props

  const dispatch = useDispatch()

  const { formatValue } = useFormatValue()

  const { saveUserViews } = useSaveUserViews({ filterKey })

  const { tenantInfo } = useTenantInfo()

  const { currencyCode } = tenantInfo

  const { debug } = useDebug()

  const modal = useModal()

  const { actingTenantId } = useAuth()

  const { primaryFields } = usePipelineFeature()

  const [currentViewData, setCurrentViewData] = useState(undefined)
  const [currentViewSort, setCurrentViewSort] = useState(undefined)
  const [urlParamsHash, setUrlParamsHash] = useState('')
  const [downloadErrorText, setDownloadErrorText] = useState('')
  const [downloadRequested, setDownloadRequested] = useState(false)
  const [complete, setComplete] = useState(false)
  const [available, setAvailable] = useState(false)

  const { total: totalSearchResults } = useSelector((state) => state.searchedOpps)
  const currentFilters = useSelector((state) => state.currentFilters)

  const { location, hash } = useLocationData()
  const history = useHistory()

  const organization = useSelector((state) => state.organization)
  const pipelineGridFields = useSelector((state) => state.pipelineGridFields)
  const objectDefinitions = useSelector((state) => state.objectDefinitions)
  const searchFilters = useSelector((state) => state.searchFilters)
  const { appliedFilters, view, viewInitialized } = searchFilters

  const { views: savedViews } = useViews()

  const [latestAppliedFilters, setLatestAppliedFilters] = useState(undefined)
  const [allViews, setAllViews] = useState([])
  const [tenantViews, setTenantViews] = useState([])
  const [userViews, setUserViews] = useState([])

  const setFilterState = useCallback((viewKey, payload) => {
    const valuesList = payload?.query?.children?.valuesList ?? []
    const filters = valuesList.filter((v) => {
      const f = v.node?._filter
      if (f) {
        const selected = f.data?.selected ?? []
        if (f.type === 'MultiSelectFilter') {
          return selected.length > 0
        }
        return true
      }
      return false
    }).map((f) => ({ filter: f.node._filter, pending: true, applied: true }))

    dispatch(clearSearchFilters())
    dispatch(setSearchFilters({ filters, view: viewKey }))

    const appliedFilters = {}
    forEach(filters, (f) => {
      const { filter } = f
      const { fieldDefinition } = filter
      if (fieldDefinition.key) {
        appliedFilters[fieldDefinition.key] = filter
      }
    })
    setLatestAppliedFilters(appliedFilters)
  }, [dispatch])

  const availableFields = useMemo(() => {
    const fieldsList = objectDefinitions.opportunity?.fieldsList ?? []
    return [
      ...fieldsList.map((f) => f.to),
      canopyFields.ownerId.key,
      canopyFields._owner.key,
      canopyFields['owner.id'].key,
      canopyFields._reportsTo.key,
      canopyFields._signalCount.key,
      canopyFields._status.key,
      canopyFields._internalupdatedat.key
    ].map((f) => f.toLowerCase())
  }, [objectDefinitions.opportunity])

  const doSearch = useCallback(({ query, changeSince, skip, take, sortKey, sortDirection, search, initialViewLoad = false }) => {
    const searchOptions = {
      changeSince,
      availableFields,
      fields: primaryFields,
      ...search && { searchText: search },
      ...sortKey && { sortKey },
      ...sortDirection && { sortDirection },
      groupIds,
      userIds,
      excludeUserIds,
      actingTenantId,
      fetchDealHealthData,
      debug
    }
    dispatch(searchOpps(skip, take, query, searchOptions, changeSince, initialViewLoad))
  }, [dispatch, primaryFields, availableFields, groupIds, userIds, excludeUserIds, actingTenantId, fetchDealHealthData, debug])

  useEffect(() => {
    // when unmounting this component, be sure to clear redux state for filters
    return () => {
      dispatch(clearSearchFilters())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAllViews([
      ...explicitViews,
      ...savedViews,
    ])
    setTenantViews(filter(savedViews, (v) => v.order < 0))
    setUserViews(filter(savedViews, (v) => v.order > -1))
  }, [explicitViews, savedViews])

  useEffect(() => {
    const _view = find(allViews, (d) => d.key === (hash.view || defaultView))
    if (_view) {
      setCurrentViewData(_view)
      setCurrentViewSort({
        sortDirection: hash.sortDirection,
        sortKey: hash.sortKey
      })
    }
  }, [hash.view, hash.sortDirection, hash.sortKey, allViews])

  useEffect(() => {
    // this hook is for initial load of a view or when switching views
    if (objectDefinitions.opportunity && currentViewData && currentViewData.key !== view) {
      const { payload } = currentViewData

      // set state to track url changes
      setUrlParamsHash(objectHash(hash))

      // set filter state in redux
      setFilterState(currentViewData.key, payload)

      // perform the search
      doSearch({
        query: payload.query,
        changeSince,
        skip: hash.skip || defaultSkip,
        take: hash.take || defaultTake,
        sortKey: hash.sortKey,
        sortDirection: hash.sortDirection,
        search: hash.search,
        initialViewLoad: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectDefinitions, currentViewData, view, setFilterState, doSearch])

  useEffect(() => {
    // this hook is for tracking url changes
    if (viewInitialized) {
      const paramsHash = objectHash(hash)
      if (urlParamsHash && urlParamsHash !== paramsHash) {
        // set state to track the newer url changes
        setUrlParamsHash(paramsHash)

        doSearch({
          query: mapFiltersToQuery(appliedFilters, pipelineGridFields),
          changeSince,
          skip: hash.skip || defaultSkip,
          take: hash.take || defaultTake,
          sortKey: hash.sortKey,
          sortDirection: hash.sortDirection,
          search: hash.search
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInitialized, hash, urlParamsHash])

  useEffect(() => {
    // this hook is for tracking filter changes
    if (viewInitialized && latestAppliedFilters && objectHash(latestAppliedFilters) !== objectHash(appliedFilters)) {
      // set state to track the newer url changes
      setLatestAppliedFilters(appliedFilters)

      doSearch({
        query: mapFiltersToQuery(appliedFilters, pipelineGridFields),
        changeSince,
        skip: hash.skip || defaultSkip,
        take: hash.take || defaultTake,
        sortKey: hash.sortKey,
        sortDirection: hash.sortDirection,
        search: hash.search
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInitialized, latestAppliedFilters, appliedFilters])

  const afterDownload = useCallback(() => {
    setTimeout(() => {
      setDownloadRequested(false)
      setAvailable(false)
      setComplete(false)
      modal.setOpen(false)
    }, 3000)
  }, [modal])

  const fieldList = useMemo(() => {
    if (!objectDefinitions?.opportunity?.fieldsList) {
      return []
    }

    return primaryFields.map((fieldName) => {
      const f = fieldName.startsWith('canopy_') ? fieldName.replace('canopy', '') : fieldName
      let label
      if (f.toLowerCase() === canopyFields._internalupdatedat.key) {
        label = canopyFields._internalupdatedat.label || 'Last Updated'
      } else if (canopyFields[f]) {
        label = canopyFields[f].label || f
      } else {
        const fieldDef = find(objectDefinitions.opportunity.fieldsList, (field) => f && field && field.to && f.toLowerCase() === field.to.toLowerCase())
        label = fieldDef ? fieldDef.label : f
      }
      return {
        key: f,
        label
      }
    })
  }, [primaryFields, objectDefinitions])

  const requestDownloadRequest = useGrpcCallback({
    onError: () => {
      setDownloadErrorText('Unable to download pipleline, please try again.')
      afterDownload()
    },
    onSuccess: (obj) => {
      const { url } = obj
      setAvailable(true)
      if (url) {
        downloadFile({
          url,
          contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileName: `${currentViewData.label}.xlsx`,
          onSuccess: () => {
            setComplete(true)
            afterDownload()
          },
          onError: () => {
            setDownloadErrorText('Unable to download pipleline, please try again.')
            afterDownload()
          }
        })
      }
    },
    onFetch: () => setDownloadRequested(true),
    grpcMethod: 'exportSearchResults',
    debug: false,
  }, [currentViewData, afterDownload])

  const requestDownload = useCallback(() => {
    modal.setOpen(true)

    if (totalSearchResults >= 100000) {
      setDownloadErrorText('Pipeline includes more than 100,000 records, please reduce results and try again.')
      afterDownload()
      return
    }

    const q = cloneDeep(currentFilters.query)

    const searchOptions = {
      availableFields,
      fields: [],
      groupIds,
      userIds,
      excludeUserIds,
      ...currentViewSort
    }

    const searchObject = getSearchObject(q, searchOptions, objectDefinitions, organization, changeSince, 0, totalSearchResults)
    const { objectName, query, paging } = searchObject
    const searchResultsRequest = {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'signalCount', label: 'Signals' },
        { key: 'owner', label: 'Owner' },
        ...fieldList.filter((f) => availableFields.includes(f.key.toLowerCase()))
      ],
      objectName,
      query,
      paging,
      currencyCode
    }

    const request = toExportSearchResultsRequest(searchResultsRequest)
    requestDownloadRequest(request)
  }, [
    modal,
    currencyCode,
    requestDownloadRequest,
    fieldList,
    currentFilters,
    currentViewSort,
    objectDefinitions,
    organization,
    changeSince,
    groupIds,
    userIds,
    excludeUserIds,
    totalSearchResults,
    availableFields
  ])

  const onRemoveOptionFilter = useCallback((filter, option) => {
    const { fieldDefinition } = filter
    if (fieldDefinition.key) {
      dispatch(removeSearchOptionFilter({ key: fieldDefinition.key, option }))

      if (has(hash, 'skip') || has(hash, 'take')) {
        let route = location.pathname
        let i = 0
        const h = {
          ...hash,
        }
        delete h.skip
        delete h.take
        forOwn(h, (value, key) => {
          if (i === 0) {
            route += '#'
          } else {
            route += '&'
          }
          if (value) {
            route += `${key}=${value}`
          } else {
            route += key
          }
          i += 1
        })
        history.push(route)
      }
    }
  }, [location, hash])

  const onRemoveFilter = useCallback((filter) => {
    const { fieldDefinition } = filter
    if (fieldDefinition.key) {
      dispatch(removeSearchFilter({ key: fieldDefinition.key, pending: true, applied: true }))

      if (has(hash, 'skip') || has(hash, 'take')) {
        let route = location.pathname
        let i = 0
        const h = {
          ...hash,
        }
        delete h.skip
        delete h.take
        forOwn(h, (value, key) => {
          if (i === 0) {
            route += '#'
          } else {
            route += '&'
          }
          if (value) {
            route += `${key}=${value}`
          } else {
            route += key
          }
          i += 1
        })
        history.push(route)
      }
    }
  }, [location, hash])

  const renderRemoveFilter = useCallback((filter, index) => {
    const { fieldDefinition } = filter
    const renderType = getFieldRenderType(fieldDefinition)
    let component = <React.Fragment key={`filter-${fieldDefinition.key}-${index}`} />
    switch (renderType) {
      case fieldRenderTypes.DATE:
      case fieldRenderTypes.TIMESTAMP: {
        let startDate = new Date()
        let endDate = new Date()
        if (filter.data && filter.data.startDate && isString(filter.data.startDate)) {
          try {
            if (filter.data && filter.data.startDate.length >= 10 && !filter.data.isTimestamp) {
              startDate = parseDate(filter.data.startDate.substring(0, 10))
            } else {
              startDate = parseISO(filter.data.startDate)
            }
          } catch (err) {
            console.log(err)
          }
        } else if (isDate(filter.data.startDate)) {
          startDate = filter.data.startDate
        }
        if (filter.data && filter.data.endDate && isString(filter.data.endDate)) {
          try {
            if (filter.data && filter.data.endDate.length >= 10 && !filter.data.isTimestamp) {
              endDate = parseDate(filter.data.endDate.substring(0, 10))
            } else {
              endDate = parseISO(filter.data.endDate)
            }
          } catch (err) {
            console.log(err)
          }
        } else if (isDate(filter.data.endDate)) {
          endDate = filter.data.endDate
        }
        let rangeLabel = `${fieldDefinition.label} between ${startDate.toLocaleDateString('en-US')} and ${endDate.toLocaleDateString('en-US')}`
        if (filter.data.rangeByName) {
          if (filter.data.rangeByName === 'thisMonth') {
            startDate = startOfMonth(new Date())
            endDate = endOfMonth(new Date())
            rangeLabel = `${fieldDefinition.label} Within This Month`
          } else if (filter.data.rangeByName === 'nextMonth') {
            startDate = startOfMonth(addMonths(new Date(), 1))
            endDate = endOfMonth(addMonths(new Date(), 1))
            rangeLabel = `${fieldDefinition.label} Within Next Month`
          } else if (filter.data.rangeByName === 'thisPeriod') {
            const { periodStart, periodEnd } = organization
            const salesPeriodLength = (organization && organization.organization && organization.organization.salesPeriodLength) || 1
            startDate = parseDate(periodStart)
            endDate = parseDate(periodEnd)
            // eslint-disable-next-line max-len
            rangeLabel = `${fieldDefinition.label} Within This ${salesPeriodLength === 3 ? 'Quarter' : 'Period'} ${startDate.toLocaleDateString('en-US')} - ${endDate.toLocaleDateString('en-US')}`
          } else if (filter.data.rangeByName === 'nextPeriod') {
            const { periodStart, periodEnd } = organization
            const salesPeriodLength = (organization && organization.organization && organization.organization.salesPeriodLength) || 1
            startDate = addMonths(parseDate(periodStart), salesPeriodLength)
            endDate = endOfMonth(addMonths(parseDate(periodEnd), salesPeriodLength))
            // eslint-disable-next-line max-len
            rangeLabel = `${fieldDefinition.label} Within Next ${salesPeriodLength === 3 ? 'Quarter' : 'Period'} ${startDate.toLocaleDateString('en-US')} - ${endDate.toLocaleDateString('en-US')}`
          } else if (filter.data.rangeByName === 'thisYear') {
            const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
            const month = getMonth(new Date()) + 1
            const year = getYear(new Date())
            if (month >= fiscalYearStartMonth) {
              startDate = parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d')
              endDate = addDays(addYears(startDate, 1), -1)
            } else {
              startDate = parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d')
              endDate = addDays(addYears(startDate, 1), -1)
            }
            rangeLabel = `${fieldDefinition.label} Within This Year`
            rangeLabel += ` ${startDate.toLocaleDateString('en-US')} - ${endDate.toLocaleDateString('en-US')}`
          } else if (filter.data.rangeByName === 'nextYear') {
            const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
            const month = getMonth(new Date()) + 1
            const year = getYear(new Date())
            if (month >= fiscalYearStartMonth) {
              startDate = addYears(parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), 1)
              endDate = addDays(addYears(startDate, 1), -1)
            } else {
              startDate = addYears(parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), 1)
              endDate = addDays(addYears(startDate, 1), -1)
            }
            rangeLabel = `${fieldDefinition.label} Within Next Year`
            rangeLabel += ` ${startDate.toLocaleDateString('en-US')} - ${endDate.toLocaleDateString('en-US')}`
          }
        }
        component = (
          <div
            key={`filter-date-range-${fieldDefinition.key}-${index}`}
            className="text-color-ffffff text-size-12px font-normal bg-color-1d4dcf rounded-full px-4 py-1 mr-2 last:mr-0 mt-1 flex items-center justify-between whitespace-nowrap"
            style={{ height: 24 }}>
            {rangeLabel}
            <span
              onClick={() => onRemoveFilter(filter)}
              className="focus:outline-none cursor-pointer">
              <FontAwesomeIcon icon="times" className="text-color-ffffff ml-2" />
            </span>
          </div>
        )
        break
      }
      case fieldRenderTypes.NUMBER: {
        const { min, max } = filter.data || {}
        let label = `No ${fieldDefinition.label} min or max`
        if (min && max) {
          label = `${fieldDefinition.label} min is ${formatValue(min, fieldDefinition.format)} and max is ${formatValue(max, fieldDefinition.format)}`
        } else if (min) {
          label = `${fieldDefinition.label} min is ${formatValue(min, fieldDefinition.format)}`
        } else if (max) {
          label = `${fieldDefinition.label} max is ${formatValue(max, fieldDefinition.format)}`
        }
        component = (
          <div
            key={`filter-number-range-${fieldDefinition.key}-${index}`}
            className="text-color-ffffff text-size-12px font-normal bg-color-1d4dcf rounded-full px-4 py-1 mr-2 last:mr-0 mt-1 flex items-center justify-between whitespace-nowrap"
            style={{ height: 24 }}>
            {label}
            <span onClick={() => onRemoveFilter(filter)} className="focus:outline-none cursor-pointer"><FontAwesomeIcon icon="times" className="text-color-ffffff ml-2" /></span>
          </div>
        )
        break
      }
      case fieldRenderTypes.STRING: {
        if (filter && filter.data && filter.data.selected && filter.data.selected.length > 0) {
          const selected = filter.data.selected
          component = (
            <React.Fragment key={`filter-option-${fieldDefinition.key}-${index}`}>
              {selected.map((option, i) => (
                <div
                  key={`filter-option-${fieldDefinition.key}-${index}-${i}`}
                  // eslint-disable-next-line max-len
                  className="text-color-ffffff text-size-12px font-normal bg-color-1d4dcf rounded-full px-4 py-1 mr-2 last:mr-0 mt-1 flex items-center justify-between whitespace-nowrap"
                  style={{ height: 24 }}>
                  {option.label}
                  <span
                    onClick={() => onRemoveOptionFilter(filter, option)}
                    className="focus:outline-none cursor-pointer">
                    <FontAwesomeIcon icon="times" className="text-color-ffffff ml-2" />
                  </span>
                </div>
              ))}
            </React.Fragment>
          )
        }
        break
      }
      case fieldRenderTypes.SINGLE_SELECT:
      case fieldRenderTypes.CHECKBOX:
      case fieldRenderTypes._STATUS:
      case fieldRenderTypes.CANOPY_STATUS: {
        const { selected = [] } = filter.data || {}
        const labelPrefix = (renderType === fieldRenderTypes.CHECKBOX)
          ? `${fieldDefinition.label}: `
          : ''
        component = (
          <React.Fragment key={`filter-option-${fieldDefinition.key}-${index}`}>
            {selected.map((option, i) => (
              <div
                key={`filter-option-${fieldDefinition.key}-${index}-${i}`}
                // eslint-disable-next-line max-len
                className="text-color-ffffff text-size-12px font-normal bg-color-1d4dcf rounded-full px-4 py-1 mr-2 last:mr-0 mt-1 flex items-center justify-between whitespace-nowrap"
                style={{ height: 24 }}>
                {`${labelPrefix}${option.label}`}
                <span onClick={() => onRemoveOptionFilter(filter, option)}
                  className="focus:outline-none cursor-pointer">
                  <FontAwesomeIcon icon="times" className="text-color-ffffff ml-2" />
                </span>
              </div>
            ))}
          </React.Fragment>
        )
        break
      }
      default:
    }
    return component
  }, [organization, onRemoveFilter, onRemoveOptionFilter, formatValue])

  const filters = useMemo(() => {
    const f = values(appliedFilters).filter((x) => {
      const key = x?.fieldDefinition?.key ?? ''
      return availableFields.includes(key.toLowerCase())
    })
    return f
  }, [appliedFilters, availableFields])

  const moveView = useCallback((dragIndex, hoverIndex) => {
    const views = cloneDeep(userViews)
    const dragItem = views[dragIndex]
    const hoverItem = views[hoverIndex]
    views[dragIndex] = hoverItem
    views[hoverIndex] = dragItem
    // optimistically set the views for local state
    setUserViews(views)
    // save the views to the db
    saveUserViews(views)
  }, [userViews, saveUserViews])

  return (
    <div>
      <DownloadModal
        modal={modal}
        requested={downloadRequested}
        available={available}
        complete={complete}
        errorText={downloadErrorText} />
      <div className="flex flex-wrap items-center">

        <CustomDragLayer
          itemRenderer={viewOptionRenderer}
          data={allViews} />

        {explicitViews.map((d) => (
          <ViewOption
            key={`ViewOption-${d.key}`}
            data={d}
            filterKey={filterKey}
            viewsList={allViews}
            selected={currentViewData && currentViewData.key === d.key}
            dragAndDropDisabled={true} />
        ))}

        {tenantViews.map((d) => (
          <ViewOption
            key={`ViewOption-${d.key}`}
            data={d}
            filterKey={filterKey}
            viewsList={allViews}
            selected={currentViewData && currentViewData.key === d.key}
            dragAndDropDisabled={true} />
        ))}

        {userViews.map((d, index) => (
          <ViewOption
            key={`ViewOption-${d.key}`}
            data={d}
            filterKey={filterKey}
            viewsList={allViews}
            selected={currentViewData && currentViewData.key === d.key}
            index={index}
            moveView={moveView} />
        ))}

        <ViewMenu
          filterKey={filterKey}
          viewsList={allViews}
          onDownloadRequest={requestDownload}
          currentView={currentViewData}
          currentSort={currentViewSort} />

      </div>

      {filters.length === 0
        ? <div className="mt-3 mb-2 text-size-14px text-color-818e93 font-weight-400">Add filters to create your custom view</div>
        : (
          <div className={className}>
            {filters.map((f, i) => renderRemoveFilter(f, i))}
          </div>
        )}
    </div>
  )
}

export default ViewsAndFilters
