import React, { useState, useRef, useEffect, useCallback } from 'react'
import Popover from '../popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'

export const dealSortingOptions = [
  { label: 'Close Date', sortField: 'closeDate.seconds' },
  { label: 'Deal Name', sortField: 'name' },
  { label: 'Deal Size', sortField: 'amount' },
  { label: 'Forecast', sortField: 'forecastCategory' },
  { label: 'Signals', sortField: 'insightCount' },
  { label: 'Stage', sortField: 'stageName' },
]

const MenuItem = ({ text, handleClick, width }) => {
  return (
    <a className="cursor-pointer select-none"
      onClick={handleClick}>
      <div style={{ width }}
        className="bg-color-fafafa hover:bg-gradient-green hover:text-color-fafafa px-6 py-2 whitespace-nowrap truncate">
        {text}
      </div>
    </a>
  )
}

const DealSortingControl = (props) => {
  const { handleSortFieldClick, handleSortOrderClick, selectedLabel, isDescendingSort } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const buttonRef = useRef(null)

  const [selectedItem, setSelectedItem] = useState({ label: selectedLabel })

  useEffect(() => {
    setSelectedItem({ label: selectedLabel })
  }, [selectedLabel])

  const [menuItemWidth, setMenuItemWidth] = useState(100)
  function handleClick(event) {
    setAnchorEl(event.currentTarget)
    setMenuItemWidth(buttonRef.current.clientWidth)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleMenuItemClickInternal = useCallback((menuItem) => {
    window.analytics.track('Deal Sort Dropdown', { sortField: menuItem.label })
    handleSortFieldClick(menuItem)
    setSelectedItem(menuItem)
    handleClose()
  }, [handleSortFieldClick])

  const handleSortOrderClickInternal = useCallback(() => {
    const newSort = !isDescendingSort
    window.analytics.track('Deal Sort Direction Button', { sort: newSort ? 'Descending' : 'Ascending' })
    handleSortOrderClick({ isDescendingSort: newSort, selectedLabel })
  }, [handleSortOrderClick, isDescendingSort, selectedLabel])

  return (
    <div className="flex justify-between items-center">
      <label className="text-color-4e5d7e">Sorted by</label>
      <div className="mx-2 w-56">
        <button ref={buttonRef}
          className="w-full flex justify-between items-center rounded border border-color-d6d9e6 bg-color-fafafa overflow-hidden focus:outline-none"
          onClick={handleClick}>
          <span className="px-4 py-2">{selectedItem.label}</span>
          <FontAwesomeIcon icon="chevron-down" className="mx-6" />
        </button>
        <Popover
          style={{ zIndex: 10000 }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}>
          {dealSortingOptions.map((m, i) => {
            return <MenuItem key={i} text={m.label} handleClick={() => handleMenuItemClickInternal(m)} width={menuItemWidth} />
          })}
        </Popover>
      </div>

      <Tippy
        offset={[0, 10]}
        duration={[0, 0]}
        content={<div className="px-4 py-3">{isDescendingSort ? 'Descending Order' : 'Ascending Order'}</div>}
        arrow={roundArrow}
        theme="canopy">
        <button
          className="h-full px-4 py-3 flex justify-between items-center rounded border border-color-d6d9e6 bg-color-fafafa overflow-hidden focus:outline-none whitespace-nowrap"
          onClick={handleSortOrderClickInternal}>
          <FontAwesomeIcon icon="sort-amount-down" color={isDescendingSort ? '#151d49' : '#b4b7c4'} />
          <FontAwesomeIcon icon="sort-amount-up-alt" color={isDescendingSort ? '#b4b7c4' : '#151d49'} className="fa-flip-horizontal" />
        </button>
      </Tippy>

    </div>
  )
}

export default DealSortingControl
