import React from 'react'
import { useCanvasItem } from '../../context/canvasItem'
import RealtimeViewContainer from '../realtimeView/realtimeViewContainer'

const CanvasItemContainer = ({ className, menuBar, children }) => {
  const { name, tileKey } = useCanvasItem()

  return (
    <RealtimeViewContainer
      className={className}
      menuBar={menuBar}
      name={name}
      tileKey={tileKey}>
      {children}
    </RealtimeViewContainer>
  )
}

export default CanvasItemContainer
