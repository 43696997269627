import React, { useCallback, useMemo } from 'react'
import { formatDate } from '../../formatters'
import { useRoutes } from '../../context/routes'
import Avatar from '../avatar/avatar'
import { resolveCompanyImageUrl } from '../../lib/image'
import classNames from 'classnames'
import get from 'lodash/get'
import Icon, { iconType } from '../icon'
import triangleIcon from '../../assets/triangle-sm.png'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import { useHistory } from 'react-router-dom'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useGroups } from '../../context/groups'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const DealListItem = (props) => {
  const { opportunity, columnClasses, handleItemClick, fieldHistoryHoverDates, disabled } = props
  const {
    id,
    name,
    stageName,
    forecastCategory,
    amount,
    ownerId,
    statusColor,
    statusLabel,
    closeDateLabel,
    hasCloseDate,
    status,
    isClosed,
    daysUntilCloseRelativeLabelColor,
    daysUntilCloseRelativeLabel,
    closeDate,
    insightCount = 0,
    changeAmount = 0,
    versionsList = [],
  } = opportunity

  const history = useHistory()

  const { routes } = useRoutes()

  const { formatCurrency } = useFormatCurrency()

  const lastVersion = get(versionsList, '[0]')

  const { findGroupById } = useGroups()

  const user = findGroupById(ownerId) || {}
  const { loggedInUser } = useLoggedInUser()

  const ownerName = useMemo(() => {
    return get(opportunity, 'owner.name') || (ownerId === loggedInUser.id ? loggedInUser.name : user.name)
  }, [opportunity, loggedInUser, user])

  const companyImageUrl = resolveCompanyImageUrl(opportunity)

  const onClick = useCallback(() => {
    if (disabled) {
      return
    }
    handleItemClick && handleItemClick()
    window.analytics.track('Deals List Item Clicked')
    history.push(routes.deal.replace(':opportunityId', id))
  }, [opportunity])

  const showChange = useMemo(() => {
    const change = {
      stage: false,
      forecast: false,
      dealSize: false,
      closeDate: false,
      owner: false,
    }

    if (lastVersion) {
      change.stage = stageName !== lastVersion.stageName
      change.forecast = forecastCategory !== lastVersion.forecastCategory
      change.dealSize = amount !== lastVersion.amount
      change.closeDate = get(closeDate, 'seconds') !== get(lastVersion, 'closeDate.seconds')
      change.owner = ownerId !== lastVersion.ownerId
    } else {
      change.changeAmount = changeAmount !== 0
    }

    return change
  }, [opportunity])

  const iconName = useMemo(() => {
    if (lastVersion) {
      return amount > get(lastVersion, 'amount', 0) ? 'arrow-up' : 'arrow-down'
    } else if (changeAmount !== 0) {
      return changeAmount > 0 ? 'arrow-up' : 'arrow-down'
    }
  }, [opportunity])

  const iconColor = useMemo(() => {
    if (lastVersion) {
      return amount > get(lastVersion, 'amount', 0) ? '#4de578' : '#ff4947'
    } else if (changeAmount !== 0) {
      return changeAmount > 0 ? '#4de578' : '#ff4947'
    }
  }, [opportunity])

  const fieldHistoryHover = useCallback((startDate, endDate, startValue, endValue) => {
    return (
      <div className="px-4 py-3">
        <div className="flex justify-between my-2">
          <div className="mr-8 font-bold">{endDate}</div>
          <div className="text-right font-bold whitespace-nowrap truncate">{endValue}</div>
        </div>
        <div className="flex justify-between my-2">
          <div className="mr-8 font-bold text-color-b0bac9">{startDate}</div>
          <div className="text-right font-bold text-color-b0bac9 whitespace-nowrap truncate">{startValue}</div>
        </div>
      </div>
    )
  }, [opportunity])

  return (
    <div className={classNames('card tr mb-4 hover:bg-gradient-white-to-blue pointer-events-auto', { 'cursor-pointer': !disabled })} onClick={onClick}>
      <div className={columnClasses[0]}>
        <div className="flex items-center">
          <Avatar className="w-10 h-10 mr-2" imageUrl={companyImageUrl} text={name.charAt(0)} />
          <div className="mr-2">
            <div className="text-size-15px text-color-2e384d font-bold">{name}</div>
            <div className="flex flex-row flex-nowrap items-center">
              <div className="w-2 h-2 mr-1 rounded-full flex-shrink-0" style={{ backgroundColor: statusColor }} />
              <div className="text-size-12px text-color-8097b1 font-normal">{statusLabel}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={columnClasses[1]}>
        <div className="flex items-center justify-end">
          {showChange.stage && (
            <Tippy
              offset={[0, 10]}
              duration={[0, 0]}
              maxWidth={800}
              content={fieldHistoryHover(get(fieldHistoryHoverDates, 'startDate', '-'), get(fieldHistoryHoverDates, 'endDate', '-'), lastVersion.stageName, stageName)}
              arrow={roundArrow}
              zIndex={999999999}
              theme="canopy">
              <div className="flex-shrink-0"><Icon type={iconType.IMAGE} src={triangleIcon} className="mx-1" /></div>
            </Tippy>
          )}
          <div className="text-size-15px text-color-2e384d">{stageName}</div>
        </div>
      </div>
      <div className={columnClasses[2]}>
        <div className="flex items-center justify-end">
          {showChange.forecast && (
            <Tippy
              offset={[0, 10]}
              duration={[0, 0]}
              maxWidth={800}
              content={fieldHistoryHover(
                get(fieldHistoryHoverDates, 'startDate', '-'),
                get(fieldHistoryHoverDates, 'endDate', '-'),
                lastVersion.forecastCategory,
                forecastCategory
              )}
              arrow={roundArrow}
              zIndex={999999999}
              theme="canopy">
              <div className="flex-shrink-0"><Icon type={iconType.IMAGE} src={triangleIcon} className="mx-1" /></div>
            </Tippy>
          )}
          <div className="text-size-15px text-color-2e384d">{forecastCategory}</div>
        </div>
      </div>
      <div className={columnClasses[3]}>
        <div className="flex items-center justify-end">
          {showChange.dealSize && (
            <Tippy
              offset={[0, 10]}
              duration={[0, 0]}
              maxWidth={800}
              content={fieldHistoryHover(
                get(fieldHistoryHoverDates, 'startDate', '-'),
                get(fieldHistoryHoverDates, 'endDate', '-'),
                formatCurrency(get(lastVersion, 'amount', 0)),
                formatCurrency(amount)
              )}
              arrow={roundArrow}
              zIndex={999999999}
              theme="canopy">
              <div className="flex-shrink-0"><Icon type={iconType.FONTAWESOME} iconName={iconName} color={iconColor} size="sm" className="mx-1" /></div>
            </Tippy>
          )}
          {showChange.changeAmount && <div className="flex-shrink-0"><Icon type={iconType.FONTAWESOME} iconName={iconName} color={iconColor} size="sm" className="mx-1" /></div>}
          {formatCurrency(amount)}
        </div>
      </div>
      <div className={columnClasses[4]}>
        {closeDateLabel
          ? (
            <>
              <div className="flex items-center justify-end">
                {showChange.closeDate && (
                  <Tippy
                    offset={[0, 10]}
                    duration={[0, 0]}
                    maxWidth={800}
                    content={fieldHistoryHover(
                      get(fieldHistoryHoverDates, 'startDate', '-'),
                      get(fieldHistoryHoverDates, 'endDate', '-'),
                      get(lastVersion, 'closeDate.seconds', 0) !== 0 ? formatDate(lastVersion.closeDate.seconds) : '-',
                      get(closeDate, 'seconds', 0) !== 0 ? formatDate(closeDate.seconds) : '-'
                    )}
                    arrow={roundArrow}
                    zIndex={999999999}
                    theme="canopy">
                    <div className="flex-shrink-0"><Icon type={iconType.IMAGE} src={triangleIcon} className="mx-1" /></div>
                  </Tippy>
                )}
                <div className="text-size-15px text-color-2e384d">{closeDateLabel}</div>
              </div>
              {hasCloseDate && status !== 'lost' && status !== 'won' && !isClosed
                ? (
                  <div className={`text-size-12px ${daysUntilCloseRelativeLabelColor}`}>
                    {daysUntilCloseRelativeLabel}
                  </div>
                ) : null}
            </>
          )
          : <div className="text-size-15px text-color-2e384d">{get(closeDate, 'seconds', 0) !== 0 ? formatDate(closeDate.seconds) : '-'}</div>}
      </div>
      <div className={columnClasses[5]}>
        <div className="flex items-center justify-end">
          {/* can't show the owner change hover until the owner object is populated in the versionsList */}
          {/* {showChange.owner && <Tippy
            offset={[0, 10]}
            duration={[0, 0]}
            maxWidth={800}
            content={fieldHistoryHover(get(fieldHistoryHoverDates, 'startDate', '-'), get(fieldHistoryHoverDates, 'endDate', '-'), get(lastVersion, 'owner.name', '-'), ownerName)}
            arrow={roundArrow}
            zIndex={999999999}
            theme={'canopy'}>
            <div className="flex-shrink-0"><Icon type={iconType.IMAGE} src={triangleIcon} className="mx-1" /></div>
          </Tippy>} */}
          <div className="text-size-15px text-color-2e384d">{ownerName}</div>
        </div>
      </div>
      <div className={columnClasses[6]}>
        {insightCount > 0
          && (
            <div
              className="flex items-center justify-center w-6 h-6 text-xs rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-gradient-green">
              {insightCount}
            </div>
          )}
      </div>
    </div>
  )
}

export default DealListItem
