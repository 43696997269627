import { forEach, has } from 'lodash'
import { secondsToFormattedDate } from '../../lib/dateFns'
import evaluatex from 'evaluatex'
import Call from '../../lib/call'

const getRawForecastColumnCall = (...args) => {
  const [columnId, forecast = {}, forecastLocal = {}] = args

  const { current = {}, previous = {}, autoCalculated } = forecast
  const { categoryCalls = {}, defaultCategoryCalls = {} } = current
  const { localCategoryCalls = {} } = forecastLocal

  if (has(localCategoryCalls, columnId)) {
    return localCategoryCalls[columnId]?.value
  }
  if (autoCalculated?.[columnId]) {
    return defaultCategoryCalls[columnId]?.value ?? 0
  }
  if (categoryCalls[columnId]?.valid) {
    return categoryCalls[columnId]?.value
  }
  if (previous?.categoryCalls?.[columnId]?.valid) {
    return previous.categoryCalls[columnId]?.value
  }

  return defaultCategoryCalls[columnId]?.value ?? 0
}

const getRawRepForecastColumnCall = (...args) => {
  const [columnId, forecast = {}] = args

  const { current = {}, previous = {}, autoCalculated } = forecast
  const { categoryCalls = {}, defaultCategoryCalls = {} } = current

  if (autoCalculated?.[columnId]) {
    if (categoryCalls[columnId]?.valid && categoryCalls[columnId]?.forecastId) {
      return categoryCalls[columnId]?.value
    }
    return defaultCategoryCalls[columnId]?.value ?? 0
  }
  if (categoryCalls[columnId]?.valid) {
    return categoryCalls[columnId]?.value
  }
  if (previous?.categoryCalls?.[columnId]?.valid) {
    return previous.categoryCalls[columnId]?.value
  }

  return defaultCategoryCalls[columnId]?.value ?? 0
}

export function getCurrentForecastCategoryCall(...args) {
  const [id, forecast, forecastLocal, isRep] = args
  const columnCallFn = isRep ? getRawRepForecastColumnCall : getRawForecastColumnCall

  const {
    displayFormulas = {},
    valueFormatters = {},
    valueInitializers = {}
  } = forecast

  const {
    latexFormula,
    variableMappingMap = [] } = displayFormulas[id] ?? {}

  const variableMapping = {}
  new Map(variableMappingMap).forEach((configId, varName) => {
    const value = columnCallFn(configId, forecast, forecastLocal)
    variableMapping[varName] = value
  })

  let fn
  if (latexFormula) {
    try {
      fn = evaluatex(latexFormula, {}, { latex: true })
    } catch (err) {
      console.log('Formula expression not valid: ', latexFormula, err)
    }
  }

  const rawColumnCall = columnCallFn(id, forecast, forecastLocal)

  const targetCall = fn
    ? fn(variableMapping)
    : rawColumnCall

  const call = new Call(targetCall, valueInitializers[id])
  call.inherentValue = rawColumnCall
  call.formatter = valueFormatters[id]

  return call
}

export function getRawPreviousForecastCategoryCall(forecastCategoryName, forecast = {}) {
  const { previous = {} } = forecast
  const { categoryCalls = {}, defaultCategoryCalls = {} } = previous
  if (categoryCalls[forecastCategoryName]?.valid) {
    return categoryCalls[forecastCategoryName]?.value
  } else {
    return defaultCategoryCalls[forecastCategoryName]?.value || 0
  }
}

export function getPreviousForecastCategoryCall(id, forecast = {}) {
  const {
    displayFormulas = {},
    valueFormatters = {},
    valueInitializers = {} } = forecast

  const {
    latexFormula,
    variableMappingMap = [] } = displayFormulas[id] ?? {}

  const variableMapping = {}
  new Map(variableMappingMap).forEach((configId, varName) => {
    const value = getRawPreviousForecastCategoryCall(configId, forecast)
    variableMapping[varName] = value
  })

  let fn
  if (latexFormula) {
    try {
      fn = evaluatex(latexFormula, {}, { latex: true })
    } catch (err) {
      console.log('Formula expression not valid: ', latexFormula, err)
    }
  }

  const rawColumnCall = getRawPreviousForecastCategoryCall(id, forecast)

  const targetCall = fn
    ? fn(variableMapping)
    : rawColumnCall

  const call = new Call(targetCall, valueInitializers[id])
  call.inherentValue = rawColumnCall
  call.formatter = valueFormatters[id]

  return call
}

export function getRawHistoricalForecastCategoryCall(forecastCategoryName, forecast = {}, useDefault) {
  const { current = {} } = forecast
  const { categoryCalls = {}, defaultCategoryCalls = {} } = current

  if (useDefault) {
    return defaultCategoryCalls[forecastCategoryName]?.value || 0
  }

  if (categoryCalls[forecastCategoryName]?.valid) {
    return categoryCalls[forecastCategoryName]?.value
  }

  return defaultCategoryCalls[forecastCategoryName]?.value || 0
}

export function getHistoricalForecastCategoryCall(id, forecast = {}, useDefault) {
  const {
    displayFormulas = {},
    valueFormatters = {},
    valueInitializers = {} } = forecast

  const {
    latexFormula,
    variableMappingMap = [] } = displayFormulas[id] ?? {}

  const variableMapping = {}
  new Map(variableMappingMap).forEach((configId, varName) => {
    const value = getRawHistoricalForecastCategoryCall(configId, forecast, useDefault)
    variableMapping[varName] = value
  })

  let fn
  if (latexFormula) {
    try {
      fn = evaluatex(latexFormula, {}, { latex: true })
    } catch (err) {
      console.log('Formula expression not valid: ', latexFormula, err)
    }
  }

  const rawColumnCall = getRawHistoricalForecastCategoryCall(id, forecast, useDefault)

  const targetCall = fn
    ? fn(variableMapping)
    : rawColumnCall

  const call = new Call(targetCall, valueInitializers[id])
  call.inherentValue = rawColumnCall
  call.formatter = valueFormatters[id]

  return call
}

export function getManagersTeamForecastCategoryCall(forecastCategoryName, managerTeamForecasts, forecastByUserLocal) {
  const rollupCall = new Call(0)
  forEach(managerTeamForecasts, (f) => {
    const { user, info } = f
    const ownerId = user.id
    const forecast = info
    const forecastLocal = forecastByUserLocal?.[ownerId]
    const userCall = getCurrentForecastCategoryCall(forecastCategoryName, forecast, forecastLocal)
    rollupCall.update(rollupCall.value + userCall.value)
    rollupCall.formatter = userCall.formatter
    rollupCall.initializer = userCall.initializer
  })
  return new Call(rollupCall)
}

export function getForecastSubmittedAt(forecast, format = 'M/d/yyyy h:mmaaa') {
  let submittedAt
  if (forecast && forecast.submittedAt && forecast.submittedAt.value && forecast.submittedAt.value.seconds) {
    try {
      submittedAt = secondsToFormattedDate(forecast.submittedAt.value.seconds, format).toLowerCase()
    } catch (err) {
      console.log(`Unable to parse unix time ${forecast.submittedAt.value}`)
    }
  }
  return submittedAt
}
