import { createReducer } from '@reduxjs/toolkit'
import {
  clearChangedInputs,
  clearForecastSimulationWorkspace,
  clearFormulaInputs,
  clearHiddenScenarios,
  commitChangedInput,
  deleteScenario,
  setActiveScenarioKey,
  setForecastSimulationWorkspace,
  setRunFailed,
  toggleHiddenScenario,
  updateScenarioLabel,
  upsertScenario
} from '../actions'
import { getForecastSimulationLevelOptionsSuccess, getForecastSimulationsAvailableSuccess } from '../actions/reportingService'
import { every, filter, findIndex, forEach, get, has, max, orderBy, remove } from 'lodash'
import { baselineName, getColor } from '../components/scenarioPlanner/constants'

const initialState = []
export const forecastSimulationsAvailable = createReducer(initialState, {
  [clearFormulaInputs.type]: (state, action) => initialState,
  [getForecastSimulationsAvailableSuccess.type]: (state, action) => action.payload.itemsList,
})

const forecastSimulationLevelOptionsInitialState = {
  groupLevelOptionsList: [],
  personLevelOptionsList: [],
}
export const forecastSimulationLevelOptions = createReducer(forecastSimulationLevelOptionsInitialState, {
  [clearFormulaInputs.type]: (state, action) => forecastSimulationLevelOptionsInitialState,
  [getForecastSimulationLevelOptionsSuccess.type]: (state, action) => {
    return {
      groupLevelOptionsList: orderBy(get(action.payload, 'groupLevelOptionsList', []), (o) => o.name),
      personLevelOptionsList: orderBy(get(action.payload, 'personLevelOptionsList', []), (o) => o.name),
    }
  },
})

const forecastSimulationWorkspaceInitialState = {
  hasAllOutput: false,
  scenariosList: [],
}
const enrichState = (state) => {
  state.hasAllOutput = state.scenariosList.length > 0 && every(state.scenariosList, (s) => s.output && s.output !== undefined)

  forEach(state.scenariosList, (scenario, index) => {
    scenario.color = getColor(index)
    scenario.isBaseline = scenario.label === baselineName

    if (scenario.output) {
      scenario.output.numericValuesList = scenario.output.numericValuesList.map((item) => {
        item.value = Math.floor(item.value)
        return item
      })

      const values = {}
      forEach(filter(get(scenario, 'output.numericValuesList', []), (n) => n.callout), (item) => {
        values[item.callout] = item
      })
      scenario.output.calloutValues = values
    }

    forEach(scenario.input.categoriesList, (category) => {
      category.maxValue = max(category.fieldsList.map((f) => f.value))
    })
  })
}
export const forecastSimulationWorkspace = createReducer(forecastSimulationWorkspaceInitialState, {
  [clearForecastSimulationWorkspace.type]: (state, action) => forecastSimulationWorkspaceInitialState,
  [setForecastSimulationWorkspace.type]: (state, action) => {
    state = action.payload
    enrichState(state)
    return state
  },
  [updateScenarioLabel.type]: (state, action) => {
    const scenario = action.payload
    const { key, label } = scenario
    const index = findIndex(state.scenariosList, (s) => s.key === key)
    if (index !== -1) {
      state.scenariosList[index].label = label
    }
    return state
  },
  [setRunFailed.type]: (state, action) => {
    const scenario = action.payload
    const { key, failed = true } = scenario
    const index = findIndex(state.scenariosList, (s) => s.key === key)
    if (index !== -1) {
      state.scenariosList[index].runFailed = failed
    }
    return state
  },
  [upsertScenario.type]: (state, action) => {
    const scenario = action.payload
    const index = findIndex(state.scenariosList, (s) => s.key === scenario.key)
    if (index !== -1) {
      state.scenariosList[index] = scenario
    } else {
      state.scenariosList.push(scenario)
    }
    enrichState(state)
    return state
  },
  [deleteScenario.type]: (state, action) => {
    const key = action.payload
    remove(state.scenariosList, (scenario) => scenario.key === key)
    enrichState(state)
    return state
  },
})

const activeScenarioKeyInitialState = ''
export const activeScenarioKey = createReducer(activeScenarioKeyInitialState, {
  [clearForecastSimulationWorkspace.type]: (state, action) => activeScenarioKeyInitialState,
  [setActiveScenarioKey.type]: (state, action) => action.payload,
})

const changedInputsInitialState = []
export const changedInputs = createReducer(changedInputsInitialState, {
  [clearChangedInputs.type]: (state, action) => changedInputsInitialState,
  [commitChangedInput.type]: (state, action) => {
    const changeSet = action.payload
    remove(state, (c) => c.categoryKey === changeSet.categoryKey && c.field.key === changeSet.field.key)
    state.push(changeSet)
    return state
  },
})

const hiddenScenariosInitialState = {}
export const hiddenScenarios = createReducer(hiddenScenariosInitialState, {
  [clearHiddenScenarios.type]: (state, action) => hiddenScenariosInitialState,
  [toggleHiddenScenario.type]: (state, action) => {
    state[action.payload.key] = action.payload.isHidden
    return state
  },
})
