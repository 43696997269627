import { createAction } from '@reduxjs/toolkit'

export const getFeatureSettingsKey = 'api/settings/getFeatureSettings'
export const getFeatureSettingsRequested = createAction(`${getFeatureSettingsKey}/requested`)
export const getFeatureSettingsSuccess = createAction(`${getFeatureSettingsKey}/success`)
export const getFeatureSettingsFailed = createAction(`${getFeatureSettingsKey}/failed`)

export const getCrmSettingsKey = 'api/settings/getCrmSettings'
export const getCrmSettingsRequested = createAction(`${getCrmSettingsKey}/requested`)
export const getCrmSettingsSuccess = createAction(`${getCrmSettingsKey}/success`)
export const getCrmSettingsFailed = createAction(`${getCrmSettingsKey}/failed`)
