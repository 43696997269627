import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import SignalsList from './signalsList'
import { signalTotalCountByIdSelector, apiSuccessSelector } from '../../selectors'
import { getSignalsByUser } from '../../services/signalService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSignalsByUserKey } from '../../actions/signalService'
import { clearSignals } from '../../actions'
import EmptyState from '../empty/emptyState'
import StarLg from '../svg/starLg'
import AnimatedLoader from '../loaders/animatedLoader'
import { useGroups } from '../../context/groups'

const RepSignalsLegacy = (props) => {
  const params = useParams()
  const { userId } = params

  const dispatch = useDispatch()

  const signals = useSelector((state) => state.signalsByUser)
  const signalsCount = signals.length

  const [showEmptyList, setShowEmptyList] = useState(false)

  const { findGroupById } = useGroups()

  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getSignalsByUserKey))
  const totalCount = useSelector((state) => signalTotalCountByIdSelector(state, userId))
  const showWaypoint = signalsCount < totalCount

  const filterHeaders = [
    {
      key: 'filter|definition.level|eq',
      value: 'person'
    },
    {
      key: 'filter|definition.name|nstartswith',
      value: 'metric_'
    },
  ]

  const handleWaypointEntered = useCallback(() => {
    window.analytics.track('Load More Rep Signals')
    dispatch(getSignalsByUser(userId, signalsCount, 20, filterHeaders))
  }, [signalsCount])

  useEffect(() => {
    dispatch(clearSignals())
    dispatch(getSignalsByUser(userId, 0, 20, filterHeaders))
  }, [])

  const [init, setInit] = useState(false)
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    setShowLoader(!apiSuccess && !init)

    if (apiSuccess) {
      setInit(true)
    }
  }, [init, apiSuccess])

  useEffect(() => {
    setShowEmptyList(apiSuccess && signals.length === 0)
  }, [apiSuccess, signals])

  const user = findGroupById(userId)
  const subHeader = user ? `${user.firstName} doesn't have any signals to show.` : ''

  if (showEmptyList) {
    return (
      <div className="flex justify-center pt-2">
        <EmptyState iconControl={<StarLg />} header="No Signals" subHeader={subHeader} />
      </div>
    )
  } else {
    return (
      <>

        {showLoader
          && (
            <div className="h-screen">
              <AnimatedLoader
                className="mt-16"
                title="Loading Signals"
                subTitle="Please wait..."
                maxWidth={300} />
            </div>
          )}

        <SignalsList
          signals={signals}
          keyPrefix={`repSignals-${userId}`}
          avatar={true}
          itemClassName="w-10/12 mx-auto flex" />

        {showWaypoint && (
          <div className="flex justify-center my-4">
            <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
          </div>
        )}
        {showWaypoint && <Waypoint onEnter={handleWaypointEntered} />}

      </>
    )
  }
}

export default RepSignalsLegacy
