export const sizeOptions = ['xs', 'sm', 'md', 'lg', 'xl']

export const defaultBreakpoints = [
  { key: 'xs', breakpoint: 640 },
  { key: 'sm', breakpoint: 768 },
  { key: 'md', breakpoint: 1024 },
  { key: 'lg', breakpoint: 1280 },
  { key: 'xl', breakpoint: 1536 },
]

export const getComputedBreakpoint = (width, height, breakpoints = []) => {
  let i = 0
  let b = breakpoints[i]
  const len = breakpoints.length
  while (i < len) {
    b = breakpoints[i]
    if (b.breakpoint > width) {
      break
    }
    i += 1
  }
  i = (i - 1) < 0 ? 0 : (i - 1)
  b = breakpoints[i]
  return b
}
