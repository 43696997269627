import { createReducer } from '@reduxjs/toolkit'
import { getAllPeopleSuccess } from '../actions/personService'
import { clearPeople } from '../actions'
import orderBy from 'lodash/orderBy'

const initialState = []

export const people = createReducer(initialState, {
  [clearPeople.type]: (state, action) => initialState,
  [getAllPeopleSuccess.type]: (state, action) => orderBy(action.payload.peopleList, [(p) => p.name.toLowerCase()], ['asc']),
})
