import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { toGetGroupSubTreeRequest } from '../grpc/converters'
import { useGrpcCallback } from '../grpc'
import { useTenantInfo } from './tenantInfo'
import flattenDeep from 'lodash/flattenDeep'

const GroupsContext = React.createContext()

export function GroupsProvider({ children }) {
  const [key, setKey] = useState(guid())
  const [groups, setGroups] = useState({})
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState()
  const [initialFetch, setInitialFetch] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const { tenantInfo } = useTenantInfo()
  const { appHierarchyOptions } = tenantInfo

  const groupSubtreeRequest = useMemo(() => {
    if (!appHierarchyOptions) return
    const {
      activeUsersOnly: activeUsers,
      simplifyHierarchy: simplify,
      useManagerNamesInHierarchy: useManagerNames
    } = appHierarchyOptions

    return toGetGroupSubTreeRequest({
      maxDepth: -1,
      hydrateUsers: true,
      hydrateCrmRoles: true,
      activeUsers,
      simplify,
      useManagerNames
    })
  }, [appHierarchyOptions])

  const getGroupSubTrees = useGrpcCallback({
    onSuccess: (obj) => {
      setSuccess(true)
      setGroups(obj)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
      setError(err)
    },
    onFetch: () => {
      setInitialFetch(true)
      setIsFetching(true)
    },
    grpcMethod: 'getGroupSubTreeWithContext',
    debug: false
  }, [])

  useEffect(() => {
    if (groupSubtreeRequest) {
      getGroupSubTrees(groupSubtreeRequest)
    }
  }, [getGroupSubTrees, groupSubtreeRequest, key])

  const rootGroupId = useMemo(() => {
    return (groups && groups.rootsList && groups.rootsList[0] && groups.rootsList[0].groupId) || ''
  }, [groups])

  const subTrees = useMemo(() => {
    const { rootsList = [] } = groups
    return rootsList.map(({ subTree = {} }) => subTree)
  }, [groups])

  const getSubTreesForId = useCallback((id) => {
    let subTree = null
    const findRecursive = (trees) => {
      return trees.find((tree) => {
        const { group, childrenList = [] } = tree
        if (group.id === id) {
          subTree = tree
        }
        return findRecursive(childrenList)
      })
    }
    findRecursive(subTrees)
    return subTree
  }, [subTrees])

  const searchInTree = useCallback((s = '', tree, showReps) => {
    const search = s.trim().toLowerCase()
    if (!search) {
      return
    }
    const {
      childrenList: cl = [],
      membersList: ml = [],
      group: g = {}
    } = tree

    const { name: groupName = '' } = g

    const matches = (str, sea) => {
      return str.toLowerCase().includes(sea)
    }

    const cls = cl.map((c) => searchInTree(search, c, showReps))
      .filter((c) => c.childrenList.length || c.group?.id || c.membersList.length)
    const childrenList = cls.filter((c) => {
      const { membersList: _ml, group: _g, childrenList: _cl } = c
      return (_ml.length || group?.name || _cl.length) ? searchInTree(search, c, showReps) : []
    })
    const membersList = showReps ? ml.filter(({ name }) => matches(name, search)) : []
    const groupNameMatch = !showReps ? matches(groupName, search) : false
    const showGroup = membersList.length || childrenList.length || groupNameMatch
    const group = (showGroup && Object.keys(g).length) ? g : undefined

    return {
      childrenList,
      group,
      membersList
    }
  }, [])

  const flattened = useMemo(() => {
    const getGroupAndMembers = (tree) => {
      const {
        group,
        childrenList = [],
        membersList = [] } = tree

      const _children = childrenList.map(getGroupAndMembers)
      const hasChildren = !!(_children.length || membersList.length)
      const childrenIdsList = []
      const membersIdsList = []

      // our current group structure only has EITHER members OR children, not both
      childrenList.forEach(({ group }) => childrenIdsList.push(group?.id))
      membersList.forEach(({ id }) => membersIdsList.push(id))

      return flattenDeep([
        {
          ...group,
          childrenIdsList,
          hasChildren,
          membersIdsList
        },
        membersList,
        _children
      ])
    }
    return flattenDeep(subTrees.map(getGroupAndMembers))
  }, [subTrees])

  const findGroupById = useCallback((userId, options = {}) => {
    const {
      includeDescendants = false,
      depth = 0
    } = options

    const found = flattened.find(({ id }) => userId === id)
    if (includeDescendants && found?.hasChildren) {
      const {
        membersIdsList = [],
        childrenIdsList = []
      } = found

      const newDepth = depth && depth - 1
      const opts = {
        ...options,
        includeDescendants: !!newDepth,
        depth: newDepth
      }

      found.childrenList = childrenIdsList.map((cId) => findGroupById(cId, opts))
      found.membersList = membersIdsList.map((mId) => findGroupById(mId, opts))
    }
    return found
  }, [flattened])

  const invalidate = useCallback(() => {
    setKey(guid())
    setGroups([])
  }, [])

  const contextValue = useMemo(() => {
    return {
      error,
      flattened,
      getSubTreesForId,
      groups,
      searchInTree,
      invalidate,
      key,
      rootGroupId,
      setGroups,
      subTrees,
      success,
      findGroupById,
      initialFetch,
      isFetching,
    }
  }, [
    error,
    flattened,
    getSubTreesForId,
    groups,
    invalidate,
    key,
    rootGroupId,
    searchInTree,
    setGroups,
    subTrees,
    findGroupById,
    success,
    initialFetch,
    isFetching,
  ])

  return <GroupsContext.Provider value={contextValue}>{children}</GroupsContext.Provider>
}

export function useGroups() {
  const context = React.useContext(GroupsContext)
  if (context === undefined) {
    throw new Error('useGroups must be used within a GroupsProvider')
  }
  return context
}
