import React, { useMemo } from 'react'
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { featureSettingSelector } from '../../selectors'
import { featureNames } from '../../constants/featureNames'
import { useGroups } from '../../context/groups'
import { useRoutes } from '../../context/routes'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useLoggedInUser } from '../../context/loggedInUser'
import GroupHeaderControl from '../header/groupHeaderControl'
import TabMenu from '../tabMenu/tabMenu'
import RepCoaching from '../coaching/repCoaching'
import RepDeals from '../deals/repDeals'
import RepScorecard from '../scorecard/repScorecard'
import RepPipelineCanvas from '../canvas/repPipelineCanvas'
import RepSignalsCanvas from '../canvas/repSignalsCanvas'

const PerformanceByUser = (props) => {
  const { loggedInUser } = useLoggedInUser()
  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()
  const { findGroupById } = useGroups()
  const history = useHistory()
  const params = useParams()
  const coachingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.coaching }))

  const { userId } = params
  const performanceByUserUrl = routes.performanceByUser.replace(':userId', userId)
  const performanceByUserSignalsUrl = routes.performanceByUserSignals.replace(':userId', userId)
  const performanceByUserCoachUrl = routes.performanceByUserCoach.replace(':userId', userId)
  const performanceByUserDealsUrl = routes.performanceByUserDeals.replace(':userId', userId)

  const signalsMatch = useRouteMatch(performanceByUserSignalsUrl)
  const coatchMatch = useRouteMatch(performanceByUserCoachUrl)
  const dealsMatch = useRouteMatch(performanceByUserDealsUrl)

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessNote
    )
  }, [checkPermissions])

  const coachingEnabled = useMemo(() => {
    return permissions.CanAccessNote && (coachingFeature?.payload?.enabled ?? false)
  }, [permissions, coachingFeature])

  const name = useMemo(() => {
    const user = findGroupById(userId)
    if (user) {
      return user.name ?? ''
    } else if (userId === loggedInUser?.id) {
      return loggedInUser?.name ?? ''
    } else {
      return '-'
    }
  }, [findGroupById, userId, loggedInUser])

  const options = useMemo(() => {
    return [
      {
        index: 0,
        name: 'Scorecard',
        canShow: () => true,
        onClick: () => history.push(performanceByUserUrl)
      },
      {
        index: 1,
        name: 'Signals',
        canShow: () => true,
        onClick: () => history.push(performanceByUserSignalsUrl)
      },
      {
        index: 2,
        name: 'Coach',
        canShow: () => coachingEnabled,
        onClick: () => history.push(performanceByUserCoachUrl)
      },
      {
        index: 3,
        name: 'Deals',
        canShow: () => true,
        onClick: () => history.push(performanceByUserDealsUrl)
      }
    ]
  }, [history, performanceByUserUrl, performanceByUserSignalsUrl, performanceByUserCoachUrl, performanceByUserDealsUrl, coachingEnabled])

  const tabIndex = useMemo(() => {
    if (signalsMatch) return 1
    else if (coatchMatch) return 2
    else if (dealsMatch) return 3
    else return 0
  }, [signalsMatch, coatchMatch, dealsMatch])

  return (
    <>
      <GroupHeaderControl
        showReps={true}
        title={name}
        showSearch={false} />

      <div className="main-body" style={{ minHeight: '100vh' }}>

        <TabMenu
          tabIndex={tabIndex}
          options={options}
          tabPrefix="repPerformance" />

        <RepPipelineCanvas
          userId={userId} />

        <Switch>

          <Route
            path={performanceByUserSignalsUrl}
            render={(routeProps) => (
              <div className="pt-6">
                <RepSignalsCanvas
                  userId={userId}
                  {...routeProps} />
              </div>
            )} />

          {coachingEnabled && (
            <Route
              path={performanceByUserCoachUrl}
              render={(routeProps) => (
                <div className="pt-10">
                  <RepCoaching
                    userId={userId}
                    {...routeProps} />
                </div>
              )} />
          )}

          <Route
            path={performanceByUserDealsUrl}
            render={(routeProps) => (
              <div className="-ml-10 pt-6">
                <RepDeals
                  userId={userId}
                  {...routeProps} />
              </div>
            )} />

          <Route
            path={performanceByUserUrl}
            render={(routeProps) => (
              <div className="pt-6">
                <RepScorecard
                  userId={userId} />
              </div>
            )} />

        </Switch>

      </div>
    </>
  )
}

export default PerformanceByUser
