import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import DealDetailDock from './dealDetailDock'
import ForecastingDock from '../forecasting/dock/forecastingDock'

const PipelineDock = (props) => {
  const dock = useSelector((state) => state.dock)

  const renderDock = useCallback(() => {
    if (dock.dealDetail && dock.dealDetail.enabled) {
      return (
        <DealDetailDock {...dock.dealDetail} />
      )
    } else if (dock.forecasting && dock.forecasting.enabled) {
      return (
        <ForecastingDock {...dock.forecasting} />
      )
    }
    return null
  }, [dock])

  return renderDock()
}

export default PipelineDock
