import React, { useLayoutEffect, useMemo } from 'react'
import { dealStatus } from '../../constants/dealStatus'
import FieldDebug from './fieldDebug'
import classNames from 'classnames'

const StatusFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    className = '',
    labelClassName = '',
  } = props

  const label = useMemo(() => {
    if (field?.value) {
      return dealStatus[field.value] ? dealStatus[field.value].label : ''
    }
    return ''
  }, [field])

  const color = useMemo(() => {
    if (field?.value) {
      return dealStatus[field.value] ? dealStatus[field.value].color : '#000'
    }
    return '#000'
  }, [field])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure])

  return (
    <>
      {!label && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('StatusFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
              Status
              <FieldDebug
                debug={debug}
                data={{ opportunity, fieldDefinition, field }} />
            </div>
            {label
              ? (
                <div
                  style={{ transform: 'translateY(-1px)' }}
                  className="flex flex-row flex-nowrap items-center">
                  <div className="w-3 h-3 mr-1 rounded-full flex-shrink-0" style={{ backgroundColor: color, transform: 'translateY(-1px)' }} />
                  <div className="text-color-09242F text-size-16px font-weight-300">{label}</div>
                </div>
              )
              : <div className="text-color-09242F text-size-16px font-weight-300">-</div>}
          </div>
        )}
    </>
  )
}

export default StatusFieldDetailItem
