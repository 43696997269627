import { createReducer } from '@reduxjs/toolkit'
import { getSignalsByUserSuccess, getSignalsByOpportunitySuccess } from '../actions/signalService'
import { clearSignals } from '../actions'

const initialState = {}

export const signalCounters = createReducer(initialState, {
  [clearSignals.type]: (state, action) => initialState,
  [getSignalsByUserSuccess.type]: (state, action) => {
    if (!state[action.payload.userId]) state[action.payload.userId] = {}
    state[action.payload.userId].totalCount = action.payload.totalCount
    return state
  },
  [getSignalsByOpportunitySuccess.type]: (state, action) => {
    if (!state[action.payload.opportunityId]) state[action.payload.opportunityId] = {}
    state[action.payload.opportunityId].totalCount = action.payload.totalCount
    return state
  }
})
