import React, { useRef, useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Waypoint } from 'react-waypoint'
import { RemoveScroll } from 'react-remove-scroll'
import Modal from '../common/modal'
import AnimatedLoader from '../loaders/animatedLoader'
import classNames from 'classnames'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import EmptyState from '../empty/emptyState'
import pageIcon from '../../assets/pageIcon.png'
import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'

const RealtimeViewDetailsModal = (props) => {
  const {
    isFetching,
    isModalOpen,
    closeModal,
    getNextPage,
    error,
    total,
    data,
    title
  } = props

  const containerRef = useRef(null)

  const history = useHistory()
  const { routes } = useRoutes()

  const { formatCurrency } = useFormatCurrency()

  const showEmptyList = useMemo(() => {
    return data?.length === 0
  }, [data])

  const showEndOfListLoader = useMemo(() => {
    return !isFetching && total > 0 && data?.length < total
  }, [isFetching, total, data])

  const onWaypointEnter = useCallback(() => {
    getNextPage && data && getNextPage(data.length)
  }, [getNextPage, data])

  const closeModalInternal = useCallback(() => {
    closeModal && closeModal()
    return false
  }, [closeModal])

  const onDealClick = useCallback((deal) => {
    history.push(routes.deal.replace(':opportunityId', deal.id))
  }, [])

  return (
    <RemoveScroll enabled={isModalOpen}>
      <Modal
        maxWidth="lg"
        handleClose={closeModalInternal}
        open={isModalOpen}>
        <div ref={containerRef} className="bg-color-ffffff rounded-lg overflow-y-auto" style={{ height: 'calc(100vh - 60px)' }}>
          <div
            className="fixed rounded-lg overflow-hidden bg-color-ffffff"
            style={{
              width: Math.max(0, (containerRef?.current?.offsetWidth ?? 0) - 20),
              height: 90
            }}>
            <div className="w-full h-full flex justify-between pt-9 pb-6">
              <div className="pl-10 pr-4 text-color-151d49 text-size-24px">
                {title}
                {' '}
                <span>
                  (
                  {total}
                  )
                </span>
              </div>
              <button onClick={closeModalInternal} className="mx-10 focus:outline-none pointer-events-auto">
                <FontAwesomeIcon icon="times" size="lg" className="text-color-151d49" />
              </button>
            </div>
          </div>
          <div className="px-10" style={{ paddingTop: 90 }}>
            {isFetching
              ? (
                <AnimatedLoader
                  className="my-10"
                  title="Loading Deals"
                  subTitle="Please wait..."
                  maxWidth={220} />
              )
              : (
                <>
                  {showEmptyList && (
                    <div className="flex justify-center my-10">
                      <EmptyState
                        iconControl={<img src={pageIcon} />}
                        header="No Deals"
                        subHeader="There are no deals to show." />
                    </div>
                  )}

                  {!showEmptyList && (
                    <div className="flex-table striped hovered main-first-cell">
                      <div className="thead">
                        <div className="tr">
                          <div className={columnClasses[0]}>Deal</div>
                          <div className={columnClasses[1]}>Stage</div>
                          <div className={columnClasses[2]}>Forecast</div>
                          <div className={columnClasses[3]}>Deal Size</div>
                          <div className={columnClasses[4]}>Close Date</div>
                          <div className={columnClasses[5]}>Owner</div>
                        </div>
                      </div>
                      <div className="tbody">
                        {data?.map((d, index) => {
                          const {
                            id,
                            name,
                            stageName,
                            forecastCategoryName,
                            amount,
                            closeDate,
                            owner
                          } = d
                          return (
                            <div
                              key={`deal-${id}-${index}`}
                              className={classNames('card tr mb-4 hover:bg-gradient-white-to-blue pointer-events-auto cursor-pointer')}
                              onClick={() => onDealClick(d)}>
                              <div className={columnClasses[0]}>
                                <div className="flex items-center">
                                  <div className="mr-2">
                                    <div className="text-size-15px text-color-2e384d font-bold">{name}</div>
                                  </div>
                                </div>
                              </div>
                              <div className={columnClasses[1]}>
                                <div className="flex items-center justify-end">
                                  <div className="text-size-15px text-color-2e384d">{stageName}</div>
                                </div>
                              </div>
                              <div className={columnClasses[2]}>
                                <div className="flex items-center justify-end">
                                  <div className="text-size-15px text-color-2e384d">{forecastCategoryName}</div>
                                </div>
                              </div>
                              <div className={columnClasses[3]}>
                                <div className="flex items-center justify-end">
                                  {formatCurrency(amount)}
                                </div>
                              </div>
                              <div className={columnClasses[4]}>
                                <div className="text-size-15px text-color-2e384d">
                                  {closeDate}
                                </div>
                              </div>
                              <div className={columnClasses[5]}>
                                <div className="flex items-center justify-end">
                                  <div className="text-size-15px text-color-2e384d">{owner?.name ?? '-'}</div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        {showEndOfListLoader && (
                          <>
                            <div className="flex justify-center my-4">
                              <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
                            </div>
                            <Waypoint onEnter={onWaypointEnter} />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
      </Modal>
    </RemoveScroll>
  )
}

export default RealtimeViewDetailsModal

const columnClasses = [
  classNames('td w-48'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('mr-4 w-6 text-right font-normal')
]
