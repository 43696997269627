import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RealtimeDraftView from '../realtimeView/realtimeDraftView'
import { RemoveScroll } from 'react-remove-scroll'
import { useCanvasItem } from '../../context/canvasItem'
import { useModal } from '../../hooks/useModal'
import ActionModal from '../common/actionModal'
import { useRealtimeDraftView } from '../../context/realtimeDraftView'
import { useRealtimeViewModal } from '../../context/realtimeViewModal'
import { FilterSourceOptionsProvider } from '../../context/filterSourceOptions'
import FilterEditor from './filterEditor'
import { RealtimeFilterEditorProvider } from '../../context/realtimeFilterEditor'
import FilterName from './filterName'
import FilterActions from './filterActions'
import FilterApplyOverlay from './filterApplyOverlay'
import FilterModalHeader from './filterModalHeader'
import FilterModalContainer from './filterModalContainer'
import { useAuth } from '../../context/auth'

const FilterModal = (props) => {
  const { actingUserId } = useAuth()

  const { name } = useCanvasItem()

  const { selectedFilter: selectedDraftFilter } = useRealtimeDraftView()

  const { isModalOpen, closeModal } = useRealtimeViewModal()

  const containerRef = useRef({})

  const confirmationModal = useModal()

  const [headerWidth, setHeaderWidth] = useState(0)

  const handleResize = useCallback(() => {
    const scrollBarWidth = Math.max(14, (containerRef.current?.offsetWidth ?? 0) - (containerRef.current?.clientWidth ?? 0))
    setHeaderWidth(Math.max(0, (containerRef.current?.offsetWidth ?? 0) - scrollBarWidth))
  }, [containerRef.current])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const onClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const filterLabel = useMemo(() => {
    return actingUserId === selectedDraftFilter?.userId ? 'Your Filter' : 'Shared Filter'
  }, [actingUserId, selectedDraftFilter])

  return (
    <RemoveScroll enabled={isModalOpen}>
      <FilterSourceOptionsProvider isModalOpen={isModalOpen}>
        <RealtimeFilterEditorProvider selectedFilter={selectedDraftFilter}>
          <FilterModalContainer
            confirmationModal={confirmationModal}
            onClose={onClose}>
            <div ref={containerRef} className="bg-color-ffffff rounded-lg overflow-y-auto" style={{ maxHeight: 'calc(100vh - 60px)' }}>
              <div
                className="fixed rounded-lg overflow-hidden bg-color-ffffff"
                style={{
                  width: headerWidth,
                  height: 90,
                  zIndex: 99999
                }}>
                <FilterModalHeader
                  confirmationModal={confirmationModal}
                  onClose={onClose} />
              </div>
              <div style={{ paddingTop: 90 }}>
                <div className="relative w-full h-full">
                  <div>
                    <div className="px-12 pb-4 text-size-18px text-color-818e93 font-weight-500">
                      Use this area to explore, create, and manage the filters applied to this metric by building them below.
                    </div>
                    <div className="pl-18 pr-10 py-4 leading-tight">
                      <p className="text-color-09242f text-size-24px font-weight-700">
                        {name}
                      </p>
                      <p className="text-color-818e93 text-size-18px font-normal">
                        {selectedDraftFilter?.name}
                      </p>
                    </div>
                    <div className="pl-16 pr-10 pb-10">
                      <RealtimeDraftView />
                    </div>
                  </div>
                  <FilterApplyOverlay />
                </div>
                <div className="flex bg-color-fafafa border-t border-color-ecf0fc p-12">
                  <div className="filters-main">
                    <div className="filters-header">
                      <div className="leading-tight">
                        <FilterName />
                        <p className="text-color-818e93 text-size-16px font-normal">
                          {filterLabel}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <FilterActions />
                      </div>
                    </div>
                    <FilterEditor />
                  </div>
                </div>
              </div>
            </div>
          </FilterModalContainer>
          <ActionModal
            modal={confirmationModal}
            onAction={onClose} />
        </RealtimeFilterEditorProvider>
      </FilterSourceOptionsProvider>
    </RemoveScroll>
  )
}

export default FilterModal
