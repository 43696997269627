import { useMemo } from 'react'
import { useLoggedInUser } from '../context/loggedInUser'
import { roles } from '../constants/roles'

export default function useRoles() {
  const { isFetching, loggedInUser } = useLoggedInUser()

  const isIndividualContributorRole = useMemo(() => {
    // if a role is not set, then defaultly set them as a IC
    return loggedInUser.id && (loggedInUser.role === '' || (loggedInUser.role && loggedInUser.role === roles.IC))
  }, [loggedInUser])

  const isManagerRole = useMemo(() => {
    return loggedInUser.id && (loggedInUser.role === roles.MANAGER || loggedInUser.groupViewerIdsList?.length)
  }, [loggedInUser])

  const isIndividualContributorRoleOrAbove = useMemo(() => {
    return isIndividualContributorRole || isManagerRole
  }, [isIndividualContributorRole, isManagerRole])

  const isIndividualContributorRoleOnly = useMemo(() => {
    return isIndividualContributorRole && !isManagerRole
  }, [isIndividualContributorRole, isManagerRole])

  const isPlayerCoach = useMemo(() => {
    return isIndividualContributorRole && isManagerRole
  }, [isIndividualContributorRole, isManagerRole])

  return {
    isFetching,
    isIndividualContributorRole,
    isIndividualContributorRoleOnly,
    isIndividualContributorRoleOrAbove,
    isManagerRole,
    isPlayerCoach,
  }
}
