import React, { useEffect, useMemo } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { get, has, some } from 'lodash'
import { getFilterKey } from './helpers'

const FilterItem = (props) => {
  const {
    rowRendererArgs,
    cellMeasurerArgs,
    filteredMenuItems,
    checked,
    menuItemClassName,
    categoryClassName,
    categoriesOpened,
    categoriesRendered,
    onCategoryClick,
    handleToggleChange,
  } = props

  const { index, style } = rowRendererArgs
  const { registerChild, measure } = cellMeasurerArgs
  const m = filteredMenuItems[index]

  const renderCategory = useMemo(() => {
    if (has(m, 'category') && m.category !== '') {
      categoriesRendered[m.category] = true
      return !some(filteredMenuItems.slice(0, index), (x) => x.category === m.category)
    }
    return false
  }, [m, filteredMenuItems, categoriesRendered])

  const itemStyle = useMemo(() => {
    return has(m, 'data.level') ? { marginLeft: 20 * m.data.level } : {}
  }, [m])

  const isCategoryOpen = useMemo(() => {
    return get(categoriesOpened, m.category, false)
  }, [m, categoriesOpened])

  const menuItemChecked = useMemo(() => {
    return get(checked, `['${getFilterKey(m.category, m.key)}'].checked`, null)
  }, [m, checked])

  useEffect(() => {
    measure && measure()
  }, [measure, filteredMenuItems, renderCategory, isCategoryOpen])

  return (
    <div ref={registerChild} style={style}>

      {renderCategory
        && (
          <div onClick={() => onCategoryClick(m)} className={classNames(categoryClassName, 'cursor-pointer')}>
            <span>{m.category}</span>
            <FontAwesomeIcon icon={isCategoryOpen ? 'chevron-up' : 'chevron-down'} color="#151d49" className="mt-1" />
          </div>
        )}

      <div className={classNames(menuItemClassName, { hidden: m.category && !isCategoryOpen })} style={itemStyle}>
        <div className="flex items-center mx-2 my-1">
          <ToggleButtonGroup
            size="small"
            exclusive
            value={menuItemChecked}
            onChange={(e, newValue) => handleToggleChange(e, newValue, m)}>
            <ToggleButton value="include">
              <FontAwesomeIcon icon="check"
                size="sm"
                className={classNames({ 'text-color-ffffff': menuItemChecked === 'include' }, { 'text-color-91959f': menuItemChecked === null })} />
            </ToggleButton>
            <ToggleButton value="exclude">
              <FontAwesomeIcon icon="times"
                size="sm"
                className={classNames({ 'text-color-ffffff': menuItemChecked === 'exclude' }, { 'text-color-91959f': menuItemChecked === null })} />
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="ml-2" style={{ marginTop: 3 }}>{m.name || '-'}</div>
        </div>
      </div>

    </div>
  )
}

export default FilterItem
