import Chartist from 'chartist'

export default function targetHorizontalLine(options) {
  return function targetHorizontalLine(chart) {
    const defaultOptions = {
      lineClass: 'target-horizontal-line',
      labelClass: 'target-horizontal-line-label',
      offsetY: 0
    }

    options = Chartist.extend({}, defaultOptions, options)

    chart.on('created', (ctx) => {
      const targetY = ctx.chartRect.y1 - Math.round((ctx.axisY.axisLength) * (options.targetValue / ctx.bounds.range))

      const line = ctx.svg.elem('line', {
        x1: 30,
        x2: ctx.axisX.axisLength + 30,
        y1: targetY + options.offsetY,
        y2: targetY + options.offsetY,
        style: `stroke-dasharray: 3,4; stroke: ${options.lineColor}; stroke-width: 1px;`
      }, options.lineClass)
      if (options.animate) {
        line.animate({
          opacity: { ...options.animate.opacity }
        })
      }

      const text = ctx.svg.elem('text', {
        x: options.position === 'right' ? ctx.axisX.axisLength + 50 : 5,
        y: targetY + 5 + options.offsetY,
        style: `text-anchor: ${options.position === 'right' ? 'end' : 'start'}`
      }, options.labelClass).text(options.label)
      if (options.animate) {
        text.animate({
          opacity: { ...options.animate.opacity }
        })
      }
    })
  }
}
