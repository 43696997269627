import React from 'react'
import ChartText from '../controls/chartText'
import classNames from 'classnames'
import flattenDeep from 'lodash/flattenDeep'
import filter from 'lodash/filter'
import get from 'lodash/get'
import max from 'lodash/max'
import min from 'lodash/min'

export const getMinMax = (arr) => {
  const flattened = flattenDeep(arr)
  return {
    min: min(flattened),
    max: max(flattened)
  }
}

export const getLinecapSize = (style) => {
  return style.strokeLinecap === 'round' ? style.strokeWidth / 2 : 0
}

export const renderBarLabels = (config) => {
  const orientation = get(config, 'props.orientation', 'horizontal')
  const stacked = get(config, 'props.stacked', false)
  const bars = get(config, 'bars', [])
  return (
    bars.map((bar) => {
      const { seriesIndex, dataIndex, isFirst, isLast } = bar
      const label = config.props.grouped ? config.measured.barLabels[`${seriesIndex}-${dataIndex}`].text : config.measured.barLabels[seriesIndex].text
      const attributes = {
        style: {
          ...config.barLabel.style
        }
      }
      if (orientation === 'horizontal') {
        attributes.x = bar.pt2.x + getLinecapSize(bar.style) + config.barLabel.offset.x
        attributes.y = bar.pt2.y + config.barLabel.offset.y
      } else if (orientation === 'vertical') {
        attributes.x = bar.pt2.x + config.barLabel.offset.x
        attributes.y = bar.pt2.y + config.barLabel.offset.y - (getLinecapSize(bar.style) + (config.barLabel.style.fontSize / 2))

        if (stacked) {
          attributes.y -= 5
        }
      }
      const classes = classNames(
        'g-charttext-group bar-label',
        `series-${seriesIndex}`,
        `data-${dataIndex}`,
        { first: isFirst },
        { last: isLast }
      )

      return (
        <g key={`barLabel-${seriesIndex}-${dataIndex}`} className={classes}>
          <ChartText {...attributes}>{label}</ChartText>
        </g>
      )
    })
  )
}

export const renderAxisBarLabels = (config) => {
  const orientation = get(config, 'props.orientation', 'horizontal')
  const grouped = get(config, 'props.grouped', false)
  const stacked = get(config, 'props.stacked', false)
  const bars = get(config, 'bars', [])
  const textAnchorOffset = get(config, 'axisBarLabel.style.textAnchor') === 'end' ? config.measured.axisBarLabelMaxWidth : 0
  return (
    bars.map((bar) => {
      const { seriesIndex, groupIndex, dataIndex, isFirst, isLast } = bar
      let label
      if (grouped && stacked) {
        label = config.measured.axisBarLabels[`${seriesIndex}-${groupIndex}`].text
      } else if (grouped) {
        label = config.measured.axisBarLabels[`${seriesIndex}-${dataIndex}`].text
      } else {
        label = config.measured.axisBarLabels[seriesIndex].text
      }
      const attributes = {
        style: {
          ...config.axisBarLabel.style
        }
      }
      if (orientation === 'horizontal') {
        attributes.x = config.axisBarLabel.offset.x + textAnchorOffset
        attributes.y = bar.pt1.y + config.axisBarLabel.offset.y
      } else if (orientation === 'vertical') {
        attributes.x = bar.pt1.x + config.axisBarLabel.offset.x
        attributes.y = config.chartRect.y + config.chartRect.height + config.axisBarLabel.style.fontSize
      }
      const classes = classNames(
        'g-charttext-group axis-bar-label',
        `series-${seriesIndex}`,
        `group-${groupIndex}`,
        `data-${dataIndex}`,
        { first: isFirst },
        { last: isLast }
      )

      return (
        <g key={`axisBarLabel-${seriesIndex}-${groupIndex}-${dataIndex}`} className={classes}>
          <ChartText {...attributes}>{label}</ChartText>
        </g>
      )
    })
  )
}

export const renderAxisDataLabels = (config) => {
  const orientation = get(config, 'props.orientation', 'horizontal')
  const labels = config.measured.axisDataLabels
  const range = config.props.axisDataLabels ? config.axisData.maxBound - config.axisData.minBound : config.seriesMinMax.max
  return (
    labels.map((labelData, i) => {
      const { text, data } = labelData

      const tickAttributes = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        style: {
          strokeWidth: 1,
          strokeLinecap: 'round',
          stroke: '#d6d9e6',
          fill: 'none',
        },
      }

      const attributes = {
        style: {
          ...config.axisDataLabel.style
        }
      }
      if (orientation === 'horizontal') {
        attributes.x = config.chartRect.x + config.axisDataLabel.offset.x
        if (range !== 0) {
          attributes.x += ((data / range) * config.chartRect.width)
        }
        attributes.y = config.chartRect.y + config.chartRect.height + config.axisDataLabel.offset.y + (config.axisDataLabel.gutter.height / 2)
        if (i === 0) {
          attributes.style.textAnchor = 'start'
        }

        tickAttributes.x1 = attributes.x
        tickAttributes.y1 = attributes.y - 14
        tickAttributes.x2 = attributes.x
        tickAttributes.y2 = attributes.y - 9
      } else if (orientation === 'vertical') {
        attributes.x = config.chartRect.x + config.axisDataLabel.offset.x + config.chartRect.width
        if (config.props.axisDataLines) {
          tickAttributes.x1 = config.chartRect.x
          tickAttributes.x2 = config.chartRect.x + config.chartRect.width
        } else {
          tickAttributes.x1 = attributes.x - 7
          tickAttributes.x2 = attributes.x - 2
        }

        attributes.y = config.plotY(data)
        tickAttributes.y1 = attributes.y
        tickAttributes.y2 = attributes.y
      }

      return (
        <g key={`axisDataLabel-${data}-${i}`} className="g-charttext-group axis-data-label">
          <line {...tickAttributes} />
          <ChartText {...attributes}>{text}</ChartText>
        </g>
      )
    })
  )
}

export const renderGroupBarLabels = (config) => {
  const orientation = get(config, 'props.orientation', 'horizontal')
  const bars = filter(get(config, 'bars', []), (b) => get(b, 'dataIndex') === 0)
  if (orientation === 'horizontal') {
    return (
      bars.map((bar) => {
        const { seriesIndex, isFirst, isLast } = bar
        const label = config.measured.groupBarLabels[`${seriesIndex}`].text
        const attributes = {
          style: {
            ...config.groupBarLabel.style
          }
        }
        attributes.x = bar.pt1.x + config.groupBarLabel.offset.x
        attributes.y = bar.pt1.y - config.bar.style.strokeWidth + config.groupBarLabel.offset.y
        const classes = classNames(
          'g-charttext-group group-bar-label',
          `series-${seriesIndex}`,
          { first: isFirst },
          { last: isLast }
        )
        return (
          <g key={`groupBarLabel-${seriesIndex}`} className={classes}>
            <ChartText {...attributes}>{label}</ChartText>
          </g>
        )
      })
    )
  }
}
