import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core'
import { find, get } from 'lodash'
import { number } from '../../gml/lib/number'
import classNames from 'classnames'
import pencilSm from '../../assets/pencil-sm.png'
import Icon, { iconType } from '../icon'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const useStyles = makeStyles({
  root: {
    color: (props) => (props.valueDiffersFromBaseline ? '#5951FF' : '#c9ced0'),
    height: 6,
  },
  thumb: {
    height: 12,
    width: 12,
    background: (props) => (props.valueDiffersFromBaseline ? 'linear-gradient(-180deg, rgb(89, 81, 255) 0%, rgb(119, 113, 252) 100%)' : '#818e93'),
    marginTop: -3,
    marginLeft: -6,
    boxShadow: '0px 0px 2px 0px rgba(243, 100, 66, 0.3)',
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
})

const FieldSlider = (props) => {
  const { disabled, paramsList, categoryKey, categoryMaxValue, field, baselineField, handleSliderChange } = props

  const [isEditing, setIsEditing] = useState(false)

  const { key, name, value } = field

  const [val, setVal] = useState(value)
  const [valueDiffersFromBaseline, setValueDiffersFromBaseline] = useState(baselineField.value !== value)

  useEffect(() => {
    setVal(field.value)
    setValueDiffersFromBaseline(baselineField.value !== field.value)
  }, [field])

  const onChange = useCallback((e, newValue) => {
    setValueDiffersFromBaseline(baselineField.value !== newValue)
    setVal(newValue)
  }, [])

  const onChangeCommitted = useCallback((e, newValue) => {
    setValueDiffersFromBaseline(baselineField.value !== newValue)
    setVal(newValue)

    handleSliderChange({
      categoryKey,
      field: {
        ...field,
        value: newValue,
      },
      baselineField,
    })
  }, [field])

  const param = useMemo(() => {
    return find(paramsList, (x) => x.key === categoryKey)
  }, [categoryKey, paramsList])

  const setInputVal = useCallback((newValue) => {
    const format = get(param, 'format')
    const newVal = (format === 'percent') ? newValue / 100 : newValue
    onChangeCommitted(null, newVal)
  }, [param, onChangeCommitted])

  const onInputBlur = useCallback(({ target }) => {
    setIsEditing(false)
    setInputVal(target.valueAsNumber || value)
  }, [value, setInputVal])

  const onKeyPress = useCallback((e) => {
    const { key, target } = e
    if (key === 'Enter') {
      setIsEditing(false)
      setInputVal(target.valueAsNumber || value)
    }
  }, [value, setInputVal])

  const onValueClick = useCallback((e) => {
    e.preventDefault()
    if (!disabled) {
      setIsEditing(true)
    }
  }, [disabled])

  const isPercentFormat = useMemo(() => {
    return get(param, 'format') === 'percent'
  }, [param])

  const inputVal = useMemo(() => {
    switch (get(param, 'format')) {
      case 'percent':
        return Math.round(val * 100)
      case 'currency':
      default:
        return Math.round(val)
    }
  }, [param, val])

  const { formatCurrency } = useFormatCurrency()

  const formattedValue = useMemo(() => {
    switch (get(param, 'format')) {
      case 'percent':
        return number(val, 'percent')
      case 'currency':
        return formatCurrency(Math.round(val))
      default:
        return Math.round(val)
    }
  }, [param, val, formatCurrency])

  const min = useMemo(() => {
    // if (isPercentFormat && val < 0) {
    //   return -1
    // }
    return 0
  }, [isPercentFormat, val])

  const max = useMemo(() => {
    if (isPercentFormat) {
      return val > 1 ? 2 : 1
    }
    return Math.round(categoryMaxValue * 3)
  }, [isPercentFormat, categoryMaxValue])

  const step = useMemo(() => {
    return isPercentFormat ? 0.001 : 1
  }, [isPercentFormat])

  const classes = useStyles({ valueDiffersFromBaseline })

  return (
    <div>
      <div className={classNames('flex justify-between leading-none', { 'items-center mt-1': disabled })} style={{ transform: 'translateY(3px)' }}>
        <div
          className={classNames('text-color-a0a8bb text-size-14px font-normal leading-none pr-1', { ' text-color-5951FF': valueDiffersFromBaseline })}>
          {name}
        </div>
        {isEditing && (
          <input
            autoFocus={true}
            defaultValue={inputVal}
            onKeyPress={onKeyPress}
            onBlur={onInputBlur}
            style={{ top: '-5px' }}
            className="absolute text-right text-size-14px right-0 -top-7 w-1/2 p-1 border border-color-d6d9e6 rounded focus:outline-none"
            type="number" />
        )}
        <div
          onClick={onValueClick}
          style={{ marginRight: '-11px' }}
          className={classNames(
            'text-color-a0a8bb text-size-14px font-normal leading-none pl-1 flex flex-row',
            { 'group cursor-pointer': !disabled },
            { 'text-color-5951FF': valueDiffersFromBaseline }
          )}>
          {formattedValue}
          <div
            className={classNames(
              'invisible',
              { 'group-hover:visible': !disabled }
            )}
            style={{ transform: 'translate(4px, 0px)' }}>
            <Icon type={iconType.IMAGE} src={pencilSm} />
          </div>
        </div>
      </div>
      {!disabled && (
        <div className={classNames({ 'opacity-50 pointer-events-none': disabled })}>
          <Slider
            min={min}
            max={max}
            value={val}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            step={step}
            classes={{
              root: classes.root,
              thumb: classes.thumb,
              track: classes.track,
              rail: classes.rail,
            }} />
        </div>
      )}
    </div>
  )
}

export default FieldSlider
