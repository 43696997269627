import { useCallback, useState } from 'react'

export function useModal() {
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    open,
    setOpen,
    isSubmitting,
    setIsSubmitting,
    handleClose,
  }
}
