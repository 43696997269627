import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'

const ChartText = (props) => {
  const {
    name,
    styleNames,
    hide = false,
    x,
    y,
    textAnchor,
    style,
    children
  } = props

  PropTypes.checkPropTypes(ChartText.propTypes, props, 'prop', 'ChartText')

  const hideInternal = useHideToggle(name, hide)

  const attributes = {
    ...name && { id: name },
    className: classNames(
      'g-charttext',
      { [styleNames]: !!styleNames }
    ),
    ...x && { x },
    ...y && { y },
    ...textAnchor && { textAnchor },
    ...style && { style }
  }

  return (
    <>
      {!hideInternal && <text {...attributes}>{children}</text>}
    </>
  )
}

ChartText.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  hide: PropTypes.bool,
  children: PropTypes.node,
  x: PropTypes.number,
  y: PropTypes.number,
  textAnchor: PropTypes.string,
  style: PropTypes.object,
}

export default ChartText
