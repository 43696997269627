import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { modalCallbackContainer } from './modals'

const ForecastDealOverrideModal = (props) => {
  const { closeModal } = props

  const containerRef = useRef(null)
  const headerRef = useRef(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [headerHeight, setHeaderHeight] = useState(0)
  const [windowSize, setWindowSize] = useState({ width: window.innerHeight, height: window.innerHeight })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerHeight, height: window.innerHeight })
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current)
        setContainerSize({
          width: computedStyle.width.replace('px', '') * 1,
          height: computedStyle.height.replace('px', '') * 1
        })
      }
      if (headerRef.current) {
        setHeaderHeight(getComputedStyle(headerRef.current).height.replace('px', '') * 1)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleCloseInternal = useCallback(() => {
    modalCallbackContainer.callback = undefined
    closeModal && closeModal()
  }, [modalCallbackContainer, closeModal])

  const handleYesClick = useCallback(() => {
    modalCallbackContainer.callback && modalCallbackContainer.callback()
    modalCallbackContainer.callback = undefined
    closeModal && closeModal()
  }, [modalCallbackContainer, closeModal])

  return (
    <div ref={containerRef} className="static mx-auto bg-color-ffffff rounded overflow-y-auto" style={{ maxWidth: '430px', maxHeight: windowSize.height - 80, marginTop: 40 }}>
      <div ref={headerRef} className="fixed bg-color-ffffff rounded pt-9" style={{ width: containerSize.width - 20 }}>
        <div className="w-full flex justify-between">
          <div className="pl-10 text-color-151d49 text-size-24px">Wait!</div>
          <button onClick={handleCloseInternal} className="focus:outline-none"><FontAwesomeIcon icon="times" size="lg" className="mr-6 text-color-151d49" /></button>
        </div>
      </div>
      <div className="mt-32 px-10 pb-10 pt-4" style={{ marginTop: headerHeight }}>
        <div className="text-size-16px text-color-151d49 font-normal mb-4">
          You've already changed the total of this category.  By changing this deal you will override the total change you made.
        </div>
        <div className="text-size-16px text-color-151d49 font-normal">
          Would you like to reset your category-level call and continue to override this deal?
        </div>
        <div className="flex justify-center text-center mt-8">
          <button onClick={handleCloseInternal}
            className="mx-3 px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-color-a6b2cf shadow-btn focus:outline-none">
            No
          </button>
          <button onClick={handleYesClick}
            className="mx-3 px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none">
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default ForecastDealOverrideModal
