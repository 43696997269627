import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { clearOppDetail, setDock } from '../../actions'
import { getFieldFromObject } from '../fieldRenderers/helpers'
import { canonicalFieldKeys, canopyFields } from '../fieldRenderers/constants'

const DealNameCell = (props) => {
  const {
    opportunity,
    enableDealClick = true
  } = props

  const dock = useSelector((state) => state.dock)

  const isLoadedInDock = useMemo(() => {
    return dock.dealDetail && dock.dealDetail.enabled && dock.dealDetail.opportunity && dock.dealDetail.opportunity.id === opportunity.id
  }, [opportunity, dock])

  const name = useMemo(() => {
    const field = getFieldFromObject(opportunity, canonicalFieldKeys.name)
    return field?.value ?? '-'
  }, [opportunity])

  const ownerName = useMemo(() => {
    const field = getFieldFromObject(opportunity, canopyFields._owner.key)
    return field?.value?.name ?? ''
  }, [opportunity])

  const dispatch = useDispatch()

  const onDealClick = useCallback(() => {
    if (!enableDealClick) {
      return
    }
    if (!dock.dealDetail || !dock.dealDetail.enabled || !dock.dealDetail.opportunity || dock.dealDetail.opportunity.id !== opportunity.id) {
      dispatch(clearOppDetail())
      dispatch(setDock({ dealDetail: { enabled: true, fetchOppDetail: true, opportunity } }))
      window.analytics.track('dealGrid.dealClicked')
    } else {
      dispatch(clearOppDetail())
      dispatch(setDock({ dealDetail: { enabled: false } }))
    }
  }, [dispatch, dock, opportunity, enableDealClick])

  return (
    <div className="pl-2 py-3 leading-tight">
      <div
        onClick={onDealClick}
        id={`deal-name-cell-${opportunity.id}}`}
        className={classNames('text-color-818E93 text-size-15px font-weight-600 focus:outline-none text-left',
          { 'cursor-pointer': enableDealClick },
          { 'cursor-text': !enableDealClick })}>
        <span className={classNames('max-lines-2', { underline: isLoadedInDock })}>{name}</span>
      </div>
      {ownerName && <div className="text-color-91959f text-size-14px font-normal text-left">{ownerName}</div>}
    </div>
  )
}

export default DealNameCell
