import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import Tippy from '@tippyjs/react'
import { roundArrow, hideAll } from 'tippy.js'
import ConditionalWrapper from './conditionalWrapper'
import get from 'lodash/get'
import some from 'lodash/some'
import { controlTypes, createTooltipContent } from '../renderer/gmlRenderer'

const Tooltip = (props) => {
  const {
    getRendererProps,
    interactive = false,
    hide = false,
    disabled = false,
    name = '',
    offsetX = 0,
    offsetY = 0,
    content,
    children
  } = props

  PropTypes.checkPropTypes(Tooltip.propTypes, props, 'prop', 'Tooltip')

  const hideInternal = useHideToggle(name, hide)

  const className = classNames('g-tooltip')

  const hasTooltipContent = useMemo(() => {
    if (getRendererProps) {
      const { currentObject } = getRendererProps()
      return some(get(currentObject, 'childrenList', get(currentObject, 'children', [])), (c) => c.type === controlTypes.TooltipContent)
    }
  }, [getRendererProps])

  const tooltipContent = useMemo(() => {
    if (hasTooltipContent) {
      const result = createTooltipContent(getRendererProps)
      const { content } = result
      return content
    } else {
      return <div />
    }
  }, [getRendererProps, hasTooltipContent])

  const onShow = useCallback(() => {
    hideAll()
    return !disabled
  }, [disabled])

  if (hideInternal) {
    return <></>
  } else if (content) {
    return (
      <Tippy
        showOnCreate={false}
        onShow={onShow}
        className={className}
        interactive={interactive}
        appendTo={document.body}
        content={content}
        duration={[0, 0]}
        maxWidth={800}
        arrow={roundArrow}
        zIndex={999999999}
        theme="canopy">
        {children}
      </Tippy>
    )
  } else {
    return (
      <ConditionalWrapper
        condition={hasTooltipContent}
        wrapper={(c) => (
          <Tippy
            showOnCreate={false}
            onShow={onShow}
            className={className}
            interactive={interactive}
            appendTo={document.body}
            content={tooltipContent}
            duration={[0, 0]}
            maxWidth={800}
            arrow={roundArrow}
            zIndex={999999999}
            theme="canopy">
            <div className="g-tooltip-wrapper">{c}</div>
          </Tippy>
        )}>
        {children}
      </ConditionalWrapper>
    )
  }
}

Tooltip.propTypes = {
  getRendererProps: PropTypes.func,
  interactive: PropTypes.bool,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  name: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node
}

export default Tooltip
