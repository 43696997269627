import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTenantUsers } from '../../../context/tenantUsers'
import { useTextField } from '../../../hooks/useTextField'
import { debounce } from 'lodash'
import Header from '../../header/header'
import Groups from '../../icons/groups'
import TenantUsersList from './tenantUsersList'
import pluralize from 'pluralize'
import SearchBox from '../../search/searchBox'
import AnimatedLoader from '../../loaders/animatedLoader'
import EmptyState from '../../empty/emptyState'
import { useRoutes } from '../../../context/routes'
import queryString from 'query-string'

const TenantUsersMain = (props) => {
  const history = useHistory()
  const location = useLocation()
  const hash = queryString.parse(location.hash)

  const { routes } = useRoutes()

  const search = useTextField()

  const { isFetching, tenantUsers, setSearch, total } = useTenantUsers()

  const count = useMemo(() => {
    return tenantUsers.length
  }, [tenantUsers])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const debounceSearch = useMemo(() => {
    return debounce((searchText) => {
      setSearch(searchText)
    }, 350)
  }, [])

  const onBackClick = useCallback(() => {
    history.push(routes.tenants)
  }, [])

  useEffect(() => {
    debounceSearch(search.value)
  }, [search.value])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        showSearch={false}
        text={`${hash.name || 'Users'}`}
        showBackButton={true}
        onBackClick={onBackClick} />

      <div className="flex-grow overflow-auto p-10">

        <div className="flex items-center justify-between mb-3">
          <div className="text-size-20px text-color-51636a font-weight-400">{!isFetching ? pluralize('User', total, true) : ''}</div>
          <div className="flex items-center">
            <SearchBox
              className="mx-2"
              value={search.value}
              onChange={search.onChange}
              onClear={search.reset}
              autoFocus={true} />
          </div>
        </div>

        {showEmptyState
          ? (
            <div className="flex justify-center my-10">
              <EmptyState
                iconControl={<Groups transform="scale(1.2)" />}
                header="No Users"
                subHeader="There are no users to display" />
            </div>
          )
          : (
            <>
              {isFetching
                ? (
                  <div className="flex justify-center my-10">
                    <AnimatedLoader
                      className="my-10"
                      title="Loading Users"
                      subTitle="Please wait..." />
                  </div>
                )
                : (
                  <TenantUsersList />
                )}
            </>
          )}

      </div>
    </div>
  )
}

export default TenantUsersMain
