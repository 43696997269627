import { useGroups } from '../../context/groups'
import { useLocationData } from '../location/hooks'
import { useLoggedInUser } from '../../context/loggedInUser'
import classNames from 'classnames'
import DealsSlider from '../deals/dealsSlider'
import Icon from '../icon'
import MyTeam from './myTeam'
import React, { useMemo, useState } from 'react'
import GroupHeaderControl from '../header/groupHeaderControl'
import { CanvasProvider } from '../../context/canvas'
import { DealsProvider } from '../../context/deals'
import { canvasKey } from '../canvas/constants'
import MetricsCanvas from '../canvas/metricsCanvas'
import PipelineCanvas from '../canvas/pipelineCanvas'
import { PeopleProvider } from '../../context/people'
import { useAuth } from '../../context/auth'

const ManagerSummary = () => {
  const { params } = useLocationData()
  const { userId } = params

  const [hideTeam, setHideTeam] = useState(false)

  const { actingTenantId } = useAuth()

  const { isFetching, findGroupById, getSubTreesForId, subTrees } = useGroups()

  const { loggedInUser, loggedInUserId } = useLoggedInUser()

  const actor = useMemo(() => {
    if (userId || loggedInUser) {
      return {
        tenantId: actingTenantId,
        userId: userId || loggedInUser.teamId
      }
    }
  }, [userId, loggedInUser, actingTenantId])

  const id = useMemo(() => {
    const { teamId, groupViewerIdsList } = loggedInUser
    if (!userId && (teamId || groupViewerIdsList.length)) {
      return groupViewerIdsList[0] ?? teamId
    }
    return userId || loggedInUserId
  }, [userId, loggedInUserId])

  const showTopTen = useMemo(() => {
    const userSubTrees = getSubTreesForId(id)
    if (userSubTrees && userSubTrees.childrenList.length) {
      return true
    }
    if (!userSubTrees && subTrees.length) {
      const treesReduced = subTrees.reduce((p, v) => {
        const { childrenList = [], membersList = [] } = v
        return {
          childrenList: [
            ...p.childrenList || [],
            ...childrenList
          ]
        }
      }, {})
      if (treesReduced.childrenList.length) {
        return true
      }
    }
    return false
  }, [id, userId, subTrees, getSubTreesForId])

  const pageTitle = useMemo(() => {
    if (!isFetching && id) {
      const { teamId } = loggedInUser
      const teamName = findGroupById(id)?.name ?? ''

      if (teamId && teamId === id) {
        return teamName ? `${teamName} (Your Team)` : 'Your Team'
      }

      return teamName ?? ''
    }
    return ''
  }, [isFetching, userId, loggedInUser, findGroupById])

  return (
    <>
      <GroupHeaderControl
        title={pageTitle}
        showReps={false}
        teamsSelectable={true}
      />
      <div className="main-body">
        <CanvasProvider
          key={`CanvasProvider-${canvasKey.SUMMARY_PIPELINE}-${userId}`}
          canvasKey={canvasKey.SUMMARY_PIPELINE}>
          <PipelineCanvas
            key={`PipelineCanvas-${id}`}
            userId={userId}
            actor={actor} />
        </CanvasProvider>

        <DealsProvider
          skip={0}
          take={20}
          sort="insightCount DESC"
          id={userId}>
          <DealsSlider />
        </DealsProvider>

        <div className="flex mr-4 mt-8">
          <div className={classNames('w-full', { 'w-8/12': !hideTeam })}>
            <CanvasProvider
              key={`CanvasProvider-${canvasKey.SUMMARY_METRICS}-${userId}`}
              canvasKey={canvasKey.SUMMARY_METRICS}>
              <MetricsCanvas
                key={`MetricsCanvas-${userId}`}
                userId={userId}
                actor={actor}
                viewKey={`view-${hideTeam}`} />
            </CanvasProvider>
          </div>
          {hideTeam && (
            <div className={classNames('absolute right-0 px-12 pt-6')}>
              <div onClick={() => setHideTeam(false)} className="flex flex-col justify-center cursor-pointer">
                <Icon iconName="users" size="lg" className="mx-auto" />
                <a className="text-color-4e5d7e text-size-12px font-weight-500 cursor-pointer my-2 mx-auto whitespace-nowrap">Show Team</a>
              </div>
            </div>
          )}
          <div className={classNames('ml-8', { hidden: hideTeam }, { 'block w-4/12': !hideTeam })}>
            <PeopleProvider
              id={id}>
              <MyTeam
                key={`MyTeam-${userId}`}
                showTopTen={showTopTen}
                setHideTeam={setHideTeam} />
            </PeopleProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManagerSummary
