import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDebug } from '../context/debug'
import { useDispatch } from 'react-redux'
import { useRoutes } from '../context/routes'
import { subscribe } from '../gml/eventBus'
import { eventTypes } from '../gml/eventBus/eventTypes'
import { objectToQueryString } from '../lib/url'
import { setGrpcPrefix, updateModal } from '../actions'
import { modalTypes } from './modals/modals'

const AppHandlers = (props) => {
  const dispatch = useDispatch()
  window.dispatch = dispatch

  const history = useHistory()
  window.navigate = history.push

  const { grpcPrefix } = useDebug()

  const { routes } = useRoutes()

  useEffect(() => {
    dispatch(setGrpcPrefix(grpcPrefix))
  }, [dispatch, grpcPrefix])

  useEffect(() => {
    const { unsubscribe } = subscribe(eventTypes.linkClicked, (args) => {
      const { clickData = {} } = args
      const { type, redirect = true } = clickData

      if (type === 'opportunities_modal') {
        dispatch(updateModal({
          ...clickData,
          type: modalTypes.deals,
        }))
      } else if (redirect) {
        history.push(`${routes.eventBusRedirect}?${objectToQueryString(clickData)}`)
      }
    })

    return () => unsubscribe()
  }, [dispatch])

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.target.classList.contains('slate-editor')) {
        return
      }

      if (e.target !== window.document.body) {
        return
      }

      let searchBox

      const code = e.code
      switch (code) {
        case 'Slash':
          searchBox = document.getElementById('searchBox')
          if (searchBox) searchBox.focus()
          e.preventDefault()
          break
        default:
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return null
}

export default AppHandlers
