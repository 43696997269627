import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import GenericModal from '../modal/genericModal'
import { Close } from '@getoutreach/react-icons'
import FormulaInputs from './formulaInputs'
import { useFormulaInputs } from './hooks'
import AnimatedLoader from './animatedLoader'
import { guid } from '../../lib/guid'
import { cloneDeep, find, get, orderBy } from 'lodash'
import { defaultFormula, defaultTarget, defaultTimePeriod, defaultSelectedFilters, targetAll, timePeriodOptions } from './constants'
import { DateRangeOption, GlobalLevel } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { getFilterLabels } from './helpers'

const ChangeParametersModal = (props) => {
  const { tenantId, groupId, baselineScenarioKey, modal } = props

  const { open, setOpen, isSubmitting, setIsSubmitting, handleClose } = modal

  const forecastSimulationsAvailable = useSelector((state) => state.forecastSimulationsAvailable)
  const forecastSimulationLevelOptions = useSelector((state) => state.forecastSimulationLevelOptions)
  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)
  const scenarioPlannerOptions = useSelector((state) => state.scenarioPlannerOptions)

  const what = useMemo(() => {
    const formula = find(forecastSimulationsAvailable, (s) => s.key === forecastSimulationWorkspace.key)
    if (formula) {
      return { key: formula.key, value: formula.label }
    }
    return defaultFormula
  }, [forecastSimulationsAvailable, forecastSimulationWorkspace])

  const when = useMemo(() => {
    const { dateRangeType, startDatetime, endDatetime } = forecastSimulationWorkspace
    const option = find(timePeriodOptions, (o) => o.key === dateRangeType)
    if (option) {
      if (option.key === DateRangeOption.CUSTOM) {
        const start = moment(startDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')
        const end = moment(endDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')
        return {
          key: DateRangeOption.CUSTOM,
          value: `Between ${start} - ${end}`,
          start,
          end,
        }
      } else {
        return option
      }
    }
    return defaultTimePeriod
  }, [forecastSimulationWorkspace])

  const who = useMemo(() => {
    const { level, entityIdsList = [] } = forecastSimulationWorkspace.level || {}
    if (level === GlobalLevel.COMPANY) {
      return { ...targetAll, level: GlobalLevel.COMPANY }
    } else if (level === GlobalLevel.GROUP) {
      const group = find(forecastSimulationLevelOptions.groupLevelOptionsList.map((x) => ({ key: x.id, value: x.name })), (o) => o.key === get(entityIdsList, '[0]', ''))
      if (group) {
        return { ...group, level: GlobalLevel.GROUP }
      }
    } else if (level === GlobalLevel.PERSON) {
      const person = find(forecastSimulationLevelOptions.personLevelOptionsList.map((x) => ({ key: x.id, value: x.name })), (o) => o.key === get(entityIdsList, '[0]', ''))
      if (person) {
        return { ...person, level: GlobalLevel.PERSON }
      }
    }
    return defaultTarget
  }, [forecastSimulationLevelOptions, forecastSimulationWorkspace])

  const currentFilters = useMemo(() => {
    const filters = cloneDeep(forecastSimulationWorkspace?.filtersList) ?? []
    if (filters.length === 0) {
      return defaultSelectedFilters
    } else {
      const filterGroups = orderBy(cloneDeep(scenarioPlannerOptions.filterOptionsList || []), (o) => o.label)
      const labels = getFilterLabels({ filterGroups, filters })
      return {
        key: filters,
        ...labels
      }
    }
  }, [scenarioPlannerOptions, forecastSimulationWorkspace])

  const {
    selectedFormula,
    onFormulaSelect,
    selectedTimePeriod,
    setSelectedTimePeriod,
    onTimePeriodSelect,
    selectedTarget,
    onTargetSelect,
    onClearFilters,
    selectedFilters,
    onFilterSelected,
    canStart,
    startClicked,
    setStartClicked,
    onStartClick,
  } = useFormulaInputs({ tenantId, groupId, defaultFormula: what, defaultTimePeriod: when, defaultTarget: who, defaultFilters: currentFilters })

  const handleCloseInternal = useCallback(() => {
    if (startClicked) {
      return
    }
    handleClose && handleClose()
    window.analytics.track('scenarioPlanner.input.changeParametersModalClose')
  }, [startClicked, handleClose])

  const [prevBaselineScenarioKey, setPrevBaselineScenarioKey] = useState(baselineScenarioKey)

  useEffect(() => {
    if (baselineScenarioKey !== prevBaselineScenarioKey) {
      setPrevBaselineScenarioKey(baselineScenarioKey)
      setStartClicked(false)
      handleClose && handleClose()
      window.analytics.track('scenarioPlanner.input.changeParametersRan')
    }
  }, [baselineScenarioKey])

  const [animationKey, setAnimationKey] = useState(guid())
  useCallback(() => {
    if (!open) {
      setAnimationKey(guid())
    }
  }, [open])

  return (
    <GenericModal
      maxWidth="md"
      title="Start New Bulk Export"
      actionBtnText="Start"
      handleClose={handleCloseInternal}
      open={open}
      isSubmitting={isSubmitting}>
      <div className="w-full flex flex-col font-normal">
        <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
          <div className="text-size-22px text-color-ffffff font-bold">Change Parameters</div>
          <button onClick={handleCloseInternal} className="focus:outline-none"><Close htmlColor="#ffffff" /></button>
        </div>

        <div className="w-full h-full px-10 pt-10">

          <div className="p-10 bg-color-fafafa border border-color-edeeee rounded">

            {startClicked
              ? (
                <div className="w-full" style={{ height: 309 }}>
                  <AnimatedLoader key={`AnimationLoader-${animationKey}`} className="py-18" />
                </div>
              )
              : (
                <>
                  <div className="mx-auto" style={{ maxWidth: 420 }}>
                    <div className="text-size-30px font-bold text-center">Time for another scenario?</div>
                    <div className="text-size-14px">
                      No problem, just pick from the drop-downs below.
                      <span className="italic">Please note that updating these parameters will reset all of your current situations.</span>
                    </div>
                  </div>
                  <div className="px-6 my-6 mx-auto" style={{ maxWidth: 450 }}>
                    <FormulaInputs
                      tenantId={tenantId}
                      groupId={groupId}
                      selectedFormula={selectedFormula}
                      onFormulaSelect={onFormulaSelect}
                      selectedTimePeriod={selectedTimePeriod}
                      onTimePeriodSelect={onTimePeriodSelect}
                      setSelectedTimePeriod={setSelectedTimePeriod}
                      selectedTarget={selectedTarget}
                      onTargetSelect={onTargetSelect}
                      onClearFilters={onClearFilters}
                      selectedFilters={selectedFilters}
                      onFilterSelected={onFilterSelected}
                      start={when.start ? new Date(when.start) : new Date()}
                      end={when.end ? new Date(when.end) : new Date()} />
                  </div>
                </>
              )}

          </div>
        </div>

        <div className="flex justify-center py-6">
          <button
            className={classNames('px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none',
              { 'opacity-25 pointer-events-none': startClicked })}
            onClick={onStartClick}>
            Update
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default ChangeParametersModal
