import React, { useMemo } from 'react'
import { orderBy } from 'lodash'

const DealHealthDetails = (props) => {
  const { currentOppDealHealth } = props

  const negativeFactors = useMemo(() => {
    const negative = currentOppDealHealth?.healthFactorsList.filter((factor) => factor.polarity === 'negative')
    // Only display the 3 factors with the highest weight
    const sorted = orderBy(negative, ['weight'], ['desc']).slice(0, 3)
    return (
      <div>
        {sorted.map((factor, i) => (
          <div
            key={`positive-factors-${i}`}
            className="flex flex-row  mt-10px">
            <div className="static rounded-full flex-shrink-0 bg-color-ff4947 text-size-14px" style={{ width: 10, height: 10, marginRight: '6px', marginTop: '3px' }}>&nbsp;</div>
            <div className="font-weight-400 text-size-14px leading-tight">{factor.description}</div>
          </div>
        ))}
      </div>
    )
  }, [currentOppDealHealth?.healthFactorsList])

  const positiveFactors = useMemo(() => {
    const positive = currentOppDealHealth?.healthFactorsList.filter((factor) => factor.polarity === 'positive')
    // Only display the 3 factors with the highest weight
    const sorted = orderBy(positive, ['weight'], ['desc']).slice(0, 3)
    return (
      <div>
        {sorted.map((factor, i) => (
          <div
            key={`positive-factors-${i}`}
            className="flex flex-row mt-10px">
            <div className="static rounded-full flex-shrink-0 bg-color-1dcf83 text-size-14px mr-1"
              style={{ width: 10, height: 10, marginRight: '6px', marginTop: '3px' }}>
              &nbsp;
            </div>
            <div className="font-weight-400 text-size-14px leading-tight">{factor.description}</div>
          </div>
        ))}
      </div>
    )
  }, [currentOppDealHealth?.healthFactorsList])

  return (
    <>
      <div>What's going well</div>
      {positiveFactors}
      <div className="mt-3">Where to focus</div>
      {negativeFactors}
    </>
  )
}

export default DealHealthDetails
