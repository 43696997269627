import { filter } from 'lodash'
import { useCategoryRollup, useForecastingFeature } from './hooks'
import { useForecasting } from '../../context/forecasting'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import Tippy from '@tippyjs/react'
import { hideAll, roundArrow } from 'tippy.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TeamCallTooltip = (props) => {
  const {
    currentCall,
    forecastCategory,
    ownerId
  } = props

  const {
    teamForecasts,
    updateForecastByUserLocal
  } = useForecasting()
  const forecastingFeature = useForecastingFeature()

  const { forecastCategories } = forecastingFeature

  const {
    teamCall,
    teamRollupCall,
  } = useCategoryRollup(forecastCategory, teamForecasts)

  const onUseAdjustedRollup = useCallback(() => {
    updateForecastByUserLocal({
      ownerId,
      forecastCategory,
      call: teamCall,
    })
    hideAll()
    window.analytics.track('forecasting.v3.managersTeamCall.useAdjustedRollup')
  }, [updateForecastByUserLocal, ownerId, forecastCategory, teamCall])

  const affectedCategories = filter(forecastCategories, (fc) => (fc?.includeCategories ?? []).includes(forecastCategory.key)).map((fc) => fc.name)
  let affectedCategoriesText = affectedCategories.join('|')
  if (affectedCategories.length > 1) {
    const pos = affectedCategoriesText.lastIndexOf('|')
    affectedCategoriesText = `${affectedCategoriesText.substring(0, pos)} and ${affectedCategoriesText.substring(pos + 1)}`
    affectedCategoriesText = affectedCategoriesText.replace(/\|/g, ', ')
  }

  const tooltip = useMemo(() => {
    const affectedCategories = filter(forecastCategories, (fc) => (fc?.includeCategories ?? []).includes(forecastCategory.key)).map((fc) => fc.name)
    let affectedCategoriesText = affectedCategories.join('|')
    if (affectedCategories.length > 1) {
      const pos = affectedCategoriesText.lastIndexOf('|')
      affectedCategoriesText = `${affectedCategoriesText.substring(0, pos)} and ${affectedCategoriesText.substring(pos + 1)}`
      affectedCategoriesText = affectedCategoriesText.replace(/\|/g, ', ')
    }

    return (
      <div className="px-4 py-3">
        <div>
          <div className="text-size-18px text-color-09242f font-bold my-2">Adjusted Rollup</div>
        </div>
        <div className="text-size-14px text-color-09242f font-weight-400 leading-snug">
          You've adjusted your
          {' '}
          {forecastCategory.name}
          {' '}
          rollup call from
          {' '}
          <span className="font-bold">{currentCall.toString()}</span>
          {' '}
          to
          {' '}
          <span className="font-bold">{teamRollupCall.toString()}</span>
          .  Do you want to use your adjusted rollup as your top-line call?
          {affectedCategories.length > 0
            && (
              <div className="mt-3">
                {'Note: this will affect your '}
                {pluralize('call', affectedCategories.length)}
                {' for '}
                {affectedCategoriesText}
                .
              </div>
            )}
        </div>
        <div className="flex flex-col justify-center">
          <button
            onClick={onUseAdjustedRollup}
            className="my-3 mx-auto px-8 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none">
            Yes
          </button>
        </div>
      </div>
    )
  }, [forecastCategories, forecastCategory.name, forecastCategory.key, currentCall, teamRollupCall, onUseAdjustedRollup])

  return (
    <Tippy
      offset={[0, 2]}
      duration={[0, 0]}
      maxWidth={300}
      content={tooltip}
      interactive={true}
      appendTo={() => document.body}
      arrow={roundArrow}
      theme="canopy">
      <div>
        <FontAwesomeIcon icon="info-circle" color="#A6AEBE" />
      </div>
    </Tippy>
  )
}

export default TeamCallTooltip
