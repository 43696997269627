import React, { useEffect, useCallback, useState } from 'react'
import FieldSelect from './fields/fieldSelect'
import { ParameterType } from '../../protos/src/common/realtime/query_pb'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'

const ResolvedQueryParameter = (props) => {
  const {
    user,
    parameter
  } = props

  const { setResolvedQuery, addNewParameter } = useRealtimeFilterEditor()

  const { options, key: parameterKey, label } = parameter
  const valueKey = user?.resolvedQuery?.queriesList?.[0]?.key

  const [source] = options?.sourcesList ?? [{}]
  const { fieldsList } = source
  const [fieldList] = fieldsList ?? [{}]
  const { valuesList = [] } = fieldList

  const { PARAMETER_TYPE_VALUE } = ParameterType

  const [inputValue, setInputValue] = useState()

  useEffect(() => {
    if (!valuesList?.length || !valueKey) {
      return
    }

    const selected = valuesList.find((vl) => vl.value === valueKey)
    if (!selected) {
      return
    }
    setInputValue([selected])
  }, [valueKey, valuesList])

  const handleAddValue = useCallback(() => {
    addNewParameter(parameter, PARAMETER_TYPE_VALUE)
  }, [parameter, addNewParameter])

  const handleValueChange = useCallback((options) => {
    const [option] = options

    setInputValue(options)
    setResolvedQuery(parameterKey, {
      value: option?.value,
    })
  }, [setResolvedQuery])

  return (
    <div className="self-start -ml-4">
      {user
        ? (
          <div className="flex flex-col mr-4 last:mr-0">
            <div className="mb-1 pl-4 text-color-51636a text-size-16px font-weight-500">{label}</div>
            <FieldSelect
              fullWidth
              onChange={handleValueChange}
              selectedDisplay={inputValue?.map(({ label }) => label).join(', ')}
              getIsSelected={({ value }) => inputValue?.find((iv) => iv.value === value)}
              values={valuesList} />
          </div>
        )
        : (
          <div className="filters-actions self-start">
            <button onClick={handleAddValue}>+ Add Value</button>
          </div>
        )}
    </div>
  )
}

export default ResolvedQueryParameter
