import React, { useState, useEffect } from 'react'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import numeral from 'numeral'

const CustomSlider = withStyles({
  root: {
    color: '#2e5bff',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const DealSizeSlider = (props) => {
  const { width, min, max, step, defaultValue, handleChangeCommitted } = props

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  function formatLabel(val) {
    return numeral(val).format('$0,0')
  }

  return (
    <div className="flex flex-col px-5 py-6" style={{ width }}>
      <div className="px-4 pb-2 flex justify-between text-color-4e5d7e text-size-16px">
        <div className="flex flex-col">
          <span className="uppercase text-size-12px font-weight-300 text-color-b0bac9">Min</span>
          {' '}
          <span>{formatLabel(value[0])}</span>
        </div>
        <div className="flex flex-col text-right">
          <span className="uppercase text-size-12px font-weight-300 text-color-b0bac9">Max</span>
          {' '}
          <span>{formatLabel(value[1])}</span>
        </div>
      </div>
      <div className="px-2">
        <CustomSlider
          value={value}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          valueLabelDisplay="off"
          min={min}
          max={max}
          step={step}
          aria-labelledby="range-slider"
        />
      </div>
    </div>
  )
}

export default DealSizeSlider
