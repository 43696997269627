import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Popover from '../popover'
import { usePopover } from '../../hooks/usePopover'
import { DateRange } from 'react-date-range'
import { format, parse, parseISO } from 'date-fns'
import classNames from 'classnames'
import { formatTypes } from './constants'
import FieldDebug from './fieldDebug'
import pencilSm from '../../assets/pencil-sm.png'
import Icon, { iconType } from '../icon'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import { useVersionsTooltip } from './hooks'
import { reformatDate } from '../../lib/dateFns'
import { getFieldFromObject } from './helpers'
import { daysUntil } from '../../lib/date'
import pluralize from 'pluralize'

const DateFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
    readonlyOverride = false,
    onFieldChanged,
    showDaysRelative,
  } = props

  const { label, canEdit } = fieldDefinition
  const { versions = [] } = field

  const showSubLabel = useMemo(() => {
    const field = getFieldFromObject(opportunity, 'isclosed')
    const closed = field && field.value
    return !closed
  }, [opportunity])

  const daysRelativeLabel = useMemo(() => {
    let label
    if (showDaysRelative && field && field.value) {
      const date = parse(field.value, 'yyyy-MM-dd', new Date())
      const days = daysUntil(date)
      if (days > 0) {
        label = (
          <div className="text-color-91959f text-size-12px font-normal">
            {days}
            {' '}
            {pluralize('day', days)}
            {' '}
            left
          </div>
        )
      } else if (days < 0) {
        label = (
          <div className="text-color-91959f text-size-12px font-normal text-color-ff4947">
            {Math.abs(days)}
            {' '}
            {pluralize('day', Math.abs(days))}
            {' '}
            ago
          </div>
        )
      } else {
        label = <div className="text-color-91959f text-size-12px font-normal text-color-ff4947">Today</div>
      }
    }
    return label
  }, [showDaysRelative, field])

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  const date = useMemo(() => {
    try {
      const { value } = field
      if (!!value && value !== 'null') {
        if (fieldDefinition.format === formatTypes.timestamp) {
          const d = parseISO(value)
          return isNaN(d) ? undefined : d
        } else {
          const d = parse(value, 'yyyy-MM-dd', new Date())
          return isNaN(d) ? undefined : d
        }
      }
    } catch (err) {
      // continue
    }
    return undefined
  }, [fieldDefinition, field])

  const [actualDate, setActualDate] = useState('')

  useEffect(() => {
    setActualDate(date ? format(date, 'M/d/yyyy') : '')
  }, [date])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const [dateRange, setDateRange] = useState([{
    startDate: actualDate ? new Date(actualDate) : new Date(),
    endDate: actualDate ? new Date(actualDate) : new Date(),
    key: 'selection',
  }])

  useEffect(() => {
    let d;
    try {
      d = new Date(actualDate)
    } catch (err) {
      d = new Date()
    }
    if (!(d instanceof Date && !isNaN(d))) {
      d = new Date()
    }
    setDateRange([{
      startDate: d,
      endDate: d,
      key: 'selection',
    }])
  }, [actualDate])

  const onDateRangeChange = useCallback((dateRange) => {
    const startDate = dateRange.selection.startDate.toLocaleDateString('en-US')
    const endDate = dateRange.selection.endDate.toLocaleDateString('en-US')
    if (startDate !== endDate) {
      return
    }

    setActualDate(startDate)

    onFieldChangedInternal(format(new Date(startDate), 'yyyy-MM-dd'))

    // close the popover to prevent a selection of a range since we want this component to only select a single date
    onClose()
  }, [onFieldChangedInternal])

  const className = useMemo(() => {
    const movedBack = versions.length > 1 && versions[0].value && versions[1].value && versions[0].value > versions[1].value
    const movedForward = versions.length > 1 && versions[0].value && versions[1].value && versions[0].value < versions[1].value
    return classNames('text-size-15px font-normal',
      { 'text-color-2e384d': !movedForward && !movedBack },
      { 'text-color-1dcf83 font-bold': movedForward },
      { 'text-color-fb6c6a font-bold': movedBack })
  }, [versions])

  const versionsTooltip = useVersionsTooltip(versions, (val) => (val ? reformatDate(val) : ''))

  return (
    <div className="DateFieldRowItem">
      <div
        className={classNames('flex items-center text-right', { 'group cursor-pointer': !readonly })}>
        <Tippy
          onShow={() => versions.length > 1}
          offset={[0, 4]}
          duration={[0, 0]}
          maxWidth={800}
          content={versionsTooltip}
          arrow={roundArrow}
          theme="canopy">
          <div
            {...!readonly && { onClick }}
            className={className}>
            {actualDate || '-'}
          </div>
        </Tippy>
        <div {...!readonly && { onClick }} className="invisible group-hover:visible" style={{ transform: 'translate(4px, 0px)' }}>
          <Icon type={iconType.IMAGE} src={pencilSm} />
        </div>
      </div>
      {(showSubLabel && daysRelativeLabel) && daysRelativeLabel}
      <Popover
        style={{ zIndex: 999999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}>
        <div>
          <div className="flex items-center justify-between px-3">
            <div className="py-2 text-size-16px">Choose a Date</div>
          </div>
          <DateRange
            editableDateInputs={false}
            onChange={onDateRangeChange}
            moveRangeOnFirstSelection={true}
            ranges={dateRange}
            staticRanges={[]}
            inputRanges={[]}
            showPreview={false}
            showDateDisplay={false} />
        </div>
      </Popover>
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </div>
  )
}

export default DateFieldRowItem
