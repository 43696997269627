import { useMemo } from 'react'
import { has } from 'lodash'

export function useDealFilters(hash) {
  const filters = useMemo(() => {
    const filterHeaders = []
    if (has(hash, 'status')) {
      filterHeaders.push({
        key: 'filter|status|eq',
        value: hash.status
      })
    }
    if (has(hash, 'stage')) {
      filterHeaders.push({
        key: 'filter|stageName|eq',
        value: hash.stage
      })
    }
    if (has(hash, 'forecast')) {
      filterHeaders.push({
        key: 'filter|forecastCategory|eq',
        value: hash.forecast
      })
    }
    if (has(hash, 'dealSize')) {
      filterHeaders.push({
        key: 'filter|amount|betweenIn',
        value: hash.dealSize.replace(',', '|')
      })
    }
    if (has(hash, 'owner')) {
      filterHeaders.push({
        key: 'filter|ownerId|eq',
        value: hash.owner
      })
    }
    if (has(hash, 'currentPeriod') && !(has(hash, 'status') && hash.status === 'pushed')) {
      filterHeaders.push({
        key: 'filter|currentPeriod|eq',
        value: hash.currentPeriod
      })
    }
    if (has(hash, 'sort')) {
      filterHeaders.push({
        key: 'sort',
        value: hash.sort
      })
    } else {
      filterHeaders.push({
        key: 'sort',
        value: 'insightCount DESC'
      })
    }
    return filterHeaders
  }, [hash])

  return filters
}
