import React, { useCallback } from 'react'
import Signal from './signal'
import SignalDebug from './signalDebug'
import classNames from 'classnames'
import Avatar from '../avatar/avatar'
import get from 'lodash/get'
import { resolveCompanyImageUrl } from '../../lib/image'
import { useUserImageUrl } from '../../hooks/useUserImageUrl'
import { useDebug } from '../../context/debug'

const SignalsList = (props) => {
  const { id, level, signals, avatar, itemClassName = '', itemStyle, configOverride } = props

  const { debug } = useDebug()

  const { userImageUrl } = useUserImageUrl('[URL]')

  const renderAvatar = useCallback((signal) => {
    if (avatar) {
      const { targetEntity = {} } = signal
      const { type } = targetEntity
      let imageUrl = ''
      let text = ''

      if (type === 'PERSON') {
        imageUrl = userImageUrl.replace('[URL]', get(targetEntity, 'personEntity.photoUrl', ''))
        text = get(targetEntity, 'personEntity.firstName', '').charAt(0)
      } else if (type === 'OPPORTUNITY') {
        imageUrl = resolveCompanyImageUrl({ website: get(targetEntity, 'opportunityEntity.website', '') })
        text = get(targetEntity, 'opportunityEntity.name', '').charAt(0)
      }

      return (
        <div className="w-12 h-12 mx-6 mt-12">
          <Avatar className={classNames('w-10 h-10 mr-2', { 'rounded-full': type === 'PERSON' })} imageUrl={imageUrl} text={text} />
        </div>
      )
    }
  }, [avatar])

  return (
    <>

      {signals.map((signal, index) => {
        return (
          <React.Fragment key={`${signal.id}-${index}`}>

            {debug && <SignalDebug signal={signal} />}

            <div
              className={classNames(itemClassName)}
              style={itemStyle}>

              {renderAvatar(signal)}

              <Signal
                id={id}
                level={level}
                signal={signal}
                debug={debug}
                configOverride={configOverride} />

            </div>
          </React.Fragment>
        )
      })}

    </>
  )
}

export default SignalsList
