import React from 'react'
import numeral from 'numeral'
import TrendChart from '../charts/trendChart'
import CountUp from 'react-countup'
import orderBy from 'lodash/orderBy'

const TrendListItem = (props) => {
  const { id, trend, aggregateBy } = props
  const { name, dailyValuesList, weeklyValuesList, monthlyValuesList, dailyAverage, weeklyAverage, monthlyAverage } = trend
  let seriesData = []
  let perLabel = ''
  let average = 0

  switch (aggregateBy) {
    case 'weekly':
      seriesData = orderBy(weeklyValuesList, (v) => v.date)
      perLabel = '/week'
      average = weeklyAverage
      break
    case 'monthly':
      seriesData = orderBy(monthlyValuesList, (v) => v.date)
      perLabel = '/month'
      average = monthlyAverage
      break
    default:
      seriesData = orderBy(dailyValuesList, (v) => v.date)
      perLabel = '/day'
      average = dailyAverage
  }

  return (
    <div className="pt-4 border-t border-color-eaeaea">

      <div className="flex justify-between">
        <div className="text-size-16px text-color-4e5d7e">{name}</div>
        <div className="text-size-14px text-color-151d49">
          <CountUp end={average * 100} duration={0.5} formattingFn={(value) => numeral(value / 100).format('0,0.[00]')} />
          {perLabel}
        </div>
      </div>

      <TrendChart id={id} seriesData={seriesData} average={average} aggregateBy={aggregateBy} />

    </div>
  )
}

export default TrendListItem
