import React from 'react'
import { Redirect } from 'react-router-dom'
import { useRoutes } from '../../context/routes'

const RepSummary = (props) => {
  const { routes } = useRoutes()
  return (
    <Redirect to={routes.performance} />
  )
}

export default RepSummary
