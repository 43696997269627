import { grpcInvoke } from '../grpc'
import { ResourceType, NoteStatus, NoteVisibility } from '../protos/src/common/notes/notes_pb'
import { NotificationType } from '../protos/src/common/notification/notification_pb'
import { SaveNoteRequest, GetByAttachmentRequest, DeleteNoteRequest } from '../protos/src/services/notes/notes_pb'
import { DeliverNoteRequest } from '../protos/src/services/api/api_pb'
import {
  saveNoteRequested,
  saveNoteSuccess,
  saveNoteFailed,
  getNotesByAttachmentRequested,
  getNotesByAttachmentSuccess,
  getNotesByAttachmentFailed,
  deleteNoteRequested,
  deleteNoteSuccess,
  deleteNoteFailed,
  deliverNoteRequested,
  deliverNoteSuccess,
  deliverNoteFailed,
} from '../actions/noteService'
import { toNote, toPerson } from '../grpc/converters'
import { forEach, get, orderBy } from 'lodash'
import { publish } from '../gml/eventBus'
import { eventTypes } from '../gml/eventBus/eventTypes'

export const ResourceTypes = ResourceType
export const NoteStatuses = NoteStatus
export const NoteVisibilities = NoteVisibility
export const NotificationTypes = NotificationType

const getMentions = (elements, mentions) => {
  forEach(elements, (el) => {
    if (el.type === 'mention') {
      mentions.push(el)
    } else {
      getMentions(get(el, 'children', []), mentions)
    }
  })
}

export const getNotesByAttachment = (id, type) => {
  return async (dispatch, getState) => {
    const request = new GetByAttachmentRequest()
    request.setResourceId(id)
    request.setType(type)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getNotesByAttachmentRequested())
      },
      onSuccess: (obj) => {
        obj.notesList = orderBy(obj.notesList, ['createdAt.seconds'], ['desc'])
        dispatch(getNotesByAttachmentSuccess(obj))
      },
      onError: (err) => {
        dispatch(getNotesByAttachmentFailed(err))
      },
      grpcMethod: 'getNotesByAttachment',
      debug: false,
    })
  }
}

export const saveNote = (note, id, type) => {
  return async (dispatch, getState) => {
    const mentions = []
    getMentions(JSON.parse(note.raw), mentions)
    note.mentionsList = mentions.map((m) => {
      return {
        noteId: note.id,
        resourceId: m.resourceId,
        type: ResourceTypes.OPPORTUNITY,
        label: `${m.mentionText}`,
      }
    })
    const request = new SaveNoteRequest()
    request.setNote(toNote(note))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(saveNoteRequested())
      },
      onSuccess: (obj) => {
        dispatch(saveNoteSuccess({ note: obj, activeNote: getState().activeNote }))
        if (id && type) {
          dispatch(getNotesByAttachment(id, type))
          publish(eventTypes.newNote)
        }
      },
      onError: (err) => {
        dispatch(saveNoteFailed(err))
      },
      grpcMethod: 'saveNote',
      debug: false,
    })
  }
}

export const deleteNote = (note, id, type) => {
  return async (dispatch, getState) => {
    const request = new DeleteNoteRequest()
    request.setNote(toNote(note))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(deleteNoteRequested())
      },
      onSuccess: (obj) => {
        dispatch(deleteNoteSuccess(obj))
        dispatch(getNotesByAttachment(id, type))
      },
      onError: (err) => {
        dispatch(deleteNoteFailed(err))
      },
      grpcMethod: 'deleteNote',
      debug: false,
    })
  }
}

export const deliverNote = (note, recipients, customMessage, loggedInUser) => {
  return async (dispatch, getState) => {
    const request = new DeliverNoteRequest()
    request.setNote(toNote(note))
    request.setRecipientsList(recipients)
    request.setDeliveryType(NotificationTypes.EMAIL)
    request.setCustomMessage(customMessage)
    request.setSender(toPerson({ name: get(loggedInUser, 'name', '') }))
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(deliverNoteRequested())
      },
      onSuccess: (obj) => {
        dispatch(deliverNoteSuccess(obj))
      },
      onError: (err) => {
        dispatch(deliverNoteFailed(err))
      },
      grpcMethod: 'deliverNote',
      debug: false,
    })
  }
}
