import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pluralize from 'pluralize'
import DealListItem from './dealListItem'
import classNames from 'classnames'
import { useFormatCurrency } from '../../../hooks/useFormatCurrency'

const CategoryDeals = (props) => {
  const {
    forecastCategoryWithOpps
  } = props

  const { formatCurrency } = useFormatCurrency()

  const { id, name, opps = [], oppTotal } = forecastCategoryWithOpps

  const [isOpen, setIsOpen] = useState(false)

  const toggleDeals = useCallback(() => {
    setIsOpen(!isOpen)
    window.analytics.track('forecasting.v2.dock.categoryDeals.toggleClicked')
  }, [isOpen])

  return (
    <div className={classNames('mx-6', { 'border-b last:border-b-0 border-color-c9ced0': isOpen })}>
      <div
        className="flex justify-between my-4">
        <div>
          <div className="text-size-16px text-color-09242f pr-1">{name}</div>
          <div onClick={toggleDeals} className="flex items-center text-size-14px text-color-818e93 font-weight-400 pt-1 cursor-pointer">
            <span>{pluralize('deal', opps.length, true)}</span>
            <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} className="text-color-818e93 ml-1" style={{ width: 14, height: 14 }} />
          </div>
        </div>
        <div className="text-size-16px text-color-818e93 font-weight-600">{formatCurrency(oppTotal)}</div>
      </div>
      {isOpen
        && (
          <>
            {opps.map((opp, index) => (
              <DealListItem
                key={`DealListItem-${id}-${index}`}
                opp={opp} />
            ))}
          </>
        )}
    </div>
  )
}

export default CategoryDeals
