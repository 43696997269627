import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popover from '../popover'
import { usePopover } from '../../hooks/usePopover'
import { timePeriodOptions } from './constants'

const TimePeriodSelector = (props) => {
  const {
    options = timePeriodOptions,
    selectedOption = {},
    onChange,
  } = props

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const onChangeInternal = useCallback((o) => {
    onChange && onChange(o)
    onClose()
  }, [onChange])

  return (
    <>
      <div onClick={onClick} className="w-28 flex items-center justify-between border border-color-d6d9e6 rounded cursor-pointer">
        <div className="text-color-a0a8bb text-size-14px font-weight-500 mx-3 py-1">{selectedOption.label || '-'}</div>
        <FontAwesomeIcon icon="chevron-down" className="mx-3 text-color-a0a8bb" size="sm" />
      </div>
      <Popover
        style={{ zIndex: 999999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}>
        <div className="flex flex-col" style={{ minWidth: 140 }}>
          {options.map((o, index) => (
            <button
              key={`TimePeriodSelectorOption-${index}`}
              onClick={() => onChangeInternal(o)}
              className="px-4 py-2 text-size-16px text-left focus:outline-none hover:bg-gradient-dark-gray hover:text-color-ffffff">
              {o.label}
            </button>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default TimePeriodSelector
