import React, { useCallback, useEffect, useMemo, useState } from 'react'
import pencilSm from '../../assets/pencil-sm.png'
import { moveCursorToEnd } from '../../lib/input'
import Icon, { iconType } from '../icon'
import { useFormatValue } from './hooks'
import FieldDebug from './fieldDebug'
import { getFormatLength, getFormatType } from './helpers'
import { formatTypes } from './constants'
import Popover from '../popover'
import { usePopover } from '../../hooks/usePopover'

const StringFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
    readonlyOverride = false,
    onFieldChanged,
  } = props

  const { label, canEdit, required, format } = fieldDefinition

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const formatType = useMemo(() => {
    return getFormatType(format)
  }, [format])

  const isMultiline = useMemo(() => {
    return formatType === formatTypes.plaintextarea || formatType === formatTypes.richtextarea
  }, [formatType])

  const maxLength = useMemo(() => {
    const len = getFormatLength(format)
    return !len ? {} : { maxLength: len }
  }, [format])

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  const [inputValue, setInputValue] = useState(field.value || '')
  const [prevInputValue, setPrevInputValue] = useState(field.value || '')

  const { formatValue } = useFormatValue()

  const formattedValue = formatValue(inputValue, fieldDefinition.format)

  useEffect(() => {
    const { value = '' } = field
    setPrevInputValue(value)
    setInputValue(value)
  }, [field])

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  const handleInputClick = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }, [])

  const onPopoverClose = useCallback(() => {
    setInputValue(prevInputValue)
    onClose()
  }, [prevInputValue])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      onPopoverClose()
    } else if (e.key === 'Enter') {
      if (!isMultiline) {
        e.preventDefault()
      }
    }
  }, [isMultiline, onPopoverClose])

  const onFocus = useCallback((e) => {
    if (e && e.currentTarget) {
      const inputEl = e.currentTarget
      inputEl.focus()
      moveCursorToEnd(inputEl)
    }
  }, [])

  const onSave = useCallback(() => {
    if (inputValue !== prevInputValue) {
      onFieldChangedInternal(inputValue)
    }
    onClose()
  }, [inputValue, prevInputValue, onFieldChangedInternal])

  return (
    <div className="StringFieldRowItem">
      <div className="flex items-center text-left group cursor-pointer">
        <div
          onClick={onClick}
          className="w-full text-color-2e384d text-size-15px font-normal max-lines-2">
          {formattedValue || '-'}
        </div>
        {!readonly
          && (
            <div {...!readonly && { onClick }} className="invisible group-hover:visible" style={{ transform: 'translate(4px, 0px)' }}>
              <Icon type={iconType.IMAGE} src={pencilSm} />
            </div>
          )}
      </div>
      <Popover
        style={{ zIndex: 999999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        placement="bottom-start">
        <div className="flex flex-col px-4 pb-4 pt-2">
          <div className="flex items-center justify-between leading-loose">
            <div className="text-color-818e93 text-size-16px font-weight-700 truncate">{label}</div>
            {!readonly
              && (
                <button
                  onClick={onSave}
                  className="text-color-1d4dcf text-size-14px font-weight-600 uppercase focus:outline-none">
                  Save
                </button>
              )}
          </div>
          <textarea
            value={inputValue}
            onChange={handleInputChange}
            onClick={handleInputClick}
            onKeyDown={handleKeyDown}
            className="px-3 py-1 text-size-15px font-normal border border-color-b0bac9 rounded focus:outline-none"
            cols={40}
            rows={4}
            {...maxLength}
            autoFocus={true}
            onFocus={onFocus}
            readOnly={readonly} />
        </div>
      </Popover>
      <FieldDebug
        debug={debug}
        data={{ fieldDefinition, field }} />
    </div>
  )
}

export default StringFieldRowItem
