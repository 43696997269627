import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const SearchBox = (props) => {
  const {
    className,
    value,
    onChange,
    onClear,
    placeholder = 'Search',
    autoFocus,
    disabled,
    disableHint,
    dense
  } = props

  const [showHint, setShowHint] = useState(!autoFocus)

  const onInputFocus = useCallback(() => {
    setShowHint(false)
  }, [])

  const onInputBlur = useCallback(() => {
    setShowHint(!value.length && true)
  }, [value])

  const onClearInternal = useCallback(() => {
    onClear && onClear()
    setShowHint(true)
  }, [onClear])

  return (
    <div className={className}>
      <div className="w-full relative">
        <FontAwesomeIcon icon="search" size="sm" className="absolute flex text-color-a0a8bb left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
        {value.length > 0
          && (
            <button
              className="flex itemse-center absolute text-color-a0a8bb hover:text-color-151d49 focus:outline-none right-2 top-1/2 transform -translate-y-1/2"
              onClick={onClearInternal}>
              <FontAwesomeIcon icon="times-circle" size="sm" className="pointer-events-none" />
            </button>
          )}
        <input
          id="searchBox"
          className={classNames(
            'search-box z-0 w-full outline-none border border-color-d6d9e6 rounded-full pl-8 pr-6 py-1',
            {
              'pointer-events-none opacity-50': disabled,
              'text-size-14px': dense
            }
          )}
          style={{ ...dense && { height: 28 } }}
          autoComplete="off"
          onChange={onChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          value={value}
          type="text"
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled} />
        <div
          style={{ top: dense ? 4 : 7, right: 12, lineHeight: '18px' }}
          className={classNames(
            'absolute rounded text-center text-color-a0a8bb border border-color-a0a8bb font-bold w-5 h-5',
            { hidden: !showHint || disableHint }
          )}>
          /
        </div>
      </div>
    </div>
  )
}

export default SearchBox
