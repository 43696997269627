import { createAction } from '@reduxjs/toolkit'

export const clearAuth = createAction('clearAuth')

export const setHeader = createAction('setHeader')
export const removeHeader = createAction('removeHeader')

export const setMainWidth = createAction('setMainWidth')

export const clearTenants = createAction('clearTenants')
export const clearTeam = createAction('clearTeam')
export const clearOpportunities = createAction('clearOpportunities')
export const clearSearchedOpportunities = createAction('clearSearchedOpportunities')
export const clearPipelineOpportunities = createAction('clearPipelineOpportunities')
export const clearStrengths = createAction('clearStrengths')
export const clearWeaknesses = createAction('clearWeaknesses')
export const clearApiActivity = createAction('clearApiActivity')
export const clearApiActivityByKey = createAction('clearApiActivityByKey')
export const clearPipelineReport = createAction('clearPipelineReport')
export const clearPipelineReportOpportunities = createAction('clearPipelineReportOpportunities')
export const clearOppSettings = createAction('clearOppSettings')
export const clearManagers = createAction('clearManagers')
export const clearRawForecastCategoriesForUsers = createAction('clearRawForecastCategoriesForUsers')
export const clearModalOpportunities = createAction('clearModalOpportunities')
export const clearPeople = createAction('clearPeople')
export const clearNlgTester = createAction('clearNlgTester')

export const clearSignals = createAction('clearSignals')
export const clearSummaryStats = createAction('clearSummaryStats')
export const clearKeyMetrics = createAction('clearKeyMetrics')
export const clearHeaderMetrics = createAction('clearHeaderMetrics')

export const updateLocation = createAction('updateLocation')
export const updateSearch = createAction('updateSearch')

export const clearImpersonation = createAction('clearImpersonation')
export const clearImpersonationUsers = createAction('clearImpersonationUsers')
export const setImpersonation = createAction('setImpersonation')

export const updateForecastContext = createAction('updateForecastContext')

export const updateModal = createAction('updateModal')
export const clearModal = createAction('clearModal')

export const setLoadingScreen = createAction('setLoadingScreen')

export const updateForecastIsLoading = createAction('updateForecastIsLoading')

export const clearPipelineReportSelections = createAction('clearPipelineReportSelections')
export const updatePipelineReportSelection = createAction('updatePipelineReportSelection')
export const setPipelineReportRoles = createAction('setPipelineReportRoles')
export const removePipelineReportRole = createAction('removePipelineReportRole')
export const setPipelineReportFilters = createAction('setPipelineReportFilters')
export const removePipelineReportFilter = createAction('removePipelineReportFilter')
export const setPipelineReportUsers = createAction('setPipelineReportUsers')
export const removePipelineReportUser = createAction('removePipelineReportUser')

export const clearNotes = createAction('clearNotes')
export const updateActiveNote = createAction('updateActiveNote')
export const clearNoteSearchedOpportunities = createAction('clearNoteSearchedOpportunities')

export const clearForecastSimulationWorkspace = createAction('clearForecastSimulationWorkspace')
export const setForecastSimulationWorkspace = createAction('setForecastSimulationWorkspace')
export const upsertScenario = createAction('upsertScenario')
export const deleteScenario = createAction('deleteScenario')
export const setActiveScenarioKey = createAction('setActiveScenarioKey')
export const commitChangedInput = createAction('commitChangedInput')
export const clearFormulaInputs = createAction('clearFormulaInputs')
export const clearChangedInputs = createAction('clearChangedInputs')
export const clearHiddenScenarios = createAction('clearHiddenScenarios')
export const toggleHiddenScenario = createAction('toggleHiddenScenario')
export const updateScenarioLabel = createAction('updateScenarioLabel')
export const setRunFailed = createAction('setRunFailed')

export const setPageContext = createAction('setPageContext')

export const setDock = createAction('setDock')

export const clearSearchedOpps = createAction('clearSearchedOpps')
export const clearOppDetail = createAction('clearOppDetail')
export const clearDealHealthById = createAction('clearDealHealthById')

export const clearSearchFilters = createAction('clearSearchFilters')
export const setSearchFilter = createAction('setSearchFilter')
export const setSearchFilters = createAction('setSearchFilters')
export const removeSearchFilter = createAction('removeSearchFilter')
export const removePendingSearchFilters = createAction('removePendingSearchFilters')
export const removeAllSearchFilters = createAction('removeAllSearchFilters')
export const removeSearchOptionFilter = createAction('removeSearchOptionFilter')
export const applySearchFilters = createAction('applySearchFilters')
export const setSearchText = createAction('setSearchText')
export const updateOppAfterPatch = createAction('updateOppAfterPatch')

export const clearIntegrationObject = createAction('clearIntegrationObject')

export const setForecast = createAction('setForecast')
export const clearForecastByTeam = createAction('clearForecastByTeam')
export const clearForecastByUserLocal = createAction('clearForecastByUserLocal')
export const updateLocalForecastedOpp = createAction('updateLocalForecastedOpp')
export const clearLocalForecastOpps = createAction('clearLocalForecastOpps')
export const clearForecastOpps = createAction('clearForecastOpps')
export const clearForecastHistory = createAction('clearForecastHistory')

export const clearDashboardMetrics = createAction('clearDashboardMetrics')

export const clearCurrentFilters = createAction('clearCurrentFilters')
export const setCurrentFilters = createAction('setCurrentFilters')
export const setViewInitialized = createAction('setViewInitialized')

export const clearActivityTimeline = createAction('clearActivityTimeline')

export const setGrpcPrefix = createAction('setGrpcPrefix')
