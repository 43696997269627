import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core'
import { RemoveScroll } from 'react-remove-scroll'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'inherit', // inhert moves the modal content to the top.  the default is vertically centered.
  },
  paper: {
    borderRadius: 10,
  },
}))

const GenericModal = (props) => {
  const {
    maxWidth,
    open,
    isSubmitting,
    handleClose,
    handleSubmit,
    children,
  } = props

  const classes = useStyles()

  useEffect(() => {
    if (open) {
      const documentWidth = document.documentElement.clientWidth
      const windowWidth = window.innerWidth
      const scrollBarWidth = windowWidth - documentWidth
      document.body.style.paddingRight = scrollBarWidth
      document.body.classList.add('no-scrollbar')
    } else {
      document.body.style.paddingRight = 0
      document.body.classList.remove('no-scrollbar')
    }
  }, [open])

  return (
    <RemoveScroll enabled={open}>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
        scroll="paper"
        BackdropProps={{
          style: {
            pointerEvents: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(3px)',
          },
        }}
        classes={{
          container: classes.container,
          paper: classes.paper,
        }}>
        <div className="pointer-events-auto">
          {children}
        </div>
      </Dialog>
    </RemoveScroll>
  )
}

export default GenericModal
