import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback } from '../grpc'
import { toAdjustOrgCanvasTileLayoutRequest, toGetCanvasTileRequest, toUpsertCanvasTileRequest } from '../grpc/converters'
import { ObjectType } from '../grpc/enums'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { cloneDeep, find, orderBy } from 'lodash'

const CanvasItemContext = React.createContext()

export function CanvasItemProvider({ canvasTile, children }) {
  const { actingTenantId, actingUserId } = useAuth()
  const { notifyError } = useNotification()

  const [key, setKey] = useState(undefined)
  const [tile, setTile] = useState(canvasTile)
  const [isFetching, setIsFetching] = useState(false)
  const [isUpserting, setIsUpserting] = useState(false)
  const [pendingFilter, setPendingFilter] = useState(undefined)

  const name = useMemo(() => {
    return tile?.tile?.name
  }, [tile])

  const canvasKey = useMemo(() => {
    return tile?.canvasKey
  }, [tile])

  const tileKey = useMemo(() => {
    return tile?.tileKey
  }, [tile])

  const rawGML = useMemo(() => {
    return tile?.tile?.realtimeView?.view?.view
  }, [tile])

  const nlgMap = useMemo(() => {
    return tile?.tile?.realtimeView?.view?.nlgMap ?? []
  }, [tile])

  const queryKey = useMemo(() => {
    return tile?.tile?.realtimeView?.view?.queryKey
  }, [tile])

  const queryObjectType = useMemo(() => {
    return tile?.tile?.realtimeView?.view?.query?.objectSource?.objectType ?? ObjectType.OBJECT_TYPE_UNSPECIFIED
  }, [tile])

  const availableParameters = useMemo(() => {
    return tile?.tile?.realtimeView?.view?.query?.availableParameters
  }, [tile])

  const userQueryParametersList = useMemo(() => {
    const uqpList = cloneDeep(tile?.tile?.realtimeView?.userQueryParametersList ?? [])
    if (pendingFilter) {
      // since we are modifying the list here, we should always clone it before modifying
      uqpList.push(pendingFilter)
    }
    return uqpList.map((uqp) => {
      uqp.value = uqp.id
      uqp.label = uqp.name || '\u00A0'
      return uqp
    })
  }, [tile, pendingFilter])

  const defaultUserQueryParameter = useMemo(() => {
    return tile?.layout?.defaultUserQueryParameter
  }, [tile])

  const orgDefaultUserQueryParameter = useMemo(() => {
    return tile?.orgDefaultLayout?.defaultUserQueryParameter
  }, [tile])

  const defaultUserFilter = useMemo(() => {
    return find(userQueryParametersList, (uqp) => uqp.id === defaultUserQueryParameter)
  }, [userQueryParametersList, defaultUserQueryParameter])

  const defaultOrgFilter = useMemo(() => {
    return find(userQueryParametersList, (uqp) => uqp.id === orgDefaultUserQueryParameter)
  }, [userQueryParametersList, orgDefaultUserQueryParameter])

  const getCanvasTile = useGrpcCallback({
    onSuccess: (obj) => {
      if (obj?.tile?.realtimeView?.userQueryParametersList) {
        obj.tile.realtimeView.userQueryParametersList = orderBy(obj.tile.realtimeView.userQueryParametersList, (p) => p.sort)
      }
      setTile(obj)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
      notifyError('Error saving filter')
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getCanvasTile',
    debug: false
  }, [])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const upsertCanvasTileUserQueryParameters = useGrpcCallback({
    onSuccess: (res) => {
      setIsUpserting(false)
      invalidate()
    },
    onError: (err) => {
      setIsUpserting(false)
      notifyError('Error saving filter')
    },
    onFetch: () => setIsUpserting(true),
    grpcMethod: 'upsertCanvasTileUserQueryParameters',
    debug: false
  }, [])

  const adjustCanvasTileLayout = useGrpcCallback({
    onSuccess: (res) => {
      invalidate()
    },
    onError: (err) => {
      notifyError('Error setting default filter')
    },
    grpcMethod: 'adjustCanvasTileLayout',
    debug: false
  }, [])

  const setDefault = useCallback((userQueryParametersId) => {
    if (userQueryParametersId) {
      const request = toUpsertCanvasTileRequest({
        tenantId: actingTenantId,
        userId: actingUserId,
        canvasKey,
        canvasTile: {
          ...tile,
          tenantId: actingTenantId,
          userId: actingUserId,
          canvasKey,
          tileKey,
          layout: {
            defaultUserQueryParameter: userQueryParametersId
          }
        }
      })
      adjustCanvasTileLayout(request)
    }
  }, [adjustCanvasTileLayout, tile, canvasKey, tileKey])

  const adjustOrgCanvasTileLayout = useGrpcCallback({
    onSuccess: (res) => {
      invalidate()
    },
    onError: (err) => {
      notifyError('Error setting default org filter')
    },
    grpcMethod: 'adjustOrgCanvasTileLayout',
    debug: false
  }, [])

  const setOrgDefault = useCallback((userQueryParametersId) => {
    if (userQueryParametersId) {
      const request = toAdjustOrgCanvasTileLayoutRequest({
        tenantId: actingTenantId,
        canvasKey,
        tileKey,
        userQueryParametersId
      })
      adjustOrgCanvasTileLayout(request)
    }
  }, [adjustOrgCanvasTileLayout, canvasKey, tileKey])

  useEffect(() => {
    if (key) {
      const request = toGetCanvasTileRequest({
        tenantId: actingTenantId,
        userId: actingUserId,
        canvasKey,
        tileKey
      })
      getCanvasTile(request)
    }
  }, [key])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      isUpserting,
      invalidate,
      canvasTile: tile,
      canvasKey,
      tileKey,
      name,
      rawGML,
      nlgMap,
      queryKey,
      queryObjectType,
      availableParameters,
      userQueryParametersList,
      defaultUserQueryParameter,
      orgDefaultUserQueryParameter,
      defaultUserFilter,
      defaultOrgFilter,
      upsertCanvasTileUserQueryParameters,
      pendingFilter,
      setPendingFilter,
      setDefault,
      setOrgDefault
    }
  }, [isFetching, isUpserting, invalidate, tile, canvasKey, tileKey, name, rawGML, nlgMap,
    queryKey, queryObjectType, availableParameters, userQueryParametersList,
    defaultUserQueryParameter, orgDefaultUserQueryParameter, defaultUserFilter, defaultOrgFilter,
    upsertCanvasTileUserQueryParameters, pendingFilter,
    setDefault, setOrgDefault])

  return <CanvasItemContext.Provider value={contextValue}>{children}</CanvasItemContext.Provider>
}

export function useCanvasItem() {
  const context = React.useContext(CanvasItemContext)
  if (context === undefined) {
    throw new Error('useCanvasItem must be used within a CanvasItemProvider')
  }
  return context
}
