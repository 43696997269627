import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useStore } from 'react-redux'
import { useRoutes } from '../../context/routes'
import SideMenuItem from './sideMenuItem'
import { stopImpersonating as stopImpersonatingLegacy } from '../../services/impersonationService'
import { useLocationData } from '../location/hooks'
import Icon from '../icon'
import { useAuth } from '../../context/auth'
import useKeyPressEffect, { KeyPressHandler } from '../../hooks/useKeyPress'
import { copyToClipboard } from '../../lib/clipboard'

const SystemAdminMenu = () => {
  const { stopImpersonating } = useAuth()

  const history = useHistory()

  const { routes } = useRoutes()

  const { isDebug } = useLocationData()

  const tenantsMatch = useRouteMatch(routes.tenants)

  const store = useStore()

  const dispatch = useDispatch()
  const onTenantsClick = useCallback(() => {
    dispatch(stopImpersonatingLegacy())
    stopImpersonating()
    history.push(routes.tenants)
  }, [dispatch, history, routes.tenants, stopImpersonating])

  // useKeyPressEffect({
  //   targetKey: 't',
  //   onDoubleTap: onTenantsClick
  // }, [onTenantsClick])

  return (
    <>
      <KeyPressHandler
        keyPressProps={{
          doubleTap: true,
          targetKey: 't'
        }}>
        <SideMenuItem
          label="Tenants"
          isCurrent={tenantsMatch}
          icon={<Icon iconName="unlock" color="#a6b2cf" style={{ width: 20, height: 20 }} />}
          onClick={onTenantsClick} />
      </KeyPressHandler>

      {isDebug
        && (
          <SideMenuItem
            label="Copy Redux State"
            isCurrent={false}
            icon={<Icon iconName="unlock" color="#a6b2cf" style={{ width: 20, height: 20 }} />}
            onClick={() => copyToClipboard(store.getState(), true)} />
        )}
    </>
  )
}

export default SystemAdminMenu
