import React, { useMemo } from 'react'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useSelector } from 'react-redux'
import { featureSettingSelector } from '../../selectors'
import { featureNames } from '../../constants/featureNames'
import classNames from 'classnames'
import RepStrengthsAndWeaknessesCanvas from '../canvas/repStrengthsAndWeaknessesCanvas'
import RepMetricsCanvas from '../canvas/repMetricsCanvas'
import ActivityTrends from './activityTrends'
import { useGroups } from '../../context/groups'

const RepScorecard = (props) => {
  const { userId: uId } = props

  const { loggedInUserId, loggedInUser } = useLoggedInUser()
  const { findGroupById } = useGroups()

  const userId = useMemo(() => {
    return uId || loggedInUserId
  }, [uId, loggedInUserId])

  const user = useMemo(() => {
    if (userId === loggedInUserId) {
      return loggedInUser
    } else {
      return findGroupById(userId) || {}
    }
  }, [userId, findGroupById, loggedInUserId, loggedInUser])

  const activityTrendsFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.activityTrends }))

  const hideActivityTrends = useMemo(() => {
    const enabled = activityTrendsFeature?.payload?.enabled ?? false
    return !enabled
  }, [activityTrendsFeature])

  return (
    <>

      <RepStrengthsAndWeaknessesCanvas
        userId={userId} />

      <div className="flex mr-4 mt-8">
        <div className={classNames('w-full', { 'w-8/12': !hideActivityTrends })}>
          <RepMetricsCanvas
            userId={userId} />
        </div>
        <div className={classNames('ml-8', { hidden: hideActivityTrends }, { 'block w-4/12': !hideActivityTrends })}>
          <ActivityTrends user={user} />
        </div>
      </div>

    </>
  )
}

export default RepScorecard
