import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

const ShowHideContainer = (props) => {
  const {
    maxHeight = 100,
    children
  } = props

  const [showContent, setShowContent] = useState(false)
  const [overflows, setOverflows] = useState(false)
  const [contentMaxHeight, setContentMaxHeight] = useState(0)

  const containerRef = useRef()
  const contentWrapper = useRef()

  useEffect(() => {
    if (containerRef.current && contentWrapper.current) {
      const container = getComputedStyle(containerRef.current)
      const content = getComputedStyle(contentWrapper.current)

      const containerHeight = container.height.replace('px', '') * 1
      const contentHeight = content.height.replace('px', '') * 1

      setContentMaxHeight(contentHeight)
      setOverflows(contentHeight > containerHeight)
    }
  }, [containerRef.current, contentWrapper.current])

  return (
    <div className="w-full flex flex-col items-start">
      <div
        style={{ maxHeight: (showContent) ? contentMaxHeight : maxHeight }}
        ref={containerRef}
        className={classNames('w-full transition-max-height duration-200', { 'overflow-hidden': !showContent })}>
        <div ref={contentWrapper}>
          {children}
        </div>
      </div>
      {overflows && (
        <button
          style={{ pointerEvents: 'initial' }}
          className="my-2 font-bold text-color-2e5bff text-size-13px"
          onClick={() => setShowContent(!showContent)}>
          {showContent ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  )
}

export default ShowHideContainer
