import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getHeaderMetricsByUserKey } from '../../actions/signalService'
import { apiSuccessSelector } from '../../selectors'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import find from 'lodash/find'
import get from 'lodash/get'
import SignalDebug from '../signals/signalDebug'
import ErrorBoundary from '../../gml/controls/errorBoundary'
import EmptyGoals from '../empty/emptyGoals'
import { metricNames } from '../header/constants'

const Goals = (props) => {
  const { user = {}, level, title, subTitle, debug = false } = props

  const emptyMessage = useMemo(() => {
    return user.id && user.firstName ? `${user.firstName} doesn't appear to have goals set` : ''
  }, [user])

  const headerMetrics = useSelector((state) => state.headerMetrics)

  const goalsMetric = useMemo(() => {
    return find(headerMetrics, (x) => get(x, 'definition.name') === metricNames.goals)
  }, [headerMetrics])

  const userApiSuccess = useSelector((state) => apiSuccessSelector(state, getHeaderMetricsByUserKey))

  const apiResponded = useMemo(() => {
    return level === 'person' && userApiSuccess
  }, [level, userApiSuccess])

  const showGoals = useMemo(() => {
    return apiResponded && goalsMetric
  }, [apiResponded, goalsMetric])

  const showEmpty = useMemo(() => {
    return apiResponded && !goalsMetric
  }, [apiResponded, goalsMetric])

  return (
    <>

      {debug && <SignalDebug signal={goalsMetric} className="px-10" />}

      <div className="mt-4 mb-6 px-10 py-6 rounded-lg border border-color-2e5bff-08">

        {showGoals && (
          <div className="goals-metric-container">

            {title && <h1>{title}</h1>}
            {subTitle && <div className="text-size-14px text-color-4e5d7e">{subTitle}</div>}

            <ErrorBoundary>
              <GMLRenderer
                key={`GMLRenderer-${goalsMetric.id}`}
                tree={get(goalsMetric, 'presentation.resolved')}
                debug={debug}
                animate={true} />
            </ErrorBoundary>
          </div>
        )}

        {showEmpty
          && (
            <div>
              <EmptyGoals message={emptyMessage} />
            </div>
          )}

      </div>

    </>
  )
}

export default Goals
