import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SideMenu from '../sideMenu/sideMenu'
import MainContainer from '../mainContainer'
import VersionChecker from '../../versionChecker'
import LocationTracker from '../location/locationTracker'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setMainWidth } from '../../actions'
import useRefResize from '../../hooks/useRefResize'
import classNames from 'classnames'
import { some, values } from 'lodash'
import { useRoutes } from '../../context/routes'
import SandboxBanner from '../sandbox/banner'
import { useTenantInfo } from '../../context/tenantInfo'
import { useUserPrefs } from '../../context/userPrefs'
import MainTemplateErrorBoundary from './mainTemplateErrorBoundary'

const MainTemplate = ({ component: Component, dock: Dock, canAccess, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { key } = location

  const { key: prefKey, getPref } = useUserPrefs()

  const { isSandbox } = useTenantInfo()

  const { routes } = useRoutes()

  const mainRef = useRef()

  const mainWidth = useSelector((state) => state.mainWidth)

  const handleResize = useCallback(({ width }) => {
    mainWidth !== width && dispatch(setMainWidth(width))
  }, [mainWidth])

  useRefResize(mainRef, handleResize)

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [key])

  const [enableDock, setEnableDock] = useState(false)
  const dock = useSelector((state) => state.dock)

  useEffect(() => {
    setEnableDock(Dock && some(values(dock), (d) => d.enabled === true))
  }, [dock])

  useEffect(() => {
    if (!canAccess) {
      history.push(routes.unauthorized)
    }
  }, [canAccess])

  const mainClassName = useMemo(() => {
    const isOpen = getPref('sidebarMenu', 'isOpen') ?? true
    return classNames('w-full min-h-screen pl-0 md:pl-18 pr-0',
      { 'md:pl-64': isOpen }, // Sidebar is open
      { 'md:pr-96': enableDock && Dock }) // Dock container is open
  }, [enableDock, Dock, prefKey, getPref])

  const renderDock = useCallback(() => {
    if (canAccess && enableDock && Dock) {
      return (
        <div id="dock-container" className="fixed top-0 right-0 w-full h-screen md:w-96 border-l boxShadow-right border-color-eaeaea">
          <Dock {...rest} />
        </div>
      )
    }
  }, [canAccess, enableDock, Dock])

  return (
    <div className={classNames('flex flex-wrap md:min-h-screen', { 'pt-16': isSandbox })}>
      {canAccess && <SandboxBanner />}
      {canAccess
        && (
          <>
            <LocationTracker />
            <VersionChecker key={key} />
            <SideMenu />
          </>
        )}
      <div ref={mainRef} className={mainClassName}>
        <MainTemplateErrorBoundary>
          <div>
            {canAccess
              && (
                <MainContainer {...rest}>
                  <Component {...rest} />
                </MainContainer>
              )}
          </div>
        </MainTemplateErrorBoundary>
      </div>
      {renderDock()}
    </div>
  )
}

export default MainTemplate
