import React, { useEffect, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MetricsHeader from '../header/metricsHeader'
import { featureSettingSelector, apiSuccessSelector } from '../../selectors'
import RepButtonMenu from '../buttonMenus/repButtonMenu'
import RepSignalsLegacy from '../signals/repSignalsLegacy'
import RepDeals from '../deals/repDeals'
import RepCoaching from '../coaching/repCoaching'
import RepScorecardLegacy from '../scorecard/repScorecardLegacy'
import { featureNames } from '../../constants/featureNames'
import ErrorBoundary from '../../gml/controls/errorBoundary'
import { clearApiActivityByKey, clearHeaderMetrics } from '../../actions'
import { getHeaderMetricsByUserKey } from '../../actions/signalService'
import { getHeaderMetricsByUser } from '../../services/signalService'
import { headerMetricsPersonFilterHeaders } from '../header/constants'
import { useRoutes } from '../../context/routes'
import { useDebug } from '../../context/debug'
import { useLocationData } from '../location/hooks'
import { useGroups } from '../../context/groups'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useTenantInfo } from '../../context/tenantInfo'
import { get, has } from 'lodash'
import GroupHeaderControl from '../header/groupHeaderControl'
import { useLoggedInUser } from '../../context/loggedInUser'

const RepByUser = (props) => {
  const { checkPermissions } = usePermissions()

  const { routes } = useRoutes()

  const { loggedInUser } = useLoggedInUser()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessNote
    )
  }, [checkPermissions])

  const { debug } = useDebug()

  const { tenantInfo } = useTenantInfo()

  const { params } = useLocationData()

  const { userId } = params

  const dispatch = useDispatch()

  const { findGroupById } = useGroups()

  const signalsRoute = `${routes.rep}/signals`
  const coachingRoute = `${routes.rep}/coaching`
  const dealsRoute = `${routes.rep}/deals`

  useEffect(() => {
    if (!has(tenantInfo, 'currencyCode')) {
      return
    }

    dispatch(clearApiActivityByKey(getHeaderMetricsByUserKey))
    dispatch(clearHeaderMetrics())
    dispatch(getHeaderMetricsByUser(userId, 0, -1, headerMetricsPersonFilterHeaders, true))
  }, [userId, tenantInfo, dispatch])

  const headerMetricsSuccess = useSelector((state) => apiSuccessSelector(state, getHeaderMetricsByUserKey))

  const ready = useMemo(() => {
    return headerMetricsSuccess
  }, [headerMetricsSuccess])

  const user = findGroupById(userId)
  const { name = '' } = user || {}

  const coachingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.coaching }))
  const coachingFeatureEnabled = get(coachingFeature, 'payload.enabled', false)

  const repIsUser = useMemo(() => {
    return loggedInUser?.id === userId
  }, [loggedInUser, userId])

  const header = useMemo(() => {
    if (userId) {
      return (
        <GroupHeaderControl
          showReps={true}
          title={name}
          showSearch={false}
          header={(
            <ErrorBoundary>
              <MetricsHeader
                user={user}
                level="person"
                ready={ready}
                debug={debug} />
            </ErrorBoundary>
          )} />
      )
    }
    return null
  }, [userId, name, user, ready, debug])

  return (
    <div id="rep" className="w-full flex flex-col min-h-screen">

      {header}

      <RepButtonMenu id={userId} coachingFeatureEnabled={coachingFeatureEnabled && !repIsUser} />

      <Switch>

        <Route
          path={signalsRoute}
          render={(routeProps) => (
            <div className="px-10">
              <RepSignalsLegacy {...routeProps} />
            </div>
          )} />

        {coachingFeatureEnabled && permissions
          && (
            <Route
              path={coachingRoute}
              render={(routeProps) => (
                <div className="px-10">
                  <RepCoaching {...routeProps} />
                </div>
              )} />
          )}

        <Route
          path={dealsRoute}
          render={(routeProps) => (
            <RepDeals {...routeProps} />
          )} />

        <Route
          path={routes.rep}
          render={(routeProps) => (
            <div className="px-10">
              <RepScorecardLegacy {...routeProps} />
            </div>
          )} />

      </Switch>

    </div>
  )
}

export default RepByUser
