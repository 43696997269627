import React, { useCallback, useMemo } from 'react'
import FilterWrapper from './filterWrapper'
import { useCanvasItem } from '../../context/canvasItem'
import { useFilterSourceOptions } from '../../context/filterSourceOptions'
import { ParameterType } from '../../grpc/enums'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'

const FilterParameter = (props) => {
  const {
    user,
    parameter
  } = props

  const { name } = useCanvasItem()

  const { sourceOptions } = useFilterSourceOptions()

  const { workingFilter, addNewParameter } = useRealtimeFilterEditor()

  const parameterKey = useMemo(() => {
    return user?.key
  }, [user])

  const filters = useMemo(() => {
    return user?.filter?.filters
  }, [user])

  const handleAddFilter = useCallback(() => {
    addNewParameter(parameterKey, ParameterType.PARAMETER_TYPE_FILTER)
    window.analytics.track('realtimeMetrics.filterModal.addFilterClicked', { name, filter: workingFilter?.name })
  }, [parameterKey, workingFilter, addNewParameter])

  return (
    <>
      {filters
        ? (
          <FilterWrapper
            filters={filters}
            parameterKey={parameterKey}
            sourceOptions={sourceOptions} />
        )
        : (
          <div className="filters-actions self-start">
            {sourceOptions.length
              ? (
                <button
                  onClick={handleAddFilter}>
                  + Add Filter
                </button>
              )
              : <span>No source options available for this parameter</span>}
          </div>
        )}
    </>
  )
}

export default FilterParameter
