import React, { useMemo, useRef, useState } from 'react'
import useRefResize from '../../../hooks/useRefResize'
import Header from './header'
import Footer from './footer'
import DealList from './dealList'
import { useForecastingFeature } from '../hooks'
import { DisplayOption, VisibilityOption } from '../../../grpc/enums'
import { useForecastConfigs } from '../../../context/forecastConfigs'

const ForecastingDock = (props) => {
  const { configsList } = useForecastConfigs()

  const { forecastCategories } = useForecastingFeature()

  const visibleCategories = useMemo(() => {
    return forecastCategories
      .filter((fc) => [DisplayOption.ALL, DisplayOption.BANNER, DisplayOption.BANNER_GRID].includes(fc.displayOption))
      .filter((fc) => [VisibilityOption.ALL, VisibilityOption.IC].includes(fc.visibilityOption))
  }, [forecastCategories])

  const headerRef = useRef()
  const [headerHeight, setHeaderHeight] = useState(0)
  useRefResize(headerRef, ({ height }) => { setHeaderHeight(height) })

  const footerRef = useRef()
  const [footerHeight, setFooterHeight] = useState(0)
  useRefResize(footerRef, ({ height }) => { setFooterHeight(height) })

  return (
    <div className="forecasting-dock relative w-full h-full bg-color-ffffff leading-none">

      <div ref={headerRef} className="absolute w-full" style={{ minHeight: 100 }}>
        <div className="px-6 pt-6 pb-3">
          <Header
            forecastCategories={visibleCategories} />
        </div>
      </div>

      {configsList.length > 0
        && (
          <>
            <div className="absolute w-full overflow-y-auto" style={{ top: Math.max(100, headerHeight), bottom: footerHeight }}>
              <DealList
                forecastCategories={visibleCategories} />
            </div>

            <div ref={footerRef} className="absolute w-full bottom-0 bg-color-ffffff">
              <div className="px-6">
                <Footer
                  forecastCategories={visibleCategories} />
              </div>
            </div>
          </>
        )}

    </div>
  )
}

export default ForecastingDock
