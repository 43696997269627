import React, { useContext, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OpenCategories } from '../pipeline/dealFilters'

const FilterExpander = (props) => {
  const {
    name,
    open,
    showCount = true,
    count = 0,
    children,
    onToggle
  } = props

  const { categoriesOpened = [], activeOnly } = useContext(OpenCategories)

  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (categoriesOpened.indexOf(name) > -1) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [categoriesOpened])

  const toggleOpen = useCallback((_isOpen) => {
    setIsOpen(_isOpen)
    onToggle?.(_isOpen, name)
  }, [onToggle])

  return (
    <>
      <div onClick={() => toggleOpen(!isOpen)} className="flex items-center justify-between mt-3 cursor-pointer focus:outline-none">
        <div className="font-weight-600 text-color-818e93 text-size-16px">{name}</div>
        <div className="flex justify-center items-center">
          {showCount && count > 0 && (
            <div className="w-5 h-5 rounded-full h bg-color-5951FF flex items-center justify-center text-color-ffffff text-size-12px mr-3">
              {count}
            </div>
          )}
          <FontAwesomeIcon icon={isOpen ? 'minus' : 'plus'} size="xs" className="text-color-09242f" />
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col">
          {children}
        </div>
      )}
    </>
  )
}

export default FilterExpander
