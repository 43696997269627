import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Popper } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DealSearchResult from './dealSearchResult'
import DealPreview from './dealPreview'
import PersonSearchResult from './personSearchResult'
import PersonPreview from './personPreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGroups } from '../../context/groups'
import { debounce, filter, orderBy } from 'lodash'
import { useGrpcCallback } from '../../grpc'
import { FilterOperation, SearchCombineAction } from '../../grpc/enums'
import { toSortItem } from '../../services/searchService'
import { toSearchRequest } from '../../grpc/converters'
import { usePipelineFeature } from '../pipeline/hooks'
import { enrichFields } from '../../reducers/searchedOpps'
import classNames from 'classnames'

const SearchDealsOrPeople = (props) => {
  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const { primaryFields } = usePipelineFeature()
  const { flattened: teamFlattened } = useGroups()
  const [search, setSearch] = useState('')
  const [opps, setOpps] = useState([])
  const [people, setPeople] = useState([])
  const [showInstructions, setShowInstructions] = useState(true)
  const [showResults, setShowResults] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const [previewType, setPreviewType] = useState('')
  const clearRef = useRef(null)
  const inputRef = useRef(null)

  function reset() {
    setSearch('')
    setShowInstructions(true)
    setShowResults(false)
    setOpps([])
    setPeople([])
  }

  const searchOpps = useGrpcCallback({
    onSuccess: (obj) => {
      if (obj.valuesList) {
        obj.valuesList = [...obj.valuesList.map((opp) => enrichFields(opp))]
        setOpps(obj.valuesList)
      }
    },
    grpcMethod: 'search',
    grpcMethodName: 'globalSearchOpps',
  }, [])

  function espaceSpecialChars(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const doSearch = useCallback((searchText) => {
    if (primaryFields.length > 0 && objectDefinitions.opportunity && objectDefinitions.opportunity.fieldsList) {
      setPreviewType('')
      setPreviewData(null)
      if (searchText.length > 2) {
        searchText = espaceSpecialChars(searchText)
        const obj = {
          objectName: 'opportunity',
          fieldsList: [
            '*'
          ],
          paging: {
            sortList: [],
            skip: 0,
            take: 5,
          },
          query: {
            children: {
              combineAction: SearchCombineAction.AND,
              valuesList: [
                {
                  node: {
                    item: toSortItem(objectDefinitions.opportunity.fieldsList, 'name'),
                    valuesList: [`.*${searchText}.*`],
                    operation: FilterOperation.LIKE,
                  },
                }
              ]
            }
          },
        }
        const request = toSearchRequest(obj)
        searchOpps(request)
        window.analytics.track('Search', { action: searchText })
      }
    }
  }, [primaryFields, objectDefinitions])

  const debounceSearch = useMemo(() => {
    return debounce(doSearch, 350)
  }, [doSearch])

  const handleSearchChange = useCallback((e) => {
    const searchText = e.target.value
    setSearch(searchText)

    if (searchText.length === 0) {
      reset()
    } else {
      setShowInstructions(searchText.length < 3)
      setShowResults(searchText.length > 2)
      debounceSearch(searchText)
    }
  }, [debounceSearch])

  useEffect(() => {
    if (search.length > 2) {
      const users = orderBy(filter(teamFlattened, (p) => {
        const matches = p.name.toLowerCase().match(`\\S*\\b${espaceSpecialChars(search.toLowerCase())}\\S*`)
        return (matches || []).length > 0
      }), (o) => o.name)
      setPeople(users.slice(0, 5))
    } else {
      setPeople([])
    }
  }, [teamFlattened, search])

  const [showHint, setShowHint] = useState(true)
  const [resultSelectors, setResultSelectors] = useState([])
  useEffect(() => {
    setResultSelectors([
      ...opps.map((o) => `#searchResult-${o.id}`),
      ...people.map((p) => `#searchResult-${p.id}`)
    ])
  }, [opps, people])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function onSearchFocus(e) {
    setAnchorEl(e.currentTarget)
    setShowHint(false)
  }

  function onSearchBlur(e) {
    setShowHint(!search.length && true)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function onFocus(previewType, data) {
    setPreviewType(previewType)
    setPreviewData(data)
  }

  function onBlur() {
    setPreviewType('')
    setPreviewData(null)
  }

  function onMouseOver(e, previewType, data) {
    e.target.focus()
    onFocus(previewType, data)
  }

  function onMouseOut() {
    inputRef.current.focus()
    onBlur()
  }

  function onClickAway(e) {
    if (e.target !== inputRef.current && e.target !== clearRef.current) {
      setAnchorEl(null)
    }
  }

  function onClearClick(e) {
    reset()
    inputRef.current.focus()
    window.analytics.track('Clear Search')
    e.stopPropagation()
  }

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      reset()
      setAnchorEl(null)
      inputRef.current.blur()
      return
    }

    if (resultSelectors.length > 0) {
      if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
        const el = document.querySelector(resultSelectors[0])
        el && el.focus()
        e.preventDefault()
      } else if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
        const el = document.querySelector(resultSelectors[resultSelectors.length - 1])
        el && el.focus()
        e.preventDefault()
      }
    }
  }, [resultSelectors])

  return (
    <div className="w-full max-w-96 min-w-48 mr-6">
      <div className="w-full relative">
        <div className="absolute flex justify-between w-full pointer-events-none">
          <FontAwesomeIcon icon="search" size="sm" className="text-color-a0a8bb" style={{ marginTop: 10, marginLeft: 10 }} />
          {search.length > 0
            && (
              <button
                ref={clearRef}
                className="text-color-a0a8bb hover:text-color-151d49 focus:outline-none pointer-events-auto"
                onClick={onClearClick}
                style={{ marginTop: 5, marginRight: 8 }}>
                <FontAwesomeIcon icon="times-circle" size="sm" className="pointer-events-none" />
              </button>
            )}
        </div>
        <input
          ref={inputRef}
          id="searchBox"
          autoComplete="off"
          onKeyDown={onKeyDown}
          onFocus={onSearchFocus}
          onBlur={onSearchBlur}
          onChange={handleSearchChange}
          value={search}
          type="text"
          placeholder="Search"
          className="z-0 w-full outline-none border border-color-d6d9e6 rounded-full pl-8 pr-6 py-1 focus:shadow-md" />
        <div
          style={{ top: '7px', right: '0px', lineHeight: '18px' }}
          className={classNames(
            'absolute rounded text-center text-color-a0a8bb border border-color-a0a8bb font-bold w-5 h-5 mr-3',
            { hidden: !showHint }
          )}>
          /
        </div>
      </div>
      <Popper
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        width={600}>
        <ClickAwayListener onClickAway={onClickAway}>
          <div className="bg-color-ffffff shadow-md rounded overflow-hidden font-normal"
            style={showInstructions
              ? { width: 300, minHeight: 50, marginLeft: 0 }
              : { width: 600, minHeight: 345, marginLeft: -150 }}>
            {showInstructions && <div className="pt-3 pb-3 text-center text-color-a6b2cf">Continue typing to search...</div>}
            {showResults && (
              <div className="flex justify-between">
                <div style={{ width: 300 }}>
                  <div className="px-6 pt-3 pb-2 text-size-12px font-weight-600 uppercase text-color-a6b2cf tracking-widest">Deals</div>
                  {opps.length === 0
                    ? <div className="px-6 py-3 border-t border-color-eaeaea">No deals match your search</div>
                    : opps.map((o, i) => (
                      <DealSearchResult
                        key={`dealResult-${i}`}
                        opp={o}
                        closePopper={handleClose}
                        onFocus={(e) => { onFocus('deal', o) }}
                        onBlur={onBlur}
                        onMouseOver={(e) => { onMouseOver(e, 'deal', o) }}
                        onMouseOut={onMouseOut}
                        resultSelectors={resultSelectors} />
                    ))}
                  <div className="px-6 pt-3 pb-2 text-size-12px font-weight-600 uppercase text-color-a6b2cf tracking-widest">People</div>
                  {people.length === 0
                    ? <div className="px-6 py-3 border-t border-color-eaeaea">No people match your search</div>
                    : people.map((p, i) => (
                      <PersonSearchResult
                        key={`personResult-${i}`}
                        closePopper={handleClose}
                        person={p}
                        onFocus={(e) => { onFocus('person', p) }}
                        onBlur={onBlur}
                        onMouseOver={(e) => { onMouseOver(e, 'person', p) }}
                        onMouseOut={onMouseOut}
                        resultSelectors={resultSelectors} />
                    ))}
                </div>
                <div className="flex flex-row items-center p-10" style={{ width: 300, minHeight: 345 }}>
                  {previewType === 'deal' && <DealPreview opp={previewData} />}
                  {previewType === 'person' && <PersonPreview person={previewData} />}
                </div>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default SearchDealsOrPeople
