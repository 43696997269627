import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { resolveCompanyImageUrl } from '../../lib/image'
import Avatar from '../avatar/avatar'
import findIndex from 'lodash/findIndex'
import { getFieldFromObject } from '../fieldRenderers/helpers'
import { useDealDetail } from '../../hooks/useDealDetail'
import { canopyFields } from '../fieldRenderers/constants'

const DealSearchResult = (props) => {
  const { opp, closePopper, onFocus, onBlur, onMouseOver, onMouseOut, resultSelectors } = props
  const { id } = opp

  const { getValueOrDefault } = useDealDetail(opp)

  const { routes } = useRoutes()

  const name = useMemo(() => {
    const field = getFieldFromObject(opp, 'name')
    return (field && field.value) || ''
  }, [opp])

  const companyImageUrl = useMemo(() => {
    const value = getValueOrDefault(canopyFields._account.key, undefined)
    const website = value?.website
    return website ? resolveCompanyImageUrl({ website }) : ''
  }, [getValueOrDefault])

  const link = routes.deal.replace(':opportunityId', id)

  function onClick(e) {
    window.analytics.track('Deal Search Result Clicked', { action: name })
    closePopper()
  }

  function onKeyDown(e) {
    if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
      const currentIndex = findIndex(resultSelectors, (r) => r === `#searchResult-${id}`)
      if (currentIndex + 1 === resultSelectors.length) {
        const searchBox = document.querySelector('#searchBox')
        if (searchBox) {
          searchBox.focus()
        }
      } else {
        const el = document.querySelector(resultSelectors[currentIndex + 1])
        el && el.focus()
      }
      e.preventDefault()
    } else if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
      const currentIndex = findIndex(resultSelectors, (r) => r === `#searchResult-${id}`)
      if (currentIndex - 1 === -1) {
        const searchBox = document.querySelector('#searchBox')
        if (searchBox) {
          searchBox.focus()
        }
      } else {
        const el = document.querySelector(resultSelectors[currentIndex - 1])
        el && el.focus()
      }
      e.preventDefault()
    }
  }

  return (
    <Link
      id={`searchResult-${id}`}
      to={link}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onKeyDown={onKeyDown}
      className="flex flex-row items-center px-6 py-3 border-t border-color-eaeaea focus:bg-gradient-white-to-blue focus:outline-none">
      <Avatar className="w-8 h-8 mr-5 pointer-events-none" imageUrl={companyImageUrl} text={name.charAt(0)} />
      <div className="truncate pointer-events-none" style={{ maxWidth: 300 }}>{name}</div>
    </Link>
  )
}

export default DealSearchResult
