import React, { useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from '@material-ui/core/Switch'
import get from 'lodash/get'
import has from 'lodash/has'
import { useRoutes } from '../../context/routes'
import classNames from 'classnames'
import { useLocationData } from '../location/hooks'

const SignalDebug = (props) => {
  const { signal, className } = props

  const { routes } = useRoutes()

  const { location, hash } = useLocationData()

  const [showDebug, setShowDebug] = useState(false)
  const signalName = get(signal, 'definition.name')

  const history = useHistory()

  const parsedGML = useMemo(() => {
    return get(signal, 'presentation.resolved')
  }, [signal])

  const handleFilterClick = useCallback(() => {
    if (has(hash, 'signal')) {
      history.push(location.pathname)
    } else {
      history.push(`${location.pathname}#signal=${signalName}`)
    }
  }, [location, hash, signalName])

  return (
    <div className={classNames('font-bold text-sm', className)}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span>{signalName}</span>
        </div>
        <div className="flex items-center">
          <label>Show Debug</label>
          <Switch checked={showDebug} onChange={() => setShowDebug(!showDebug)} />
        </div>
      </div>
      {showDebug && (
        <div className="flex justify-between mb-4">
          <textarea value={get(signal, 'presentation.raw')}
            className="w-full border border-color-a0a8bb mx-2 first:ml-0"
            rows="8"
            style={{ padding: 8, fontSize: 12 }} />
          <textarea value={JSON.stringify(parsedGML, null, 2)}
            className="w-full border border-color-a0a8bb mx-2 first:ml-0"
            rows="8"
            style={{ padding: 8, fontSize: 12 }} />
          <textarea value={JSON.stringify(signal, null, 2)}
            className="w-full border border-color-a0a8bb mx-2 first:ml-0"
            rows="8"
            style={{ padding: 8, fontSize: 12 }} />
        </div>
      )}
    </div>
  )
}

export default SignalDebug
