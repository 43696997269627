import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { seriesColors } from '../colors'

const CalloutItem = (props) => {
  const {
    name,
    styleNames,
    containerStyleNames = '',
    labelStyleNames = '',
    valueStyleNames = '',
    childIndex,
    hide = false,
    label,
    value,
    accessory = 'none',
    accessoryColor,
  } = props

  PropTypes.checkPropTypes(CalloutItem.propTypes, props, 'prop', 'CalloutItem')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-calloutitem',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const iconAttributes = {
    color: accessoryColor || seriesColors[childIndex]
  }

  return (
    <div {...attributes}>
      {accessory === 'bullet' && (
        <div className="g-calloutitem-accessory rounded-full flex-shrink-0">
          <FontAwesomeIcon className="g-calloutitem-icon" icon="circle" {...iconAttributes} />
        </div>
      )}
      <div className={classNames('g-calloutitem-container flex-1', containerStyleNames)}>
        <div className={classNames('g-calloutitem-label text-sm whitespace-nowrap', labelStyleNames)}>{label}</div>
        <div className={classNames('g-calloutitem-value text-4xl whitespace-nowrap font-bold', valueStyleNames)}>{value}</div>
      </div>
    </div>
  )
}

CalloutItem.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  containerStyleNames: PropTypes.string,
  labelStyleNames: PropTypes.string,
  valueStyleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  accessory: PropTypes.oneOf(['none', 'bullet']),
  accessoryColor: PropTypes.string,
  children: PropTypes.node,
}

export default CalloutItem
