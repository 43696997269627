import React, { useMemo } from 'react'
import { useReferenceFieldOptionsList } from '../../context/referenceFieldOptionsList'
import MultiSelectFilterVirtualized from './multiSelectFilterVirtualized'

const MultiSelectFilterLazy = (props) => {
  const {
    onToggle,
    open,
    fieldDefinition,
    onChange,
    fieldReference
  } = props

  const { isFetching, referenceFieldOptions, hasMore, nextPage } = useReferenceFieldOptionsList()

  const options = useMemo(() => {
    if (referenceFieldOptions.length) {
      const opts = referenceFieldOptions.map((o) => {
        return {
          label: o.label,
          value: o.key
        }
      })
      if (hasMore) {
        opts.push({ autoLoad: true })
      }
      return opts
    }
  }, [referenceFieldOptions, hasMore])

  const isOpen = useMemo(() => {
    return open || isFetching || !!options?.length
  }, [open, isFetching, options])

  return (
    <MultiSelectFilterVirtualized
      onToggle={onToggle}
      open={isOpen}
      maxHeight={525}
      fieldDefinition={fieldDefinition}
      onChange={onChange}
      lazyLoad={true}
      options={options}
      fieldReference={fieldReference}
      isFetching={isFetching}
      hasMore={hasMore}
      nextPage={nextPage} />
  )
}

export default MultiSelectFilterLazy
