import React from 'react'
import slackLogo from '../../assets/slack-logo.png'

const SlackAuthComplete = (props) => {
  return (
    <div className="flex justify-center items-center content-center">

      <div className="text-center p-4 my-4">
        <img src={slackLogo} className="mx-auto my-4" style={{ width: 120 }} />
        <p className="text-lg my-2">You have successfully connected the Outreach Commit Bot with your Slack account.</p>
        <p className="text-sm my-2">(You may now close this window)</p>
      </div>

    </div>
  )
}

export default SlackAuthComplete
