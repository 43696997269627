import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Icon, { iconType } from '../icon'
import { Create, Error, FileCopy, Visibility, VisibilityOff } from '@getoutreach/react-icons'
import SliderGroup from './sliderGroup'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearChangedInputs,
  commitChangedInput,
  setActiveScenarioKey,
  toggleHiddenScenario,
  upsertScenario,
  updateScenarioLabel,
  setRunFailed,
  deleteScenario
} from '../../actions'
import { runForecastSimulation } from '../../services/reportingService'
import { cloneDeep, find, findIndex, forEach, get } from 'lodash'
import { baselineScenarioSelector } from '../../selectors'
import { guid } from '../../lib/guid'
import moment from 'moment'
import { useEditableLabel } from '../../hooks/useEditableLabel'
import Tippy from '@tippyjs/react'
import { roundArrow, hideAll } from 'tippy.js'
import DeleteScenarioConfirm from './deleteScenarioConfirm'

const ScenarioListItem = (props) => {
  const { tenantId, groupId, scenario } = props

  const { key, label, color, isBaseline, input, output, createdAt, runFailed } = scenario
  const { categoriesList = [] } = input

  const scenarioDuplicateKey = useMemo(() => {
    return `${tenantId}|${groupId}|${guid()}`
  }, [])

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)
  const activeScenarioKey = useSelector((state) => state.activeScenarioKey)
  const hiddenScenarios = useSelector((state) => state.hiddenScenarios)
  const baselineScenario = useSelector(baselineScenarioSelector)
  const changedInputs = useSelector((state) => state.changedInputs)

  const { hasAllOutput } = forecastSimulationWorkspace

  const dispatch = useDispatch()
  const [enableRun, setEnableRun] = useState(false)

  const [categoriesOpen, setCategoriesOpen] = useState({})

  const handleCategoryToggled = useCallback((category, isOpen) => {
    setCategoriesOpen({
      ...categoriesOpen,
      [category.key]: isOpen,
    })
  }, [categoriesOpen])

  const paramsList = useMemo(() => {
    return get(forecastSimulationWorkspace, 'equation.paramsList', [])
  }, [forecastSimulationWorkspace])

  const isActive = useMemo(() => {
    return key === activeScenarioKey
  }, [key, activeScenarioKey])

  const onClick = useCallback(() => {
    if (key === activeScenarioKey || !output) {
      return
    }

    dispatch(clearChangedInputs())
    dispatch(setActiveScenarioKey(key))
    window.analytics.track('scenarioPlanner.input.scenarioClicked', { name: label, key })
  }, [key, activeScenarioKey, output])

  const editableLabel = useEditableLabel({
    defaultValue: label,
    onChangeCommitted: (value) => {
      dispatch(updateScenarioLabel({ key, label: value }))
    },
  })

  useEffect(() => {
    if (!scenario.output) {
      editableLabel.setIsEditMode(true)
    }
  }, [])

  const onRename = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    editableLabel.setIsEditMode(true)
    window.analytics.track('scenarioPlanner.input.renameScenarioClicked')
  }, [editableLabel.setIsEditMode])

  const onDelete = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    if (key === activeScenarioKey) {
      dispatch(setActiveScenarioKey(baselineScenario.key))
    }
    dispatch(deleteScenario(key))
    window.analytics.track('scenarioPlanner.input.deleteScenarioClicked', { name: label, key })
  }, [key, activeScenarioKey, baselineScenario])

  const onDuplicate = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    const clone = cloneDeep(scenario)
    const s = {
      ...cloneDeep(baselineScenario),
      key: scenarioDuplicateKey,
      createdAt: {
        value: {
          seconds: parseInt(moment().format('X')),
          nanos: 0
        },
        isValid: true,
      },
      baseline: cloneDeep(baselineScenario),
    }
    s.label = `${clone.label} Copy`
    s.input = clone.input
    delete s.output

    dispatch(clearChangedInputs())
    dispatch(upsertScenario(s))
    window.analytics.track('scenarioPlanner.input.duplicateScenarioClicked')
  }, [key, baselineScenario, scenario])

  const isHidden = useMemo(() => {
    return hiddenScenarios[key] === true
  }, [key, hiddenScenarios])

  const onToggleHide = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    dispatch(toggleHiddenScenario({
      key,
      isHidden: !isHidden,
    }))
    window.analytics.track('scenarioPlanner.input.toggleScenarioVisibility', { key, name: label, isHidden })
  }, [isHidden])

  const baselineCategoriesList = useMemo(() => {
    return get(baselineScenario, 'input.categoriesList', [])
  }, [baselineScenario])

  const handleSliderChange = useCallback((changeSet) => {
    dispatch(commitChangedInput(changeSet))
    window.analytics.track('scenarioPlanner.input.variableSliderChange', {
      name: changeSet.categoryKey,
      newValue: changeSet.field.value,
      oldValue: changeSet.baselineField.value,
      changePct: (changeSet.field.value - changeSet.baselineField.value) / changeSet.baselineField.value
    })
  }, [])

  useEffect(() => {
    setEnableRun(changedInputs.length > 0)
  }, [changedInputs])

  const [runClicked, setRunClicked] = useState(false)

  const onRunClick = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    setRunClicked(true)

    const s = cloneDeep(scenario)

    const workspace = {
      ...cloneDeep(forecastSimulationWorkspace),
      scenariosList: undefined,
      scenario: s,
    }

    forEach(changedInputs, (changeSet) => {
      const category = find(s.input.categoriesList, (c) => c.key === changeSet.categoryKey)
      if (category) {
        const index = findIndex(category.fieldsList, (f) => f.key === changeSet.field.key)
        if (index !== -1) {
          category.fieldsList[index] = {
            ...changeSet.field,
            deviation: 0,
          }
        }
      }
    })

    // console.log('START: runForecastSimulation', workspace)
    dispatch(setRunFailed({ key: s.key, failed: false }))
    dispatch(clearChangedInputs())
    dispatch(runForecastSimulation({ tenantId, groupId }, workspace))
    window.analytics.track('scenarioPlanner.input.runScenarioClicked')
  }, [scenario, forecastSimulationWorkspace, baselineScenario, changedInputs])

  const onResetClick = useCallback(() => {
    const s = {
      ...cloneDeep(baselineScenario),
      key: scenario.key,
      label: scenario.label,
    }
    delete s.output

    setEnableRun(false)

    dispatch(clearChangedInputs())
    dispatch(upsertScenario(s))
    window.analytics.track('scenarioPlanner.input.resetScenarioClicked')
  }, [scenario, baselineScenario])

  useEffect(() => {
    if (runFailed) {
      setRunClicked(false)
      setEnableRun(true)
    }
  }, [runFailed])

  useEffect(() => {
    if (output) {
      window.scrollTo(0, 0)
    }
  }, [output])

  return (
    <div className="w-full mb-3 px-5 py-3 border border-color-2e5bff-08 cursor-pointer rounded-lg bg-color-ffffff" onClick={onClick}>

      {editableLabel.isEditMode
        ? (
          <input
            ref={editableLabel.inputRef}
            value={editableLabel.value}
            placeholder="Enter a name"
            type="text"
            className="w-full px-2 py-1 border border-color-b0bac9 rounded focus:outline-none"
            onChange={editableLabel.handleChange}
            onBlur={editableLabel.handleBlur}
            onKeyDown={editableLabel.handleKeyDown} />
        )
        : (
          <div className="flex items-start">
            {output && (
              <div className="flex justify-center">
                {isBaseline
                  ? (
                    <>
                      <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
                      <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
                    </>
                  )
                  : <FontAwesomeIcon icon="circle" color={isActive ? '#2e5bff' : color} style={{ width: 8, height: 8, marginTop: 7, marginLeft: 4 }} />}
              </div>
            )}
            <div className="w-full pl-2 first:pl-0">
              <div className="text-color-09242f text-size-16px font-bold leading-snug">
                <span>{editableLabel.value}</span>
                {!isBaseline && (
                  <span className="ml-1 mr-1 last:mr-0 focus:outline-none invisible group-hover:visible" onClick={onRename}>
                    <Create className="text-color-a0a8bb hover:text-color-151d49" style={{ width: 18, height: 18 }} />
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

      <div className="flex items-center justify-between">
        <div className="text-color-a0a8bb text-size-14px font-normal whitespace-nowrap truncate pr-2">
          {createdAt && moment(get(createdAt, 'value.seconds', 0) * 1000).fromNow()}
        </div>
        <div className={classNames('flex items-center', { 'invisible group-hover:visible': !isActive })}>
          {!isBaseline
            && (
              <Tippy
                offset={[0, 0]}
                duration={[0, 0]}
                maxWidth={800}
                content={<DeleteScenarioConfirm label={label} onCancel={hideAll} onDelete={onDelete} />}
                arrow={roundArrow}
                trigger="click"
                interactive={true}
                theme="canopy">
                <div className="ml-1 mr-1 last:mr-0 cursor-pointer pointer-events-auto focus:outline-none">
                  <Icon type={iconType.FONTAWESOME} iconName="trash" size="sm" className="text-color-a0a8bb hover:text-color-151d49" />
                </div>
              </Tippy>
            )}
          {!isBaseline && (
            <button className={classNames('ml-1 mr-1 last:mr-0 focus:outline-none', { 'opacity-25 pointer-events-none cursor-default': !hasAllOutput })} onClick={onDuplicate}>
              <FileCopy className="text-color-a0a8bb hover:text-color-151d49" style={{ width: 16, height: 16, marginLeft: 4 }} />
            </button>
          )}
          <button className={classNames('ml-1 mr-1 last:mr-0 focus:outline-none', { 'opacity-25 pointer-events-none cursor-default': !output })} onClick={onToggleHide}>
            {isHidden
              ? <VisibilityOff className="text-color-a0a8bb hover:text-color-151d49" style={{ width: 20, height: 20 }} />
              : <Visibility className="text-color-a0a8bb hover:text-color-151d49" style={{ width: 20, height: 20 }} />}
          </button>
        </div>
      </div>

      {(isActive || !output) && (
        <>
          <div className="border-t border-color-edeeee mt-2 py-2">
            {categoriesList.map((category, index) => (
              <SliderGroup
                key={`SliderGroup-${activeScenarioKey}-${index}`}
                paramsList={paramsList}
                category={category}
                disabled={isBaseline || output || runClicked}
                baselineCategory={find(baselineCategoriesList, (c) => c.key === category.key)}
                handleSliderChange={handleSliderChange}
                categoriesOpen={categoriesOpen}
                handleCategoryToggled={handleCategoryToggled} />
            ))}
          </div>

          {!output && (
            <div className="border-t border-color-edeeee mt-2 pt-5 flex flex-col items-center">
              {runClicked && !runFailed
                ? (
                  <div className="flex items-center justify-between pb-2">
                    <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
                  </div>
                )
                : (
                  <>
                    <button
                      className={classNames('px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none cursor-pointer',
                        { 'pointer-events-none opacity-25': !enableRun })}
                      onClick={onRunClick}>
                      Run
                    </button>
                    <button
                      className={classNames('my-2 text-size-14px text-color-a6b2cf focus:outline-none cursor-pointer', { 'pointer-events-none opacity-25': !enableRun })}
                      onClick={onResetClick}>
                      <Icon type={iconType.FONTAWESOME} iconName="redo-alt" color="#a6b2cf" size="xs" />
                      {' '}
                      reset
                    </button>

                    {runFailed && (
                      <div className="flex items-center justify-center my-6">
                        <Error htmlColor="#fb6c6a" />
                        <div className="ml-1 text-color-fb6c6a">Oops...something went wrong!</div>
                      </div>
                    )}
                  </>
                )}
            </div>
          )}

        </>
      )}
    </div>
  )
}

export default ScenarioListItem
