import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ForecastingMain from './forecastingMain'
import { useRoutes } from '../../context/routes'
import { apiSuccessSelector, apiRequestedSelector } from '../../selectors'
import { getFeatureSettingsKey } from '../../actions/settingsService'
import { useForecastingFeature } from './hooks'

const Forecasting = () => {
  const { routes } = useRoutes()

  const forecastingFeature = useForecastingFeature()
  const apiRequested = useSelector((state) => apiRequestedSelector(state, getFeatureSettingsKey))
  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getFeatureSettingsKey))

  if (apiRequested) {
    return <></>
  } else if (apiSuccess && forecastingFeature.hasData && forecastingFeature.enabled) {
    return (<ForecastingMain />)
  } else {
    return <Redirect to={routes.summary} />
  }
}

export default Forecasting
