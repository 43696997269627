import React, { useMemo } from 'react'
import { CanvasProvider } from '../../context/canvas'
import { canvasKey } from './constants'
import { useAuth } from '../../context/auth'
import MetricsCanvas from './metricsCanvas'

const RepMetricsCanvas = (props) => {
  const { userId } = props

  const { actingTenantId, actingUserId } = useAuth()

  const actor = useMemo(() => {
    return {
      tenantId: actingTenantId,
      userId
    }
  }, [actingTenantId, userId])

  const headers = useMemo(() => {
    return { ...userId === actingUserId && { forceuser: true } }
  }, [userId, actingUserId])

  return (
    <CanvasProvider
      key={`CanvasProvider-${canvasKey.REP_METRICS}-${userId}`}
      canvasKey={canvasKey.REP_METRICS}>
      <MetricsCanvas
        key={`MetricsCanvas-${userId}`}
        userId={userId}
        actor={actor}
        viewKey={`view-${userId}`}
        headers={headers} />
    </CanvasProvider>
  )
}

export default RepMetricsCanvas
