import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import { defaultBreakpoints, sizeOptions } from './helpers'

const GMLResponsiveContainer = (props) => {
  const {
    className,
    breakpoints = defaultBreakpoints,
    children
  } = props

  PropTypes.checkPropTypes(GMLResponsiveContainer.propTypes, props, 'prop', 'GMLResponsiveContainer')

  const ref = useRef()
  const [childParams, setChildParams] = useState({ width: 0, height: 0, breakpoints })

  const onResize = useCallback(() => {
    if (ref && ref.current) {
      const { width, height } = window.getComputedStyle(ref.current)
      setChildParams({
        width: parseInt(width),
        height: parseInt(height),
        breakpoints
      })
    }
  }, [ref, breakpoints])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  return (
    <div
      ref={ref}
      className={className}>
      {children(childParams)}
    </div>
  )
}

GMLResponsiveContainer.propTypes = {
  className: PropTypes.string,
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf(sizeOptions),
      breakpoint: PropTypes.number
    })
  )
}

export default GMLResponsiveContainer
