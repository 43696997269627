import { createReducer } from '@reduxjs/toolkit'
import { getTeamSuccess } from '../actions/personService'
import { clearTeam } from '../actions'
import { number } from '../gml/lib/number'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

export const enrichUser = (user) => {
  const { goalDataList = [] } = user

  user.goals = uniqBy(goalDataList.map((g) => {
    const label = get(g, 'category.name', 'N/A')
    let format = get(g, 'category.format')
    if (format === 'currency') format = 'shortCurrency'
    const key = get(g, 'evaluation.key', undefined)
    let actualValue = get(g, 'evaluation.actualValue', undefined)
    let baselineValue = get(g, 'evaluation.baselineValue', undefined)
    if (format === 'percent') {
      if (actualValue !== undefined) actualValue /= 100
      if (baselineValue !== undefined) baselineValue /= 100
    }
    const formattedValue = actualValue === undefined ? 'N/A' : `${number(actualValue, format)}/${number(baselineValue, format)}`
    return {
      key,
      label,
      value: formattedValue,
      format,
      actualValue,
      baselineValue,
    }
  }), (g) => g.key)

  return user
}

const initialState = []
export const team = createReducer(initialState, {
  [clearTeam.type]: (state, action) => initialState,
  [getTeamSuccess.type]: (state, action) => {
    const team = orderBy(action.payload.peopleList, [(p) => p.firstName.toLowerCase()]).map((user) => enrichUser(cloneDeep(user)))
    return team
  }
})

const teamFlattenedInitialState = []
export const teamFlattened = createReducer(teamFlattenedInitialState, {
  [clearTeam.type]: (state, action) => teamFlattenedInitialState,
  [getTeamSuccess.type]: (state, action) => {
    const team = orderBy(action.payload.peopleList, [(p) => p.firstName.toLowerCase()]).map((user) => enrichUser(cloneDeep(user)))

    const teamFlattened = []
    function collectTeamRecursively(t) {
      forEach(t, (user) => {
        if (!find(teamFlattened, (u) => u.id === user.id)) {
          teamFlattened.push(enrichUser(cloneDeep(user)))
          collectTeamRecursively(get(user, 'childrenList', []))
        }
      })
    }
    collectTeamRecursively(team)

    return teamFlattened
  }
})
