import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ScenarioDetails from './scenarioDetails'
import ScenarioListItem from './scenarioListItem'
import { baselineScenarioSelector } from '../../selectors'
import { Add } from '@getoutreach/react-icons'
import { cloneDeep } from 'lodash'
import { clearChangedInputs, upsertScenario } from '../../actions'
import { guid } from '../../lib/guid'
import moment from 'moment'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const ScenarioInputs = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateScenarioPlanner
    )
  }, [checkPermissions])

  const { tenantId, groupId } = props

  const dispatch = useDispatch()

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)

  const baselineScenario = useSelector(baselineScenarioSelector)

  const scenarios = useMemo(() => {
    return forecastSimulationWorkspace.scenariosList.slice().reverse()
  }, [forecastSimulationWorkspace])

  const hasAllOutput = useMemo(() => {
    return forecastSimulationWorkspace.hasAllOutput
  }, [forecastSimulationWorkspace])

  const onAddScenario = useCallback(() => {
    const scenario = {
      ...cloneDeep(baselineScenario),
      key: `${tenantId}|${groupId}|${guid()}`,
      label: `Scenario ${scenarios.length}`,
      createdAt: {
        value: {
          seconds: parseInt(moment().format('X')),
          nanos: 0
        },
        isValid: true,
      },
      baseline: cloneDeep(baselineScenario),
    }
    delete scenario.output

    dispatch(clearChangedInputs())
    dispatch(upsertScenario(scenario))

    window.analytics.track('scenarioPlanner.input.addScenarioClicked')
  }, [baselineScenario])

  return (
    <>

      <ScenarioDetails
        tenantId={tenantId}
        groupId={groupId}
        baselineScenarioKey={baselineScenario.key} />

      {permissions.CanUpdateScenarioPlanner && (
        <>
          {hasAllOutput && (
            <div className="flex flex-col justify-center mt-5 py-1 cursor-pointer" onClick={onAddScenario}>
              <div className="flex items-center justify-between bg-gradient-green rounded-full flex-shrink-0 mx-auto text-center" style={{ width: 32, height: 32 }}>
                <Add className="text-color-ffffff mx-auto" />
              </div>
              <div className="py-1 text-size-18px font-bold text-color-151d49 text-center">Add Scenario</div>
            </div>
          )}
        </>
      )}

      <div className="mt-4 mb-8">
        {scenarios.map((scenario) => (
          <ScenarioListItem
            tenantId={tenantId}
            groupId={groupId}
            key={`ScenarioListItem-${scenario.key}`}
            scenario={scenario} />
        ))}
      </div>
    </>
  )
}

export default ScenarioInputs
