import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRoutes } from '../../context/routes'
import { featureNames } from '../../constants/featureNames'
import { featureSettingSelector } from '../../selectors'
import { cloneDeep, has } from 'lodash'
import { ForecastConfigValueFormat, ForecastMode, CategoryRollupMode } from '../../grpc/enums'
import {
  getCurrentForecastCategoryCall,
  getForecastSubmittedAt,
  getPreviousForecastCategoryCall,
  getManagersTeamForecastCategoryCall
} from './helpers'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { useForecasting } from '../../context/forecasting'
import { useForecastConfigs } from '../../context/forecastConfigs'

export function useForecastingFeature() {
  const forecastingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.forecasting }))

  const { selectedConfig, columnsList } = useForecastConfigs()

  const { isPreview } = useRoutes()

  const { formatCurrency } = useFormatCurrency()
  const { formatCurrency: shortCurrency } = useFormatCurrency({ shortCurrency: true })

  const enabled = useMemo(() => {
    return forecastingFeature !== undefined && forecastingFeature.payload && forecastingFeature.payload.enabled
  }, [forecastingFeature])

  const mode = useMemo(() => {
    return forecastingFeature !== undefined && forecastingFeature.payload && forecastingFeature.payload.mode
  }, [forecastingFeature])

  const totalCallIncludesWon = useMemo(() => {
    return (enabled && forecastingFeature !== undefined && forecastingFeature.payload && forecastingFeature.payload.totalCall
      && forecastingFeature.payload.totalCall.includeWon) || false
  }, [enabled, forecastingFeature])

  const forecastCategories = useMemo(() => {
    return columnsList.map((col) => ({
      ...col,
      readOnly: isPreview || col.readOnly
    }))
  }, [columnsList, isPreview])

  const acceptedCategoriesList = useMemo(() => {
    return forecastCategories.map((f) => f.key)
  }, [forecastCategories])

  const categoryRollupsMap = useMemo(() => {
    return forecastCategories.map((f) => {
      return [
        f.key,
        f.autoCalculate
          ? CategoryRollupMode.ROLLUP_ONLY
          : CategoryRollupMode.DEFAULT
      ]
    })
  }, [forecastCategories])

  const getCategoryValueInitializer = useCallback((objectSettings) => {
    const { rollupFieldFormatEnum } = objectSettings

    let initializer = { up: parseInt }

    switch (rollupFieldFormatEnum) {
      case ForecastConfigValueFormat.PERCENT:
        initializer = {
          up: (val) => Number.parseFloat((Number.parseFloat(val) * 100).toFixed(2)),
          down: (val) => val / 100
        }
        break
      case ForecastConfigValueFormat.COVERAGE:
        initializer.up = (val) => Number.parseFloat(Number.parseFloat(val).toFixed(2))
        break
      case ForecastConfigValueFormat.NUMBER:
      case ForecastConfigValueFormat.CURRENCY:
      case ForecastConfigValueFormat.SHORT_CURRENCY:
      default:
    }

    return initializer
  }, [])

  const getCategoryValueFormatter = useCallback((objectSettings) => {
    const { rollupFieldFormatEnum } = objectSettings

    let formatter = (v) => v
    switch (rollupFieldFormatEnum) {
      case ForecastConfigValueFormat.CURRENCY:
        formatter = formatCurrency
        break
      case ForecastConfigValueFormat.SHORT_CURRENCY:
        formatter = shortCurrency
        break
      case ForecastConfigValueFormat.PERCENT:
        formatter = (val) => {
          return `${Number.parseFloat((val).toFixed(2))}%`
        }
        break
      case ForecastConfigValueFormat.NUMBER:
        formatter = Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0
        }).format
        break
      case ForecastConfigValueFormat.COVERAGE:
        formatter = (val) => `${Number.parseFloat(val.toFixed(2))}x`
        break
      default:
        formatter = formatCurrency
        break
    }

    return formatter
  }, [formatCurrency, shortCurrency])

  const enrichForecast = useCallback((_forecast) => {
    const forecastInternal = cloneDeep(_forecast)
    const {
      current,
      previous,
      teamForecastsList,
      forecastConfig,
    } = forecastInternal

    forecastInternal.displayFormulas = {}
    forecastInternal.autoCalculated = {}
    forecastInternal.valueFormatters = {}
    forecastInternal.valueInitializers = {}

    const config = forecastConfig || selectedConfig

    // forecastInternal.config = config

    const { columnsList = [], objectSettings } = config
    columnsList.forEach(({ id, autoCalculate, displayFormula, forecastConfigValueSettings = {} }) => {
      const {
        objectValueSettings: colObjectSettings
      } = forecastConfigValueSettings
      forecastInternal.autoCalculated[id] = autoCalculate
      forecastInternal.displayFormulas[id] = displayFormula
      forecastInternal.valueFormatters[id] = getCategoryValueFormatter(colObjectSettings ?? objectSettings)
      forecastInternal.valueInitializers[id] = getCategoryValueInitializer(colObjectSettings ?? objectSettings)
    })

    // if (teamForecastsList) {
    //   forecastInternal.teamForecastsList = teamForecastsList.map((teamForecast) => ({
    //     ...teamForecast,
    //     view: {
    //       ...cloneDeep(teamForecast.view),
    //       forecastConfig
    //     }
    //   }))
    // }

    if (current) {
      forecastInternal.current.categoryCalls = {}
      forecastInternal.current.defaultCategoryCalls = {}
      current.callsList.forEach((m) => {
        const { key, pb_default: d, value, forecastId } = m
        const { name } = columnsList.find(({ id }) => key === id) ?? {}

        forecastInternal.current.categoryCalls[key] = {
          forecastId,
          name,
          value: value?.value ?? 0,
          valid: value?.valid ?? false
        }
        forecastInternal.current.defaultCategoryCalls[key] = {
          forecastId,
          name,
          value: d ?? 0
        }
      })
    }

    if (previous) {
      forecastInternal.previous.categoryCalls = {}
      forecastInternal.previous.defaultCategoryCalls = {}
      previous.callsList.forEach((m) => {
        const { key, pb_default: d, value, forecastId } = m
        const { name } = columnsList.find(({ id }) => key === id) ?? {}

        forecastInternal.previous.categoryCalls[key] = {
          forecastId,
          name,
          value: value?.value ?? 0,
          valid: value?.valid ?? false
        }
        forecastInternal.previous.defaultCategoryCalls[key] = {
          forecastId,
          name,
          value: d ?? 0
        }
      })
    }

    return forecastInternal
  }, [selectedConfig, getCategoryValueFormatter, getCategoryValueInitializer])

  return {
    hasData: forecastingFeature !== undefined && has(forecastingFeature, 'payload'),
    enabled,
    payload: forecastingFeature !== undefined && forecastingFeature.payload,
    mode,
    isAutoMode: mode === ForecastMode.AUTO,
    isManualMode: mode === ForecastMode.MANUAL,
    totalCallIncludesWon,
    forecastCategories,
    acceptedCategoriesList,
    categoryRollupsMap,
    enrichForecast
  }
}

export function useForecastDelta(forecastCategory, forecast = {}, forecastByUserLocal) {
  const { current = {}, previous = {} } = forecast

  const hasPrevious = useMemo(() => {
    return previous.submittedAt?.valid ?? false
  }, [previous])

  const forecastLocal = useMemo(() => {
    const { ownerId } = current
    if (ownerId && forecastByUserLocal && forecastByUserLocal[ownerId]) {
      return forecastByUserLocal[ownerId]
    }
  }, [current, forecastByUserLocal])

  const forecastDeltaData = useMemo(() => {
    const { current = {} } = forecast

    let previousAmount = getPreviousForecastCategoryCall(forecastCategory.id, forecast, forecastLocal)
    let currentAmount = getCurrentForecastCategoryCall(forecastCategory.id, forecast, forecastLocal)

    if (forecastCategory.autoCalculate) {
      const defaultAmount = getCurrentForecastCategoryCall(forecastCategory.id, forecast, forecastLocal)
      if (currentAmount.valueOf() !== defaultAmount.valueOf()) {
        previousAmount = currentAmount
        currentAmount = defaultAmount
      }
    }

    return {
      hasPrevious,
      previousDate: current.submittedAt ? getForecastSubmittedAt(current, 'M/d/yyyy') : '',
      previousAmount,
      currentAmount,
    }
  }, [forecast, hasPrevious, forecastCategory, forecastLocal])

  const forecastDeltaTooltip = useMemo(() => {
    const { previousDate, previousAmount, currentAmount } = forecastDeltaData
    return currentAmount.value !== previousAmount.value ? (
      <div className="flex justify-between px-4 py-3 leading-tight">
        <div className="flex flex-col">
          <div className="text-size-12px text-color-2e384d">Current</div>
          <div className="text-size-14px text-color-2e384d font-bold">{currentAmount.toString()}</div>
        </div>
        <div className="flex flex-col ml-6">
          <div className="text-size-12px text-color-a0a8bb">{previousDate || '-'}</div>
          <div className="text-size-14px text-color-a0a8bb font-bold">{previousAmount.toString()}</div>
        </div>
      </div>
    ) : undefined
  }, [forecastDeltaData])

  return {
    current,
    previous,
    forecastLocal,
    forecastDeltaData,
    forecastDeltaTooltip,
  }
}

export function useCategoryRollup(forecastCategory, managerTeamForecasts) {
  const [teamCall, setTeamCall] = useState(0)

  const { forecastByUserLocal } = useForecasting()

  const teamRollupCall = useMemo(() => {
    const teamCall = getManagersTeamForecastCategoryCall(forecastCategory.id, managerTeamForecasts, forecastByUserLocal)
    setTeamCall(teamCall)
    return teamCall
  }, [forecastCategory.id, managerTeamForecasts, forecastByUserLocal])

  return useMemo(() => ({
    teamCall,
    teamRollupCall,
  }), [teamCall, teamRollupCall])
}
