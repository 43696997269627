import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDebug } from '../../context/debug'
import { useDealSignals } from '../../context/dealSignals'
import GenericErrorBoundary from '../errors/genericErrorBoundary'
import { useCanvas } from '../../context/canvas'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import { Skeleton } from '@material-ui/lab'

const DealSignals = (props) => {
  const {
    opportunityId,
    className = 'px-6 py-4 bg-color-ffffff rounded-lg border border-color-2e5bff-08',
    showHeader = true
  } = props

  const { canvasKey, tenantStandardParams } = useCanvas()

  const { isFetching, dealSignals } = useDealSignals()

  const { debug } = useDebug()

  const params = useParams()

  const oppId = useMemo(() => {
    return params.opportunityId || opportunityId
  }, [params, opportunityId])

  const signalCount = useMemo(() => {
    return dealSignals.length
  }, [dealSignals])

  return (
    <div className={className}>

      {showHeader
        && (
          <>
            <div className="flex items-center">
              <div
                style={{ width: 28, height: 28 }}
                className="inline-block text-center text-size-14px font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-color-5951FF">
                <div className="font-weight-700 text-size-14px">{isFetching ? '-' : signalCount}</div>
              </div>
              <div className="ml-2 text-size-24px text-color-09242f font-weight-700">Signals</div>
            </div>
            <div className="text-size-14px text-color-51636a font-normal leading-tight">Signals and noteworthy updates that should be known about this deal.</div>
            <div className="border-t border-color-d6d9e6 mt-4 mb-3" />
          </>
        )}

      {isFetching
        ? (
          <>
            <div className="mt-4 mb-3">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
            </div>
            <div className="mt-4 mb-3">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
            </div>
            <div className="mt-4 mb-3">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
            </div>
          </>
        ) : (
          <>
            {signalCount > 0 ? (
              <>
                {dealSignals.map((s, i) => {
                  const { name, gmlResponse } = s
                  const gmlInstanceId = `gml-${oppId}-${canvasKey}-${s.queryKey}-${i}`
                  return (
                    <div
                      key={gmlInstanceId}
                      className="deal-signal mt-4 mb-3 leading-tight">
                      <div className="font-weight-600 text-color-000000 text-size-16px">{name}</div>
                      <GenericErrorBoundary
                        title="Error"
                        subTitle="Error rendering signal">
                        <GMLRenderer
                          tree={gmlResponse}
                          debug={debug}
                          useContentType={false}
                          channel="web"
                          size="xs"
                          registeredData={{
                            gmlInstanceId,
                            ...tenantStandardParams
                          }}
                        />
                      </GenericErrorBoundary>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className="my-4">
                <div className="font-weight-400">No signals available for this deal</div>
              </div>
            )}
          </>
        )}

    </div>
  )
}

export default DealSignals
