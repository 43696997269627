import { createReducer } from '@reduxjs/toolkit'
import { updateSearch, updateLocation } from '../actions'

const initialState = {
  value: ''
}

export const search = createReducer(initialState, {
  [updateSearch.type]: (state, action) => {
    state.value = action.payload
    return state
  },
  [updateLocation.type]: (state, action) => initialState
})
