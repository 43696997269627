import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import { signalConfig } from './signalConfig'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import ErrorBoundary from '../../gml/controls/errorBoundary'
import Popover from '../popover'
import chevronDown from '../../assets/chevron-down.png'
import { getSignalById } from '../../services/signalService'
import AnimatedLoader from '../loaders/animatedLoader'

const Signal = (props) => {
  const { id, level, signal, debug = false, configOverride } = props

  const groupId = useMemo(() => {
    return signal.id
  }, [signal])

  const dispatch = useDispatch()

  const emptySignal = { definition: {}, presentation: {} }
  const [activeSignal, setActiveSignal] = useState(signal)
  const [selectedSignalId, setSelectedSignalId] = useState(signal.id)

  const signalsByGroup = useSelector((state) => state.signalsByGroup)

  useEffect(() => {
    const signalsByGroupKey = `${groupId}|${selectedSignalId}`
    if (has(signalsByGroup, signalsByGroupKey)) {
      setActiveSignal(signalsByGroup[signalsByGroupKey])
    }
  }, [signalsByGroup, selectedSignalId])

  const config = useMemo(() => {
    const chartName = `${activeSignal.definition.name}Chart`
    const chartConfig = get(signalConfig, `config.${chartName}`)
    let config = {}
    if (chartConfig) {
      config = {
        config: {
          [chartName]: chartConfig
        }
      }
    } else if (configOverride) {
      config = configOverride
    }
    return config
  }, [activeSignal])

  const name = useMemo(() => {
    return get(activeSignal, 'definition.name')
  }, [activeSignal])

  const availability = useMemo(() => {
    return get(activeSignal, 'definition.availability')
  }, [activeSignal])

  const tree = useMemo(() => {
    return get(activeSignal, 'presentation.resolved')
  }, [activeSignal])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const isGrouped = get(signal, 'siblingsList', []).length > 0

  const menuItems = useMemo(() => {
    const items = [{ id: get(signal, 'id'), title: get(signal, 'definition.title') }]
    forEach(get(signal, 'siblingsList', []), (s) => {
      items.push({ id: get(s, 'id'), title: get(s, 'definition.title') })
    })
    return items
  }, [signal])

  const handleMenuItemClick = useCallback((menuItem) => {
    setAnchorEl(null)

    const titleText = document.querySelector(`#signal-${groupId} #header #title .g-text`)
    if (titleText && titleText.childNodes.length > 0 && activeSignal.id !== menuItem.id) {
      setActiveSignal(emptySignal)
      setSelectedSignalId(menuItem.id)

      dispatch(getSignalById(id, level, groupId, menuItem.id))
    }
  }, [id, signal, activeSignal])

  useEffect(() => {
    function handleMenuClick(e) {
      setAnchorEl(e.currentTarget)

      const title = document.querySelector(`#signal-${groupId} #header #title`)
      if (title.classList.contains('menu-open')) {
        title.classList.remove('menu-open')
      } else {
        title.classList.add('menu-open')
      }
    }

    const header = document.querySelector(`#signal-${groupId} #header`)
    const title = document.querySelector(`#signal-${groupId} #header #title`)
    const titleText = document.querySelector(`#signal-${groupId} #header #title .g-text`)

    if (isGrouped) {
      if (title) {
        title.classList.add('flex')
        title.classList.add('items-center')
      }

      if (titleText) {
        titleText.classList.add('flex')
        titleText.classList.add('items-center')
        titleText.classList.add('cursor-pointer')
        titleText.addEventListener('click', handleMenuClick)

        if (!(titleText.childNodes.length > 1 && titleText.childNodes[1].nodeName.toLowerCase() === 'img')) {
          const img = document.createElement('img')
          img.src = chevronDown
          img.style.width = '18px'
          img.style.height = '8px'
          img.style.paddingLeft = '5px'
          titleText.appendChild(img)
        }
      }
    }

    const settings = get(activeSignal, 'definition.settingsMap', [])
    const helpLink = get(find(settings, (s) => s[0] === 'helpLink') || [], '[1]')
    if (header && helpLink) {
      let link = document.querySelector(`#signal-${groupId} #learnMore`)
      if (link) {
        link.href = helpLink
      } else {
        link = document.createElement('a')
        link.appendChild(document.createTextNode('Learn More'))
        link.id = 'learnMore'
        link.href = helpLink
        link.target = '_blank'
        link.classList.add('text-color-2e5bff')
        link.classList.add('text-size-12px')
        link.classList.add('font-weight-500')
        link.classList.add('cursor-pointer')
        link.classList.add('float-right')
        link.classList.add('my-2')
        header.prepend(link)
      }
    }

    if (!debug) {
      const footer = document.querySelector(`#signal-${groupId} #footer`)
      if (footer) {
        footer.style.display = 'none'
      }
    }

    return () => {
      if (titleText) {
        titleText.removeEventListener('click', handleMenuClick)
      }
    }
  }, [signal, activeSignal, debug])

  useEffect(() => {
    const onScroll = (e) => {
      const open = Boolean(anchorEl)
      if (open) {
        handleClose()
      }
    }
    document.addEventListener('scroll', onScroll, false)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [anchorEl, handleClose])

  return (
    <div id={`signal-${groupId}`} className="signal">
      {availability && (
        <div className="availability-tag">
          <div className="availability-tag-container">
            <div className="availability-tag-text">{availability}</div>
          </div>
        </div>
      )}
      {tree
        ? (
          <>
            <ErrorBoundary>
              <GMLRenderer
                key={`key-${signal.id}-${activeSignal.id}`}
                tree={tree}
                debug={debug}
                config={config}
                animate={true} />
            </ErrorBoundary>
            {isGrouped && (
              <Popover
                style={{ zIndex: 10000 }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                maxHeight={500}
                timeout={0}>
                <div className="flex flex-col max-h-500">
                  {menuItems.map((m, i) => (
                    <button
                      key={`SignalMenu-${groupId}-${i}`}
                      className={classNames('text-left px-3 py-2 cursor-pointer text-size-15px font-normal focus:outline-none whitespace-nowrap',
                        'truncate bg-color-transparent hover:bg-gradient-green hover:text-color-ffffff',
                        { 'text-color-2e5bff': selectedSignalId === m.id })}
                      onClick={() => handleMenuItemClick(m)}>
                      {m.title}
                    </button>
                  ))}
                </div>
              </Popover>
            )}
            {/* <Waypoint onEnter={onViewed} /> */}
          </>
        )
        : (
          <>
            <AnimatedLoader
              className="my-12"
              title="Loading"
              subTitle="Please wait..."
              maxWidth={300} />
          </>
        )}
    </div>
  )
}

export default Signal
