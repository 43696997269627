import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../header/header'
import AnalyticsContainer from './analyticsContainer'
import ReportInput from './reportInput'
import ResultPlaceholder from './resultPlaceholder'
import moment from 'moment'
import { getPipelineReport } from '../../services/reportingService'
import { DateRangeOption, CloseDateRangeOption } from '../../protos/src/common/reporting/pipeline_report_pb'
import PipelineGroup from './pipelineGroup'
import PipelineGroupResults from './pipelineGroupResults'
import { clearPipelineReport, clearApiActivityByKey } from '../../actions'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import AnimatedLoader from '../loaders/animatedLoader'
import { getPipelineReportKey } from '../../actions/reportingService'
import { apiRequestedSelector } from '../../selectors'
import { formatDate } from '../../formatters'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useCache } from '../../context/cache'

const cacheContext = 'analytics'
const cacheInputKey = 'input'

const Analytics = (props) => {
  const { getCache, setCache } = useCache()
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadAnalytic,
      permissionNames.CanCreateAnalytic
    )
  }, [checkPermissions])

  const dispatch = useDispatch()

  const [currentInput, setCurrentInput] = useState(undefined)
  const [selectedGroup, setSelectedGroup] = useState({})
  const [prevSelectedGroup, setPrevSelectedGroup] = useState({})

  const pipelineReport = useSelector((state) => state.pipelineReport)
  const pipelineReportOptions = useSelector((state) => state.pipelineReportOptions)

  const hasPipelineReport = has(pipelineReport, 'id')
  const hasPipelineResults = get(pipelineReport, 'output.endGroupsList', []).length > 0

  const pipelineGroups = useMemo(() => {
    return get(pipelineReport, 'output.pipelineGroupsList', [])
  }, [pipelineReport])

  const totalPipeline = useMemo(() => {
    return get(pipelineReport, 'output', {})
  }, [pipelineReport])

  useEffect(() => {
    setPrevSelectedGroup({})
    setSelectedGroup(get(pipelineReport, 'output', {}))
  }, [pipelineReport])

  useEffect(() => {
    if (!currentInput) {
      const input = getCache(cacheContext, cacheInputKey)
      setCurrentInput(input)
    }
  }, [currentInput, getCache])

  const containerRef = useRef(null)
  const [width, setWidth] = useState(0)
  useEffect(() => {
    if (!containerRef.current) return
    const handleResize = () => {
      setWidth(containerRef.current.clientWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleItemClick = useCallback((data) => {
    if (data.id === selectedGroup.id) {
      setPrevSelectedGroup(selectedGroup)
      setSelectedGroup(totalPipeline)
    } else {
      setPrevSelectedGroup(selectedGroup)
      setSelectedGroup(data)
    }
    window.analytics.track('reporting.output.pipelineGroupClicked', { id: data.id })
  }, [selectedGroup, totalPipeline])

  const isRunning = useSelector((state) => apiRequestedSelector(state, getPipelineReportKey))

  const handleRunClick = useCallback((reportInput) => {
    const { selectedGroupBy, selectedDateRange, selectedCloseDateRange, selectedFilters, selectedRoles, roleFieldInfo, selectedUsers, userFieldInfo } = reportInput

    const input = {
      groupByList: [],
      dateRangeType: selectedDateRange,
      closeDateRange: {
        closeDateRangeType: selectedCloseDateRange
      },
      filtersList: []
    }

    if (selectedFilters.length > 0) {
      const includes = filter(selectedFilters, (x) => x.checked === 'include').map((x) => {
        const f = cloneDeep(x)
        const option = find(pipelineReportOptions.filterOptionsList, (o) => o.field === f.fieldName)
        if (option.delimiter) {
          forEach(f.comparisonList, (c) => {
            const value = c.valuesList.join('|')
            const regexValue = `(^(?:${value})|^(?:${value});|;(?:${value})$|;(?:${value});)`
            c.valuesList = [regexValue]
            c.op = 'REGEX'
          })
        }
        return f
      })
      input.filtersList.push(...includes)
      // console.log('includes', includes)

      const excludes = filter(selectedFilters, (x) => x.checked === 'exclude').map((x) => {
        const f = cloneDeep(x)
        const option = find(pipelineReportOptions.filterOptionsList, (o) => o.field === f.fieldName)
        if (option.delimiter) {
          forEach(f.comparisonList, (c) => {
            const value = c.valuesList.join('|')
            const regexValue = `(^(?:${value})|^(?:${value});|;(?:${value})$|;(?:${value});)`
            c.valuesList = [regexValue]
            c.op = 'NREGEX'
          })
        }
        return f
      })
      input.filtersList.push(...excludes)
      // console.log('excludes', excludes)
    }

    if (selectedUsers.length > 0) {
      const userFilter = {
        key: userFieldInfo.label,
        fieldName: userFieldInfo.field,
        type: userFieldInfo.type,
        comparisonList: []
      }

      const includes = filter(selectedUsers, (x) => x.checked === 'include').map((x) => x.key)
      if (includes.length > 0) {
        userFilter.comparisonList.push({
          op: 'IN',
          valuesList: includes
        })
      }

      const excludes = filter(selectedUsers, (x) => x.checked === 'exclude').map((x) => x.key)
      if (excludes.length > 0) {
        userFilter.comparisonList.push({
          op: 'NIN',
          valuesList: excludes
        })
      }

      if (userFilter.comparisonList.length > 0) {
        input.filtersList.push(userFilter)
      }
    }

    if (selectedGroupBy !== undefined) {
      input.groupByList.push(selectedGroupBy)
    }

    if (selectedRoles.length > 0 && roleFieldInfo) {
      const roleFilter = {
        key: roleFieldInfo.label,
        fieldName: roleFieldInfo.field,
        type: roleFieldInfo.fieldtype,
        comparisonList: []
      }

      const includes = filter(selectedRoles, (x) => x.checked === 'include').map((x) => x.key)
      if (includes.length > 0) {
        roleFilter.comparisonList.push({
          op: 'IN',
          valuesList: includes
        })
      }

      const excludes = filter(selectedRoles, (x) => x.checked === 'exclude').map((x) => x.key)
      if (excludes.length > 0) {
        roleFilter.comparisonList.push({
          op: 'NIN',
          valuesList: excludes
        })
      }

      if (roleFilter.comparisonList.length > 0) {
        input.filtersList.push(roleFilter)
      }
    }

    if (input.dateRangeType === DateRangeOption.CUSTOM) {
      const { startDate, endDate } = reportInput.calendarRange[0]
      input.startDatetime = {
        seconds: parseInt(moment(startDate).utc(true).format('X')),
        nanos: 0
      }
      input.endDatetime = {
        seconds: parseInt(moment(endDate).utc(true).format('X')),
        nanos: 0
      }
    }

    if (input.closeDateRange.closeDateRangeType === CloseDateRangeOption.CUSTOM) {
      const { startDate, endDate } = reportInput.closeDateCalendarRange[0]
      input.closeDateRange.startDatetime = {
        seconds: parseInt(moment(startDate).utc(true).format('X')),
        nanos: 0
      }
      input.closeDateRange.endDatetime = {
        seconds: parseInt(moment(endDate).utc(true).format('X')),
        nanos: 0
      }
    }

    setSelectedGroup({})
    setPrevSelectedGroup({})

    dispatch(clearApiActivityByKey(getPipelineReportKey))
    dispatch(clearPipelineReport())
    dispatch(getPipelineReport(input))

    setCurrentInput(input)
    setCache(cacheContext, cacheInputKey, input)

    // console.log('input', input)

    window.analytics.track('reporting.runClicked')
  }, [totalPipeline, pipelineReportOptions])

  const lastRun = useMemo(() => {
    if (get(pipelineReport, 'createdAt.valid', false)) {
      return `Last Run: ${moment(get(pipelineReport, 'createdAt.value.seconds', 0) * 1000).fromNow()}`
    } else {
      return ''
    }
  }, [pipelineReport])

  return (
    <AnalyticsContainer>

      <Header
        text="Analytics"
        showSearch={false}
        childrenPaddingTop={93}>
        {permissions.CanCreateAnalytic && (
          <ReportInput isRunning={isRunning} handleRunClick={handleRunClick} />
        )}
      </Header>

      <div className="main-body">
        {permissions.CanReadAnalytic && (
          <>
            {isRunning
              ? (
                <AnimatedLoader
                  className="mt-16"
                  title="Hang Tight!"
                  subTitle="We're running the data real quick, don't go anywhere"
                  maxWidth={220} />
              )
              : (
                <>
                  {
                    hasPipelineReport
                      ? (
                        <>
                          {
                            hasPipelineResults
                              ? (
                                <>
                                  <div className="pb-4 border-b border-color-eaeaea">
                                    <div className="text-color-151d49 text-size-22px font-bold">Pipeline Movement</div>
                                    <div className="text-color-a6aebe text-size-14px font-weight-500">{lastRun}</div>
                                  </div>

                                  <div className="my-4 text-size-22px font-bold">
                                    {formatDate(get(pipelineReport, 'input.startDatetime.seconds', 0), 'MMM D, YYYY')}
                                  </div>
                                  {pipelineGroups.length > 0 && (
                                    <div ref={containerRef} className="flex">
                                      <PipelineGroup pipelineGroups={pipelineGroups}
                                        containerWidth={width}
                                        inPipeline={true}
                                        selectedGroup={selectedGroup}
                                        handleItemClick={handleItemClick} />
                                      <PipelineGroup pipelineGroups={pipelineGroups}
                                        containerWidth={width}
                                        inPipeline={false}
                                        selectedGroup={selectedGroup}
                                        handleItemClick={handleItemClick} />
                                    </div>
                                  )}

                                  <div className="my-4">
                                    {formatDate(get(pipelineReport, 'input.endDatetime.seconds', 0), 'MMM D, YYYY')}
                                  </div>
                                  {selectedGroup.endGroupsList
                                    && (
                                      <PipelineGroupResults
                                        selectedGroup={selectedGroup}
                                        prevSelectedGroup={prevSelectedGroup}
                                        containerWidth={width}
                                        inputParams={currentInput} />
                                    )}
                                </>
                              )
                              : (
                                <ResultPlaceholder
                                  className="mt-16"
                                  title="No Data Available"
                                  subTitle="Please try another question" />
                              )
                          }
                        </>
                      )
                      : (
                        <ResultPlaceholder
                          className="mt-16"
                          title="Get Started"
                          subTitle="Customize the question above to get a detailed breakdown of what's moved in your sales pipeline"
                          maxWidth={320} />
                      )
                  }
                </>
              )}
          </>
        )}

      </div>

    </AnalyticsContainer>
  )
}

export default Analytics
