import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useLocationData } from '../location/hooks'
import { cloneDeep, find, forOwn } from 'lodash'
import { useDispatch } from 'react-redux'
import { setViewInitialized } from '../../actions'
import Edit from '../svg/edit'
import { useSaveUserViews } from './hooks'
import { useDragAndDrop } from '../dragAndDrop/hooks'

const ViewOption = (props) => {
  const {
    data,
    filterKey,
    viewsList = [],
    selected,
    index,
    moveView,
    dragAndDropDisabled = false,
  } = props

  const { key, readonly, order } = data

  const isReadonly = useMemo(() => {
    return readonly || (order < 0)
  }, [readonly, order])

  const [label, setLabel] = useState(data.label || '')

  useEffect(() => {
    setLabel(data.label || '')
  }, [data])

  const labelRef = useRef()

  const { location, hash } = useLocationData()
  const history = useHistory()

  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    if (!selected) {
      dispatch(setViewInitialized(false))

      let route = location.pathname
      let i = 0
      const h = {
        ...hash
      }
      const view = find(viewsList, (v) => v.key === key)
      if (view) {
        const { sortKey, sortDirection } = view.payload.query

        if (sortKey && sortDirection) {
          h.sortKey = sortKey
          h.sortDirection = sortDirection
        }
      }
      h.view = key
      delete h.skip
      delete h.take
      forOwn(h, (value, key) => {
        if (i === 0) {
          route += '#'
        } else {
          route += '&'
        }
        if (value) {
          route += `${key}=${value}`
        } else {
          route += key
        }
        i += 1
      })
      history.push(route)
      window.analytics.track('views.viewClicked')
    }
  }, [selected, dispatch, location.pathname, hash, viewsList, key, history])

  const [isEditMode, setIsEditMode] = useState(false)

  const onEdit = useCallback((e) => {
    e.preventDefault()
    setIsEditMode(true)
    window.analytics.track('views.viewRenameClicked')
  }, [labelRef])

  const { saveUserViews } = useSaveUserViews({ filterKey })

  const handleLabelChange = useCallback(() => {
    setIsEditMode(false)
    const el = labelRef.current
    if (el) {
      const newLabel = el.innerText.trim()
      if (newLabel === '') {
        el.innerText = label
        setLabel(label)
      } else if (newLabel !== label.toUpperCase()) {
        setLabel(newLabel)

        const views = cloneDeep(viewsList)
        const view = find(views, (v) => v.key === key)
        if (view) {
          view.label = newLabel
          saveUserViews(views)
        }
      }
    }
  }, [filterKey, key, viewsList, labelRef, label])

  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleLabelChange()
    } else if (e.target && e.target.innerText && e.target.innerText.length >= 40) {
      e.preventDefault()
    }
  }, [handleLabelChange])

  const onBlur = useCallback(() => {
    handleLabelChange()
  }, [handleLabelChange])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      if (isEditMode) {
        if (labelRef && labelRef.current) {
          const el = labelRef.current
          el.focus()
          document.execCommand('selectAll', false, null)
        }
      }
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [labelRef, isEditMode])

  const { ref, handlerId, isDragging, dragLayer } = useDragAndDrop({
    key,
    index,
    type: 'ViewOption',
    onMove: moveView,
    horizontal: true,
    disabled: dragAndDropDisabled,
  })

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <div className={classNames('flex flex-col py-1 pr-6 mt-1 whitespace-nowrap leading-tight focus:outline-none',
        { 'pointer-events-none': selected },
        { 'cursor-pointer': !selected })}>
        <div className="flex items-center group">
          <div>
            <div
              ref={labelRef}
              contentEditable={isEditMode}
              suppressContentEditableWarning={true}
              {...!isEditMode && { onClick }}
              onKeyPress={onKeyPress}
              onBlur={onBlur}
              className={classNames('viewOption text-size-14px font-bold tracking-widest uppercase focus:outline-none',
                { 'bg-color-eaeaea rounded': isDragging },
                { 'text-color-151d49': !isDragging && selected },
                { 'text-color-818e93': !isDragging && !selected })}>
              <span className={classNames({ 'opacity-0': isDragging })}>
                {label}
              </span>
            </div>
            <div
              className={
                classNames('w-full rounded-full bg-color-5951FF',
                  { 'opacity-0': isDragging },
                  { invisible: !selected })
              }
              style={{ height: 3 }} />
          </div>
          {!isReadonly && !dragLayer.isDragging
            && (
              <div className="relative">
                <div className="viewOption-edit absolute" style={{ transform: 'translate(-5px, -14px)' }} onClick={onEdit}>
                  <div className="invisible group-hover:visible">
                    <Edit fill="#a0a8bb" transform="scale(0.55)" />
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default ViewOption

export function viewOptionRenderer({ data = [], isDragging, item, itemType, clientOffset }) {
  const view = find(data, (d) => item && d.key === item.id)
  if (itemType === 'ViewOption' && view && clientOffset) {
    const { label } = view
    const { x, y } = clientOffset
    return (
      <div style={{ transform: `translate(${x}px, ${y}px)` }}>
        <div className="viewOption text-size-14px font-bold tracking-widest uppercase focus:outline-none text-color-5951FF">{label}</div>
      </div>
    )
  } else {
    return null
  }
}
