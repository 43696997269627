import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DealsList from '../deals/dealsList'
import { getModalOpportunities } from '../../services/opportunityService'
import { clearModalOpportunities } from '../../actions'
import AnimatedLoader from '../loaders/animatedLoader'
import { apiSuccessSelector } from '../../selectors'
import { getModalOpportunitiesKey } from '../../actions/opportunityService'
import { Waypoint } from 'react-waypoint'
import { hideAll } from 'tippy.js'
import useDisableWheel from '../../hooks/useDisableWheel'
import useRoles from '../../hooks/useRoles'

const DealsModal = (props) => {
  const { ids = [], title, subTitle, closeModal } = props

  const { isIndividualContributorRoleOnly } = useRoles()

  const dispatch = useDispatch()

  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getModalOpportunitiesKey))

  const modalOpportunities = useSelector((state) => state.modalOpportunities)
  const { opportunitiesList, totalCount } = modalOpportunities

  useEffect(() => {
    ids.length > 0 && dispatch(getModalOpportunities(ids, 20))
  }, [])

  const showAnimatedLoader = useMemo(() => {
    return ids.length > 0 && opportunitiesList.length === 0 && !apiSuccess
  }, [apiSuccess, opportunitiesList])

  const showEndOfListLoader = useMemo(() => {
    return totalCount > 0 && opportunitiesList.length < totalCount
  }, [opportunitiesList, totalCount])

  const handleWaypointEntered = useCallback(() => {
    ids.length > 0 && dispatch(getModalOpportunities(ids, 20))
  }, [opportunitiesList])

  const containerRef = useRef(null)
  const headerRef = useRef(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [windowSize, setWindowSize] = useState({ width: window.innerHeight, height: window.innerHeight })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerHeight, height: window.innerHeight })
      if (containerRef.current) {
        hideAll()
        const computedStyle = getComputedStyle(containerRef.current)
        setContainerSize({
          width: computedStyle.width.replace('px', '') * 1,
          height: computedStyle.height.replace('px', '') * 1
        })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const closeModalInternal = useCallback(() => {
    dispatch(clearModalOpportunities())
    closeModal && closeModal()
  }, [closeModal])

  const handleItemClick = useCallback(() => {
    closeModalInternal()
  }, [closeModalInternal])

  const buttonRef = useRef()
  useDisableWheel(buttonRef)

  return (
    <div ref={containerRef} className="static mx-auto bg-color-ffffff rounded overflow-hidden" style={{ maxWidth: '915px', maxHeight: windowSize.height - 80, marginTop: 40 }}>
      <div className="overflow-y-auto" style={{ maxWidth: '915px', maxHeight: windowSize.height - 80 }}>
        <div ref={headerRef} className="fixed bg-color-ffffff rounded pt-9 pb-6 pointer-events-none" style={{ width: containerSize.width - 20 }}>
          <div className="w-full flex justify-between">
            <div className="pl-10 text-color-151d49 text-size-24px">
              {title}
              {' '}
              {apiSuccess && (
                <span>
                  (
                  {totalCount}
                  )
                </span>
              )}
            </div>
            <button ref={buttonRef} onClick={closeModalInternal} className="focus:outline-none pointer-events-auto">
              <FontAwesomeIcon icon="times" size="lg" className="mr-6 text-color-151d49" />
            </button>
          </div>
          {subTitle && <div className="px-10 text-color-4e5d7e">{subTitle}</div>}
        </div>
        <div className="mt-28 px-10 pb-10">
          {showAnimatedLoader
            ? (
              <AnimatedLoader
                className="mt-40 mb-10"
                title="Loading Deals"
                subTitle="Please wait..."
                maxWidth={220} />
            )
            : (
              <>
                <DealsList
                  opportunities={opportunitiesList}
                  enableSorting={false}
                  enableFilters={false}
                  showEmptyList={false}
                  disabledNav={isIndividualContributorRoleOnly}
                  emptyStateSubHeader="There are no deals to show."
                  handleItemClick={handleItemClick} />
                {showEndOfListLoader && (
                  <>
                    <div className="flex justify-center my-4">
                      <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
                    </div>
                    <Waypoint onEnter={handleWaypointEntered} />
                  </>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  )
}

export default DealsModal
