import { camelCase, forEach, has, upperFirst } from 'lodash'
import { typeCheck } from 'type-check'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb.js'
import { NullFloat64, NullString, NullTimestamp } from '../protos/src/common/nullable/nullable_pb'
import { Person } from '../protos/src/common/person/person_pb'
import { QuotaSummary } from '../protos/src/common/quota/quota_pb'
import { Opportunity } from '../protos/src/common/opportunity/opportunity_pb'
import {
  PipelineReport,
  TimeWindowedReportInput,
  CloseDateRange,
} from '../protos/src/common/reporting/pipeline_report_pb'
import { QueryParam, QueryParamComparison } from '../protos/src/common/param/query_param_pb'
import { Note, Attachment, Mention, ShareItem } from '../protos/src/common/notes/notes_pb'
import { IdentificationParams } from '../protos/src/services/reporting/reporting_pb'
import { Query } from '../protos/src/common/query/query_pb'
import {
  FutureForecastContainerInput,
  FutureForecastContainerOutput,
  FutureForecastEquation,
  FutureForecastEquationParam,
  FutureForecastEquationParamEvalRange,
  FutureForecastGlobalLevel,
  FutureForecastInputCategory,
  FutureForecastInputField,
  FutureForecastNumericResult,
  FutureForecastScenario,
  FutureForecastRunContainer,
} from '../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import {
  ActivityDirection as ActivityDirectionEnum,
  ActivityType as ActivityTypeEnum,
} from '../protos/src/common/activity/activity_pb'
import {
  SearchRequest,
  GetSearchItemByIDRequest,
  GetTenantOrganizationRequest,
  PatchSearchItemRequest,
  GetSavedFiltersByKeyRequest,
  UpsertSavedFiltersByKeyRequest,
  GetUserHasActivitySyncedRequest,
  CheckPermissionsRequest,
  IndividualFeaturePermissionCheck,
  PermissionCheckObject,
  ExportSearchResultsRequest,
  ExportSearchResultsFields,
  GetObjectMappingsSourceOptionsRequest,
  RunRealtimeQueriesRequest,
  RealtimeQueryRequestContainer,
  GetOutreachSettingRequest,
  GetObjectReferenceFieldOptionsRequest,
  ResolveObjectReferenceFieldOptionsContainer,
  ResolveObjectReferenceFieldOptionsRequest,
} from '../protos/src/services/api/api_pb'
import {
  GetCanvasRequest,
  GetCanvasTileRequest,
  DeleteCanvasTileUserQueryParametersRequest,
  UpsertCanvasTileUserQueryParametersRequest,
  UpsertCanvasTileRequest,
  AdjustOrgCanvasTileLayoutRequest
} from '../protos/src/services/mosaic/mosaic_pb'
import {
  SearchContainer,
  SearchContainerNode,
  SearchContainerChildren,
  SearchPaginationContainer,
  SearchSortItem,
} from '../protos/src/common/search/search_pb'
import {
  SearchItem,
  SearchItemField,
  SearchItemFieldActual,
  SearchItemFieldVersion,
  SearchItems,
} from '../protos/src/common/search/item/item_pb'
import {
  SavedFilter,
  SavedFilters,
} from '../protos/src/common/filter/filter_pb'
import {
  GetTenantObjectRequest,
  GetTenantsRequest,
  GetTenantInfoRequest,
} from '../protos/src/services/tenant/tenant_pb'
import {
  DefaultForecastRequest,
  ForecastOpportunityIdsRequest,
  GetDefaultForecastList
} from '../protos/src/services/forecast/v2/forecast_pb'
import {
  ExportSubmissionHistoryRequest,
  ForecastConfig,
  ForecastConfigColumn,
  ForecastConfigCustomQuerySettings,
  ForecastConfigDisplayFormula,
  ForecastConfigGoalSettings,
  ForecastConfigObjectSettings,
  ForecastConfigSearchContainer,
  ForecastConfigValueSettings,
  ForecastConfigPivotGroup,
  GetForecastRequest,
  GetForecastConfigRequest,
  ListForecastConfigsByGroupIdRequest,
  SaveForecastRequest,
  SearchRequest as SearchForecastsRequest,
  ForecastFilter,
  ForecastSort,
  ForecastTeamSettings,
  ForecastPeriod
} from '../protos/src/services/forecast/v3/forecast_pb'
import {
  Forecast,
  ForecastCall,
  ForecastObject
} from '../protos/src/common/forecast/v3/forecast_pb'
import {
  GetGroupSubTreeRequest,
  GetCRMRolesRequest,
  PersonFilter,
  SearchPeopleRequest,
} from '../protos/src/services/orchard/orchard_pb'
import {
  GetActivityTimelineRequest,
  GetActivityDetailsRequest,
  GetActivityDateCursorRequest,
} from '../protos/src/services/activity/activity_pb'
import {
  CanvasTile,
  CanvasTenantStandardParams,
  CanvasTileUserDefinedParametersLayout
} from '../protos/src/common/canvas/canvas_pb'
import {
  SourceOptions,
  FieldOptions,
  LabelValue,
  QueryParameters,
  QueryParameter,
  ParameterOptions,
  FilterParameter,
  FilterWrapper,
  Filter,
  TimeWindowParameter,
  TimeWindowRange,
  TimeWindowInterval,
  OrderParameter,
  Order,
  GroupParameter,
  Group,
  ValueParameter,
  ResolvedQueryParameter,
  Query as RQuery,
  FieldDefinition,
  QueryContract,
  QueryDependencies,
  UserQueryParameters
} from '../protos/src/common/realtime/query_pb'
import {
  GML,
  Include as GMLInclude,
  Data as GMLData,
  Function as GMLFunction,
  Content as GMLContent,
  UIControl,
  UIControlAttribute,
  EmailContentAttributes
} from '../protos/src/common/gml/gml_pb'
import { DataRequest } from '../protos/src/common/data_request_pb'
import {
  RunQueryObjectDataRequest,
  GroupedObjectFilter,
  TenantSettingParams
} from '../protos/src/services/hotspring/hotspring_pb'
import {
  Actor
} from '../protos/src/common/actor_pb'
import {
  Tile
} from '../protos/src/common/canvas/tile_pb'
import {
  RealtimeViewTile
} from '../protos/src/common/canvas/tiles/realtime_view_pb'
import {
  SearchOpportunitiesRequest
} from '../protos/src/services/tightbeam_global/tightbeam_global_pb'

// only converts standard js types (numbers, strings and booleans or arrays of each type)
export function protoFromObject(ProtoClass, obj) {
  const newObj = new ProtoClass()
  for (const prop in obj) {
    const setter = `set${upperFirst(camelCase(prop))}`
    const value = obj[prop]
    if (typeCheck('Function', newObj[setter])) {
      if (typeCheck('Number | String | Boolean', value)) {
        newObj[setter](value)
      } else if (typeCheck('[Number | String | Boolean]', value)) {
        newObj[setter](value.map((v) => v))
      }
    }
  }
  return newObj
}

export const toTimestamp = (obj) => {
  const newObj = new Timestamp()
  has(obj, 'seconds') && newObj.setSeconds(obj.seconds)
  has(obj, 'nanos') && newObj.setNanos(obj.nanos)
  return newObj
}

export const toNullTimestamp = (obj) => {
  const newObj = new NullTimestamp()
  has(obj, 'valid') && newObj.setValid(obj.valid)
  has(obj, 'value') && newObj.setValue(toTimestamp(obj.value))
  return newObj
}

export const toNullString = (obj) => {
  const newObj = new NullString()
  has(obj, 'valid') && newObj.setValid(obj.valid)
  has(obj, 'value') && newObj.setValue(obj.value)
  return newObj
}

export const toNullFloat64 = (obj) => {
  const newObj = new NullFloat64()
  has(obj, 'valid') && newObj.setValid(obj.valid)
  has(obj, 'value') && newObj.setValue(obj.value)
  return newObj
}

export const toQuotaSummary = (obj) => {
  const newObj = new QuotaSummary()
  has(obj, 'endDate') && newObj.setEndDate(toTimestamp(obj.endDate))
  has(obj, 'startDate') && newObj.setStartDate(toTimestamp(obj.startDate))
  has(obj, 'actual') && newObj.setActual(obj.actual)
  has(obj, 'baseline') && newObj.setBaseline(obj.baseline)
  has(obj, 'quarter') && newObj.setQuarter(obj.quarter)
  has(obj, 'monthIdx') && newObj.setMonthIdx(obj.monthIdx)
  has(obj, 'year') && newObj.setYear(obj.year)
  return newObj
}

export const toPerson = (obj) => {
  const newObj = new Person()
  has(obj, 'id') && newObj.setId(obj.id)
  has(obj, 'accountId') && newObj.setAccountId(obj.accountId)
  has(obj, 'username') && newObj.setUsername(obj.username)
  has(obj, 'firstName') && newObj.setFirstName(obj.firstName)
  has(obj, 'lastName') && newObj.setLastName(obj.lastName)
  has(obj, 'name') && newObj.setName(obj.name)
  has(obj, 'companyName') && newObj.setCompanyName(obj.companyName)
  has(obj, 'title') && newObj.setTitle(obj.title)
  has(obj, 'email') && newObj.setEmail(obj.email)
  has(obj, 'isActive') && newObj.setIsActive(obj.isActive)
  has(obj, 'lastLoginDate') && newObj.setLastLoginDate(toTimestamp(obj.lastLoginDate))
  has(obj, 'managerId') && newObj.setManagerId(obj.managerId)
  has(obj, 'timezone') && newObj.setTimezone(obj.timezone)
  has(obj, 'photoUrl') && newObj.setPhotoUrl(obj.photoUrl)
  has(obj, 'createdDate') && newObj.setCreatedDate(toTimestamp(obj.createdDate))
  has(obj, 'lastModifiedDate') && newObj.setLastModifiedDate(toTimestamp(obj.lastModifiedDate))
  has(obj, 'quota') && newObj.setQuota(obj.quota)
  has(obj, 'teamId') && newObj.setTeamId(obj.teamId)
  has(obj, 'quotaDataList') && newObj.setQuotaDataList(obj.quotaDataList.map((i) => toQuotaSummary(i)))
  has(obj, 'hasChildren') && newObj.setHasChildren(obj.hasChildren)
  return newObj
}

export const toOpportunity = (obj) => {
  const newObj = new Opportunity()
  has(obj, 'id') && newObj.setId(obj.id)
  has(obj, 'teamId') && newObj.setTeamId(obj.teamId)
  has(obj, 'isDeleted') && newObj.setIsDeleted(obj.isDeleted)
  has(obj, 'accountId') && newObj.setAccountId(obj.accountId)
  has(obj, 'recordTypeId') && newObj.setRecordTypeId(obj.recordTypeId)
  has(obj, 'name') && newObj.setName(obj.name)
  has(obj, 'description') && newObj.setDescription(obj.description)
  has(obj, 'stageName') && newObj.setStageName(obj.stageName)
  has(obj, 'amount') && newObj.setAmount(obj.amount)
  has(obj, 'probability') && newObj.setProbability(obj.probability)
  has(obj, 'closeDate') && newObj.setCloseDate(toTimestamp(obj.closeDate))
  has(obj, 'type') && newObj.setType(obj.type)
  has(obj, 'leadSource') && newObj.setLeadSource(obj.leadSource)
  has(obj, 'isClosed') && newObj.setIsClosed(obj.isClosed)
  has(obj, 'isWon') && newObj.setIsWon(obj.isWon)
  has(obj, 'forecastCategory') && newObj.setForecastCategory(obj.forecastCategory)
  has(obj, 'forecastCategoryName') && newObj.setForecastCategoryName(obj.forecastCategoryName)
  has(obj, 'pricebook2Id') && newObj.setPricebook2Id(obj.pricebook2Id)
  has(obj, 'ownerId') && newObj.setOwnerId(obj.ownerId)
  has(obj, 'createdDate') && newObj.setCreatedDate(toTimestamp(obj.createdDate))
  has(obj, 'createdById') && newObj.setCreatedById(obj.createdById)
  has(obj, 'lastModifiedDate') && newObj.setLastModifiedDate(toTimestamp(obj.lastModifiedDate))
  has(obj, 'lastActivityDate') && newObj.setLastActivityDate(toTimestamp(obj.lastActivityDate))
  has(obj, 'contractLength') && newObj.setContractLength(obj.contractLength)
  has(obj, 'nextStep') && newObj.setNextStep(obj.nextStep)
  has(obj, 'website') && newObj.setWebsite(obj.website)
  has(obj, 'stagesList') && newObj.setStagesList(obj.stagesList)
  has(obj, 'insightCount') && newObj.setInsightCount(obj.insightCount)
  has(obj, 'status') && newObj.setStatus(obj.status)
  has(obj, 'score') && newObj.setScore(obj.score)
  has(obj, 'currentPeriod') && newObj.setCurrentPeriod(obj.currentPeriod)
  return newObj
}

export const toQueryParamComparison = (obj) => {
  const newObj = new QueryParamComparison()
  has(obj, 'op') && newObj.setOp(obj.op)
  has(obj, 'valuesList') && newObj.setValuesList(obj.valuesList)
  has(obj, 'weight') && newObj.setWeight(obj.weight)
  return newObj
}

export const toQueryParam = (obj) => {
  const newObj = new QueryParam()
  has(obj, 'key') && newObj.setKey(obj.key)
  has(obj, 'fieldName') && newObj.setFieldName(obj.fieldName)
  has(obj, 'type') && newObj.setType(obj.type)
  has(obj, 'title') && newObj.setTitle(obj.title)
  has(obj, 'comparisonList') && newObj.setComparisonList(obj.comparisonList.map((i) => toQueryParamComparison(i)))
  return newObj
}

export const toCloseDateRange = (obj) => {
  const newObj = new CloseDateRange()
  has(obj, 'closeDateRangeType') && newObj.setCloseDateRangeType(obj.closeDateRangeType)
  has(obj, 'startDatetime') && newObj.setStartDatetime(toTimestamp(obj.startDatetime))
  has(obj, 'endDatetime') && newObj.setEndDatetime(toTimestamp(obj.endDatetime))
  return newObj
}

export const toTimeWindowedReportInput = (obj) => {
  const newObj = new TimeWindowedReportInput()
  has(obj, 'startDatetime') && newObj.setStartDatetime(toTimestamp(obj.startDatetime))
  has(obj, 'endDatetime') && newObj.setEndDatetime(toTimestamp(obj.endDatetime))
  has(obj, 'groupByList') && newObj.setGroupByList(obj.groupByList)
  has(obj, 'filtersList') && newObj.setFiltersList(obj.filtersList.map((i) => toQueryParam(i)))
  has(obj, 'dateRangeType') && newObj.setDateRangeType(obj.dateRangeType)
  has(obj, 'closeDateRange') && newObj.setCloseDateRange(toCloseDateRange(obj.closeDateRange))
  return newObj
}

export const toPipelineReport = (obj) => {
  const newObj = new PipelineReport()
  has(obj, 'input') && newObj.setInput(toTimeWindowedReportInput(obj.input))
  return newObj
}

export const toAttachment = (obj) => {
  const newObj = new Attachment()
  has(obj, 'noteId') && newObj.setNoteId(obj.noteId)
  has(obj, 'resourceId') && newObj.setResourceId(obj.resourceId)
  has(obj, 'type') && newObj.setType(obj.type)
  has(obj, 'label') && newObj.setLabel(obj.label)
  has(obj, 'link') && newObj.setLink(obj.link)
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toMention = (obj) => {
  const newObj = new Mention()
  has(obj, 'id') && newObj.setId(obj.id)
  has(obj, 'noteId') && newObj.setNoteId(obj.noteId)
  has(obj, 'resourceId') && newObj.setResourceId(obj.resourceId)
  has(obj, 'type') && newObj.setType(obj.type)
  has(obj, 'label') && newObj.setLabel(obj.label)
  has(obj, 'link') && newObj.setLink(obj.link)
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toShareItem = (obj) => {
  const newObj = new ShareItem()
  has(obj, 'id') && newObj.setId(obj.id)
  has(obj, 'noteId') && newObj.setNoteId(obj.noteId)
  has(obj, 'type') && newObj.setType(obj.type)
  has(obj, 'sharedWithId') && newObj.setSharedWithId(obj.sharedWithId)
  has(obj, 'status') && newObj.setStatus(obj.status)
  return newObj
}

export const toNote = (obj) => {
  const newObj = new Note()
  has(obj, 'id') && newObj.setId(obj.id)
  has(obj, 'tenantId') && newObj.setTenantId(obj.tenantId)
  has(obj, 'name') && newObj.setName(obj.name)
  has(obj, 'raw') && newObj.setRaw(obj.raw)
  has(obj, 'attachmentsList') && newObj.setAttachmentsList(obj.attachmentsList.map((a) => toAttachment(a)))
  has(obj, 'mentionsList') && newObj.setMentionsList(obj.mentionsList.map((m) => toMention(m)))
  has(obj, 'tagsList') && newObj.setTagsList(obj.tagsList)
  has(obj, 'status') && newObj.setStatus(obj.status)
  has(obj, 'ownerId') && newObj.setOwnerId(obj.ownerId)
  has(obj, 'owner') && newObj.setOwner(toPerson(obj.owner))
  has(obj, 'visibility') && newObj.setVisibility(obj.visibility)
  has(obj, 'shareListList') && newObj.setShareListList(obj.shareListList.map((s) => toShareItem(s)))
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toIdentificationParams = (obj) => {
  const newObj = new IdentificationParams()
  has(obj, 'tenantId') && newObj.setTenantId(obj.tenantId)
  has(obj, 'groupId') && newObj.setGroupId(obj.groupId)
  has(obj, 'userId') && newObj.setUserId(obj.userId)
  return newObj
}

export const toFutureForecastGlobalLevel = (obj) => {
  const newObj = new FutureForecastGlobalLevel()
  has(obj, 'level') && newObj.setLevel(obj.level)
  has(obj, 'entityIdsList') && newObj.setEntityIdsList(obj.entityIdsList)
  return newObj
}

export const toFutureForecastEquationParamEvalRange = (obj) => {
  const newObj = new FutureForecastEquationParamEvalRange()
  has(obj, 'rangeType') && newObj.setRangeType(obj.rangeType)
  has(obj, 'value') && newObj.setValue(obj.value)
  has(obj, 'includeWholeWindow') && newObj.setIncludeWholeWindow(obj.includeWholeWindow)
  return newObj
}

export const toFutureForecastEquationParam = (obj) => {
  const newObj = new FutureForecastEquationParam()
  has(obj, 'key') && newObj.setKey(obj.key)
  has(obj, 'format') && newObj.setFormat(obj.format)
  has(obj, 'randomize') && newObj.setRandomize(obj.randomize)
  has(obj, 'group') && newObj.setGroup(obj.group)
  has(obj, 'aggType') && newObj.setAggType(obj.aggType)
  has(obj, 'rangeType') && newObj.setRangeType(toFutureForecastEquationParamEvalRange(obj.rangeType))
  has(obj, 'subSampleRange') && newObj.setSubSampleRange(obj.subSampleRange)
  has(obj, 'label') && newObj.setLabel(obj.label)
  has(obj, 'objectType') && newObj.setObjectType(obj.objectType)
  has(obj, 'includeInactiveEntities') && newObj.setIncludeInactiveEntities(obj.includeInactiveEntities)
  has(obj, 'alternateDataKey') && newObj.setAlternateDataKey(obj.alternateDataKey)
  return newObj
}

export const toFutureForecastEquation = (obj) => {
  const newObj = new FutureForecastEquation()
  has(obj, 'literal') && newObj.setLiteral(obj.literal)
  has(obj, 'paramsList') && newObj.setParamsList(obj.paramsList.map((p) => toFutureForecastEquationParam(p)))
  return newObj
}

export const toFutureForecastInputField = (obj) => {
  const newObj = new FutureForecastInputField()
  has(obj, 'key') && newObj.setKey(obj.key)
  has(obj, 'name') && newObj.setName(obj.name)
  has(obj, 'value') && newObj.setValue(obj.value)
  has(obj, 'deviation') && newObj.setDeviation(obj.deviation)
  has(obj, 'sort') && newObj.setSort(obj.sort)
  return newObj
}

export const toFutureForecastInputCategory = (obj) => {
  const newObj = new FutureForecastInputCategory()
  has(obj, 'key') && newObj.setKey(obj.key)
  has(obj, 'fieldsList') && newObj.setFieldsList(obj.fieldsList.map((f) => toFutureForecastInputField(f)))
  return newObj
}

export const toFutureForecastContainerInput = (obj) => {
  const newObj = new FutureForecastContainerInput()
  has(obj, 'baseline') && newObj.setBaseline(toFutureForecastContainerInput(obj.baseline))
  has(obj, 'isActive') && newObj.setIsActive(obj.isActive)
  has(obj, 'categoriesList') && newObj.setCategoriesList(obj.categoriesList.map((c) => toFutureForecastInputCategory(c)))
  return newObj
}

export const toFutureForecastNumericResult = (obj) => {
  const newObj = new FutureForecastNumericResult()
  has(obj, 'value') && newObj.setValue(obj.value)
  has(obj, 'count') && newObj.setCount(obj.count)
  has(obj, 'callout') && newObj.setCallout(obj.callout)
  return newObj
}

export const toFutureForecastContainerOutput = (obj) => {
  const newObj = new FutureForecastContainerOutput()
  has(obj, 'numericValuesList') && newObj.setNumericValuesList(obj.numericValuesList.map((n) => toFutureForecastNumericResult(n)))
  has(obj, 'format') && newObj.setFormat(obj.format)
  return newObj
}

export const toFutureForecastScenario = (obj) => {
  const newObj = new FutureForecastScenario()
  has(obj, 'key') && newObj.setKey(obj.key)
  has(obj, 'label') && newObj.setLabel(obj.label)
  has(obj, 'createdAt.value') && newObj.setCreatedAt(toNullTimestamp(obj.createdAt))
  has(obj, 'input') && newObj.setInput(toFutureForecastContainerInput(obj.input))
  has(obj, 'output') && newObj.setOutput(toFutureForecastContainerOutput(obj.output))
  has(obj, 'baseline') && newObj.setBaseline(toFutureForecastScenario(obj.baseline))
  return newObj
}

export const toRealtimeQuery = (obj) => {
  const newObj = new Query()
  has(obj, 'key') && newObj.setKey(obj.key)
  return newObj
}

export const toFutureForecastRunContainer = (obj) => {
  const newObj = new FutureForecastRunContainer()
  has(obj, 'key') && newObj.setKey(obj.key)
  has(obj, 'label') && newObj.setLabel(obj.label)
  has(obj, 'startDatetime') && newObj.setStartDatetime(toTimestamp(obj.startDatetime))
  has(obj, 'endDatetime') && newObj.setEndDatetime(toTimestamp(obj.endDatetime))
  has(obj, 'dateRangeType') && newObj.setDateRangeType(obj.dateRangeType)
  has(obj, 'filtersList') && newObj.setFiltersList(obj.filtersList.map((i) => toQueryParam(i)))
  has(obj, 'level') && newObj.setLevel(toFutureForecastGlobalLevel(obj.level))
  has(obj, 'equation') && newObj.setEquation(toFutureForecastEquation(obj.equation))
  has(obj, 'realtimeQuery') && newObj.setRealtimeQuery(toRealtimeQuery(obj.realtimeQuery))
  has(obj, 'scenario') && newObj.setScenario(toFutureForecastScenario(obj.scenario))
  has(obj, 'outputFormat') && newObj.setOutputFormat(obj.outputFormat)
  return newObj
}

export const toSearchItemFieldActual = (obj) => {
  const newObj = protoFromObject(SearchItemFieldActual, obj)
  has(obj, 'type') && newObj.setType(obj.type)
  has(obj, 'value') && newObj.setValue(obj.value)
  return newObj
}

export const toSearchItemFieldVersion = (obj) => {
  const newObj = new SearchItemFieldVersion()
  has(obj, 'instancesList') && newObj.setInstancesList(obj.instancesList.map((i) => toSearchItemFieldActual(i)))
  has(obj, 'ts') && newObj.setTs(toTimestamp(obj.ts))
  return newObj
}

export const toSearchItemField = (obj) => {
  const newObj = new SearchItemField()
  has(obj, 'instancesList') && newObj.setInstancesList(obj.instancesList.map((i) => toSearchItemFieldActual(i)))
  has(obj, 'versionsList') && newObj.setVersionsList(obj.versionsList.map((v) => toSearchItemFieldVersion(v)))
  return newObj
}

export const toSearchItem = (obj) => {
  const newObj = protoFromObject(SearchItem, obj)
  if (has(obj, 'fieldsMap')) {
    const fieldsMap = newObj.getFieldsMap()
    forEach(obj.fieldsMap, (m) => {
      if (m.length > 1) {
        fieldsMap.set(m[0], toSearchItemField(m[1]))
      }
    })
  }
  return newObj
}

export const toSearchItems = (obj) => {
  const newObj = protoFromObject(SearchItems, obj)
  has(obj, 'valuesList') && newObj.setValuesList(obj.valuesList.map((v) => toSearchItem(v)))
  return newObj
}

export const toSearchSortItem = (obj) => {
  const newObj = protoFromObject(SearchSortItem, obj)
  has(obj, 'item') && newObj.setItem(toSearchItemFieldActual(obj.item))
  return newObj
}

export const toSearchContainerNode = (obj) => {
  const newObj = protoFromObject(SearchContainerNode, obj)
  has(obj, 'item') && newObj.setItem(toSearchItemFieldActual(obj.item))
  has(obj, 'operation') && newObj.setOperation(obj.operation)
  return newObj
}

export const toSearchPaginationContainer = (obj) => {
  const newObj = protoFromObject(SearchPaginationContainer, obj)
  has(obj, 'sortList') && newObj.setSortList(obj.sortList.map((s) => toSearchSortItem(s)))
  return newObj
}

export const toSearchContainer = (obj) => {
  const newObj = new SearchContainer()
  has(obj, 'node') && newObj.setNode(toSearchContainerNode(obj.node))
  // eslint-disable-next-line no-use-before-define
  has(obj, 'children') && newObj.setChildren(toSearchContainerChildren(obj.children))
  return newObj
}

export const toSearchContainerChildren = (obj) => {
  const newObj = new SearchContainerChildren()
  has(obj, 'combineAction') && newObj.setCombineAction(obj.combineAction)
  has(obj, 'valuesList') && newObj.setValuesList(obj.valuesList.map((v) => toSearchContainer(v)))
  return newObj
}

export const toSearchRequest = (obj) => {
  const newObj = protoFromObject(SearchRequest, obj)
  has(obj, 'query') && newObj.setQuery(toSearchContainer(obj.query))
  has(obj, 'paging') && newObj.setPaging(toSearchPaginationContainer(obj.paging))
  has(obj, 'changeSince') && newObj.setChangeSince(toTimestamp(obj.changeSince))
  return newObj
}

export const toGetSearchItemByIDRequest = (obj) => {
  const newObj = protoFromObject(GetSearchItemByIDRequest, obj)
  has(obj, 'changeSince') && newObj.setChangeSince(toTimestamp(obj.changeSince))
  return newObj
}

export const toGetTenantObjectRequest = (obj) => {
  const newObj = protoFromObject(GetTenantObjectRequest, obj)
  return newObj
}

export const toPatchSearchItemRequest = (obj) => {
  const newObj = protoFromObject(PatchSearchItemRequest, obj)
  has(obj, 'item') && newObj.setItem(toSearchItem(obj.item))
  has(obj, 'changeSince') && newObj.setChangeSince(toTimestamp(obj.changeSince))
  return newObj
}

export const toForecastTeamSettings = (obj) => {
  const newObj = protoFromObject(ForecastTeamSettings, obj)
  has(obj, 'levelChildGroupsList') && newObj.setLevelChildGroupsList(obj.levelChildGroupsList)
  has(obj, 'levelMembersList') && newObj.setLevelMembersList(obj.levelMembersList)
  return newObj
}

export const toDefaultForecastRequest = (obj) => {
  const newObj = protoFromObject(DefaultForecastRequest, obj)
  has(obj, 'forecastTeamSettings') && newObj.setTeamSettings(toForecastTeamSettings(obj.forecastTeamSettings))
  if (has(obj, 'categoryRollupsMap')) {
    const categoryRollupsMap = newObj.getCategoryRollupsMap()
    forEach(obj.categoryRollupsMap, (m) => {
      if (m.length > 1) {
        categoryRollupsMap.set(m[0], m[1])
      }
    })
  }
  return newObj
}

export const toForecastOpportunityIdsRequest = (obj) => {
  const newObj = protoFromObject(ForecastOpportunityIdsRequest, obj)
  return newObj
}

export const toForecastCall = (obj) => {
  const newObj = protoFromObject(ForecastCall, obj)
  has(obj, 'pb_default') && newObj.setDefault(obj.pb_default)
  has(obj, 'value') && newObj.setValue(toNullFloat64(obj.value))
  return newObj
}

export const toForecastObject = (obj) => {
  const newObj = protoFromObject(ForecastObject, obj)
  has(obj, 'opportunity') && newObj.setOpportunity(toOpportunity(obj.opportunity))
  return newObj
}

export const toForecast = (obj) => {
  const newObj = protoFromObject(Forecast, obj)
  has(obj, 'callsList') && newObj.setCallsList(obj.callsList.map(toForecastCall))
  has(obj, 'objectsList') && newObj.setObjectsList(obj.objectsList.map(toForecastObject))
  return newObj
}

export const toSaveForecastRequest = (obj) => {
  const newObj = protoFromObject(SaveForecastRequest, obj)
  has(obj, 'forecastTeamSettings') && newObj.setTeamSettings(toForecastTeamSettings(obj.forecastTeamSettings))
  has(obj, 'forecast') && newObj.setForecast(toForecast(obj.forecast))
  return newObj
}

export const toGetDefaultForecastList = (obj) => {
  const newObj = protoFromObject(GetDefaultForecastList, obj)
  return newObj
}

export const toForecastSort = (obj) => {
  const newObj = protoFromObject(ForecastSort, obj)
  return newObj
}

export const toForecastFilter = (obj) => {
  const newObj = protoFromObject(ForecastFilter, obj)
  return newObj
}

export const toSearchForecastsRequest = (obj) => {
  const newObj = protoFromObject(SearchForecastsRequest, obj)
  has(obj, 'filtersList') && newObj.setFiltersList(obj.filtersList.map(toForecastFilter))
  has(obj, 'sortOptionsList') && newObj.setSortOptionsList(obj.filtersList.map(toForecastSort))
  has(obj, 'period') && newObj.setPeriod(toForecastPeriod(obj.period))
  return newObj
}

export const toGetTenantOrganizationRequest = (obj) => {
  const newObj = protoFromObject(GetTenantOrganizationRequest, obj)
  return newObj
}

export const toGetSavedFiltersByKeyRequest = (obj) => {
  const newObj = protoFromObject(GetSavedFiltersByKeyRequest, obj)
  return newObj
}

export const toSavedFilter = (obj) => {
  const newObj = protoFromObject(SavedFilter, obj)
  return newObj
}

export const toSavedFilters = (obj) => {
  const newObj = protoFromObject(SavedFilters, obj)
  if (has(obj, 'valueMap')) {
    const valueMap = newObj.getValueMap()
    forEach(obj.valueMap, (m) => {
      if (m.length > 1) {
        valueMap.set(m[0], toSavedFilter(m[1]))
      }
    })
  }
  return newObj
}

export const toUpsertSavedFiltersByKeyRequest = (obj) => {
  const newObj = protoFromObject(UpsertSavedFiltersByKeyRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toSavedFilters(obj.setting))
  return newObj
}

export const toGetTenantsRequest = (obj) => {
  const newObj = protoFromObject(GetTenantsRequest, obj)
  return newObj
}

export const toGetTenantInfoRequest = (obj) => {
  const newObj = protoFromObject(GetTenantInfoRequest, obj)
  return newObj
}

export const toPersonFilter = (obj) => {
  const newObj = protoFromObject(PersonFilter, obj)
  return newObj
}

export const toSearchPeopleRequest = (obj) => {
  const newObj = protoFromObject(SearchPeopleRequest, obj)
  has(obj, 'filtersList') && newObj.setFiltersList(obj.filtersList.map((f) => toPersonFilter(f)))
  return newObj
}

export const toGetGroupSubTreeRequest = (obj) => {
  const newObj = protoFromObject(GetGroupSubTreeRequest, obj)
  return newObj
}

export const toIndividualFeaturePermissionCheck = (obj) => {
  const newObj = protoFromObject(IndividualFeaturePermissionCheck, obj)
  return newObj
}

export const toPermissionCheckObject = (obj) => {
  const newObj = protoFromObject(PermissionCheckObject, obj)
  has(obj, 'checksList') && newObj.setChecksList(obj.checksList.map((c) => toIndividualFeaturePermissionCheck(c)))
  return newObj
}

export const toCheckPermissionsRequest = (obj) => {
  const newObj = protoFromObject(CheckPermissionsRequest, obj)
  if (has(obj, 'valsMap')) {
    const valsMap = newObj.getValsMap()
    forEach(obj.valsMap, (v) => {
      if (v.length > 1) {
        valsMap.set(v[0], toPermissionCheckObject(v[1]))
      }
    })
  }
  return newObj
}

export const toGetCRMRolesRequest = (obj) => {
  const newObj = protoFromObject(GetCRMRolesRequest, obj)
  return newObj
}

export const toGetActivityTimelineRequest = (obj) => {
  const newObj = protoFromObject(GetActivityTimelineRequest, obj)
  return newObj
}

export const toGetActivityDetailsRequest = (obj) => {
  const newObj = protoFromObject(GetActivityDetailsRequest, obj)
  has(obj, 'partitionTs') && obj.partitionTs !== undefined && newObj.setPartitionTs(toTimestamp(obj.partitionTs))
  return newObj
}

export const toGetActivityDateCursorRequest = (obj) => {
  const newObj = protoFromObject(GetActivityDateCursorRequest, obj)
  has(obj, 'pivotTs') && newObj.setPivotTs(toTimestamp(obj.pivotTs))
  return newObj
}

export const toGetUserHasActivitySyncedRequest = (obj) => {
  const newObj = protoFromObject(GetUserHasActivitySyncedRequest, obj)
  return newObj
}

export const ActivityDirection = ActivityDirectionEnum
export const ActivityType = ActivityTypeEnum

export const toExportSearchResultsFields = (obj) => {
  const newObj = protoFromObject(ExportSearchResultsFields, obj)
  return newObj
}

export const toExportSearchResultsRequest = (obj) => {
  const newObj = protoFromObject(ExportSearchResultsRequest, obj)
  has(obj, 'query') && newObj.setQuery(toSearchContainer(obj.query))
  has(obj, 'paging') && newObj.setPaging(toSearchPaginationContainer(obj.paging))
  has(obj, 'fields') && newObj.setFieldsList(obj.fields.map((f) => toExportSearchResultsFields(f)))
  return newObj
}

export const toGetCanvasRequest = (obj) => {
  const newObj = protoFromObject(GetCanvasRequest, obj)
  return newObj
}

export const toGetCanvasTileRequest = (obj) => {
  const newObj = protoFromObject(GetCanvasTileRequest, obj)
  return newObj
}

export const toLabelValue = (obj) => {
  const newObj = protoFromObject(LabelValue, obj)
  return newObj
}

export const toFieldOptions = (obj) => {
  const newObj = protoFromObject(FieldOptions, obj)
  has(obj, 'values') && newObj.setValuesList(obj.values.map((v) => toLabelValue(v)))
  return newObj
}

export const toSourceOptions = (obj) => {
  const newObj = protoFromObject(SourceOptions, obj)
  has(obj, 'fields') && newObj.setFieldsList(obj.fields.map((fo) => toFieldOptions(fo)))
  return newObj
}

export const toGetObjectMappingsSourceOptionsRequest = (obj) => {
  const newObj = protoFromObject(GetObjectMappingsSourceOptionsRequest, obj)
  has(obj, 'requestedOptions') && newObj.setRequestedOptionsList(obj.requestedOptions.map((ro) => toSourceOptions(ro)))
  return newObj
}

export const toParameterOptions = (obj) => {
  const newObj = protoFromObject(ParameterOptions, obj)
  has(obj, 'sourcesList') && newObj.setSourcesList(obj.sourcesList.map((s) => toSourceOptions(s)))
  return newObj
}

export const toFilter = (obj) => {
  const newObj = protoFromObject(Filter, obj)
  return newObj
}

export const toFilterWrapper = (obj) => {
  const newObj = protoFromObject(FilterWrapper, obj)
  has(obj, 'filter') && obj.filter !== undefined && newObj.setFilter(toFilter(obj.filter))
  has(obj, 'nestedList') && newObj.setNestedList(obj.nestedList.map((f) => toFilterWrapper(f)))
  return newObj
}

export const toFilterParameter = (obj) => {
  if (obj === undefined) {
    return undefined
  }
  const newObj = protoFromObject(FilterParameter, obj)
  has(obj, 'filters') && newObj.setFilters(toFilterWrapper(obj.filters))
  return newObj
}

export const toTimeWindowRange = (obj) => {
  const newObj = protoFromObject(TimeWindowRange, obj)
  return newObj
}

export const toTimeWindowInterval = (obj) => {
  const newObj = protoFromObject(TimeWindowInterval, obj)
  return newObj
}

export const toTimeWindowParameter = (obj) => {
  const newObj = protoFromObject(TimeWindowParameter, obj)
  newObj.setRange(toTimeWindowRange(obj?.range ?? {}))
  newObj.setInterval(toTimeWindowInterval(obj?.interval ?? {}))
  return newObj
}

export const toOrder = (obj) => {
  const newObj = protoFromObject(Order, obj)
  return newObj
}

export const toOrderParameter = (obj) => {
  const newObj = protoFromObject(OrderParameter, obj)
  has(obj, 'ordersList') && newObj.setOrdersList(obj.ordersList.map((o) => toOrder(o)))
  return newObj
}

export const toGroup = (obj) => {
  const newObj = protoFromObject(Group, obj)
  return newObj
}

export const toGroupParameter = (obj) => {
  const newObj = protoFromObject(GroupParameter, obj)
  has(obj, 'groupsList') && newObj.setGroupsList(obj.groupsList.map((g) => toGroup(g)))
  return newObj
}

export const toValueParameter = (obj) => {
  const newObj = protoFromObject(ValueParameter, obj)
  return newObj
}

export const toFieldDefinition = (obj) => {
  const newObj = protoFromObject(FieldDefinition, obj)
  has(obj, 'nestedList') && newObj.setNestedList(obj.nestedList.map((f) => toFieldDefinition(f)))
  return newObj
}

export const toQueryContract = (obj) => {
  const newObj = protoFromObject(QueryContract, obj)
  has(obj, 'fieldsList') && newObj.setFieldsList(obj.fieldsList.map((f) => toFieldDefinition(f)))
  return newObj
}

export const toQueryDependencies = (obj) => {
  const newObj = protoFromObject(QueryDependencies, obj)
  if (has(obj, 'queryVersionsMap')) {
    const queryVersionsMap = newObj.getQueryVersionsMap()
    forEach(obj.queryVersionsMap, (m) => {
      if (m.length > 1) {
        queryVersionsMap.set(m[0], m[1])
      }
    })
  }
  return newObj
}

export const toQuery = (obj) => {
  const newObj = protoFromObject(RQuery, obj)
  has(obj, 'contract') && newObj.setContract(toQueryContract(obj.contract))
  has(obj, 'availableParameters') && newObj.setAvailableParameters(toQueryParameters(obj.availableParameters))
  has(obj, 'dependencies') && newObj.setDependencies(toQueryDependencies(obj.dependencies))
  if (has(obj, 'metadataMap')) {
    const metadataMap = newObj.getMetadataMap()
    forEach(obj.metadataMap, (m) => {
      if (m.length > 1) {
        metadataMap.set(m[0], m[1])
      }
    })
  }
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toResolvedQueryParameter = (obj) => {
  const newObj = protoFromObject(ResolvedQueryParameter, obj)
  has(obj, 'queriesList') && newObj.setQueriesList(obj.queriesList.map((q) => toQuery(q)))
  return newObj
}

export const toQueryParameter = (obj) => {
  const newObj = protoFromObject(QueryParameter, obj)
  has(obj, 'options') && newObj.setOptions(toParameterOptions(obj.options))
  has(obj, 'filter') && newObj.setFilter(toFilterParameter(obj.filter))
  has(obj, 'timeWindow') && newObj.setTimeWindow(toTimeWindowParameter(obj.timeWindow))
  has(obj, 'order') && newObj.setOrder(toOrderParameter(obj.order))
  has(obj, 'group') && newObj.setGroup(toGroupParameter(obj.group))
  has(obj, 'value') && newObj.setValue(toValueParameter(obj.value))
  has(obj, 'resolvedQuery') && newObj.setResolvedQuery(toResolvedQueryParameter(obj.resolvedQuery))
  return newObj
}

export const toQueryParameters = (obj) => {
  const newObj = protoFromObject(QueryParameters, obj)
  has(obj, 'parametersList') && newObj.setParametersList(obj.parametersList.map((p) => toQueryParameter(p)))
  return newObj
}

export const toGMLInclude = (obj) => {
  const newObj = protoFromObject(GMLInclude, obj)
  has(obj, 'includedGml') && newObj.setIncludedGml(toGML(obj.includedGml))
  return newObj
}

export const toGMLData = (obj) => {
  const newObj = protoFromObject(GMLData, obj)
  has(obj, 'childrenList') && newObj.setChildrenList(obj.childrenList.map((c) => toGMLData(c)))
  return newObj
}

export const toGMLFunction = (obj) => {
  const newObj = protoFromObject(GMLFunction, obj)
  return newObj
}

export const toUIControlAttribute = (obj) => {
  const newObj = protoFromObject(UIControlAttribute, obj)
  return newObj
}

export const toUIControl = (obj) => {
  const newObj = protoFromObject(UIControl, obj)
  has(obj, 'attributesList') && newObj.setAttributesList(obj.attributesList.map((a) => toUIControlAttribute(a)))
  has(obj, 'childrenList') && newObj.setChildrenList(obj.childrenList.map((c) => toUIControl(c)))
  return newObj
}

export const toEmailContentAttributes = (obj) => {
  const newObj = protoFromObject(EmailContentAttributes, obj)
  has(obj, 'subject') && newObj.setSubject(toUIControlAttribute(obj.subject))
  has(obj, 'to') && newObj.setTo(toUIControlAttribute(obj.to))
  has(obj, 'cc') && newObj.setCc(toUIControlAttribute(obj.cc))
  has(obj, 'bcc') && newObj.setBcc(toUIControlAttribute(obj.bcc))
  has(obj, 'from') && newObj.setFrom(toUIControlAttribute(obj.from))
  has(obj, 'template') && newObj.setTemplate(toUIControlAttribute(obj.template))
  return newObj
}

export const toGMLContent = (obj) => {
  const newObj = protoFromObject(GMLContent, obj)
  has(obj, 'childrenList') && newObj.setChildrenList(obj.childrenList.map((c) => toUIControl(c)))
  has(obj, 'emailAttributes') && newObj.setEmailAttributes(toEmailContentAttributes(obj.emailAttributes))
  return newObj
}

export const toGML = (obj) => {
  const newObj = protoFromObject(GML, obj)
  has(obj, 'includedList') && newObj.setIncludedList(obj.includedList.map((i) => toGMLInclude(i)))
  has(obj, 'dataList') && newObj.setDataList(obj.dataList.map((d) => toGMLData(d)))
  has(obj, 'functionsList') && newObj.setFunctionsList(obj.functionsList.map((f) => toGMLFunction(f)))
  has(obj, 'contentsList') && newObj.setContentsList(obj.contentsList.map((c) => toGMLContent(c)))
  return newObj
}

export const toRealtimeQueryRequestContainer = (obj) => {
  const newObj = protoFromObject(RealtimeQueryRequestContainer, obj)
  has(obj, 'params') && newObj.setParams(toQueryParameters(obj.params))
  if (has(obj, 'nlgMap')) {
    const nlgMap = newObj.getNlgMap()
    forEach(obj.nlgMap, (m) => {
      if (m.length > 1) {
        nlgMap.set(m[0], m[1])
      }
    })
  }
  has(obj, 'gml') && newObj.setGml(toGML(obj.gml))
  return newObj
}

export const toCanvasTenantStandardParams = (obj) => {
  const newObj = protoFromObject(CanvasTenantStandardParams, obj)
  return newObj
}

export const toRunRealtimeQueriesRequest = (obj) => {
  const newObj = protoFromObject(RunRealtimeQueriesRequest, obj)
  if (has(obj, 'realtimeQueriesMap')) {
    const realtimeQueriesMap = newObj.getRealtimeQueriesMap()
    forEach(obj.realtimeQueriesMap, (m) => {
      if (m.length > 1) {
        realtimeQueriesMap.set(m[0], toRealtimeQueryRequestContainer(m[1]))
      }
    })
  }
  has(obj, 'tenantStandardParams') && newObj.setTenantStandardParams(toCanvasTenantStandardParams(obj.tenantStandardParams))
  return newObj
}

export const toDataRequest = (obj) => {
  const newObj = protoFromObject(DataRequest, obj)
  return newObj
}

export const toUserQueryParameters = (obj) => {
  const newObj = protoFromObject(UserQueryParameters, obj)
  has(obj, 'parameters') && newObj.setParameters(toQueryParameters(obj.parameters))
  return newObj
}

export const toUpsertCanvasTileUserQueryParametersRequest = (obj) => {
  const newObj = protoFromObject(UpsertCanvasTileUserQueryParametersRequest, obj)
  has(obj, 'parameters') && newObj.setParameters(toUserQueryParameters(obj.parameters))
  return newObj
}

export const toDeleteCanvasTileUserQueryParametersRequest = (obj) => {
  const newObj = protoFromObject(DeleteCanvasTileUserQueryParametersRequest, obj)
  return newObj
}

export const toActor = (obj) => {
  const newObj = protoFromObject(Actor, obj)
  has(obj, 'impersonator') && newObj.setImpersonator(toActor(obj.impersonator))
  return newObj
}

export const toGroupedObjectFilter = (obj) => {
  const newObj = protoFromObject(GroupedObjectFilter, obj)
  return newObj
}

export const toTenantSettingParams = (obj) => {
  const newObj = protoFromObject(TenantSettingParams, obj)
  return newObj
}

export const toRunQueryObjectDataRequest = (obj) => {
  const newObj = protoFromObject(RunQueryObjectDataRequest, obj)
  has(obj, 'paramsList') && newObj.setParamsList(obj.paramsList.map((p) => toQueryParameter(p)))
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'groupFiltersList') && newObj.setGroupFiltersList(obj.groupFiltersList.map((g) => toGroupedObjectFilter(g)))
  has(obj, 'tenantSettingParams') && newObj.setTenantSettingParams(toTenantSettingParams(obj.tenantSettingParams))
  return newObj
}

export const toCanvasTileUserDefinedParametersLayout = (obj) => {
  const newObj = protoFromObject(CanvasTileUserDefinedParametersLayout, obj)
  return newObj
}

export const toRealtimeViewTile = (obj) => {
  const newObj = protoFromObject(RealtimeViewTile, obj)
  // TODO: convert "view" prop (hasn't been needed yet)
  has(obj, 'userQueryParametersList') && newObj.setUserQueryParametersList(obj.userQueryParametersList.map((u) => toUserQueryParameters(u)))
  return newObj
}

export const toTile = (obj) => {
  const newObj = protoFromObject(Tile, obj)
  has(obj, 'realtimeView') && newObj.setRealtimeView(toRealtimeViewTile(obj.realtimeView))
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toCanvasTile = (obj) => {
  const newObj = protoFromObject(CanvasTile, obj)
  has(obj, 'tile') && newObj.setTile(toTile(obj.tile))
  has(obj, 'layout') && newObj.setLayout(toCanvasTileUserDefinedParametersLayout(obj.layout))
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  has(obj, 'orgDefaultLayout') && newObj.setOrgDefaultLayout(toCanvasTileUserDefinedParametersLayout(obj.orgDefaultLayout))
  return newObj
}

export const toUpsertCanvasTileRequest = (obj) => {
  const newObj = protoFromObject(UpsertCanvasTileRequest, obj)
  has(obj, 'canvasTile') && newObj.setCanvasTile(toCanvasTile(obj.canvasTile))
  return newObj
}

export const toAdjustOrgCanvasTileLayoutRequest = (obj) => {
  const newObj = protoFromObject(AdjustOrgCanvasTileLayoutRequest, obj)
  return newObj
}

export const toForecastPeriod = (obj) => {
  const newObj = protoFromObject(ForecastPeriod, obj)
  return newObj
}

export const toGetForecastRequest = (obj) => {
  const newObj = protoFromObject(GetForecastRequest, obj)
  has(obj, 'teamSettings') && newObj.setTeamSettings(toForecastTeamSettings(obj.teamSettings))
  has(obj, 'config') && newObj.setConfig(toForecastConfig(obj.config))
  has(obj, 'period') && newObj.setPeriod(toForecastPeriod(obj.period))
  return newObj
}

export const toForecastConfigCustomQuerySettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigCustomQuerySettings, obj)
  has(newObj, 'queryParameters') && newObj.setQueryParameters(toQueryParameters(newObj.queryParameters))
  return newObj
}

export const toForecastConfigObjectSettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigObjectSettings, obj)
  return newObj
}

export const toForecastConfigValueSettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigValueSettings, obj)
  has(obj, 'filter') && newObj.setFilter(toFilterParameter(obj.filter))
  has(obj, 'objectValueSettings') && newObj.setObjectValueSettings(toForecastConfigObjectSettings(obj.objectValueSettings))
  has(obj, 'customQuerySettings') && newObj.setCustomQuerySettings(toForecastConfigCustomQuerySettings(obj.customQuerySettings))
  return newObj
}

export const toForecastConfigDisplayFormula = (obj) => {
  const newObj = protoFromObject(ForecastConfigDisplayFormula, obj)
  if (has(obj, 'variableMappingMap')) {
    const varsMap = newObj.getVariableMappingMap()
    forEach(obj.variableMappingMap, (v) => {
      if (v.length > 1) {
        varsMap.set(v[0], v[1])
      }
    })
  }
  return newObj
}

export const toForecastConfigGoalSettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigGoalSettings, obj)
  return newObj
}

export const toForecastConfigColumn = (obj) => {
  const newObj = protoFromObject(ForecastConfigColumn, obj)
  has(obj, 'forecastConfigValueSettings') && newObj.setForecastConfigValueSettings(toForecastConfigValueSettings(obj.forecastConfigValueSettings))
  has(obj, 'displayFormula') && newObj.setDisplayFormula(toForecastConfigDisplayFormula(obj.displayFormula))
  has(obj, 'goalSettings') && newObj.setGoalSettings(toForecastConfigGoalSettings(obj.goalSettings))
  return newObj
}

export const toForecastConfigPivotGroup = (obj) => {
  const newObj = protoFromObject(ForecastConfigPivotGroup, obj)
  return newObj
}

export const toForecastConfig = (obj) => {
  const newObj = protoFromObject(ForecastConfig, obj)
  has(obj, 'columnsList') && newObj.setColumnsList(obj.columnsList.map((p) => toForecastConfigColumn(p)))
  has(obj, 'pivotGroupsList') && newObj.setPivotGroupsList(obj.pivotGroupsList.map(toForecastConfigPivotGroup))
  has(obj, 'objectSettings') && newObj.setObjectSettings(toForecastConfigObjectSettings(obj.objectSettings))
  has(obj, 'goalSettings') && newObj.setGoalSettings(toForecastConfigGoalSettings(obj.goalSettings))
  return newObj
}

export const toForecastConfigSearchContainer = (obj) => {
  const newObj = protoFromObject(ForecastConfigSearchContainer, obj)
  return newObj
}

export const toListForecastConfigsByGroupIdRequest = (obj) => {
  const newObj = protoFromObject(ListForecastConfigsByGroupIdRequest, obj)
  has(obj, 'filters') && newObj.setFilters(toForecastConfigSearchContainer(obj.filters))
  return newObj
}

export const toGetForecastConfigRequest = (obj) => {
  const newObj = protoFromObject(GetForecastConfigRequest, obj)
  return newObj
}

export const toGetOutreachSettingRequest = (obj) => {
  const newObj = protoFromObject(GetOutreachSettingRequest, obj)
  return newObj
}

export const toSearchOpportunitiesRequest = (obj) => {
  const newObj = protoFromObject(SearchOpportunitiesRequest, obj)
  return newObj
}

export const toGetObjectReferenceFieldOptionsRequest = (obj) => {
  const newObj = protoFromObject(GetObjectReferenceFieldOptionsRequest, obj)
  return newObj
}

export const toResolveObjectReferenceFieldOptionsContainer = (obj) => {
  const newObj = protoFromObject(ResolveObjectReferenceFieldOptionsContainer, obj)
  return newObj
}

export const toResolveObjectReferenceFieldOptionsRequest = (obj) => {
  const newObj = protoFromObject(ResolveObjectReferenceFieldOptionsRequest, obj)
  has(obj, 'itemsList') && newObj.setItemsList(obj.itemsList.map(toResolveObjectReferenceFieldOptionsContainer))
  return newObj
}

export const toExportSubmissionHistoryRequest = (obj) => {
  const newObj = protoFromObject(ExportSubmissionHistoryRequest, obj)
  has(obj, 'searchRequest') && newObj.setSearchRequest(toSearchForecastsRequest(obj.searchRequest))
  has(obj, 'forecastConfig') && newObj.setForecastConfig(toForecastConfig(obj.forecastConfig))
  return newObj
}
