import { useMemo } from 'react'
import { useTenantInfo } from '../context/tenantInfo'

const useCanWrite = () => {
  const { tenantInfo } = useTenantInfo()

  const canWrite = useMemo(() => {
    return tenantInfo?.canWriteBack ?? false
  }, [tenantInfo])

  return {
    canWrite
  }
}

export default useCanWrite
