import { createReducer } from '@reduxjs/toolkit'
import {
  getSignalsByUserSuccess,
  getSignalsByOpportunitySuccess,
  getSignalByIdSuccess,
  getKeyMetricsByTeamSuccess,
  getKeyMetricsByUserSuccess,
  getHeaderMetricsByTeamSuccess,
  getHeaderMetricsByUserSuccess,
  getDashboardMetricsSuccess,
} from '../actions/signalService'
import { clearSignals, clearKeyMetrics, clearHeaderMetrics, clearDashboardMetrics } from '../actions'
import get from 'lodash/get'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import { flatten, uniqBy } from 'lodash'

const enrichSignal = (signal) => {
  const typeMap = get(signal, 'relations.byTypeMap', [])
  forEach(typeMap, (arr) => {
    const t = find(get(arr, '[1].relationsList', []), (r) => r.type === 'TARGET')
    if (t) {
      signal.targetEntity = t.entity
    }
  })
  return signal
}

const enrichSignals = (currentState, signalsList) => {
  forEach(signalsList, (signal) => enrichSignal(signal))
  return signalsList
}

const keyMetricsByTeamInitialState = []
export const keyMetricsByTeam = createReducer(keyMetricsByTeamInitialState, {
  [clearKeyMetrics.type]: (state, action) => keyMetricsByTeamInitialState,
  [getKeyMetricsByTeamSuccess.type]: (state, action) => {
    const { signalsList, userId, pageContext } = action.payload
    if (userId === pageContext.id || !pageContext.id) {
      state.push(...enrichSignals(state, signalsList))
    }
    return state
  },
})

const signalsByUserInitialState = []
export const signalsByUser = createReducer(signalsByUserInitialState, {
  [clearSignals.type]: (state, action) => signalsByUserInitialState,
  [getSignalsByUserSuccess.type]: (state, action) => {
    state.push(...enrichSignals(state, action.payload.signalsList))
    return state
  },
})

const keyMetricsByUserInitialState = []
export const keyMetricsByUser = createReducer(keyMetricsByUserInitialState, {
  [clearKeyMetrics.type]: (state, action) => keyMetricsByUserInitialState,
  [getKeyMetricsByUserSuccess.type]: (state, action) => {
    const { signalsList, userId, pageContext } = action.payload
    if (userId === pageContext.id || !pageContext.id) {
      state.push(...enrichSignals(state, signalsList))
    }
    return state
  },
})

const keyMetricsCountersInitialState = {}
export const keyMetricsCounters = createReducer(keyMetricsCountersInitialState, {
  [clearKeyMetrics.type]: (state, action) => keyMetricsCountersInitialState,
  [getKeyMetricsByUserSuccess.type]: (state, action) => {
    if (!state[action.payload.userId]) state[action.payload.userId] = {}
    state[action.payload.userId].totalCount = action.payload.totalCount
    return state
  },
  [getKeyMetricsByTeamSuccess.type]: (state, action) => {
    if (!state[action.payload.userId]) state[action.payload.userId] = {}
    state[action.payload.userId].totalCount = action.payload.totalCount
    return state
  },
})

const signalsByOpportunityInitialState = []
export const signalsByOpportunity = createReducer(signalsByOpportunityInitialState, {
  [clearSignals.type]: (state, action) => signalsByOpportunityInitialState,
  [getSignalsByOpportunitySuccess.type]: (state, action) => {
    if (action.payload.skip === 0) {
      state = enrichSignals(state, action.payload.signalsList)
    } else {
      state.push(...enrichSignals(state, action.payload.signalsList))
    }
    return state
  },
})

const signalsByGroupInitialState = {}
export const signalsByGroup = createReducer(signalsByGroupInitialState, {
  [clearSignals.type]: (state, action) => signalsByGroupInitialState,
  [getSignalByIdSuccess.type]: (state, action) => {
    const { groupId, signal } = action.payload
    if (has(signal, 'id')) {
      const key = `${groupId}|${get(signal, 'id')}`
      state[key] = enrichSignal(signal)
    }
    return state
  },
})

const headerMetricsInitialState = []
export const headerMetrics = createReducer(headerMetricsInitialState, {
  [clearHeaderMetrics.type]: (state, action) => headerMetricsInitialState,
  [getHeaderMetricsByTeamSuccess.type]: (state, action) => {
    const { signalsList, userId, pageContext } = action.payload
    if (userId === pageContext.id || !pageContext.id) {
      state.push(...enrichSignals(state, signalsList))
    }
    return state
  },
  [getHeaderMetricsByUserSuccess.type]: (state, action) => {
    const { signalsList, userId, pageContext } = action.payload
    if (userId === pageContext.id || !pageContext.id) {
      state.push(...enrichSignals(state, signalsList))
    }
    return state
  },
})

const dashboardMetricsInitialState = []
export const dashboardMetrics = createReducer(dashboardMetricsInitialState, {
  [clearDashboardMetrics.type]: (state, action) => dashboardMetricsInitialState,
  [getDashboardMetricsSuccess.type]: (state, action) => {
    const { signalsList } = action.payload
    state.push(...enrichSignals(state, signalsList))
    return state
  },
})

const dashboardMetricsSiblingsInitialState = []
export const dashboardMetricsSiblings = createReducer(dashboardMetricsSiblingsInitialState, {
  [getDashboardMetricsSuccess.type]: (state, action) => {
    const { signalsList, cacheSiblings } = action.payload
    if (cacheSiblings) {
      const siblings = []
      siblings.push(...flatten(signalsList.map((s) => s.siblingsList)))
      return uniqBy(siblings, (s) => s.id)
    }
    return state
  },
})
