import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Popover from '../popover'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { usePopover } from '../../hooks/usePopover'
import { filter, remove, some } from 'lodash'
import { useFieldDefinitionOptions } from './hooks'
import classNames from 'classnames'
import FieldDebug from './fieldDebug'
import pencilSm from '../../assets/pencil-sm.png'
import Icon, { iconType } from '../icon'
import { getDelimiter } from './helpers'

const MultiSelectFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
  } = props

  const { label, canEdit, format } = fieldDefinition
  const options = useFieldDefinitionOptions(fieldDefinition)

  const [selectedOptions, setSelectedOptions] = useState([])

  const delimiter = useMemo(() => {
    return getDelimiter(format)
  }, [format])

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure, selectedOptions])

  const afterClose = useCallback(() => {
    onFieldChangedInternal(selectedOptions.join(delimiter))
  }, [onFieldChangedInternal, selectedOptions])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover(afterClose)

  useEffect(() => {
    if (field.value) {
      const opts = field.value.split(delimiter)
      setSelectedOptions(filter(options, (o) => opts.includes(o.value)).map((x) => x.value))
    }
  }, [field, options])

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const onOptionClick = useCallback((e, option) => {
    e.preventDefault()

    if (option.checked) {
      setSelectedOptions([...selectedOptions, option.value])
    } else {
      const newOptions = selectedOptions.slice()
      remove(newOptions, (o) => o === option.value)
      setSelectedOptions(newOptions)
    }
  }, [selectedOptions])

  const multiSelectOptions = useMemo(() => {
    return (
      <div className="flex flex-col px-4">
        {options.map((option, index) => {
          const checked = some(selectedOptions, (o) => o === option.value)
          return (
            <FormControlLabel
              key={`multiSelectOptions-${option.value}-${index}-${checked}`}
              disabled={readonly}
              control={(
                <Checkbox
                  checked={checked}
                  onChange={(e) => onOptionClick(e, { ...option, checked: e.target.checked })}
                  style={{
                    transform: 'scale(0.8)',
                    color: '#1d4dcf'
                  }} />
              )}
              label={option.label} />
          )
        })}
      </div>
    )
  }, [selectedOptions])

  const renderedValue = useMemo(() => {
    const opts = field && field.value ? field.value.split(delimiter) : []
    return opts.length === 0 ? '-' : (
      <div className="flex flex-wrap">
        {opts.map((o, index) => (
          <div
            key={`option-${index}`}
            className="bg-color-09242f text-size-12px text-color-ffffff rounded-full px-2 py-1 mr-1 my-1 last:mr-0">
            {o}
          </div>
        ))}
      </div>
    )
  }, [field, delimiter])

  return (
    <>
      {renderedValue === '-' && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('MultiSelectFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
              {label}
              <FieldDebug
                debug={debug}
                data={{ opportunity, fieldDefinition, field }} />
            </div>
            <div
              style={{ transform: 'translateY(-1px)' }}
              onClick={onClick}
              className={classNames('flex items-center group cursor-pointer', { 'pointer-events-none': readonly })}>
              <div className={classNames('text-size-16px font-weight-300',
                { 'text-color-09242f': !readonly },
                { 'text-color-09242f': readonly })}>
                {renderedValue}
              </div>
              <Icon type={iconType.IMAGE} src={pencilSm} className="invisible group-hover:visible" style={{ transform: 'translate(4px, -2px)' }} />
            </div>
            <Popover
              style={{ zIndex: 999999999 }}
              open={open}
              anchorEl={anchorEl}
              onClose={onClose}
              placement="bottom-start">
              {multiSelectOptions}
            </Popover>
          </div>
        )}
    </>
  )
}

export default MultiSelectFieldDetailItem
