import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useCanvasItem } from './canvasItem'
import { useGrpcCallback } from '../grpc'
import { toRunRealtimeQueriesRequest } from '../grpc/converters'
import { useAuth } from './auth'
import { useCanvas } from './canvas'
import { find, isEqual } from 'lodash'
import { updateDynamicDateRanges } from './realtimeViewHelpers'

const RealtimeDraftViewContext = React.createContext()

export function RealtimeDraftViewProvider({ userId, headers, children }) {
  const { actingTenantId, actingUserId } = useAuth()
  const { tenantStandardParams } = useCanvas()
  const { tileKey, rawGML, nlgMap, userQueryParametersList } = useCanvasItem()

  const [key, setKey] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [response, setResponse] = useState(undefined)
  const [isFetching, setIsFetching] = useState(false)
  const [parsedGML, setParsedGML] = useState(undefined)
  const [selectedFilterId, setSelectedFilterId] = useState(undefined)
  const [selectedFilter, setSelectedFilter] = useState(undefined)
  const [lastRanParametersList, setLastRanParametersList] = useState(undefined)
  const [overrideParametersList, setOverrideParametersList] = useState(undefined)

  const id = useMemo(() => {
    return userId || actingUserId
  }, [actingUserId, userId])

  const runRealtimeQueries = useGrpcCallback({
    ...headers && { headers },
    onSuccess: (obj) => {
      let err
      let gml
      const { responsesMap } = obj
      if (responsesMap[0] && responsesMap[0][1] && responsesMap[0][1]) {
        const responseContainer = responsesMap[0][1]
        const { status, code, errorMessage, queryKey, gmlResponse } = responseContainer
        if (status === 1) {
          err = {
            status,
            code,
            errorMessage,
            queryKey
          }
        } else if (gmlResponse) {
          err = undefined
          gml = gmlResponse
        } else {
          err = {
            status: 1,
            code,
            errorMessage: 'gmlResponse is empty',
            queryKey
          }
        }
      }
      if (!err && !gml) {
        err = {
          status: 1,
          errorMessage: 'responseContainer is empty',
          queryKey: tileKey
        }
      }
      setError(err)
      setResponse(obj)
      setParsedGML(gml)
      setIsFetching(false)
    },
    onError: (err) => {
      setError({ error: err })
      setResponse(undefined)
      setIsFetching(false)
    },
    onFetch: () => {
      setError(undefined)
      setResponse(undefined)
      setIsFetching(true)
    },
    grpcMethod: 'runRealtimeQueries',
    grpcMethodName: 'runRealtimeDraftQueries',
    debug: false
  }, [headers])

  useEffect(() => {
    if (selectedFilterId) {
      const filter = find(userQueryParametersList, (uqp) => uqp.id === selectedFilterId)
      if (filter) {
        setSelectedFilter(filter)
        setSelectedFilterId(undefined)
      }
    }
  }, [userQueryParametersList, selectedFilterId])

  useEffect(() => {
    if (key && selectedFilter) {
      const parametersList = overrideParametersList || (selectedFilter?.parameters?.parametersList ?? [])
      if (!isEqual(lastRanParametersList, parametersList)) {
        const { fiscalYearStartMonth } = tenantStandardParams
        updateDynamicDateRanges({ parametersList, fiscalYearStartMonth })
        const request = toRunRealtimeQueriesRequest({
          tenantId: actingTenantId,
          personId: id,
          realtimeQueriesMap: [
            [
              tileKey,
              {
                queryKey: tileKey,
                params: {
                  parametersList
                },
                nlgMap,
                gml: rawGML
              }
            ]
          ],
          ...tenantStandardParams && { tenantStandardParams }
        })
        setLastRanParametersList(parametersList)
        runRealtimeQueries(request)
      }
    }
  }, [key, runRealtimeQueries, id, selectedFilter, lastRanParametersList, overrideParametersList])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const clearError = useCallback(() => {
    setError(undefined)
  }, [])

  const reset = useCallback(() => {
    setError(undefined)
    setResponse(undefined)
    setIsFetching(false)
    setParsedGML(undefined)
    setSelectedFilter(undefined)
    setLastRanParametersList(undefined)
    setOverrideParametersList(undefined)
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      error,
      setError,
      clearError,
      response,
      parsedGML,
      setParsedGML,
      selectedFilterId,
      setSelectedFilterId,
      selectedFilter,
      setSelectedFilter,
      invalidate,
      reset,
      setOverrideParametersList
    }
  }, [isFetching, error, response, parsedGML, selectedFilter])

  return <RealtimeDraftViewContext.Provider value={contextValue}>{children}</RealtimeDraftViewContext.Provider>
}

export function useRealtimeDraftView() {
  const context = React.useContext(RealtimeDraftViewContext)
  if (context === undefined) {
    throw new Error('useRealtimeDraftView must be used within a RealtimeDraftViewProvider')
  }
  return context
}
