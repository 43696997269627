import React, { useCallback, useMemo } from 'react'
import { useAuth } from './auth'
import { useTenantInfo } from './tenantInfo'

const ViewDealInCrmContext = React.createContext()

const grpcUrl = process.env.GRPC_URL

export function ViewDealInCrmProvider({ opportunityId, analyticsTrackingArgs = [], children }) {
  const { actingTenantId } = useAuth()
  const { crmName } = useTenantInfo()

  const text = useMemo(() => {
    return `View in ${crmName}`
  }, [crmName])

  const onClick = useCallback(() => {
    try {
      const a = document.createElement('a')
      a.setAttribute('href', grpcUrl)
      const { protocol, hostname } = a
      const origin = `${protocol}//${hostname}`
      const url = `${origin}/api/tenant/v2/proxy/crm/${actingTenantId}/opportunity/${opportunityId}`
      window.open(url)
    } catch (err) {
      console.log(err)
    }
    if (analyticsTrackingArgs.length > 0) {
      window.analytics.track(...analyticsTrackingArgs)
    }
  }, [actingTenantId, opportunityId])

  const contextValue = useMemo(() => {
    return {
      crmName,
      text,
      onClick
    }
  }, [crmName, text, onClick])

  return <ViewDealInCrmContext.Provider value={contextValue}>{children}</ViewDealInCrmContext.Provider>
}

export function useViewDealInCrm() {
  const context = React.useContext(ViewDealInCrmContext)
  if (context === undefined) {
    throw new Error('useViewDealInCrm must be used within a ViewDealInCrmProvider')
  }
  return context
}
