import React, { useMemo } from 'react'
import { CanvasProvider } from '../../context/canvas'
import { canvasKey } from './constants'
import { useAuth } from '../../context/auth'
import PipelineCanvas from './pipelineCanvas'

const RepPipelineCanvas = (props) => {
  const { userId } = props

  const { actingTenantId, actingUserId } = useAuth()

  const actor = useMemo(() => {
    return {
      tenantId: actingTenantId,
      userId
    }
  }, [actingTenantId, userId])

  const headers = useMemo(() => {
    return { ...userId === actingUserId && { forceuser: true } }
  }, [userId, actingUserId])

  return (
    <CanvasProvider
      key={`CanvasProvider-${canvasKey.REP_PIPELINE}-${userId}`}
      canvasKey={canvasKey.REP_PIPELINE}
      userId={userId}>
      <PipelineCanvas
        key={`PipelineCanvas-${userId}`}
        userId={userId}
        actor={actor}
        headers={headers} />
    </CanvasProvider>
  )
}

export default RepPipelineCanvas
