import PropTypes from 'prop-types/prop-types'
import { paddingPropTypes, strokePropTypes } from '../propTypes/common'

export const DonutChartConfig = {
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  segment: {
    style: {
      strokeWidth: 28,
      strokeLinecap: 'butt',
      fill: 'none',
    },
  },
}

export const donutChartConfigPropTypes = {
  padding: PropTypes.shape(paddingPropTypes),
  segment: PropTypes.shape({
    style: PropTypes.shape(strokePropTypes),
  }),
}
