import React, { useCallback } from 'react'
import GroupMenu from '../sideMenu/groupMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from './header'
import Popover from '../popover'
import { usePopover } from '../../hooks/usePopover'

const GroupHeaderControl = (props) => {
  const {
    title,
    header,
    showReps,
    teamsSelectable
  } = props

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    window.analytics.track('headerMenu.teamMenuClicked')
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    onClose()
  }, [onClose])

  return (
    <>
      <Header
        onHeaderTextClick={onClick}
        text={(
          <>
            {title}
            {title && <FontAwesomeIcon icon="chevron-down" size="sm" className="ml-2" />}
          </>
        )}
        removeChildrenPadding={true}
      >
        {header}
      </Header>
      <Popover
        style={{ marginTop: 12, zIndex: 999999999 }}
        open={open}
        anchorEl={anchorEl}
        elevation={2}
        onClose={handleClose}
        placement="bottom-start">
        <div>
          <GroupMenu
            close={handleClose}
            teamsSelectable={teamsSelectable}
            showReps={showReps} />
        </div>
      </Popover>
    </>
  )
}

export default GroupHeaderControl
