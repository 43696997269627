import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import classNames from 'classnames'
import DealHealthDetails from '../pipeline/dealHealthDetails'
import { apiRequestedSelector } from '../../selectors'
import { dealHealthDataByIdKey } from '../../actions/searchService'

const DealHealthDetailItem = (props) => {
  const { currentOppDealHealth } = useSelector((state) => state.dealHealthById)
  const isFetchingDealHealth = useSelector((state) => apiRequestedSelector(state, dealHealthDataByIdKey))

  const hasDealHealth = useMemo(() => {
    return !!currentOppDealHealth?.id
      && !!currentOppDealHealth?.healthScore
      && currentOppDealHealth?.healthScore !== ''
      && currentOppDealHealth?.healthScore > 0
  }, [currentOppDealHealth])
  return (
    <>

      {isFetchingDealHealth
        && (
          <>
            <div className="mt-4 mb-3">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
            </div>
            <div className="mt-4 mb-3">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
            </div>
          </>
        )}

      {hasDealHealth && (
        <>
          <div className="mt-3 text-color-151d49 text-size-18px font-weight-700">Health Insights</div>
          <div className="border-t border-color-d6d9e6 my-2" />
          <div className="flex flex-row">
            <div
              style={{ width: 32, height: 32 }}
              className={classNames(
                'inline-block text-center text-size-14px font-normal rounded-full leading-loose flex-shrink-0 mb-2',
                { invisible: currentOppDealHealth === null },
                { 'bg-color-fdebec text-color-5e161C': currentOppDealHealth?.healthCategory === 'RED' },
                { 'bg-color-eaf7f2 text-color-154732': currentOppDealHealth?.healthCategory === 'GREEN' },
                { 'bg-color-fdf3e5 text-color-603900': currentOppDealHealth?.healthCategory === 'YELLOW' }
              )}>
              <div className="mt-1 font-weight-700 font-size-14px" style={{ transform: 'translateY(-2px)' }}>{currentOppDealHealth?.healthScore}</div>
            </div>
            <div className="text-color-151d49 text-size-16px font-weight-600 ml-2" style={{ marginTop: '5px' }}>
              {currentOppDealHealth?.healthCategory === 'RED' && (
                <div>At Risk</div>
              )}
              {currentOppDealHealth?.healthCategory === 'GREEN' && (
                <div>On Track</div>
              )}
              {currentOppDealHealth?.healthCategory === 'YELLOW' && (
                <div>Needs Review</div>
              )}
            </div>
          </div>
          <DealHealthDetails
            currentOppDealHealth={currentOppDealHealth} />
        </>
      )}

    </>
  )
}

export default DealHealthDetailItem
