import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox } from '@material-ui/core'
import FieldDebug from './fieldDebug'

const BoolFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
    readonlyOverride = false,
    onFieldChanged,
  } = props

  const { label, canEdit } = fieldDefinition

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, label, onFieldChanged])

  const [isChecked, setIsChecked] = useState(field.value)

  useEffect(() => {
    setIsChecked(field.value)
  }, [field])

  const onChange = useCallback((e) => {
    const checked = e.target.checked
    setIsChecked(checked)
    onFieldChangedInternal(checked)
  }, [onFieldChangedInternal])

  return (
    <div className="BoolFieldRowItem">
      <div style={{ transform: 'translate(-14px, 0px)' }}>
        <Checkbox checked={isChecked} onChange={onChange} color="primary" disabled={readonly} />
      </div>
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </div>
  )
}

export default BoolFieldRowItem
