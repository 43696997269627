import { createReducer } from '@reduxjs/toolkit'
import { clearNotes, updateActiveNote } from '../actions'
import { getNotesByAttachmentSuccess, saveNoteSuccess } from '../actions/noteService'
import moment from 'moment'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import has from 'lodash/has'

const initialState = []

function enrichNote(note) {
  if (has(note, 'updatedAtLabel') || !has(note, 'raw')) {
    return note
  }

  const updatedAt = moment(get(note, 'updatedAt.seconds', 0) * 1000)
  const createdAt = moment(get(note, 'createdAt.seconds', 0) * 1000)

  const parsedNote = JSON.parse(note.raw)

  const n = {
    ...note,
    raw: JSON.stringify(parsedNote), // need to do this so we can compare the stringified strings later
    rawJson: parsedNote,
    updatedAtLabel: `Updated ${updatedAt.fromNow()}`,
    createdAtLabel: `Created ${createdAt.fromNow()}`,
  }

  // if more than a week, show the date created instead of relative time
  if (createdAt.isBefore(moment().subtract(7, 'days').startOf('day'))) {
    n.createdAtLabel = `Created ${createdAt.format('l')}`
  }

  n.title = get(find(n.rawJson, (el) => el.type === 'title'), 'children', []).map((t) => get(t, 'text', get(t, 'mentionText', ''))).join(' ') || 'Untitled'
  if (n.rawJson.length > 1) {
    const node = n.rawJson[1]
    if (node.type === 'ordered-list' || node.type === 'unordered-list') {
      n.preview = get(node, 'children[0].children', []).map((t) => get(t, 'text', get(t, 'mentionText', ''))).join(' ')
    } else {
      n.preview = get(node, 'children', []).map((t) => get(t, 'text', get(t, 'mentionText', ''))).join(' ')
    }
  }
  return n
}

export const notes = createReducer(initialState, {
  [clearNotes.type]: (state, action) => initialState,
  [getNotesByAttachmentSuccess.type]: (state, action) => {
    const { notesList = [] } = action.payload
    return notesList.map((n) => enrichNote(n))
  },
  [saveNoteSuccess.type]: (state, action) => {
    const { note } = action.payload
    const index = findIndex(state, (n) => n.id === note.id)
    if (index !== -1) {
      state[index] = enrichNote(note)
    }
    return state
  },
})

const activeNoteInitialState = {}

export const activeNote = createReducer(activeNoteInitialState, {
  [getNotesByAttachmentSuccess.type]: (state, action) => {
    const { notesList = [] } = action.payload
    if (notesList.length > 0) {
      return enrichNote(notesList[0])
    } else {
      return activeNoteInitialState
    }
  },
  [saveNoteSuccess.type]: (state, action) => {
    const { note, activeNote } = action.payload
    return enrichNote({
      ...activeNote,
      raw: note.raw,
      updatedAt: note.updatedAt,
    })
  },
  [updateActiveNote.type]: (state, action) => {
    return enrichNote(action.payload)
  }
})
