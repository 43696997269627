import React, { useCallback, useMemo, useState } from 'react'
import ContentModal from '../controls/contentModal'
import { useModal } from '../hooks/useModal'

const GMLContentModalContext = React.createContext()

export function GMLContentModalProvider({ children }) {
  const modal = useModal()
  const { setOpen } = modal

  const [tree, setTree] = useState(undefined)
  const [contentName, setContentName] = useState(undefined)
  const [size, setSize] = useState(undefined)
  const [title, setTitle] = useState(undefined)

  const handleClose = useCallback(() => {
    setOpen(false)
    setTree(undefined)
    setContentName(undefined)
    setSize(undefined)
    setTitle(undefined)
  }, [setOpen])

  const contextValue = useMemo(() => {
    return {
      ...modal,
      handleClose,
      setTree,
      setContentName,
      setSize,
      setTitle
    }
  }, [modal, handleClose])

  return (
    <GMLContentModalContext.Provider value={contextValue}>
      {children}
      {tree && (contentName || size) && (
        <ContentModal
          modal={{
            ...modal,
            handleClose
          }}
          tree={tree}
          contentName={contentName}
          size={size}
          title={title} />
      )}
    </GMLContentModalContext.Provider>
  )
}

export function useGMLContentModal() {
  const context = React.useContext(GMLContentModalContext)
  if (context === undefined) {
    throw new Error('useGMLContentModal must be used within a GMLContentModalProvider')
  }
  return context
}
