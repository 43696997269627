import { find, forEach, last } from 'lodash'

export function getFilterLabels({ filterGroups, filters }) {
  const labels = []
  forEach(filters, (f) => {
    forEach(f.comparisonList[0].valuesList, (value) => {
      const filterGroup = find(filterGroups, (fg) => resolveQueryParamFieldName(fg.field) === resolveQueryParamFieldName(f.fieldName))
      const option = find(filterGroup?.optionItemsList || [], (o) => o.key === value)
      if (option) {
        labels.push(option.value)
      }
    })
  })
  return {
    value: labels.slice(0, 2).join(', '),
    secondaryValue: labels.length > 2 ? `+ ${labels.slice(2).length} more` : '',
    secondaryValueTooltip: labels.length > 2 ? labels.slice(2).join(', ') : '',
    labels
  }
}

export function resolveQueryParamFieldName(str) {
  return str.startsWith('opportunity_canonical_current')
    ? str : `opportunity_canonical_current.${last(str.split('.'))}`
}

export function resolveQueryParamKey(str) {
  return str.startsWith('injectables.comparison.opportunity_canonical_current')
    ? str : `injectables.comparison.opportunity_canonical_current.${last(str.split('.'))}`
}
