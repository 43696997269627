import { useMemo } from 'react'
import { useAuth } from '../context/auth'

const API_CLIENT = process.env.GRPC_URL
const API_CLIENT_EU = process.env.EU_GRPC_URL

export function useUserImageUrl(url) {
  const { actingTenantId, actingTenantRegion } = useAuth()

  const tenantId = actingTenantId

  const userImageUrl = useMemo(() => {
    if (actingTenantRegion.toLowerCase() === 'eu') {
      return `${API_CLIENT_EU}/api/tenant/v2/proxy/image/user/${tenantId}?url=${url}`
    } else {
      return `${API_CLIENT}/api/tenant/v2/proxy/image/user/${tenantId}?url=${url}`
    }
  }, [tenantId, url, actingTenantRegion])

  return {
    userImageUrl,
  }
}
