import { GetTenantRegion } from '../context/auth'

const API_CLIENT = process.env.GRPC_URL
const API_CLIENT_EU = process.env.EU_GRPC_URL

export const resolveCompanyImageUrl = (opportunity, region) => {
  if (!opportunity) {
    return ''
  }
  let contactEmail = ''
  if (opportunity.contactsList && opportunity.contactsList.length > 0) {
    contactEmail = opportunity.contactsList[0].email
  }
  let url = `${API_CLIENT}/api/tenant/v2/proxy/image/company?`
  const actingTenantRegion = GetTenantRegion()
  if (actingTenantRegion.toLowerCase() === 'eu') {
    url = `${API_CLIENT_EU}/api/tenant/v2/proxy/image/company?`
  }
  if (contactEmail) {
    url += `email=${contactEmail}&`
  }
  url += `url=${opportunity.website}`
  return url
}
