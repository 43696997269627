import React from 'react'
import classNames from 'classnames'
import InfoTip from '../infoTip'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const PipelineGroupItem = (props) => {
  const { data, containerWidth, itemCount, amountSum, inPipeline, isSelected, handleClick } = props
  const { id, label, totalAmount, totalDeals } = data

  const { formatCurrency } = useFormatCurrency({ shortCurrency: true })

  const classes = classNames(
    'flex flex-col justify-center px-3 py-5 text-center border border-color-d6d9e6 cursor-pointer first:rounded-l last:rounded-r',
    { 'bg-color-e0e7ff': inPipeline && !isSelected },
    { 'bg-gradient-green text-color-ffffff': isSelected }
  )

  const minWidth = 75
  const totalMinWidth = minWidth * itemCount
  const itemSpacing = 4
  const totalItemSpacing = itemSpacing * (itemCount - 1)
  const remainingContainerWidth = containerWidth - totalMinWidth - totalItemSpacing
  const width = minWidth + remainingContainerWidth * (totalAmount / amountSum)

  const style = inPipeline || isSelected
    ? { marginRight: itemSpacing, width: isNaN(width) ? 0 : width }
    : { marginRight: itemSpacing, width: isNaN(width) ? 0 : width, background: 'repeating-linear-gradient(45deg, #e0e7ff, #e0e7ff 10px, #fff 10px, #fff 20px)' }

  const isGroupInPipeline = (g) => {
    return g.id !== 'isNew' && g.id !== 'isPulledIn' && g.id !== 'isOverdue'
  }

  const tooltipContent = {
    isNew: 'Opportunities which did not exist at the start of the selected time period, but were created by the end of the time period.',
    // eslint-disable-next-line max-len
    isPulledIn: 'Opportunities which were open and had a close date after the selected close date range at the start of the time period, then subsequently had their close date moved into the selected time period.',
    // eslint-disable-next-line max-len
    isOverdue: 'Opportunities which were open yet had a close date before the selected close date range on the first day of time period, then subsequently had their close date moved into the selected time period.'
  }

  return (
    <div className={classes} style={style} onClick={handleClick}>
      <div className="flex justify-center items-center flex-nowrap" style={{ height: 22 }}>
        <div className="text-size-14px mr-1 truncate">{label || 'Empty'}</div>
        <div>{!isGroupInPipeline(data) && <InfoTip size="xs" content={<div className="px-4 py-3">{tooltipContent[id]}</div>} />}</div>
      </div>
      <div className="text-size-14px truncate">
        {formatCurrency(totalAmount)}
        {' '}
        (
        {totalDeals}
        )
      </div>
    </div>
  )
}

export default PipelineGroupItem
