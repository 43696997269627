import { clearApiActivity, clearApiActivityByKey } from '../actions'
import omit from 'lodash/omit'

export const apiActivity = (state = {}, action) => {
  const { type } = action
  const matches = /(.*)\/(requested|success|failed)/.exec(type)

  if (type === clearApiActivity.type) {
    return {}
  } else if (type === clearApiActivityByKey.type) {
    return omit(state, [action.payload])
  }

  // not a */requested or */success or */failed action, so just ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState
  }
}
