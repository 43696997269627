import React, { useCallback } from 'react'
import { DateRangeOption } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import { timePeriodOptions } from './constants'
import classNames from 'classnames'

const TimePeriodSelect = (props, ref) => {
  const { selectedOption, onSelect, onClose } = props

  const onSelectInternal = useCallback((option) => {
    onSelect && onSelect(option)
    option.key !== DateRangeOption.CUSTOM && onClose && onClose()
  }, [onSelect])

  return (
    <div className="flex flex-col" style={{ minWidth: 400 }} ref={ref}>
      {timePeriodOptions.map((o) => (
        <button
          key={`option-${o.key}`}
          onClick={() => onSelectInternal(o)}
          className={classNames('px-4 py-2 text-size-16px text-left focus:outline-none hover:bg-color-edeeee',
            { 'font-weight-700': selectedOption.key === o.key })}>
          {o.value}
        </button>
      ))}
    </div>
  )
}

export default React.forwardRef(TimePeriodSelect)
