import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import pencilSm from '../../assets/pencil-sm.png'
import { moveCursorToEnd } from '../../lib/input'
import Icon, { iconType } from '../icon'
import { useFormatValue } from './hooks'
import classNames from 'classnames'
import FieldDebug from './fieldDebug'
import { getFormatLength } from './helpers'

const NumberFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
  } = props

  const { label, canEdit, required, format } = fieldDefinition

  const maxLength = useMemo(() => {
    const len = getFormatLength(format)
    return !len ? {} : { maxLength: len }
  }, [format])

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure])

  const inputRef = useRef()
  const [inputValue, setInputValue] = useState(field.value || '')
  const [prevInputValue, setPrevInputValue] = useState(field.value || '')

  const { formatValue } = useFormatValue()

  const formattedValue = formatValue(inputValue, fieldDefinition.format)

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  useEffect(() => {
    const { value = '' } = field
    setPrevInputValue(value)
    setInputValue(value)
  }, [field])

  const [isEditMode, setIsEditMode] = useState(false)

  const onClick = useCallback(() => {
    if (readonly) {
      return
    }
    setPrevInputValue(inputValue)
    setIsEditMode(true)
  }, [readonly, inputValue])

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  const handleInputClick = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }, [])

  const handleInputBlur = useCallback((e) => {
    setIsEditMode(false)
    if (e.cancelled) {
      return
    }
    if (inputValue === '' && required) {
      setInputValue(prevInputValue)
      return
    }
    if (inputValue !== prevInputValue) {
      onFieldChangedInternal(inputValue)
    }
  }, [inputValue, prevInputValue, onFieldChangedInternal, required])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setInputValue(prevInputValue)
      handleInputBlur({ cancelled: true })
    } else if (e.key === 'Enter') {
      handleInputBlur({ cancelled: false })
    }
  }, [handleInputBlur, prevInputValue])

  useEffect(() => {
    if (isEditMode) {
      const inputEl = inputRef.current
      inputEl.focus()
      moveCursorToEnd(inputEl)
    }
  }, [isEditMode, inputRef])

  return (
    <>
      {field.value === undefined && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('NumberFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
              {label}
              <FieldDebug
                debug={debug}
                data={{ opportunity, fieldDefinition, field }} />
            </div>
            {isEditMode
              ? (
                <div>
                  <input
                    ref={inputRef}
                    value={inputValue}
                    type="number"
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onClick={handleInputClick}
                    onKeyDown={handleKeyDown}
                    className="w-full px-3 py-1 text-size-15px font-normal border border-color-b0bac9 rounded focus:outline-none"
                    {...maxLength} />
                </div>
              )
              : (
                <div
                  style={{ transform: 'translateY(-1px)' }}
                  onClick={onClick}
                  className={classNames('flex items-center group cursor-pointer text-right', { 'pointer-events-none': readonly })}>
                  <div className={classNames('text-size-16px font-weight-300',
                    { 'text-color-09242f': !readonly },
                    { 'text-color-09242f': readonly })}>
                    {field.value === undefined ? '-' : formattedValue}
                  </div>
                  <Icon type={iconType.IMAGE} src={pencilSm} className="invisible group-hover:visible" style={{ transform: 'translate(4px, -2px)' }} />
                </div>
              )}
          </div>
        )}
    </>
  )
}

export default NumberFieldDetailItem
