import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { getFieldDefinition } from '../fieldRenderers/helpers'
import StatusFieldDetailItem from '../fieldRenderers/statusFieldDetailItem'
import StringFieldDetailItem from '../fieldRenderers/stringFieldDetailItem'
import DateFieldDetailItem from '../fieldRenderers/dateFieldDetailItem'
import NumberFieldDetailItem from '../fieldRenderers/numberFieldDetailItem'
import SingleSelectFieldDetailItem from '../fieldRenderers/singleSelectFieldDetailItem'
import { getIntegrationObjectDefinitionKey } from '../../actions/integrationService'
import { getOppDetailByIdKey } from '../../actions/searchService'
import DealContacts from './dealContacts'
import useIsReadOnly from '../../hooks/useIsReadOnly'
import { canonicalFieldKeys, canopyFields } from '../fieldRenderers/constants'
import { useDealDetail } from '../../hooks/useDealDetail'
import { useOpportunityDefinition } from '../../hooks/useOpportunityDefinition'
import classNames from 'classnames'

const DealSummary = (props) => {
  const {
    opportunityId,
    onFieldChanged,
    isUpdating = false,
    debug,
  } = props

  const apiActivity = useSelector((state) => state.apiActivity)

  const { deal, dealProperties, lastModified, getField } = useDealDetail()

  const { fieldsList } = useOpportunityDefinition()

  const { isReadOnly } = useIsReadOnly()

  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    const apiSuccess = apiActivity[getIntegrationObjectDefinitionKey] === 'success'
      && apiActivity[getOppDetailByIdKey] === 'success'
    const populated = fieldsList.length && dealProperties.length
    setDataReady(apiSuccess && populated)
  }, [apiActivity, fieldsList, deal, dealProperties])

  const getParams = useCallback((fieldName) => {
    const fieldDefinition = cloneDeep(getFieldDefinition(fieldsList, fieldName))
    const fieldReference = fieldDefinition?.referencesList?.[0]
    const lazyLoad = !!fieldReference
    return {
      fieldDefinition,
      field: getField(fieldName),
      readonlyOverride: isReadOnly(deal, fieldDefinition),
      fieldReference,
      lazyLoad
    }
  }, [fieldsList, getField, deal, isReadOnly])

  return (
    <div className={classNames('pr-8', { 'animate-pulse-fast pointer-events-none': isUpdating })}>
      <div className="border-b border-color-d6d9e6">
        <div className="text-size-14px text-color-818e93 tracking-wider font-normal py-2">
          {'Last Updated '}
          {lastModified}
        </div>
      </div>

      {dataReady && (
        <div className="flex flex-row">
          <div className="flex flex-col w-3/5 pr-6">

            <StatusFieldDetailItem
              debug={debug}
              opportunity={deal}
              {...getParams(canopyFields._status.key)} />

            <StringFieldDetailItem
              canScroll={true}
              debug={debug}
              opportunity={deal}
              {...getParams(canonicalFieldKeys.nextstep)}
              onFieldChanged={onFieldChanged} />

            <DealContacts
              opportunity={deal} />

          </div>
          <div className="flex flex-col w-2/5">

            <SingleSelectFieldDetailItem
              debug={debug}
              opportunity={deal}
              {...getParams(canopyFields.ownerId.key.toLowerCase())}
              onFieldChanged={onFieldChanged} />

            <DateFieldDetailItem
              debug={debug}
              opportunity={deal}
              {...getParams(canonicalFieldKeys.closedate)}
              onFieldChanged={onFieldChanged} />

            <NumberFieldDetailItem
              debug={debug}
              opportunity={deal}
              {...getParams(canonicalFieldKeys.amount)}
              onFieldChanged={onFieldChanged} />

            <SingleSelectFieldDetailItem
              debug={debug}
              opportunity={deal}
              {...getParams(canonicalFieldKeys.stagename)}
              onFieldChanged={onFieldChanged} />

            <SingleSelectFieldDetailItem
              debug={debug}
              opportunity={deal}
              {...getParams(canonicalFieldKeys.forecastcategoryname)}
              onFieldChanged={onFieldChanged} />

          </div>
        </div>
      )}
    </div>
  )
}

export default DealSummary
