import React, { useCallback } from 'react'
import ConditionRow from './conditionRow'
import OperatorSelector from './operatorSelector'
import { useCanvasItem } from '../../context/canvasItem'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'

const FilterWrapper = (props) => {
  const {
    filters,
    parameterKey,
    parentWrapper,
    depth = 0,
    index = 0
  } = props

  const { name } = useCanvasItem()

  const { addFilter, addGroup, setCombiner, workingFilter } = useRealtimeFilterEditor()

  const { filter, nestedList = [] } = filters

  const parentCombiner = parentWrapper?.combiner || 0

  const handleCombinerChange = useCallback((newCombiner) => {
    if (parentWrapper) {
      setCombiner(parameterKey, newCombiner, parentWrapper)
    }
    window.analytics.track('realtimeMetrics.filterModal.andOrClicked', { name, filter: workingFilter?.name, value: newCombiner })
  }, [parameterKey, parentWrapper, setCombiner, name, workingFilter])

  const handleAddFilter = useCallback(() => {
    addFilter(parameterKey, nestedList)
    window.analytics.track('realtimeMetrics.filterModal.addFilterClicked', { name, filter: workingFilter?.name })
  }, [parameterKey, nestedList, addFilter, name, workingFilter])

  const handleAddGroup = useCallback(() => {
    addGroup(parameterKey, nestedList)
    window.analytics.track('realtimeMetrics.filterModal.addGroupClicked', { name, filter: workingFilter?.name })
  }, [parameterKey, nestedList, addGroup, name, workingFilter])

  return (
    <>
      {filter && (
        <ConditionRow
          key={`ConditionRow-${workingFilter?.id}-${index}`}
          index={index}
          userFilter={filter}
          parameterKey={parameterKey}
          combiner={parentCombiner}
          onCombinerChange={handleCombinerChange} />
      )}
      {!!nestedList.length && (
        <div className="nested-filter">
          <OperatorSelector
            index={index}
            onChange={handleCombinerChange}
            combiner={parentCombiner} />
          <div className="group">
            <div className="group-conditions">
              {nestedList.map((n, i) => (
                <FilterWrapper
                  key={`FilterWrapper-${depth}-${index}-${i}-${nestedList.length}-${n.key}`}
                  filters={n}
                  depth={depth + 1}
                  index={i}
                  parentWrapper={filters}
                  parameterKey={parameterKey} />
              ))}
            </div>
            <div className="filters-actions ml-14">
              {!nestedList.find(({ nestedList: nl }) => nl.length)
                && (
                  <button
                    onClick={handleAddFilter}
                    className="selected">
                    + Add Filter
                  </button>
                )}
              <button
                onClick={handleAddGroup}
                className="selected">
                + Add Group
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FilterWrapper
