import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toCheckPermissionsRequest } from '../grpc/converters'
import { Permission, PermissionSet } from '../grpc/enums'
import { guid } from '../lib/guid'
import { forEach } from 'lodash'
import { permissionNames } from '../constants/permissionNames'
import { useAuth } from './auth'
import { useDebug } from './debug'

const PermissionsContext = React.createContext()

export function PermissionsProvider({ children }) {
  const { debug, setCanDebug } = useDebug()
  const { actingUserId } = useAuth()

  const [key, setKey] = useState(guid())
  const [permissions, setPermissions] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const getPermissionCheckObject = useCallback((permissionSet, permission) => {
    return {
      checksList: [
        {
          permissionSet,
          permissionsList: [permission],
        },
      ]
    }
  }, [])

  const checkPermission = useCallback((permissionName) => {
    return permissions[permissionName] === true
  }, [permissions])

  const checkPermissions = useCallback((...permissionNames) => {
    const permissions = {}
    forEach(permissionNames, (p) => {
      permissions[p] = checkPermission(p)
    })
    return permissions
  }, [checkPermission])

  useGrpcEffect({
    request: toCheckPermissionsRequest({
      valsMap: [
        // Internal Admin
        [
          permissionNames.CanAccessInternalAdmin,
          getPermissionCheckObject(PermissionSet.INTERNALADMIN, Permission.ACCESS),
        ],
        // Web App
        [
          permissionNames.CanAccessWebApp,
          getPermissionCheckObject(PermissionSet.WEBAPP, Permission.ACCESS),
        ],
        // Admin
        [
          permissionNames.CanAccessAdmin,
          getPermissionCheckObject(PermissionSet.ADMIN, Permission.ACCESS),
        ],
        // Tenant
        [
          permissionNames.CanAccessTenant,
          getPermissionCheckObject(PermissionSet.TENANT, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadTenant,
          getPermissionCheckObject(PermissionSet.TENANT, Permission.READ),
        ],
        // Deal
        [
          permissionNames.CanAccessDeal,
          getPermissionCheckObject(PermissionSet.DEAL, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadDeal,
          getPermissionCheckObject(PermissionSet.DEAL, Permission.READ),
        ],
        [
          permissionNames.CanUpdateDeal,
          getPermissionCheckObject(PermissionSet.DEAL, Permission.UPDATE),
        ],
        // Analytic
        [
          permissionNames.CanAccessAnalytic,
          getPermissionCheckObject(PermissionSet.ANALYTIC, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadAnalytic,
          getPermissionCheckObject(PermissionSet.ANALYTIC, Permission.READ),
        ],
        [
          permissionNames.CanCreateAnalytic,
          getPermissionCheckObject(PermissionSet.ANALYTIC, Permission.CREATE),
        ],
        // Scenario Planner
        [
          permissionNames.CanAccessScenarioPlanner,
          getPermissionCheckObject(PermissionSet.SCENARIOPLANNER, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadScenarioPlanner,
          getPermissionCheckObject(PermissionSet.SCENARIOPLANNER, Permission.READ),
        ],
        [
          permissionNames.CanCreateScenarioPlanner,
          getPermissionCheckObject(PermissionSet.SCENARIOPLANNER, Permission.CREATE),
        ],
        [
          permissionNames.CanUpdateScenarioPlanner,
          getPermissionCheckObject(PermissionSet.SCENARIOPLANNER, Permission.UPDATE),
        ],
        // Forecast
        [
          permissionNames.CanAccessForecast,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadForecast,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.READ),
        ],
        [
          permissionNames.CanCreateForecast,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.CREATE),
        ],
        [
          permissionNames.CanUpdateForecast,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.UPDATE),
        ],
        // Note
        [
          permissionNames.CanAccessNote,
          getPermissionCheckObject(PermissionSet.NOTE, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadNote,
          getPermissionCheckObject(PermissionSet.NOTE, Permission.READ),
        ],
        [
          permissionNames.CanCreateNote,
          getPermissionCheckObject(PermissionSet.NOTE, Permission.CREATE),
        ],
        [
          permissionNames.CanUpdateNote,
          getPermissionCheckObject(PermissionSet.NOTE, Permission.UPDATE),
        ],
        [
          permissionNames.CanDeleteNote,
          getPermissionCheckObject(PermissionSet.NOTE, Permission.DELETE),
        ],
        [
          permissionNames.CanShareNote,
          getPermissionCheckObject(PermissionSet.NOTE, Permission.SHARE),
        ],
        [
          permissionNames.CanManageSignal,
          getPermissionCheckObject(PermissionSet.SIGNAL, Permission.MANAGE),
        ],
      ],
    }),
    onError: () => setIsFetching(false),
    onSuccess: (obj) => {
      const p = {}
      forEach(obj.resultsMap, (m) => {
        if (m.length > 1) {
          p[m[0]] = m[1]
        }
      })
      setPermissions(p)
      setIsFetching(false)

      const canAccessInternalAdmin = p[permissionNames.CanAccessInternalAdmin] === true
      setCanDebug(canAccessInternalAdmin)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'checkPermissions',
    debug: false,
  }, [key, actingUserId, debug])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      permissions,
      checkPermission,
      checkPermissions,
      getPermissionCheckObject,
      key,
      invalidate,
    }
  }, [isFetching, permissions, checkPermission, checkPermissions])

  return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>
}

export function usePermissions() {
  const context = React.useContext(PermissionsContext)
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider')
  }
  return context
}
