import React, { useLayoutEffect, useMemo } from 'react'
import FieldDebug from './fieldDebug'
import { format, parseISO } from 'date-fns'
import classNames from 'classnames'

const TimestampFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    className = '',
    labelClassName = '',
  } = props

  const { label } = fieldDefinition

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure])

  const value = useMemo(() => {
    if (field && field.value) {
      try {
        const d = parseISO(field.value)
        return format(d, 'M/d/yyyy')
      } catch (err) {
        console.log(err)
      }
    }

    return '-'
  }, [field])

  return (
    <>
      {value === '-' && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('TimestampFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className="flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate">
              {label}
              <FieldDebug
                debug={debug}
                data={{ opportunity, fieldDefinition, field }} />
            </div>
            <div
              style={{ transform: 'translateY(-1px)' }}
              className={classNames('text-color-09242f text-size-16px font-weight-300', labelClassName)}>
              {value}
            </div>
          </div>
        )}
    </>
  )
}

export default TimestampFieldDetailItem
