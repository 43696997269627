import { grpcInvoke } from '../grpc'
import { grpcCodes } from '../grpc/grpcCodes'
import { GetSettingsCommonRequest } from '../protos/src/services/setting/setting_pb'
import {
  getFeatureSettingsRequested,
  getFeatureSettingsSuccess,
  getFeatureSettingsFailed,
  getCrmSettingsRequested,
  getCrmSettingsSuccess,
  getCrmSettingsFailed
} from '../actions/settingsService'

export const getFeatureSettings = () => {
  return async (dispatch, getState) => {
    const request = new GetSettingsCommonRequest()
    request.setType('app')
    request.setKey('feature.*')
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getFeatureSettingsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getFeatureSettingsSuccess(obj))
      },
      onError: (err) => {
        if (err.code === grpcCodes.NOT_FOUND) {
          dispatch(getFeatureSettingsSuccess({ settingsList: [] }))
        } else {
          dispatch(getFeatureSettingsFailed(err))
        }
      },
      grpcMethod: 'getSettings',
      grpcMethodName: 'getFeatureSettings',
      debug: false,
    })
  }
}

export const getCrmSettings = () => {
  return async (dispatch, getState) => {
    const request = new GetSettingsCommonRequest()
    request.setType('app')
    request.setKey('crm.*')
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getCrmSettingsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getCrmSettingsSuccess(obj))
      },
      onError: (err) => {
        if (err.code === grpcCodes.NOT_FOUND) {
          dispatch(getCrmSettingsSuccess({ settingsList: [] }))
        } else {
          dispatch(getCrmSettingsFailed(err))
        }
      },
      grpcMethod: 'getSettings',
      grpcMethodName: 'getCrmSettings',
      debug: false,
    })
  }
}
