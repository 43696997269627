import { createReducer } from '@reduxjs/toolkit'
import {
  updatePipelineReportSelection,
  clearPipelineReportSelections,
  setPipelineReportRoles,
  removePipelineReportRole,
  setPipelineReportFilters,
  removePipelineReportFilter,
  setPipelineReportUsers,
  removePipelineReportUser,
} from '../actions'
import { DateRangeOption, CloseDateRangeOption, PipelineReportGroupingOption } from '../protos/src/common/reporting/pipeline_report_pb'
import { getUserLastReportInputSuccess } from '../actions/reportingService'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import remove from 'lodash/remove'
import moment from 'moment'

const initialState = {
  selectedDateRange: DateRangeOption.PREVIOUS_MONTH,
  startDate: new Date().toLocaleDateString('en-US'),
  endDate: new Date().toLocaleDateString('en-US'),
  selectedCloseDateRange: CloseDateRangeOption.REPORT,
  closeDateRangeStart: new Date().toLocaleDateString('en-US'),
  closeDateRangeEnd: new Date().toLocaleDateString('en-US'),
  selectedGroupBy: PipelineReportGroupingOption.FORECASTCATEGORY,
  selectedFilters: [],
  selectedRoles: [],
  selectedUsers: [],
}

function getCheckedStateFromFilter(filter) {
  if (has(filter, 'checked')) {
    return filter.checked
  } else {
    const op = get(filter, 'comparisonList[0].op', '')
    switch (op) {
      case 'IN': return 'include'
      case 'NIN': return 'exclude'
      default: return null
    }
  }
}

export const pipelineReportSelections = createReducer(initialState, {
  [clearPipelineReportSelections.type]: (state, action) => initialState,
  [getUserLastReportInputSuccess]: (state, action) => {
    const { startDatetime, endDatetime, groupByList, filtersList, dateRangeType, closeDateRange } = action.payload

    state.selectedDateRange = dateRangeType
    state.startDate = moment(startDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')
    state.endDate = moment(endDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')

    state.selectedCloseDateRange = closeDateRange.closeDateRangeType
    state.closeDateRangeStart = moment(closeDateRange.startDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')
    state.closeDateRangeEnd = moment(closeDateRange.endDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')

    if (groupByList.length > 0) state.selectedGroupBy = groupByList[0]

    const selectedFilters = []
    const filters = filter(filtersList, (f) => !['Role', 'Users'].includes(f.key))
    forEach(filters, (f) => {
      const comparisonList = get(f, 'comparisonList')
      forEach(comparisonList, (c) => {
        const clonedFilter = cloneDeep(f)
        clonedFilter.comparisonList = [c]
        clonedFilter.checked = getCheckedStateFromFilter(clonedFilter)
        selectedFilters.push(clonedFilter)
      })
    })
    state.selectedFilters = selectedFilters

    const selectedRoles = []
    const roles = filter(filtersList, (f) => f.key === 'Role')
    forEach(roles, (r) => {
      const comparisonList = get(r, 'comparisonList')
      forEach(comparisonList, (c) => {
        const role = cloneDeep(r)
        role.comparisonList = [c]
        role.checked = getCheckedStateFromFilter(role)
        role.label = r.key
        role.field = r.fieldName
        role.fieldtype = r.type
        const roleIds = get(role, 'comparisonList[0].valuesList', [])
        forEach(roleIds, (roleId) => {
          selectedRoles.push({
            ...role,
            key: roleId
          })
        })
      })
    })
    state.selectedRoles = selectedRoles

    const selectedUsers = []
    const users = filter(filtersList, (f) => f.key === 'Users')
    forEach(users, (u) => {
      forEach(u.comparisonList, (c) => {
        const clonedUser = cloneDeep(u)
        clonedUser.comparisonList = [c]
        clonedUser.checked = getCheckedStateFromFilter(clonedUser)
        selectedUsers.push(...c.valuesList.map((id) => ({
          checked: clonedUser.checked,
          key: id
        })))
      })
    })
    state.selectedUsers = selectedUsers

    return state
  },
  [updatePipelineReportSelection.type]: (state, action) => {
    state = {
      ...state,
      ...action.payload
    }
    return state
  },
  [setPipelineReportUsers.type]: (state, action) => {
    const users = action.payload || []
    state.selectedUsers = users
    forEach(state.selectedUsers, (u) => {
      u.checked = getCheckedStateFromFilter(u)
    })
    return state
  },
  [removePipelineReportUser.type]: (state, action) => {
    const { key, name } = action.payload
    const { selectedUsers } = state
    remove(selectedUsers, (u) => u.key === key)
    return state
  },
  [setPipelineReportRoles.type]: (state, action) => {
    const roles = action.payload || []
    state.selectedRoles = roles.map((r) => {
      return {
        checked: r.checked,
        ...r,
        roleName: r.name,
      }
    })
    return state
  },
  [removePipelineReportRole.type]: (state, action) => {
    const { key } = action.payload
    const { selectedRoles } = state
    remove(selectedRoles, (r) => r.key === key)
    return state
  },
  [setPipelineReportFilters.type]: (state, action) => {
    const filters = action.payload || []
    const selectedFilters = []
    forEach(filters, (f) => {
      const index = findIndex(selectedFilters, (x) => x.key === f.data.label && x.checked === f.checked)
      if (index !== -1) {
        const filter = selectedFilters[index]
        const valuesList = get(filter, 'comparisonList[0].valuesList', [])
        if (!valuesList.includes(f.key)) {
          valuesList.push(f.key)
        }
      } else {
        selectedFilters.push({
          key: f.data.label,
          fieldName: f.data.field,
          type: f.data.fieldtype,
          comparisonList: [
            {
              op: f.checked === 'include' ? 'IN' : 'NIN',
              valuesList: [f.key]
            }
          ],
          checked: getCheckedStateFromFilter(f)
        })
      }
    })
    state.selectedFilters = selectedFilters
    return state
  },
  [removePipelineReportFilter.type]: (state, action) => {
    const filter = action.payload
    const { selectedFilters } = state
    const index = findIndex(selectedFilters, (x) => x.key === filter.category && x.checked === filter.checked)
    if (index !== -1) {
      const f = selectedFilters[index]
      const valuesList = get(f, 'comparisonList[0].valuesList', [])
      remove(valuesList, (v) => v === filter.key)
      if (valuesList.length === 0) {
        remove(selectedFilters, (x) => x.key === filter.category && x.checked === filter.checked)
      }
    }
    return state
  }
})
