import { useCallback, useState } from 'react'

export function usePopover(afterClose) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const onClose = useCallback((e) => {
    setAnchorEl(null)
    afterClose && afterClose()
  }, [afterClose])

  return {
    anchorEl,
    setAnchorEl,
    open,
    onClose,
  }
}
