import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

export function useNotification() {
  // https://iamhosseindhv.com/notistack/api
  const { enqueueSnackbar } = useSnackbar()

  const notify = useCallback((key, options) => {
    enqueueSnackbar(key, options)
  }, [enqueueSnackbar])

  const notifySuccess = useCallback((key, options) => {
    enqueueSnackbar(key, { variant: 'success', ...options })
  }, [enqueueSnackbar])

  const notifyError = useCallback((key, options) => {
    enqueueSnackbar(key, { variant: 'error', ...options })
  }, [enqueueSnackbar])

  return {
    notify,
    notifySuccess,
    notifyError,
  }
}
