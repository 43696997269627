import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Checkbox } from '@material-ui/core'
import FieldDebug from './fieldDebug'
import classNames from 'classnames'

const BoolFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
  } = props

  const { label, canEdit } = fieldDefinition

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, label, onFieldChanged])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure])

  const [isChecked, setIsChecked] = useState(field.value)

  useEffect(() => {
    setIsChecked(field.value)
  }, [field])

  const onChange = useCallback((e) => {
    const checked = e.target.checked
    setIsChecked(checked)
    onFieldChangedInternal(checked)
  }, [onFieldChangedInternal])

  return (
    <div className={classNames('DateFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
      <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
        {label}
        <FieldDebug
          debug={debug}
          data={{ opportunity, fieldDefinition, field }} />
      </div>
      <div style={{ transform: 'translate(-14px, -9px)' }}>
        <Checkbox checked={isChecked} onChange={onChange} color="primary" disabled={readonly} />
      </div>
    </div>
  )
}

export default BoolFieldDetailItem
