/* eslint-disable max-len */

import React, { useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useRoutes } from './context/routes'
import ProtectedRoute from './components/protectedRoute'
import Connected from './components/connected/connected'
import SlackAuthComplete from './components/slack/slackAuthComplete'
import Login from './components/auth/login'
import AuthRedirect from './components/auth/authRedirect'
import Logout from './components/auth/logout'
import Unauthorized from './components/unauthorized'
import Analytics from './components/analytics/analytics'
import Deals from './components/deals/deals'
import DealDetail from './components/deals/dealDetail'
import Forecasting from './components/forecasting/forecasting'
import ForecastingHistory from './components/forecasting/forecastingHistory'
import NLGTester from './components/nlg/nlgTester'
import Rep from './components/rep/rep'
import Performance from './components/performance/performance'
import Pipeline from './components/pipeline/pipeline'
import PipelineDock from './components/pipeline/pipelineDock'
import ScenarioPlanner from './components/scenarioPlanner/scenarioPlanner'
import Status from './components/status/status'
import Summary from './components/summary/summary'
import Tenants from './components/tenants/tenants'
import Users from './components/tenants/users/users'
import { MuiThemeProvider } from '@material-ui/core/styles'
import MuiTheme from './lib/muiTheme'
import './lib/fontAwesome'
import './prototypes'
import * as Sentry from '@sentry/browser'
import EventBusRedirect from './components/eventBusRedirect'
import AvailabilityMode from './components/availabilityMode'
import AppHandlers from './components/appHandlers'
import { permissionNames } from './constants/permissionNames'
import useRoles from './hooks/useRoles'
import NotFound from './components/notFound/notFound'
import * as BaseTemplates from './components/templates'
import * as PreviewTemplates from './components/templates/preview'

if (process.env.TAG_NAME !== 'local') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.TAG_NAME || process.env.SHORT_SHA
  })
}

const App = (props) => {
  const {
    isIndividualContributorRoleOrAbove,
    isManagerRole,
  } = useRoles()

  const { routes, isPreview } = useRoutes()

  const MainTemplate = useMemo(() => {
    return (isPreview && PreviewTemplates?.MainTemplate)
      || BaseTemplates.MainTemplate
  }, [isPreview])

  const ForecastingTemplate = useMemo(() => {
    return (isPreview && PreviewTemplates?.ForecastingTemplate)
      || BaseTemplates.ForecastingTemplate
  }, [isPreview])

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <Router>
        <AppHandlers />
        <AvailabilityMode />
        <Switch>
          <Route path="/noop" component={() => <></>} />
          <Route path={routes.eventBusRedirect} component={EventBusRedirect} />
          <Route path={routes.connected} component={Connected} />
          <Route path={routes.slackAuthComplete} component={SlackAuthComplete} />
          <Route path={routes.login} component={Login} />
          <Route path={routes.authRedirect} component={AuthRedirect} />
          <Route path={routes.logout} component={Logout} />
          <Route path={routes.unauthorized} component={Unauthorized} />
          <Route path={routes.status} component={Status} />
          <ProtectedRoute path={routes.analytics} template={MainTemplate} component={Analytics} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessAnalytic]} />
          <ProtectedRoute path={routes.deals} template={MainTemplate} component={Deals} roleChecks={[isIndividualContributorRoleOrAbove]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessDeal]} />
          <ProtectedRoute path={routes.deal} template={MainTemplate} component={DealDetail} dock={PipelineDock} roleChecks={[isIndividualContributorRoleOrAbove]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessDeal]} />
          <ProtectedRoute path={routes.nlgTester} template={MainTemplate} component={NLGTester} roleChecks={[]} checkPermissions={[permissionNames.CanAccessInternalAdmin, permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={routes.forecastingByTeam} template={ForecastingTemplate} component={Forecasting} dock={PipelineDock} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessForecast]} />
          <ProtectedRoute path={[routes.forecastingByConfig, routes.forecasting]} template={ForecastingTemplate} component={Forecasting} dock={PipelineDock} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessForecast]} />
          <ProtectedRoute path={[routes.forecastingHistoryByConfig, routes.myForecastingHistoryByConfig]} template={ForecastingTemplate} component={ForecastingHistory} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessForecast]} />
          <ProtectedRoute path={[routes.forecastingHistory, routes.myForecastingHistory]} template={ForecastingTemplate} component={ForecastingHistory} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessForecast]} />
          <ProtectedRoute path={[routes.rep, routes.repHome]} template={ForecastingTemplate} component={Rep} dock={PipelineDock} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={[routes.performanceByUser, routes.performance]} template={ForecastingTemplate} component={Performance} roleChecks={[]} checkPermissions={[permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={routes.pipeline} template={ForecastingTemplate} component={Pipeline} dock={PipelineDock} roleChecks={[isIndividualContributorRoleOrAbove]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessDeal]} />
          <ProtectedRoute path={routes.userSummary} template={MainTemplate} component={Summary} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={routes.scenarioPlanner} template={MainTemplate} component={ScenarioPlanner} roleChecks={[isManagerRole]} checkPermissions={[permissionNames.CanAccessWebApp, permissionNames.CanAccessScenarioPlanner]} />
          <ProtectedRoute path={routes.summary} template={MainTemplate} component={Summary} roleChecks={[isIndividualContributorRoleOrAbove]} checkPermissions={[permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={routes.tenantUsers} template={MainTemplate} component={Users} roleChecks={[]} checkPermissions={[permissionNames.CanAccessInternalAdmin, permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={routes.tenants} template={MainTemplate} component={Tenants} roleChecks={[]} checkPermissions={[permissionNames.CanAccessInternalAdmin, permissionNames.CanAccessWebApp]} />
          <ProtectedRoute path={routes.default} template={MainTemplate} component={Summary} roleChecks={[isIndividualContributorRoleOrAbove]} checkPermissions={[permissionNames.CanAccessWebApp]} />
          <ProtectedRoute exact path={routes.default} template={MainTemplate} component={Summary} roleChecks={[isIndividualContributorRoleOrAbove]} checkPermissions={[permissionNames.CanAccessWebApp]} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  )
}

export default App

/* eslint-enable max-len */
