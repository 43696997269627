import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import classNames from 'classnames'
import DealListItem from './dealListItem'
import DealSortingControl, { dealSortingOptions } from './dealSortingControl'
import forEach from 'lodash/forEach'
import orderBy from 'lodash/orderBy'
import has from 'lodash/has'
import find from 'lodash/find'
import DealFiltersToggle from './dealFiltersToggle'
import DealFilters from './dealFilters'
import EmptyState from '../empty/emptyState'
import { keys } from 'lodash'
import pageIcon from '../../assets/pageIcon.png'

const columnClasses = [
  classNames('td w-48'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('td w-10 text-right font-normal'),
  classNames('mr-4 w-6 text-right font-normal')
]

const DealsList = (props) => {
  const { opportunities, enableSorting, enableFilters, showEmptyList, emptyStateSubHeader, handleItemClick, fieldHistoryHoverDates, disabledNav } = props

  const location = useLocation()
  const hash = queryString.parse(location.hash)
  const hasFilters = keys(hash).length > 0

  const history = useHistory()

  const disabled = useMemo(() => {
    return disabledNav
  }, [disabledNav])

  const defaultSortField = 'insightCount'
  const defaultSortOrder = 'DESC'
  const [selectedSortField, setSelectedSortField] = useState(defaultSortField)
  const [selectedSortOrder, setSelectedSortOrder] = useState(defaultSortOrder)
  useEffect(() => {
    if (has(hash, 'sort')) {
      const parts = hash.sort.split(' ')
      setSelectedSortField(parts[0])
      setSelectedSortOrder(parts[1])
    }
  }, [location])
  const selectedOption = find(dealSortingOptions, (o) => o.sortField === selectedSortField)

  const navigateWithFilters = useCallback((filters) => {
    filters = orderBy(filters, ['key'], ['asc'])
    let hashFilters = ''
    filters.map((f) => { hashFilters += `&${f.key}=${f.value}` })
    if (hashFilters.length > 0) hashFilters = hashFilters.substr(1)
    !disabled && history.push(location.pathname + (hashFilters ? `#${hashFilters}` : ''))
  }, [disabled, location, selectedSortField, selectedSortOrder])

  const handleSortFieldClick = useCallback((menuItem) => {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'sort') {
        filters.push({ key, value })
      }
    })
    filters.push({ key: 'sort', value: `${menuItem.sortField} ${selectedSortOrder}` })
    navigateWithFilters(filters)
  }, [location, selectedSortField, selectedSortOrder])

  const handleSortOrderClick = useCallback((args) => {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'sort') {
        filters.push({ key, value })
      }
    })
    const option = find(dealSortingOptions, (o) => o.label === args.selectedLabel)
    filters.push({ key: 'sort', value: `${option.sortField} ${args.isDescendingSort ? 'DESC' : 'ASC'}` })
    navigateWithFilters(filters)
  }, [location, selectedSortField, selectedSortOrder])

  const [showFilters, setShowFilters] = useState(hasFilters)
  const handleFilterClick = useCallback(() => {
    window.analytics.track('Show Filters', { isShowing: !showFilters })
    setShowFilters(!showFilters)
  }, [showFilters])

  const sortFilterClasses = classNames('flex items-center', { 'justify-between': enableFilters }, { 'justify-end': !enableFilters })

  return (
    <>
      {(enableSorting || enableFilters) && (
        <div className={sortFilterClasses}>
          {enableFilters
            && (
              <DealFiltersToggle
                showFilters={showFilters}
                handleFilterClick={handleFilterClick}
                hasFilters={hasFilters} />
            )}
          {enableSorting
            && (
              <DealSortingControl
                handleSortFieldClick={handleSortFieldClick}
                handleSortOrderClick={handleSortOrderClick}
                selectedLabel={selectedOption.label}
                isDescendingSort={selectedSortOrder === 'DESC'} />
            )}
        </div>
      )}
      {enableFilters && showFilters && <div className="my-6"><DealFilters enableOwnerFilter={false} /></div>}

      {showEmptyList && (
        <div className="flex justify-center">
          <EmptyState iconControl={<img src={pageIcon} />} header="No Deals" subHeader={emptyStateSubHeader} />
        </div>
      )}

      {!showEmptyList && (
        <div className="flex-table striped hovered main-first-cell">
          <div className="thead">
            <div className="tr">
              <div className={columnClasses[0]}>Deal</div>
              <div className={columnClasses[1]}>Stage</div>
              <div className={columnClasses[2]}>Forecast</div>
              <div className={columnClasses[3]}>Deal Size</div>
              <div className={columnClasses[4]}>Close Date</div>
              <div className={columnClasses[5]}>Owner</div>
              <div className={columnClasses[6]} />
            </div>
          </div>
          <div className="tbody">
            {opportunities.map((opportunity, i) => {
              return (
                <DealListItem
                  key={`${opportunity.id}-${i}`}
                  opportunity={opportunity}
                  columnClasses={columnClasses}
                  handleItemClick={handleItemClick}
                  fieldHistoryHoverDates={fieldHistoryHoverDates}
                  disabled={disabled} />
              )
            })}
          </div>
        </div>
      )}

    </>
  )
}

export default DealsList
