import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { apiSuccessSelector } from '../../selectors'
import SignalsList from './signalsList'
import EmptyState from '../empty/emptyState'
import Chart from '../svg/chart'
import { getKeyMetricsByTeamKey } from '../../actions/signalService'

const TeamKeyMetrics = (props) => {
  const { id } = props

  const keyMetrics = useSelector((state) => state.keyMetricsByTeam)

  const teamApiSuccess = useSelector((state) => apiSuccessSelector(state, getKeyMetricsByTeamKey))

  const showEmptyMessage = useMemo(() => {
    return teamApiSuccess && keyMetrics.length === 0
  }, [teamApiSuccess, keyMetrics])

  return (
    <div className="w-full">
      <h1>Key Metrics</h1>

      {showEmptyMessage
        ? (
          <div className="flex justify-center my-4">
            <EmptyState iconControl={<Chart />} header="No Metrics" subHeader="Not enough data" />
          </div>
        )
        : (
          <SignalsList
            id={id}
            level="group"
            signals={keyMetrics} />
        )}
    </div>
  )
}

export default TeamKeyMetrics
