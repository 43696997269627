import React, { useCallback } from 'react'
import iconFilter from '../../assets/filter-icon.png'
import { useCanvasItem } from '../../context/canvasItem'
import { useRealtimeViewModal } from '../../context/realtimeViewModal'

const FiltersButton = () => {
  const { openModal } = useRealtimeViewModal()

  const { name } = useCanvasItem()

  const onFilterClick = useCallback(() => {
    openModal()
    window.analytics.track('realtimeMetrics.filtersClicked', { name })
  }, [openModal, name])

  return (
    <button
      onClick={onFilterClick}
      className="mx-4 flex items-center focus:outline-none">
      <span className="mr-2" style={{ transform: 'translateY(-1px)' }}>
        <img src={iconFilter} className="flex-shrink-0" />
      </span>
      <span className="text-size-16px text-color-a0a8bb font-weight-500">Filters</span>
    </button>
  )
}

export default FiltersButton
