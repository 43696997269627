import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useCanvasItem } from './canvasItem'
import { useGrpcCallback } from '../grpc'
import { toRunQueryObjectDataRequest } from '../grpc/converters'
import { subscribe } from '../gml/eventBus'
import { eventTypes } from '../gml/eventBus/eventTypes'
import RealtimeViewDetailsModal from '../components/realtimeView/realtimeViewDetailsModal'
import { hideAll } from 'tippy.js'
import { useRealtimeView } from './realtimeView'
import { useCanvas } from './canvas'

const RealtimeViewDetailsModalContext = React.createContext()

export function RealtimeViewDetailsModalProvider({ actor, gmlInstanceId, children }) {
  const { tenantStandardParams } = useCanvas()
  const { tileKey, queryObjectType } = useCanvasItem()
  const { selectedFilter } = useRealtimeView()

  const [key, setKey] = useState(guid())
  const [error, setError] = useState(undefined)
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [groupFiltersList, setGroupFiltersList] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(20)
  const [total, setTotal] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [title, setTitle] = useState('')

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    setOffset(0)
    setTotal(0)
    setGroupFiltersList([])
    setData([])
  }, [])

  const getNextPage = useCallback((offset) => {
    setOffset(offset)
  }, [])

  const getQueryResultDetails = useGrpcCallback({
    grpcMethod: 'getQueryResultDetails',
    onSuccess: (obj) => {
      const { objectData = '', total = 0 } = obj
      try {
        setData([
          ...data,
          ...JSON.parse(atob(objectData))
        ])
        setTotal(total)
      } catch (err) {
        setError({ error: err })
      }
      setIsFetching(false)
    },
    onError: (err) => {
      setError({ error: err })
      setIsFetching(false)
    },
    onFetch: () => {
      hideAll()
      setIsModalOpen(true)
      setIsFetching(true)
    },
    debug: false,
  }, [data])

  useEffect(() => {
    const { unsubscribe } = subscribe(eventTypes.linkClicked, (args) => {
      const { clickData = {} } = args
      const { gmlInstanceId: instanceId, type, modalTitle, groupFilters } = clickData
      if (type === 'realtime_view_details_modal' && gmlInstanceId === instanceId && groupFilters?.length > 0) {
        setTitle(modalTitle)
        setGroupFiltersList(groupFilters)
      }
    })
    return () => unsubscribe()
  }, [gmlInstanceId])

  useEffect(() => {
    if (key && actor && selectedFilter && groupFiltersList?.length > 0) {
      const paramsList = selectedFilter?.parameters.parametersList ?? []
      const r = toRunQueryObjectDataRequest({
        queryKey: tileKey,
        paramsList,
        groupFiltersList,
        offset,
        limit,
        actor,
        tenantSettingParams: {
          fiscalYearStartMonthIdx: tenantStandardParams?.fiscalYearStartMonth ?? 1,
          salesPeriodMonthLength: tenantStandardParams?.salesPeriodLength ?? 3,
          utcOffsetSeconds: tenantStandardParams?.tzOffset ?? 0,
        }
      })
      getQueryResultDetails(r)
    }
  }, [key, actor, offset, limit, tileKey, groupFiltersList, selectedFilter])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      error,
      offset,
      setOffset,
      limit,
      setLimit,
      queryObjectType,
      invalidate
    }
  }, [isFetching, error, offset, limit, queryObjectType])

  return (
    <RealtimeViewDetailsModalContext.Provider value={contextValue}>
      {children}
      <RealtimeViewDetailsModal
        isFetching={isFetching && offset === 0}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        getNextPage={getNextPage}
        error={error}
        total={total}
        data={data}
        title={title} />
    </RealtimeViewDetailsModalContext.Provider>
  )
}

export function useRealtimeViewDetailsModal() {
  const context = React.useContext(RealtimeViewDetailsModalContext)
  if (context === undefined) {
    throw new Error('useRealtimeViewDetailsModal must be used within a RealtimeViewDetailsModalProvider')
  }
  return context
}
