import { forecastPeriods } from './constants'
import { NavigateNext } from '@getoutreach/react-icons'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useForecasting } from '../../context/forecasting'
import { usePopover } from '../../hooks/usePopover'
import classNames from 'classnames'
import Popover from '../popover'
import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useRoutes } from '../../context/routes'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'

const ForecastPeriodSelector = (props) => {
  const { fieldset } = props
  const { setAnchorEl, anchorEl, onClose, open } = usePopover()
  const { cursor, setCursor } = useForecasting()
  const { selectedConfig } = useForecastConfigs()
  const { periodLength = 0 } = selectedConfig ?? {}

  const params = useParams()
  const history = useHistory()
  const { routes } = useRoutes()
  const forecastingDealsRouteMatch = useRouteMatch(routes.forecastingByTeam_Deals)
  const forecastingTeamDealsRouteMatch = useRouteMatch(routes.forecastingByTeam_TeamDeals)

  const getForecastPeriods = useMemo(() => {
    return forecastPeriods[periodLength] || forecastPeriods.default
  }, [periodLength])

  const [selectedPeriod, setSelectedPeriod] = useState(getForecastPeriods[cursor + 1])

  useEffect(() => {
    setSelectedPeriod(getForecastPeriods[cursor + 1])
  }, [cursor, getForecastPeriods])

  const onPeriodSelection = useCallback((period) => {
    window.analytics.track('forecasting.v3.forecastingPeriod.periodSelectionChanged', { period })
    setSelectedPeriod(period)
    setCursor(period.id)
    if (forecastingDealsRouteMatch?.isExact || forecastingTeamDealsRouteMatch?.isExact) {
      if (params?.forecastConfigId && params?.userIds) {
        history.push(routes.forecastingByTeam.replace(':forecastConfigId', params.forecastConfigId).replace(':userIds', params.userIds))
      } else if (params?.forecastConfigId) {
        history.push(routes.forecastingByConfig.replace(':forecastConfigId', params.forecastConfigId))
      } else {
        history.push(routes.forecasting)
      }
    }
    onClose()
  }, [
    onClose,
    setCursor,
    history,
    params,
    routes.forecastingByTeam,
    routes.forecastingByConfig,
    routes.forecasting,
    forecastingDealsRouteMatch,
    forecastingTeamDealsRouteMatch
  ])

  return (
    <div>
      <button onClick={(e) => setAnchorEl(e.currentTarget)}>
        {fieldset ? (
          <fieldset className="text-color-818e93 whitespace-nowrap truncate border rounded border-color-d6d9e6 w-40 text-left">
            <legend className="text-color-818e93 text-left pl-2">Sales Period</legend>
            <div className="flex flex-row justify-between">
              <div className="ml-2 mb-1">{selectedPeriod?.name}</div>
              {' '}
              <NavigateNext transform="rotate(90)" htmlColor="818e93" />
            </div>
          </fieldset>
        )
          : (
            <div className=" flex flex-row justify-between mt-2">
              <div className="ml-2 mt-1 font-weight-700">{selectedPeriod?.name}</div>
              {' '}
              <NavigateNext transform="rotate(90) scale(0.7)" htmlColor="818e93" />
            </div>
          )}
      </button>
      <Popover
        className="w-40"
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}>
        <div>
          {getForecastPeriods.map((period, i) => {
            return (
              <div
                key={i}
                className={
                  classNames(
                    'p-3 cursor-pointer hover:bg-color-edeeee font-weight-400',
                    { 'font-weight-700 text-color-5951FF': selectedPeriod?.id === period.id }
                  )
                }
                onClick={() => onPeriodSelection(period)}>
                {period?.name}
              </div>
            )
          })}
        </div>
      </Popover>
    </div>
  )
}

export default ForecastPeriodSelector
