import React from 'react'
import TrendListItem from './trendListItem'

const TrendList = (props) => {
  const { userId, trends, aggregateBy } = props

  return (
    <>

      {trends.map((t, index) => {
        return <TrendListItem key={`trend-${userId}-${index}`} id={`${userId}-${index}`} trend={t} aggregateBy={aggregateBy} />
      })}

    </>
  )
}

export default TrendList
