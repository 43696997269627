import { createReducer } from '@reduxjs/toolkit'
import { forOwn } from 'lodash'
import { setDock } from '../actions'

const initialState = {}

export const dock = createReducer(initialState, {
  [setDock.type]: (state, action) => {
    const newState = {}
    forOwn(state, (value, key) => {
      newState[key] = {
        ...value,
        enabled: false,
      }
    })
    return {
      ...newState,
      ...action.payload,
    }
  },
})
