import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { clearImpersonation, clearImpersonationUsers, clearTeam } from '../../actions'
import useKeyPressEffect from '../../hooks/useKeyPress'

const TenantListItem = (props) => {
  const {
    i,
    selected,
    tenant,
    onMouseOver
  } = props

  const { id, name, status } = tenant

  const dispatch = useDispatch()

  const { routes } = useRoutes()

  const { stopImpersonating } = useAuth()

  const history = useHistory()

  const impersonate = useCallback(() => {
    dispatch(clearTeam())

    dispatch(clearImpersonation())
    dispatch(clearImpersonationUsers())

    stopImpersonating()

    history.push(`${routes.tenantUsers.replace(':tenantId', tenant.id)}#name=${tenant.name}&region=${tenant.region}`)
  }, [tenant])

  const onMouseOverInternal = useCallback(() => {
    onMouseOver(i)
  }, [onMouseOver, i])

  useKeyPressEffect({
    targetKey: 'Enter',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (selected) {
        impersonate()
      }
    }
  }, [impersonate, selected])

  return (
    <div
      className={
        classNames(
          'tr hover:bg-color-51636a-05 border-b border-color-c9ced0',
          { 'bg-color-51636a-05': selected }
        )
      }
      onMouseOver={onMouseOverInternal}>
      <div className="td w-48 leading-tight">
        <div>{name}</div>
        <div className="text-size-11px text-color-a0a8bb">{id}</div>
      </div>
      <div className="td w-10">
        <div className="inline-block">
          <div className={classNames('text-size-12px text-color-2e384d border px-2 rounded',
            { 'text-color-1dcf83 border-color-1dcf83': status === 'active' },
            { 'border-color-d8d8d8': status !== 'active' })}>
            {status || '--'}
          </div>
        </div>
      </div>
      <div className="td w-10 leading-tight truncate">
        {tenant.region || '--'}
      </div>
      <div className="td w-10 text-right">
        <button
          onClick={impersonate}
          className="px-6 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none">
          Impersonate
        </button>
      </div>
    </div>
  )
}

export default TenantListItem
