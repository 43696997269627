import React, { useMemo } from 'react'
import CategoryDeals from './categoryDeals'
import { useForecasting } from '../../../context/forecasting'
import { cloneDeep, filter, forEach, sumBy } from 'lodash'

const DealList = (props) => {
  const {
    forecastCategories,
  } = props

  const { forecast } = useForecasting()

  const opportunitiesList = useMemo(() => {
    return forecast?.current?.objectsList ?? []
  }, [forecast])

  const categoriesWithOpps = useMemo(() => {
    const categories = cloneDeep(forecastCategories)
    forEach(categories, (fc) => {
      fc.opps = filter(opportunitiesList, (o) => o && o.objectId !== '' && o.callKey === fc.id)
      fc.oppTotal = sumBy(fc.opps, (o) => o?.opportunity?.amount ?? 0)
    })
    return filter(categories, (fc) => fc.opps.length > 0)
  }, [forecastCategories, opportunitiesList])

  return (
    <>
      {categoriesWithOpps.map((fc, index) => {
        return (
          <CategoryDeals
            key={`CategoryDeals-${fc.id}-${index}`}
            forecastCategoryWithOpps={fc} />
        )
      })}
    </>
  )
}

export default DealList
