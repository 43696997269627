import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForecastingFeature } from './hooks'

const NoForecast = (props) => {
  const forecastingFeature = useForecastingFeature()

  const { isAutoMode, isManualMode } = forecastingFeature

  const message = useMemo(() => {
    if (isAutoMode) {
      return 'Change a deal\'s forecast category to add it to your forecast'
    } else if (isManualMode) {
      return 'Click the plus sign next to a deal\'s name to add that deal to your forecast.'
    } else {
      return ''
    }
  }, [isAutoMode, isManualMode])

  return (
    <div className="flex items-center h-full mt-10">
      <div className="text-center">
        <div className="flex justify-center my-4">
          <div
            style={{ width: 38, height: 38 }}
            className="inline-block text-center rounded-full flex-shrink-0 text-color-ffffff bg-gradient-green">
            <FontAwesomeIcon icon="exclamation" size="lg" className="text-color-ffffff" style={{ transform: 'translateY(8px)' }} />
          </div>
        </div>
        <div className="text-size-22px font-weight-700 text-color-151d49 my-4">No Forecast</div>
        <div className="text-size-14px font-weight-500 text-color-4e5d7e">{message}</div>
      </div>
    </div>
  )
}

export default NoForecast
