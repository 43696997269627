import moment from 'moment'

export const formatDate = (date, format = 'MM/DD') => {
  return moment(date).format(format)
}

// salesPeriodName examples
// console.log('salesPeriodName(1, false) => Month', salesPeriodName(1, false))
// console.log('salesPeriodName(1, true) => Mo', salesPeriodName(1, true))
// console.log('salesPeriodName(3, false) => Quarter', salesPeriodName(3, false))
// console.log('salesPeriodName(3, true) => Qtr', salesPeriodName(3, true))

export const salesPeriodName = (salesPeriodLength, useShortName) => {
  if (salesPeriodLength === 1) {
    return useShortName ? 'Mo' : 'Month'
  } else if (salesPeriodLength === 3) {
    return useShortName ? 'Qtr' : 'Quarter'
  }
  return ''
}

// salesPeriod examples
// console.log('salesPeriod(1, 1, 2020-04-14, true, false) => April 2020', salesPeriod(1, 1, '2020-04-14', true, false))
// console.log('salesPeriod(1, 1, 2020-06-01, false, false) => June', salesPeriod(1, 1, '2020-06-01', false, false))
// console.log('salesPeriod(1, 1, 2020-01-01, true, true) => Jan 2020', salesPeriod(1, 1, '2020-01-01', true, true))
// console.log('salesPeriod(3, 1, 2020-04-14, true, false) => Quarter 2 2020', salesPeriod(3, 1, '2020-04-14', true, false))
// console.log('salesPeriod(3, 1, 2020-06-01, true, false) => Quarter 2', salesPeriod(3, 1, '2020-06-01', false, false))
// console.log('salesPeriod(3, 1, 2020-01-01, true, true) => Q1 2020', salesPeriod(3, 1, '2020-01-01', true, true))
// console.log('salesPeriod(3, 2, 2020-04-14, true, false) => Quarter 1 2020', salesPeriod(3, 2, '2020-04-14', true, false))
// console.log('salesPeriod(3, 2, 2020-01-11, true, true) => Q4 2019', salesPeriod(3, 2, '2020-01-01', true, true))

export const salesPeriod = (salesPeriodLength, fiscalYearStartMonth, date, includeYear, useShortName) => {
  let d = moment(date)
  if (salesPeriodLength === 1) {
    if (useShortName) {
      return includeYear ? d.format('MMM YYYY') : d.format('MMM')
    } else {
      return includeYear ? d.format('MMMM YYYY') : d.format('MMMM')
    }
  } else {
    // as the default for now, assume salesPeriodLength === 3
    if (fiscalYearStartMonth > 1) {
      d = d.subtract(fiscalYearStartMonth - 1, 'months')
    } else if (fiscalYearStartMonth < 0) {
      d = d.subtract(12 + fiscalYearStartMonth, 'month').add(1, 'year')
    }
    const quarter = Math.ceil((d.month() + 1.0) / 3.0)
    const year = d.year()
    const prefix = useShortName ? 'Q' : 'Quarter '
    return includeYear ? `${prefix}${quarter} ${year}` : `${prefix}${quarter}`
  }
}
