import { createAction } from '@reduxjs/toolkit'

export const getOpportunitiesByTeamKey = 'api/opportunity/getOpportunitiesByTeam'
export const getOpportunitiesByTeamRequested = createAction(`${getOpportunitiesByTeamKey}/requested`)
export const getOpportunitiesByTeamSuccess = createAction(`${getOpportunitiesByTeamKey}/success`)
export const getOpportunitiesByTeamFailed = createAction(`${getOpportunitiesByTeamKey}/failed`)

export const getPipelineOpportunitiesKey = 'api/opportunity/getPipelineOpportunities'
export const getPipelineOpportunitiesRequested = createAction(`${getPipelineOpportunitiesKey}/requested`)
export const getPipelineOpportunitiesSuccess = createAction(`${getPipelineOpportunitiesKey}/success`)
export const getPipelineOpportunitiesFailed = createAction(`${getPipelineOpportunitiesKey}/failed`)

export const getOppSettingsByTeamKey = 'api/opportunity/getOppSettingsByTeam'
export const getOppSettingsByTeamRequested = createAction(`${getOppSettingsByTeamKey}/requested`)
export const getOppSettingsByTeamSuccess = createAction(`${getOppSettingsByTeamKey}/success`)
export const getOppSettingsByTeamFailed = createAction(`${getOppSettingsByTeamKey}/failed`)

export const getModalOpportunitiesKey = 'api/opportunity/getModalOpportunities'
export const getModalOpportunitiesRequested = createAction(`${getModalOpportunitiesKey}/requested`)
export const getModalOpportunitiesSuccess = createAction(`${getModalOpportunitiesKey}/success`)
export const getModalOpportunitiesFailed = createAction(`${getModalOpportunitiesKey}/failed`)

export const noteSearchOpportunitiesKey = 'api/opportunity/noteSearchOpportunities'
export const noteSearchOpportunitiesRequested = createAction(`${noteSearchOpportunitiesKey}/requested`)
export const noteSearchOpportunitiesSuccess = createAction(`${noteSearchOpportunitiesKey}/success`)
export const noteSearchOpportunitiesFailed = createAction(`${noteSearchOpportunitiesKey}/failed`)
