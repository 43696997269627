import { format } from 'date-fns'
import { forOwn, isString } from 'lodash'
import { FilterOperation, SearchCombineAction } from '../../grpc/enums'
import { parseDate } from '../../lib/dateFns'
import { endOfDayTimestamp, lastModifiedDateSortItem, ownerIdSortItem, startOfDayTimestamp } from './constants'

export function toSavedFilters(obj) {
  const newObj = {}
  if (obj.query && obj.query.children) {
    newObj.query = {
      children: {
        combineAction: obj.query.children.combineAction,
        valuesList: obj.query.children.valuesList ? obj.query.children.valuesList.map((v) => {
          const {
            node = {
              item: {},
              valuesList: [],
              operation: undefined,
            }
          } = v
          return {
            node: {
              item: {
                name: node.item.name,
                type: node.item.type,
                source: node.item.source,
              },
              valuesList: node.valuesList,
              operation: node.operation,
              ...v.node._filter && { _filter: v.node._filter },
            }
          }
        }) : [],
      }
    }
  }
  return newObj
}

export function mapFiltersToQuery(filters, pipelineGridFields) {
  const children = {
    combineAction: SearchCombineAction.AND,
    valuesList: [],
  }

  forOwn(filters, (filter, fieldName) => {
    if (fieldName === 'owner.id') {
      const { selected = [] } = filter.data || {}
      const values = selected.map((option) => option.value)
      if (values.length > 0) {
        children.valuesList.push({
          node: {
            item: {
              ...ownerIdSortItem,
              cache: true,
            },
            valuesList: selected.map((option) => option.value),
            operation: FilterOperation.IN,
            _filter: filter,
          },
        })
      }
    } else {
      const field = pipelineGridFields[fieldName.toLowerCase()] || filter.field
      if (field && field.length > 0) {
        const fieldInstance = field[0]
        if (fieldInstance) {
          switch (filter.type) {
            case 'MultiSelectFilter': {
              const { selected = [] } = filter.data || {}
              const values = selected.map((option) => option.value)
              if (values.length > 0) {
                children.valuesList.push({
                  node: {
                    item: {
                      name: fieldInstance.name,
                      source: fieldInstance.source,
                      type: fieldInstance.type,
                    },
                    valuesList: selected.map((option) => option.value),
                    operation: FilterOperation.IN,
                    _filter: filter,
                  },
                })
              }
              break
            }
            case 'DateRangeFilter': {
              const { data = {} } = filter
              let { startDate = new Date(), endDate = new Date() } = data
              const { isTimestamp = false } = data
              if (isString(startDate) && startDate.length >= 10) {
                startDate = parseDate(startDate.substring(0, 10))
              }
              if (isString(endDate) && endDate.length >= 10) {
                endDate = parseDate(endDate.substring(0, 10))
              }
              children.valuesList.push({
                node: {
                  item: {
                    name: fieldInstance.name,
                    source: fieldInstance.source,
                    type: fieldInstance.type,
                  },
                  valuesList: [
                    `${format(startDate, 'yyyy-MM-dd')}${isTimestamp ? startOfDayTimestamp : ''}`,
                    `${format(endDate, 'yyyy-MM-dd')}${isTimestamp ? endOfDayTimestamp : ''}`
                  ],
                  operation: FilterOperation.BETWEENIN,
                  _filter: filter,
                },
              })
              break
            }
            case 'NumberRangeFilter': {
              const { min, max } = filter.data || {}
              if (min) {
                children.valuesList.push({
                  node: {
                    item: {
                      name: fieldInstance.name,
                      source: fieldInstance.source,
                      type: fieldInstance.type,
                    },
                    valuesList: [min],
                    operation: FilterOperation.GE,
                    _filter: filter,
                  },
                })
              }
              if (max) {
                children.valuesList.push({
                  node: {
                    item: {
                      name: fieldInstance.name,
                      source: fieldInstance.source,
                      type: fieldInstance.type,
                    },
                    valuesList: [max],
                    operation: FilterOperation.LE,
                    _filter: filter,
                  },
                })
              }
              break
            }
            default:
          }
        }
      } else if (fieldName === 'lastmodifieddate') {
        const { data = {} } = filter
        let { startDate = new Date(), endDate = new Date() } = data
        if (isString(startDate) && startDate.length >= 10) {
          startDate = parseDate(startDate.substring(0, 10))
        }
        if (isString(endDate) && endDate.length >= 10) {
          endDate = parseDate(endDate.substring(0, 10))
        }
        children.valuesList.push({
          node: {
            item: lastModifiedDateSortItem,
            valuesList: [`${format(startDate, 'yyyy-MM-dd')}${startOfDayTimestamp}`, `${format(endDate, 'yyyy-MM-dd')}${endOfDayTimestamp}`],
            operation: FilterOperation.BETWEENIN,
            _filter: filter,
          },
        })
      }
    }
  })

  return children.valuesList.length > 0 ? { children } : {}
}
