import React from 'react'
import { useRoutes } from '../../context/routes'
import Avatar from '../avatar/avatar'
import findIndex from 'lodash/findIndex'
import { Link } from 'react-router-dom'
import { useUserImageUrl } from '../../hooks/useUserImageUrl'

const PersonSearchResult = (props) => {
  const { person, closePopper, onFocus, onBlur, onMouseOver, onMouseOut, resultSelectors } = props
  const { id, name, photoUrl, hasChildren } = person

  const { userImageUrl } = useUserImageUrl(photoUrl)

  const { routes } = useRoutes()

  const link = hasChildren ? routes.userSummary.replace(':userId', id) : routes.rep.replace(':userId', id)

  function onClick(e) {
    window.analytics.track('Person Search Result Clicked', { action: name })
    closePopper()
  }

  function onKeyDown(e) {
    if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
      const currentIndex = findIndex(resultSelectors, (r) => r === `#searchResult-${id}`)
      if (currentIndex + 1 === resultSelectors.length) {
        const searchBox = document.querySelector('#searchBox')
        if (searchBox) {
          searchBox.focus()
        }
      } else {
        const next = document.querySelector(resultSelectors[currentIndex + 1])
        if (next) {
          next.focus()
        }
      }
      e.preventDefault()
    } else if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
      const currentIndex = findIndex(resultSelectors, (r) => r === `#searchResult-${id}`)
      if (currentIndex - 1 === -1) {
        const searchBox = document.querySelector('#searchBox')
        if (searchBox) {
          searchBox.focus()
        }
      } else {
        const prev = document.querySelector(resultSelectors[currentIndex - 1])
        if (prev) {
          prev.focus()
        }
      }
      e.preventDefault()
    }
  }

  return (
    <Link
      id={`searchResult-${id}`}
      to={link}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onKeyDown={onKeyDown}
      className="flex flex-row items-center px-6 py-3 border-t border-color-eaeaea focus:bg-gradient-white-to-blue focus:outline-none">
      <Avatar className="w-8 h-8 mr-5 pointer-events-none" imageUrl={userImageUrl} text={name.charAt(0)} isRound={true} />
      <div className="truncate pointer-events-none" style={{ maxWidth: 300 }}>{name}</div>
    </Link>
  )
}

export default PersonSearchResult
