import { grpcInvoke } from '../grpc'
import { NlgTestRequest } from '../protos/src/services/api/api_pb'
import {
  testNlgServiceRequested,
  testNlgServiceSuccess,
  testNlgServiceFailed,
} from '../actions/nlgService'

export const testNlgService = (template, dataJson, flags) => {
  return async (dispatch, getState) => {
    const request = new NlgTestRequest()
    request.setTemplate(template)
    request.setDatajson(dataJson)
    request.setFlags(flags)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(testNlgServiceRequested())
      },
      onSuccess: (obj) => {
        dispatch(testNlgServiceSuccess({ result: obj }))
      },
      onError: (err) => {
        dispatch(testNlgServiceFailed(err))
      },
      grpcMethod: 'testNlgService',
      debug: false,
    })
  }
}
