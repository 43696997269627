import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import Avatar from '../avatar/avatar'
import { resolveCompanyImageUrl } from '../../lib/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGroups } from '../../context/groups'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const DealCard = (props) => {
  const { index, opportunity } = props
  const { insightCount = 0 } = opportunity
  const { findGroupById } = useGroups()
  const user = findGroupById(opportunity.ownerId) || {}
  const companyImageUrl = resolveCompanyImageUrl(opportunity)

  const { routes } = useRoutes()

  function onClick() {
    window.analytics.track('Deal Card Clicked')
  }

  const { formatCurrency } = useFormatCurrency()

  const changeAmountLabel = useMemo(() => {
    const { changeAmount = 0 } = opportunity
    return changeAmount === 0 ? '' : formatCurrency(Math.abs(changeAmount))
  }, [opportunity, formatCurrency])

  return (
    <Link to={routes.deal.replace(':opportunityId', opportunity.id)} className={index === 0 ? 'ml-10' : ''} onClick={onClick}>
      <div className="card tr p-4 mr-4 hover:bg-gradient-white-to-blue" style={{ width: 325, height: 200 }}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Avatar className="w-10 h-10 mr-2" imageUrl={companyImageUrl} text={opportunity.name.charAt(0)} />
            <div className="">
              <div className="text-size-18px text-color-2e384d font-bold whitespace-nowrap truncate" style={{ maxWidth: 200 }}>{opportunity.name}</div>
              <div className="text-size-14px text-color-91959f">{user.name}</div>
            </div>
          </div>
          {insightCount > 0
            && (
              <div
                className="flex items-center justify-center mb-4 w-7 h-7 text-sm font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-gradient-green">
                {insightCount}
              </div>
            )}
        </div>

        <div className="flex items-center h-full" style={{ height: 70 }}>
          <div className="text-center">
            {opportunity.changeAmount !== 0 && (
              <div className="flex text-size-12px text-color-91959f">
                <div className="flex items-center justify-center">
                  <FontAwesomeIcon icon={opportunity.changeAmountIcon} size="xs" className="mr-1" color="#8097b1" />
                  <span style={{ paddingTop: 1 }}>{opportunity.changeAmountMovement}</span>
                </div>
              </div>
            )}
            <div className="text-size-16px text-color-2e384d">{changeAmountLabel}</div>
          </div>
        </div>

        <div className="flex items-end justify-between">
          <div>
            <div className="flex flex-row flex-nowrap items-center">
              <div className="w-2 h-2 mr-1 rounded-full flex-shrink-0" style={{ backgroundColor: opportunity.statusColor }} />
              <div className="text-size-12px text-color-91959f font-normal">{opportunity.statusLabel}</div>
            </div>
            <div className="text-size-24px text-color-2e384d font-bold leading-tight">{formatCurrency(opportunity.amount)}</div>
          </div>
          {opportunity.hasCloseDate && (
            <div className="text-right">
              <div className="text-size-15px text-color-2e384d">{opportunity.closeDateLabel}</div>
              {(opportunity.status !== 'lost' && opportunity.status !== 'won')
                ? <div className={`text-size-12px text-color-91959f ${opportunity.daysUntilCloseRelativeLabelColor}`}>{opportunity.daysUntilCloseRelativeLabel}</div> : null}
            </div>
          )}
        </div>

      </div>
    </Link>
  )
}

export default DealCard
