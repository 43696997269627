import React from 'react'
import EmptyState from '../empty/emptyState'
import { AccountCircle } from '@getoutreach/react-icons'
import GroupHeaderControl from '../header/groupHeaderControl'

const RepHome = (props) => {
  return (
    <>
      <GroupHeaderControl
        showReps={true}
        title="Select a Team Member"
        showSearch={false} />

      <div className="main-body">

        <div className="flex justify-center my-10">
          <EmptyState
            iconControl={<AccountCircle htmlColor="#5951FF" transform="scale(2.0)" />}
            header="Select a Team Member"
            subHeader="Pick a team member above to view their performance" />
        </div>

      </div>
    </>
  )
}

export default RepHome
