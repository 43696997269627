import React, { useCallback } from 'react'
import { useCanvas } from '../../context/canvas'
import { useCanvasItem } from '../../context/canvasItem'
import { useRealtimeView } from '../../context/realtimeView'
import { copyToClipboard } from '../../lib/clipboard'

const RealtimeViewDebugMenuItem = ({ closeMenu }) => {
  const { canvas } = useCanvas()
  const { canvasTile } = useCanvasItem()
  const { response } = useRealtimeView()

  const onClick = useCallback(() => {
    const data = {
      canvas,
      canvasTile,
      runRealtimeQueriesResponse: response,
      tileKey: canvasTile?.tileKey
    }
    copyToClipboard(data, true)
    closeMenu()
  }, [closeMenu, canvas, canvasTile])

  return (
    <button onClick={onClick} className="px-4 py-2 text-left focus:outline-none">
      <div className="text-color-151d49 text-size-16px font-weight-500">Copy Debug Data</div>
    </button>
  )
}

export default RealtimeViewDebugMenuItem
