import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UserSideMenuItem from './userSideMenuItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRoutes } from '../../context/routes'
import has from 'lodash/has'
import sortBy from 'lodash/sortBy'
import { BasicStatus } from '../../grpc/enums'
import { useUserPrefs } from '../../context/userPrefs'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import classNames from 'classnames'

const GroupMenuItem = (props) => {
  const {
    initOpen = false,
    showOpen = false,
    menu = {},
    level = 0,
    style = {},
    showReps,
    teamsSelectable,
    close,
    baseOptionId,
  } = props

  const { routes } = useRoutes()

  const { group, childrenList = [], membersList = [] } = menu

  const { savePref, getPref } = useUserPrefs()

  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames } = useNearlineFeatures()

  const accordionRef = useRef()
  const [isOpen, setIsOpen] = useState(initOpen || showOpen)
  const nextLevel = level + 1

  const membersListSorted = useMemo(() => {
    return sortBy(membersList, 'firstName').filter(({ status }) => status === BasicStatus.ACTIVE)
  }, [membersList])

  const hasChildren = useMemo(() => {
    return childrenList.length > 0 || membersListSorted.length > 0
  }, [childrenList, membersListSorted])
  const history = useHistory()

  const showToggle = useMemo(() => {
    return showReps && (hasContent || membersList.length > 0)
  }, [hasContent, showReps, membersList.length > 0])

  const onRepClick = useCallback((rep) => {
    if (!isAccessNearlineCheckReady) {
      return
    }
    window.analytics.track('headerMenu.repClicked', { action: rep.name })
    if (canAccessNearlineFeature({ featureName: featureNames.performance })) {
      history.push(routes.performanceByUser.replace(':userId', rep.id))
    } else {
      history.push(routes.rep.replace(':userId', rep.id))
    }
    close?.()
  }, [isAccessNearlineCheckReady, canAccessNearlineFeature, close])

  const onGroupClick = useCallback(() => {
    if (teamsSelectable && group) {
      window.analytics.track('headerMenu.teamClicked', { action: group.name })
      history.push(routes.userSummary.replace(':userId', group.id))
      close?.()
    }
  }, [teamsSelectable, close, group])

  const openPanel = useCallback(() => {
    setIsOpen(true)
    accordionRef.current && accordionRef.current.classList.add('open')
  }, [accordionRef])

  const closePanel = useCallback(() => {
    setIsOpen(false)
    accordionRef.current && accordionRef.current.classList.remove('open')
  }, [accordionRef])

  const togglePanel = useCallback((io) => {
    const openMenus = getPref('groupMenu', 'openMenus') || {}

    if (io) {
      closePanel()
      delete openMenus[group.id]
    } else {
      openPanel()
      openMenus[group.id] = true
    }
    savePref('groupMenu', 'openMenus', openMenus)
  }, [isOpen, accordionRef, savePref, getPref])

  const hasContent = useMemo(() => {
    return (showReps)
      ? !!childrenList.length || !!membersListSorted.length
      : !!childrenList.length
  }, [childrenList, membersListSorted, showReps])

  const renderAccordionPanel = useMemo(() => {
    const open = isOpen || showOpen

    if (!open) {
      return false
    }

    return open && hasContent
  }, [
    isOpen,
    showOpen,
    hasContent])

  useEffect(() => {
    if (!group) return
    const openMenus = getPref('groupMenu', 'openMenus') || {}
    if (has(openMenus, group.id)) {
      openPanel()
    }
  }, [group, getPref])

  return (
    <div style={style}>
      {group?.id && (
        <div
          ref={accordionRef}
          className={classNames('w-full accordion hidden md:inline-block select-none', { open: showReps })}>
          <UserSideMenuItem
            key={`UserSideMenuItem-${group.id}`}
            isBaseOption={baseOptionId === group.id}
            showImage={false}
            user={{
              ...group,
              hasChildren
            }}
            onClick={teamsSelectable && onGroupClick} />
          {showToggle && (
            <button
              role="menuitem"
              tabIndex={-1}
              type="button"
              className="relative float-right z-20 cursor-pointer select-none focus:outline-none mr-4"
              style={{ width: 50, height: 52, marginTop: -52 }}
              onClick={() => togglePanel(isOpen)}>
              <FontAwesomeIcon icon="chevron-down" className="accordion-icon" color="#a6b2cf" />
            </button>
          )}
        </div>
      )}
      {renderAccordionPanel && (
        <div
          className="accordion-panel"
          style={{ minHeight: 52, marginLeft: nextLevel * 10 }}>
          {childrenList.map((childList) => (
            <GroupMenuItem
              key={`GroupMenuChild-${childList.group.id}`}
              baseOptionId={baseOptionId}
              showReps={showReps}
              teamsSelectable={teamsSelectable}
              menu={childList}
              close={close}
              initOpen={showReps}
              showOpen={!showReps || membersList.length > 0 || showOpen}
              level={nextLevel} />
          ))}
          {showReps && membersListSorted.map((member) => (
            <UserSideMenuItem
              key={`UserSideMenuItem-${member.id}`}
              showImage={true}
              user={member}
              onClick={onRepClick} />
          ))}
        </div>
      )}
    </div>
  )
}

export default GroupMenuItem
