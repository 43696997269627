import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cardScrollViewSettings } from '../../constants/cardScrollViewSettings'
import classNames from 'classnames'
import { useRepRatings } from '../../context/repRatings'
import GenericErrorBoundary from '../errors/genericErrorBoundary'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import { useCanvas } from '../../context/canvas'
import { Skeleton } from '@material-ui/lab'

const StrengthsAndWeaknesses = (props) => {
  const { title, subTitle } = props

  const { canvasKey, tenantStandardParams } = useCanvas()

  const { userId, isFetching, repRatings } = useRepRatings()

  const count = useMemo(() => {
    return repRatings.length
  }, [repRatings])

  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [enableNextBtn, setEnableNextBtn] = useState(false)
  const scrollViewRef = useRef({})
  const [canShowNextPrevBtns, setCanShowNextPrevBtns] = useState(false)

  const getNearestCardIndex = useCallback(() => {
    if (scrollViewRef && scrollViewRef.current) {
      const { scrollLeft } = scrollViewRef.current
      return Math.round(scrollLeft / cardScrollViewSettings.cardOffsetWidth)
    } else {
      return 0
    }
  }, [scrollViewRef.current])

  const scrollToCardIndex = useCallback((index) => {
    if (scrollViewRef && scrollViewRef.current) {
      scrollViewRef.current.scroll({ left: index * cardScrollViewSettings.cardOffsetWidth })
    }
  }, [scrollViewRef.current])

  const debounceScrollEnded = debounce(scrollToCardIndex, 750)

  const handleScroll = useCallback((e) => {
    if (scrollViewRef && scrollViewRef.current) {
      const { scrollLeft, offsetWidth, scrollWidth } = scrollViewRef.current
      const enableNext = offsetWidth + scrollLeft < scrollWidth
      setEnablePrevBtn(scrollLeft > 0)
      setEnableNextBtn(enableNext)
      if (enableNext) {
        debounceScrollEnded(getNearestCardIndex())
      }
    }
  }, [scrollViewRef.current])

  useEffect(() => {
    if (scrollViewRef.current) {
      setCanShowNextPrevBtns(!isFetching && count > 0)
      handleScroll()
    }
  }, [isFetching, count, scrollViewRef.current])

  const prev = useCallback(() => {
    window.analytics.track('Strength Weakness Back')
    scrollToCardIndex(getNearestCardIndex() - 1)
  }, [])

  const next = useCallback(() => {
    window.analytics.track('Strength Weakness Forward')
    scrollToCardIndex(getNearestCardIndex() + 1)
  }, [])

  const sharedBtnClasses = 'flex items-center bg-color-ffffff rounded-full cursor-pointer pointer-events-auto'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, '-mr-13', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  const cards = useMemo(() => {
    return repRatings.map((r, i) => {
      const { gmlResponse } = r
      const gmlInstanceId = `gml-${userId}-${canvasKey}-${r.queryKey}-${i}`
      return (
        <div
          key={`card-${i}`}
          className="p-4 ml-4 first:ml-10 rounded-lg border border-color-2e5bff-08 flex-none bg-gradient-blue-to-white"
          style={{ width: 325, height: 220 }}>
          <GenericErrorBoundary
            className="mt-6"
            title="Error"
            subTitle="Error rendering rating">
            <GMLRenderer
              tree={gmlResponse}
              debug={false}
              useContentType={false}
              channel="web"
              size="xs"
              registeredData={{
                gmlInstanceId,
                ...tenantStandardParams
              }}
            />
          </GenericErrorBoundary>
        </div>
      )
    })
  }, [repRatings])

  return (
    <div className="relative">

      {canShowNextPrevBtns && (
        <div className="-ml-10 -mr-6 z-20 absolute flex justify-between w-full pointer-events-none select-none">
          <div className={prevBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={prev}>
            <FontAwesomeIcon icon="chevron-left" size="2x" color="#c9ced0" className="mx-auto" />
          </div>
          <div className={nextBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={next}>
            <FontAwesomeIcon icon="chevron-right" size="2x" color="#c9ced0" className="mx-auto" />
          </div>
        </div>
      )}

      <div className="-ml-10 -mr-6">
        {title && <h1 className="ml-10">{title}</h1>}
        {subTitle && <div className="ml-10 text-size-14px text-color-4e5d7e">{subTitle}</div>}
        <div ref={scrollViewRef}
          className="mt-3 pb-2 inline-flex max-w-full overflow-y-hidden no-scrollbar"
          style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}
          onScroll={handleScroll}>
          {isFetching ? (
            <>
              <div className="ml-4 first:ml-10 rounded-lg border border-color-2e5bff-08 flex-none overflow-hidden">
                <Skeleton width={325} height={220} variant="rect" animation="wave" />
              </div>
              <div className="ml-4 first:ml-10 rounded-lg border border-color-2e5bff-08 flex-none overflow-hidden">
                <Skeleton width={325} height={220} variant="rect" animation="wave" />
              </div>
              <div className="ml-4 first:ml-10 rounded-lg border border-color-2e5bff-08 flex-none overflow-hidden">
                <Skeleton width={325} height={220} variant="rect" animation="wave" />
              </div>
            </>
          ) : cards}
        </div>
      </div>

    </div>
  )
}

export default StrengthsAndWeaknesses

const nextPrevBtnStyle = {
  width: '48px',
  height: '48px',
  marginTop: 160,
  fontSize: '13px',
  border: '1px solid rgb(227, 232, 250)',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
}
