import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useRoutes } from '../../context/routes'
import { apiRequestedSelector, apiSuccessSelector, featureSettingSelector } from '../../selectors'
import { featureNames } from '../../constants/featureNames'
import { getFeatureSettingsKey } from '../../actions/settingsService'
import { clearApiActivityByKey } from '../../actions'
import {
  getForecastSimulationsAvailableKey,
  getForecastSimulationLevelOptionsKey
} from '../../actions/reportingService'

const ScenarioPlannerContainer = (props) => {
  const { children } = props

  const dispatch = useDispatch()

  const { routes } = useRoutes()

  const scenarioPlannerFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.scenarioPlanner }))
  const apiRequested = useSelector((state) => apiRequestedSelector(state, getFeatureSettingsKey))
  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getFeatureSettingsKey))

  useEffect(() => {
    return () => {
      dispatch(clearApiActivityByKey(getForecastSimulationsAvailableKey))
      dispatch(clearApiActivityByKey(getForecastSimulationLevelOptionsKey))
    }
  }, [])

  if (apiRequested) {
    return <></>
  } else if (apiSuccess && scenarioPlannerFeature && scenarioPlannerFeature.payload.enabled) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return <Redirect to={routes.summary} />
  }
}

export default ScenarioPlannerContainer
