import React, { useMemo } from 'react'
import { CanvasProvider } from '../../context/canvas'
import { canvasKey } from './constants'
import { RepRatingsProvider } from '../../context/repRatings'
import StrengthsAndWeaknesses from '../signals/strengthsAndWeaknesses'
import { useAuth } from '../../context/auth'

const RepStrengthsAndWeaknessesCanvas = (props) => {
  const { userId } = props
  const { actingUserId } = useAuth()

  const headers = useMemo(() => {
    return { ...userId === actingUserId && { forceuser: true } }
  }, [userId, actingUserId])

  return (
    <CanvasProvider
      key={`CanvasProvider-${canvasKey.REP_RATINGS}-${userId}`}
      canvasKey={canvasKey.REP_RATINGS}
      userId={userId}>
      <RepRatingsProvider
        userId={userId}
        headers={headers}>
        <StrengthsAndWeaknesses
          key={`StrengthsAndWeaknesses-${userId}`}
          title="Strengths and Weaknesses"
          subTitle="Areas where you excel and where you have room to grow, often compared to your team." />
      </RepRatingsProvider>
    </CanvasProvider>
  )
}

export default RepStrengthsAndWeaknessesCanvas
