import React, { useCallback, useMemo } from 'react'
import { find } from 'lodash'
import { useSelector } from 'react-redux'
import { TimePeriod } from './constants'
import { startOfWeek, endOfWeek, fromUnixTime, format } from 'date-fns'
import { useFormatValue } from '../fieldRenderers/hooks'

const OppUpdates = (props) => {
  const {
    selectedTimePeriod,
    opportunity,
    showUpdateDetails
  } = props

  const { formatValue } = useFormatValue()

  const changesList = useMemo(() => {
    const { diff = { changesList: [] } } = opportunity
    return diff.changesList
  }, [opportunity])

  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const fieldsList = useMemo(() => {
    const { opportunity = { fieldsList: [] } } = objectDefinitions
    return opportunity.fieldsList
  }, [objectDefinitions])

  const onViewClick = useCallback((change, date) => {
    showUpdateDetails && showUpdateDetails(change, date)
  }, [])

  const getDate = useCallback(() => {
    if (opportunity?.diff) {
      const date = fromUnixTime(opportunity.diff.targetTs.seconds)
      if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
        return `${format(startOfWeek(date), 'EEEE, LLL do')} - ${format(endOfWeek(date), 'EEEE, LLL do')}`
      } else {
        return format(date, 'h:mmaaa')
      }
    }
  }, [opportunity])

  const updatesList = useMemo(() => {
    return (
      <>
        <div className="mt-5 first:mt-1 mb-5 last:mb-1 leading-tight">
          <div className="text-size-14px text-color-818e93 font-weight-500">{getDate()}</div>
        </div>
        {changesList.length > 0 ? changesList.map((change, index) => {
          const { fieldName, newValue, oldValue } = change
          const fieldDef = find(fieldsList, (f) => f.key === fieldName.toLowerCase()) || { format: '' }
          const { format, label = fieldName } = fieldDef
          const formattedOldValue = formatValue(oldValue, format) || '--'
          const formattedNewValue = formatValue(newValue, format) || '--'

          return (
            <div key={`ActivityItem-${index}`} className="mt-5 first:mt-1 mb-5 last:mb-1 leading-tight">
              <div className="text-size-16px text-color-09242f font-weight-600 mt-1">{`${label} Change`}</div>
              <div className="leading-tight">
                {fieldName === 'nextStep'
                  ? (
                    <div className="mt-1 w-full">
                      <div className="truncate-block text-size-16px text-color-818e93 font-weight-600">{formattedNewValue}</div>
                      <div
                        onClick={() => onViewClick(change, opportunity.diff.targetTs)}
                        className="mt-2 text-size-13px text-color-2e5bff font-bold focus:outline-none cursor-pointer">
                        View Changes
                      </div>
                    </div>
                  )
                  : (
                    <div className="flex justify-start" style={{ alignItems: 'center' }}>
                      <div className="text-size-16px text-color-818e93 font-weight-600">{formattedOldValue}</div>
                      <div className="arrow mx-5" style={{ width: '30px' }} />
                      <div className="text-size-16px text-color-818e93 font-weight-600">{formattedNewValue}</div>
                    </div>
                  )}
              </div>
            </div>
          )
        })
          : <div className="text-size-16px text-color-09242f font-weight-600">...</div>}
      </>
    )
  }, [changesList, fieldsList, formatValue])

  return (
    <div className="px-4 py-3 overflow-auto" style={{ maxWidth: 600, maxHeight: 'calc(100vh * 0.5)' }}>
      {updatesList}
    </div>
  )
}

export default OppUpdates
