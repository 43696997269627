import { apiSuccessSelector, peopleContextOrderedByRankSelector } from '../../selectors'
import { clearApiActivityByKey, clearHeaderMetrics, clearKeyMetrics, clearOpportunities } from '../../actions'
import { getAllPeople } from '../../services/personService'
import { getHeaderMetricsByTeam, getKeyMetricsByTeam } from '../../services/signalService'
import { getHeaderMetricsByTeamKey, getKeyMetricsByTeamKey } from '../../actions/signalService'
import { getOpportunitiesByTeam } from '../../services/opportunityService'
import { getOpportunitiesByTeamKey } from '../../actions/opportunityService'
import { has } from 'lodash'
import { headerMetricsGroupFilterHeaders } from '../header/constants'
import { keyMetricsGroupFilterHeaders } from '../signals/constants'
import { useDebug } from '../../context/debug'
import { useDispatch, useSelector } from 'react-redux'
import { useGroups } from '../../context/groups'
import { useLocationData } from '../location/hooks'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useTenantInfo } from '../../context/tenantInfo'
import AnimatedLoader from '../loaders/animatedLoader'
import classNames from 'classnames'
import DealsSliderLegacy from '../deals/dealsSliderLegacy'
import ErrorBoundary from '../../gml/controls/errorBoundary'
import GroupHeaderControl from '../header/groupHeaderControl'
import Icon from '../icon'
import MetricsHeader from '../header/metricsHeader'
import MyTeamLegacy from './myTeamLegacy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TeamKeyMetrics from '../signals/teamKeyMetrics'
import MyTeamEmptyState from './myTeamEmptyState'

const ManagerSummaryLegacy = () => {
  const { debug } = useDebug()

  const { location, params } = useLocationData()
  const { key } = location
  const { userId } = params

  const { tenantInfo } = useTenantInfo()

  const { isFetching, findGroupById, getSubTreesForId, subTrees } = useGroups()

  const { loggedInUser, loggedInUserId } = useLoggedInUser()

  const teamOrdered = useSelector(peopleContextOrderedByRankSelector)

  const user = findGroupById(userId)

  const id = useMemo(() => {
    const { teamId, groupViewerIdsList } = loggedInUser
    if (!userId && (teamId || groupViewerIdsList.length)) {
      return groupViewerIdsList[0] ?? teamId
    }
    return userId || loggedInUserId
  }, [userId, loggedInUserId])

  const showTopTen = useMemo(() => {
    const userSubTrees = getSubTreesForId(id)
    if (userSubTrees && userSubTrees.childrenList.length) {
      return true
    }
    if (!userSubTrees && subTrees.length) {
      const treesReduced = subTrees.reduce((p, v) => {
        const { childrenList = [], membersList = [] } = v
        return {
          childrenList: [
            ...p.childrenList || [],
            ...childrenList
          ]
        }
      }, {})
      if (treesReduced.childrenList.length) {
        return true
      }
    }
    return false
  }, [id, userId, subTrees, getSubTreesForId])

  const userContext = useMemo(() => {
    return userId ? user : loggedInUser
  }, [userId, user, loggedInUser])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPeople(id))
  }, [dispatch, id])

  useEffect(() => {
    if (!has(tenantInfo, 'currencyCode')) {
      return
    }

    dispatch(clearApiActivityByKey(getOpportunitiesByTeamKey))
    dispatch(clearOpportunities())
    dispatch(getOpportunitiesByTeam(0, 20, [{ key: 'sort', value: 'insightCount DESC' }], userId))

    dispatch(clearApiActivityByKey(getKeyMetricsByTeamKey))
    dispatch(clearKeyMetrics())
    dispatch(getKeyMetricsByTeam(0, -1, keyMetricsGroupFilterHeaders, id, true, false, id))

    dispatch(clearApiActivityByKey(getHeaderMetricsByTeamKey))
    dispatch(clearHeaderMetrics())
    dispatch(getHeaderMetricsByTeam(0, -1, headerMetricsGroupFilterHeaders, id, true, id))
  }, [id, tenantInfo])

  const oppsSuccess = useSelector((state) => apiSuccessSelector(state, getOpportunitiesByTeamKey))
  const teamMetricsSuccess = useSelector((state) => apiSuccessSelector(state, getKeyMetricsByTeamKey))
  const headerMetricsSuccess = useSelector((state) => apiSuccessSelector(state, getHeaderMetricsByTeamKey))

  const ready = useMemo(() => {
    return oppsSuccess && teamMetricsSuccess && headerMetricsSuccess
  }, [id, oppsSuccess, teamMetricsSuccess, headerMetricsSuccess])

  const pageTitle = useMemo(() => {
    if (!isFetching && id) {
      const { teamId } = loggedInUser
      const teamName = findGroupById(id)?.name ?? ''

      if (teamId && teamId === id) {
        return teamName ? `${teamName} (Your Team)` : 'Your Team'
      }

      return teamName ?? ''
    }
    return ''
  }, [isFetching, userId, loggedInUser, findGroupById])

  const myTeamHeader = useMemo(() => {
    if (showTopTen) {
      return 'Top 10'
    }
    if (userContext) {
      const { firstName, name } = userContext
      if (!userId) {
        return 'My Team'
      } else if (firstName) {
        return `${firstName}'s Team`
      } else if (name) {
        return `${name}'s Team`
      }
    }
    return ''
  }, [userId, userContext, showTopTen])

  const header = useMemo(() => {
    return (
      <>
        {userContext && (
          <ErrorBoundary>
            <MetricsHeader
              key={`MetricsHeader-${key}`}
              user={userContext}
              level="group"
              ready={ready}
              debug={debug} />
          </ErrorBoundary>
        )}
      </>
    )
  }, [userContext, key, debug, ready])

  const [hideTeam, setHideTeam] = useState(false)

  const toggleHideTeam = useCallback(() => {
    setHideTeam(!hideTeam)
  }, [hideTeam])

  return (
    <>
      <GroupHeaderControl
        title={pageTitle}
        showReps={false}
        header={header}
        teamsSelectable={true}
      />
      <div className="main-body" style={{ minHeight: '100vh' }}>
        {!ready
          ? (
            <AnimatedLoader
              className="mt-16"
              title="Loading Summary"
              subTitle="Please wait..."
              maxWidth={300} />
          )
          : (
            <>
              <DealsSliderLegacy key={`DealsSliderLegacy-${id}`} />
              <div className="flex mr-4 mt-15">
                <div className={classNames('w-full', { 'w-8/12': !hideTeam })}>
                  <TeamKeyMetrics key={`TeamKeyMetrics-${key}-${hideTeam}`} id={id} />
                </div>
                {hideTeam && (
                  <div className={classNames('absolute right-0 px-16')}>
                    <div onClick={toggleHideTeam} className="flex flex-col justify-center cursor-pointer">
                      <Icon iconName="users" size="lg" className="mx-auto" />
                      <a className="text-color-4e5d7e text-size-12px font-weight-500 cursor-pointer my-2 mx-auto whitespace-nowrap">Show Team</a>
                    </div>
                  </div>
                )}
                <div className={classNames('ml-8', { hidden: hideTeam }, { 'block w-4/12': !hideTeam })}>
                  <MyTeamLegacy
                    key={`MyTeam-${id}`}
                    header={myTeamHeader}
                    userId={id}
                    showTopTen={showTopTen}
                    toggleHideTeam={toggleHideTeam}>
                    {!teamOrdered.length && (
                      <MyTeamEmptyState />
                    )}
                  </MyTeamLegacy>
                </div>
              </div>
            </>
          )}
      </div>
    </>
  )
}

export default ManagerSummaryLegacy
