import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { claimNames } from '../constants/claimNames'
import { setImpersonationHeaders } from '../grpc'
import { getClaims } from '../lib/auth0'
import { useRoutes } from './routes'

const AuthContext = React.createContext()

export function GetTenantRegion() {
  let isImpersonatingUser
  let impersonatingTenant
  try {
    isImpersonatingUser = JSON.parse(localStorage.getItem('isImpersonatingUser'))
    impersonatingTenant = JSON.parse(localStorage.getItem('impersonatingTenant'))
  } catch {
    // ignore
  }
  const region = impersonatingTenant?.region ?? ''  
  if (isImpersonatingUser && region) {
    return region
  } else {
    const claims = getClaims()
    return claims[claimNames.tenantRegion] || ''
  }
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [impersonatingTenant, setImpersonatingTenant] = useState(undefined)
  const [impersonatingUser, setImpersonatingUser] = useState(undefined)
  const [isImpersonatingUser, setIsImpersonatingUser] = useState(false)
  const [demo, setDemo] = useState(false)
  const { routeTenantId } = useRoutes()

  const stopImpersonating = useCallback(() => {
    setImpersonationHeaders({})
    setImpersonatingTenant(undefined)
    setImpersonatingUser(undefined)
    setIsImpersonatingUser(false)
    setDemo(false)
    localStorage.removeItem('impersonatingTenant')
    localStorage.removeItem('impersonatingUser')
    localStorage.removeItem('isImpersonatingUser')
    localStorage.removeItem('demo')
  }, [])

  const persistImpersonation = ({ tenant, user, demo }) => {
    localStorage.setItem('impersonatingTenant', JSON.stringify(tenant))
    localStorage.setItem('impersonatingUser', JSON.stringify(user))
    localStorage.setItem('isImpersonatingUser', true)
    if (demo) {
      localStorage.setItem('demo', true)
    } else {
      localStorage.removeItem('demo')
    }
  }

  const startImpersonating = useCallback(({ tenant, user, demo }) => {
    setImpersonatingTenant(tenant)
    setImpersonatingUser(user)
    setIsImpersonatingUser(true)
    setDemo(demo)
    setImpersonationHeaders({
      ...tenant && { ImpersonateTenantId: tenant.id },
      ...user && { ImpersonateUserId: user.id },
      ...demo && { demo: true },
    })
  }, [])

  const resetAuth = useCallback(() => {
    setIsAuthenticated(false)
    stopImpersonating()
  }, [])

  useEffect(() => {
    if (routeTenantId) {
      setIsImpersonatingUser(true)
      setImpersonatingTenant({ id: routeTenantId, name: '' })
      return
    }
    try {
      const isImpersonatingUser = localStorage.getItem('isImpersonatingUser')
      if (isImpersonatingUser) {
        const tenant = JSON.parse(localStorage.getItem('impersonatingTenant'))
        const user = JSON.parse(localStorage.getItem('impersonatingUser'))
        const demo = JSON.parse(localStorage.getItem('demo'))
        setIsImpersonatingUser(true)
        setImpersonatingTenant(tenant)
        setImpersonatingUser(user)
        setDemo(demo)
        setImpersonationHeaders({
          ...tenant && { ImpersonateTenantId: tenant.id },
          ...user && { ImpersonateUserId: user.id },
          ...demo && { demo: true },
        })
      } else {
        stopImpersonating()
      }
    } catch (err) {
      stopImpersonating()
    }
  }, [routeTenantId])

  const tenantId = useMemo(() => {
    const claims = getClaims()
    return claims[claimNames.tenantId] || ''
  }, [isAuthenticated])

  const tenantRegion = useMemo(() => {
    const claims = getClaims()
    return claims[claimNames.tenantRegion] || ''
  }, [isAuthenticated])

  const personId = useMemo(() => {
    const claims = getClaims()
    return claims[claimNames.personId] || ''
  }, [isAuthenticated])

  const actingTenantId = useMemo(() => {
    if (isImpersonatingUser && impersonatingTenant && impersonatingTenant.id) {
      return impersonatingTenant.id
    } else {
      return tenantId
    }
  }, [isAuthenticated, isImpersonatingUser, impersonatingTenant, tenantId])

  const actingTenantRegion = useMemo(() => {
    if (isImpersonatingUser && impersonatingTenant && impersonatingTenant.region) {
      return impersonatingTenant.region
    } else {
      return tenantRegion
    }
  }, [isAuthenticated, isImpersonatingUser, impersonatingTenant, tenantId, tenantRegion])

  const actingUserId = useMemo(() => {
    if (isImpersonatingUser && impersonatingUser && impersonatingUser.id) {
      return impersonatingUser.id
    } else {
      return personId
    }
  }, [isAuthenticated, isImpersonatingUser, impersonatingUser, personId])

  const contextValue = useMemo(() => {
    return {
      isAuthenticated,
      setIsAuthenticated,
      tenantId,
      personId,
      actingTenantId,
      actingTenantRegion,
      actingUserId,
      isImpersonatingUser,
      persistImpersonation,
      demo,
      impersonatingUser,
      impersonatingTenant,
      resetAuth,
      stopImpersonating,
      startImpersonating,
    }
  }, [isAuthenticated, actingTenantId, actingUserId, persistImpersonation, demo, impersonatingUser, impersonatingTenant])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
