import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import { getLinecapSize, renderBarLabels, renderAxisBarLabels, renderAxisDataLabels, renderGroupBarLabels, getMinMax } from '../lib/gChart'
import { getTextWidth } from '../lib/text'
import { HorizontalBarChartConfig, VerticalBarChartConfig, barChartConfigPropTypes } from '../config/barChartConfig'
import ConditionalWrapper from './conditionalWrapper'
import Tooltip from './tooltip'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flattenDeep from 'lodash/flattenDeep'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import merge from 'lodash/merge'
import sum from 'lodash/sum'
import { controlTypes, createTooltipContent, getPlugins } from '../renderer/gmlRenderer'
import { BarChartFormatterError } from '../../errors'
import { seriesColors, getSeriesColor } from '../colors'
import { pluginTypes } from '../plugins'
import useRefResize from '../../hooks/useRefResize'

const BarChart = (props) => {
  const {
    getRendererProps,
    config = {},
    name,
    styleNames,
    childIndex,
    hide = false,
    series = [],
    orientation,
    grouped = false,
    stacked = false,
    barLabels = false,
    barLabelFormatter,
    axisBarLabels = false,
    axisBarLabelFormatter,
    axisDataLines = false,
    axisDataLabels = false,
    axisDataLabelFormatter,
    groupBarLabels = false,
    groupBarLabelFormatter,
    tooltips = false,
    colors,
    barWidth,
    barMargin,
    stackMargin,
    groupMargin,
    height,
    minData,
    maxData,
  } = props

  PropTypes.checkPropTypes(BarChart.propTypes, props, 'prop', 'BarChart')

  const containerRef = useRef()
  const svgRef = useRef()
  const [svgWidth, setSvgWidth] = useState(0)
  const [svgHeight, setSvgHeight] = useState(0)

  const handleResize = useCallback(({ width }) => {
    setSvgWidth(width)
  }, [])

  useRefResize(containerRef, handleResize)

  const mergedConfig = useMemo(() => {
    const c = cloneDeep(orientation === 'horizontal' ? HorizontalBarChartConfig : VerticalBarChartConfig)
    if (stacked) {
      c.bar.style = c.bar.stack.style
    } else if (grouped) {
      c.bar.style = c.bar.group.style
    }
    merge(c, config)

    if (orientation === 'vertical') {
      if (height) {
        setSvgHeight(height)
        c.height = height - (c.padding.top + c.padding.bottom)
      } else {
        setSvgHeight(c.height)
        c.height -= (c.padding.top + c.padding.bottom)
      }
    }

    // console.log('mergedConfig', c)
    return c
  }, [])

  const linecapSize = useMemo(() => {
    return getLinecapSize(mergedConfig.bar.style)
  }, [mergedConfig])

  const seriesMinMax = useMemo(() => {
    const minMax = getMinMax(series)
    return {
      min: minData || minMax.min,
      max: maxData || minMax.max
    }
  }, [series])

  const getBounds = useCallback((max, min) => {
    const maxStr = String(maxData || max)
    const maxBoundDigit = Number(maxStr.charAt(0)) + 1
    const maxBound = Math.floor(Number(maxBoundDigit + maxStr.substr(1).replace(/[0-9]/g, '0')))

    let minBound = 0
    let minBoundDigit = 0
    if (min < 0) {
      const minStr = String(Math.abs(minData || min))
      minBoundDigit = Number(minStr.charAt(0)) + 1
      minBound = Math.floor(Number(minBoundDigit + minStr.substr(1).replace(/[0-9]/g, '0'))) * -1
    }
    return {
      maxBound,
      maxBoundDigit,
      minBound,
      minBoundDigit,
    }
  }, [])

  const axisData = useMemo(() => {
    let seriesDataMax
    let seriesDataMin
    if (stacked && grouped) {
      seriesDataMax = Math.ceil(maxBy(flattenDeep(series.map((s) => s.map((arr) => sum(filter(arr, (val) => val > 0))), (s) => s)), (v) => v))
      seriesDataMin = Math.ceil(minBy(flattenDeep(series.map((s) => s.map((arr) => sum(filter(arr, (val) => val < 0))), (s) => s)), (v) => v))
    } else if (stacked) {
      seriesDataMax = Math.ceil(maxBy(series.map((arr) => sum(filter(arr, (val) => val > 0))), (s) => s))
      seriesDataMin = Math.ceil(minBy(series.map((arr) => sum(filter(arr, (val) => val < 0))), (s) => s))
    } else {
      seriesDataMax = Math.ceil(seriesMinMax.max)
      seriesDataMin = Math.ceil(seriesMinMax.min)
    }

    let pluginMax = 0
    let pluginMin = 0
    // check the line marker value's against the max data in the series
    const plugins = getPlugins(getRendererProps)
    const lineMarker = find(plugins, (p) => p.type === pluginTypes.lineMarker)
    if (lineMarker) {
      const lineMarkerValue = get(lineMarker, 'config.value')
      if (isNumber(lineMarkerValue)) {
        if (lineMarkerValue > 0) pluginMax = lineMarkerValue
        else if (lineMarkerValue < 0) pluginMin = lineMarkerValue
      }
    }

    let max = Math.max(seriesDataMax, pluginMax)
    let min = Math.min(seriesDataMin, pluginMin)

    const bounds = getBounds(max, min)

    const data = []
    const negativeData = []

    let divisor = 1
    switch (bounds.maxBoundDigit) {
      case 2:
        divisor = 4
        break
      case 4:
        divisor = 8
        break
      case 3:
      case 6:
        divisor = 6
        break
      case 5:
      case 10:
        divisor = 5
        break
      case 7:
        divisor = 7
        break
      case 8:
        divisor = 8
        break
      case 9:
        divisor = 9
        break
      default:
    }
    const axisIncrement = bounds.maxBound / divisor

    for (let i = 0; i < divisor; i += 1) {
      const dataPoint = Math.floor(axisIncrement * i)
      data.push(dataPoint)
    }

    if (data.length > 0) {
      const lastDataPt = data[data.length - 1]
      if (seriesDataMax > pluginMax
        && seriesDataMax > lastDataPt && seriesDataMax < bounds.maxBound
        && Math.abs(bounds.maxBound - seriesDataMax) < Math.abs(data[data.length - 1], seriesDataMax)
      ) {
        data.push(bounds.maxBound)
      }

      if (seriesDataMax < pluginMax && lastDataPt > pluginMax) {
        data.pop()
      }

      if (axisDataLabels) {
        // check the max against the axis labels that need to render
        max = Math.max(max, data[data.length - 1])
      }
    }

    if (bounds.minBound < 0) {
      let val = axisIncrement * -1
      negativeData.push(val)
      while (val > bounds.minBound) {
        val += -axisIncrement
        negativeData.push(val)
      }

      if (axisDataLabels) {
        // check the min against the axis labels that need to render
        min = Math.min(min, negativeData[negativeData.length - 1])
      }
    }

    const d = {
      data,
      negativeData: negativeData.slice().reverse(),
      seriesDataMax,
      seriesDataMin,
      pluginMax,
      max,
      ...bounds,
      maxBound: max,
      minBound: min,
      axisIncrement,
      positivePerc: data.length === 0 ? 1 : (data.length / (data.length + negativeData.length)),
    }

    // console.log('axisData', d)
    return d

    // eslint-disable-next-line no-use-before-define
  }, [seriesMinMax, axisData])

  const measured = useMemo(() => {
    const c = mergedConfig
    const m = {
      barLabels: {},
      barLabelMaxWidth: 0,
      axisBarLabels: {},
      axisBarLabelMaxWidth: 0,
      axisDataLabels: [],
      axisDataLabelMaxWidth: 0,
      groupBarLabels: [],
      groupBarLabelMaxWidth: 0
    }

    function getMeasuredText(data, formatter, style, seriesIndex, dataIndex) {
      const { fontWeight, fontSize, fontFamily } = style
      let text = ''
      try {
        text = formatter ? formatter(data, seriesIndex, dataIndex) : `${seriesIndex}`
      } catch (err) {
        throw new BarChartFormatterError(formatter.toString(), err)
      }
      const width = getTextWidth(text, fontWeight, fontSize, fontFamily)
      return {
        text,
        width,
        data,
        seriesIndex,
        dataIndex
      }
    }

    if (grouped && stacked) {
      series.map((arr, seriesIndex) => {
        arr.map((group, groupIndex) => {
          if (barLabels) {
            const label = getMeasuredText(group, barLabelFormatter, c.barLabel.style, seriesIndex, groupIndex)
            m.barLabels[`${seriesIndex}-${groupIndex}`] = label
            m.barLabelMaxWidth = Math.max(m.barLabelMaxWidth, label.width)
          }
          if (axisBarLabels) {
            const label = getMeasuredText(group, axisBarLabelFormatter, c.axisBarLabel.style, seriesIndex, groupIndex)
            m.axisBarLabels[`${seriesIndex}-${groupIndex}`] = label
            m.axisBarLabelMaxWidth = Math.max(m.axisBarLabelMaxWidth, label.width)
          }
        })

        if (groupBarLabels) {
          const label = getMeasuredText(arr, groupBarLabelFormatter, c.groupBarLabel.style, seriesIndex, undefined)
          m.groupBarLabels[`${seriesIndex}`] = label
          m.groupBarLabelMaxWidth = Math.max(m.groupBarLabelMaxWidth, label.width)
        }
      })
    } else if (grouped) {
      series.map((arr, seriesIndex) => {
        arr.map((data, dataIndex) => {
          if (barLabels) {
            const label = getMeasuredText(data, barLabelFormatter, c.barLabel.style, seriesIndex, dataIndex)
            m.barLabels[`${seriesIndex}-${dataIndex}`] = label
            m.barLabelMaxWidth = Math.max(m.barLabelMaxWidth, label.width)
          }
          if (axisBarLabels) {
            const label = getMeasuredText(data, axisBarLabelFormatter, c.axisBarLabel.style, seriesIndex, dataIndex)
            m.axisBarLabels[`${seriesIndex}-${dataIndex}`] = label
            m.axisBarLabelMaxWidth = Math.max(m.axisBarLabelMaxWidth, label.width)
          }
        })

        if (groupBarLabels) {
          const label = getMeasuredText(arr, groupBarLabelFormatter, c.groupBarLabel.style, seriesIndex, undefined)
          m.groupBarLabels[`${seriesIndex}`] = label
          m.groupBarLabelMaxWidth = Math.max(m.groupBarLabelMaxWidth, label.width)
        }
      })
    } else {
      series.map((data, seriesIndex) => {
        if (barLabels) {
          const label = getMeasuredText(data, barLabelFormatter, c.barLabel.style, seriesIndex)
          m.barLabels[seriesIndex] = label
          if (!stacked || seriesIndex === series.length - 1) {
            m.barLabelMaxWidth = Math.max(m.barLabelMaxWidth, label.width)
          }
        }
        if (axisBarLabels) {
          const label = getMeasuredText(data, axisBarLabelFormatter, c.axisBarLabel.style, seriesIndex)
          m.axisBarLabels[seriesIndex] = label
          m.axisBarLabelMaxWidth = Math.max(m.axisBarLabelMaxWidth, label.width)
        }
      })
    }

    if (axisBarLabels) {
      m.axisBarLabelMaxWidth += c.axisBarLabel.offset.width
    }

    if (barLabels) {
      m.barLabelMaxWidth += c.barLabel.offset.width
    }

    if (groupBarLabels) {
      m.groupBarLabelMaxWidth += c.groupBarLabel.offset.width
    }

    if (axisDataLabels) {
      const { fontWeight, fontSize, fontFamily } = c.axisDataLabel
      const axisDataLabelData = [...axisData.negativeData, ...axisData.data]
      const len = axisDataLabelData.length
      const labels = axisDataLabelData.map((data, index) => {
        const text = axisDataLabelFormatter ? axisDataLabelFormatter(data, len, index) : `${data}`
        const width = getTextWidth(text, fontWeight, fontSize, fontFamily)
        return {
          text,
          width,
          data
        }
      })
      m.axisDataLabels = labels
      m.axisDataLabelMaxWidth = labels.length > 0 ? maxBy(labels, 'width').width + c.axisDataLabel.offset.width : 0
    }

    return m
  }, [svgWidth, mergedConfig, seriesMinMax])

  const chartRect = useMemo(() => {
    const rect = {
      x: mergedConfig.padding.left,
      y: mergedConfig.padding.top,
    }

    if (orientation === 'horizontal') {
      rect.width = svgWidth - (mergedConfig.padding.left + mergedConfig.padding.right)
      rect.height = 0 // calculated later

      if (axisBarLabels) {
        rect.x += measured.axisBarLabelMaxWidth
        rect.width -= measured.axisBarLabelMaxWidth
      }

      if (barLabels) {
        rect.width -= (measured.barLabelMaxWidth + mergedConfig.barLabel.offset.x + mergedConfig.barLabel.offset.width)
      }

      if (axisDataLabels) {
        rect.width -= measured.axisDataLabelMaxWidth
      }
    } else if (orientation === 'vertical') {
      rect.x = 0
      rect.width = 0 // calculated later
      rect.height = mergedConfig.height// - (mergedConfig.padding.top + mergedConfig.padding.bottom)
    }

    rect.width = Math.max(rect.width, 0)
    rect.height = Math.max(rect.height, 0)

    // console.log('chartRect', rect)
    return rect
  }, [svgWidth, mergedConfig, measured, axisData])

  const plotY = useCallback((data) => {
    let dataPercOfBounds = (data - axisData.minBound) / (axisData.maxBound - axisData.minBound)
    if (isNaN(dataPercOfBounds)) dataPercOfBounds = 0
    return chartRect.y + (1 - (dataPercOfBounds * chartRect.height)) + chartRect.height
  }, [axisData, chartRect])

  const barData = useMemo(() => {
    const b = []
    const chartRectOverride = {}
    let x = chartRect.x + mergedConfig.padding.left
    let y = chartRect.y + mergedConfig.padding.right

    if (grouped && stacked) {
      if (orientation === 'vertical') {
        const stackMarginLeft = get(stackMargin, 'left', mergedConfig.bar.stack.margin.left)
        const stackMarginRight = get(stackMargin, 'right', mergedConfig.bar.stack.margin.right)
        const groupMarginLeft = get(groupMargin, 'left', mergedConfig.bar.group.margin.left)
        const groupMarginRight = get(groupMargin, 'right', mergedConfig.bar.group.margin.right)

        series.map((arr, seriesIndex) => {
          x += groupMarginLeft

          arr.map((group, groupIndex) => {
            const negativeValues = filter(group, (val) => val < 0).map((val) => (val === -0.01 ? 0 : val))
            const negativeAccumulatedValues = negativeValues.slice().reverse().map((val, index) => (index === 0 ? val : sum(negativeValues.slice(0, index + 1)))).reverse()

            const positiveValues = filter(group, (val) => val >= 0)
            const positiveAccumulatedValues = positiveValues.map((val, index) => (index === 0 ? val : sum(positiveValues.slice(0, index + 1))))

            x += stackMarginLeft

            negativeAccumulatedValues.map((data, dataIndex) => {
              const y1 = plotY(data)
              const y2 = plotY(negativeAccumulatedValues[dataIndex + 1] || 0)
              const pt1 = { x, y: y1 }
              const pt2 = { x, y: y2 }

              const style = { ...mergedConfig.bar.style }
              if (typeof colors === 'string') {
                style.stroke = colors
              } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
                style.stroke = get(colors, `[${groupIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
              } else if (Array.isArray(colors)) {
                style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
              } else if (dataIndex >= seriesColors.length) {
                style.stroke = getSeriesColor(dataIndex)
              }

              if (isNumber(barWidth)) {
                style.strokeWidth = barWidth
              } else if (Array.isArray(barWidth)) {
                style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
              }

              b.push({
                data,
                pt1,
                pt2,
                style,
                seriesIndex,
                groupIndex,
                dataIndex,
                isFirst: dataIndex === 0,
                isLast: dataIndex === group.length - 1,
                isNegativeAxis: true,
              })
            }, [])

            y = plotY(0)
            positiveAccumulatedValues.map((data, index) => {
              const dataIndex = negativeAccumulatedValues.length + index
              const y1 = y
              const y2 = plotY(data)

              const pt1 = { x, y: y1 }
              const pt2 = { x, y: y2 }

              const style = { ...mergedConfig.bar.style }
              if (typeof colors === 'string') {
                style.stroke = colors
              } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
                style.stroke = get(colors, `[${groupIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
              } else if (Array.isArray(colors)) {
                style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
              } else if (dataIndex >= seriesColors.length) {
                style.stroke = getSeriesColor(dataIndex)
              }

              if (isNumber(barWidth)) {
                style.strokeWidth = barWidth
              } else if (Array.isArray(barWidth)) {
                style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
              }

              b.push({
                data,
                pt1,
                pt2,
                style,
                seriesIndex,
                groupIndex,
                dataIndex,
                isFirst: dataIndex === 0,
                isLast: dataIndex === group.length - 1,
                isNegativeAxis: false,
              })

              y = y2
            })

            x += stackMarginRight
          })

          x += groupMarginRight
        })
      }
    } else if (stacked) {
      const seriesSums = series.map((arr) => sum(arr))
      if (orientation === 'horizontal') {
        series.map((arr, seriesIndex) => {
          const marginTop = get(barMargin, 'top', mergedConfig.bar.stack.margin.top)
          const marginBottom = get(barMargin, 'bottom', mergedConfig.bar.stack.margin.bottom)

          x = chartRect.x
          y += marginTop

          const arrSum = seriesSums[seriesIndex]
          let sumPerc = arrSum / axisData.maxBound
          if (isNaN(sumPerc)) sumPerc = 0

          arr.map((data, dataIndex) => {
            let perc = data / arrSum
            if (isNaN(perc)) perc = 0

            const x1 = x
            const x2 = x1 + (perc * (chartRect.width * sumPerc))
            const pt1 = { x: x1, y }
            const pt2 = { x: Math.max(x1, x2), y }

            const style = { ...mergedConfig.bar.style }
            if (typeof colors === 'string') {
              style.stroke = colors
            } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
              style.stroke = get(colors, `[${seriesIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (Array.isArray(colors)) {
              style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (dataIndex >= seriesColors.length) {
              style.stroke = getSeriesColor(dataIndex)
            }

            if (isNumber(barWidth)) {
              style.strokeWidth = barWidth
            } else if (Array.isArray(barWidth)) {
              style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
            }

            b.push({
              data,
              pt1,
              pt2,
              style,
              seriesIndex,
              dataIndex,
              isFirst: dataIndex === 0,
              isLast: dataIndex === arr.length - 1
            })

            x = x2
          })

          y += marginBottom
        })
      } else if (orientation === 'vertical') {
        const marginLeft = get(barMargin, 'left', mergedConfig.bar.stack.margin.left)
        const marginRight = get(barMargin, 'right', mergedConfig.bar.stack.margin.right)
        const barAreaWidth = Math.max(measured.barLabelMaxWidth, measured.axisBarLabelMaxWidth, mergedConfig.bar.style.strokeWidth)

        series.map((arr, seriesIndex) => {
          const negativeValues = filter(arr, (val) => val < 0).map((val) => (val === -0.01 ? 0 : val))
          const negativeAccumulatedValues = negativeValues.slice().reverse().map((val, index) => (index === 0 ? val : sum(negativeValues.slice(0, index + 1)))).reverse()

          const positiveValues = filter(arr, (val) => val >= 0)
          const positiveAccumulatedValues = positiveValues.map((val, index) => (index === 0 ? val : sum(positiveValues.slice(0, index + 1))))

          x += marginLeft
          x += barAreaWidth / 2

          negativeAccumulatedValues.map((data, dataIndex) => {
            const y1 = plotY(data)
            const y2 = plotY(negativeAccumulatedValues[dataIndex + 1] || 0)
            const pt1 = { x, y: y1 }
            const pt2 = { x, y: y2 }

            const style = { ...mergedConfig.bar.style }
            if (typeof colors === 'string') {
              style.stroke = colors
            } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
              style.stroke = get(colors, `[${seriesIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (Array.isArray(colors)) {
              style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (dataIndex >= seriesColors.length) {
              style.stroke = getSeriesColor(dataIndex)
            }

            if (isNumber(barWidth)) {
              style.strokeWidth = barWidth
            } else if (Array.isArray(barWidth)) {
              style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
            }

            b.push({
              data,
              pt1,
              pt2,
              style,
              seriesIndex,
              dataIndex,
              isFirst: dataIndex === 0,
              isLast: dataIndex === arr.length - 1,
              isNegativeAxis: true,
            })
          }, [])

          y = plotY(0)
          positiveAccumulatedValues.map((data, index) => {
            const dataIndex = negativeAccumulatedValues.length + index
            const y1 = y
            const y2 = plotY(data)
            const pt1 = { x, y: y1 }
            const pt2 = { x, y: y2 }

            const style = { ...mergedConfig.bar.style }
            if (typeof colors === 'string') {
              style.stroke = colors
            } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
              style.stroke = get(colors, `[${seriesIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (Array.isArray(colors)) {
              style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (dataIndex >= seriesColors.length) {
              style.stroke = getSeriesColor(dataIndex)
            }

            if (isNumber(barWidth)) {
              style.strokeWidth = barWidth
            } else if (Array.isArray(barWidth)) {
              style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
            }

            b.push({
              data,
              pt1,
              pt2,
              style,
              seriesIndex,
              dataIndex,
              isFirst: dataIndex === 0,
              isLast: dataIndex === arr.length - 1,
              isNegativeAxis: false,
            })

            y = y2
          })

          x += barAreaWidth / 2
          x += marginRight
        })
      }
    } else if (grouped) {
      if (orientation === 'horizontal') {
        const groupMarginTop = get(groupMargin, 'top', mergedConfig.bar.group.margin.top)
        const groupMarginBottom = get(groupMargin, 'bottom', mergedConfig.bar.group.margin.bottom)

        series.map((arr, seriesIndex) => {
          y += groupMarginTop

          arr.map((data, dataIndex) => {
            const style = { ...mergedConfig.bar.style }
            if (typeof colors === 'string') {
              style.stroke = colors
            } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
              style.stroke = get(colors, `[${seriesIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (Array.isArray(colors)) {
              style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (dataIndex >= seriesColors.length) {
              style.stroke = getSeriesColor(dataIndex)
            }

            if (isNumber(barWidth)) {
              style.strokeWidth = barWidth
            } else if (Array.isArray(barWidth)) {
              style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
            }

            const linecapSize = getLinecapSize(style)
            const marginTop = get(barMargin, 'top', mergedConfig.bar.margin.top)
            const marginBottom = get(barMargin, 'bottom', mergedConfig.bar.margin.bottom)

            y += marginTop
            y += style.strokeWidth / 2

            let perc = seriesMinMax.max === 0 ? 0 : data / seriesMinMax.max
            if (isNaN(perc)) perc = 0

            const x1 = chartRect.x + linecapSize
            const x2 = chartRect.x + (perc * chartRect.width) - style.strokeWidth
            const pt1 = { x: x1, y }
            const pt2 = { x: Math.max(x1, x2), y }

            b.push({
              data,
              pt1,
              pt2,
              style,
              seriesIndex,
              dataIndex,
              isFirst: dataIndex === 0,
              isLast: dataIndex === arr.length - 1
            })

            y += style.strokeWidth / 2
            y += marginBottom
          })

          y += groupMarginBottom
        })
      } else if (orientation === 'vertical') {
        series.map((arr, seriesIndex) => {
          const groupWidth = (mergedConfig.bar.style.strokeWidth + mergedConfig.bar.margin.left + mergedConfig.bar.margin.right) * arr.length
          const barAreaWidth = Math.max(measured.barLabelMaxWidth, measured.axisBarLabelMaxWidth, groupWidth)
          x += mergedConfig.bar.group.margin.left
          x += barAreaWidth / 2

          arr.map((data, dataIndex) => {
            const style = { ...mergedConfig.bar.style }

            if (typeof colors === 'string') {
              style.stroke = colors
            } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
              style.stroke = get(colors, `[${seriesIndex}][${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (Array.isArray(colors)) {
              style.stroke = get(colors, `[${dataIndex}]`, getSeriesColor(dataIndex))
            } else if (dataIndex >= seriesColors.length) {
              style.stroke = getSeriesColor(dataIndex)
            }

            if (isNumber(barWidth)) {
              style.strokeWidth = barWidth
            } else if (Array.isArray(barWidth)) {
              style.strokeWidth = get(barWidth, `[${dataIndex}]`, style.strokeWidth)
            }

            const linecapSize = getLinecapSize(style)
            const marginLeft = get(barMargin, 'left', mergedConfig.bar.margin.left)
            const marginRight = get(barMargin, 'right', mergedConfig.bar.margin.right)

            x += marginLeft
            x += style.strokeWidth / 2

            let perc = seriesMinMax.max === 0 ? 0 : data / seriesMinMax.max
            if (isNaN(perc)) perc = 0

            const y1 = chartRect.y + chartRect.height - linecapSize
            const y2 = perc > 0 ? chartRect.y + ((1 - perc) * chartRect.height) + linecapSize : y1
            const pt1 = { x, y: y1 }
            const pt2 = { x, y: y2 }

            b.push({
              data,
              pt1,
              pt2,
              style,
              seriesIndex,
              dataIndex,
              isFirst: dataIndex === 0,
              isLast: dataIndex === arr.length - 1
            })

            x += style.strokeWidth / 2
            x += marginRight
          })

          x += barAreaWidth / 2
          x += mergedConfig.bar.group.margin.right
        })
      }
    } else {
      series.map((data, seriesIndex) => {
        let perc = seriesMinMax.max === 0 ? 0 : data / seriesMinMax.max
        if (isNaN(perc)) perc = 0
        if (orientation === 'horizontal') {
          const style = { ...mergedConfig.bar.style }
          if (typeof colors === 'string') {
            style.stroke = colors
          } else if (Array.isArray(colors)) {
            style.stroke = get(colors, `[${seriesIndex}]`, getSeriesColor(seriesIndex))
          } else if (seriesIndex >= seriesColors.length) {
            style.stroke = getSeriesColor(seriesIndex)
          }

          if (isNumber(barWidth)) {
            style.strokeWidth = barWidth
          } else if (Array.isArray(barWidth)) {
            style.strokeWidth = get(barWidth, `[${seriesIndex}]`, style.strokeWidth)
          }

          const linecapSize = getLinecapSize(style)
          const marginTop = get(barMargin, 'top', mergedConfig.bar.margin.top)
          const marginBottom = get(barMargin, 'bottom', mergedConfig.bar.margin.bottom)

          y += marginTop
          y += style.strokeWidth / 2

          const x1 = chartRect.x + linecapSize
          const x2 = chartRect.x + (perc * chartRect.width) - linecapSize
          const pt1 = { x: x1, y }
          const pt2 = { x: Math.max(x1, x2), y }

          b.push({
            data,
            pt1,
            pt2,
            style,
            seriesIndex,
            dataIndex: undefined,
            isFirst: seriesIndex === 0,
            isLast: seriesIndex === series.length - 1
          })

          y += style.strokeWidth / 2
          y += marginBottom
        } else if (orientation === 'vertical') {
          const marginLeft = get(barMargin, 'left', mergedConfig.bar.margin.left)
          const marginRight = get(barMargin, 'right', mergedConfig.bar.margin.right)

          const barAreaWidth = Math.max(measured.barLabelMaxWidth, measured.axisBarLabelMaxWidth, mergedConfig.bar.style.strokeWidth)
          x += marginLeft
          x += barAreaWidth / 2

          const y1 = chartRect.y + chartRect.height - linecapSize
          const y2 = chartRect.y + ((1 - perc) * chartRect.height) + linecapSize
          const pt1 = { x, y: y1 }
          const pt2 = { x, y: Math.min(y1, y2) }

          const style = { ...mergedConfig.bar.style }
          if (typeof colors === 'string') {
            style.stroke = colors
          } else if (Array.isArray(colors)) {
            style.stroke = get(colors, `[${seriesIndex}]`, getSeriesColor(seriesIndex))
          } else if (seriesIndex >= seriesColors.length) {
            style.stroke = getSeriesColor(seriesIndex)
          }

          if (isNumber(barWidth)) {
            style.strokeWidth = barWidth
          } else if (Array.isArray(barWidth)) {
            style.strokeWidth = get(barWidth, `[${seriesIndex}]`, style.strokeWidth)
          }

          b.push({
            data,
            pt1,
            pt2,
            style,
            seriesIndex,
            dataIndex: undefined,
            isFirst: seriesIndex === 0,
            isLast: seriesIndex === series.length - 1
          })

          x += barAreaWidth / 2
          x += marginRight
        }
      })
    }
    if (orientation === 'horizontal') {
      chartRectOverride.height = y - chartRect.y
    } else if (orientation === 'vertical') {
      chartRectOverride.width = x - chartRect.x
      let w = chartRectOverride.width
      if (axisDataLabels) {
        w += measured.axisDataLabelMaxWidth
      }
      const translateX = (svgWidth - w) / 2
      chartRectOverride.x = Math.max(chartRect.x + translateX, 0)
      b.map((bar, index) => {
        bar.pt1.x += Math.max(translateX, 0)
        bar.pt2.x += Math.max(translateX, 0)

        if (translateX < 0) {
          if (!stacked && !grouped) {
            bar.pt1.x -= index * ((mergedConfig.bar.margin.left + mergedConfig.bar.margin.right) * 0.7)
            bar.pt2.x -= index * ((mergedConfig.bar.margin.left + mergedConfig.bar.margin.right) * 0.7)
          }
        }
      })
    }
    return {
      bars: b,
      chartRectOverride
    }
  }, [mergedConfig, measured, chartRect, seriesMinMax, axisData, plotY])

  // the main config object
  const cfg = useMemo(() => {
    const c = mergedConfig
    c.props = props
    c.seriesMinMax = seriesMinMax
    c.measured = measured
    c.bars = barData.bars
    c.axisData = axisData
    c.chartRect = {
      ...chartRect,
      ...barData.chartRectOverride,
    }
    c.plotY = plotY

    PropTypes.checkPropTypes(barChartConfigPropTypes, c, 'prop', 'BarChart')

    // console.log('cfg', c)
    return c
  }, [svgWidth, mergedConfig, seriesMinMax, measured, chartRect, barData, axisData, plotY])

  useEffect(() => {
    if (orientation === 'horizontal') {
      setSvgHeight(cfg.chartRect.y + cfg.chartRect.height + cfg.padding.bottom + cfg.axisDataLabel.gutter.height)
    }
  }, [cfg])

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-barchart',
        `child-${childIndex}`,
        `orientation-${orientation}`,
        { grouped },
        { stacked },
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const renderBars = useCallback(() => {
    let interactive = false
    const { currentObject } = getRendererProps()
    const obj = find(get(currentObject, 'childrenList', get(currentObject, 'children', [])), (c) => c.type === controlTypes.TooltipContent)
    if (obj && find(get(obj, 'attributesList', get(obj, 'attributes', [])), (attr) => attr.key === 'interactive' && attr.value === '{true}')) {
      interactive = true
    }

    return barData.bars.map((bar, index) => {
      const { seriesIndex, groupIndex, dataIndex, data, pt1, pt2, style } = bar

      const result = tooltips ? createTooltipContent(getRendererProps, { data, _seriesIndex: seriesIndex, _groupIndex: groupIndex, _dataIndex: dataIndex }) : { tree: {} }
      const { tree, content } = result
      const disabled = get(find(tree.attributesList, (a) => a.key === 'disabled') || {}, 'value', false)

      return (
        <ConditionalWrapper
          key={`${name}-bar-${seriesIndex}-${groupIndex}-${dataIndex}`}
          condition={tooltips && content}
          wrapper={(children) => <Tooltip disabled={disabled} content={content} interactive={interactive}>{children}</Tooltip>}>
          <g className="g-barchart-bar-group focus:outline-none">
            <line
              className={`g-barchart-bar series-${seriesIndex} group-${groupIndex} data-${dataIndex} focus:outline-none`}
              x1={pt1.x}
              y1={pt1.y}
              x2={pt2.x}
              y2={pt2.y}
              style={{ ...style }} />
          </g>
        </ConditionalWrapper>
      )
    })
  }, [barData])

  const renderBarLabelsInternal = useCallback(() => {
    if (svgRef.current && barLabels) {
      return renderBarLabels(cfg)
    }
  }, [svgRef, cfg])

  const renderAxisBarLabelsInternal = useCallback(() => {
    if (svgRef.current && axisBarLabels) {
      return renderAxisBarLabels(cfg)
    }
  }, [svgRef, cfg])

  const renderAxisDataLabelsInternal = useCallback(() => {
    if (svgRef.current && axisDataLabels) {
      return renderAxisDataLabels(cfg)
    }
  }, [svgRef, cfg])

  const renderGroupBarLabelsInternal = useCallback(() => {
    if (svgRef.current && groupBarLabels) {
      return renderGroupBarLabels(cfg)
    }
  }, [svgRef, cfg])

  const renderPlugins = useCallback((size) => {
    if (svgRef.current) {
      const plugins = getPlugins(getRendererProps)
      const { functions } = getRendererProps()
      return plugins.map((p) => {
        merge(cfg, { plugins: { [p.type]: { config: p.config } }, functions })
        return (
          p.plugin(cfg)
        )
      })
    }
  }, [cfg])

  const [svgNeededWidth, setSvgNeededWidth] = useState(0)

  useEffect(() => {
    if (orientation === 'vertical') {
      let width = cfg.chartRect.width
      if (axisDataLabels) {
        width += measured.axisDataLabelMaxWidth + 20
      }
      setSvgNeededWidth(Math.max(svgWidth, width))
    } else {
      setSvgNeededWidth(svgWidth)
    }
  }, [cfg.chartRect.width, svgWidth, measured])

  return (
    <>
      <div ref={containerRef} {...attributes} style={{ height: svgHeight + 14 }}>
        <div style={{ width: svgWidth, height: svgHeight + 14, overflow: 'hidden' }}>
          <div style={{ width: svgWidth, overflowX: 'auto', overflowY: 'hidden' }}>
            <svg ref={svgRef} width={svgNeededWidth} height={svgHeight}>
              {props.debug && (
                <rect
                  x={cfg.chartRect.x}
                  y={cfg.chartRect.y}
                  width={Math.max(cfg.chartRect.width, 0)}
                  height={Math.max(cfg.chartRect.height, 0)}
                  style={{ fill: 'red', opacity: 0.5 }} />
              )}

              {svgWidth > 0 && svgHeight > 0
                && (
                  <>
                    {renderAxisDataLabelsInternal()}
                    {renderBars()}
                    {renderBarLabelsInternal()}
                    {renderAxisBarLabelsInternal()}
                    {renderGroupBarLabelsInternal()}
                    {renderPlugins()}
                  </>
                )}
            </svg>
          </div>
        </div>
      </div>
    </>
  )
}

BarChart.propTypes = {
  getRendererProps: PropTypes.func,
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  series: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
  ]),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  grouped: PropTypes.bool,
  stacked: PropTypes.bool,
  barLabels: PropTypes.bool,
  barLabelFormatter: PropTypes.func,
  axisBarLabels: PropTypes.bool,
  axisBarLabelFormatter: PropTypes.func,
  axisDataLines: PropTypes.bool,
  axisDataLabels: PropTypes.bool,
  axisDataLabelFormatter: PropTypes.func,
  groupBarLabels: PropTypes.bool,
  groupBarLabelFormatter: PropTypes.func,
  tooltips: PropTypes.bool,
  colors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))),
  ]),
  barWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  barMargin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  stackMargin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  groupMargin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  height: PropTypes.number,
  minData: PropTypes.number,
  maxData: PropTypes.number,
}

export default BarChart
