import React, { useMemo } from 'react'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const MetricItem = (props) => {
  const {
    className,
    showSeparator,
    separatorClassName,
    labelClassName,
    valueClassName,
    metric,
  } = props

  const { label, value, format, actualValue, baselineValue } = metric

  const { formatCurrency } = useFormatCurrency()
  const { formatCurrency: formatShortCurrency } = useFormatCurrency({ shortCurrency: true })

  const formattedValue = useMemo(() => {
    if (format === 'currency') {
      return actualValue === undefined ? 'N/A' : `${formatCurrency(actualValue)}/${formatCurrency(baselineValue)}`
    } else if (format === 'shortCurrency') {
      return actualValue === undefined ? 'N/A' : `${formatShortCurrency(actualValue)}/${formatShortCurrency(baselineValue)}`
    } else {
      return value
    }
  }, [format, actualValue, baselineValue, formatCurrency, formatShortCurrency])

  return (
    <div className={className}>
      {showSeparator && <div className={separatorClassName} />}
      <div>
        <div className={labelClassName}>{label}</div>
        <div className={valueClassName}>{formattedValue}</div>
      </div>
    </div>
  )
}

export default MetricItem
