import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTextField } from '../../hooks/useTextField'
import FilterExpander from './filterExpander'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { getFormatType } from './helpers'
import { formatTypes } from './constants'

const NumberRangeFilter = (props) => {
  const {
    fieldDefinition,
    onChange,
    open = false,
    onToggle
  } = props

  const { label, format } = fieldDefinition

  const formatType = useMemo(() => {
    return getFormatType(format)
  }, [format])

  const minMax = useMemo(() => {
    const mm = {}
    if (formatType === formatTypes.percent) {
      mm.min = -100
      mm.max = 200
    }
    return mm
  }, [formatType])

  const minTextField = useTextField()
  const maxTextField = useTextField()

  const [minValue, setMinValue] = useState('')
  const [maxValue, setMaxValue] = useState('')

  const searchFilters = useSelector((state) => state.searchFilters)
  const { pendingFilters } = searchFilters

  useEffect(() => {
    const filter = fieldDefinition.key && pendingFilters[fieldDefinition.key]
    if (filter) {
      const { data = {} } = filter
      const { min, max } = data
      minTextField.setValue(min || '')
      maxTextField.setValue(max || '')
    } else {
      minTextField.setValue('')
      maxTextField.setValue('')
    }
  }, [fieldDefinition])

  const onMinChange = useCallback((e) => {
    minTextField.onChange(e)
    setMinValue(e.target.value)
  }, [minTextField.onChange])

  const onMaxChange = useCallback((e) => {
    maxTextField.onChange(e)
    setMaxValue(e.target.value)
  }, [maxTextField.onChange])

  const triggerOnChange = (minValue, maxValue) => {
    // console.log('min', minValue, 'max', maxValue)
    onChange && onChange({
      fieldDefinition,
      type: 'NumberRangeFilter',
      data: {
        min: minValue,
        max: maxValue,
      },
    })
  }

  const debounceOnChange = useMemo(() => {
    return debounce(triggerOnChange, 350)
  }, [triggerOnChange])

  const [canDebounce, setCanDebounce] = useState(false)

  useEffect(() => {
    if (canDebounce) {
      debounceOnChange(minValue, maxValue)
    }
  }, [minValue, maxValue])

  useEffect(() => {
    setCanDebounce(true)
  }, [canDebounce])

  return (
    <FilterExpander
      count={1}
      showCount={maxTextField.value || minTextField.value}
      onToggle={onToggle}
      open={open}
      name={label}>
      <div className="NumberRangeFilter-DateRangeWrapper flex items-center justify-between pt-2 w-full">
        <div className="first:mr-2">
          <div className="text-size-12px text-color-91959f font-weight-500">Min</div>
          <div className="flex items-center">
            {formatType === formatTypes.currency && <span className="text-color-91959f font-weight-600 mx-1">$</span>}
            <input
              className="w-32 rounded border border-color-818e93 px-2 py-1 focus:outline-none"
              type="number"
              value={minTextField.value}
              onChange={onMinChange}
              {...minMax} />
            {formatType === formatTypes.percent && <span className="text-color-91959f font-weight-600 mx-1">%</span>}
          </div>
        </div>
        <div className="first:mr-2">
          <div className="text-size-12px text-color-91959f font-weight-500">Max</div>
          <div className="flex items-center">
            {formatType === formatTypes.currency && <span className="text-color-91959f font-weight-600 mx-1">$</span>}
            <input
              className="w-32 rounded border border-color-818e93 px-2 py-1 focus:outline-none"
              type="number"
              value={maxTextField.value}
              onChange={onMaxChange}
              {...minMax} />
            {formatType === formatTypes.percent && <span className="text-color-91959f font-weight-600 mx-1">%</span>}
          </div>
        </div>
      </div>
    </FilterExpander>
  )
}

export default NumberRangeFilter
