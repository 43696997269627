import React, { useMemo } from 'react'
import { FirstPage, LastPage, NavigateNext, NavigateBefore } from '@getoutreach/react-icons'
import classNames from 'classnames'

const Paging = (props) => {
  const {
    className,
    textClassName,
    navigationClassName,
    total,
    limit,
    page,
    onSetPage,
    textFormatter,
    changeSinceRenderer
  } = props

  const pagingText = useMemo(() => {
    if (total !== 0 && total !== undefined && limit !== undefined && page !== undefined) {
      const start = ((page - 1) * limit) + 1
      const end = Math.min(start + (limit - 1), total)
      return textFormatter ? textFormatter(start, end, total) : `${start} - ${end} of ${total}`
    }
  }, [total, limit, page])

  return (total !== undefined && limit !== undefined && page !== undefined) ? (
    <div className={className}>
      <div className={textClassName}>{pagingText}</div>
      {changeSinceRenderer && changeSinceRenderer()}
      <div className={navigationClassName}>
        <button onClick={() => onSetPage?.(1)} className={classNames('mx-2 px-1 focus:outline-none', { 'pointer-events-none': parseInt(page) === 1 })}>
          <FirstPage htmlColor={parseInt(page) === 1 ? '#bdbdbd' : '#151d49'} />
        </button>
        <button onClick={() => onSetPage?.(parseInt(page) - 1)} className={classNames('mx-2 px-1 focus:outline-none', { 'pointer-events-none': parseInt(page) === 1 })}>
          <NavigateBefore htmlColor={parseInt(page) === 1 ? '#bdbdbd' : '#151d49'} />
        </button>
        <button onClick={() => onSetPage?.(parseInt(page) + 1)} className={classNames('mx-2 px-1 focus:outline-none', { 'pointer-events-none': parseInt(page) * limit >= total })}>
          <NavigateNext htmlColor={parseInt(page) * limit >= total ? '#bdbdbd' : '#151d49'} />
        </button>
        <button
          onClick={() => onSetPage?.(Math.ceil(total / limit))}
          className={classNames('mx-2 px-1 focus:outline-none', { 'pointer-events-none': parseInt(page) * limit >= total })}>
          <LastPage htmlColor={parseInt(page) * limit >= total ? '#bdbdbd' : '#151d49'} />
        </button>
      </div>
    </div>
  ) : null
}

export default Paging
