import React, { useMemo, useCallback } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import Avatar from '../avatar/avatar'
import { useUserImageUrl } from '../../hooks/useUserImageUrl'
import { useLoggedInUser } from '../../context/loggedInUser'
import useRoles from '../../hooks/useRoles'

const UserSideMenuItem = (props) => {
  const {
    level,
    user,
    showImage,
    isBaseOption,
    onClick } = props

  const {
    id,
    photoUrl,
    firstName,
    lastName,
    name,
    hasChildren } = user

  const { loggedInUser } = useLoggedInUser()

  const { isIndividualContributorRole: isIc } = useRoles()

  const { teamId } = loggedInUser

  const params = useParams()
  const { userId } = params

  const topLevelId = useMemo(() => {
    const { teamId, groupViewerIdsList, loggedInUserId } = loggedInUser
    if (groupViewerIdsList.length) {
      return groupViewerIdsList[0]
    }
    return teamId ?? loggedInUserId
  }, [userId])

  const initials = useMemo(() => {
    return firstName?.charAt(0) + lastName?.charAt(0)
  }, [firstName, lastName])

  const isCurrent = useMemo(() => {
    if (!isBaseOption && (userId === id) && (userId !== teamId)) {
      return true
    }

    if (!userId && (id === topLevelId)) {
      return true
    }

    if (isBaseOption && ((userId === teamId) || (!userId && (id === teamId)))) {
      return true
    }

    if (isIc && userId === id) {
      return true
    }

    return false
  }, [userId, id, hasChildren, teamId, isBaseOption, isIc, topLevelId])

  const { userImageUrl } = useUserImageUrl(photoUrl)

  const highlightClass = classNames('sidemenu-item-highlight z-10 absolute w-full h-full', { 'sidemenu-highlight': isCurrent })

  const linkClass = classNames(
    'sidemenu-item-link',
    'w-full',
    'z-20',
    'font-medium',
    'hidden',
    'md:flex',
    'items-center',
    'justify-between',
    'px-6',
    'py-2',
    'border-transparent',
    'select-none',
    { 'cursor-pointer': onClick },
    { 'sidemenu-hover': !isCurrent && onClick },
    { 'sidemenu-selected': isCurrent }
  )

  const handleClick = useCallback(() => {
    onClick?.(user)
  }, [onClick, user])

  return (
    <div className="sidemenu-item flex relative" style={{ minHeight: 52 }}>
      <div className={highlightClass} />
      <a role="menuitem" tabIndex={-1} className={linkClass} onClick={handleClick}>
        <div className="flex flex-row items-center" style={{ marginLeft: level ? level * 30 : 0 }}>
          {showImage && <Avatar className="w-8 h-8 mr-2 purple" imageUrl={userImageUrl} text={initials} isRound={true} />}
          <span className="flex-1 text-base ml-1 text-color-09242f">
            {name}
            {teamId === user.id && <span className="text-color-51636a ml-1">(Your Team)</span>}
            {loggedInUser.id === user.id && <span className="text-color-51636a ml-1">(You)</span>}
          </span>
        </div>
      </a>
    </div>
  )
}

export default UserSideMenuItem
