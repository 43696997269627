import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import DealDropDownFilter from './dealDropDownFilter'
import { uniqForecastCategoriesSelector, oppSettingsSelector, ownerMenuItemsSelector } from '../../selectors'
import forEach from 'lodash/forEach'
import orderBy from 'lodash/orderBy'
import has from 'lodash/has'
import find from 'lodash/find'
import values from 'lodash/values'
import DealSizeFilter from './dealSizeFilter'
import { dealStatus } from '../../constants/dealStatus'

const DealFilters = (props) => {
  const { enableOwnerFilter } = props

  const history = useHistory()

  const location = useLocation()
  const hash = queryString.parse(location.hash)

  const ownerMenuItems = useSelector(ownerMenuItemsSelector)

  const timePeriodItems = [{ label: 'Current', value: 'true' }]

  const statusMenuItems = values(dealStatus)

  const [selectedStatus, setSelectedStatus] = useState('Any')
  const [selectedStage, setSelectedStage] = useState('Any')
  const [selectedForecast, setSelectedForecast] = useState('Any')
  const [selectedOwner, setSelectedOwner] = useState('Any')
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('Any')
  useEffect(() => {
    setSelectedStatus(has(hash, 'status') ? dealStatus[hash.status].label : 'Any')
    setSelectedStage(has(hash, 'stage') ? hash.stage : 'Any')
    setSelectedForecast(has(hash, 'forecast') ? hash.forecast : 'Any')
    setSelectedTimePeriod(has(hash, 'currentPeriod') ? find(timePeriodItems, (tp) => tp.value === hash.currentPeriod).label : 'Any')

    if (has(hash, 'owner')) {
      const owner = find(ownerMenuItems, (o) => o.id === hash.owner) || { label: 'Any' }
      setSelectedOwner(owner.label)
    } else {
      setSelectedOwner('Any')
    }
  }, [location, ownerMenuItems])

  const oppSettings = useSelector(oppSettingsSelector)
  const { minDealValue, maxDealValue } = oppSettings
  const selectedDealSize = has(hash, 'dealSize') ? hash.dealSize.split(',').map((x) => parseInt(x)) : [minDealValue, maxDealValue]

  const stages = useSelector((state) => state.oppSettings.normalizedStages)
  const stageMenuItems = stages.map((s, i) => { return { label: s } })

  const forecastCategories = useSelector(uniqForecastCategoriesSelector)
  const forecastCategoryItems = forecastCategories.map((fc, i) => { return { label: fc } })

  function navigateWithFilters(filters) {
    filters = orderBy(filters, ['key'], ['asc'])
    let hashFilters = ''
    filters.map((f) => { hashFilters += `&${f.key}=${f.value}` })
    if (hashFilters.length > 0) hashFilters = hashFilters.substr(1)
    history.push(location.pathname + (hashFilters ? `#${hashFilters}` : ''))
  }

  function handleStatusClick(menuItem) {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'status') {
        filters.push({ key, value })
      }
    })
    if (menuItem.label !== 'Any') {
      filters.push({ key: 'status', value: menuItem.id })
    }
    window.analytics.track('Filter', { filter: 'Status', value: menuItem.label })
    navigateWithFilters(filters)
  }

  function handleStageClick(menuItem) {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'stage') {
        filters.push({ key, value })
      }
    })
    if (menuItem.label !== 'Any') {
      filters.push({ key: 'stage', value: menuItem.label })
    }
    window.analytics.track('Filter', { filter: 'Stage', value: menuItem.label })
    navigateWithFilters(filters)
  }

  function handleForecastCategoryClick(menuItem) {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'forecast') {
        filters.push({ key, value })
      }
    })
    if (menuItem.label !== 'Any') {
      filters.push({ key: 'forecast', value: menuItem.label })
    }
    window.analytics.track('Filter', { filter: 'Forecast Category', value: menuItem.label })
    navigateWithFilters(filters)
  }

  const handleDealSizeSliderChangeComitted = (event, newValue) => {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'dealSize') {
        filters.push({ key, value })
      }
    })
    if (!(minDealValue === newValue[0] && maxDealValue === newValue[1])) {
      filters.push({ key: 'dealSize', value: newValue.toString() })
    }
    window.analytics.track('Filter', { filter: 'Size', min: minDealValue, max: maxDealValue })
    navigateWithFilters(filters)
  }

  function handleOwnerClick(menuItem) {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'owner') {
        filters.push({ key, value })
      }
    })
    if (menuItem.label !== 'Any') {
      filters.push({ key: 'owner', value: menuItem.id })
    }
    window.analytics.track('Filter', { filter: 'Owner', value: menuItem.label })
    navigateWithFilters(filters)
  }

  function handleTimePeriodClick(menuItem) {
    const filters = []
    forEach(hash, (value, key) => {
      if (key !== 'currentPeriod') {
        filters.push({ key, value })
      }
    })
    if (menuItem.label !== 'Any') {
      filters.push({ key: 'currentPeriod', value: menuItem.label === 'Current' })
    }
    window.analytics.track('Filter', { filter: 'Time Period', value: menuItem.label })
    navigateWithFilters(filters)
  }

  return (
    <div>
      <div className="flex mb-6">
        <div className="w-1/3 mx-3 ml-0">
          <DealDropDownFilter label="Status" menuItems={statusMenuItems} handleMenuItemClick={handleStatusClick} selectedLabel={selectedStatus} />
        </div>
        <div className="w-1/3 mx-3">
          <DealDropDownFilter label="Stage" menuItems={stageMenuItems} handleMenuItemClick={handleStageClick} selectedLabel={selectedStage} />
        </div>
        <div className="w-1/3 mx-3 mr-0">
          <DealDropDownFilter label="Forecast" menuItems={forecastCategoryItems} handleMenuItemClick={handleForecastCategoryClick} selectedLabel={selectedForecast} />
        </div>
      </div>
      <div className="flex">
        <div className="w-1/3 mx-3 ml-0">
          <DealSizeFilter min={minDealValue} max={maxDealValue} step={500} defaultValue={selectedDealSize} handleChangeCommitted={handleDealSizeSliderChangeComitted} />
        </div>
        <div className="w-1/3 mx-3">
          <DealDropDownFilter label="Time Period" menuItems={timePeriodItems} handleMenuItemClick={handleTimePeriodClick} selectedLabel={selectedTimePeriod} />
        </div>
        <div className="w-1/3 mx-3 mr-0">
          {enableOwnerFilter && <DealDropDownFilter label="Owner" menuItems={ownerMenuItems} handleMenuItemClick={handleOwnerClick} selectedLabel={selectedOwner} />}
        </div>
      </div>
    </div>
  )
}

export default DealFilters
