import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Add from '../svg/add'
import Delete from '../svg/delete'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { cloneDeep, filter, find, forEach, remove } from 'lodash'
import { guid } from '../../lib/guid'
import { useHistory, useLocation } from 'react-router-dom'
import { mapFiltersToQuery } from './helpers'
import { setViewInitialized } from '../../actions'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import DownloadIcon from '../icons/DownloadIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSaveUserViews } from './hooks'

const ViewMenu = (props) => {
  const {
    filterKey,
    viewsList = [],
    currentView = {},
    currentSort,
    onDownloadRequest
  } = props

  const { readonly = false, order } = currentView

  const isReadonly = useMemo(() => {
    return readonly || (order < 0)
  }, [readonly, order])

  const [userViews, setUserViews] = useState([])

  useEffect(() => {
    const views = filter(viewsList, (v) => v.order > -1)
    setUserViews(views)
  }, [viewsList])

  const [visible, setVisible] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()

  const location = useLocation()

  const pipelineGridFields = useSelector((state) => state.pipelineGridFields)
  const searchFilters = useSelector((state) => state.searchFilters)
  const { appliedFilters } = searchFilters

  const { isFetching, saveUserViews } = useSaveUserViews({ filterKey })

  const onAdd = useCallback(() => {
    setVisible(false)

    const query = mapFiltersToQuery(appliedFilters, pipelineGridFields)
    if (query.children) {
      if (!query.children.valuesList) {
        query.children.valuesList = []
      }
      forEach(query.children.valuesList, (v) => {
        if (v.node && v.node._filter && v.node._filter.fieldDefinition) {
          v.node._filter = cloneDeep(v.node._filter)
          v.node._filter.fieldDefinition = {
            key: v.node._filter.fieldDefinition.key,
            label: v.node._filter.fieldDefinition.label,
            format: v.node._filter.fieldDefinition.format,
            toType: v.node._filter.fieldDefinition.toType,
          }
        }
      })
    }

    const views = cloneDeep(userViews)
    const view = {
      key: guid(),
      label: 'CUSTOM',
      order: userViews.length + 1,
      payload: {
        query
      },
    }
    views.push(view)

    const onSuccess = () => {
      dispatch(setViewInitialized(false))
      history.push(`${location.pathname}#view=${view.key}`)

      setTimeout(() => {
        const nodes = document.querySelectorAll('.viewOption-edit')
        if (nodes.length > 0) {
          nodes[nodes.length - 1].click()
        }
      }, 100)
    }

    // optimistically set the views for local state
    setUserViews(views)
    // save the views to the db
    saveUserViews(views, { onSuccess })

    window.analytics.track('views.viewMenu.addClicked')
  }, [filterKey, userViews, appliedFilters, pipelineGridFields, location, saveUserViews])

  const onDelete = useCallback(() => {
    setVisible(false)

    const views = cloneDeep(userViews)
    remove(views, (v) => v.key === currentView.key)
    const onSuccess = () => {
      dispatch(setViewInitialized(false))
      history.push(location.pathname)
    }

    // optimistically set the views for local state
    setUserViews(views)
    // save the views to the db
    saveUserViews(views, { onSuccess })

    window.analytics.track('views.viewMenu.deleteClicked')
  }, [filterKey, userViews, currentView, location])

  const onSave = useCallback(() => {
    setVisible(false)

    const views = cloneDeep(userViews)
    const query = mapFiltersToQuery(appliedFilters, pipelineGridFields)
    if (query.children) {
      if (!query.children.valuesList) {
        query.children.valuesList = []
      }
      if (currentSort) {
        const { sortDirection, sortKey } = currentSort
        query.sortDirection = sortDirection
        query.sortKey = sortKey
      }
      forEach(query.children.valuesList, (v) => {
        if (v.node && v.node._filter && v.node._filter.fieldDefinition) {
          v.node._filter = cloneDeep(v.node._filter)
          v.node._filter.fieldDefinition = {
            key: v.node._filter.fieldDefinition.key,
            label: v.node._filter.fieldDefinition.label,
            format: v.node._filter.fieldDefinition.format,
            toType: v.node._filter.fieldDefinition.toType,
          }
        }
      })
    }
    const view = find(views, (v) => v.key === currentView.key)
    if (view) {
      view.payload = {
        query
      }
      // optimistically set the views for local state
      setUserViews(views)
      // save the views to the db
      saveUserViews(views)

      window.analytics.track('views.viewMenu.saveClicked')
    }
  }, [filterKey, userViews, currentView, currentSort, appliedFilters, pipelineGridFields, saveUserViews])

  const canSave = useMemo(() => {
    return !isReadonly
  }, [isReadonly])

  const onDownload = useCallback(() => {
    setVisible(false)
    onDownloadRequest?.()
  }, [onDownloadRequest])

  return (
    <div className="flex flex-row">
      <Tippy
        visible={visible}
        offset={[0, 4]}
        duration={[0, 0]}
        placement="right-start"
        interactive={true}
        onClickOutside={() => setVisible(false)}
        content={(
          <div className="flex flex-col p-2">

            <button
              type="button"
              onClick={onAdd}
              className={
                classNames(
                  'focus:outline-none rounded p-2',
                  { 'hover:bg-color-fafafa': !isFetching },
                  { 'pointer-events-none': isFetching }
                )
              }>
              <div className="flex flex-row">
                <div className="flex justify-center align-center" style={{ width: '30px' }}>
                  <Add />
                </div>
                <div className="flex flex-col items-start">
                  <div className="font-weight-500">Add View</div>
                  <div className="text-size-12px text-color-a0a8bb">Add a new custom view</div>
                </div>
              </div>
            </button>

            <button
              type="button"
              onClick={onDelete}
              className={
                classNames(
                  'focus:outline-none rounded p-2',
                  { 'hover:bg-color-fafafa': !isFetching && !isReadonly },
                  { 'pointer-events-none': isFetching || isReadonly },
                  { 'opacity-25': isReadonly }
                )
              }>
              <div className="flex flex-row">
                <div className="flex justify-center align-center" style={{ width: '30px' }}>
                  <Delete transform="scale(0.65)" />
                </div>
                <div className="flex flex-col items-start">
                  <div className="font-weight-500">Remove View</div>
                  <div className="text-size-12px text-color-a0a8bb">Delete this view</div>
                </div>
              </div>
            </button>

            <button
              type="button"
              onClick={onSave}
              className={
                classNames(
                  'focus:outline-none rounded p-2',
                  { 'cursor-pointer': canSave && !isFetching },
                  { 'hover:bg-color-fafafa': canSave && !isFetching },
                  { 'pointer-events-none opacity-25': !canSave || isFetching }
                )
              }>
              <div className="flex flex-row">
                <div className="flex justify-center align-center" style={{ width: '30px' }}>
                  <FontAwesomeIcon icon="save" />
                </div>
                <div className="flex flex-col items-start">
                  <div className="font-weight-500">Save View</div>
                  <div className="text-size-12px text-color-a0a8bb">Save any changes made to this view</div>
                </div>
              </div>
            </button>

            <button
              type="button"
              onClick={onDownload}
              className={
                classNames(
                  'focus:outline-none rounded p-2',
                  { 'cursor-pointer': !isFetching },
                  { 'hover:bg-color-fafafa': !isFetching },
                  { 'pointer-events-none opacity-25': isFetching }
                )
              }>
              <div className="flex flex-row">
                <div className="flex justify-center align-center" style={{ width: '30px' }}>
                  <DownloadIcon />
                </div>
                <div className="flex flex-col items-start">
                  <div className="font-weight-500">Download View</div>
                  <div className="text-size-12px text-color-a0a8bb">Download a XLSX file of the deals in this view</div>
                </div>
              </div>
            </button>
          </div>
        )}
        arrow={roundArrow}
        theme="canopy">
        <button className="p2" onClick={() => setVisible(true)}>
          <FontAwesomeIcon className="mx-3" color="#a0a8bb" icon="ellipsis-v" />
        </button>
      </Tippy>
    </div>
  )
}

export default ViewMenu
