import React, { useCallback, useMemo, useState } from 'react'
import Popover from '../popover'
import { filter, orderBy } from 'lodash'
import { useAuth } from '../../context/auth'
import FilterSelectGroup from './filterSelectGroup'
import SearchBox from '../search/searchBox'
import { useTextField } from '../../hooks/useTextField'
import { usePopover } from '../../hooks/usePopover'
import { useCanvasItem } from '../../context/canvasItem'
import classNames from 'classnames'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const sharedClasses = 'px-4 py-1 text-size-14px text-color-09242f font-normal cursor-pointer hover:bg-color-edeeee'

const FilterSelectPopover = (props) => {
  const {
    popover,
    options = [],
    selectedOption,
    onSelectedOptionChanged,
    zIndex = 999999999,
    disablePortal = false
  } = props

  const { anchorEl, open, onClose } = popover
  const optionsPopover = usePopover()
  const [popoverOption, setPopoverOption] = useState(undefined)

  const { actingUserId } = useAuth()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageSignal
    )
  }, [checkPermissions])

  const canShare = useMemo(() => {
    return permissions.CanManageSignal
  }, [permissions])

  const {
    name,
    defaultUserQueryParameter,
    orgDefaultUserQueryParameter,
    setDefault,
    setOrgDefault
  } = useCanvasItem()

  const search = useTextField()

  const yourFilters = useMemo(() => {
    let filters = filter(options, (o) => o.userId === actingUserId && !o.isPending)
      .map((f) => {
        if (f.sort === 0) {
          f.sort = 999999
        }
        return f
      })
    if (search.value) {
      filters = filter(filters, (o) => o.label.toLowerCase().includes(search.value.toLowerCase()))
    }
    return orderBy(filters, (o) => o.sort)
  }, [options, search])

  const sharedFilters = useMemo(() => {
    let filters = filter(options, (o) => o.userId !== actingUserId && !o.isPending)
      .map((f) => {
        if (f.sort === 0) {
          f.sort = 999999
        }
        return f
      })
    if (search.value) {
      filters = filter(filters, (o) => o.label.toLowerCase().includes(search.value.toLowerCase()))
    }
    return orderBy(filters, (o) => o.sort)
  }, [options, search])

  const onOptions = useCallback((e, option) => {
    e.preventDefault()
    e.stopPropagation()
    if (popoverOption?.value === option?.value) {
      setPopoverOption(undefined)
      optionsPopover.setAnchorEl(undefined)
    } else {
      setPopoverOption(option)
      optionsPopover.setAnchorEl(e.currentTarget)
    }
    window.analytics.track('realtimeMetrics.filterMenuItem.optionsClicked', { name })
  }, [popoverOption])

  const onSetDefault = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setDefault(popoverOption?.value)
    setPopoverOption(undefined)
    optionsPopover.onClose()
    window.analytics.track('realtimeMetrics.filterMenuItem.setDefaultClicked', { name })
  }, [name, setDefault, popoverOption])

  const onSetOrgDefault = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setOrgDefault(popoverOption?.value)
    setPopoverOption(undefined)
    optionsPopover.onClose()
    window.analytics.track('realtimeMetrics.filterMenuItem.setOrgDefaultClicked', { name })
  }, [name, setOrgDefault, popoverOption])

  return (
    <Popover
      style={{ zIndex }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      disablePortal={disablePortal}
      placement="top"
      timeout={0}>
      <div className="flex flex-col pt-2">
        <SearchBox
          className="mx-2"
          value={search.value}
          onChange={search.onChange}
          onClear={search.reset}
          autoFocus
          disableHint
          dense
        />
        {yourFilters.length > 0 && (
          <FilterSelectGroup
            title="Your Filters"
            selectedOption={selectedOption}
            options={yourFilters}
            onChange={onSelectedOptionChanged}
            onOptions={onOptions}
            popoverOption={popoverOption} />
        )}
        {sharedFilters.length > 0 && (
          <FilterSelectGroup
            title="Shared Filters"
            selectedOption={selectedOption}
            options={sharedFilters}
            onChange={onSelectedOptionChanged}
            onOptions={onOptions}
            popoverOption={popoverOption} />
        )}
        {yourFilters.length === 0 && sharedFilters.length === 0 && (
          <div className="px-4 py-2 text-color-818e93">No filters found</div>
        )}
        <Popover
          arrow
          style={{ marginTop: 10, zIndex: 999999999, boxShadow: 'none' }}
          open={optionsPopover.open}
          anchorEl={optionsPopover.anchorEl}
          onClose={optionsPopover.onClose}
          placement="bottom"
          timeout={0}>
          <div>
            <div
              className={classNames(sharedClasses, 'text-color-09242f',
                { 'pointer-events-none opacity-50': defaultUserQueryParameter === popoverOption?.value })}
              onClick={onSetDefault}>
              Set as Default
            </div>
            {canShare && popoverOption?.userId === '' && (
              <div
                className={classNames(sharedClasses, 'text-color-09242f',
                  { 'pointer-events-none opacity-50': orgDefaultUserQueryParameter === popoverOption?.value })}
                onClick={onSetOrgDefault}>
                Set as Org Default
              </div>
            )}
          </div>
        </Popover>
      </div>
    </Popover>
  )
}

export default FilterSelectPopover
