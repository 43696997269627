import { claimNames } from '../../constants/claimNames'
import { featureNames } from '../../constants/featureNames'
import { featureSettingSelector } from '../../selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getClaims } from '../../lib/auth0'
import { Link } from 'react-router-dom'
import { links } from '../../constants/links'
import { permissionNames } from '../../constants/permissionNames'
import { some } from 'lodash'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import { usePermissions } from '../../context/permissions'
import { usePipelineFeature } from '../pipeline/hooks'
import { useRoutes } from '../../context/routes'
import { useSelector } from 'react-redux'
import logo from '../../assets/outreach-logo.png'
import Popover from '../popover'
import React, { useCallback, useMemo, useState } from 'react'
import useRoles from '../../hooks/useRoles'

const adminUrl = process.env.ADMIN_URL

const MenuItem = ({ link, text, closePopover, onClick, ...props }) => {
  const onClickInternal = useCallback((e) => {
    window.analytics.track('Mobile Menu', { action: e.target.textContent })
    onClick && onClick()
    closePopover && closePopover()
  }, [onClick])

  return (
    <Link to={link} onClick={onClickInternal} {...props}><div className="px-6 py-3 border-b border-color-d8d8d8">{text}</div></Link>
  )
}

const MobileMenu = (props) => {
  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames: nearlineFeatureNames } = useNearlineFeatures()

  const {
    isManagerRole,
    isIndividualContributorRole,
  } = useRoles()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanAccessForecast,
      permissionNames.CanAccessAnalytic,
      permissionNames.CanAccessScenarioPlanner,
      permissionNames.CanAccessDeal
    )
  }, [checkPermissions])

  const { routes, routeTenantId } = useRoutes()

  const pipelineFeature = usePipelineFeature()

  const forecastingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.forecasting }))
  const reportingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.reporting }))
  const scenarioPlannerFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.scenarioPlanner }))

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const renderManagerMenu = useCallback(() => {
    return (
      <>
        <MenuItem link={routes.summary} text="Summary" closePopover={handleClose} />

        {!isAccessNearlineCheckReady ? <></> : (
          <>
            {canAccessNearlineFeature({ featureName: nearlineFeatureNames.performance }) ? (
              <MenuItem link={routes.performance} text="Rep Performance" closePopover={handleClose} />
            ) : (
              <MenuItem link={routes.repHome} text="Rep Performance" closePopover={handleClose} />
            )}
          </>
        )}

        {permissions.CanAccessForecast && forecastingFeature && forecastingFeature.payload.enabled && (
          <MenuItem link={routes.forecasting} text="Forecasting" closePopover={handleClose} />
        )}

        {permissions.CanAccessAnalytic && reportingFeature && reportingFeature.payload.enabled && (
          <MenuItem link={routes.analytics} text="Analytics" closePopover={handleClose} />
        )}

        {permissions.CanAccessScenarioPlanner && scenarioPlannerFeature && scenarioPlannerFeature.payload.enabled && (
          <MenuItem link={routes.scenarioPlanner} text="Scenario Planner" closePopover={handleClose} />
        )}

        {permissions.CanAccessDeal && (
          <MenuItem link={routes.pipeline} text="Deals" closePopover={handleClose} />
        )}
      </>
    )
  }, [routes, performance, permissions, forecastingFeature, reportingFeature, scenarioPlannerFeature, handleClose])

  const renderRepMenu = useCallback(() => {
    return (
      <>
        <MenuItem link={routes.performance} text="Performance" closePopover={handleClose} />

        {permissions.CanAccessDeal && pipelineFeature.enabled && (
          <MenuItem link={routes.pipeline} text="Deals" closePopover={handleClose} />
        )}
      </>
    )
  }, [routes, permissions, pipelineFeature, handleClose])

  const renderMenu = useCallback(() => {
    if (isManagerRole) {
      return renderManagerMenu()
    } else if (isIndividualContributorRole) {
      return renderRepMenu()
    } else if (permissions.CanAccessInternalAdmin) {
      return renderManagerMenu()
    } else {
      return <></>
    }
  }, [isManagerRole, isIndividualContributorRole, permissions, renderManagerMenu, renderRepMenu])

  const onAdminClick = useCallback(() => {
    const claims = getClaims()
    const tenantContexts = claims[claimNames.tenantContexts] || []
    let url = adminUrl
    if (routeTenantId && some(tenantContexts, (c) => c.tenant_id === routeTenantId)) {
      url += `/${routeTenantId}`
    }
    url += `/?nocache=${new Date().getTime()}`
    window.location = url
  }, [routeTenantId])

  return (
    <div className="md:hidden bg-color-ffffff">
      <div className="flex items-center justify-between">
        <div className="flex flex-row items-center px-8 py-6">
          <Link to={routes.summary}><img src={logo} style={{ width: 200 }} /></Link>
        </div>
        <button className="focus:outline-none mx-6" onClick={handleClick}>
          <FontAwesomeIcon icon="bars" className="mx-6" color="#000000" size="lg" />
        </button>
        <Popover
          style={{ zIndex: 10000 }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          fullHeight>
          {renderMenu()}
          {(permissions.CanAccessAdmin || permissions.CanAccessInternalAdmin)
            && <MenuItem link="#" text="Admin" closePopover={handleClose} onClick={onAdminClick} />}
          {permissions.CanAccessInternalAdmin
            && <MenuItem link={routes.tenants} text="Tenants" />}
          <MenuItem link={{ pathname: links.support }} target="_blank" rel="noreferrer" text="Support Portal" />
        </Popover>
      </div>
    </div>
  )
}

export default MobileMenu
