import { debounce, has, last } from 'lodash'
import { permissionNames } from '../../constants/permissionNames'
import { Route, Switch, useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { setSearchText } from '../../actions'
import { useAuth } from '../../context/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useExplicitViews } from '../pipeline/hooks'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useForecasting } from '../../context/forecasting'
import { useGroups } from '../../context/groups'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { useRoutes } from '../../context/routes'
import { useTextField } from '../../hooks/useTextField'
import { ViewsProvider } from '../../context/views'
import classNames from 'classnames'
import DealFilters from '../pipeline/dealFilters'
import Deals from './deals'
import DealSearchSummary from '../pipeline/dealSearchSummary'
import Forecast from './forecast'
import ForecastingBreadcrumbs from './forecastingBreadcrumbs'
import ForecastingTabs from './forecastingTabs'
import Header from '../header/header'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ResultPlaceholder from '../analytics/resultPlaceholder'
import SearchBox from '../search/searchBox'
import Team from './team'
import ViewsAndFilters from '../pipeline/viewsAndFilters'
import AnimatedLoader from '../loaders/animatedLoader'
import { useDealChangeSinceForecastStart } from '../../hooks/useDealChangeSinceForecastStart'
import { useTenantInfo } from '../../context/tenantInfo'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'

const Forecasting = (props) => {
  const { actingUserId } = useAuth()
  const { checkPermissions } = usePermissions()
  const { loggedInUser, isFetching: isFetchingLoggedInUser } = useLoggedInUser()
  const { notifyError } = useNotification()
  const { routes } = useRoutes()
  const dispatch = useDispatch()
  const explicitViews = useExplicitViews()
  const history = useHistory()
  const params = useParams()
  const { isFetching: isFetchingTenantInfo } = useTenantInfo()
  const { hasFeatureSettings, isDealHealthEnabled, isSuccessPlansEnabled } = useOutreachFeatures()

  const {
    findGroupById,
    rootGroupId,
    initialFetch: initialGroupsFetch,
    isFetching: isFetchingGroups } = useGroups()

  const {
    clearForecastByUserLocal,
    isFetching: isFetchingForecasting,
    forecast,
    success: forecastSuccess,
    error: forecastError,
    getCurrentForecastByConfig
  } = useForecasting()

  const isFetching = useMemo(() => {
    return !initialGroupsFetch || isFetchingTenantInfo || isFetchingLoggedInUser || isFetchingGroups || isFetchingForecasting
  }, [initialGroupsFetch, isFetchingTenantInfo, isFetchingLoggedInUser, isFetchingGroups, isFetchingForecasting])

  const { selectedConfig } = useForecastConfigs()

  const { changeSince } = useDealChangeSinceForecastStart()

  const { groupViewerIdsList = [], teamId } = loggedInUser

  const [tabIndex, setTabIndex] = useState(0)

  const lastUserIdParam = useMemo(() => {
    return (params.userIds && last(params.userIds.split('|'))) || ''
  }, [params])

  const userId = useMemo(() => {
    if (lastUserIdParam) {
      return lastUserIdParam
    }
    if (groupViewerIdsList.length) {
      return groupViewerIdsList[0]
    }
    return teamId
  }, [lastUserIdParam, teamId, groupViewerIdsList])

  const group = useMemo(() => {
    if (!userId) {
      return
    }

    return findGroupById(userId)
  }, [userId, findGroupById])

  useEffect(() => {
    dispatch(setSearchText(''))
    return () => {
      clearForecastByUserLocal()
    }
  }, [dispatch, clearForecastByUserLocal])

  useEffect(() => {
    if (forecastError) {
      notifyError('Error loading forecast!')
    }
  }, [forecastError, notifyError])

  const fetchForecast = useCallback(() => {
    getCurrentForecastByConfig(selectedConfig, userId)
  }, [getCurrentForecastByConfig, selectedConfig, userId])

  useEffect(() => {
    if (!selectedConfig) {
      return
    }

    if (params?.forecastConfigId && params.forecastConfigId !== selectedConfig?.id) {
      // transitioning via route change, hold up until the 'selectedConfig' prop
      // catches up to that of the url
      return
    }
    fetchForecast()
  }, [fetchForecast, selectedConfig, userId, params?.forecastConfigId])

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadDeal
    )
  }, [checkPermissions])

  const isDirectReport = useMemo(() => {
    if (!params.userIds) {
      return
    }

    const userIds = params.userIds.split('|')
    if (userIds.length === 1) {
      return true
    }

    if (!teamId) {
      return
    }

    const teamIdIndex = userIds.indexOf(teamId)
    return teamIdIndex > -1 && teamIdIndex === userIds.length - 2
  }, [params, teamId])

  const isIcManagerAndDirectReport = useMemo(() => {
    return isDirectReport && (forecast.ownerType === 'ic' || forecast.ownerType === 'orphan')
  }, [isDirectReport, forecast])

  const forecastingMatch = useRouteMatch(routes.forecasting)
  const forecastingByConfigMatch = useRouteMatch(routes.forecastingByConfig)
  const forecastingByTeamDealsMatch = useRouteMatch(routes.forecastingByTeam_TeamDeals)
  const forecastingByTeamMyDealsMatch = useRouteMatch(routes.forecastingByTeam_Deals)
  const forecastingByTeamMatch = useRouteMatch(routes.forecastingByTeam)

  useEffect(() => {
    if (forecastingByTeamMyDealsMatch?.isExact) {
      setTabIndex(2)
    } else if (forecastingByTeamDealsMatch?.isExact) {
      setTabIndex(1)
    } else if (forecastingByTeamMatch?.isExact) {
      setTabIndex(0)
    } else if (forecastingByConfigMatch?.isExact) {
      setTabIndex(0)
    } else if (forecastingMatch?.isExact) {
      setTabIndex(0)
    }
  }, [forecastingMatch, forecastingByTeamMatch, forecastingByTeamDealsMatch, forecastingByTeamMyDealsMatch, forecastingByConfigMatch?.isExact])

  const isLoggedInUsersTeam = useMemo(() => {
    return [teamId, actingUserId].includes(userId)
  }, [userId, actingUserId, teamId])

  const user = useMemo(() => {
    const isLoggedInUser = userId === actingUserId
    return {
      info: isLoggedInUser ? loggedInUser : findGroupById(userId),
      isLoggedInUser,
    }
  }, [userId, actingUserId, findGroupById, loggedInUser])

  const forecastingError = useMemo(() => {
    return ((forecastSuccess && !forecast) || forecastError)
  }, [forecastSuccess, forecast, forecastError])

  const searchFilters = useSelector((state) => state.searchFilters)
  const { searchText } = searchFilters

  const search = useTextField({
    defaultValue: searchText,
    onReset: () => {
      dispatch(setSearchText(''))
    },
  })

  const doSearch = useCallback((searchValue) => {
    tabIndex !== 0 && dispatch(setSearchText(searchValue))
  }, [dispatch, tabIndex])

  const debounceSearch = useMemo(() => {
    return debounce(doSearch, 350)
  }, [doSearch])

  useEffect(() => {
    if (tabIndex !== 0) {
      debounceSearch(search.value)
    }
  }, [tabIndex, search.value, debounceSearch])

  const headerText = useMemo(() => {
    if (!group) {
      return 'Forecast Overview'
    }
    return `${group?.name}'s Forecast`
  }, [group])

  const viewsAndFiltersProps = useMemo(() => {
    const p = {}
    if (tabIndex === 1) {
      // search filter for Team Deals
      const group = findGroupById(userId)
      if (groupViewerIdsList.length && userId === loggedInUser.id) {
        p.groupIds = groupViewerIdsList
      } else if (group) {
        p.groupIds = [group.id]
        p.excludeUserIds = [userId]
      } else {
        p.groupIds = [rootGroupId]
        p.excludeUserIds = [userId]
      }
    } else if (tabIndex === 2) {
      // search filter for My Deals
      p.userIds = [userId]
    }
    return p
  }, [tabIndex, rootGroupId, userId, findGroupById, groupViewerIdsList, loggedInUser])

  const onBackClick = useCallback((userIds) => {
    const ids = userIds.split('|')
    if (ids.length > 1) {
      ids.splice(-1)
      history.push(routes.forecastingByTeam
        .replace(':forecastConfigId', params.forecastConfigId)
        .replace(':userIds', ids.join('|')))
    } else {
      history.push(routes.forecastingByConfig.replace(':forecastConfigId', params.forecastConfigId))
    }
  }, [history, routes.forecastingByTeam, routes.forecastingByConfig, params.forecastConfigId])

  const canShowViewsAndFilters = useMemo(() => {
    return tabIndex !== 0
      && permissions.CanReadDeal
      && !isFetchingTenantInfo
      && !isFetchingLoggedInUser
      && !isFetchingGroups
      && hasFeatureSettings
      && has(forecast, 'forecastConfigId')
  }, [tabIndex, permissions.CanReadDeal, isFetchingTenantInfo, isFetchingLoggedInUser, isFetchingGroups, hasFeatureSettings, forecast])

  return (
    <div id="forecasting" className={classNames('w-full h-screen flex flex-col')}>
      <Header
        text={headerText}
        showBackButton={has(params, 'userIds')}
        showSearch={false}
        onBackClick={() => onBackClick(params.userIds)}
        breadcrumbControl={params.userIds && (
          <ForecastingBreadcrumbs
            forecastConfigId={selectedConfig?.id}
            userIds={params.userIds.split('|')} />
        )} />

      {isFetching ? (
        <AnimatedLoader
          className="mt-16"
          title="Loading Forecasting"
          subTitle="Please wait..."
          maxWidth={300} />
      ) : (
        <>
          <Forecast
            group={group}
            user={user}
            isIcManagerAndDirectReport={isIcManagerAndDirectReport}
            isLoggedInUsersTeam={isLoggedInUsersTeam} />

          {forecastingError
            ? (
              <div className="flex justify-center mt-16">
                <ResultPlaceholder
                  className="mt-16"
                  title="No Forecasts"
                  subTitle="You have no forecasts configured yet. Please reach out to your administrator to set one up for you."
                  maxWidth={320} />
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <ForecastingTabs
                    key={`ForecastingTabs-${userId}`}
                    tabIndex={tabIndex}
                    user={user}
                    forecastConfigId={selectedConfig?.id}
                    userIdsParam={params.userIds || userId}
                    isLoggedInUsersTeam={isLoggedInUsersTeam} />

                  {permissions.CanReadDeal && (
                    <div className="flex items-center">
                      {tabIndex !== 0
                        && (
                          <DealFilters
                            showOwnerFilter={false}
                            tipPlacement="left" />
                        )}
                      <SearchBox
                        className="mt-2 ml-2 mx-10"
                        placeholder={`Search for a ${tabIndex === 0 ? 'person' : 'deal'}`}
                        value={search.value}
                        onChange={search.onChange}
                        onClear={search.reset}
                        autoFocus={false} />
                    </div>
                  )}
                </div>
              </>
            )}
        </>
      )}

      {canShowViewsAndFilters
        && (
          <div className="flex justify-between mx-10">
            <ViewsProvider filterKey="pipeline">
              <ViewsAndFilters
                key={`ViewsAndFilters-${userId}-${tabIndex}`}
                filterKey="pipeline"
                className="flex flex-wrap py-3"
                {...viewsAndFiltersProps}
                explicitViews={explicitViews}
                changeSince={changeSince}
                fetchDealHealthData={isDealHealthEnabled || isSuccessPlansEnabled} />
            </ViewsProvider>
            <DealSearchSummary />
          </div>
        )}

      <Switch>
        <Route
          path={routes.forecastingByTeam_Deals}
          render={() => (
            <Deals
              key={`MyDeals-${userId}-${tabIndex}`}
              name={`MyDeals-${userId}-${tabIndex}`}
              changeSince={changeSince}
              fetchForecast={fetchForecast} />
          )} />
        <Route
          path={routes.forecastingByTeam_TeamDeals}
          render={() => (
            <Deals
              key={`TeamDeals-${userId}-${tabIndex}`}
              name={`TeamDeals-${userId}-${tabIndex}`}
              changeSince={changeSince}
              fetchForecast={fetchForecast} />
          )} />
        <Route
          path={[routes.forecasting, routes.forecastingByConfig]}
          render={() => (
            <Team
              group={group}
              user={user}
              key={`Team-${userId}`}
              isLoggedInUsersTeam={isLoggedInUsersTeam}
              isIcManagerAndDirectReport={isIcManagerAndDirectReport}
              search={search.value}
              forecastConfigIdParam={selectedConfig?.id}
              userIdsParam={params.userIds} />
          )} />
      </Switch>

    </div>
  )
}

export default Forecasting
