import React, { useMemo } from 'react'
import { useDealSignals } from '../../context/dealSignals'

const DealSignalsHeader = (props) => {
  const { isFetching, dealSignals } = useDealSignals()

  const signalCount = useMemo(() => {
    return dealSignals.length
  }, [dealSignals.length])

  return (
    <>
      <div className="mt-3 mb-1 flex items-center">
        <div
          style={{ width: 28, height: 28 }}
          className="inline-block text-center text-size-14px font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-color-5951FF">
          <div className="font-weight-700 text-size-14px">{isFetching ? '-' : signalCount}</div>
        </div>
        <div style={{ transform: 'translateY(1px)' }} className="ml-2 text-color-151d49 text-size-18px font-weight-700">Signals</div>
      </div>
      <div className="border-t border-color-d6d9e6 my-2" />
    </>
  )
}

export default DealSignalsHeader
