import PropTypes from 'prop-types/prop-types'
import { paddingPropTypes, strokePropTypes, offsetPropTypes, svgTextPropTypes } from '../propTypes/common'

export const LineChartConfig = {
  padding: {
    top: 30,
    bottom: 30,
    left: 30,
    right: 30,
  },
  line: {
    style: {
      strokeWidth: 4,
      strokeLinecap: 'round',
      fill: 'none',
    },
  },
  label: {
    offset: {
      x: 0,
      y: 22,
      width: 0,
      height: 0,
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'normal',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#4e5d7e',
    },
    // callback function to determine if it should render the label
    canRender: (text, index) => index > 0,
  },
  axisDataLabel: {
    offset: {
      x: 5,
      y: 0,
      width: 10,
      height: 0,
    },
    style: {
      textAnchor: 'start',
      fontWeight: 'normal',
      fontSize: 12,
      fontFamily: 'Proxima Nova',
      fill: '#919191',
    },
    // callback function to determine if it should render the label
    canRender: (text, index) => index > 0,
  },
  tick: {
    offset: {
      x: 0,
      y: 7,
      width: 0,
      height: 5,
    },
    style: {
      strokeWidth: 1,
      strokeLinecap: 'round',
      stroke: '#d6d9e6',
      fill: 'none',
    },
  },
}

export const lineChartConfigPropTypes = {
  padding: PropTypes.shape(paddingPropTypes),
  line: PropTypes.shape({
    style: PropTypes.shape(strokePropTypes),
  }),
  label: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
    canRender: PropTypes.func,
  }),
  tick: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(strokePropTypes),
  }),
}
