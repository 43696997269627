import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcCallback } from '../grpc'
import { toListForecastConfigsByGroupIdRequest, toGetForecastConfigRequest } from '../grpc/converters'
import { useLoggedInUser } from './loggedInUser'
import { useParams, useLocation } from 'react-router-dom'
import { useSearchString } from '../hooks/useSearchString'
import { useRoutes } from './routes'
import useRoles from '../hooks/useRoles'

const ForecastConfigsContext = React.createContext()

export function ForecastConfigsProvider({ children }) {
  const { forecastConfigId } = useParams()
  const { search } = useLocation()
  const { loggedInUser } = useLoggedInUser()
  const { isPlayerCoach } = useRoles()
  const { isPreview } = useRoutes()
  const { searchParams } = useSearchString()

  const [key, setKey] = useState(guid())
  const [isFetching, setIsFetching] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [configsList, setConfigsList] = useState([])
  const [selectedConfig, setSelectedConfig] = useState(null)
  const [columnsList, setColumnsList] = useState([])

  const { groupViewerIdsList = [], teamId } = loggedInUser

  const groupId = useMemo(() => {
    if (isPlayerCoach && groupViewerIdsList.length) {
      return groupViewerIdsList[0]
    }
    return teamId
  }, [teamId, isPlayerCoach, groupViewerIdsList])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useEffect(() => {
    if (!configsList?.length) {
      return
    }

    if (!forecastConfigId) {
      const defaultConfig = configsList[0]
      setSelectedConfig(defaultConfig)
      return
    }

    const requestedConfig = configsList.find(({ id }) => id === forecastConfigId)
    if (requestedConfig) {
      setSelectedConfig(requestedConfig)
    }
  }, [configsList, forecastConfigId])

  const getForecastConfigRequest = useGrpcCallback({
    onSuccess: (obj) => {
      setConfigsList([obj])
      setIsFetching(false)
      setError(null)
      setSuccess(true)
    },
    onError: (err) => {
      setError(err)
      setIsFetching(false)
    },
    grpcMethod: 'getForecastConfig',
    debug: false
  }, [])

  const listForecastConfigsRequest = useGrpcCallback({
    onSuccess: (obj) => {
      setConfigsList(obj?.configsList)
      setIsFetching(false)
      setError(null)
      setSuccess(true)
    },
    onError: (err) => {
      setError(err)
      setIsFetching(false)
    },
    grpcMethod: 'listForecastConfigsByGroupId',
    debug: false
  }, [])

  useEffect(() => {
    if (!isPreview || !forecastConfigId) {
      return
    }

    const request = toGetForecastConfigRequest({ forecastConfigId })
    getForecastConfigRequest(request)
  }, [isPreview, getForecastConfigRequest, forecastConfigId])

  useEffect(() => {
    if (!groupId) {
      return
    }

    const listRequest = toListForecastConfigsByGroupIdRequest({ groupId })
    listForecastConfigsRequest(listRequest)
  }, [groupId, listForecastConfigsRequest])

  const contextValue = useMemo(() => {
    return {
      key,
      invalidate,
      success,
      isFetching,
      error,
      configsList,
      columnsList,
      selectedConfig,
      setColumnsList,
      setSelectedConfig
    }
  }, [
    key,
    invalidate,
    success,
    isFetching,
    error,
    configsList,
    columnsList,
    selectedConfig,
    setColumnsList,
    setSelectedConfig
  ])

  return <ForecastConfigsContext.Provider value={contextValue}>{children}</ForecastConfigsContext.Provider>
}

export function useForecastConfigs() {
  const context = React.useContext(ForecastConfigsContext)
  if (context === undefined) {
    throw new Error('useForecastConfigs must be used within a ForecastConfigsProvider')
  }
  return context
}
