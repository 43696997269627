import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import pencilSm from '../../assets/pencil-sm.png'
import { moveCursorToEnd } from '../../lib/input'
import Icon, { iconType } from '../icon'
import { useFormatValue } from './hooks'
import classNames from 'classnames'
import FieldDebug from './fieldDebug'
import { getFormatLength, getFormatType } from './helpers'
import { formatTypes } from './constants'
import ShowHideContainer from '../showHideContainer'

const StringFieldDetailItem = (props) => {
  const {
    canScroll = false,
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
  } = props

  const { label, canEdit, required, format } = fieldDefinition

  const formatType = useMemo(() => {
    return getFormatType(format)
  }, [format])

  const isMultiline = useMemo(() => {
    return formatType === formatTypes.plaintextarea || formatType === formatTypes.richtextarea
  }, [formatType])

  const maxLength = useMemo(() => {
    const len = getFormatLength(format)
    return !len ? {} : { maxLength: len }
  }, [format])

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const onFieldChangedInternal = useCallback((value) => {
    onFieldChanged && onFieldChanged(opportunity, {
      ...field,
      value,
      label,
    })
  }, [opportunity, field, onFieldChanged])

  const inputRef = useRef()
  const [inputValue, setInputValue] = useState(field.value || '')
  const [prevInputValue, setPrevInputValue] = useState(field.value || '')

  const { formatValue } = useFormatValue()

  const formattedValue = formatValue(inputValue, fieldDefinition.format)

  useEffect(() => {
    const { value = '' } = field
    setPrevInputValue(value)
    setInputValue(value)
  }, [field])

  const [isEditMode, setIsEditMode] = useState(false)

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure, isEditMode])

  const onClick = useCallback(() => {
    if (readonly) {
      return
    }
    setPrevInputValue(inputValue)
    setIsEditMode(true)
  }, [readonly, inputValue])

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  const handleInputClick = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }, [])

  const handleInputBlur = useCallback((e) => {
    setIsEditMode(false)
    if (e.cancelled) {
      return
    }
    if (inputValue === '' && required) {
      setInputValue(prevInputValue)
      return
    }
    if (inputValue !== prevInputValue) {
      onFieldChangedInternal(inputValue)
    }
  }, [inputValue, prevInputValue, onFieldChangedInternal, required])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setInputValue(prevInputValue)
      handleInputBlur({ cancelled: true })
    } else if (e.key === 'Enter' && !e.shiftKey) {
      handleInputBlur({ cancelled: false })
    }
  }, [handleInputBlur, prevInputValue])

  useEffect(() => {
    if (isEditMode) {
      const inputEl = inputRef.current
      inputEl.focus()
      moveCursorToEnd(inputEl)
    }
  }, [isEditMode, inputRef])

  return (
    <>
      {!formattedValue && hideWhenEmpty
        ? (<></>)
        : (
          <div className={classNames('StringFieldDetailItem', { 'my-4': !className }, { [className]: className })}>
            <div className={classNames('flex items-center justify-between text-color-09242f text-size-16px font-weight-600 truncate', labelClassName)}>
              {label}
              <FieldDebug
                debug={debug}
                data={{ opportunity, fieldDefinition, field }} />
            </div>
            {isEditMode
              ? (
                <div>
                  {isMultiline
                    ? (
                      <textarea
                        ref={inputRef}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onClick={handleInputClick}
                        onKeyDown={handleKeyDown}
                        className="w-full px-3 py-1 text-size-15px font-normal border border-color-b0bac9 rounded focus:outline-none"
                        rows={4}
                        {...maxLength} />
                    )
                    : (
                      <input
                        ref={inputRef}
                        value={inputValue}
                        type="text"
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onClick={handleInputClick}
                        onKeyDown={handleKeyDown}
                        className="w-full px-3 py-1 text-size-15px font-normal border border-color-b0bac9 rounded focus:outline-none"
                        {...maxLength} />
                    )}
                </div>
              )
              : (
                <div
                  onClick={onClick}
                  className={classNames('flex items-center group', { 'cursor-pointer': !readonly }, { 'pointer-events-none': !canScroll && readonly })}>
                  {isMultiline
                    ? (
                      <ShowHideContainer maxHeight={150}>
                        <pre style={{ fontFamily: 'Proxima Nova' }}
                          className={classNames('whitespace-pre-line text-size-16px font-weight-300',
                            { 'text-color-09242f': !readonly },
                            { 'text-color-09242f': readonly })}>
                          {formattedValue || '-'}
                        </pre>
                      </ShowHideContainer>
                    )
                    : (
                      <ShowHideContainer maxHeight={150}>
                        <div
                          style={{ transform: 'translateY(-1px)' }}
                          className={classNames('text-size-16px font-weight-300 break-words',
                            { 'text-color-09242f': !readonly },
                            { 'text-color-09242f': readonly })}>
                          {formattedValue || '-'}
                        </div>
                      </ShowHideContainer>
                    )}
                  {!readonly && (
                    <Icon type={iconType.IMAGE} src={pencilSm} className="invisible group-hover:visible" style={{ transform: 'translate(4px, -2px)' }} />
                  )}
                </div>
              )}
          </div>
        )}
    </>
  )
}

export default StringFieldDetailItem
