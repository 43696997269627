import { SearchFieldType } from '../../grpc/enums'

export const defaultSkip = 0
export const defaultTake = 20
export const defaultView = 'currentPipeline'
export const startOfDayTimestamp = 'T00:00:00.000'
export const endOfDayTimestamp = 'T23:59:59.999'

export const changeHistoryFields = [
  'stagename',
  'forecastcategoryname',
  'closedate',
  'amount',
]

export const defaultSortItem = {
  name: 'signalCount',
  source: 'canopy',
  type: SearchFieldType.INTEGER,
}

export const lastModifiedDateSortItem = {
  name: '_internalUpdatedAt',
  source: 'raw',
  type: SearchFieldType.DATE,
}

export const ownerIdSortItem = {
  name: 'ownerId',
  source: 'canonical',
  type: SearchFieldType.STRING,
}

export const statusSortItem = {
  name: '_status',
  source: 'canopy',
  type: SearchFieldType.STRING,
}
