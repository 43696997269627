import { Close } from '@getoutreach/react-icons'
import { forEach } from 'lodash'
import { forecastPeriods } from './constants'
import { getCurrentForecastCategoryCall } from './helpers'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useForecasting } from '../../context/forecasting'
import { useForecastingFeature } from './hooks'
import { useTextField } from '../../hooks/useTextField'
import classNames from 'classnames'
import Modal from '../common/modal'
import React, { useCallback, useMemo } from 'react'

const SubmitForecastModal = (props) => {
  const {
    modal,
    isManager,
  } = props

  const { value, onChange } = useTextField()

  const { open, handleClose } = modal

  const forecastingFeature = useForecastingFeature()
  const { forecastCategories } = forecastingFeature

  const { forecast, submitForecast, forecastByUserLocal, cursor } = useForecasting()

  const { selectedConfig } = useForecastConfigs()
  const { periodLength = 0 } = selectedConfig ?? {}

  const categoryCalls = useMemo(() => {
    const { current = {} } = forecast
    const { ownerId } = current
    const callsListMap = new Map()
    forEach(forecastCategories, (fc) => {
      const categoryCall = getCurrentForecastCategoryCall(fc.id, forecast, forecastByUserLocal[ownerId], !isManager)
      callsListMap.set(fc.id, categoryCall.inherentValue)
    })
    return callsListMap
  }, [forecast, forecastCategories, forecastByUserLocal, isManager])

  const getForecastPeriods = useMemo(() => {
    return forecastPeriods[periodLength]
  }, [periodLength])

  const selectedPeriod = useMemo(() => {
    const { name = '' } = getForecastPeriods[cursor + 1] ?? forecastPeriods.default
    return name
  }, [cursor, getForecastPeriods])

  const onSubmitInternal = useCallback(() => {
    submitForecast(value, categoryCalls, isManager)
    window.analytics.track('forecasting.v3.submittedForecast')
    handleClose()
  }, [submitForecast, value, categoryCalls, isManager, handleClose])

  const onCloseInternal = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <Modal
      maxWidth="md"
      handleClose={onCloseInternal}
      open={open}>
      <div className="w-full flex flex-col font-normal">
        <div className="px-10 py-6">
          <div className="flex items-center justify-between">
            <div className="text-size-24px font-weight-600">Submit Forecast</div>
            <button onClick={onCloseInternal} className="focus:outline-none"><Close /></button>
          </div>
          <div className="text-color-51636a text-size-18px font-weight-400">
            {selectedPeriod}
          </div>
        </div>

        <div className="w-full h-full px-10 pt-10">
          <textarea
            value={value}
            onChange={onChange}
            className="w-full px-4 py-3 bg-color-fafafa border border-color-edeeee rounded leading-tight focus:outline-none"
            placeholder="What should your manager know about this forecast?"
            autoFocus={true}
            rows="10" />
        </div>

        <div className="flex justify-center pt-6 pb-4">
          <button
            className={classNames('px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none')}
            onClick={onSubmitInternal}>
            Submit
          </button>
        </div>

        <div className="w-1/3 pb-6 mx-auto text-center text-size-10px text-color-b0bac9 font-weight-400 tracking-widest">
          Submitting will add any notes or comments you make to this forecast and submit it to your manager.
        </div>
      </div>
    </Modal>
  )
}

export default SubmitForecastModal
