import React, { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import CountUp from 'react-countup'
import InfoTip from '../infoTip'
import { updateModal } from '../../actions'
import get from 'lodash/get'
import { modalTypes } from '../modals/modals'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import classNames from 'classnames'

const PipelineGroupResultItem = (props) => {
  const { data, prevData, amountSum, prevAmountSum, style, selectedGroup, inputParams } = props
  const { id, label, totalAmount, totalDeals, opportunityIdsList } = data

  const { formatCurrency } = useFormatCurrency({ shortCurrency: true })

  const startAmount = get(prevData, 'totalAmount', 0)
  const startDeals = get(prevData, 'totalDeals', 0)

  const startPerc = startAmount / prevAmountSum || 0
  const endPerc = totalAmount / amountSum || 0
  function formatPerc(value) {
    return `${value}%`
  }

  const tooltipContent = useMemo(() => {
    return {
      won: 'Revenue and deals that were marked closed and won by the end of the selected time period',
      lost: 'Revenue and deals that were marked closed and lost by the end of the selected time period',
      moved: 'Revenue and deals that changed groups (stage, forecast, etc.), but were still open by the end of the selected time period',
      // eslint-disable-next-line max-len
      slipped: 'Revenue and deals that had a close date of within the sales period at the start of the time window, but by the end had been updated to a close date after the sales period',
      idle: 'Revenue and deals that did not change group, close or slip within the selected time period'
    }
  }, [])

  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(updateModal({
      type: modalTypes.pipelineReportOpportunities,
      id,
      label,
      totalAmount,
      totalDeals,
      ids: opportunityIdsList,
      tooltip: tooltipContent[id],
      selectedGroup,
      inputParams
    }))
  }, [opportunityIdsList, data])

  return (
    <div className="text-color-151d49 leading-tight bg-gradient-light border border-color-eaeaea px-5 py-4 rounded-lg mr-2 last:mr-0" style={style}>
      <div className="flex items-center mb-1">
        <div className="text-size-18px font-weight-500 mr-1 truncate">{label}</div>
        <InfoTip content={<div className="px-4 py-3">{tooltipContent[id]}</div>} />
      </div>
      <div className="lg:text-size-30px text-size-24px font-bold truncate">
        <CountUp start={startAmount} end={totalAmount} duration={0.5} formattingFn={formatCurrency} />
      </div>
      <div className="lg:text-size-24px text-size-20px font-weight-600 text-color-a6b2cf truncate">
        <CountUp start={startPerc * 100} end={endPerc * 100} duration={0.5} formattingFn={formatPerc} />
      </div>
      <div className="text-size-14px text-color-2e5bff">
        <button onClick={handleClick} className={classNames('font-bold focus:outline-none', { 'pointer-events-none opacity-50': totalDeals === 0 })}>
          View Deals (
          <CountUp start={startDeals} end={totalDeals} duration={0.5} />
          )
        </button>
      </div>
    </div>
  )
}

export default PipelineGroupResultItem
