import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const iconType = {
  FONTAWESOME: 'FONTAWESOME',
  IMAGE: 'IMAGE'
}

const Icon = (props) => {
  const { type = iconType.FONTAWESOME, className, iconName, color, size, style, src } = props

  if (type === iconType.FONTAWESOME) {
    return <FontAwesomeIcon className={className} icon={iconName} color={color} size={size} style={style} />
  } else if (type === iconType.IMAGE) {
    return <img src={src} className={className} style={style} />
  } else {
    return <></>
  }
}

export default Icon
