import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCurrentForecastCategoryCall } from './helpers'
import { useDebug } from '../../context/debug'
import { useForecastDelta } from './hooks'
import { useForecasting } from '../../context/forecasting'
import classNames from 'classnames'
import ForecastCall from './forecastCall'
import ManagersTeamCall from './managersTeamCall'
import PercentOfQuota from './percentOfQuota'
import React, { useCallback, useMemo } from 'react'

const CallCard = (props) => {
  const {
    isLoggedInUsersTeam,
    isIcManagerAndDirectReport,
    isManager,
    forecastCategory,
    forecast,
    readonly = false,
    notifyError,
  } = props

  const { debug } = useDebug()

  const {
    forecastByUserLocal,
    removeForecastByUserLocalCategoryCall,
    updateForecastByUserLocal,
    isPastForecastPeriod } = useForecasting()

  const { name, forecastConfigValueSettings = {} } = forecastCategory

  const hideQuota = useMemo(() => {
    const { customQuerySettings = {} } = forecastConfigValueSettings
    return customQuerySettings?.canopyDataUrl === 'internal::commit.forecast.view.goalAmount'
  }, [forecastConfigValueSettings])

  const {
    current,
    forecastLocal,
    forecastDeltaTooltip } = useForecastDelta(forecastCategory, forecast, forecastByUserLocal)

  const { ownerId } = current || {}

  const value = useMemo(() => {
    const categoryCall = getCurrentForecastCategoryCall(
      forecastCategory.id,
      forecast,
      forecastLocal
    )
    return categoryCall
  }, [forecastCategory.id, forecast, forecastLocal])

  const validator = useCallback((newValue) => {
    return !!newValue
  }, [])

  const validatorMessage = useMemo((newValue) => {
    return 'A value is required'
  }, [])

  const onCallChange = useCallback((forecastCalls) => {
    const newCall = forecastCalls[forecastCategory.id]
    if (newCall.value !== value.value) {
      updateForecastByUserLocal({
        ownerId,
        forecastCategory,
        call: newCall.value,
      })
      window.analytics.track('forecasting.v3.callCard.callChanged')
    }
  }, [forecastCategory, value, updateForecastByUserLocal, ownerId])

  const onRemoveCall = useCallback((forecastCategory) => {
    removeForecastByUserLocalCategoryCall({
      forecast,
      forecastCategory,
    })
    window.analytics.track('forecasting.v3.callCard.callRemoved')
  }, [forecast, removeForecastByUserLocalCategoryCall])

  const showLabelDecorator = useMemo(() => {
    if (forecastCategory.autoCalculate) {
      return false
    }
    return (forecastByUserLocal[ownerId]?.localCategoryCalls?.[forecastCategory.id])
  }, [ownerId, forecastByUserLocal, forecastCategory])

  const labelDecorator = useMemo(() => {
    return !showLabelDecorator ? undefined : (
      <div
        onClick={() => onRemoveCall(forecastCategory)}
        className="float-right cursor-pointer"
        style={{ transform: 'translate(20px, -26px)' }}>
        <FontAwesomeIcon icon="trash" size="xs" className="text-color-a0a8bb hover:text-color-151d49" style={{ width: 16, height: 16 }} />
      </div>
    )
  }, [showLabelDecorator, forecastCategory, onRemoveCall])

  const minHeight = useMemo(() => {
    let height = 139
    if (isManager) height += 24
    return height
  }, [isManager])

  return (
    <div
      data-testid="CallCard"
      className="flex-1 mt-3 mr-2 last:mr-0 p-6 rounded-lg border border-color-2e5bff-08 bg-gradient-blue-to-white"
      style={{ minHeight }}>
      <div
        className="text-size-16px text-color-151d49 font-weight-600 whitespace-nowrap truncate">
        {name}
      </div>
      <ForecastCall
        animate={true}
        className="inline-block mb-1"
        style={{ height: 33 }}
        inputClassName="w-full px-2 bg-color-fafafa border border-color-d6d9e6 rounded focus:outline-none text-size-18px font-weight-500"
        inputStyle={{ paddingTop: 6, paddingBottom: 5 }}
        labelClassName={classNames('text-size-28px text-color-151d49 font-bold whitespace-nowrap',
          { 'underline-dashed-lg cursor-pointer': !readonly },
          { 'pointer-events-none cursor-default': readonly })}
        forecastCategory={forecastCategory}
        value={value}
        validator={validator}
        validatorMessage={validatorMessage}
        readonly={readonly}
        onCallChange={onCallChange}
        labelDecorator={labelDecorator}
        notifyError={notifyError}
        tooltip={forecastDeltaTooltip} />

      {forecast.current
        && (
          <>
            <>
              {isManager && !isPastForecastPeriod && (!readonly || debug)
                && (
                  <ManagersTeamCall
                    isLoggedInUsersTeam={isLoggedInUsersTeam}
                    isIcManagerAndDirectReport={isIcManagerAndDirectReport}
                    ownerId={ownerId}
                    forecastCategory={forecastCategory}
                    forecast={forecast}
                    currentCall={value} />
                )}

              {!hideQuota && (
                <PercentOfQuota
                  className="text-size-14px text-color-818e93 font-weight-400"
                  forecast={forecast}
                  value={value} />
              )}
            </>
          </>
        )}
    </div>
  )
}

export default CallCard
