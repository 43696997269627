import React, { useState, useEffect, useCallback } from 'react'
import Popover from '../popover'
import find from 'lodash/find'
import has from 'lodash/has'
import some from 'lodash/some'
import classNames from 'classnames'

const FilterSelector = (props) => {
  const {
    enabled,
    menuItems = [],
    disabledMenuItems = [],
    selectedKey,
    className,
    menuItemClassName,
    handleMenuItemClick,
    categoryClassName,
    display,
    maxHeight,
    showSecondaryMenuCallback,
    secondaryMenu
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [selectedItem, setSelectedItem] = useState({})
  useEffect(() => {
    const menuItem = find(menuItems, (m) => m.key === selectedKey)
    if (menuItem) {
      setSelectedItem(menuItem)
    }
  }, [menuItems, selectedKey])

  function handleClick(event) {
    if (!enabled) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleMenuItemClickInternal = useCallback((menuItem) => {
    const isDisabled = some(disabledMenuItems, (x) => x.key === menuItem.key)
    if (isDisabled) {
      return
    }

    handleMenuItemClick && handleMenuItemClick(menuItem, selectedItem)
    setSelectedItem(menuItem)
    if (!(showSecondaryMenuCallback && showSecondaryMenuCallback(menuItem.key))) {
      handleClose()
    }
  }, [selectedItem, handleMenuItemClick, disabledMenuItems])

  const renderMenu = useCallback(() => {
    if (showSecondaryMenuCallback && showSecondaryMenuCallback(selectedItem.key) && secondaryMenu) {
      return (
        <>
          {secondaryMenu}
        </>
      )
    } else {
      const categoriesRendered = {}
      return (
        <>
          {menuItems.map((m, i) => {
            let renderCategory = false
            if (has(m, 'category')) {
              renderCategory = !has(categoriesRendered, m.category)
              categoriesRendered[m.category] = true
            }
            const textStyle = has(m, 'data.level') ? { marginLeft: 20 * m.data.level, marginRight: 10 * m.data.level } : {}
            const isDisabled = some(disabledMenuItems, (x) => x.key === m.key)
            return (
              <React.Fragment key={i}>
                {m.category && renderCategory && <div className={categoryClassName}>{m.category}</div>}
                <div
                  className={classNames(menuItemClassName, { 'opacity-50 cursor-default': isDisabled })}
                  onClick={() => handleMenuItemClickInternal(m)}>
                  <span style={textStyle}>{m.name || '-'}</span>
                </div>
              </React.Fragment>
            )
          })}
        </>
      )
    }
  }, [menuItems, disabledMenuItems, selectedItem, showSecondaryMenuCallback, secondaryMenu])

  return (
    <>
      <span className={className} onClick={handleClick}>
        {display || (selectedItem.formatter ? selectedItem.formatter(selectedItem.name) : selectedItem.name)}
      </span>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div style={{ maxHeight }}>
          {renderMenu()}
        </div>
      </Popover>
    </>
  )
}

export default FilterSelector
