import { createAction } from '@reduxjs/toolkit'

export const getKeyMetricsByTeamKey = 'api/signal/getKeyMetricsByTeam'
export const getKeyMetricsByTeamRequested = createAction(`${getKeyMetricsByTeamKey}/requested`)
export const getKeyMetricsByTeamSuccess = createAction(`${getKeyMetricsByTeamKey}/success`)
export const getKeyMetricsByTeamFailed = createAction(`${getKeyMetricsByTeamKey}/failed`)

export const getSignalsByUserKey = 'api/signal/getSignalsByUser'
export const getSignalsByUserRequested = createAction(`${getSignalsByUserKey}/requested`)
export const getSignalsByUserSuccess = createAction(`${getSignalsByUserKey}/success`)
export const getSignalsByUserFailed = createAction(`${getSignalsByUserKey}/failed`)

export const getKeyMetricsByUserKey = 'api/signal/getKeyMetricsByUser'
export const getKeyMetricsByUserRequested = createAction(`${getKeyMetricsByUserKey}/requested`)
export const getKeyMetricsByUserSuccess = createAction(`${getKeyMetricsByUserKey}/success`)
export const getKeyMetricsByUserFailed = createAction(`${getKeyMetricsByUserKey}/failed`)

export const getSignalsByOpportunityKey = 'api/signal/getSignalsByOpportunity'
export const getSignalsByOpportunityRequested = createAction(`${getSignalsByOpportunityKey}/requested`)
export const getSignalsByOpportunitySuccess = createAction(`${getSignalsByOpportunityKey}/success`)
export const getSignalsByOpportunityFailed = createAction(`${getSignalsByOpportunityKey}/failed`)

export const getSignalStrengthsByUserKey = 'api/signal/getSignalStrengthsByUser'
export const getSignalStrengthsByUserRequested = createAction(`${getSignalStrengthsByUserKey}/requested`)
export const getSignalStrengthsByUserSuccess = createAction(`${getSignalStrengthsByUserKey}/success`)
export const getSignalStrengthsByUserFailed = createAction(`${getSignalStrengthsByUserKey}/failed`)

export const getSignalWeaknessesByUserKey = 'api/signal/getSignalWeaknessesByUser'
export const getSignalWeaknessesByUserRequested = createAction(`${getSignalWeaknessesByUserKey}/requested`)
export const getSignalWeaknessesByUserSuccess = createAction(`${getSignalWeaknessesByUserKey}/success`)
export const getSignalWeaknessesByUserFailed = createAction(`${getSignalWeaknessesByUserKey}/failed`)

export const getSignalByIdKey = 'api/signal/getSignalById'
export const getSignalByIdRequested = createAction(`${getSignalByIdKey}/requested`)
export const getSignalByIdSuccess = createAction(`${getSignalByIdKey}/success`)
export const getSignalByIdFailed = createAction(`${getSignalByIdKey}/failed`)

export const getSummaryStatsByUserKey = 'api/signal/getSummaryStatsByUser'
export const getSummaryStatsByUserRequested = createAction(`${getSummaryStatsByUserKey}/requested`)
export const getSummaryStatsByUserSuccess = createAction(`${getSummaryStatsByUserKey}/success`)
export const getSummaryStatsByUserFailed = createAction(`${getSummaryStatsByUserKey}/failed`)

export const getHeaderMetricsByTeamKey = 'api/signal/getHeaderMetricsByTeam'
export const getHeaderMetricsByTeamRequested = createAction(`${getHeaderMetricsByTeamKey}/requested`)
export const getHeaderMetricsByTeamSuccess = createAction(`${getHeaderMetricsByTeamKey}/success`)
export const getHeaderMetricsByTeamFailed = createAction(`${getHeaderMetricsByTeamKey}/failed`)

export const getHeaderMetricsByUserKey = 'api/signal/getHeaderMetricsByUser'
export const getHeaderMetricsByUserRequested = createAction(`${getHeaderMetricsByUserKey}/requested`)
export const getHeaderMetricsByUserSuccess = createAction(`${getHeaderMetricsByUserKey}/success`)
export const getHeaderMetricsByUserFailed = createAction(`${getHeaderMetricsByUserKey}/failed`)

export const getDashboardMetricsKey = 'api/signal/getDashboardMetrics'
export const getDashboardMetricsRequested = createAction(`${getDashboardMetricsKey}/requested`)
export const getDashboardMetricsSuccess = createAction(`${getDashboardMetricsKey}/success`)
export const getDashboardMetricsFailed = createAction(`${getDashboardMetricsKey}/failed`)
