/* eslint-disable max-classes-per-file */
export class FunctionEvalError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'FunctionEvalError'
    this.err = err
  }
}

export class EvalControlError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'EvalControlError'
    this.err = err
  }
}

export class AttributeEvalError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'AttributeEvalError'
    this.err = err
  }
}

export class PluginEvalError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'PluginEvalError'
    this.err = err
  }
}

export class BarChartFormatterError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'BarChartFormatterError'
    this.err = err
  }
}

export class FunnelChartFormatterError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'FunnelChartFormatterError'
    this.err = err
  }
}

export class PropTypesError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'PropTypesError'
    this.err = err
  }
}

export class RegisteredFunctionError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'RegisteredFunctionError'
    this.err = err
  }
}

/* eslint-enable max-classes-per-file */
