import React, { useState } from 'react'
import classNames from 'classnames'

const Avatar = (props) => {
  const { imageUrl, text, className, isRound } = props

  const [showDefault, setShowDefault] = useState(false)
  const handleError = (e) => {
    setShowDefault(true)
  }

  const classes = classNames(
    'avatar', className,
    { 'rounded-full': isRound },
    { 'rounded-lg': !isRound }
  )

  return (
    <>
      {showDefault || !imageUrl
        ? <div className={classes}>{text}</div>
        : <img className={classes} src={imageUrl} onError={handleError} style={{ objectFit: 'cover' }} />}
    </>
  )
}

export default Avatar
