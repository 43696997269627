import React, { useMemo } from 'react'
import { useAuth } from '../../context/auth'
import { CanvasProvider } from '../../context/canvas'
import { CanvasItemProvider } from '../../context/canvasItem'
import { RepSignalsProvider } from '../../context/repSignals'
import RepSignals from '../signals/repSignals'
import { canvasKey } from './constants'

const RepSignalsCanvas = (props) => {
  const { userId } = props
  const { actingUserId } = useAuth()

  const headers = useMemo(() => {
    return { ...userId === actingUserId && { forceuser: true } }
  }, [userId, actingUserId])

  return (
    <CanvasProvider
      key={`CanvasProvider-${canvasKey.REP_SIGNALS}-${userId}`}
      canvasKey={canvasKey.REP_SIGNALS}
      userId={userId}>
      <CanvasItemProvider>
        <RepSignalsProvider
          userId={userId}
          headers={headers}>
          <RepSignals
            key={`RepSignals-${userId}`}
            userId={userId} />
        </RepSignalsProvider>
      </CanvasItemProvider>
    </CanvasProvider>
  )
}

export default RepSignalsCanvas
