import React from 'react'
import IconTip from './iconTip'
import { iconType } from './icon'

const InfoTip = (props) => {
  const { content, size } = props
  return (
    <IconTip
      content={content}
      iconProps={{
        type: iconType.FONTAWESOME,
        iconName: 'info-circle',
        color: '#a7aebf',
        size
      }} />
  )
}

export default InfoTip
