import React, { useCallback, useMemo } from 'react'
import FilterSelector from './filterSelector'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { DateRangeOption, CloseDateRangeOption, PipelineReportGroupingOption } from '../../protos/src/common/reporting/pipeline_report_pb'
import { DateRange } from 'react-date-range'
import moment from 'moment'
import { filter, find, forEach, get, orderBy, uniq } from 'lodash'
import Icon, { iconType } from '../icon'
import {
  clearPipelineReportSelections,
  updatePipelineReportSelection,
  setPipelineReportRoles,
  removePipelineReportRole,
  setPipelineReportFilters,
  removePipelineReportFilter,
  setPipelineReportUsers,
  removePipelineReportUser,
} from '../../actions'
import MultiSelectFilter from './multiSelectFilter'
import MultiSelectFilterVirtualized from './multiSelectFilterVirtualized'
import { apiRequestedSelector } from '../../selectors'
import { getPipelineReportOptionsKey } from '../../actions/reportingService'
import { Skeleton } from '@material-ui/lab'

const ReportInput = (props) => {
  const { isRunning, handleRunClick } = props

  const dispatch = useDispatch()

  const apiRequested = useSelector((state) => apiRequestedSelector(state, getPipelineReportOptionsKey))

  const pipelineReportOptions = useSelector((state) => state.pipelineReportOptions)
  const {
    dateRangeOptionsList = [],
    closeDateRangeOptionsList = [],
    groupByOptionsList = [],
    filterOptionsList = [],
    roleOptions = undefined,
    roleFieldInfo,
    userOptions
  } = pipelineReportOptions

  const pipelineReportSelections = useSelector((state) => state.pipelineReportSelections)
  const { selectedDateRange, startDate, endDate, selectedCloseDateRange, closeDateRangeStart, closeDateRangeEnd, selectedFilters, selectedRoles } = pipelineReportSelections

  const calendarRange = useMemo(() => {
    return [
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection'
      }
    ]
  }, [startDate, endDate])

  const closeDateCalendarRange = useMemo(() => {
    return [
      {
        startDate: new Date(closeDateRangeStart),
        endDate: new Date(closeDateRangeEnd),
        key: 'selection'
      }
    ]
  }, [closeDateRangeStart, closeDateRangeEnd])

  const dateRangeItems = useMemo(() => {
    return dateRangeOptionsList.map((x) => {
      const option = {
        key: x.value,
        name: x.label,
        formatter: (value) => value.toLowerCase()
      }
      if (x.value === DateRangeOption.CUSTOM) {
        option.formatter = () => {
          const { startDate, endDate } = calendarRange[0]
          const start = moment(startDate).format('MMM D, YYYY')
          const end = moment(endDate).format('MMM D, YYYY')
          return `between ${start} and ${end}`
        }
      }
      return option
    })
  }, [dateRangeOptionsList, calendarRange])

  const closeDateRangeItems = useMemo(() => {
    return closeDateRangeOptionsList.map((x) => {
      const option = {
        key: x.value,
        name: x.label,
        formatter: (value) => value.toLowerCase()
      }
      if (x.value === CloseDateRangeOption.CUSTOM) {
        option.formatter = () => {
          const { startDate, endDate } = closeDateCalendarRange[0]
          const start = moment(startDate).format('MMM D, YYYY')
          const end = moment(endDate).format('MMM D, YYYY')
          return `between ${start} and ${end}`
        }
      }
      return option
    })
  }, [closeDateRangeOptionsList, closeDateCalendarRange])

  const groupByItems = useMemo(() => {
    return groupByOptionsList.map((x) => {
      return {
        key: get(x, 'value', 0),
        name: x.label,
        formatter: (value) => value.toLowerCase()
      }
    })
  }, [groupByOptionsList])

  const selectedGroupBy = useMemo(() => {
    if (pipelineReportSelections.selectedGroupBy !== undefined
      && find(groupByItems, (o) => o.key === pipelineReportSelections.selectedGroupBy)) {
      return pipelineReportSelections.selectedGroupBy
    } else if (groupByItems.length > 0) {
      return get(groupByItems, '[0].key')
    }
  }, [groupByOptionsList, groupByItems, pipelineReportSelections])

  const roleItems = useMemo(() => {
    const roles = []
    function getRoles(current, group) {
      const g = [...group, current.roleId]
      roles.push({ level: g.length - 1, group: g, ...current })
      current.childrenList && forEach(orderBy(current.childrenList, [(c) => c.roleName.toLowerCase()]), (x) => getRoles(x, g))
    }
    roleOptions && getRoles(roleOptions, [])
    return roles.map((r) => { return { key: r.roleId, name: r.roleName, data: r } })
  }, [roleOptions])

  const filterItems = useMemo(() => {
    const items = []
    forEach(filterOptionsList, (o) => {
      forEach(o.optionItemsList, (option) => {
        items.push({ key: option.key, name: option.value, category: o.label, data: o })
      })
    })
    return orderBy(items, [(i) => i.category.toLowerCase(), (i) => i.name.toLowerCase()])
  }, [filterOptionsList])

  const userMenuItems = useMemo(() => {
    const u = orderBy(get(userOptions, 'userOptionsList', []), [(u) => u.name.toLowerCase()])
    return u.map((x) => {
      return {
        key: x.id,
        name: x.name
      }
    })
  }, [userOptions])

  const selectedUsers = useMemo(() => {
    const users = get(pipelineReportSelections, 'selectedUsers', []).map((u) => {
      const userOptionsList = get(userOptions, 'userOptionsList', [])
      if (userOptionsList.length === 0) {
        return u
      }

      const user = {
        ...u
      }
      const userOption = find(userOptionsList, (x) => x.id === user.key)
      if (userOption) {
        user.name = userOption.name
      }
      return user
    })
    return users
  }, [pipelineReportSelections, pipelineReportOptions])

  const updateCalendarRange = useCallback((calendarRange) => {
    dispatch(updatePipelineReportSelection({
      startDate: calendarRange[0].startDate.toLocaleDateString('en-US'),
      endDate: calendarRange[0].endDate.toLocaleDateString('en-US')
    }))
  }, [])

  const updateCloseDateRange = useCallback((closeDateCalendarRange) => {
    dispatch(updatePipelineReportSelection({
      closeDateRangeStart: closeDateCalendarRange[0].startDate.toLocaleDateString('en-US'),
      closeDateRangeEnd: closeDateCalendarRange[0].endDate.toLocaleDateString('en-US')
    }))
  }, [])

  const handleDateRangeChange = useCallback((menuItem) => {
    if (menuItem.key !== DateRangeOption.CUSTOM) {
      dispatch(updatePipelineReportSelection({
        startDate: new Date().toLocaleDateString('en-US'),
        endDate: new Date().toLocaleDateString('en-US')
      }))
    }
    dispatch(updatePipelineReportSelection({ selectedDateRange: menuItem.key }))
    window.analytics.track('reporting.input.dateRangeChanged', { name: menuItem.name })
  }, [])

  const handleCloseDateRangeChange = useCallback((menuItem) => {
    if (menuItem.key !== CloseDateRangeOption.CUSTOM) {
      dispatch(updatePipelineReportSelection({
        closeDateRangeStart: new Date().toLocaleDateString('en-US'),
        closeDateRangeEnd: new Date().toLocaleDateString('en-US')
      }))
    }
    dispatch(updatePipelineReportSelection({ selectedCloseDateRange: menuItem.key }))
    window.analytics.track('reporting.input.closeDateRangeChanged', { name: menuItem.name })
  }, [])

  const handleGroupByChange = useCallback((menuItem) => {
    dispatch(updatePipelineReportSelection({ selectedGroupBy: menuItem.key }))
    window.analytics.track('reporting.input.groupByChanged', { name: menuItem.name })
  }, [])

  const handleSelectedRolesChanged = useCallback((selectedRoles) => {
    dispatch(setPipelineReportRoles(selectedRoles))
    window.analytics.track('reporting.input.setRoles', { roles: selectedRoles })
  }, [])

  const removeRole = useCallback((role) => {
    dispatch(removePipelineReportRole(role))
    window.analytics.track('reporting.input.roleRemoved', { name: get(role, 'roleName') })
  }, [])

  const handleSelectedFiltersChanged = useCallback((selectedFilters) => {
    dispatch(setPipelineReportFilters(selectedFilters))
    window.analytics.track('reporting.input.setFilters', { filters: selectedFilters })
  }, [])

  const removeFilter = useCallback((filter) => {
    dispatch(removePipelineReportFilter(filter))
    window.analytics.track('reporting.input.filterRemoved', { name: filter.name })
  }, [])

  const handleSelectedUsersChanged = useCallback((selectedUsers) => {
    dispatch(setPipelineReportUsers(selectedUsers))
    window.analytics.track('reporting.input.setUsers', { users: selectedUsers })
  }, [])

  const removeUser = useCallback((key, name) => {
    dispatch(removePipelineReportUser({ key }))
    window.analytics.track('reporting.input.userRemoved', { name })
  }, [])

  const moreOptionsClicked = useCallback(() => {
    dispatch(updatePipelineReportSelection({ selectedDateRange: DateRangeOption.PREVIOUS_MONTH }))
    window.analytics.track('reporting.input.moreOptionsClicked')
  }, [])

  const closeDateMoreOptionsClicked = useCallback(() => {
    dispatch(updatePipelineReportSelection({ selectedCloseDateRange: CloseDateRangeOption.REPORT }))
    window.analytics.track('reporting.input.closeDateMoreOptionsClicked')
  }, [])

  const handleClick = useCallback(() => {
    if (isRunning) {
      return
    }

    handleRunClick && handleRunClick({
      selectedDateRange,
      calendarRange,
      selectedCloseDateRange,
      closeDateCalendarRange,
      selectedGroupBy,
      selectedFilters,
      selectedRoles,
      roleFieldInfo,
      selectedUsers,
      userFieldInfo: {
        label: get(userOptions, 'label'),
        field: get(userOptions, 'field'),
        type: get(userOptions, 'fieldtype'),
      }
    })
  }, [isRunning, pipelineReportSelections, calendarRange, closeDateCalendarRange, selectedGroupBy, userOptions])

  const handleResetClick = useCallback(() => {
    if (isRunning) {
      return
    }
    dispatch(clearPipelineReportSelections())
    window.analytics.track('reporting.input.resetClicked')
  }, [isRunning])

  const showReset = useMemo(() => {
    const defaultOptions = {
      selectedDateRange: DateRangeOption.PREVIOUS_MONTH,
      selectedGroupBy: PipelineReportGroupingOption.FORECASTCATEGORY,
    }
    return selectedDateRange !== defaultOptions.selectedDateRange
      || selectedGroupBy !== defaultOptions.selectedGroupBy
      || selectedUsers.length > 0
      || selectedRoles.length > 0
      || selectedFilters.length > 0
  }, [selectedDateRange, selectedGroupBy, selectedUsers, selectedRoles, selectedFilters])

  const d = new Date()
  const yearFromNow = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate())

  const selectedRoleItems = useMemo(() => {
    const roles = []
    forEach(selectedRoles, (r) => {
      const role = find(roleItems, (roleItem) => roleItem.key === r.key)
      if (role) {
        roles.push({
          ...role,
          roleName: role.name,
          checked: r.checked
        })
      }
    })
    return roles
  }, [selectedRoles, roleItems])

  const selectedFilterItems = useMemo(() => {
    const items = []
    forEach(selectedFilters, (f) => {
      const values = get(f, 'comparisonList[0].valuesList', [])
      forEach(values, (v) => {
        const filterItem = {
          ...find(filterItems, (fi) => fi.category === f.key && fi.key === v),
          checked: f.checked,
        }
        if (filterItem) items.push(filterItem)
      })
    })
    return items
  }, [selectedFilters, filterItems])

  const buildText = useCallback(() => {
    const minDate = pipelineReportOptions.minDatetime ? moment(pipelineReportOptions.minDatetime.seconds * 1000).utc().toDate() : undefined

    const includedUsers = filter(selectedUsers, (x) => x.checked === 'include')
    const excludedUsers = filter(selectedUsers, (x) => x.checked === 'exclude')
    const includedRoles = filter(selectedRoleItems, (x) => x.checked === 'include')
    const excludedRoles = filter(selectedRoleItems, (x) => x.checked === 'exclude')
    const includedFilters = filter(selectedFilterItems, (x) => x.checked === 'include')
    const excludedFilters = filter(selectedFilterItems, (x) => x.checked === 'exclude')

    const includedFilterCategories = uniq(includedFilters.map((f) => f.category)).map((category) => {
      return {
        category,
        filters: filter(includedFilters, (f) => f.category === category)
      }
    })

    const excludedFilterCategories = uniq(excludedFilters.map((f) => f.category)).map((category) => {
      return {
        category,
        filters: filter(excludedFilters, (f) => f.category === category)
      }
    })

    return (
      <>
        <span>What moved through the pipeline </span>
        <FilterSelector
          enabled={!isRunning}
          menuItems={dateRangeItems}
          selectedKey={selectedDateRange}
          className="text-color-2e5bff underline-dashed cursor-pointer"
          menuItemClassName="px-3 py-2 cursor-pointer text-size-15px font-normal whitespace-nowrap truncate bg-color-transparent hover:bg-gradient-green hover:text-color-ffffff"
          handleMenuItemClick={handleDateRangeChange}
          showSecondaryMenuCallback={(selectedValue) => selectedValue === DateRangeOption.CUSTOM}
          secondaryMenu={(
            <div>
              <div className="flex items-center justify-between px-3">
                <div className="py-2 text-size-16px">Select a Range</div>
                <div className="text-size-12px text-color-2e5bff underline cursor-pointer" onClick={moreOptionsClicked}>More Options</div>
              </div>
              <DateRange
                editableDateInputs={false}
                onChange={(item) => updateCalendarRange([item.selection])}
                moveRangeOnFirstSelection={false}
                minDate={minDate}
                maxDate={yearFromNow}
                ranges={calendarRange} />
            </div>
          )} />
        <span> closing </span>
        <FilterSelector
          enabled={!isRunning}
          menuItems={closeDateRangeItems}
          selectedKey={selectedCloseDateRange}
          className="text-color-2e5bff underline-dashed cursor-pointer"
          menuItemClassName="px-3 py-2 cursor-pointer text-size-15px font-normal whitespace-nowrap truncate bg-color-transparent hover:bg-gradient-green hover:text-color-ffffff"
          handleMenuItemClick={handleCloseDateRangeChange}
          showSecondaryMenuCallback={(selectedValue) => selectedValue === CloseDateRangeOption.CUSTOM}
          secondaryMenu={(
            <div>
              <div className="flex items-center justify-between px-3">
                <div className="py-2 text-size-16px">Select a Range</div>
                <div className="text-size-12px text-color-2e5bff underline cursor-pointer" onClick={closeDateMoreOptionsClicked}>More Options</div>
              </div>
              <DateRange
                editableDateInputs={false}
                onChange={(item) => updateCloseDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                minDate={minDate}
                maxDate={yearFromNow}
                ranges={closeDateCalendarRange} />
            </div>
          )} />
        <span> by </span>
        <FilterSelector
          enabled={!isRunning}
          menuItems={groupByItems}
          selectedKey={selectedGroupBy}
          className="text-color-2e5bff underline-dashed cursor-pointer"
          menuItemClassName="px-3 py-2 cursor-pointer text-size-15px font-normal whitespace-nowrap truncate bg-color-transparent hover:bg-gradient-green hover:text-color-ffffff"
          handleMenuItemClick={handleGroupByChange} />

        {(selectedUsers.length > 0 || selectedRoles.length > 0 || selectedFilters.length > 0) && <span> where </span>}

        {includedUsers.length > 0 && (
          <>
            {includedUsers.map((u, i) => (
              <React.Fragment key={i}>
                {i === 0 && <span> owner is </span>}
                <span className="text-color-2e5bff cursor-pointer group whitespace-nowrap">
                  <MultiSelectFilterVirtualized
                    enabled={!isRunning}
                    menuItems={userMenuItems}
                    selectedItems={selectedUsers}
                    className="text-color-2e5bff underline-dashed cursor-pointer whitespace-nowrap"
                    menuItemClassName="px-4 py-0 cursor-pointer"
                    categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                    display={<>{u.name}</>}
                    maxHeight={470}
                    handleSelectionChanged={handleSelectedUsersChanged} />
                  <span onClick={() => removeUser(u.key, u.name)} className="inline" style={{ paddingLeft: 3, paddingRight: 2 }}>
                    <Icon type={iconType.FONTAWESOME} iconName="times-circle" color="#bdc9e2" style={{ marginBottom: -1, width: 10 }} />
                  </span>
                </span>
                {i < includedUsers.length - 1 && <span> or </span>}
              </React.Fragment>
            ))}
          </>
        )}

        {excludedUsers.length > 0 && (
          <>
            {excludedUsers.map((u, i) => (
              <React.Fragment key={i}>
                {i === 0 && (
                  <span>
                    {' '}
                    {includedUsers.length > 0 && <>and</>}
                    {' '}
                    owner is not
                    {' '}
                  </span>
                )}
                <span className="text-color-2e5bff cursor-pointer group whitespace-nowrap">
                  <MultiSelectFilterVirtualized
                    enabled={!isRunning}
                    menuItems={userMenuItems}
                    selectedItems={selectedUsers}
                    className="text-color-2e5bff underline-dashed cursor-pointer whitespace-nowrap"
                    menuItemClassName="px-4 py-0 cursor-pointer"
                    categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                    display={<>{u.name}</>}
                    maxHeight={470}
                    handleSelectionChanged={handleSelectedUsersChanged} />
                  <span onClick={() => removeUser(u.key, u.name)} className="inline" style={{ paddingLeft: 3, paddingRight: 2 }}>
                    <Icon type={iconType.FONTAWESOME} iconName="times-circle" color="#bdc9e2" style={{ marginBottom: -1, width: 10 }} />
                  </span>
                </span>
                {i < excludedUsers.length - 1 && <span> or </span>}
              </React.Fragment>
            ))}
          </>
        )}

        {includedRoles.length > 0 && (
          <>
            {includedRoles.map((r, i) => (
              <React.Fragment key={i}>
                {i === 0 && (
                  <span>
                    {' '}
                    {selectedUsers.length > 0 && <>and</>}
                    {' '}
                    role is
                    {' '}
                  </span>
                )}
                <span className="text-color-2e5bff cursor-pointer group whitespace-nowrap">
                  <MultiSelectFilter
                    enabled={!isRunning}
                    menuItems={roleItems}
                    selectedItems={selectedRoleItems}
                    className="text-color-2e5bff underline-dashed cursor-pointer whitespace-nowrap"
                    menuItemClassName="px-4 py-0 cursor-pointer"
                    categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                    display={<>{r.roleName}</>}
                    maxHeight={470}
                    handleSelectionChanged={handleSelectedRolesChanged} />
                  <span onClick={() => removeRole(r)} className="inline" style={{ paddingLeft: 3, paddingRight: 2 }}>
                    <Icon type={iconType.FONTAWESOME} iconName="times-circle" color="#bdc9e2" style={{ marginBottom: -1, width: 10 }} />
                  </span>
                </span>
                {i < includedRoles.length - 1 && <span> or </span>}
              </React.Fragment>
            ))}
          </>
        )}

        {excludedRoles.length > 0 && (
          <>
            {excludedRoles.map((r, i) => (
              <React.Fragment key={i}>
                {i === 0 && (
                  <span>
                    {' '}
                    {(includedRoles.length > 0 || selectedUsers.length > 0) && <>and</>}
                    {' '}
                    role is not
                    {' '}
                  </span>
                )}
                <span className="text-color-2e5bff cursor-pointer group whitespace-nowrap">
                  <MultiSelectFilter
                    enabled={!isRunning}
                    menuItems={roleItems}
                    selectedItems={selectedRoleItems}
                    className="text-color-2e5bff underline-dashed cursor-pointer whitespace-nowrap"
                    menuItemClassName="px-4 py-0 cursor-pointer"
                    categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                    display={<>{r.roleName}</>}
                    maxHeight={470}
                    handleSelectionChanged={handleSelectedRolesChanged} />
                  <span onClick={() => removeRole(r)} className="inline" style={{ paddingLeft: 3, paddingRight: 2 }}>
                    <Icon type={iconType.FONTAWESOME} iconName="times-circle" color="#bdc9e2" style={{ marginBottom: -1, width: 10 }} />
                  </span>
                </span>
                {i < excludedRoles.length - 1 && <span> or </span>}
              </React.Fragment>
            ))}
          </>
        )}

        {includedFilterCategories.length > 0 && (
          <>
            {includedFilterCategories.map((filterCategory, i) => (
              <React.Fragment key={`includedFilterCategory-${i}`}>
                {i === 0 ? (
                  <span>
                    {' '}
                    {(selectedUsers.length > 0 || selectedRoles.length > 0) && <>and</>}
                    {' '}
                    {filterCategory.category}
                    {' '}
                    is
                    {' '}
                  </span>
                ) : (
                  <span>
                    {' '}
                    {filterCategory.category}
                    {' '}
                    is
                    {' '}
                  </span>
                )}
                {filterCategory.filters.map((f, j) => (
                  <React.Fragment key={`includedFilters-${j}`}>
                    <span className="text-color-2e5bff cursor-pointer group whitespace-nowrap">
                      <MultiSelectFilterVirtualized
                        enabled={!isRunning}
                        menuItems={filterItems}
                        selectedItems={selectedFilterItems}
                        className="text-color-2e5bff underline-dashed cursor-pointer whitespace-nowrap"
                        menuItemClassName="px-4 py-0 cursor-pointer"
                        categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                        display={<>{f.name}</>}
                        maxHeight={470}
                        handleSelectionChanged={handleSelectedFiltersChanged} />
                      <span onClick={() => removeFilter(f, f.name)} className="inline" style={{ paddingLeft: 3, paddingRight: 2 }}>
                        <Icon type={iconType.FONTAWESOME} iconName="times-circle" color="#bdc9e2" style={{ marginBottom: -1, width: 10 }} />
                      </span>
                    </span>
                    {j < filterCategory.filters.length - 1 && <span> or </span>}
                  </React.Fragment>
                ))}
                {i < includedFilterCategories.length - 1 && <span> and </span>}
              </React.Fragment>
            ))}
          </>
        )}

        {excludedFilterCategories.length > 0 && (
          <>
            {excludedFilterCategories.map((filterCategory, i) => (
              <React.Fragment key={`excludedFilterCategory-${i}`}>
                {i === 0 ? (
                  <span>
                    {' '}
                    {(includedFilters.length > 0 || selectedUsers.length > 0 || selectedRoles.length > 0) && <>and</>}
                    {' '}
                    {filterCategory.category}
                    {' '}
                    is not
                    {' '}
                  </span>
                ) : (
                  <span>
                    {' '}
                    {filterCategory.category}
                    {' '}
                    is not
                    {' '}
                  </span>
                )}
                {filterCategory.filters.map((f, j) => (
                  <React.Fragment key={`excludedFilters-${j}`}>
                    <span className="text-color-2e5bff cursor-pointer group whitespace-nowrap">
                      <MultiSelectFilterVirtualized
                        enabled={!isRunning}
                        menuItems={filterItems}
                        selectedItems={selectedFilterItems}
                        className="text-color-2e5bff underline-dashed cursor-pointer whitespace-nowrap"
                        menuItemClassName="px-4 py-0 cursor-pointer"
                        categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                        display={<>{f.name}</>}
                        maxHeight={470}
                        handleSelectionChanged={handleSelectedFiltersChanged} />
                      <span onClick={() => removeFilter(f, f.name)} className="inline" style={{ paddingLeft: 3, paddingRight: 2 }}>
                        <Icon type={iconType.FONTAWESOME} iconName="times-circle" color="#bdc9e2" style={{ marginBottom: -1, width: 10 }} />
                      </span>
                    </span>
                    {j < filterCategory.filters.length - 1 && <span> or </span>}
                  </React.Fragment>
                ))}
                {i < excludedFilterCategories.length - 1 && <span> and </span>}
              </React.Fragment>
            ))}
          </>
        )}

        <span>?</span>

      </>
    )
  }, [pipelineReportOptions, pipelineReportSelections, isRunning, selectedUsers, selectedRoles, selectedFilters, filterItems, selectedGroupBy, selectedFilterItems])

  const classes = classNames({ 'opacity-50': isRunning })
  return (
    <div className={classes}>
      {apiRequested
        ? (
          <div className="w-4/5">
            <Skeleton animation="wave" />
          </div>
        )
        : (
          <div className="text-size-16px text-color-151d49 font-weight-600 pt-1">
            {buildText()}
            {userMenuItems.length > 0 && (
              <MultiSelectFilterVirtualized
                enabled={!isRunning}
                menuItems={userMenuItems}
                selectedItems={selectedUsers}
                className="cursor-pointer text-color-4e5d7e text-size-14px ml-4"
                menuItemClassName="px-4 py-0 cursor-pointer"
                categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                display={<>&#43; add user</>}
                maxHeight={525}
                handleSelectionChanged={handleSelectedUsersChanged} />
            )}
            {roleItems.length > 0 && (
              <MultiSelectFilter
                enabled={!isRunning}
                menuItems={roleItems}
                selectedItems={selectedRoleItems}
                className="cursor-pointer text-color-4e5d7e text-size-14px ml-4"
                menuItemClassName="px-4 py-0 cursor-pointer"
                categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                display={<>&#43; add group</>}
                maxHeight={525}
                width={400}
                handleSelectionChanged={handleSelectedRolesChanged} />
            )}
            {filterItems.length > 0 && (
              <MultiSelectFilterVirtualized
                enabled={!isRunning}
                menuItems={filterItems}
                selectedItems={selectedFilterItems}
                className="cursor-pointer text-color-4e5d7e text-size-14px ml-4"
                menuItemClassName="px-4 py-0 cursor-pointer"
                categoryClassName="px-4 py-2 text-size-15px font-bold w-full flex justify-between"
                display={<>&#43; add filter</>}
                maxHeight={525}
                minHeight={75}
                handleSelectionChanged={handleSelectedFiltersChanged} />
            )}
          </div>
        )}

      <div className={classNames('mt-8 flex items-center', { 'opacity-50 pointer-events-none': apiRequested })}>
        <button onClick={handleClick} className="px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none">Run</button>
        {showReset && (
          <button onClick={handleResetClick} className="ml-4 text-size-14px text-color-a6b2cf focus:outline-none cursor-pointer">
            <Icon type={iconType.FONTAWESOME} iconName="redo-alt" color="#a6b2cf" size="xs" />
            {' '}
            reset
          </button>
        )}
      </div>
    </div>
  )
}

export default ReportInput
