import { noop } from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const SearchMenuItem = ({ children, label = '', onClick = noop, selected, ...props }) => {
  return (
    <div
      className={
        classNames(
          'group flex items-center justify-between px-4 py-2',
          'text-size-14px text-left cursor-pointer focus:outline-none hover:bg-color-edeeee leading-tight',
          { 'font-weight-500 text-color-09242f ': !selected },
          { 'font-bold text-color-5951FF': selected }
        )
      }
      label={label}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  )
}

SearchMenuItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export default SearchMenuItem
