import React, { useCallback, useMemo } from 'react'
import Avatar from '../avatar/avatar'
import { useRoutes } from '../../context/routes'
import numeral from 'numeral'
import MetricItem from '../metrics/metricItem'
import take from 'lodash/take'
import { useHistory } from 'react-router-dom'
import { useUserImageUrl } from '../../hooks/useUserImageUrl'

const TeamMember = (props) => {
  const { rank, user } = props
  const { name, photoUrl, goals = [] } = user

  const { userImageUrl } = useUserImageUrl(photoUrl)

  const { routes } = useRoutes()

  const history = useHistory()

  const metrics = useMemo(() => {
    return take(goals, 3)
  }, [goals])

  const handleUserClick = useCallback(() => {
    window.analytics.track('My Team User Name Link')
    history.push(routes.rep.replace(':userId', user.id))
  }, [])

  return (
    <div className="py-3 border-t separator">
      <div className="flex justify-between">
        <div className="flex items-center mb-3 cursor-pointer focus:outline-none" onClick={handleUserClick}>
          <Avatar className="w-9 h-9 mr-3" imageUrl={userImageUrl} text={props.user.firstName.charAt(0)} isRound={true} />
          <div>
            <div className="text-size-20px text-color-151d49 font-bold leading-tight">{name}</div>
            <div className="text-color-a0a8bb">{numeral(rank).format('0o')}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        {metrics.length > 0
          ? (
            <>
              {metrics.map((metric, i) => (
                <MetricItem
                  key={`TeamMemberMetricItem-${i}`}
                  className="w-1/3 flex"
                  showSeparator={i > 0}
                  separatorClassName="w-1 h-8 border-r separator mx-3"
                  labelClassName="text-size-10px text-color-91959f font-weight-500 tracking-widest uppercase"
                  valueClassName="text-size-14px text-color-151d49 font-bold"
                  metric={metric} />
              ))}
            </>
          )
          : <div className="text-size-10px text-color-91959f font-weight-500 tracking-widest uppercase">No Goals</div>}
      </div>
    </div>
  )
}

export default TeamMember
