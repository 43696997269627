import React, { useCallback, useMemo } from 'react'
import GenericModal from '../modal/genericModal'
import { Close } from '@getoutreach/react-icons'
import RefreshDownloadCheck from '../icons/refreshDownloadCheck'

const DownloadModal = (props) => {
  const {
    modal,
    requested = false,
    available = false,
    complete = false,
    errorText
  } = props

  const { open, handleClose } = modal

  const handleCloseInternal = useCallback(() => {
    handleClose && handleClose()
  }, [handleClose])

  const loadingText = useMemo(() => {
    if (requested && available && !complete) {
      return {
        title: 'Downloading',
        subTitle: <span>Generating and downloading your pipeline file. Just a moment.</span>,
      }
    } else if (requested && available && complete) {
      return {
        title: 'Complete',
        subTitle: <span>Your goals file has been successfully generated and downloaded.</span>,
      }
    } else {
      return {
        title: 'Generating',
        subTitle: <span>We are generating your pipeline file. Just a moment.</span>,
      }
    }
  }, [requested, available, complete])

  return (
    <GenericModal
      maxWidth="sm"
      handleClose={handleCloseInternal}
      open={open}>

      <div className="w-full flex flex-col font-normal">
        <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
          <div className="text-size-22px text-color-ffffff font-bold">Download View</div>
          <button onClick={handleCloseInternal} className="focus:outline-none"><Close htmlColor="#ffffff" /></button>
        </div>
        {!!errorText && (
          <div className="flex justify-center bg-color-fa6d6b text-size-16px text-color-ffffff font-weight-600 py-2 mt-6">
            {errorText}
          </div>
        )}
        <div className="w-full h-full px-10 py-10 leading-tight overflow-y-auto overflow-x-hidden" style={{ maxHeight: 'calc(100vh * 0.85)' }}>
          <div className="flex justify-center w-full h-full my-10">
            <div className="text-center">
              <div className="flex justify-center my-3">
                <RefreshDownloadCheck
                  refresh={requested}
                  download={available}
                  check={complete} />
              </div>
              <div className="text-size-24px text-color-151d49 opacity-50 font-weight-600">{loadingText.title}</div>
              <div className="text-size-14px text-color-51636a font-weight-400 leading-none">{loadingText.subTitle}</div>
            </div>
          </div>
        </div>
      </div>
    </GenericModal>
  )
}

export default DownloadModal
