import React, { useState, useRef, useEffect } from 'react'
import Popover from '../popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DealSizeSlider from './dealSizeSlider'
import numeral from 'numeral'
import classNames from 'classnames'

const DealDropDownFilter = (props) => {
  const { min, max, step, defaultValue, handleChangeCommitted } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const buttonRef = useRef(null)

  const [popoverWidth, setPopoverWidth] = useState(100)
  function handleClick(event) {
    setAnchorEl(event.currentTarget)
    setPopoverWidth(buttonRef.current.clientWidth)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function formatLabel(val) {
    return numeral(val).format('$0,0')
  }

  function handleChangeCommittedInternal(_, newValue) {
    handleChangeCommitted(_, newValue)
  }

  const [label, setLabel] = useState('')
  useEffect(() => {
    if (min === defaultValue[0] && max === defaultValue[1]) {
      setLabel('Any')
    } else {
      setLabel(`${formatLabel(defaultValue[0])} - ${formatLabel(defaultValue[1])}`)
    }
  }, [defaultValue])

  const labelClasses = classNames('px-4 py-2 text-size-16px font-bold text-color-151d49', { 'text-color-2e5bff': label !== 'Any' })

  return (
    <div className="flex flex-col">
      <div className="px-4 py-1 text-color-4e5d7e text-size-16px">Deal Size</div>
      <div>
        <button
          ref={buttonRef}
          className="w-full flex justify-between items-center bg-color-fafafa rounded-full shadow-btn overflow-hidden truncate focus:outline-none"
          onClick={handleClick}>
          <span className={labelClasses}>{label}</span>
          <FontAwesomeIcon icon="chevron-down" className="mx-6" />
        </button>
        <Popover
          style={{ zIndex: 10000 }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}>
          <DealSizeSlider width={popoverWidth} min={min} max={max} step={step} defaultValue={defaultValue} handleChangeCommitted={handleChangeCommittedInternal} />
        </Popover>
      </div>
    </div>
  )
}

export default DealDropDownFilter
