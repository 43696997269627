import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import EventBus from '../eventBus'
import { eventTypes } from '../eventBus/eventTypes'
import get from 'lodash/get'

const Link = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    publish,
    clickData,
    children
  } = props

  PropTypes.checkPropTypes(Link.propTypes, props, 'prop', 'Link')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-link focus:outline-none cursor-pointer',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const handleClick = useCallback((e) => {
    e.preventDefault()

    if (publish) {
      const names = get(publish, 'names', [])
      const eventName = get(publish, 'eventName')
      const eventArgs = {
        ...publish,
        names
      }
      eventName && EventBus.publish(eventName, eventArgs)
    } else {
      clickData && EventBus.publish(eventTypes.linkClicked, { name, clickData })
    }
  }, [])

  return (
    <a {...attributes} onClick={handleClick}>{children}</a>
  )
}

Link.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  publish: PropTypes.object,
  clickData: PropTypes.object,
  children: PropTypes.node
}

export default Link
