import { last } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { forecastPeriods } from './constants'
import { useForecasting } from '../../context/forecasting'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useGroups } from '../../context/groups'
import { useLocationData } from '../location/hooks'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useParams } from 'react-router-dom'
import { usePopover } from '../../hooks/usePopover'
import useRoles from '../../hooks/useRoles'
import DownloadIcon from '../icons/DownloadIcon'
import ForecastingHistoryTable from './forecastingHistoryTable'
import Modal from '../common/modal'
import MoreVertical from '../icons/moreVertical'
import Popover from '../popover'
import React, { useCallback, useMemo, useRef } from 'react'

const tableLimit = 20

const ForecastingHistoryModal = (props) => {
  const { modal, group } = props

  const { anchorEl, setAnchorEl, open: popOpen, onClose } = usePopover()

  const { location, hash } = useLocationData()

  const { loggedInUser } = useLoggedInUser()

  const { teamId, groupViewerIdsList } = loggedInUser

  const { isPlayerCoach } = useRoles()
  const { cursor, exportSubmissionHistory } = useForecasting()
  const { selectedConfig } = useForecastConfigs()
  const { flattened: teamFlattened } = useGroups()
  const params = useParams()

  const containerRef = useRef()

  const { open, handleClose } = modal
  const { periodLength = 0 } = selectedConfig ?? {}

  const onMenuClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  const user = useMemo(() => {
    if (params.userId) {
      return find(teamFlattened, (u) => u.id === params.userId)
    }
  }, [params, teamFlattened])

  const lastUserIdParam = useMemo(() => {
    return (params.userIds && last(params.userIds.split('|'))) || ''
  }, [params])

  const userId = useMemo(() => {
    if (lastUserIdParam) {
      return lastUserIdParam
    }
    if (isPlayerCoach && groupViewerIdsList.length) {
      return groupViewerIdsList[0]
    }
    return teamId
  }, [lastUserIdParam, isPlayerCoach, groupViewerIdsList, teamId])

  const header = useMemo(() => {
    if (group?.name) {
      return `${group.name} Submission History`
    } else {
      return 'Submission History'
    }
  }, [group])

  const getForecastPeriods = useMemo(() => {
    return forecastPeriods[periodLength] || forecastPeriods.default
  }, [periodLength])

  const selectedPeriod = useMemo(() => {
    const { name } = getForecastPeriods[cursor + 1]
    return name
  }, [cursor, getForecastPeriods])

  const onDownloadClick = useCallback(() => {
    const { periodLength, id } = selectedConfig

    exportSubmissionHistory({
      period: {
        cursor,
        type: selectedConfig.periodLength
      },
      configId: selectedConfig.id,
      ownerId: userId,
      limit: tableLimit,
      page: (hash && hash.page) || 1,
      groupName: group?.name ?? ''
    })
  }, [selectedConfig, exportSubmissionHistory, cursor, userId, hash, group])

  const closeModalInternal = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <Modal
      maxWidth="lg"
      handleClose={closeModalInternal}
      open={open}>
      <div ref={containerRef} className="bg-color-ffffff rounded-lg overflow-y-auto" style={{ height: 'calc(100vh - 60px)' }}>
        <div
          className="fixed rounded-lg overflow-hidden bg-color-ffffff"
          style={{
            width: Math.max(0, (containerRef?.current?.offsetWidth ?? 0) - 20),
            height: 130
          }}>
          <div className="w-full h-full flex justify-between items-start pt-9 pb-6">
            <div>
              <div className="pl-10 pr-4 text-color-151d49 text-size-24px">
                {header}
              </div>
              <div className="pl-10 pr-4 text-color-51636a text-size-18px font-weight-400">
                {selectedPeriod}
              </div>
            </div>
            <div className="flex items-center">
              <button onClick={onMenuClick} className="flex items-center focus:outline-none">
                <MoreVertical fill="#51636a" fontSize="small" />
              </button>
              <Popover
                arrow
                style={{ zIndex: 999999999 }}
                open={popOpen}
                anchorEl={anchorEl}
                onClose={onClose}
                placement="left"
                timeout={0}>
                <div className="py-2" style={{ width: 300 }}>
                  <button onClick={onDownloadClick} className="w-full py-2 text-left focus:outline-none hover:bg-color-edeeee">
                    <div className="flex items-start px-6">
                      <div className="flex justify-center items-center" style={{ width: 21, height: 21 }}>
                        <DownloadIcon style={{ width: 21, height: 21, color: '#757575' }} />
                      </div>
                      <div className="ml-2">
                        <div className="text-color-151d49 text-size-16px font-weight-500">Download History</div>
                        <div className="text-color-4e5d7e text-size-13px font-weight-500">Get a CSV of submission history</div>
                      </div>
                    </div>
                  </button>
                </div>
              </Popover>
              <button onClick={closeModalInternal} className="mx-10 focus:outline-none pointer-events-auto">
                <FontAwesomeIcon icon="times" size="lg" className="text-color-151d49" />
              </button>
            </div>
          </div>
        </div>
        <div className="px-10" style={{ paddingTop: 130 }}>
          <ForecastingHistoryTable userId={userId} user={user} />
        </div>
      </div>
    </Modal>
  )
}

export default ForecastingHistoryModal
