import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiRequestedSelector, featureSettingSelector } from '../../selectors'
import { getKeyMetricsByUserKey, getSignalStrengthsByUserKey, getSignalWeaknessesByUserKey } from '../../actions/signalService'
import ActivityTrends from './activityTrends'
import RepKeyMetrics from '../signals/repKeyMetrics'
import StrengthsAndWeaknessesLegacy from '../signals/strengthsAndWeaknessesLegacy'
import AnimatedLoader from '../loaders/animatedLoader'
import { clearApiActivityByKey, clearKeyMetrics, clearStrengths, clearWeaknesses } from '../../actions'
import { getKeyMetricsByUser, getSignalStrengthsByUser, getSignalWeaknessesByUser } from '../../services/signalService'
import { keyMetricsPersonFilterHeaders } from '../signals/constants'
import { useParams } from 'react-router-dom'
import { useGroups } from '../../context/groups'
import classNames from 'classnames'
import { featureNames } from '../../constants/featureNames'

const RepScorecardLegacy = (props) => {
  const params = useParams()
  const { userId } = params

  const { findGroupById } = useGroups()
  const user = findGroupById(userId)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearApiActivityByKey(getSignalStrengthsByUserKey))
    dispatch(clearApiActivityByKey(getSignalWeaknessesByUserKey))
    dispatch(clearStrengths())
    dispatch(clearWeaknesses())
    dispatch(getSignalStrengthsByUser(userId, 0, -1))
    dispatch(getSignalWeaknessesByUser(userId, 0, -1))

    dispatch(clearApiActivityByKey(getKeyMetricsByUserKey))
    dispatch(clearKeyMetrics())
    dispatch(getKeyMetricsByUser(userId, 0, -1, keyMetricsPersonFilterHeaders, true))
  }, [dispatch, userId])

  const isStrengthsApiRequested = useSelector((state) => apiRequestedSelector(state, getSignalStrengthsByUserKey))
  const isWeaknessesApiRequested = useSelector((state) => apiRequestedSelector(state, getSignalWeaknessesByUserKey))
  const userMetricsRequested = useSelector((state) => apiRequestedSelector(state, getKeyMetricsByUserKey))

  const activityTrendsFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.activityTrends }))

  const hideActivityTrends = useMemo(() => {
    const enabled = activityTrendsFeature?.payload?.enabled ?? false
    return !enabled
  }, [activityTrendsFeature])

  return (
    <>

      {isStrengthsApiRequested || isWeaknessesApiRequested || userMetricsRequested
        ? (
          <div className="h-screen">
            <AnimatedLoader
              className="mt-16"
              title="Loading Scorecard"
              subTitle="Please wait..."
              maxWidth={300} />
          </div>
        )
        : (
          <>

            <StrengthsAndWeaknessesLegacy
              key={`StrengthsAndWeaknessesLegacy-${userId}`}
              userId={userId}
              title="Strengths and Weaknesses" />

            <div className="flex mt-6 mr-4">

              <div className={classNames('w-full', { 'w-8/12': !hideActivityTrends })}>
                <RepKeyMetrics id={userId} />
              </div>

              <div className={classNames('ml-8', { hidden: hideActivityTrends }, { 'block w-4/12': !hideActivityTrends })}>
                {!user
                  ? (
                    <div className="h-screen">
                      <AnimatedLoader
                        className="mt-16"
                        title="Loading Scorecard"
                        subTitle="Please wait..."
                        maxWidth={300} />
                    </div>
                  )
                  : (
                    <ActivityTrends user={user} />
                  )}
              </div>

            </div>

          </>
        )}

    </>
  )
}

export default RepScorecardLegacy
