import { createReducer } from '@reduxjs/toolkit'
import { clearOppSettings } from '../actions'
import { getOppSettingsByTeamSuccess } from '../actions/opportunityService'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import remove from 'lodash/remove'
import uniqBy from 'lodash/uniqBy'

const initialState = {
  forecastCategoriesList: [],
  stagesList: [],
  minDealValue: 0,
  maxDealValue: 0,
  normalizedStages: [],
  normalizedForecastCategories: []
}

export const oppSettings = createReducer(initialState, {
  [clearOppSettings.type]: (state, action) => initialState,
  [getOppSettingsByTeamSuccess.type]: (state, action) => {
    let normalizedStages = uniqBy(orderBy(action.payload.stagesList, ['sort']), 'name').map((s) => s.name)
    const wonStages = remove(normalizedStages, (s) => s.isWon)
    normalizedStages = concat(normalizedStages, wonStages)
    const normalizedForecastCategories = filter(action.payload.forecastCategoriesList, (fc) => fc !== '')
    return {
      ...action.payload,
      normalizedStages,
      normalizedForecastCategories
    }
  }
})
