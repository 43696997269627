import { format, parseISO } from 'date-fns/esm'
import { getDateRangeByQueryDateRangeType } from '../components/dateRangePicker/helpers'
import { FieldType, QueryDateRangeType } from '../grpc/enums'

const startOfDayTimestamp = 'T00:00:00.000'
const endOfDayTimestamp = 'T23:59:59.999'

function updateDynamicDateRangesInFilterWrapperRecursive({ filterWrapper, fiscalYearStartMonth }) {
  const { filter, nestedList } = filterWrapper
  const fieldType = filter?.fieldType ?? FieldType.FIELD_TYPE_UNSPECIFIED
  const queryDateRangeType = filter?.queryDateRangeType ?? QueryDateRangeType.QUERY_DATE_RANGE_TYPE__CUSTOM

  if (filter) {
    const isDate = fieldType === FieldType.FIELD_TYPE_DATE
    const isTimestamp = fieldType === FieldType.FIELD_TYPE_TIMESTAMP
    if ((isDate || isTimestamp) && queryDateRangeType !== QueryDateRangeType.QUERY_DATE_RANGE_TYPE__CUSTOM) {
      const { valuesList = [] } = filter
      try {
        const defaultRange = {
          startDate: parseISO(valuesList[0]),
          endDate: parseISO(valuesList[1])
        }
        const range = getDateRangeByQueryDateRangeType({ queryDateRangeType, defaultRange, fiscalYearStartMonth })
        valuesList[0] = `${format(range.startDate, 'yyyy-MM-dd')}${isTimestamp ? startOfDayTimestamp : ''}`
        valuesList[1] = `${format(range.endDate, 'yyyy-MM-dd')}${isTimestamp ? endOfDayTimestamp : ''}`
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    }
  }

  if (nestedList) {
    nestedList.forEach((fw) => {
      updateDynamicDateRangesInFilterWrapperRecursive({ filterWrapper: fw, fiscalYearStartMonth })
    })
  }
}

export function updateDynamicDateRanges({ parametersList = [], fiscalYearStartMonth }) {
  parametersList.forEach((p) => {
    if (p.filter && p.filter.filters) {
      updateDynamicDateRangesInFilterWrapperRecursive({ filterWrapper: p.filter.filters, fiscalYearStartMonth })
    }
  })
}
