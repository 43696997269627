import React from 'react'
import { Redirect } from 'react-router-dom'
import PerformanceByUser from './performanceByUser'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import useRoles from '../../hooks/useRoles'
import PerformanceForRep from './performanceForRep'
import { useLocationData } from '../location/hooks'
import PerformanceByUserHome from './performanceByUserHome'
import PerformanceForRepLegacy from './performanceForRepLegacy'
import { useRoutes } from '../../context/routes'
import { useLoggedInUser } from '../../context/loggedInUser'

const Performance = (props) => {
  const {
    isIndividualContributorRole,
    isManagerRole,
    isPlayerCoach
  } = useRoles()

  const { isAccessNearlineCheckReady, canAccessNearlineFeature, featureNames } = useNearlineFeatures()

  const { params } = useLocationData()
  const { userId } = params || {}
  const { routes } = useRoutes()
  const { loggedInUser } = useLoggedInUser()
  const { id } = loggedInUser

  if (!isAccessNearlineCheckReady) {
    return <></>
  } else if (!userId && isPlayerCoach) {
    if (loggedInUser?.teamId === '') {
      return <PerformanceByUserHome />
    } else {
      return <Redirect to={routes.performanceByUser.replace(':userId', id)} />
    }
  } else if (isManagerRole) {
    if (canAccessNearlineFeature({ featureName: featureNames.performance })) {
      if (userId) {
        return <PerformanceByUser {...props} userId={userId} />
      } else {
        return <PerformanceByUserHome />
      }
    } else if (userId) {
      return <Redirect to={routes.rep.replace(':userId', userId)} />
    } else {
      return <Redirect to={routes.repHome} />
    }
  } else if (isIndividualContributorRole) {
    if (canAccessNearlineFeature({ featureName: featureNames.performance })) {
      return <PerformanceForRep {...props} />
    } else {
      return <PerformanceForRepLegacy {...props} />
    }
  } else {
    // TODO: check this case and ensure that it the routing falls through to the "Not Found" page when that bug (FRI-1313) is fixed
    return null
  }
}

export default Performance
