import React, { useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useRoutes } from '../../../context/routes'
import { useAuth } from '../../../context/auth'
import { useSearchString } from '../../../hooks/useSearchString'
import queryString from 'query-string'
import useKeyPressEffect from '../../../hooks/useKeyPress'
import classNames from 'classnames'

const TenantUserListItem = (props) => {
  const {
    user,
    selected,
    onMouseOver
  } = props

  const { id, name } = user

  const { persistImpersonation } = useAuth()
  const { tenantId } = useParams()
  const { routes } = useRoutes()
  const { searchParams } = useSearchString()
  const location = useLocation()

  const impersonate = useCallback(() => {
    const hash = queryString.parse(location.hash)
    const tenant = { id: tenantId, name: hash.name, region: hash.region }

    const redirect = searchParams.get('redirect')
    const pathname = redirect || routes.summary
    persistImpersonation({ tenant, user })

    window.location = `${window.location.origin}${pathname}`
  }, [location.hash, tenantId, searchParams, routes.summary, persistImpersonation, user])

  useKeyPressEffect({
    targetKey: 'Enter',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (selected) {
        impersonate()
      }
    }
  }, [impersonate, selected])

  return (
    <div
      className={classNames(
        'tr hover:bg-color-51636a-05 border-b border-color-c9ced0',
        { 'bg-color-51636a-05': selected }
      )}
      onMouseOver={onMouseOver}>
      <div className="td w-48 leading-tight">
        <div>{name}</div>
        <div className="text-size-11px text-color-a0a8bb">{id}</div>
      </div>
      <div className="td w-10 text-right">
        <button
          onClick={impersonate}
          className="px-6 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none">
          Impersonate
        </button>
      </div>
    </div>
  )
}

export default TenantUserListItem
