import React from 'react'

const EmptyState = (props) => {
  const { iconControl, header, subHeader } = props
  return (
    <div className="flex items-center h-full">
      <div className="text-center">
        <div className="flex justify-center my-4">
          {iconControl && iconControl}
        </div>
        {header && <div className="text-size-30px font-bold text-color-91959f">{header}</div>}
        {subHeader && <div className="text-size-14px text-color-91959f">{subHeader}</div>}
      </div>
    </div>
  )
}

export default EmptyState
