import React from 'react'
import classNames from 'classnames'

const AnimatedLoader = (props) => {
  const { className, title, subTitle, maxWidth, animate = true } = props

  return (
    <div className={classNames('flex items-center', className)}>
      <div className="mx-auto text-center" style={{ maxWidth }}>
        <div className="flex justify-center">
          <div style={{ width: 50, height: 50 }}>
            <svg viewBox="0, 0, 50, 50">
              <defs>
                <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
                  <stop offset="0%" style={{ stopColor: '#5951FF', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: '#5951FF', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <ellipse cx="25" cy="25" rx="25" ry="25" fill="url(#grad)" />
              <line x1="15" y1="22" x2="15" y2="34" style={{ stroke: '#fff', strokeWidth: 5, strokeLinecap: 'round' }}>
                {animate && <animate attributeName="y1" values="34;14;34" dur="500ms" begin="0ms" repeatCount="indefinite" />}
              </line>
              <line x1="25" y1="14" x2="25" y2="34" style={{ stroke: '#fff', strokeWidth: 5, strokeLinecap: 'round' }}>
                {animate && <animate attributeName="y1" values="34;14;34" dur="500ms" begin="200ms" repeatCount="indefinite" />}
              </line>
              <line x1="35" y1="30" x2="35" y2="34" style={{ stroke: '#fff', strokeWidth: 5, strokeLinecap: 'round' }}>
                {animate && <animate attributeName="y1" values="34;14;34" dur="500ms" begin="100ms" repeatCount="indefinite" />}
              </line>
            </svg>
          </div>
        </div>
        {title && <div className="text-size-30px text-color-91959f font-bold mt-4 mb-1">{title}</div>}
        {subTitle && <div className="text-size-14px text-color-91959f font-weight-500">{subTitle}</div>}
      </div>
    </div>

  )
}

export default AnimatedLoader
