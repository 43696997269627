import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Popover from '../popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useVirtualizedList } from '../virtualized/hooks'
import VirtualizedList from '../virtualized/virtualizedList'
import { filter, forEach, get, values } from 'lodash'
import FilterItem from './filterItem'
import { getFilterKey } from './helpers'

const categoriesRendered = {}

const MultiSelectFilterVirtualized = (props) => {
  const {
    enabled,
    menuItems = [],
    selectedItems = [],
    className,
    menuItemClassName,
    handleSelectionChanged,
    categoryClassName,
    display,
    maxHeight,
    minHeight = 35,
    width = 350,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [checked, setChecked] = useState({})
  const [search, setSearch] = useState('')
  const [categoriesOpened, setCategoriesOpened] = useState({})

  useEffect(() => {
    const checked = {}
    forEach(selectedItems, (item) => {
      if (item.key) {
        checked[getFilterKey(item.category, item.key)] = {
          ...item,
          checked: get(item, 'checked', null)
        }
      }
    })
    setChecked(checked)
  }, [selectedItems])

  function handleClick(event) {
    if (!enabled) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setCategoriesOpened({})
    setSearch('')
    setAnchorEl(null)
  }

  const handleSearchChange = useCallback((e) => {
    const searchText = e.target.value
    setSearch(searchText)
  }, [])

  const onClearClick = useCallback(() => {
    setCategoriesOpened({})
    setSearch('')
  }, [])

  const onApplyClick = useCallback(() => {
    const selected = filter(values(checked), (i) => i.checked)
    handleSelectionChanged && handleSelectionChanged(selected)
    handleClose()
  }, [checked])

  const onCategoryClick = useCallback((menuItem) => {
    setCategoriesOpened({
      ...categoriesOpened,
      [menuItem.category]: !categoriesOpened[menuItem.category]
    })
  }, [categoriesOpened])

  const filteredMenuItems = useMemo(() => {
    if (search.trim().length > 0) {
      const filteredItems = filter(menuItems, (m) => {
        const s = search.trim().toLowerCase()
        return m.name.toLowerCase().startsWith(s)
          || (m.category && m.category.toLowerCase().startsWith(s))
      })
      const opened = {}
      forEach(filteredItems, (i) => {
        if (i.category) {
          opened[i.category] = true
        }
      })
      setCategoriesOpened(opened)
      return filteredItems
    }
    return menuItems
  }, [search, menuItems])

  const handleToggleChange = useCallback((e, newValue, menuItem) => {
    setChecked({
      ...checked,
      [getFilterKey(menuItem.category, menuItem.key)]: {
        ...menuItem,
        checked: newValue
      }
    })
  }, [checked])

  const renderFilter = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    return (
      <FilterItem
        rowRendererArgs={rowRendererArgs}
        cellMeasurerArgs={cellMeasurerArgs}
        filteredMenuItems={filteredMenuItems}
        checked={checked}
        menuItemClassName={menuItemClassName}
        categoryClassName={categoryClassName}
        categoriesOpened={categoriesOpened}
        categoriesRendered={categoriesRendered}
        onCategoryClick={onCategoryClick}
        handleToggleChange={handleToggleChange} />
    )
  }, [filteredMenuItems, checked, categoriesOpened])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: 35,
    rowRenderer: renderFilter,
  })

  return (
    <>
      <span className={className} onClick={handleClick}>
        {display}
      </span>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        timeout={0}>
        <div className="flex flex-col" style={{ width, maxHeight }}>

          <div className="pb-1 pl-3 pr-3 bg-color-ffffff" style={{ zIndex: 9999 }}>
            <div className="bg-color-ffffff" style={{ zIndex: 9999 }}>
              <div className="pt-3">
                <div className="absolute flex justify-between w-full pointer-events-none" style={{ marginTop: 14, marginLeft: 10 }}>
                  <FontAwesomeIcon icon="search" size="sm" className="text-color-a0a8bb" />
                  {search.length > 0
                    && (
                      <button
                        className="absolute text-color-a0a8bb hover:text-color-151d49 focus:outline-none pointer-events-auto bg-color-ffffff"
                        onClick={onClearClick}
                        style={{ marginTop: -5, marginRight: 40, right: 0 }}>
                        <FontAwesomeIcon icon="times-circle" size="sm" className="pointer-events-none" />
                      </button>
                    )}
                </div>
                <input
                  onChange={handleSearchChange}
                  value={search}
                  type="text"
                  autoFocus
                  placeholder="Search"
                  className="m-0 focus:outline-none border border-color-d6d9e6 rounded pl-8 pr-6 py-2"
                  style={{ minWidth: width - 24 }} />
              </div>
            </div>
          </div>

          <div className="w-full flex-grow" style={{ height: maxHeight - 60, maxHeight: filteredMenuItems.length * 35, minHeight }}>
            {filteredMenuItems.length === 0 && <div className="mx-4 py-1 text-sm text-color-4e5d7e font-normal">No results were found</div>}
            <VirtualizedList
              className="focus:outline-none"
              deferredMeasurementCache={cellMeasurerCache}
              rowHeight={cellMeasurerCache.rowHeight}
              rowCount={filteredMenuItems.length}
              rowRenderer={rowRenderer} />
          </div>

          <div className="flex justify-center py-4">
            {filteredMenuItems.length > 0
              && (
                <button
                  onClick={onApplyClick}
                  className="px-10 py-2 text-size-14px text-color-ffffff font-bold rounded bg-gradient-green shadow-btn focus:outline-none">
                  Apply
                </button>
              )}
          </div>

        </div>
      </Popover>
    </>
  )
}

export default MultiSelectFilterVirtualized
