import React from 'react'
import PropTypes from 'prop-types/prop-types'
import { useHideToggle } from '../hooks/useHideToggle'

const Fragment = (props) => {
  const {
    name,
    hide = false,
    children,
  } = props

  PropTypes.checkPropTypes(Fragment.propTypes, props, 'prop', 'Fragment')

  const hideInternal = useHideToggle(name, hide)

  const attributes = {
    ...name && { key: name }
  }

  return (
    <React.Fragment {...attributes}>
      {!hideInternal && children}
    </React.Fragment>
  )
}

Fragment.propTypes = {
  name: PropTypes.string,
  hide: PropTypes.bool,
  children: PropTypes.node,
}

export default Fragment
