import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import SearchBox from '../search/searchBox'
import FilterGroup from './filterGroup'
import { cloneDeep, orderBy } from 'lodash'

const FiltersSelect = (props, ref) => {
  const { onClose, onClearFilters, onSelect, search, groupsOpened, onGroupClicked, selectedFilters } = props

  const [showActiveFilters, setShowActiveFilters] = useState(false)

  const toggleActiveFilters = useCallback(() => {
    setShowActiveFilters(!showActiveFilters)
  }, [showActiveFilters])

  const onClearFiltersInternal = useCallback(() => {
    onClearFilters && onClearFilters()
    setShowActiveFilters(false)
  }, [onClearFilters])

  const onFilterSelected = useCallback((args) => {
    onSelect && onSelect(args)
  }, [onSelect])

  const scenarioPlannerOptions = useSelector((state) => state.scenarioPlannerOptions)

  const filterGroups = useMemo(() => {
    return orderBy(cloneDeep(scenarioPlannerOptions.filterOptionsList || []), (o) => o.label)
  }, [scenarioPlannerOptions])

  return (
    <div className="flex flex-col rounded" style={{ height: 550 }} ref={ref}>
      <div className="rounded-t bg-color-ffffff" style={{ minWidth: 400 }}>
        <div className="px-5 pt-5 pb-3">
          <SearchBox
            placeholder="Search"
            value={search.value}
            onChange={search.onChange}
            onClear={search.reset}
            autoFocus={true}
            disabled={false}
            disableHint={true} />
          <div className="flex justify-between mt-1">
            <button
              className="text-color-5951FF text-size-14px font-weight-500 cursor-pointer"
              onClick={toggleActiveFilters}>
              {`${showActiveFilters ? 'Hide' : 'Show'} Active Filters`}
            </button>
            <button
              className="text-color-5951FF text-size-14px font-weight-500 cursor-pointer"
              onClick={onClearFiltersInternal}>
              Clear Filters
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-grow flex-col overflow-auto" style={{ minWidth: 400 }}>
        {filterGroups.map((fg) => (
          <FilterGroup
            key={`FilterGroup-${fg.field}`}
            showActiveFilters={showActiveFilters}
            filterGroups={filterGroups}
            filterGroup={fg}
            searchText={search.value}
            groupsOpened={groupsOpened}
            onGroupClicked={onGroupClicked}
            selectedFilters={selectedFilters}
            onFilterSelected={onFilterSelected} />
        ))}
      </div>
      <div className="flex justify-center px-5 pb-5 pt-3">
        <button
          className="px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none"
          onClick={onClose}>
          Done
        </button>
      </div>
    </div>
  )
}

export default React.forwardRef(FiltersSelect)
