import React, { useCallback, useEffect, useState } from 'react'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'

const TruncatedText = (props) => {
  const {
    showToolTip = true,
    text
  } = props

  const [textIsTruncated, setTextIsTruncated] = useState(false)
  const [elementRef, setElementRef] = useState(null)

  useEffect(() => {
    if (!elementRef) {
      return
    }

    elementRef.addEventListener('mouseover', measure)

    return () => {
      elementRef.removeEventListener('mouseover', measure)
    }
  }, [measure, elementRef])

  const measure = useCallback(() => {
    setTextIsTruncated(elementRef.offsetWidth < elementRef.scrollWidth)
  }, [elementRef])

  const onRef = useCallback((ref) => {
    if (!ref) {
      return
    }
    setElementRef(ref)
    setTextIsTruncated(ref.offsetWidth < ref.scrollWidth)
  }, [])

  const getText = useCallback(() => {
    const title = (
      <div
        ref={onRef}
        className="truncate">
        {text}
      </div>
    )

    if (!showToolTip || !textIsTruncated) {
      return title
    }

    return (
      <Tippy
        offset={[0, 10]}
        duration={[0, 0]}
        content={<div className="py-2 px-4 font-weight-400 font-size-10px">{text}</div>}
        arrow={roundArrow}
        theme="canopy">
        <span className="ml-1 first:ml-0">
          {title}
        </span>
      </Tippy>
    )
  }, [onRef, text, showToolTip, textIsTruncated])

  return (
    <>
      {getText()}
    </>
  )
}

export default TruncatedText
