import React from 'react'

const TextHeader = (props) => {
  const { header, description } = props
  return (
    <>
      {header && <div className="text-color-09242f text-size-28px font-bold">{header}</div>}
      {description && <div className="text-color-09242f text-size-18px font-weight-500">{description}</div>}
    </>
  )
}

export default TextHeader
