import React, { useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import FormulaInputs from './formulaInputs'
import { useSelector } from 'react-redux'
import useRefSize from '../../hooks/useRefSize'
import AnimatedLoader from './animatedLoader'
import { useFormulaInputs } from './hooks'
import { apiFailedSelector } from '../../selectors'
import { getForecastSimulationLevelOptionsKey, getForecastSimulationReportOptionsKey, getForecastSimulationsAvailableKey } from '../../actions/reportingService'
import { defaultFormula, defaultTarget, defaultTimePeriod, defaultSelectedFilters } from './constants'
import { Error } from '@getoutreach/react-icons'

const ScenarioPlannerStart = (props) => {
  const { tenantId, groupId, setShowWorkspace } = props

  const {
    selectedFormula,
    onFormulaSelect,
    selectedTimePeriod,
    setSelectedTimePeriod,
    onTimePeriodSelect,
    selectedTarget,
    onTargetSelect,
    onClearFilters,
    selectedFilters,
    onFilterSelected,
    canStart,
    startClicked,
    onStartClick,
  } = useFormulaInputs({ tenantId, groupId, defaultFormula, defaultTarget, defaultTimePeriod, defaultFilters: defaultSelectedFilters })

  const containerRef = useRef()
  const size = useRefSize(containerRef)

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)

  const hasAllOutput = useMemo(() => {
    return forecastSimulationWorkspace.hasAllOutput
  }, [forecastSimulationWorkspace])

  const apiSimulationsAvailableFailed = useSelector((state) => apiFailedSelector(state, getForecastSimulationsAvailableKey))
  const apiLevelOptionsFailed = useSelector((state) => apiFailedSelector(state, getForecastSimulationLevelOptionsKey))
  const apiReportOptionsFailed = useSelector((state) => apiFailedSelector(state, getForecastSimulationReportOptionsKey))

  useEffect(() => {
    if (hasAllOutput) {
      setShowWorkspace && setShowWorkspace(true)
    }
  }, [hasAllOutput, setShowWorkspace])

  return (
    <div ref={containerRef} className="flex w-full h-full">
      <div className="flex justify-center p-10 w-full h-full font-normal mt-10">
        <div className="flex flex-col w-full">

          <div className="mx-auto" style={{ maxWidth: 450 }}>
            <div className="text-size-30px text-color-09242f font-bold text-center">
              Getting Started with
              <br />
              Revenue Scenario Planner
            </div>
            <div className="text-size-14px text-color-4e5d7e mt-3">
              Outreach Commit is able to scenario plan using multiple different types of forecasts.  First, select the type of forecast you'd like to plan.
              {' '}
              Then select the timeframe and team or individual you would like to include.
            </div>
          </div>

          <div className="mx-auto my-8 px-6" style={{ width: 450 }}>
            <FormulaInputs
              tenantId={tenantId}
              groupId={groupId}
              selectedFormula={selectedFormula}
              onFormulaSelect={onFormulaSelect}
              selectedTimePeriod={selectedTimePeriod}
              onTimePeriodSelect={onTimePeriodSelect}
              setSelectedTimePeriod={setSelectedTimePeriod}
              selectedTarget={selectedTarget}
              onTargetSelect={onTargetSelect}
              onClearFilters={onClearFilters}
              selectedFilters={selectedFilters}
              onFilterSelected={onFilterSelected}
              start={new Date()}
              end={new Date()} />
          </div>

          <div className="flex justify-center">
            <button
              className={classNames('px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none',
                { 'opacity-25 pointer-events-none': !canStart })}
              onClick={onStartClick}>
              Start
            </button>
          </div>

          {(apiSimulationsAvailableFailed || apiLevelOptionsFailed || apiReportOptionsFailed)
            && (
              <div className="flex items-center justify-center my-6">
                <Error htmlColor="#fb6c6a" />
                <div className="ml-1 text-color-fb6c6a">Oops...something went wrong!</div>
              </div>
            )}

        </div>
      </div>

      {!hasAllOutput && startClicked && !apiReportOptionsFailed
        && (
          <div className="fixed top-0 h-screen" style={{ paddingTop: 93, width: size.width, backgroundColor: 'rgba(256, 256, 256, .96)' }}>
            <AnimatedLoader className="py-20" />
          </div>
        )}
    </div>
  )
}

export default ScenarioPlannerStart
