import React, { useCallback, useMemo } from 'react'
import { filter, orderBy } from 'lodash'
import { secondsToFormattedDate } from '../../lib/dateFns'
import classNames from 'classnames'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import numeral from 'numeral'
import { useUserInput } from '../../hooks/useUserInput'

export function useFormatValue() {
  const { formatCurrency } = useFormatCurrency()

  const formatValue = useCallback((value, format) => {
    const formatParts = format.split('::')
    switch (formatParts[0]) {
      case 'currency':
        return formatCurrency(value)
      case 'percent':
        return numeral(value / 100).format('0.[0000000000]%')
      default:
        return value
    }
  }, [formatCurrency])

  return {
    formatValue
  }
}

// [
//  "",
// "text::length(120)",
// "plaintextarea::length(32000)",
// "select",
// "currency",
// "percent",
// "date",
// "timestamp",
// "int::length(8)",
// "currency::precision(18)",
// "float::precision(18)",
// "text::length(50)",
// "text::length(1300)",
// "float::precision(12)",
// "percent::precision(18)",
// "float::precision(2)",
// "plaintextarea::length(32768)",
// "text::length(255)",
// "email::length(80)",
// "plaintextarea::length(255)",
// "float::precision(4)",
// "multiselect",
// "float::precision(10)",
// "richtextarea::length(32768)",
// "text::length(12)",
// "url::length(255)",
// "text::length(80)",
// "text::length(8)",
// "text::length(6)",
// "text::length(35)",
// "currency::precision(12)",
// "text::length(100)",
// "currency::precision(5)",
// "location",
// "float::precision(5)"
// ]

export function useFieldDefinitionOptions(fieldDefinition) {
  const { decodeString } = useUserInput()
  const options = useMemo(() => {
    let opts = []
    if (fieldDefinition.options && fieldDefinition.options.itemsList) {
      opts = fieldDefinition.options.itemsList.map((i) => {
        try {
          return {
            label: i.label,
            value: decodeString(i.value),
          }
        } catch (err) {
          console.log(`JSON.parse error on ${i.value}`)
          return undefined
        }
      })
      opts = orderBy(filter(opts, (o) => o !== undefined), (o) => o.sort)
    }
    return opts
  }, [fieldDefinition, decodeString])

  return options
}

export function useVersionsTooltip(versions, valueFormatter) {
  const versionsTooltip = useMemo(() => {
    if (versions.length > 1) {
      return (
        <div className="flex flex-col px-4 py-3 leading-tight">
          {versions.slice().reverse().map((v, index) => (
            <div key={`v-${index}`} className="flex items-center justify-between mb-1 last:mb-0">
              <div className={classNames('text-size-16px text-color-2e384d',
                { 'text-color-151d49 font-bold': index === versions.length - 1 })}>
                {valueFormatter ? valueFormatter(v.value) : v.value}
              </div>
              <div className="leading-tight text-right pl-5">
                <div className="text-size-10px text-color-b0bac9">Changed</div>
                <div className="text-size-11px text-color-a0a8bb">{v.ts && v.ts.seconds ? secondsToFormattedDate(v.ts.seconds) : '-'}</div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }, [versions])
  return versionsTooltip
}
