import get from 'lodash/get'

export const seriesColors = [
  '#2e5bff',
  '#e5e7f7',
  '#283fb1',
  '#839eff',
  '#c1c7d2',
  '#151d49',
  '#939fd8',
  '#c0cdff',
  '#959eb2',
  '#e7e8ec',
  '#983ed6',
  '#483357',
  '#24bed6',
  '#0f4d57',
  '#572a06',
  '#ff9e56',
  '#d62f7e',
  '#d6680f',
  '#dbc176',
  '#a3731f',
]

export const getSeriesColor = (index) => get(seriesColors, `[${index % seriesColors.length}]`, '#000000')

export const getSeriesColorsRange = (startIndex = 0, endIndex) => seriesColors.slice(startIndex, endIndex)
