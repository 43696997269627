import { ForecastConfigsProvider } from '../../../context/forecastConfigs'
import { ForecastingProvider } from '../../../context/forecasting'
import PreviewTemplate from './previewTemplate'
import React from 'react'

const PreviewForecastingTemplate = (props) => {
  return (
    <ForecastConfigsProvider>
      <ForecastingProvider>
        <PreviewTemplate {...props} />
      </ForecastingProvider>
    </ForecastConfigsProvider>
  )
}

export default PreviewForecastingTemplate
