import { setMainWidth } from '../../../actions'
import { useAuth } from '../../../context/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useMessages, MessagesEvent } from '../../../hooks/useMessages'
import { useRoutes } from '../../../context/routes'
import { useTenantInfo } from '../../../context/tenantInfo'
import classNames from 'classnames'
import LocationTracker from '../../location/locationTracker'
import MainContainer from '../../mainContainer'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SandboxBanner from '../../sandbox/banner'
import useRefResize from '../../../hooks/useRefResize'
import VersionChecker from '../../../versionChecker'

const targetOrigin = process.env.ADMIN_URL

const PreviewTemplate = (props) => {
  const { component: Component, canAccess, ...rest } = props

  const { isSandbox } = useTenantInfo()
  const { routes } = useRoutes()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const mainRef = useRef()
  const mainWidth = useSelector((state) => state.mainWidth)

  const {
    actingTenantId,
    isImpersonatingUser,
    isAuthenticated,
    tenantId,
    personId
  } = useAuth()

  const {
    attach,
    authRequest,
    clearAuthRequest,
    isReady,
    messages,
    postEvent
  } = useMessages(targetOrigin)

  const { key } = location

  useEffect(() => {
    if (!isReady) {
      attach(parent)
      return
    }
    postEvent(MessagesEvent.READY, targetOrigin)
  }, [attach, isReady, postEvent])

  useEffect(() => {
    if (authRequest) {
      // check users, they need to match
      if (authRequest.user?.id !== personId) {
        postEvent(MessagesEvent.UNAUTHORIZED, targetOrigin)
        return
      }

      // if admin has tenant context, it needs to match
      if (authRequest.tenantContext) {
        if (authRequest.tenantContext?.id !== actingTenantId) {
          // tenant is not the same, set the tenant and present picker for user
          // need to try the reqeusted link *after* selection...
          const route = routes.tenantUsers.replace(':tenantId', authRequest.tenantContext?.id)
          history.push(`${route}?redirect=${location.pathname}`)
          return
        }
      }

      // check tenants, they need to match since there is no tenant context
      if (authRequest.tenantId !== tenantId) {
        postEvent(MessagesEvent.UNAUTHORIZED, targetOrigin)
        return
      }

      clearAuthRequest()
    }
  }, [
    actingTenantId,
    authRequest,
    clearAuthRequest,
    isImpersonatingUser,
    isAuthenticated,
    history,
    location.pathname,
    tenantId,
    personId,
    postEvent,
    routes
  ])

  useEffect(() => {
    if (messages.length) {
      console.log('commit: messages!!', messages)
    }
  }, [messages])

  const handleResize = useCallback(({ width }) => {
    mainWidth !== width && dispatch(setMainWidth(width))
  }, [dispatch, mainWidth])

  useRefResize(mainRef, handleResize)

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [key])

  useEffect(() => {
    if (!canAccess) {
      history.push(routes.unauthorized)
    }
  }, [canAccess, history, routes.unauthorized])

  const mainClassName = useMemo(() => {
    return 'w-full min-h-screen p-0 pr-0'
  }, [])

  return (
    <div className={classNames('flex flex-wrap md:min-h-screen', { 'pt-16': isSandbox })}>
      {canAccess && (
        <>
          <SandboxBanner />
          <LocationTracker />
          <VersionChecker key={key} />
        </>
      )}
      <div ref={mainRef} className={mainClassName}>
        <div>
          {canAccess && (
            <MainContainer {...rest}>
              <Component {...rest} />
            </MainContainer>
          )}
        </div>
      </div>
    </div>
  )
}

export default PreviewTemplate
