import { apiActivity } from './apiActivity'
import { crmSettings } from './crmSettings'
import { debug } from './debug'
import { dock } from './dock'
import { featureSettings } from './featureSettings'
import { filteredDealCount } from './filteredDealCount'
import { forecastOpps } from './forecast'
import { headers } from './headers'
import { impersonation } from './impersonation'
import { loadingScreen } from './loadingScreen'
import { mainWidth } from './mainWidth'
import { modal } from './modal'
import { modalOpportunities } from './modalOpportunities'
import { nlgTester } from './nlgTester'
import { notes, activeNote } from './notes'
import { noteSearchedOpportunities } from './noteSearchedOpportunities'
import { objectDefinitions } from './objectDefinitions'
import { opportunities } from './opportunities'
import { oppSettings } from './oppSettings'
import { organization } from './organization'
import { pageContext } from './pageContext'
import { people } from './people'
import { pipelineReport } from './pipelineReport'
import { pipelineReportOpportunities } from './pipelineReportOpportunities'
import { pipelineReportOptions } from './pipelineReportOptions'
import { pipelineReportSelections } from './pipelineReportSelections'
import { pipelineOpportunities } from './pipelineOpportunities'
import { currentFilters } from './savedFilters'
import {
  activeScenarioKey,
  changedInputs,
  forecastSimulationsAvailable,
  forecastSimulationLevelOptions,
  forecastSimulationWorkspace,
  hiddenScenarios,
} from './scenarioPlanner'
import { scenarioPlannerOptions } from './scenarioPlannerOptions'
import { search } from './search'
import { searchedOpps, oppDetail, pipelineGridFields, dealHealth, dealHealthById } from './searchedOpps'
import { searchFilters } from './searchFilters'
import { signalCounters } from './signalCounters'
import {
  keyMetricsByTeam,
  keyMetricsByUser,
  keyMetricsCounters,
  signalsByUser,
  signalsByOpportunity,
  signalsByGroup,
  headerMetrics,
  dashboardMetrics,
  dashboardMetricsSiblings,
} from './signals'
import { strengths } from './strengths'
import { team, teamFlattened } from './team'
import { tenants, impersonationUsers } from './tenants'
import { weaknesses } from './weaknesses'

export const rootReducer = {
  activeNote,
  activeScenarioKey,
  apiActivity,
  changedInputs,
  crmSettings,
  currentFilters,
  dashboardMetrics,
  dashboardMetricsSiblings,
  dealHealth,
  dealHealthById,
  debug,
  dock,
  featureSettings,
  filteredDealCount,
  forecastOpps,
  forecastSimulationsAvailable,
  forecastSimulationLevelOptions,
  forecastSimulationWorkspace,
  headerMetrics,
  headers,
  hiddenScenarios,
  impersonation,
  impersonationUsers,
  keyMetricsByTeam,
  keyMetricsByUser,
  keyMetricsCounters,
  loadingScreen,
  mainWidth,
  modal,
  modalOpportunities,
  nlgTester,
  notes,
  noteSearchedOpportunities,
  objectDefinitions,
  opportunities,
  oppDetail,
  oppSettings,
  organization,
  pageContext,
  people,
  pipelineGridFields,
  pipelineReport,
  pipelineReportOpportunities,
  pipelineReportOptions,
  pipelineReportSelections,
  pipelineOpportunities,
  scenarioPlannerOptions,
  search,
  searchedOpps,
  searchFilters,
  signalCounters,
  signalsByUser,
  signalsByOpportunity,
  signalsByGroup,
  strengths,
  team,
  teamFlattened,
  tenants,
  weaknesses,
}
