import React, { useCallback, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcEffect } from '../grpc'
import { toDataRequest } from '../grpc/converters'
import { enrichUser } from '../reducers/team'
import { cloneDeep } from 'lodash'

const PeopleContext = React.createContext()

export function PeopleProvider({ id, children }) {
  const [key, setKey] = useState(guid())
  const [people, setPeople] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useGrpcEffect({
    request: toDataRequest({
      id
    }),
    onSuccess: (obj) => {
      const { peopleList = [] } = obj
      const p = peopleList.map((person) => enrichUser(cloneDeep(person)))
      setPeople(p)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'getAllPeople',
    debug: false,
  }, [key, id])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      people,
      invalidate
    }
  }, [isFetching, people])

  return <PeopleContext.Provider value={contextValue}>{children}</PeopleContext.Provider>
}

export function usePeople() {
  const context = React.useContext(PeopleContext)
  if (context === undefined) {
    throw new Error('usePeople must be used within a PeopleProvider')
  }
  return context
}
