import { format, fromUnixTime, parse, parseISO, formatDistanceToNow } from 'date-fns'

export function secondsToFormattedDate(seconds, fmt = 'M/d/yyyy') {
  const date = fromUnixTime(seconds)
  const dateStr = date.toISOString()
  return format(parseISO(dateStr), fmt)
}

export function parseDate(strValue, format = 'yyyy-MM-dd') {
  return parse(strValue, 'yyyy-MM-dd', new Date())
}

export function reformatDate(strValue, inputFormat = 'yyyy-MM-dd', outputFormat = 'M/d/yyyy') {
  try {
    return format(parseDate(strValue, inputFormat), outputFormat)
  } catch (err) {
    return ''
  }
}

export function secondsToDate(seconds, ignoreTimezoneOffset = true) {
  const date = fromUnixTime(seconds)
  const dateStr = date.toISOString()
  const parsedDate = parseISO(dateStr)
  return ignoreTimezoneOffset ? new Date(parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000) : parsedDate
}

export function secondsToDistanceToNow(seconds, ignoreTimezoneOffset = true) {
  return formatDistanceToNow(secondsToDate(seconds, ignoreTimezoneOffset), { addSuffix: true })
}

export function secondsToTextDate(seconds, ignoreTimezoneOffset = true) {
  return format(secondsToDate(seconds, ignoreTimezoneOffset), 'MMM d yyyy h:mm a')
}
