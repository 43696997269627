import React, { useCallback } from 'react'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import EmptyState from '../empty/emptyState'
import { TrendingUpRounded } from '@getoutreach/react-icons'
import { useCanvasItem } from '../../context/canvasItem'

const FilterApplyOverlay = (props) => {
  const { name } = useCanvasItem()
  const { canApply, applyWorkingFilter } = useRealtimeFilterEditor()

  const onPreviewChanges = useCallback(() => {
    applyWorkingFilter()
    window.analytics.track('realtimeMetrics.filterModal.previewChangesClicked', { name })
  }, [name, applyWorkingFilter])

  return !canApply ? null : (
    <div className="absolute z-10 w-full h-full"
      style={{
        backgroundColor: 'rgba(256, 256, 256, 0.9)',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <div className="flex items-center justify-center w-full pt-10">
        <EmptyState
          iconControl={(
            <div className="flex items-center justify-center w-12 h-12 rounded-full text-color-ffffff bg-gradient-green">
              <TrendingUpRounded fontSize="large" />
            </div>
          )}
          header="New Data"
          subHeader={(
            <div className="text-center">
              There are filters applied below
              <br />
              that have not been previewed
              <div
                onClick={onPreviewChanges}
                className="mt-2 text-color-5951FF text-size-16px font-weight-500 cursor-pointer">
                Preview Changes
              </div>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default FilterApplyOverlay
