import React, { useMemo } from 'react'
import { useCanvas } from '../../context/canvas'
import { RealtimeDraftViewProvider } from '../../context/realtimeDraftView'
import { RealtimeViewProvider } from '../../context/realtimeView'
import CanvasItemContainer from './canvasItemContainer'
import { CanvasItemProvider } from '../../context/canvasItem'
import RealtimeView from '../realtimeView/realtimeView'
import MetricMenu from './metricMenu'
import { findIndex, orderBy } from 'lodash'
import SectionHeader from '../common/sectionHeader'
import FilterModal from '../filter/filterModal'
import { RealtimeViewDetailsModalProvider } from '../../context/realtimeViewDetailsModal'
import FiltersButton from './filtersButton'
import FilterSelect from '../filter/filterSelect'
import { RealtimeViewModalProvider } from '../../context/realtimeViewModal'

const MetricsCanvas = (props) => {
  const {
    userId,
    actor,
    viewKey,
    headers
  } = props

  const { isFetching, canvasKey, canvas } = useCanvas()

  const canvasTileOrder = useMemo(() => {
    const canvasTilesList = canvas?.canvasLayout?.canvasTilesList ?? []
    return orderBy(canvasTilesList, (t) => t.rowIdx).map((t) => t.tileKey)
  }, [canvas])

  const tileContainersList = useMemo(() => {
    const tiles = (canvas?.tileContainersList ?? []).map((t) => {
      let sort = findIndex(canvasTileOrder, (tileKey) => tileKey === t.tileKey)
      if (sort === -1) {
        sort = 99999
      }
      t.sort = sort
      return t
    })
    return orderBy(tiles, (t) => t.sort)
  }, [canvas, canvasTileOrder])

  return (
    <>
      <SectionHeader
        title="Trends"
        description="Performance over time on key business metrics." />
      {!isFetching && canvas && (
        <>
          {tileContainersList.map((tile, index) => {
            const gmlInstanceId = `gml-${userId}-${canvasKey}-${tile.tileKey}-${index}`
            return (
              <CanvasItemProvider
                key={`CanvasItemProvider-${userId}-${canvasKey}-${tile.tileKey}-${index}`}
                canvasTile={tile}>
                <RealtimeDraftViewProvider
                  userId={userId}
                  headers={headers}>
                  <RealtimeViewProvider
                    lazy={true}
                    userId={userId}
                    headers={headers}>
                    <RealtimeViewModalProvider>
                      <RealtimeViewDetailsModalProvider
                        actor={actor}
                        gmlInstanceId={gmlInstanceId}>
                        <CanvasItemContainer
                          className="mt-6 bg-gradient-blue-to-white"
                          menuBar={(
                            <>
                              <FiltersButton />
                              <FilterSelect />
                              <MetricMenu />
                            </>
                          )}>
                          <RealtimeView
                            key={`${gmlInstanceId}-${viewKey}`}
                            gmlInstanceId={gmlInstanceId} />
                          <FilterModal />
                        </CanvasItemContainer>
                      </RealtimeViewDetailsModalProvider>
                    </RealtimeViewModalProvider>
                  </RealtimeViewProvider>
                </RealtimeDraftViewProvider>
              </CanvasItemProvider>
            )
          })}
        </>
      )}
    </>
  )
}

export default MetricsCanvas
