import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCanvas } from '../../context/canvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { findIndex, orderBy } from 'lodash'
import { CanvasItemProvider } from '../../context/canvasItem'
import { RealtimeDraftViewProvider } from '../../context/realtimeDraftView'
import { RealtimeViewProvider } from '../../context/realtimeView'
import CanvasItemContainer from './canvasItemContainer'
import MetricMenu from './metricMenu'
import RealtimeView from '../realtimeView/realtimeView'
import FilterModal from '../filter/filterModal'
import { RealtimeViewDetailsModalProvider } from '../../context/realtimeViewDetailsModal'
import FiltersButton from './filtersButton'
import { RealtimeViewModalProvider } from '../../context/realtimeViewModal'
import FilterSelect from '../filter/filterSelect'

const PipelineCanvas = (props) => {
  const {
    userId,
    actor,
    headers
  } = props

  const { isFetching, canvasKey, canvas } = useCanvas()

  const canvasTileOrder = useMemo(() => {
    const canvasTilesList = canvas?.canvasLayout?.canvasTilesList ?? []
    return orderBy(canvasTilesList, (t) => t.rowIdx).map((t) => t.tileKey)
  }, [canvas])

  const tileContainersList = useMemo(() => {
    const tiles = (canvas?.tileContainersList ?? []).map((t) => {
      let sort = findIndex(canvasTileOrder, (tileKey) => tileKey === t.tileKey)
      if (sort === -1) {
        sort = 99999
      }
      t.sort = sort
      return t
    })
    return orderBy(tiles, (t) => t.sort)
  }, [canvas, canvasTileOrder])

  const pipelineTile = useMemo(() => {
    return tileContainersList[0]
  }, [tileContainersList])

  const goalsTile = useMemo(() => {
    return tileContainersList[1]
  }, [tileContainersList])

  const scrollView = useRef(null)
  const [canShowNextPrevBtns, setCanShowNextPrevBtns] = useState(false)
  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [enableNextBtn, setEnableNextBtn] = useState(false)

  const prev = useCallback(() => {
    window.analytics.track('Pipeline Scroller', { action: 'Back' })
    scrollView?.current?.scroll({ left: 0 })
    setEnablePrevBtn(false)
    setEnableNextBtn(true)
  }, [scrollView])

  const next = useCallback(() => {
    window.analytics.track('Pipeline Scroller', { action: 'Forward' })
    scrollView?.current?.scroll({ left: scrollView?.current?.scrollWidth })
    setEnablePrevBtn(true)
    setEnableNextBtn(false)
  }, [scrollView])

  const sharedBtnClasses = 'flex items-center bg-color-fcfcfc rounded-full cursor-pointer pointer-events-auto'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, '-mr-13', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  useEffect(() => {
    setCanShowNextPrevBtns(pipelineTile !== undefined && goalsTile !== undefined)
    setEnableNextBtn(goalsTile !== undefined)
  }, [pipelineTile, goalsTile])

  const onResize = useCallback(() => {
    if (canShowNextPrevBtns) {
      if (enablePrevBtn && !enableNextBtn) {
        scrollView?.current?.scroll({ left: scrollView?.current?.scrollWidth })
      } else if (!enablePrevBtn && enableNextBtn) {
        scrollView?.current?.scroll({ left: 0 })
      }
    }
  }, [scrollView, canShowNextPrevBtns, enablePrevBtn, enableNextBtn])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  return (
    <>
      {!isFetching && canvas && (
        <div
          className="relative overflow-hidden"
          style={{ width: 'calc(100% + 64px)', transform: 'translateX(-40px)' }}>

          {canShowNextPrevBtns && (
            <div
              className="-ml-10 z-20 absolute flex justify-between w-full pointer-events-none select-none pl-10 pr-6"
              style={{ top: 'calc(50% - 24px)' }}>
              <div
                className={prevBtnClasses}
                style={{ ...nextPrevBtnStyle }}
                onClick={prev}>
                <FontAwesomeIcon
                  icon="chevron-left"
                  size="2x"
                  color="#c9ced0"
                  className="mx-auto" />
              </div>
              <div
                className={nextBtnClasses}
                style={{ ...nextPrevBtnStyle }}
                onClick={next}>
                <FontAwesomeIcon
                  icon="chevron-right"
                  size="2x"
                  color="#c9ced0"
                  className="mx-auto" />
              </div>
            </div>
          )}

          <div>
            <div
              ref={scrollView}
              className="inline-flex w-full max-w-full h-full overflow-y-hidden no-scrollbar"
              style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}>
              {pipelineTile && (
                <div className="relative w-full min-w-full">
                  <CanvasItemProvider
                    key={`CanvasItemProvider-${canvasKey}-${pipelineTile.tileKey}`}
                    canvasTile={pipelineTile}>
                    <RealtimeDraftViewProvider
                      userId={userId}
                      headers={headers}>
                      <RealtimeViewProvider
                        userId={userId}
                        headers={headers}>
                        <RealtimeViewModalProvider>
                          <RealtimeViewDetailsModalProvider
                            actor={actor}
                            gmlInstanceId={`gml-${userId}-${canvasKey}-${pipelineTile.tileKey}`}>
                            <CanvasItemContainer
                              className="mx-10 my-4 bg-color-ffffff"
                              menuBar={(
                                <>
                                  <FiltersButton />
                                  <FilterSelect />
                                  <MetricMenu />
                                </>
                              )}>
                              <RealtimeView
                                gmlInstanceId={`gml-${userId}-${canvasKey}-${pipelineTile.tileKey}`} />
                              <FilterModal />
                            </CanvasItemContainer>
                          </RealtimeViewDetailsModalProvider>
                        </RealtimeViewModalProvider>
                      </RealtimeViewProvider>
                    </RealtimeDraftViewProvider>
                  </CanvasItemProvider>
                </div>
              )}
              {goalsTile && (
                <div className="relative w-full min-w-full">
                  <CanvasItemProvider
                    key={`CanvasItemProvider-${canvasKey}-${goalsTile.tileKey}`}
                    canvasTile={goalsTile}>
                    <RealtimeDraftViewProvider
                      userId={userId}
                      headers={headers}>
                      <RealtimeViewProvider
                        userId={userId}
                        headers={headers}>
                        <RealtimeViewModalProvider>
                          <RealtimeViewDetailsModalProvider
                            actor={actor}
                            gmlInstanceId={`gml-${userId}-${canvasKey}-${goalsTile.tileKey}`}>
                            <CanvasItemContainer
                              className="mx-10 my-4 bg-color-ffffff"
                              menuBar={(
                                <>
                                  <FiltersButton />
                                  <FilterSelect />
                                  <MetricMenu />
                                </>
                              )}>
                              <RealtimeView
                                gmlInstanceId={`gml-${userId}-${canvasKey}-${goalsTile.tileKey}`} />
                              <FilterModal />
                            </CanvasItemContainer>
                          </RealtimeViewDetailsModalProvider>
                        </RealtimeViewModalProvider>
                      </RealtimeViewProvider>
                    </RealtimeDraftViewProvider>
                  </CanvasItemProvider>
                </div>
              )}
            </div>
          </div>

        </div>
      )}
    </>
  )
}

export default PipelineCanvas

const nextPrevBtnStyle = {
  width: '48px',
  height: '48px',
  fontSize: '13px',
  border: '1px solid rgb(227, 232, 250)',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
}
