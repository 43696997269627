import { createReducer } from '@reduxjs/toolkit'
import { clearAuth, setHeader, removeHeader } from '../actions'

const initialState = {}

export const headers = createReducer(initialState, {
  // [saveAuth.type]: (state, action) => {
  //   state.Authorization = `Bearer ${action.payload.id_token}`;
  //   return state;
  // },
  [clearAuth.type]: (state, action) => {
    delete state.Authorization
    return state
  },
  [setHeader.type]: (state, action) => {
    state[action.payload.name] = action.payload.value
    return state
  },
  [removeHeader.type]: (state, action) => {
    delete state[action.payload.name]
    return state
  },
})
