import React from 'react'

const DeleteScenarioConfirm = ({ label, onCancel, onDelete }) => {
  return (
    <div className="px-7 py-6 font-normal cursor-default">
      <div className="text-size-16px">
        Are you sure you want to delete
        <br />
        <span className="font-bold">{label}</span>
        ?
      </div>
      <div className="mt-3 mb-7 text-size-14px text-color-4e5d7e">It can't be undone.</div>
      <div className="flex justify-center">
        <div className="flex items-center">
          <button
            onClick={onCancel}
            className="mx-3 px-8 py-2 text-size-14px text-color-ffffff font-weight-600 rounded-full bg-color-a0a8bb shadow-btn focus:outline-none">
            Cancel
          </button>
          <button
            onClick={onDelete}
            className="mx-3 px-8 py-2 text-size-14px text-color-ffffff font-weight-600 rounded-full bg-gradient-green shadow-btn focus:outline-none">
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteScenarioConfirm
