import { grpcInvoke } from '../grpc'
import { filter, get } from 'lodash'
import { EmptyMessage } from '../protos/src/common/empty_message_pb'
import { SignalDataRequest } from '../protos/src/services/api/api_pb'
import {
  getKeyMetricsByTeamRequested,
  getKeyMetricsByTeamSuccess,
  getKeyMetricsByTeamFailed,
  getSignalsByUserRequested,
  getSignalsByUserSuccess,
  getSignalsByUserFailed,
  getKeyMetricsByUserRequested,
  getKeyMetricsByUserSuccess,
  getKeyMetricsByUserFailed,
  getSignalsByOpportunityRequested,
  getSignalsByOpportunitySuccess,
  getSignalsByOpportunityFailed,
  getSignalStrengthsByUserRequested,
  getSignalStrengthsByUserSuccess,
  getSignalStrengthsByUserFailed,
  getSignalWeaknessesByUserRequested,
  getSignalWeaknessesByUserSuccess,
  getSignalWeaknessesByUserFailed,
  getSignalByIdRequested,
  getSignalByIdSuccess,
  getSignalByIdFailed,
  getSummaryStatsByUserRequested,
  getSummaryStatsByUserSuccess,
  getSummaryStatsByUserFailed,
  getHeaderMetricsByTeamRequested,
  getHeaderMetricsByTeamSuccess,
  getHeaderMetricsByTeamFailed,
  getHeaderMetricsByUserRequested,
  getHeaderMetricsByUserSuccess,
  getHeaderMetricsByUserFailed,
  getDashboardMetricsRequested,
  getDashboardMetricsSuccess,
  getDashboardMetricsFailed,
} from '../actions/signalService'

export const getKeyMetricsByTeam = (skip, take, filterHeaders, id, isGrouped, isPagedCall, pageCtx) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
    }
    const request = new SignalDataRequest()
    id && request.setId(id)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        !isPagedCall && dispatch(getKeyMetricsByTeamRequested())
      },
      onSuccess: (obj) => {
        obj.userId = id
        obj.pageContext = pageCtx ?? getState().pageContext
        dispatch(getKeyMetricsByTeamSuccess(obj))
      },
      onError: (err) => {
        dispatch(getKeyMetricsByTeamFailed(err))
      },
      grpcMethod: 'getSignalsByTeam',
      grpcMethodName: 'getKeyMetricsByTeam',
      debug: false,
    })
  }
}

export const getSignalsByUser = (userId, skip, take, filterHeaders, isGrouped) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
    }
    const request = new SignalDataRequest()
    request.setId(userId)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getSignalsByUserRequested())
      },
      onSuccess: (obj) => {
        obj.userId = userId
        dispatch(getSignalsByUserSuccess(obj))
      },
      onError: (err) => {
        dispatch(getSignalsByUserFailed(err))
      },
      grpcMethod: 'getSignalsByUser',
      debug: false,
    })
  }
}

export const getKeyMetricsByUser = (userId, skip, take, filterHeaders, isGrouped, isPagedCall) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
    }
    const request = new SignalDataRequest()
    request.setId(userId)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        !isPagedCall && dispatch(getKeyMetricsByUserRequested())
      },
      onSuccess: (obj) => {
        obj.userId = userId
        obj.pageContext = getState().pageContext
        dispatch(getKeyMetricsByUserSuccess(obj))
      },
      onError: (err) => {
        dispatch(getKeyMetricsByUserFailed(err))
      },
      grpcMethod: 'getSignalsByUser',
      grpcMethodName: 'getKeyMetricsByUser',
      debug: false,
    })
  }
}

export const getSignalsByOpportunity = (opportunityId, skip, take) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    const request = new SignalDataRequest()
    request.setId(opportunityId)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getSignalsByOpportunityRequested())
      },
      onSuccess: (obj) => {
        obj.skip = skip
        obj.opportunityId = opportunityId
        dispatch(getSignalsByOpportunitySuccess(obj))
      },
      onError: (err) => {
        dispatch(getSignalsByOpportunityFailed(err))
      },
      grpcMethod: 'getSignalsByOpportunity',
      debug: false,
    })
  }
}

export const getSignalStrengthsByUser = (userId, skip, take) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    const request = new SignalDataRequest()
    request.setId(userId)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getSignalStrengthsByUserRequested())
      },
      onSuccess: (obj) => {
        obj.userId = userId
        obj.pageContext = getState().pageContext
        dispatch(getSignalStrengthsByUserSuccess(obj))
      },
      onError: (err) => {
        dispatch(getSignalStrengthsByUserFailed(err))
      },
      grpcMethod: 'getSignalStrengthsByUser',
      debug: false,
    })
  }
}

export const getSignalWeaknessesByUser = (userId, skip, take) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    const request = new SignalDataRequest()
    request.setId(userId)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getSignalWeaknessesByUserRequested())
      },
      onSuccess: (obj) => {
        obj.userId = userId
        obj.pageContext = getState().pageContext
        dispatch(getSignalWeaknessesByUserSuccess(obj))
      },
      onError: (err) => {
        dispatch(getSignalWeaknessesByUserFailed(err))
      },
      grpcMethod: 'getSignalWeaknessesByUser',
      debug: false,
    })
  }
}

// id is the manager id or rep id,
// level is group or person
// groupId is the primary signal id for a grouped signal
// signalId is the signal id
export const getSignalById = (id, level, groupId, signalId) => {
  return async (dispatch, getState) => {
    const headers = {
      'filter|id|eq': signalId,
    }
    const request = new SignalDataRequest()
    id && request.setId(id)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getSignalByIdRequested())
      },
      onSuccess: (obj) => {
        const payload = {
          groupId,
          signal: get(obj, 'signalsList[0]')
        }
        dispatch(getSignalByIdSuccess(payload))
      },
      onError: (err) => {
        dispatch(getSignalByIdFailed(err))
      },
      grpcMethod: level === 'group' ? 'getSignalsByTeam' : 'getSignalsByUser',
      grpcMethodName: 'getSignalById',
      debug: false,
    })
  }
}

export const getSummaryStatsByUser = (userId, skip, take, filterHeaders, isGrouped) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
    }
    const request = new SignalDataRequest()
    request.setId(userId)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getSummaryStatsByUserRequested())
      },
      onSuccess: (obj) => {
        obj.userId = userId
        dispatch(getSummaryStatsByUserSuccess(obj))
      },
      onError: (err) => {
        dispatch(getSummaryStatsByUserFailed(err))
      },
      grpcMethod: 'getSignalsByUser',
      grpcMethodName: 'getSummaryStatsByUser',
      debug: false,
    })
  }
}

export const getHeaderMetricsByTeam = (skip, take, filterHeaders, id, isGrouped, pageCtx) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
    }
    const request = new SignalDataRequest()
    id && request.setId(id)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getHeaderMetricsByTeamRequested())
      },
      onSuccess: (obj) => {
        obj.userId = id
        obj.pageContext = pageCtx ?? getState().pageContext
        dispatch(getHeaderMetricsByTeamSuccess(obj))
      },
      onError: (err) => {
        dispatch(getHeaderMetricsByTeamFailed(err))
      },
      grpcMethod: 'getSignalsByTeam',
      grpcMethodName: 'getHeaderMetricsByTeam',
      debug: false,
    })
  }
}

export const getHeaderMetricsByUser = (userId, skip, take, filterHeaders, isGrouped) => {
  return async (dispatch, getState) => {
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
    }
    const request = new SignalDataRequest()
    request.setId(userId)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getHeaderMetricsByUserRequested())
      },
      onSuccess: (obj) => {
        obj.userId = userId
        obj.pageContext = getState().pageContext
        dispatch(getHeaderMetricsByUserSuccess(obj))
      },
      onError: (err) => {
        dispatch(getHeaderMetricsByUserFailed(err))
      },
      grpcMethod: 'getSignalsByUser',
      grpcMethodName: 'getHeaderMetricsByUser',
      debug: false,
    })
  }
}

export const getDashboardMetrics = (skip, take, filterHeaders, id, isGrouped, view) => {
  return async (dispatch, getState) => {
    const isDefaultView = view.value === ''
    const headers = {
      skip,
      take,
    }
    if (filterHeaders) {
      filterHeaders.map((h) => { headers[h.key] = h.value })
      if (!isDefaultView) {
        const { dashboardMetricsSiblings } = getState()
        headers['filter|id|in'] = filter(dashboardMetricsSiblings, (s) => s.definition.title.startsWith(view.label)).map((s) => s.id).join('|')
      }
    }
    const request = new SignalDataRequest()
    id && request.setId(id)
    isGrouped && request.setGroup(isGrouped)
    grpcInvoke({
      headers,
      request,
      onFetch: () => {
        dispatch(getDashboardMetricsRequested())
      },
      onSuccess: (obj) => {
        obj.cacheSiblings = isDefaultView
        dispatch(getDashboardMetricsSuccess(obj))
      },
      onError: (err) => {
        dispatch(getDashboardMetricsFailed(err))
      },
      grpcMethod: 'getSignalsByTeam',
      grpcMethodName: 'getDashboardMetrics',
      debug: false,
    })
  }
}
