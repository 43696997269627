import React, { useCallback, useState, useEffect } from 'react'
import { useTenantUsers } from '../../../context/tenantUsers'
import TenantUserListItem from './tenantUserListItem'
import numeral from 'numeral'
import Paging from '../../paging/paging'
import useKeyPressEffect from '../../../hooks/useKeyPress'

const TenantUsersList = (props) => {
  const { tenantUsers, page, setPage, pageSize, total } = useTenantUsers()

  const [arrowIndex, setArrowIndex] = useState(-1)

  const onSetPage = useCallback((currentPage) => {
    setPage(currentPage)
  }, [])

  useKeyPressEffect({
    targetKey: 'ArrowUp',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (arrowIndex > -1) {
        setArrowIndex(arrowIndex - 1)
      }
    }
  }, [arrowIndex])

  useKeyPressEffect({
    targetKey: 'ArrowDown',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (arrowIndex < tenantUsers.length) {
        setArrowIndex(arrowIndex + 1)
      }
    }
  }, [arrowIndex, tenantUsers])

  const onMouseOver = useCallback(() => {
    setArrowIndex(-1)
  }, [])

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr border-b border-color-c9ced0">
            <div className="td w-48">Name</div>
            <div className="td w-10 text-right" />
          </div>
        </div>
        <div className="tbody">
          {tenantUsers.map((user, i) => (
            <TenantUserListItem
              onMouseOver={onMouseOver}
              selected={i === arrowIndex}
              key={`TenantUserListItem-${user.id}-${i}`}
              user={user} />
          ))}
        </div>
      </div>
      <Paging
        className="flex w-full items-center justify-between py-3"
        textClassName="text-color-151d49 text-size-15px font-weight-600"
        navigationClassName="flex items-center"
        total={total}
        limit={pageSize}
        page={page}
        onSetPage={onSetPage}
        textFormatter={(start, end, totalPages) => `${numeral(start).format('0,0')} - ${numeral(end).format('0,0')} of ${numeral(totalPages).format('0,0')}`} />
    </>
  )
}

export default TenantUsersList
