import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { useRoutes } from '../context/routes'
import find from 'lodash/find'
import { useGroups } from '../context/groups'

const EventBusRedirect = () => {
  const location = useLocation()
  const search = queryString.parse(location.search)

  const { routes } = useRoutes()

  const { type, id } = search

  const { flattened: team } = useGroups()

  if (type === 'person') {
    const person = find(team, (p) => p.id === id)
    if (person) {
      if (person.hasChildren) {
        return <Redirect to={routes.userSummary.replace(':userId', id)} />
      } else {
        return <Redirect to={routes.rep.replace(':userId', id)} />
      }
    }
  } else if (type === 'opportunity') {
    return <Redirect to={routes.deal.replace(':opportunityId', id)} />
  }

  return <Redirect to={routes.summary} />
}

export default EventBusRedirect
