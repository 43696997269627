import { IntegrationSourceType } from '../protos/src/common/tenant/mapping/v2/object_mapping_pb'
import { SearchCombineAction, SearchFieldType } from '../protos/src/common/search/enum/enum_pb'
import { FilterOperation } from '../protos/src/common/filter/filter_pb'
import { PatchSearchItemErrType, PatchSearchItemStatus, RealtimeQueryStatus } from '../protos/src/services/api/api_pb'
import { ForecastMode } from '../protos/src/common/forecast/v2/forecast_pb'
import { CategoryRollupMode } from '../protos/src/services/forecast/v2/forecast_pb'
import { PermissionSet, Permission } from '../protos/src/common/auth/auth_pb'
import { PersonField } from '../protos/src/common/orchard/person_pb'
import { FilterOp } from '../protos/src/services/orchard/orchard_pb'
import { BasicStatus, SystemRoleType } from '../protos/src/common/orchard/system_role_pb'
import { InstanceSetupStatus } from '../protos/src/common/liftoff/liftoff_pb'
import { FieldType, FilterCombiner, ObjectType, ParameterType, QueryDateRangeType } from '../protos/src/common/realtime/query_pb'
import {
  ForecastConfigValueFormat,
  ForecastConfigColumnDisplayOption as DisplayOption,
  ForecastConfigColumnDisplayVisibilityOption as VisibilityOption,
  ForecastPeriodType
} from '../protos/src/services/forecast/v3/forecast_pb'

const IntegrationSourceTypeStrings = {
  0: 'unknown',
  1: 'canopy',
  2: 'canonical',
  3: 'raw',
  4: 'custom',
}

export {
  IntegrationSourceTypeStrings,
  IntegrationSourceType,
  SearchCombineAction,
  SearchFieldType,
  FilterOperation,
  PatchSearchItemErrType,
  PatchSearchItemStatus,
  ForecastMode,
  CategoryRollupMode,
  DisplayOption,
  VisibilityOption,
  PermissionSet,
  Permission,
  PersonField,
  FilterOp,
  SystemRoleType,
  BasicStatus,
  InstanceSetupStatus,
  ObjectType,
  ParameterType,
  RealtimeQueryStatus,
  FilterCombiner,
  QueryDateRangeType,
  ForecastConfigValueFormat,
  ForecastPeriodType,
  FieldType
}
