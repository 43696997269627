import { get } from 'lodash'
import { DateRangeOption, GlobalLevel } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'

export const baselineName = 'Outreach\'s Baseline'

export const calloutNames = {
  bearCase: 'BEAR CASE',
  fairValue: 'FAIR VALUE',
  bullCase: 'BULL CASE',
}

export const colors = [
  '#e6e8e9',
  '#c9ced0',
  '#818e93',
  '#51636a',
  '#203842',
]

export const getColor = (index) => get(colors, `[${index % colors.length}]`, '#e6e8e9')

export const defaultFormula = { key: -1, value: 'Select Scenario' }
export const defaultTimePeriod = { key: -1, value: 'Select Time Frame' }
export const defaultTarget = { key: -1, value: 'Select Team or Individual' }
export const defaultSelectedFilters = { key: -1, value: 'Select Filter' }
export const targetAll = { key: '', value: 'All', level: GlobalLevel.COMPANY }

export const timePeriodOptions = [
  { key: DateRangeOption.CURRENT_MONTH, value: 'This Month' },
  { key: DateRangeOption.NEXT_MONTH, value: 'Next Month' },
  { key: DateRangeOption.CURRENT_QUARTER, value: 'This Quarter' },
  { key: DateRangeOption.NEXT_QUARTER, value: 'Next Quarter' },
  // { key: DateRangeOption.CUSTOM, value: 'Custom' },
]
