import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopover } from '../../hooks/usePopover'
import { useRealtimeView } from '../../context/realtimeView'
import { useCanvasItem } from '../../context/canvasItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { find } from 'lodash'
import FilterSelectPopover from './filterSelectPopover'

const FilterSelect = () => {
  const popover = usePopover()
  const { setAnchorEl, onClose } = popover

  const {
    name,
    userQueryParametersList,
    defaultUserFilter,
    defaultOrgFilter
  } = useCanvasItem()

  const [runOnce, setRunOnce] = useState(false)

  const { clearError, selectedFilter, setSelectedFilter } = useRealtimeView()

  const onClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const onSelectedOptionChanged = useCallback((filter) => {
    clearError()
    setSelectedFilter(filter)
    onClose()
    window.analytics.track('realtimeMetrics.metricSelectList.optionClicked', { name, filter: filter?.label })
  }, [clearError, setSelectedFilter, onClose])

  useEffect(() => {
    if (userQueryParametersList.length > 0) {
      if (!runOnce) {
        if (defaultUserFilter) {
          setSelectedFilter(defaultUserFilter)
        } else if (defaultOrgFilter) {
          setSelectedFilter(defaultOrgFilter)
        } else {
          setSelectedFilter(userQueryParametersList[0])
        }
        setRunOnce(true)
      }
    }
  }, [setSelectedFilter, runOnce, userQueryParametersList, defaultUserFilter, defaultOrgFilter])

  const selectedFilterLabel = useMemo(() => {
    const filter = find(userQueryParametersList, (uqp) => uqp.id === selectedFilter?.id)
    return filter?.label ?? '\u00A0'
  }, [userQueryParametersList, selectedFilter])

  return (
    <>
      <div onClick={onClick} className="min-w-36 flex items-center justify-between border border-color-d6d9e6 rounded cursor-pointer">
        <div className="whitespace-nowrap truncate text-color-09242f text-size-14px font-weight-500 mx-3 py-1">{selectedFilterLabel}</div>
        <FontAwesomeIcon icon="chevron-down" className="mx-3 text-color-a0a8bb" size="sm" />
      </div>
      <FilterSelectPopover
        popover={popover}
        options={userQueryParametersList}
        selectedOption={selectedFilter}
        onSelectedOptionChanged={onSelectedOptionChanged} />
    </>
  )
}

export default FilterSelect
