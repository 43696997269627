import { useMemo, useCallback } from 'react'

export const useTreeHasType = (type) => {
  const typeList = useMemo(() => {
    if (Array.isArray(type)) {
      return type
    }
    return [type]
  }, [type])

  const findInTree = useCallback((t) => {
    const { childrenList = [], type } = t

    if (typeList.includes(type)) {
      return true
    }

    if (childrenList.length) {
      const found = childrenList.some(findInTree)
      return found
    }

    return false
  }, [typeList])

  const hasType = useCallback((tree) => {
    const { contentsList = [] } = tree
    return contentsList.map((t) => findInTree(t))
      .includes(true)
  }, [typeList])

  return {
    hasType
  }
}
