import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearImpersonation, clearAuth } from '../../actions'
import { logout } from '../../lib/auth0'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'

const Logout = (props) => {
  const { resetAuth } = useAuth()

  const { routes } = useRoutes()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearImpersonation())
    dispatch(clearAuth())
    resetAuth()
    window.analytics.track('Logout')
    logout({ returnTo: `${window.location.origin}${routes.login}` })
  }, [])

  return <></>
}

export default Logout
