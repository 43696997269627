import { Help } from '@getoutreach/react-icons'
import { Link } from 'react-router-dom'
import { links } from '../../constants/links'
import { useTenantInfo } from '../../context/tenantInfo'
import classNames from 'classnames'
import has from 'lodash/has'
import moment from 'moment'
import React, { useMemo } from 'react'

const tagName = process.env.TAG_NAME
const shortSHA = process.env.SHORT_SHA

const SideMenuFooter = (props) => {
  const { open } = props
  const version = tagName || shortSHA

  const { tenantInfo } = useTenantInfo()

  const showLastSync = useMemo(() => {
    const { lastSuccessfulSync } = tenantInfo
    return has(lastSuccessfulSync, 'seconds')
  }, [tenantInfo])

  return (
    <div
      className="flex flex-col font-normal justify-end py-8 px-7 mt-auto"
      style={{ zIndex: 102 }}>
      <Link
        className="flex items-center text-size-15px text-color-5951FF mb-4 whitespace-nowrap"
        onClick={() => window.analytics.track('commit.sideMenuClicked', { action: 'Support Portal' })}
        to={{ pathname: links.support }}
        target="_blank"
        rel="noreferrer">
        <Help />
        <span className={classNames('ml-5 opacity-0 group-hover:opacity-100', { 'opacity-100': open })}>
          Support Portal
        </span>
      </Link>
      <div className={classNames('opacity-0 group-hover:opacity-100 text-size-13px text-color-a6b2cf', { 'opacity-100': open })}>
        {showLastSync && <div className="leading-tight whitespace-nowrap">{`Last synced ${moment(tenantInfo.lastSuccessfulSync.seconds * 1000).utc().fromNow()}`}</div>}
        {version && <div>{version}</div>}
      </div>
    </div>
  )
}

export default SideMenuFooter
