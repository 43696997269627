import { createReducer } from '@reduxjs/toolkit'
import { cloneDeep, forEach, remove } from 'lodash'
import {
  clearSearchFilters,
  removeSearchFilter,
  removeAllSearchFilters,
  applySearchFilters,
  setSearchFilter,
  removeSearchOptionFilter,
  removePendingSearchFilters,
  setSearchText,
  setSearchFilters,
  setViewInitialized
} from '../actions'

const initialState = {
  pendingFilters: {},
  appliedFilters: {},
  canApply: false,
  searchText: '',
  queryCache: undefined,
  view: '',
  viewInitialized: false,
}

export function removeOptionFilter(filters, key, option) {
  const filter = filters[key]
  if (filter && filter.data && filter.data.selected) {
    remove(filter.data.selected, (o) => o.value === option.value)

    if (filter.data.selected.length === 0) {
      delete filters[key]
    }
  }
  return filters
}

export const searchFilters = createReducer(initialState, {
  [clearSearchFilters.type]: (state, action) => initialState,
  [setSearchText.type]: (state, action) => {
    state.searchText = action.payload
    return state
  },
  [setSearchFilter.type]: (state, action) => {
    const { filter, pending, applied } = action.payload
    const { fieldDefinition } = filter
    if (fieldDefinition.key) {
      if (pending) {
        state.pendingFilters[fieldDefinition.key] = filter

        if (filter.data && filter.data.selected && Array.isArray(filter.data.selected) && filter.data.selected.length === 0) {
          delete state.pendingFilters[fieldDefinition.key]
        }
      }
      if (applied) {
        state.appliedFilters[fieldDefinition.key] = filter

        if (filter.data && filter.data.selected && Array.isArray(filter.data.selected) && filter.data.selected.length === 0) {
          delete state.appliedFilters[fieldDefinition.key]
        }
      }
    }
    state.canApply = JSON.stringify(state.pendingFilters) !== JSON.stringify(state.appliedFilters)
    return state
  },
  [setSearchFilters.type]: (state, action) => {
    const { filters, view } = action.payload
    forEach(filters, (f) => {
      const { filter, pending, applied } = f
      const { fieldDefinition } = filter
      if (fieldDefinition.key) {
        if (pending) {
          state.pendingFilters[fieldDefinition.key] = filter

          if (filter.data && filter.data.selected && Array.isArray(filter.data.selected) && filter.data.selected.length === 0) {
            delete state.pendingFilters[fieldDefinition.key]
          }
        }
        if (applied) {
          state.appliedFilters[fieldDefinition.key] = filter

          if (filter.data && filter.data.selected && Array.isArray(filter.data.selected) && filter.data.selected.length === 0) {
            delete state.appliedFilters[fieldDefinition.key]
          }
        }
      }
    })
    state.canApply = JSON.stringify(state.pendingFilters) !== JSON.stringify(state.appliedFilters)
    state.view = view
    return state
  },
  [setViewInitialized.type]: (state, action) => {
    state.viewInitialized = action.payload
    return state
  },
  [removeSearchFilter.type]: (state, action) => {
    const { key, pending, applied } = action.payload
    if (key) {
      if (pending && state.pendingFilters[key]) {
        delete state.pendingFilters[key]
      }
      if (applied && state.appliedFilters[key]) {
        delete state.appliedFilters[key]
      }
      state.canApply = JSON.stringify(state.pendingFilters) !== JSON.stringify(state.appliedFilters)
    }
    return state
  },
  [removePendingSearchFilters.type]: (state, action) => {
    state.pendingFilters = cloneDeep(state.appliedFilters)
    state.canApply = false
    return state
  },
  [removeAllSearchFilters.type]: (state, action) => {
    state.pendingFilters = {}
    state.appliedFilters = {}
    state.canApply = false
    return state
  },
  [removeSearchOptionFilter.type]: (state, action) => {
    const { key, option } = action.payload
    if (key) {
      removeOptionFilter(state.pendingFilters, key, option)
      removeOptionFilter(state.appliedFilters, key, option)
      state.canApply = JSON.stringify(state.pendingFilters) !== JSON.stringify(state.appliedFilters)
    }
    return state
  },
  [applySearchFilters.type]: (state, action) => {
    state.appliedFilters = cloneDeep(state.pendingFilters)
    state.canApply = false
    return state
  },
})
