import React from 'react'
import classNames from 'classnames'
import { useViewDealInCrm } from '../../context/viewDealInCrm'

const ViewDealInCrm = ({ className }) => {
  const { text, onClick } = useViewDealInCrm()

  return (
    <a
      className={classNames('cursor-pointer',
        { 'text-color-2e5bff text-size-14px font-weight-500': !className },
        { className })}
      onClick={onClick}>
      {text}
    </a>
  )
}

export default ViewDealInCrm
