import { clearIntegrationObject } from '../actions'
import { startImpersonating } from './impersonationService'
import { getTenantOrganization } from './tenantService'
import { getIntegrationObjectDefinition } from './integrationService'
import { getFeatureSettings, getCrmSettings } from './settingsService'

export const handleAuth = (claims) => {
  return (dispatch, getState) => {
    try {
      const isImpersonatingUser = localStorage.getItem('isImpersonatingUser')
      if (isImpersonatingUser) {
        const tenant = JSON.parse(localStorage.getItem('impersonatingTenant'))
        const user = JSON.parse(localStorage.getItem('impersonatingUser'))
        const isDemoMode = JSON.parse(localStorage.getItem('demo'))
        dispatch(startImpersonating(tenant, user, isDemoMode))
        dispatch(getFeatureSettings())
        dispatch(getCrmSettings())
        dispatch(clearIntegrationObject())
      } else {
        dispatch(getFeatureSettings())
        dispatch(getCrmSettings())
        dispatch(getTenantOrganization())
        dispatch(clearIntegrationObject())
        dispatch(getIntegrationObjectDefinition('opportunity'))
      }
    } catch (err) {
      console.log(err)
    }
  }
}
