import React, { useEffect, useMemo } from 'react'
import Chartist from 'chartist'
import targetHorizontalLine from './plugins/targetHorizontalLine'
import numeral from 'numeral'
import labelValueTooltip from './plugins/labelValueTooltip'
import moment from 'moment'

const TrendChart = (props) => {
  const { id, seriesData, average, aggregateBy } = props

  const chartId = `trend-chart-${id}`

  const data = {
    series: [...seriesData.map((s) => [s.value])]
  }

  const seriesLength = data.series.length

  const seriesBarDistance = useMemo(() => {
    if (aggregateBy === 'daily') {
      return 6
    } else {
      return aggregateBy === 'weekly' ? 20 : 60
    }
  }, [aggregateBy])

  const labelValueTooltipLabels = useMemo(() => {
    if (aggregateBy === 'daily') {
      return 'MMM D'
    } else {
      return aggregateBy === 'weekly' ? '[Week of] MMM D' : 'MMM'
    }
  }, [])

  const options = {
    chartPadding: { right: 20 },
    horizontalBars: false,
    seriesBarDistance,
    stretch: true,
    showGrid: false,
    reverseData: false,
    axisX: {
      showGrid: false,
      showLabel: true,
      offset: 20
    },
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 20
    },
    plugins: [
      targetHorizontalLine({
        offsetY: 0,
        targetValue: average,
        label: numeral(average).format('0,0.[00]'),
        labelClass: 'ct-left-target-label',
        lineColor: '#839eff',
        animate: {
          opacity: {
            begin: 900,
            dur: 300,
            from: 0,
            to: 1,
            easing: Chartist.Svg.Easing.easeInOutQuad
          }
        }
      }),
      labelValueTooltip({
        appendToSelector: `#trend-chart-${id}`,
        target: `#vertical-bar-${id}-${aggregateBy}-{index}`,
        labels: seriesData.map((x) => x.date).map((l) => moment(l).format(labelValueTooltipLabels)),
        values: seriesData.map((x) => `(${x.value})`),
        offset: '0, 15'
      })
    ]
  }

  const seriesBarDistanceProp = useMemo(() => {
    if (aggregateBy === 'daily') {
      return 8
    } else {
      return aggregateBy === 'weekly' ? 35 : 75
    }
  }, [aggregateBy])

  const seriesBarDistanceWidth = useMemo(() => {
    if (aggregateBy === 'daily') {
      return 2
    } else {
      return aggregateBy === 'weekly' ? 6 : 10
    }
  }, [aggregateBy])

  useEffect(() => {
    const chart = new Chartist.Bar(`#${chartId}`, data, options, [
      ['screen and (min-width: 1280px)', { seriesBarDistanceProp }]
    ])

    chart.on('draw', (data) => {
      if (data.type === 'bar') {
        data.element.attr({
          id: `vertical-bar-${id}-${aggregateBy}-${data.seriesIndex}`,
          y1: data.element.attr('y1'),
          y2: data.element.attr('y2'),
          style: `stroke: #2e5bff; stroke-width: ${seriesBarDistanceWidth}px; stroke-linecap: round;`
        })
        data.element.animate({
          y2: { begin: data.seriesIndex < Math.round(seriesLength / 2) ? 300 : 600, dur: 300, from: data.y1, to: data.y2, easing: Chartist.Svg.Easing.easeInOutQuad }
        })
      }
    })
  }, [aggregateBy, seriesBarDistance, seriesBarDistanceWidth])

  return (
    <div id={chartId} className="trend-chart h-32" />
  )
}

export default TrendChart
