import React from 'react'
import { useCanvas } from '../../context/canvas'
import { useRealtimeView } from '../../context/realtimeView'
import RealtimeViewErrorBoundary from './realtimeViewErrorBoundary'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import GMLResponsiveContainer from '../../gml/renderer/gmlResponsiveContainer'
import { getComputedBreakpoint } from '../../gml/renderer/helpers'
import AnimatedLoader from '../loaders/animatedLoader'
import RealtimeViewError from './realtimeViewError'
import RealtimeViewEmpty from './realtimeViewEmpty'
import { emptyStateErrorMessages } from './constants'

const RealtimeView = ({ gmlInstanceId }) => {
  const { tenantStandardParams } = useCanvas()

  const { isFetching, error, parsedGML } = useRealtimeView()

  if (error) {
    if (emptyStateErrorMessages.includes(error?.errorMessage)) {
      return <RealtimeViewEmpty />
    } else {
      return <RealtimeViewError title="Error" subTitle="Error loading metric" error={error} />
    }
  }

  return (
    <>
      {isFetching ? (
        <AnimatedLoader
          className="my-12"
          title="Loading Metric"
          subTitle="Please wait..." />
      ) : (
        <>
          {!isFetching && parsedGML && (
            <RealtimeViewErrorBoundary>
              <GMLResponsiveContainer>
                {({ width, height, breakpoints }) => {
                  const breakpoint = getComputedBreakpoint(
                    width,
                    height,
                    breakpoints
                  )
                  // console.log('getComputedBreakpoint', breakpoint.key, 'width', width, breakpoints)
                  return (
                    <GMLRenderer
                      key={`GMLResponsive-${breakpoint.key}`}
                      tree={parsedGML}
                      debug={false}
                      useContentType={false}
                      channel="web"
                      size={breakpoint.key}
                      registeredData={{
                        gmlInstanceId,
                        ...tenantStandardParams
                      }}
                    />
                  )
                }}
              </GMLResponsiveContainer>
            </RealtimeViewErrorBoundary>
          )}
        </>
      )}
    </>
  )
}

export default RealtimeView
