import React, { useCallback, useMemo } from 'react'
import Header from '../header/header'
import DealFiltersAndSearch from './dealFiltersAndSearch'
import { useDebug } from '../../context/debug'
import PipelineGrid from './pipelineGrid'
import { useDealChangeWindowFeature } from '../../hooks/useDealChangeWindowFeature'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useForecastConfigs } from '../../context/forecastConfigs'
import { useForecasting } from '../../context/forecasting'
import useRoles from '../../hooks/useRoles'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'

const Pipeline = () => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadDeal
    )
  }, [checkPermissions])

  const { debug } = useDebug()

  const { changeSince } = useDealChangeWindowFeature()

  const { selectedConfig } = useForecastConfigs()

  const { getCurrentRepForecastByConfig } = useForecasting()

  const { isIndividualContributorRoleOnly, isPlayerCoach } = useRoles()

  const { hasFeatureSettings, isDealHealthEnabled } = useOutreachFeatures()

  const showPipeline = useMemo(() => {
    return permissions.CanReadDeal && hasFeatureSettings
  }, [permissions.CanReadDeal, hasFeatureSettings])

  const onWriteBackSuccess = useCallback(() => {
    if (!selectedConfig) {
      return
    }
    if (isIndividualContributorRoleOnly || isPlayerCoach) {
      getCurrentRepForecastByConfig()
    }
  }, [getCurrentRepForecastByConfig, selectedConfig, isIndividualContributorRoleOnly, isPlayerCoach])

  return (
    <div id="pipeline" className="w-full h-screen overflow-hidden flex flex-col">

      <Header
        text="Pipeline"
        showSearch={false} />

      {showPipeline && (
        <>
          <div className="w-full px-10">
            <div className="pt-6">
              <DealFiltersAndSearch
                showOwnerFilter={true}
                changeSince={changeSince}
                tipPlacement="right"
                debug={debug} />
            </div>
          </div>

          <div className="w-full h-full flex-grow" style={{ marginLeft: 40 }}>
            <PipelineGrid
              key="PipelineDeals"
              style={{ marginLeft: 40 }}
              changeSince={changeSince}
              onWriteBackSuccess={onWriteBackSuccess}
              isDealHealthEnabled={isDealHealthEnabled}
              debug={debug} />
          </div>
        </>
      )}

    </div>
  )
}

export default Pipeline
