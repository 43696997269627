import React, { useMemo } from 'react'
import TeamMember from './teamMember'
import { filter, orderBy } from 'lodash'
import { usePeople } from '../../context/people'
import SectionHeader from '../common/sectionHeader'
import MyTeamEmptyState from './myTeamEmptyState'
import { useParams } from 'react-router-dom'
import { useGroups } from '../../context/groups'

const MyTeam = (props) => {
  const {
    showTopTen,
    setHideTeam
  } = props

  const params = useParams()
  const { userId } = params

  const { isFetching: isFetchingGroups, findGroupById } = useGroups()
  const { isFetching: isFetchingPeople, people } = usePeople()

  const peopleRanked = useMemo(() => {
    let p = filter(people, (person) => !person.hasChildren || person.isActive)
    p = orderBy(p, ['rank'])
    if (showTopTen) {
      p = p.slice(0, 10)
    }
    return p
  }, [people, showTopTen])

  const sectionTitle = useMemo(() => {
    let title = ''
    if (!isFetchingGroups) {
      if (showTopTen) {
        title = 'Top 10'
      } else {
        title = 'Team'
      }
    }
    return title
  }, [isFetchingGroups, showTopTen])

  const teamTitle = useMemo(() => {
    let title = ''
    if (!isFetchingGroups) {
      if (showTopTen) {
        title = 'Team'
      } else if (userId) {
        const team = findGroupById(userId)
        title = team?.name ?? ''
      } else {
        title = 'My Team'
      }
    }
    return title
  }, [isFetchingGroups, userId, findGroupById, showTopTen])

  const sectionDescription = useMemo(() => {
    if (teamTitle) {
      return `${teamTitle} at a glance.`
    }
  }, [teamTitle, showTopTen])

  return (
    <div className="w-full">
      {teamTitle && (
        <div className="flex justify-between">
          <SectionHeader
            title={sectionTitle}
            description={sectionDescription} />
          <a onClick={() => setHideTeam && setHideTeam(true)} className="text-color-4e5d7e text-size-12px font-weight-500 cursor-pointer my-2">Hide Team</a>
        </div>
      )}
      <div className="pt-4">
        {!isFetchingPeople && peopleRanked.length === 0 ? (
          <MyTeamEmptyState />
        ) : (
          <>
            {peopleRanked.map((person, index) => (
              <TeamMember
                key={`teamMember-${person.id}`}
                rank={index + 1}
                user={person} />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default MyTeam
