import { getCurrentForecastCategoryCall } from './helpers'
import { has } from 'lodash'
import { roundArrow } from 'tippy.js'
import { useForecastDelta, useCategoryRollup } from './hooks'
import { useForecasting } from '../../context/forecasting'
import { useDebug } from '../../context/debug'
import Call from '../../lib/call'
import classNames from 'classnames'
import numeral from 'numeral'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import TeamCallTooltip from './teamCallTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TeamListItemCall = (props) => {
  const {
    className,
    forecast,
    forecastCategory,
    isIcManagerAndDirectReport,
    isLoggedInUsersTeam,
    isManager,
    isTeamRow,
    onCallChange,
  } = props

  const { debug } = useDebug()

  const {
    forecastByUserLocal,
    removeForecastByUserLocalCategoryCall,
    teamForecasts,
    isPastForecastPeriod
  } = useForecasting()

  const { teamRollupCall } = useCategoryRollup(forecastCategory, teamForecasts)

  const {
    current,
    forecastDeltaData,
    forecastDeltaTooltip } = useForecastDelta(forecastCategory, forecast, forecastByUserLocal)

  const { ownerId } = current

  const { currentAmount, previousAmount } = forecastDeltaData

  const inputRef = useRef()

  const [isEditMode, setIsEditMode] = useState(false)
  const [prevInputValue, setPrevInputValue] = useState(0)
  const [inputValue, setInputValue] = useState(0)

  const canEdit = useMemo(() => {
    return (!forecastCategory.readOnly && !isPastForecastPeriod) || debug
  }, [forecastCategory.readOnly, isPastForecastPeriod, debug])

  const call = useMemo(() => {
    return getCurrentForecastCategoryCall(forecastCategory.id, forecast, forecastByUserLocal[ownerId])
  }, [forecast, forecastByUserLocal, forecastCategory.id, ownerId])

  const hideQuota = useMemo(() => {
    const { customQuerySettings } = forecastCategory?.forecastConfigValueSettings || {}
    return customQuerySettings?.canopyDataUrl === 'internal::commit.forecast.view.goalAmount'
  }, [forecastCategory?.forecastConfigValueSettings])

  const hasLocalCategoryCall = useMemo(() => {
    if (!isLoggedInUsersTeam && !isIcManagerAndDirectReport) {
      return false
    }
    return has(forecastByUserLocal[ownerId]?.localCategoryCalls, forecastCategory.id)
  }, [isLoggedInUsersTeam, isIcManagerAndDirectReport, forecastByUserLocal, ownerId, forecastCategory.id])

  const onRemoveCall = useCallback((forecast, forecastCategory) => {
    removeForecastByUserLocalCategoryCall({ forecast, forecastCategory })
    window.analytics.track('forecasting.v3.teamListItem.callRemoved')
  }, [removeForecastByUserLocalCategoryCall])

  const callMade = useMemo(() => {
    return teamRollupCall.raw !== call.raw
  }, [teamRollupCall, call])

  const labelDecorator = useMemo(() => {
    if (!canEdit) {
      return
    }

    if (forecastByUserLocal[ownerId]?.localCategoryCalls?.[forecastCategory.id]) {
      return (
        <div
          onClick={() => onRemoveCall(forecast, forecastCategory)}
          className="float-right cursor-pointer"
          style={{ transform: 'translate(16px, -18px)' }}>
          <FontAwesomeIcon
            icon="trash"
            size="xs"
            className="text-color-a0a8bb hover:text-color-151d49"
            style={{ width: 12, height: 12 }} />
        </div>
      )
    }
    if (isManager && isTeamRow && callMade) {
      return (
        <div
          className="float-right cursor-pointer"
          style={{ transform: 'translate(20px, -18px)' }}>
          <TeamCallTooltip
            currentCall={call}
            forecastCategory={forecastCategory}
            ownerId={ownerId} />
        </div>
      )
    }
  }, [
    call,
    callMade,
    canEdit,
    forecast,
    forecastByUserLocal,
    forecastCategory,
    isManager,
    isTeamRow,
    onRemoveCall,
    ownerId,
  ])

  const quotaPerc = useMemo(() => {
    const { goalAmount } = forecast?.current
    const percent = call.value / goalAmount
    if (hideQuota || !isFinite(percent)) {
      return ''
    }
    return (goalAmount > 0 ? `${numeral(percent).format('0%')} of quota` : 'No quota')
  }, [call, forecast, hideQuota])

  useEffect(() => {
    setPrevInputValue(call)
    setInputValue(call)
  }, [call])

  const onLabelClick = useCallback(() => {
    setIsEditMode(true)
  }, [])

  const onChange = useCallback((e) => {
    call.update(e.target.value)
    setInputValue(new Call(call))
  }, [call])

  const onBlur = useCallback((e) => {
    setIsEditMode(false)
    if (e.cancelled) {
      return
    }
    if (inputValue === '') {
      setInputValue(prevInputValue)
      return
    }
    if (inputValue !== prevInputValue) {
      // if (validator && !validator(inputValue)) {
      // setIsEditMode(true)
      // notifyError && notifyError(validatorMessage || 'An error occurred')
      // return
      // }
      onCallChange?.({
        ownerId,
        forecastCategory,
        inputValue: call.value
      })
    }
  }, [forecastCategory, inputValue, onCallChange, ownerId, prevInputValue, call])

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setInputValue(prevInputValue)
      onBlur({ cancelled: true })
    } else if (e.key === 'Enter') {
      onBlur({ cancelled: false })
    }
  }, [onBlur, prevInputValue])

  const labelClassName = useMemo(() => {
    return classNames(
      { 'cursor-pointer underline-dashed': canEdit },
      { 'cursor-default': !canEdit },
      { 'font-bold': hasLocalCategoryCall },
      {
        'text-color-1dcf83 font-bold': canEdit && currentAmount.value > previousAmount.value
      },
      {
        'text-color-fa6d6b font-bold': canEdit && currentAmount.value < previousAmount.value
      }
    )
  }, [canEdit, hasLocalCategoryCall, currentAmount, previousAmount])

  const showTip = useCallback(() => {
    return canEdit && previousAmount.value !== currentAmount.value
  }, [canEdit, currentAmount, previousAmount])

  return (
    <div className={classNames(className)} style={{ minWidth: 120, position: 'relative' }}>
      <div className="inline-block" style={{ height: 20 }}>
        {isEditMode
          ? (
            <div>
              <input
                autoFocus
                ref={inputRef}
                className="px-1 rounded focus:outline-none"
                style={{ minWidth: 120, width: 'calc(100% - 10px)' }}
                type="number"
                onChange={onChange}
                value={inputValue?.initial}
                placeholder={prevInputValue?.value}
                onBlur={onBlur}
                onKeyDown={onKeyDown} />
            </div>
          )
          : (
            <div>
              <Tippy
                onShow={showTip}
                offset={[0, 6]}
                duration={[0, 0]}
                maxWidth={800}
                content={forecastDeltaTooltip}
                arrow={roundArrow}
                theme="canopy">
                <div
                  {...canEdit && { onClick: onLabelClick }}
                  className={labelClassName}>
                  {inputValue.toString()}
                </div>
              </Tippy>
              {labelDecorator}
            </div>
          )}
      </div>
      <div className="text-color-91959f text-size-12px font-normal text-left">{quotaPerc}</div>
    </div>
  )
}

export default TeamListItemCall
