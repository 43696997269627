import PropTypes from 'prop-types/prop-types'
import { paddingPropTypes, strokePropTypes, marginPropTypes, offsetPropTypes, svgTextPropTypes } from '../propTypes/common'

export const HorizontalBarChartConfig = {
  padding: {
    top: 20,
    bottom: 20,
    left: 0,
    right: 0
  },
  bar: {
    style: {
      strokeWidth: 14,
      strokeLinecap: 'round'
    },
    margin: {
      top: 10,
      bottom: 10,
      left: 0,
      right: 0,
    },
    group: {
      style: {
        strokeWidth: 14,
        strokeLinecap: 'round'
      },
      margin: {
        top: 15,
        bottom: 15,
        left: 0,
        right: 0,
      },
    },
    stack: {
      style: {
        strokeWidth: 40,
        strokeLinecap: 'butt'
      },
      margin: {
        top: 30,
        bottom: 30,
        left: 0,
        right: 0,
      },
    },
  },
  barLabel: {
    offset: {
      // offset the render time rect of the barLabel
      x: 4,
      y: 1.5,
      width: 10,
      height: 0
    },
    style: {
      textAnchor: 'start',
      fontWeight: 'normal',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
  axisBarLabel: {
    offset: {
      // offset the render time rect of the axisBarLabel
      x: -4,
      y: 1,
      width: 4,
      height: 0
    },
    style: {
      textAnchor: 'end',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
  axisDataLabel: {
    offset: {
      // offset the render time rect of the axisDataLabel
      x: 0,
      y: 0,
      width: 0,
      height: 0
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'normal',
      fontSize: 12,
      fontFamily: 'Proxima Nova',
      fill: '#919191',
    },
    gutter: {
      width: 0, // for vertical orientation
      height: 20 // for horizontal orientation
    },
  },
  groupBarLabel: {
    offset: {
      // offset the render time rect of the barLabel
      x: 0,
      y: 0,
      width: 0,
      height: 0
    },
    style: {
      textAnchor: 'start',
      fontWeight: 'normal',
      fontSize: 11,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
}

export const VerticalBarChartConfig = {
  height: 320,
  padding: {
    top: 20,
    bottom: 40,
    left: 20,
    right: 20
  },
  bar: {
    style: {
      strokeWidth: 14,
      strokeLinecap: 'round'
    },
    margin: {
      top: 0,
      bottom: 0,
      left: 10,
      right: 10,
    },
    group: {
      style: {
        strokeWidth: 14,
        strokeLinecap: 'round'
      },
      margin: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }
    },
    stack: {
      style: {
        strokeWidth: 28,
        strokeLinecap: 'butt'
      },
      margin: {
        top: 0,
        bottom: 0,
        left: 10,
        right: 10,
      }
    },
  },
  barLabel: {
    offset: {
      // offset the render time rect of the barLabel
      x: 0,
      y: 0,
      width: 0,
      height: 0
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'normal',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
  axisBarLabel: {
    offset: {
      // offset the render time rect of the axisBarLabel
      x: 0,
      y: 0,
      width: 0,
      height: 0
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
  axisDataLabel: {
    offset: {
      // offset the render time rect of the axisDataLabel
      x: 5,
      y: 0,
      width: 10,
      height: 0
    },
    style: {
      textAnchor: 'start',
      fontWeight: 'normal',
      fontSize: 12,
      fontFamily: 'Proxima Nova',
      fill: '#919191',
    },
    gutter: {
      width: 0,
      height: 20,
    },
  },
  groupBarLabel: {
    offset: {
      // offset the render time rect of the barLabel
      x: 0,
      y: 0,
      width: 0,
      height: 0
    },
    style: {
      textAnchor: 'start',
      fontWeight: 'normal',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#151d49',
    },
  },
}

export const barChartConfigPropTypes = {
  height: PropTypes.number,
  padding: PropTypes.shape(paddingPropTypes),
  bar: PropTypes.shape({
    style: PropTypes.shape(strokePropTypes),
    margin: PropTypes.shape(marginPropTypes),
    group: PropTypes.shape({
      style: PropTypes.shape(strokePropTypes),
      margin: PropTypes.shape(marginPropTypes),
    }),
    stack: PropTypes.shape({
      style: PropTypes.shape(strokePropTypes),
      margin: PropTypes.shape(marginPropTypes),
    }),
  }),
  barLabel: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  axisBarLabel: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  axisDataLabel: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
    gutter: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number
    }),
  }),
  groupBarLabel: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
}
