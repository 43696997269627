import React from 'react'
import classNames from 'classnames'

const Card = (props) => {
  const { className, children } = props

  return (
    <div className={classNames(className, 'bg-color-ffffff border border-color-2e5bff-08 rounded-lg w-full h-full px-10 my-8')}>
      {children}
    </div>
  )
}

export default Card
