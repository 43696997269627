import React, { useRef, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import { DonutChartConfig, donutChartConfigPropTypes } from '../config/donutChartConfig'
import ConditionalWrapper from './conditionalWrapper'
import Tooltip from './tooltip'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import merge from 'lodash/merge'
import sum from 'lodash/sum'
import { createTooltipContent, getPlugins } from '../renderer/gmlRenderer'
import useRefResize from '../../hooks/useRefResize'

const DonutChart = (props) => {
  const {
    getRendererProps,
    config = {},
    name,
    styleNames,
    childIndex,
    hide = false,
    layered = false,
    layerMargin = 10,
    tooltips = false,
    colors,
    height = 300,
    segmentWidth = 28,
  } = props

  const zeroSubstitution = 0.000001

  const series = useMemo(() => {
    const s = props.series
    if (!s) {
      return [zeroSubstitution]
    } else if (Array.isArray(s) && s.length > 0 && Array.isArray(s[0])) {
      return s.map((arr) => arr.map((value) => Math.max(value, zeroSubstitution)))
    }
    return s.map((value) => Math.max(value, zeroSubstitution))
  }, [props])

  PropTypes.checkPropTypes(DonutChart.propTypes, props, 'prop', 'DonutChart')

  const containerRef = useRef()
  const svgRef = useRef()
  const [svgWidth, setSvgWidth] = useState(0)
  const [svgHeight, setSvgHeight] = useState(0)

  const handleResize = useCallback(({ width }) => {
    setSvgWidth(width)
  }, [])

  useRefResize(containerRef, handleResize)

  const mergedConfig = useMemo(() => {
    const c = cloneDeep(DonutChartConfig)
    merge(c, config)

    setSvgHeight(height)

    return c
  }, [])

  const chartRect = useMemo(() => {
    const availableWidth = svgWidth - (mergedConfig.padding.left + mergedConfig.padding.right) - mergedConfig.segment.style.strokeWidth
    const availableHeight = height - (mergedConfig.padding.top + mergedConfig.padding.bottom) - mergedConfig.segment.style.strokeWidth
    const minSize = Math.min(availableWidth, availableHeight)
    const rect = {
      x: mergedConfig.padding.left + (mergedConfig.segment.style.strokeWidth / 2) + ((availableWidth - minSize) / 2),
      y: mergedConfig.padding.top + (mergedConfig.segment.style.strokeWidth / 2) + ((availableHeight - minSize) / 2),
      width: minSize,
      height: minSize,
    }
    return rect
  }, [svgWidth, mergedConfig])

  const segments = useMemo(() => {
    const s = []
    const centerX = chartRect.x + (chartRect.width / 2)
    const centerY = chartRect.y + (chartRect.height / 2)

    const polarToCartesian = (angleInDegrees, radius) => {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      }
    }

    const getSegmentData = (startAngle, endAngle, radius) => {
      const start = polarToCartesian(Math.min(endAngle, 360), radius)
      const end = polarToCartesian(startAngle, radius)
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
      const d = [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(' ')
      return d
    }

    let r = Math.max(chartRect.width / 2, 0)

    if (layered) {
      forEach(series, (layer, seriesIndex) => {
        let currentAngle = 0
        const seriesSum = sum(layer)
        forEach(layer, (data, dataIndex) => {
          const perc = data / seriesSum
          const angle = Math.max(perc * 360, 1)
          const startAngle = currentAngle
          const endAngle = currentAngle + angle
          r = Math.max(r, 1)
          s.push({
            originalData: data,
            seriesIndex,
            dataIndex,
            perc,
            startAngle,
            endAngle,
            radius: r,
            layerSegments: layer,
            data: getSegmentData(startAngle, endAngle, r)
          })
          currentAngle += angle
        })
        r -= (layerMargin + segmentWidth)
      })
    } else {
      let currentAngle = 0
      const seriesSum = sum(series)
      forEach(series, (data, seriesIndex) => {
        const perc = data / seriesSum
        const angle = Math.max(perc * 360, 1)
        const startAngle = currentAngle
        const endAngle = currentAngle + angle
        s.push({
          originalData: data,
          seriesIndex,
          perc,
          startAngle,
          endAngle,
          radius: r,
          data: getSegmentData(startAngle, endAngle, r)
        })
        currentAngle += angle
      })
    }

    return s
  }, [mergedConfig, chartRect])

  // the main config object
  const cfg = useMemo(() => {
    const c = mergedConfig
    c.props = props
    c.chartRect = chartRect

    PropTypes.checkPropTypes(donutChartConfigPropTypes, c, 'prop', 'DonutChart')

    // console.log(c)
    return c
  }, [svgWidth, mergedConfig, chartRect])

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-donutchart',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const renderSegments = useCallback(() => {
    return segments.map((segment) => {
      const { data, seriesIndex, dataIndex, startAngle, endAngle, radius, layerSegments } = segment
      const result = tooltips ? createTooltipContent(getRendererProps, { data, _seriesIndex: seriesIndex }) : { tree: {} }
      const { tree, content } = result
      const disabled = get(find(tree.attributesList, (a) => a.key === 'disabled') || {}, 'value', false)

      const pathStyle = { strokeWidth: segmentWidth, fill: 'none' }

      if (typeof colors === 'string') {
        pathStyle.stroke = colors
      } else if (Array.isArray(colors) && colors.length > 0 && Array.isArray(colors[0])) {
        const color = get(colors, `[${seriesIndex}][${dataIndex}]`, '#000000')
        pathStyle.stroke = color
      } else if (Array.isArray(colors)) {
        const color = get(colors, `[${seriesIndex}]`, '#000000')
        pathStyle.stroke = color
      }

      const renderCircle = segments.length === 1 || (layered && layerSegments.length === 1)

      return (
        <ConditionalWrapper
          key={`wrapper-segment-${seriesIndex}-${dataIndex}`}
          condition={tooltips && content}
          wrapper={(children) => <Tooltip disabled={disabled} content={content}>{children}</Tooltip>}>
          <g key={`segment-${seriesIndex}-${dataIndex}`} className="g-donutchart-segment focus:outline-none">
            {renderCircle
              ? (
                <circle
                  className={`g-donutchart-segment series-${seriesIndex} data-${dataIndex} focus:outline-none`}
                  cx={Math.max(chartRect.x + chartRect.width / 2, 0)}
                  cy={Math.max(chartRect.y + chartRect.height / 2, 0)}
                  r={radius}
                  style={pathStyle} />
              )
              : (
                <path
                  d={segment.data}
                  className={`g-donutchart-segment series-${seriesIndex} data-${dataIndex} focus:outline-none`}
                  style={pathStyle} />
              )}
          </g>
        </ConditionalWrapper>
      )
    })
  }, [segments])

  const renderPlugins = useCallback((size) => {
    if (svgRef.current) {
      const plugins = getPlugins(getRendererProps)
      return plugins.map((p) => {
        merge(cfg, { plugins: { [p.type]: { config: p.config } } })
        return (
          p.plugin(cfg)
        )
      })
    }
  }, [cfg])

  return (
    <div ref={containerRef} {...attributes} style={{ height: svgHeight }}>
      <svg ref={svgRef} width="100%" height={svgHeight}>
        {props.debug && (
          <rect
            x={cfg.chartRect.x}
            y={cfg.chartRect.y}
            width={Math.max(cfg.chartRect.width, 0)}
            height={Math.max(cfg.chartRect.height, 0)}
            style={{ fill: 'red', opacity: 0.5 }} />
        )}

        {renderSegments()}
        {renderPlugins()}
      </svg>
    </div>
  )
}

DonutChart.propTypes = {
  getRendererProps: PropTypes.func,
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  series: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
  ]),
  layered: PropTypes.bool,
  layerMargin: PropTypes.number,
  tooltips: PropTypes.bool,
  colors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  ]),
  height: PropTypes.number,
  segmentWidth: PropTypes.number,
}

export default DonutChart
