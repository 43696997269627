import { useState, useLayoutEffect, useCallback } from 'react'

const initialState = { width: 0, height: 0 }

export default function useRefSize(ref) {
  const [size, setSize] = useState(initialState)

  const handleResize = useCallback(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect()
      setSize({
        width,
        height,
      })
    }
  }, [ref])

  useLayoutEffect(() => {
    handleResize()

    if (typeof ResizeObserver === 'function') {
      const currentRef = ref.current
      let req
      const resizeObserver = new ResizeObserver((entries) => {
        req = requestAnimationFrame(() => {
          const contentRect = entries?.[0]?.contentRect
          if (contentRect) {
            const { width, height } = contentRect
            setSize({
              width,
              height,
            })
          }
        })
      })
      if (currentRef) {
        resizeObserver.observe(currentRef)
      }
      return () => {
        if (currentRef) {
          resizeObserver.unobserve(currentRef)
        } else {
          resizeObserver.disconnect()
        }
        req && cancelAnimationFrame(req)
      }
    } else {
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [ref])

  return size
}
