import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import moment from 'moment'

const DateTime = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    format = 'fromNow',
    seconds = 0,
    nanos = 0,
  } = props

  PropTypes.checkPropTypes(DateTime.propTypes, props, 'prop', 'DateTime')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-datetime',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  let value
  if (format === 'fromNow') {
    value = moment(seconds * 1000).fromNow()
  } else {
    value = moment(seconds * 1000).utc().format(format)
  }

  return (
    <span {...attributes}>{value}</span>
  )
}

DateTime.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  format: PropTypes.string,
  seconds: PropTypes.number,
  nanos: PropTypes.number,
}

export default DateTime
