import React, { useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePopover } from '../../../hooks/usePopover'
import Popover from '../../popover'
import classNames from 'classnames'
import { useForecastConfigs } from '../../../context/forecastConfigs'

const ForecastSelector = () => {
  const { open, anchorEl, setAnchorEl, onClose } = usePopover()

  const { selectedConfig, setSelectedConfig, configsList } = useForecastConfigs()

  const name = useMemo(() => {
    return selectedConfig?.name ?? ''
  }, [selectedConfig])

  const enableSelector = useMemo(() => {
    return configsList.length > 1
  }, [configsList])

  const onClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  const onChange = useCallback((config) => {
    window.analytics.track('forecasting.v3.forecastSelector.forecastChanged', { forecast: config })
    setSelectedConfig(config)
    onClose()
  }, [onClose, setSelectedConfig])

  return (
    <>
      <div
        onClick={onClick}
        className={classNames('flex items-center cursor-pointer', { 'pointer-events-none': !enableSelector })}>
        <div className="flex items-start text-color-09242f text-size-18px font-bold">{name}</div>
        {enableSelector
          && (
            <FontAwesomeIcon
              icon="chevron-down"
              className="mx-2 text-color-09242f"
              size="sm"
              style={{ transform: 'translateX(0px)' }} />
          )}
      </div>
      <Popover
        style={{ zIndex: 999999999, maxWidth: 250 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="top"
        timeout={0}>
        <div className="flex flex-col py-2">
          {configsList.map((config, index) => (
            <div
              key={`config-${config.id}-${index}`}
              onClick={() => onChange(config)}
              className={classNames('group flex items-center justify-between px-4 py-2',
                'text-size-14px text-left cursor-pointer focus:outline-none hover:bg-color-edeeee leading-tight',
                { 'font-weight-500 text-color-09242f ': config.id !== selectedConfig?.id },
                { 'font-bold text-color-5951FF pointer-events-none': config.id === selectedConfig?.id })}>
              <span>
                {config.name}
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default ForecastSelector
