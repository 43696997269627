import { createReducer } from '@reduxjs/toolkit'
import { orderBy, uniqBy } from 'lodash'
import { clearImpersonationUsers, clearTenants } from '../actions'
import { getImpersonationUsersSuccess, getTenantsSuccess } from '../actions/tenantService'

const initialState = {
  tenantsList: [],
  total: 0,
}

export const tenants = createReducer(initialState, {
  [clearTenants.type]: (state, action) => initialState,
  [getTenantsSuccess.type]: (state, action) => {
    const { tenantsList, total } = action.payload
    const tenants = orderBy(uniqBy([
      ...state.tenantsList,
      ...tenantsList,
    ], (t) => t.id), (o) => o.name)
    return {
      tenantsList: tenants,
      total,
    }
  }
})

const impersonationUsersInitialState = {
  peopleList: [],
  total: 0,
}

export const impersonationUsers = createReducer(impersonationUsersInitialState, {
  [clearImpersonationUsers.type]: (state, action) => impersonationUsersInitialState,
  [getImpersonationUsersSuccess.type]: (state, action) => {
    const { peopleList, total } = action.payload
    const people = orderBy(uniqBy([
      ...state.peopleList,
      ...peopleList,
    ], (t) => t.id), (o) => o.name)
    return {
      peopleList: people,
      total,
    }
  }
})
