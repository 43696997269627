import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import SearchBox from '../search/searchBox'
import { useTextField } from '../../hooks/useTextField'
import { setSearchText } from '../../actions'
import ViewForecast from '../forecasting/viewForecast'
import useRoles from '../../hooks/useRoles'
import DealFilters from './dealFilters'
import DealSearchSummary from './dealSearchSummary'
import ViewsAndFilters from './viewsAndFilters'
import { useExplicitViews } from './hooks'
import queryString from 'query-string'
import { ViewsProvider } from '../../context/views'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'

const DealFiltersAndSearch = (props) => {
  const {
    showOwnerFilter,
    userIds,
    changeSince,
    tipPlacement,
    debug,
  } = props

  const { hasFeatureSettings, isDealHealthEnabled, isSuccessPlansEnabled } = useOutreachFeatures()

  const [initialSearch, setInitialSearch] = useState('')

  const dispatch = useDispatch()

  const { isIndividualContributorRole } = useRoles()

  const explicitViews = useExplicitViews()

  const searchFilters = useSelector((state) => state.searchFilters)
  const { searchText } = searchFilters

  const search = useTextField({
    defaultValue: searchText,
    onReset: () => {
      dispatch(setSearchText(''))
      setInitialSearch('')
    },
  })

  useEffect(() => {
    const urlParts = window.location.href.split('#')
    if (urlParts.length > 1) {
      const hash = queryString.parse(`#${urlParts[1]}`)
      if (hash.search) {
        setInitialSearch(hash.search)
      }
    }
  }, [])

  useEffect(() => {
    const urlParts = window.location.href.split('#')
    if (urlParts.length > 1) {
      const hash = queryString.parse(`#${urlParts[1]}`)
      if (hash.search) {
        search.setValue(hash.search)
      }
    }
  }, [])

  const doSearch = useCallback((searchValue) => {
    dispatch(setSearchText(searchValue))
  }, [])

  const debounceSearch = useMemo(() => {
    return debounce(doSearch, 350)
  }, [doSearch])

  useEffect(() => {
    debounceSearch(search.value)
  }, [search.value, debounceSearch])

  return (
    <div className="flex items-start justify-between pb-1">

      <div className="w-full flex flex-col">

        <div className="flex flex-row items-start justify-between">

          <div className="flex flex-row items-center">
            <div className="text-size-24px text-color-151d49 font-bold py-1 mt-2 whitespace-nowrap">
              <span className="text-size-24px text-color-151d49 font-bold">Deals</span>
            </div>
            <DealFilters
              showOwnerFilter={showOwnerFilter}
              tipPlacement={tipPlacement}
              debug={debug} />
            <SearchBox
              className="mt-2 mr-2"
              placeholder="Search for a deal"
              value={search.value || initialSearch}
              onChange={search.onChange}
              onClear={search.reset}
              autoFocus={true} />
          </div>

          <DealSearchSummary />

        </div>

        {hasFeatureSettings && (
          <ViewsProvider filterKey="pipeline">
            <ViewsAndFilters
              filterKey="pipeline"
              className="flex flex-wrap py-3"
              userIds={userIds}
              changeSince={changeSince}
              explicitViews={explicitViews}
              fetchDealHealthData={isDealHealthEnabled || isSuccessPlansEnabled} />
          </ViewsProvider>
        )}

      </div>

      {isIndividualContributorRole && (
        <ViewForecast />
      )}

    </div>
  )
}

export default DealFiltersAndSearch
