import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../header/header'
import AnimatedLoader from '../loaders/animatedLoader'
import StrengthsAndWeaknessesLegacy from '../signals/strengthsAndWeaknessesLegacy'
import RepKeyMetrics from '../signals/repKeyMetrics'
import ActivityTrends from '../scorecard/activityTrends'
import { apiSuccessSelector, featureSettingSelector } from '../../selectors'
import { clearApiActivityByKey, clearHeaderMetrics, clearKeyMetrics, clearStrengths, clearWeaknesses } from '../../actions'
import { getHeaderMetricsByUserKey, getKeyMetricsByUserKey, getSignalStrengthsByUserKey, getSignalWeaknessesByUserKey } from '../../actions/signalService'
import { getHeaderMetricsByUser, getKeyMetricsByUser, getSignalStrengthsByUser, getSignalWeaknessesByUser } from '../../services/signalService'
import { keyMetricsPersonFilterHeaders } from '../signals/constants'
import { metricNames } from '../header/constants'
import Goals from './goals'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useTenantInfo } from '../../context/tenantInfo'
import { has } from 'lodash'
import classNames from 'classnames'
import { featureNames } from '../../constants/featureNames'

const PerformanceForRepLegacy = (props) => {
  const { loggedInUser, loggedInUserId } = useLoggedInUser()
  const userId = loggedInUserId

  const { tenantInfo } = useTenantInfo()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!has(tenantInfo, 'currencyCode')) {
      return
    }

    if (userId) {
      const goalsFilterHeaders = [
        {
          key: 'filter|definition.level|eq',
          value: 'person'
        },
        {
          key: 'filter|definition.name|in',
          value: `${metricNames.goals}`
        },
      ]
      dispatch(clearApiActivityByKey(getHeaderMetricsByUserKey))
      dispatch(clearHeaderMetrics())
      dispatch(getHeaderMetricsByUser(userId, 0, 1, goalsFilterHeaders, true))

      dispatch(clearApiActivityByKey(getSignalStrengthsByUserKey))
      dispatch(clearApiActivityByKey(getSignalWeaknessesByUserKey))
      dispatch(clearStrengths())
      dispatch(clearWeaknesses())
      dispatch(getSignalStrengthsByUser(userId, 0, -1))
      dispatch(getSignalWeaknessesByUser(userId, 0, -1))

      dispatch(clearApiActivityByKey(getKeyMetricsByUserKey))
      dispatch(clearKeyMetrics())
      dispatch(getKeyMetricsByUser(userId, 0, -1, keyMetricsPersonFilterHeaders, true))
    }
  }, [loggedInUser, tenantInfo])

  const headerMetricsSuccess = useSelector((state) => apiSuccessSelector(state, getHeaderMetricsByUserKey))
  const signalStrengthsSuccess = useSelector((state) => apiSuccessSelector(state, getSignalStrengthsByUserKey))
  const signalWeaknessesSuccess = useSelector((state) => apiSuccessSelector(state, getSignalWeaknessesByUserKey))
  const userMetricsSuccess = useSelector((state) => apiSuccessSelector(state, getKeyMetricsByUserKey))

  const ready = useMemo(() => {
    return headerMetricsSuccess && signalStrengthsSuccess && signalWeaknessesSuccess && userMetricsSuccess
  }, [loggedInUser, headerMetricsSuccess, signalStrengthsSuccess, signalWeaknessesSuccess, userMetricsSuccess])

  const activityTrendsFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.activityTrends }))

  const hideActivityTrends = useMemo(() => {
    const enabled = activityTrendsFeature?.payload?.enabled ?? false
    return !enabled
  }, [activityTrendsFeature])

  return (
    <>
      <Header
        text="Performance"
        showSearch={false} />
      <div className="main-body" style={{ minHeight: '100vh' }}>

        {!ready
          ? (
            <div className="h-screen">
              <AnimatedLoader
                className="mt-16"
                title="Loading Scorecard"
                subTitle="Please wait..."
                maxWidth={300} />
            </div>
          )
          : (
            <>

              <Goals
                user={loggedInUser}
                level="person"
                title="Goals"
                subTitle="Your performance, as tracked by your manager, toward specific goals." />

              <StrengthsAndWeaknessesLegacy
                key={`StrengthsAndWeaknessesLegacy-${userId}`}
                userId={userId}
                title="Strengths and Weaknesses"
                subTitle="Areas where you excel and where you have room to grow, often compared to your team." />

              <div className="flex mt-6 mr-4">

                <div className={classNames('w-full', { 'w-8/12': !hideActivityTrends })}>
                  <RepKeyMetrics
                    id={userId}
                    title="Trends"
                    subTitle="Your performance, as tracked by your manager, on key business metrics." />
                </div>

                <div className={classNames('ml-8', { hidden: hideActivityTrends }, { 'block w-4/12': !hideActivityTrends })}>
                  <ActivityTrends user={loggedInUser} />
                </div>

              </div>

            </>
          )}

      </div>
    </>
  )
}

export default PerformanceForRepLegacy
