import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { opportunitiesSelector, apiSuccessSelector } from '../../selectors'
import { getOpportunitiesByTeamKey } from '../../actions/opportunityService'
import DealCard from './dealCard'
import debounce from 'lodash/debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cardScrollViewSettings } from '../../constants/cardScrollViewSettings'
import classNames from 'classnames'

const DealsSliderLegacy = (props) => {
  const { cardOffsetWidth } = cardScrollViewSettings
  const nextPrevBtnStyle = {
    width: '48px',
    height: '48px',
    marginTop: 125,
    fontSize: '13px',
    border: '1px solid rgb(227, 232, 250)',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
  }

  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [enableNextBtn, setEnableNextBtn] = useState(false)

  const opportunities = useSelector(opportunitiesSelector)

  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getOpportunitiesByTeamKey))

  const scrollView = useRef(null)
  const [canShowNextPrevBtns, setCanShowNextPrevBtns] = useState(false)

  const getNearestCardIndex = () => {
    const { scrollLeft } = scrollView.current
    return Math.round(scrollLeft / cardOffsetWidth)
  }

  const scrollToCardIndex = (index) => {
    scrollView.current && scrollView.current.scroll({ left: index * cardOffsetWidth })
  }

  const debounceScrollEnded = debounce(scrollToCardIndex, 750)

  const handleScroll = useCallback((e) => {
    const { scrollLeft, offsetWidth, scrollWidth } = scrollView.current
    const enableNext = offsetWidth + scrollLeft < scrollWidth
    setEnablePrevBtn(scrollLeft > 0)
    setEnableNextBtn(enableNext)
    if (enableNext) {
      debounceScrollEnded(getNearestCardIndex())
    }
  }, [])

  useEffect(() => {
    if (opportunities.length > 0) {
      setCanShowNextPrevBtns(apiSuccess && (scrollView.current && scrollView.current.scrollWidth > scrollView.current.clientWidth))
      handleScroll()
    }
  }, [opportunities, apiSuccess, scrollView])

  const prev = useCallback(() => {
    window.analytics.track('Deals Scroller', { action: 'Back' })
    scrollToCardIndex(getNearestCardIndex() - 1)
  }, [])

  const next = useCallback(() => {
    window.analytics.track('Deals Scroller', { action: 'Forward' })
    scrollToCardIndex(getNearestCardIndex() + 1)
  }, [])

  const sharedBtnClasses = 'flex items-center bg-color-ffffff rounded-full bg-opacity-50 cursor-pointer pointer-events-auto'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, '-mr-13', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  return (
    <div className="relative">

      {canShowNextPrevBtns && (
        <div className="-ml-10 -mr-6 z-20 absolute flex justify-between w-full pointer-events-none select-none">
          <div className={prevBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={prev}>
            <FontAwesomeIcon icon="chevron-left" size="2x" color="#c9ced0" className="mx-auto" />
          </div>
          <div className={nextBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={next}>
            <FontAwesomeIcon icon="chevron-right" size="2x" color="#c9ced0" className="mx-auto" />
          </div>
        </div>
      )}

      <div className="-ml-10 -mr-6">
        <h1 className="ml-10">Deals to Review</h1>
        <div
          ref={scrollView}
          className="mt-3 pb-2 inline-flex max-w-full overflow-y-hidden no-scrollbar"
          style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}
          onScroll={handleScroll}>
          {opportunities.map((opportunity, index) => <DealCard index={index} key={`dealsSlider-dealCard-${opportunity.id}-${index}`} opportunity={opportunity} />)}
        </div>
      </div>

    </div>
  )
}

export default DealsSliderLegacy
