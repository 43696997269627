import React, { useCallback, useMemo } from 'react'
import Popover from '../popover'
import { useCanvasItem } from '../../context/canvasItem'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import classNames from 'classnames'
import { usePopover } from '../../hooks/usePopover'
import ActionPopover from '../common/actionPopover'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import { MoreVert } from '@getoutreach/react-icons'
import { useRealtimeViewModal } from '../../context/realtimeViewModal'

const sharedClasses = 'px-4 py-1 text-size-14px text-color-09242f font-normal cursor-pointer hover:bg-color-edeeee'

const FilterActions = (props) => {
  const {
    name,
    pendingFilter,
    defaultUserQueryParameter,
    orgDefaultUserQueryParameter,
    setDefault,
    setOrgDefault
  } = useCanvasItem()

  const { setIsEditingName } = useRealtimeViewModal()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageSignal
    )
  }, [checkPermissions])

  const {
    workingFilter,
    canSave: canSaveFilter,
    saveWorkingFilter,
    discardChanges,
    deleteFilter,
    duplicateFilter,
    createNewFilter,
    setShared
  } = useRealtimeFilterEditor()

  const isSharedFilter = useMemo(() => {
    return workingFilter?.userId === ''
  }, [workingFilter])

  const isDefaultFilter = useMemo(() => {
    return workingFilter?.id === defaultUserQueryParameter
  }, [workingFilter, defaultUserQueryParameter])

  const isOrgDefaultFilter = useMemo(() => {
    return workingFilter?.id === orgDefaultUserQueryParameter
  }, [workingFilter, orgDefaultUserQueryParameter])

  const canSave = useMemo(() => {
    if (isSharedFilter) {
      return permissions.CanManageSignal && canSaveFilter
    } else {
      return canSaveFilter
    }
  }, [isSharedFilter, canSaveFilter, permissions])

  const canRename = useMemo(() => {
    if (isSharedFilter) {
      return permissions.CanManageSignal
    } else {
      return true
    }
  }, [isSharedFilter, permissions])

  const canShare = useMemo(() => {
    return permissions.CanManageSignal && !isSharedFilter
  }, [permissions, isSharedFilter])

  const canSetAsOrgDefault = useMemo(() => {
    return isSharedFilter && permissions.CanManageSignal
  }, [isSharedFilter, permissions])

  const canDelete = useMemo(() => {
    if (isSharedFilter) {
      return permissions.CanManageSignal
    } else {
      return true
    }
  }, [isSharedFilter, permissions])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()
  const deletePopover = usePopover()

  const onCreateNewFilter = useCallback(() => {
    createNewFilter()
    window.analytics.track('realtimeMetrics.filterModal.createNewFilterClicked', { name })
  }, [name, createNewFilter])

  const onSave = useCallback(() => {
    saveWorkingFilter()
    window.analytics.track('realtimeMetrics.filterModal.options.saveClicked', { name })
  }, [name, saveWorkingFilter])

  const onOptions = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    window.analytics.track('realtimeMetrics.filterModal.optionsClicked', { name })
  }, [name])

  const onDuplicate = useCallback(() => {
    duplicateFilter()
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.duplicateClicked', { name })
  }, [name, duplicateFilter, onClose])

  const onRename = useCallback(() => {
    setIsEditingName(true)
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.renameClicked', { name })
  }, [name, onClose])

  const onShare = useCallback(() => {
    setShared()
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.shareClicked', { name })
  }, [name, setShared, onClose])

  const onSetDefault = useCallback(() => {
    setDefault(workingFilter?.id)
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.setDefaultClicked', { name })
  }, [name, setDefault, workingFilter, onClose])

  const onSetOrgDefault = useCallback(() => {
    setOrgDefault(workingFilter?.id)
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.setOrgDefaultClicked', { name })
  }, [name, setOrgDefault, workingFilter, onClose])

  const onDiscardChanges = useCallback(() => {
    discardChanges()
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.discardChangesClicked', { name })
  }, [name, discardChanges, onClose])

  const onConfirmDelete = useCallback(() => {
    deleteFilter()
    deletePopover.onClose()
    onClose()
    window.analytics.track('realtimeMetrics.filterModal.options.deleteClicked', { name })
  }, [deleteFilter, onClose])

  const onDelete = useCallback((e) => {
    deletePopover.onClose()
    deletePopover.setAnchorEl(e.currentTarget)
  }, [])

  return (
    <>
      <div
        onClick={onCreateNewFilter}
        className={classNames('mr-4 text-color-5951FF text-size-16px font-weight-500 cursor-pointer',
          { 'pointer-events-none opacity-50': pendingFilter })}
        style={{ transform: 'translateY(1px)' }}>
        Create New Filter
      </div>

      <button
        disabled={!canSave}
        onClick={onSave}
        className={classNames('px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-color-5951FF focus:outline-none',
          { 'pointer-events-none opacity-50': !canSave })}>
        Save
      </button>

      <button onClick={onOptions} className="ml-2 flex items-center focus:outline-none">
        <MoreVert htmlColor="#a0a8bb" />
      </button>
      <Popover
        arrow
        style={{ marginTop: 10, zIndex: 99999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="bottom"
        timeout={0}>
        <div className={classNames('py-1', { 'pointer-events-none': deletePopover.anchorEl })}>
          <div
            className={classNames(sharedClasses, 'text-color-09242f')}
            onClick={onDuplicate}>
            Duplicate
          </div>
          <div
            className={classNames(sharedClasses, 'text-color-09242f', { 'pointer-events-none opacity-50': !canRename })}
            onClick={onRename}>
            Rename
          </div>
          {canShare && (
            <div
              className={classNames(sharedClasses, 'text-color-09242f')}
              onClick={onShare}>
              Share
            </div>
          )}
          <div
            className={classNames(sharedClasses, 'text-color-09242f',
              { 'pointer-events-none opacity-50': isDefaultFilter })}
            onClick={onSetDefault}>
            Set as Default
          </div>
          {canSetAsOrgDefault && (
            <div
              className={classNames(sharedClasses, 'text-color-09242f',
                { 'pointer-events-none opacity-50': isOrgDefaultFilter })}
              onClick={onSetOrgDefault}>
              Set as Org Default
            </div>
          )}
          <div
            className={classNames(sharedClasses, 'text-color-09242f', { 'pointer-events-none opacity-50': !canSaveFilter })}
            onClick={onDiscardChanges}>
            Discard Changes
          </div>
          <div
            className={classNames(sharedClasses, 'text-color-fb6c6a', { 'pointer-events-none opacity-50': !canDelete })}
            onClick={onDelete}>
            Delete
          </div>
          <ActionPopover
            popover={deletePopover}
            text="Are you sure you want to delete this filter?"
            actionText="Yes, Delete"
            onCancel={onClose}
            onAction={onConfirmDelete}
            maxWidth={350}
            placement="bottom"
            zIndex={999999999} />
        </div>
      </Popover>
    </>
  )
}

export default FilterActions
