import { getAccessToken, getCurrencyCode, getTokenSilently, getIdTokenClaims } from '../lib/auth0'
import { useDebug } from '../context/debug'
import { useAuth } from '../context/auth'
import { useCallback, useEffect } from 'react'
import { ApiServiceClient, getApiServiceClientWithPrefix } from './clients'
import { captureException } from '../lib/sentry'
import { cloneDeep, debounce } from 'lodash'
import { useRoutes, getRouteTenantId } from '../context/routes'
import { grpcCodes } from './grpcCodes'
import { store } from '../index'

let metadata = {}
let impersonationHeaders = {}

const logTest = process.env.TEST_ENV === '1'
  ? (method, obj) => {
    if (!window.test) {
      window.test = {}
    }
    window.test[method] = obj
  }
  : undefined

function getHeaders({ headers = {}, canImpersonate }) {
  const accessToken = getAccessToken()
  const currencyCode = getCurrencyCode()
  return {
    ...headers,
    ...accessToken && { Authorization: `Bearer ${accessToken}` },
    ...canImpersonate && impersonationHeaders,
    ...currencyCode && { currencyCode },
  }
}

export function setImpersonationHeaders(h = {}) {
  impersonationHeaders = h
}

export function grpcInvoke({ headers, request, onError, onSuccess, onFetch, grpcMethod, grpcMethodName, data = {}, canImpersonate = true, debug, grpcPrefix, useApiRegion }) {
  const methodName = grpcMethodName || grpcMethod

  const routeTenantId = getRouteTenantId()
  const headersWithContext = (routeTenantId) ? { ...headers, tenantctxid: routeTenantId } : headers

  debug && console.log(`${methodName} request`, request.toObject(), getHeaders({ headers: headersWithContext, canImpersonate }))

  const prefix = grpcPrefix || store.getState().debug.grpcPrefix
  let serviceClient = ApiServiceClient(useApiRegion)

  if (prefix) {
    serviceClient = getApiServiceClientWithPrefix(prefix)
  }
  const serviceCall = serviceClient[grpcMethod].bind(serviceClient)

  const retryServiceCall = debounce(() => { // debounce the retry because grpc-web error handlers fire twice :(
    debug && console.log(`UNAUTHENTICATED: retry ${methodName}`)
    getTokenSilently({
      options: {
        ignoreCache: true
      },
      onSuccess: ({ accessToken }) => {
        getIdTokenClaims({
          onSuccess: () => {
            debug && console.log(
              `${methodName} retry-request`,
              request.toObject(),
              {
                ...getHeaders({ headers: headersWithContext, canImpersonate }),
                Authorization: `Bearer ${accessToken}`
              }
            )
            // eslint-disable-next-line no-use-before-define
            serviceCall(
              request,
              {
                ...getHeaders({ headers: headersWithContext, canImpersonate }),
                Authorization: `Bearer ${accessToken}`
              },
              (err, response) => callback(err, response, true)
            )
          }
        })
      },
      onError: (err) => {
        captureException(err, undefined, 'retryServiceCall -> getTokenSilently')
        // if we failed to get a new token then logout
        window.location = `${window.location.origin}/logout`
      },
    })
  }, 100)

  const callback = (err, response, isRetry) => {
    if (err) {
      if (!isRetry && err.code === grpcCodes.UNAUTHENTICATED) {
        // eslint-disable-next-line no-use-before-define
        retryServiceCall()
      } else if (!isRetry && err.code === grpcCodes.PERMISSION_DENIED) {
        // eslint-disable-next-line no-use-before-define
        retryServiceCall()
      } else {
        if (err.code !== grpcCodes.NOT_FOUND) {
          captureException(err, { request: request ? request.toObject() : undefined }, methodName)
        }
        debug && console.log(
          `${methodName} error`,
          err,
          {
            ...getHeaders({ headers: headersWithContext, canImpersonate }),
            Authorization: ''
          }
        )
        onError && onError(err)
      }
    } else {
      const obj = response.toObject()
      logTest?.(methodName, cloneDeep(obj))
      debug && console.log(`${methodName} success`, cloneDeep(obj))
      onSuccess && onSuccess(obj, data)
    }
  }

  try {
    onFetch && onFetch()
    return serviceCall(request, getHeaders({ headers: headersWithContext, canImpersonate }), callback)
  } catch (err) {
    captureException(err, { request: request ? request.toObject() : undefined }, methodName)
  }
}

export function useGrpcEffect(
  {
    headers,
    request,
    onError,
    onSuccess,
    onFetch,
    grpcMethod,
    grpcMethodName,
    canImpersonate,
    debug,
    useApiRegion = ''
  },
  dependencyList = []
) {
  const { debug: isDebug, grpcPrefix } = useDebug()
  const { isAuthenticated } = useAuth()
  const { routeTenantId } = useRoutes()
  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    const headersWithContext = (routeTenantId) ? { ...headers, tenantctxid: routeTenantId } : headers
    const call = grpcInvoke({
      headers: headersWithContext,
      request,
      onError,
      onSuccess,
      onFetch,
      grpcMethod,
      grpcMethodName,
      canImpersonate,
      debug: debug || isDebug,
      grpcPrefix,
      useApiRegion
    })
    call.on('status', (status) => { // callback for trailing metadata
      if (status.metadata) {
        metadata = {
          ...metadata,
          ...status.metadata,
        }
      }
    })
    return () => {
      call && call.cancel && call.cancel()
    }
  }, [isAuthenticated, ...dependencyList])
}

export function useGrpcCallback(
  {
    headers,
    onError,
    onSuccess,
    onFetch,
    grpcMethod,
    grpcMethodName,
    canImpersonate,
    debug,
    useApiRegion = ''
  },
  dependencyList = []
) {
  const { debug: isDebug, grpcPrefix } = useDebug()
  const { isAuthenticated } = useAuth()
  const { routeTenantId } = useRoutes()
  return useCallback((request, data) => {
    if (!isAuthenticated) {
      return
    }
    const headersWithContext = (routeTenantId) ? { ...headers, tenantctxid: routeTenantId } : headers
    const call = grpcInvoke({
      headers: headersWithContext,
      request,
      onError,
      onSuccess,
      onFetch,
      grpcMethod,
      grpcMethodName,
      data,
      canImpersonate,
      debug: debug || isDebug,
      grpcPrefix,
      useApiRegion
    })
    call.on('status', (status) => { // callback for trailing metadata
      if (status.metadata) {
        metadata = {
          ...metadata,
          ...status.metadata,
        }
      }
    })
    return call
  }, [isAuthenticated, ...dependencyList])
}
