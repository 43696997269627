import React from 'react'

const SectionHeader = ({ className, title, description }) => {
  return (
    <div {...className && { className }}>
      <h1>{title}</h1>
      {description && (
        <div className="mb-2 text-size-14px text-color-4e5d7e font-normal">{description}</div>
      )}
    </div>
  )
}

export default SectionHeader
