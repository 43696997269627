import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DealsList from '../deals/dealsList'
import { getOpportunitiesSnapshot } from '../../services/reportingService'
import { clearPipelineReportOpportunities } from '../../actions'
import AnimatedLoader from '../loaders/animatedLoader'
import { apiSuccessSelector } from '../../selectors'
import { getOpportunitiesSnapshotKey } from '../../actions/reportingService'
import { Waypoint } from 'react-waypoint'
import { formatDate } from '../../formatters'
import get from 'lodash/get'
import useDisableWheel from '../../hooks/useDisableWheel'

const oppsPerPage = 20

const PipelineReportOpportunitiesModal = (props) => {
  const { id, label, totalAmount, totalDeals, ids, tooltip, selectedGroup, inputParams, closeModal } = props

  const dispatch = useDispatch()

  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getOpportunitiesSnapshotKey))

  const pipelineReportOpportunities = useSelector((state) => state.pipelineReportOpportunities)
  const { opportunitiesList } = pipelineReportOpportunities

  const fetchOpps = useCallback((offset) => {
    let startBucket = selectedGroup?.id ?? ''
    if (startBucket === 'total') {
      startBucket = ''
    }
    const endBucket = id
    dispatch(getOpportunitiesSnapshot(ids, oppsPerPage, offset, inputParams, startBucket, endBucket))
  }, [])

  useEffect(() => {
    fetchOpps(0)
  }, [])

  const showAnimatedLoader = useMemo(() => {
    return opportunitiesList.length === 0 && !apiSuccess
  }, [apiSuccess, opportunitiesList])

  const showEndOfListLoader = useMemo(() => {
    return opportunitiesList.length < totalDeals
  }, [opportunitiesList, totalDeals])

  const handleWaypointEntered = useCallback(() => {
    fetchOpps(opportunitiesList.length)
  }, [opportunitiesList.length])

  const containerRef = useRef(null)
  const headerRef = useRef(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [windowSize, setWindowSize] = useState({ width: window.innerHeight, height: window.innerHeight })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerHeight, height: window.innerHeight })
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current)
        setContainerSize({
          width: computedStyle.width.replace('px', '') * 1,
          height: computedStyle.height.replace('px', '') * 1
        })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const closeModalInternal = useCallback(() => {
    dispatch(clearPipelineReportOpportunities())
    closeModal && closeModal()
  }, [closeModal])

  const pipelineReport = useSelector((state) => state.pipelineReport)
  const fieldHistoryHoverDates = useMemo(() => {
    return {
      startDate: formatDate(get(pipelineReport, 'input.startDatetime.seconds', 0), 'MMM D, YYYY'),
      endDate: formatDate(get(pipelineReport, 'input.endDatetime.seconds', 0), 'MMM D, YYYY'),
    }
  }, [pipelineReport])

  const buttonRef = useRef()
  useDisableWheel(buttonRef)

  return (
    <div ref={containerRef} className="static mx-auto bg-color-ffffff rounded overflow-hidden" style={{ maxWidth: '915px', maxHeight: windowSize.height - 80, marginTop: 40 }}>
      <div className="overflow-y-auto" style={{ maxWidth: '915px', maxHeight: windowSize.height - 80 }}>
        <div ref={headerRef} className="fixed bg-color-ffffff rounded pt-9 pb-6 pointer-events-none" style={{ width: containerSize.width - 20 }}>
          <div className="w-full flex justify-between">
            <div className="pl-10 text-color-151d49 text-size-24px">
              {label}
              {' '}
              (
              {totalDeals}
              )
            </div>
            <button ref={buttonRef} onClick={closeModalInternal} className="focus:outline-none pointer-events-auto">
              <FontAwesomeIcon icon="times" size="lg" className="mr-6 text-color-151d49" />
            </button>
          </div>
          <div className="px-10 text-color-4e5d7e">{tooltip}</div>
        </div>
        <div className="mt-32 px-10 pb-10">
          {showAnimatedLoader
            ? (
              <AnimatedLoader
                className="mt-40 mb-10"
                title="Loading Deals"
                subTitle="Please wait..."
                maxWidth={220} />
            )
            : (
              <>
                <DealsList
                  opportunities={opportunitiesList}
                  enableSorting={false}
                  enableFilters={false}
                  showEmptyList={false}
                  emptyStateSubHeader="There are no deals to show."
                  fieldHistoryHoverDates={fieldHistoryHoverDates}
                  handleItemClick={closeModalInternal} />
                {showEndOfListLoader && (
                  <>
                    <div className="flex justify-center my-4">
                      <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
                    </div>
                    <Waypoint onEnter={handleWaypointEntered} />
                  </>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  )
}

export default PipelineReportOpportunitiesModal
