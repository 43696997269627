import './scss/tailwind.scss'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers'
import App from './app'
import { DebugProvider } from './context/debug'
import { CacheProvider } from './context/cache'
import { AuthProvider } from './context/auth'
import { GroupsProvider } from './context/groups'
import { PermissionsProvider } from './context/permissions'
import { TenantInfoProvider } from './context/tenantInfo'
import { UserPrefsProvider } from './context/userPrefs'
import { LoggedInUserProvider } from './context/loggedInUser'
import { RoutesProvider } from './context/routes'
import { GMLContentModalProvider } from './gml/context/gmlContentModal'
import './lib/console'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-virtualized/styles.css'
import Snackbar from './components/snackbar'

const preloadedState = undefined
export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    thunk,
  ],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  enhancers: []
})

render(
  <DebugProvider>
    <CacheProvider>
      <RoutesProvider>
        <AuthProvider>
          <UserPrefsProvider>
            <PermissionsProvider>
              <TenantInfoProvider>
                <GroupsProvider>
                  <Provider store={store}>
                    <LoggedInUserProvider>
                      <Snackbar
                        maxSnack={5}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}>
                        <GMLContentModalProvider>
                          <App />
                        </GMLContentModalProvider>
                      </Snackbar>
                    </LoggedInUserProvider>
                  </Provider>
                </GroupsProvider>
              </TenantInfoProvider>
            </PermissionsProvider>
          </UserPrefsProvider>
        </AuthProvider>
      </RoutesProvider>
    </CacheProvider>
  </DebugProvider>,
  document.getElementById('root')
)

// if (module.hot) {
//   module.hot.accept()
// }

if (process.env.NODE_ENV === 'development') {
  // console.log(process.env.AUTH0_AUDIENCE)
  // console.log(process.env.AUTH0_DOMAIN)
  // console.log(process.env.AUTH0_CLIENT_ID)
  // console.log(process.env.GRPC_URL)
  // console.log(process.env.TENANT_MANAGER_URL)
}
