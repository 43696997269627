import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Icon, { iconType } from '../icon'
import NoteListItem from '../notes/noteListItem'
import RichTextEditor from '../editor/richTextEditor'
import { clearNotes, updateActiveNote, updateModal } from '../../actions'
import { saveNote, getNotesByAttachment, ResourceTypes, NoteStatuses, NoteVisibilities, deleteNote } from '../../services/noteService'
import { apiSuccessSelector } from '../../selectors'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import get from 'lodash/get'
import has from 'lodash/has'
import AnimatedLoader from '../loaders/animatedLoader'
import { getNotesByAttachmentKey, saveNoteKey } from '../../actions/noteService'
import { modalTypes } from '../modals/modals'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGroups } from '../../context/groups'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import classNames from 'classnames'

const defaultValue = [
  {
    type: 'title',
    children: [
      { text: '' },
    ],
  },
]

const RepCoaching = (props) => {
  const { userId: uId } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadNote,
      permissionNames.CanCreateNote,
      permissionNames.CanUpdateNote,
      permissionNames.CanDeleteNote,
      permissionNames.CanShareNote
    )
  }, [checkPermissions])

  const params = useParams()

  const userId = useMemo(() => {
    return uId || (params?.userId ?? '')
  }, [uId, params])

  const { findGroupById } = useGroups()
  const user = findGroupById(userId)
  const { name = '', firstName = '' } = user || {}

  const editorPadding = 24
  const [editorWidth, setEditorWidth] = useState(0)
  const [editorHeight, setEditorHeight] = useState(0)
  const containerRef = useRef({})
  const listRef = useRef({})
  const editorRef = useRef({})

  const notes = useSelector((state) => state.notes)
  const activeNote = useSelector((state) => state.activeNote)

  const dispatch = useDispatch()

  const [init, setInit] = useState(false)
  const [showLoader, setShowLoader] = useState(true)

  const [newNoteRequested, setNewNoteRequested] = useState(false)

  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getNotesByAttachmentKey))
  const saveNoteSuccess = useSelector((state) => apiSuccessSelector(state, saveNoteKey))

  const hasActiveNote = useMemo(() => {
    return has(activeNote, 'id')
  }, [activeNote])

  useEffect(() => {
    setShowLoader(!apiSuccess && !init)

    if (apiSuccess) {
      setInit(true)
    }
  }, [init, apiSuccess])

  useEffect(() => {
    dispatch(clearNotes())
    dispatch(getNotesByAttachment(userId, ResourceTypes.PERSON))

    return () => {
      dispatch(clearNotes())
    }
  }, [dispatch, userId])

  const [value, setValue] = useState()

  const onChange = useCallback((value) => {
    setValue(value)
  }, [])

  const save = useCallback((note) => {
    dispatch(saveNote(note))
  }, [dispatch])

  const debounceSaveNote = useMemo(() => {
    return debounce(save, 1000)
  }, [save])

  useEffect(() => {
    if (activeNote.id && value && document.activeElement.classList.contains('slate-editor')) {
      const newNoteRaw = JSON.stringify(value)
      if (newNoteRaw !== activeNote.raw) {
        const note = {
          ...activeNote,
          name: get(find(value, (el) => el.type === 'title'), 'children', []).map((t) => get(t, 'text', '')).join(' ') || 'Untitled',
          raw: newNoteRaw,
        }
        debounceSaveNote(note)
      }
    }
  }, [user, activeNote, value, debounceSaveNote])

  const onNewNoteClick = useCallback(() => {
    const titleText = moment().format('l')
    const note = {
      name: titleText,
      raw: JSON.stringify([
        {
          type: 'title',
          children: [
            { text: titleText },
          ],
        },
        {
          type: 'paragraph',
          children: [
            { text: '' },
          ],
        },
      ]),
      attachmentsList: [
        {
          resourceId: userId,
          type: ResourceTypes.PERSON,
          label: `@${name}`,
        }
      ],
      mentionsList: [],
      tagsList: ['1:1'],
      status: NoteStatuses.ACTIVE,
      visibility: NoteVisibilities.OWNERONLY,
    }
    setNewNoteRequested(true)
    dispatch(saveNote(note, userId, ResourceTypes.PERSON))
  }, [userId, name, dispatch])

  useEffect(() => {
    if (saveNoteSuccess) {
      setNewNoteRequested(false)
    }
  }, [saveNoteSuccess])

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && listRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const listRect = listRef.current.getBoundingClientRect()
        setEditorWidth(Math.max(containerRect.width - listRect.width - editorPadding, 0))
      }
      if (editorRef.current) {
        const editorRect = editorRef.current.getBoundingClientRect()
        setEditorHeight(window.innerHeight - editorRect.y)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const onClick = useCallback((note) => {
    dispatch(updateActiveNote(note))
  }, [dispatch])

  const onSend = useCallback((note) => {
    dispatch(updateModal({
      type: modalTypes.deliverNote,
      note,
      user,
    }))
  }, [dispatch, user])

  const onDelete = useCallback((note) => {
    dispatch(deleteNote(note, userId, ResourceTypes.PERSON))
  }, [dispatch, userId])

  return (
    <>

      {showLoader
        && (
          <div className="h-screen">
            <AnimatedLoader
              className="mt-16"
              title="Loading 1:1 Sessions"
              subTitle="Please wait..."
              maxWidth={300} />
          </div>
        )}

      {!showLoader && notes.length === 0 && (
        <div className="flex items-center mt-16">
          <div className="mx-auto text-center" style={{ maxWidth: 300 }}>
            <div className="flex justify-center">
              <div style={{ width: 50, height: 50 }}>
                {newNoteRequested
                  ? <FontAwesomeIcon icon="spinner" className="spinner mx-1 mt-2" size="2x" />
                  : (
                    <button
                      onClick={onNewNoteClick}
                      className={classNames('flex justify-center items-center rounded-full bg-gradient-green focus:outline-none',
                        { 'pointer-events-none cursor-default': !permissions.CanCreateNote })}
                      style={{ width: 50, height: 50 }}>
                      <Icon type={iconType.FONTAWESOME} iconName="plus" color="#ffffff" style={{ width: 25, height: 25 }} />
                    </button>
                  )}
              </div>
            </div>
            <div className="text-size-30px text-color-91959f font-bold mt-4 mb-1">No 1:1 Sessions</div>
            <div className="text-size-14px text-color-91959f font-weight-500">{`${firstName} hasn't had any coaching sessions yet.  Click the plus button to start one.`}</div>
          </div>
        </div>
      )}

      <div ref={containerRef} className="flex w-full h-full">

        <div ref={listRef} className="w-4/12">

          {!showLoader && notes.length > 0 && (
            <>

              <div className="flex justify-between items-center py-3 border-b border-color-e0e7ff">
                <div className="text-size-30px text-color-09242f font-bold">1:1 Sessions</div>
                {newNoteRequested
                  ? <FontAwesomeIcon icon="spinner" className="spinner mx-1" />
                  : (
                    <>
                      {permissions.CanCreateNote && (
                        <>
                          <button onClick={onNewNoteClick} className="flex justify-center items-center w-6 h-6 rounded-full bg-gradient-green focus:outline-none">
                            <Icon type={iconType.FONTAWESOME} iconName="plus" color="#ffffff" size="xs" />
                          </button>
                        </>
                      )}
                    </>
                  )}
              </div>

              {permissions.CanReadNote && (
                <>
                  {notes.map((note, index) => (
                    <NoteListItem
                      key={`NoteListItem-${userId}-${index}`}
                      note={note}
                      active={activeNote.id === note.id}
                      onSend={() => onSend(note)}
                      onClick={() => onClick(note)}
                      onDelete={() => onDelete(note)}
                      canRead={permissions.CanReadNote}
                      canDelete={permissions.CanDeleteNote}
                      canShare={permissions.CanShareNote} />
                  ))}
                </>
              )}

            </>
          )}

        </div>

        <div ref={editorRef} className="ml-8 pb-12" style={{ width: editorWidth, minHeight: editorHeight, overflowY: 'hidden' }}>

          {permissions.CanReadNote && !showLoader && notes.length > 0 && (
            <RichTextEditor
              defaultValue={defaultValue}
              value={get(activeNote, 'rawJson', [])}
              onChange={onChange}
              toolbarPosition="top"
              useTitleLayout={true}
              useMentions={true}
              readOnly={!hasActiveNote || newNoteRequested || !permissions.CanUpdateNote}
              statusText={get(activeNote, 'updatedAtLabel')} />
          )}

        </div>

      </div>

    </>
  )
}

export default RepCoaching
