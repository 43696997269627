import React, { useCallback } from 'react'

const FieldDebug = (props) => {
  const {
    debug = false,
    data,
  } = props

  const onClick = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    navigator.clipboard.writeText(JSON.stringify(data, null, 2))
    console.log('Copied to clipboard.')
  }, [data])

  let fieldDefinitionFormat
  if (data.fieldDefinition) {
    if (data.fieldDefinition.format) {
      fieldDefinitionFormat = data.fieldDefinition.format.split('::')[0]
    } else {
      fieldDefinitionFormat = data.fieldDefinition.toType ? `toType:${data.fieldDefinition.toType}` : 'Copy'
    }
  }

  return debug
    ? (
      <button onClick={onClick} className="bg-color-ff7ac5 text-size-10px text-color-ffffff rounded-full px-2 focus:outline-none" style={{ height: 16 }}>
        {fieldDefinitionFormat}
      </button>
    )
    : null
}

export default FieldDebug
