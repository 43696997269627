import React from 'react'
import logo from '../../assets/outreach-logo.png'
import laptop from '../../assets/laptop.png'

const LoginTemplate = (props) => {
  const { children } = props
  return (
    <div className="flex w-full h-screen">
      <div className="md:w-5/12 flex md:items-center">

        <div className="mt-56 md:mt-0 text-center">
          <div className="float-left absolute top-0 p-18">
            <div className="flex items-center justify-between">
              <div className="flex flex-row items-center flex-shrink-0">
                <img src={logo} style={{ width: 200 }} />
              </div>
            </div>
          </div>

          {children}

        </div>
      </div>

      <div className="md:w-7/12 md:bg-gradient-outreach-blue overflow-hidden relative">
        <img src={laptop} className="mx-20 absolute hidden md:block" style={{ top: '50%', transform: 'translate(25%, -50%) scale(1.5)', maxHeight: '400px' }} />
      </div>
    </div>
  )
}

export default LoginTemplate
