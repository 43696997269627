import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearSignals, setDock } from '../../actions'
import { useRoutes } from '../../context/routes'
import FieldDebug from './fieldDebug'
import { getSignalsByOpportunity } from '../../services/signalService'
import arrowLeft from '../../assets/arrow-left.png'
import arrowRight from '../../assets/arrow-right.png'
import classNames from 'classnames'

const SignalsDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
  } = props

  const { label } = fieldDefinition
  const { value } = field

  const { routes } = useRoutes()
  const dispatch = useDispatch()
  const history = useHistory()
  const signals = useSelector((state) => state.signalsByOpportunity)

  const opportunityId = useMemo(() => {
    return opportunity.id
  }, [opportunity])

  const signalsCount = useMemo(() => {
    return signals.length
  }, [signals])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure, signalsCount])

  useEffect(() => {
    if (value) {
      dispatch(clearSignals())
      dispatch(getSignalsByOpportunity(opportunityId, 0, 20))
    }

    return () => {
      if (value) {
        dispatch(clearSignals())
      }
    }
  }, [value])

  const onClick = useCallback(() => {
    dispatch(setDock({ dealDetail: { enabled: false } }))

    history.push(routes.deal.replace(':opportunityId', opportunityId))
  }, [opportunityId])

  const scrollViewRef = useRef()

  const [currentIndex, setCurrentIndex] = useState(0)

  const onPrevClick = useCallback((e) => {
    let newIndex = currentIndex - 1
    if (newIndex < 0) newIndex = 0
    setCurrentIndex(newIndex)
    scrollViewRef.current.scroll({ left: newIndex * (scrollViewRef.current.offsetWidth - 2) })
  }, [scrollViewRef, currentIndex])

  const onNextClick = useCallback((e) => {
    let newIndex = currentIndex + 1
    if (newIndex > signalsCount - 1) newIndex = signalsCount - 1
    setCurrentIndex(newIndex)
    scrollViewRef.current.scroll({ left: newIndex * (scrollViewRef.current.offsetWidth - 2) })
  }, [scrollViewRef, currentIndex, signalsCount])

  return (
    <div className="SignalsDetailItem my-4">
      <div className="flex items-center justify-between text-color-818e93 text-size-16px font-weight-700 truncate">
        <div className="flex items-center">
          <div
            style={{ width: 20, height: 20, marginRight: 5, transform: 'translateY(-2px)' }}
            className="inline-block text-center text-size-12px font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-color-5951FF">
            <div style={{ transform: 'translateY(-2px)' }}>{signalsCount}</div>
          </div>
          {label}
        </div>
        <div className="flex items-center">
          {value !== undefined && value !== 0
            && <a onClick={onClick} className="text-color-2e5bff text-size-12px font-weight-600 cursor-pointer focus:outline-none">View More</a>}
          <FieldDebug
            debug={debug}
            data={{ fieldDefinition, field }} />
        </div>
      </div>

      {signalsCount > 0
        && (
          <div className="relative">

            {signalsCount > 1 && (
              <div className="z-20 absolute flex justify-between w-full">
                <div className={classNames('ml-2 mt-6', { 'cursor-pointer': currentIndex > 0 }, { 'cursor-default opacity-25': currentIndex === 0 })} onClick={onPrevClick}>
                  <img src={arrowLeft} />
                </div>
                <div
                  className={
                    classNames(
                      'mr-2 mt-6',
                      { 'cursor-pointer': currentIndex < signalsCount - 1 },
                      { 'cursor-default opacity-25': currentIndex === signalsCount - 1 }
                    )
                  }
                  onClick={onNextClick}>
                  <img src={arrowRight} />
                </div>
              </div>
            )}

            <div
              ref={scrollViewRef}
              className="inline-flex max-w-full overflow-y-hidden no-scrollbar border border-color-c9ced0 rounded mt-2 overflow-visible"
              style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}>

              {signals.map((s, index) => (
                <div key={`signal-${s.id}-${index}`}
                  className={classNames('w-full flex items-center justify-between py-2',
                    { 'px-3': signalsCount === 1 },
                    { 'px-8': signalsCount > 1 })}>
                  <div className="w-full leading-tight">
                    <div className="text-color-09242f text-size-16px font-weight-600 whitespace-nowrap truncate">{s.definition.title}</div>
                    <div className="text-color-09242f text-size-14px font-normal whitespace-nowrap truncate">{s.definition.description}</div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        )}

    </div>
  )
}

export default SignalsDetailItem
