import { canopyFields, fieldRenderTypes } from '../fieldRenderers/constants'
import { filter, forOwn, has, orderBy, clone } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFieldDefinition, getFieldRenderType } from '../fieldRenderers/helpers'
import { hideAll } from 'tippy.js'
import { setSearchFilter, applySearchFilters, removePendingSearchFilters, removeAllSearchFilters } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { useGroups } from '../../context/groups'
import { useHistory } from 'react-router-dom'
import { useLocationData } from '../location/hooks'
import { useLoggedInUser } from '../../context/loggedInUser'
import { usePipelineFeature } from './hooks'
import classNames from 'classnames'
import DateRangeFilter from '../fieldRenderers/dateRangeFilter'
import iconFilter from '../../assets/filter-icon.png'
import iconFilterActive from '../../assets/filter-icon-active.png'
import MultiSelectFilterVirtualized from '../fieldRenderers/multiSelectFilterVirtualized'
import NumberRangeFilter from '../fieldRenderers/numberRangeFilter'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import Tippy from '@tippyjs/react'
import useRoles from '../../hooks/useRoles'
import { ReferenceFieldOptionsListProvider } from '../../context/referenceFieldOptionsList'
import MultiSelectFilterLazy from '../fieldRenderers/multiSelectFilterLazy'

export const OpenCategories = createContext(null)

const DealFilters = (props) => {
  const {
    showOwnerFilter = true,
    maxHeightOffset = 100,
    tipPlacement = 'bottom',
    debug,
  } = props

  const maxHeight = `calc(100vh - ${maxHeightOffset}px)`

  const dispatch = useDispatch()

  const { isManagerRole } = useRoles()

  const { flattened: teamFlattened } = useGroups()
  const { loggedInUser } = useLoggedInUser()

  const ownerOptions = useMemo(() => {
    const result = orderBy(teamFlattened, (o) => o.name).map((user) => ({
      value: user.id, label: user.name
    }))

    // Prepend current user to the ownerOptions array
    return [{ value: loggedInUser.id, label: `${loggedInUser.name} (You)` }, ...result]
  }, [loggedInUser.id, teamFlattened])

  const { primaryFields } = usePipelineFeature()

  const [search, setSearch] = useState('')
  const [categoriesOpened, setCategoriesOpened] = useState([])
  const [activeOnly, setActiveOnly] = useState(false)

  const fields = useMemo(() => {
    return [
      ...showOwnerFilter && isManagerRole ? [canopyFields._owner.key] : [],
      ...primaryFields,
    ]
  }, [primaryFields, showOwnerFilter, isManagerRole])

  const handleCloseFilter = useCallback(() => {
    setSearch('')
    setFilterIcon(iconFilter)
    setCategoriesOpened([])
    dispatch(removePendingSearchFilters())
  }, [iconFilter, removePendingSearchFilters])

  const objectDefinitions = useSelector((state) => state.objectDefinitions)
  const searchFilters = useSelector((state) => state.searchFilters)
  const { canApply } = searchFilters

  const onFilterChange = useCallback((filter) => {
    debug && console.log('onFilterChange', filter)
    dispatch(setSearchFilter({
      filter,
      pending: has(filter, 'pending') ? filter.pending : true,
      applied: has(filter, 'applied') ? filter.applied : false,
    }))
  }, [debug])

  const hasFilter = useCallback((key) => {
    const { pendingFilters, appliedFilters } = searchFilters
    return pendingFilters[key] || appliedFilters[key]
  }, [searchFilters])

  const clearFilters = useCallback(() => {
    dispatch(removeAllSearchFilters())
    setActiveOnly(false)
    setCategoriesOpened([])
  }, [])

  const onToggle = useCallback((isOpen, name) => {
    let opened = clone(categoriesOpened)
    if (isOpen) {
      opened = [name]
    } else {
      const i = opened.indexOf(name)
      opened.splice(i, 1)
    }
    setCategoriesOpened(opened)
  }, [categoriesOpened])

  const applySearch = useCallback((options) => {
    const s = search.trim().toLowerCase()
    const filteredItems = filter(options, (opt) => {
      return opt.label.toLowerCase().includes(s)
    })
    return filteredItems
  }, [search])

  const renderFilter = useCallback((fieldName, index) => {
    let component = <></>
    const searchTerm = search.trim().length > 0
    if (objectDefinitions.opportunity && objectDefinitions.opportunity.fieldsList) {
      if (fieldName.toLowerCase() === canopyFields._owner.key) {
        let options = ownerOptions
        let showOpen = false
        if (searchTerm) {
          options = applySearch(ownerOptions)
          showOpen = true
        }
        if (!options.length || (activeOnly && !hasFilter('owner.id'))) {
          component = (<></>)
        } else {
          component = (
            <MultiSelectFilterVirtualized
              maxHeight={250}
              open={showOpen || categoriesOpened.indexOf(canopyFields['owner.id']).label > -1 || activeOnly}
              onToggle={onToggle}
              fieldDefinition={canopyFields['owner.id']}
              options={options}
              onChange={onFilterChange} />
          )
        }
      } else if (fieldName.toLowerCase() === canopyFields._internalupdatedat.key) {
        if (searchTerm || (activeOnly && !hasFilter(canopyFields._internalupdatedat.key))) {
          component = (<></>)
        } else {
          component = (
            <DateRangeFilter
              open={categoriesOpened.indexOf(canopyFields._internalupdatedat).label > -1 || activeOnly}
              onToggle={onToggle}
              fieldDefinition={canopyFields._internalupdatedat}
              onChange={onFilterChange}
              isTimestamp={true} />
          )
        }
      } else {
        const fieldDefinition = getFieldDefinition(objectDefinitions.opportunity.fieldsList, fieldName)
        const renderType = getFieldRenderType(fieldDefinition)
        const fieldReference = fieldDefinition.referencesList?.[0]
        if (fieldReference) {
          const open = categoriesOpened.indexOf(fieldDefinition.label) > -1 || activeOnly
          component = (
            <ReferenceFieldOptionsListProvider
              limit={20}
              isEnabled={true}
              fieldReference={fieldReference}
              search={search}>
              <MultiSelectFilterLazy
                onToggle={onToggle}
                open={open}
                maxHeight={525}
                fieldDefinition={{
                  ...fieldDefinition,
                  options: {
                    ...fieldDefinition.options,
                    itemsList: []
                  }
                }}
                onChange={onFilterChange}
                fieldReference={fieldReference} />
            </ReferenceFieldOptionsListProvider>
          )
        } else {
          switch (renderType) {
            case fieldRenderTypes.DATE:
            case fieldRenderTypes.TIMESTAMP:
              if (searchTerm || (activeOnly && !hasFilter(fieldDefinition.key))) {
                component = (<></>)
              } else {
                component = (
                  <DateRangeFilter
                    open={categoriesOpened.indexOf(fieldDefinition.label) > -1 || activeOnly}
                    onToggle={onToggle}
                    fieldDefinition={fieldDefinition}
                    onChange={onFilterChange}
                    isTimestamp={renderType === fieldRenderTypes.TIMESTAMP} />
                )
              }
              break
            case fieldRenderTypes.NUMBER:
              if (searchTerm || (activeOnly && !hasFilter(fieldDefinition.key))) {
                component = (<></>)
              } else {
                component = (
                  <NumberRangeFilter
                    onToggle={onToggle}
                    open={activeOnly}
                    fieldDefinition={fieldDefinition}
                    onChange={onFilterChange} />
                )
              }
              break
            case fieldRenderTypes.CHECKBOX:
            case fieldRenderTypes.STRING:
            case fieldRenderTypes.SINGLE_SELECT:
            case fieldRenderTypes.CANOPY_STATUS:
            case fieldRenderTypes._STATUS:
              if (fieldDefinition.options && fieldDefinition.options.itemsList.length > 0) {
                let itemsList = fieldDefinition.options.itemsList
                let showOpen = false
                if (search.trim().length > 0) {
                  itemsList = applySearch(itemsList)
                  showOpen = true
                }
                if (!itemsList.length || (activeOnly && !hasFilter(fieldDefinition.key))) {
                  component = (<></>)
                } else {
                  const open = showOpen || categoriesOpened.indexOf(fieldDefinition.label) > -1 || activeOnly
                  const opts = ([fieldRenderTypes.CHECKBOX].includes(renderType))
                    ? fieldDefinition.options.itemsList.map((item) => ({ ...item, value: `${atob(item.value)}` }))
                    : undefined
                  component = (
                    <MultiSelectFilterVirtualized
                      onToggle={onToggle}
                      open={open}
                      maxHeight={525}
                      options={opts}
                      fieldDefinition={{
                        ...fieldDefinition,
                        options: {
                          ...fieldDefinition.options,
                          itemsList
                        }
                      }}
                      onChange={onFilterChange}
                      fieldReference={fieldReference} />
                  )
                }
              }
              break
            default:
          }
        }
      }
    }
    return (
      <div key={`Filter-${index}`}>
        {component}
      </div>
    )
  }, [
    fields,
    objectDefinitions,
    onFilterChange,
    ownerOptions,
    search,
    applySearch,
    categoriesOpened,
    hasFilter,
    activeOnly
  ])

  const renderFilters = useCallback(() => {
    const filterable = filter(fields, (fieldName) => fieldName !== 'ownerId')
      .map((fieldName) => {
        if (fieldName === 'canopy_status') {
          return '_status'
        } else {
          return fieldName
        }
      })
    return filterable.map((fieldName, index) => renderFilter(fieldName, index))
  }, [fields, renderFilter])

  const history = useHistory()
  const { location, hash } = useLocationData()

  const onApplyClickInternal = useCallback(() => {
    if (has(hash, 'skip') || has(hash, 'take')) {
      let route = location.pathname
      let i = 0
      const h = {
        ...hash,
      }
      delete h.skip
      delete h.take
      forOwn(h, (value, key) => {
        if (i === 0) {
          route += '#'
        } else {
          route += '&'
        }
        if (value) {
          route += `${key}=${value}`
        } else {
          route += key
        }
        i += 1
      })
      history.push(route)
    }

    dispatch(applySearchFilters())
    handleCloseFilter()
    hideAll()
  }, [location, hash, handleCloseFilter, hideAll])

  const [filterIcon, setFilterIcon] = useState(iconFilter)

  const handleSearchChange = useCallback((e) => {
    const searchText = e.target.value
    setSearch(searchText)
  }, [])

  const onClearClick = useCallback(() => {
    setSearch('')
  }, [])

  return (
    <Tippy
      placement={tipPlacement}
      interactive={true}
      trigger="click"
      offset={[0, 10]}
      duration={[0, 0]}
      onShow={() => setFilterIcon(iconFilterActive)}
      onHide={handleCloseFilter}
      content={(
        <div className="font-normal text-size-16px overflow-hidden rounded-lg" style={{ width: 350, maxHeight }}>
          <div className="overflow-y-auto rounded-lg" style={{ maxHeight }}>
            <div className="px-6 py-4 sticky top-0 bg-color-ffffff z-30">
              <div className="flex items-center justify-between">
                <div className="text-size-20px text-color-09242f font-bold">Filter</div>
                <button onClick={hideAll} className="focus:outline-none mt-1">
                  <FontAwesomeIcon icon="times" size="lg" className="text-color-151d49" />
                </button>
              </div>
            </div>

            <div className="pb-1 pl-3 pr-3 bg-color-ffffff" style={{ zIndex: 9999 }}>
              <div className="bg-color-ffffff" style={{ zIndex: 9999 }}>
                <div className="pt-3 relative">
                  <div className="absolute flex justify-between w-full pointer-events-none" style={{ marginTop: 14, marginLeft: 10 }}>
                    <FontAwesomeIcon icon="search" size="sm" className="text-color-a0a8bb" />
                    {search.length > 0
                      && (
                        <button
                          className="absolute text-color-a0a8bb hover:text-color-151d49 focus:outline-none pointer-events-auto bg-color-ffffff"
                          onClick={onClearClick}
                          style={{ marginTop: -5, marginRight: 40, right: 0 }}>
                          <FontAwesomeIcon icon="times-circle" size="sm" className="pointer-events-none" />
                        </button>
                      )}
                  </div>
                  <input
                    onChange={handleSearchChange}
                    value={search}
                    type="text"
                    autoFocus
                    placeholder="Search"
                    className="m-0 focus:outline-none border border-color-d6d9e6 rounded pl-8 pr-6 py-2"
                    style={{ minWidth: 326 }} />
                </div>
                <div className="flex justify-between p-2">
                  <button
                    className="text-color-2e5bff text-size-14px font-weight-500 cursor-pointer"
                    onClick={() => setActiveOnly(!activeOnly)}>
                    {`${activeOnly ? 'Hide' : 'Show'} Active Filters`}
                  </button>
                  <button
                    className="text-color-2e5bff text-size-14px font-weight-500 cursor-pointer"
                    onClick={clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>

            <div className="px-6">
              <OpenCategories.Provider value={{ categoriesOpened, activeOnly }}>
                {renderFilters()}
              </OpenCategories.Provider>
            </div>
            <div className="px-6 pb-4 sticky bottom-0 bg-color-ffffff">
              <div className="flex justify-center mt-3 pt-5 pb-2 border-t border-color-edeeee">
                <button
                  onClick={onApplyClickInternal}
                  className={classNames('px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-color-1d4dcf shadow-btn focus:outline-none',
                    { 'pointer-events-none opacity-25': !canApply })}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      theme="canopy">
      <span className="mt-2 mx-2 cursor-pointer">
        <img src={filterIcon} className="flex-shrink-0" />
      </span>
    </Tippy>
  )
}

export default DealFilters
