import React from 'react'
import ParameterRow from './parameterRow'
import AnimatedLoader from '../loaders/animatedLoader'
import { useFilterSourceOptions } from '../../context/filterSourceOptions'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'

const FilterEditor = (props) => {
  const { isFetching } = useFilterSourceOptions()
  const { parametersList } = useRealtimeFilterEditor()

  return (
    <>
      {isFetching
        ? (
          <AnimatedLoader
            className="my-16 self-center"
            title="Loading Filters"
            subTitle="Please wait..."
            maxWidth={400} />
        )
        : (
          <div className="parameters">
            {parametersList.map(({ available, user }, i) => (
              <ParameterRow
                key={`ParameterRow-${available?.key}-${i}`}
                index={i}
                user={user}
                available={available} />
            ))}
          </div>
        )}
    </>
  )
}

export default FilterEditor
