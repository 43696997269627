import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcCallback } from '../grpc'
import { useAuth } from './auth'
import { cloneDeep, filter, find, has, orderBy } from 'lodash'
import { toRunRealtimeQueriesRequest } from '../grpc/converters'
import { useCanvas } from './canvas'

const opportunityIdKey = 'custom.value.opportunityId'

const DealSignalsContext = React.createContext()

export function DealSignalsProvider({ opportunityId, children }) {
  const { actingTenantId, actingUserId } = useAuth()
  const { canvas, tenantStandardParams } = useCanvas()

  const [key, setKey] = useState(guid())
  const [dealSignals, setDealSignals] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const runRealtimeQueriesForDealSignals = useGrpcCallback({
    onSuccess: (obj) => {
      setIsFetching(false)
      const { responsesMap = [] } = obj
      const signals = filter(responsesMap.map((m) => {
        const response = m[1]
        response.name = find(canvas?.tileContainersList ?? [], (t) => t.tileKey === response.queryKey)?.tile?.name ?? ''
        return response
      }), (value) => value !== undefined)
      setDealSignals(signals)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'runRealtimeQueries',
    grpcMethodName: 'runRealtimeQueriesForDealSignals',
    debug: false
  }, [canvas])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const realtimeQueriesMap = useMemo(() => {
    if (!canvas) {
      return []
    }
    const canvasTilesList = cloneDeep(canvas?.canvasLayout?.canvasTilesList ?? [])
    const orderedTiles = orderBy(canvasTilesList, (t, i) => t?.layout?.rowIdx ?? i)
    const tileContainersList = canvas?.tileContainersList ?? []
    const orderedTileContainersList = filter(orderedTiles.map((tile) => {
      tile.tile = find(tileContainersList, (t) => tile.tileKey === t.tileKey)?.tile ?? undefined
      return tile
    }), (tile) => tile.tile !== undefined)
    const map = orderedTileContainersList.map((tile) => {
      const parametersList = tile?.tile?.realtimeView?.view?.query?.availableParameters?.parametersList ?? []
      const opportunityIdParam = find(parametersList, (p) => p.key === opportunityIdKey)
      if (has(opportunityIdParam, 'value.value')) {
        opportunityIdParam.value.value = opportunityId
      }
      return [
        tile.tileKey,
        {
          queryKey: tile?.tileKey,
          params: {
            parametersList
          },
          nlgMap: tile?.tile?.realtimeView?.view?.nlgMap ?? [],
          gml: tile?.tile?.realtimeView?.view?.view
        }
      ]
    })
    return map
  }, [canvas, opportunityId])

  useEffect(() => {
    if (key && canvas && opportunityId) {
      const request = toRunRealtimeQueriesRequest({
        tenantId: actingTenantId,
        personId: actingUserId,
        realtimeQueriesMap,
        ...tenantStandardParams && { tenantStandardParams }
      })
      runRealtimeQueriesForDealSignals(request)
    }
  }, [key, runRealtimeQueriesForDealSignals, canvas, opportunityId, realtimeQueriesMap])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      dealSignals,
      invalidate
    }
  }, [isFetching, dealSignals])

  return <DealSignalsContext.Provider value={contextValue}>{children}</DealSignalsContext.Provider>
}

export function useDealSignals() {
  const context = React.useContext(DealSignalsContext)
  if (context === undefined) {
    throw new Error('useDealSignals must be used within a DealSignalsProvider')
  }
  return context
}
