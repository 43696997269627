import { grpcInvoke } from '../grpc'
import { toGetTenantObjectRequest } from '../grpc/converters'
import {
  getIntegrationObjectDefinitionRequested,
  getIntegrationObjectDefinitionSuccess,
  getIntegrationObjectDefinitionFailed,
} from '../actions/integrationService'

export const getIntegrationObjectDefinition = (objectName) => {
  return async (dispatch, getState) => {
    if (getState().objectDefinitions[objectName]) {
      // we've already cached the object definition
      return
    }
    const request = toGetTenantObjectRequest({ objectName })
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getIntegrationObjectDefinitionRequested())
      },
      onSuccess: (obj) => {
        dispatch(getIntegrationObjectDefinitionSuccess(obj))
      },
      onError: (err) => {
        dispatch(getIntegrationObjectDefinitionFailed(err))
      },
      grpcMethod: 'getIntegrationObjectDefinition',
      debug: false,
    })
  }
}
