import { createReducer } from '@reduxjs/toolkit'
import { clearImpersonation, setImpersonation } from '../actions'

const initialState = { isImpersonatingUser: false }

export const impersonation = createReducer(initialState, {
  [clearImpersonation.type]: (state, action) => {
    delete state.tenant
    delete state.user
    delete state.isDemoMode
    state.isImpersonatingUser = false
    return state
  },
  [setImpersonation.type]: (state, action) => {
    state.tenant = action.payload.tenant
    state.user = action.payload.user
    state.isImpersonatingUser = true
    state.isDemoMode = action.payload.isDemoMode
    return state
  }
})
