import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { getFieldFromObject } from '../fieldRenderers/helpers'
import { canopyFields } from '../fieldRenderers/constants'

const SignalCountCell = (props) => {
  const {
    opportunity,
  } = props

  const signalCount = useMemo(() => {
    const field = getFieldFromObject(opportunity, canopyFields._signalCount.key)
    return field?.value ?? 0
  }, [opportunity])

  return (
    <div
      style={{ width: 28, height: 28, marginLeft: 18 }}
      className={classNames('inline-block text-center text-size-14px font-normal rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-color-5951FF',
        { invisible: signalCount === 0 })}>
      <div className="font-weight-700 text-size-14px">{signalCount}</div>
    </div>
  )
}

export default SignalCountCell
