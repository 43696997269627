import { grpcInvoke } from '../grpc'
import {
  getTenantsRequested,
  getTenantsSuccess,
  getTenantsFailed,
  getImpersonationUsersRequested,
  getImpersonationUsersSuccess,
  getImpersonationUsersFailed,
  getTenantOrganizationRequested,
  getTenantOrganizationSuccess,
  getTenantOrganizationFailed,
} from '../actions/tenantService'
import {
  toGetTenantOrganizationRequest,
  toGetTenantsRequest,
  toSearchPeopleRequest,
} from '../grpc/converters'

export const getTenants = ({ nameFilter, page = 1, pageSize = 10 }) => {
  return async (dispatch, getState) => {
    const request = toGetTenantsRequest({
      statusFilter: 'active,pending',
      nameFilter,
      page,
      pageSize,
    })
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getTenantsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getTenantsSuccess(obj))
      },
      onError: (err) => {
        dispatch(getTenantsFailed(err))
      },
      grpcMethod: 'getTenants',
      canImpersonate: false,
      debug: false,
    })
  }
}

export const getImpersonationUsers = ({ tenantId, search, page = 1, pageSize = 10, filtersList }) => {
  return async (dispatch, getState) => {
    const request = toSearchPeopleRequest({
      tenantId,
      search,
      page,
      pageSize,
      filtersList,
    })
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getImpersonationUsersRequested())
      },
      onSuccess: (obj) => {
        dispatch(getImpersonationUsersSuccess(obj))
      },
      onError: (err) => {
        dispatch(getImpersonationUsersFailed(err))
      },
      grpcMethod: 'searchPeople',
      grpcMethodName: 'getImpersonationUsers',
      canImpersonate: false,
      debug: false,
    })
  }
}

export const getTenantOrganization = () => {
  return async (dispatch, getState) => {
    const request = toGetTenantOrganizationRequest({})
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getTenantOrganizationRequested())
      },
      onSuccess: (obj) => {
        dispatch(getTenantOrganizationSuccess(obj))
      },
      onError: (err) => {
        dispatch(getTenantOrganizationFailed(err))
      },
      grpcMethod: 'getTenantOrganization',
      debug: false,
    })
  }
}
