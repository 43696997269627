import React, { useCallback } from 'react'
import { MoreVert } from '@getoutreach/react-icons'
import { usePopover } from '../../hooks/usePopover'
import Popover from '../popover'
import { useDebug } from '../../context/debug'
import RealtimeViewDebugMenuItem from '../realtimeView/realtimeViewDebugMenuItem'

const MetricMenu = () => {
  const { debug } = useDebug()
  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const onMenuClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  return !debug ? null : (
    <>
      <button onClick={onMenuClick} className="flex items-center focus:outline-none">
        <MoreVert htmlColor="#a0a8bb" />
      </button>
      <Popover
        arrow
        style={{ marginTop: 10, zIndex: 999999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="bottom"
        timeout={0}>
        <div className="flex flex-col p-2">
          <RealtimeViewDebugMenuItem closeMenu={onClose} />
        </div>
      </Popover>
    </>
  )
}

export default MetricMenu
