import { createReducer } from '@reduxjs/toolkit'
import { clearNoteSearchedOpportunities } from '../actions'
import { noteSearchOpportunitiesSuccess } from '../actions/opportunityService'

const initialState = []

export const noteSearchedOpportunities = createReducer(initialState, {
  [clearNoteSearchedOpportunities.type]: (state, action) => initialState,
  [noteSearchOpportunitiesSuccess.type]: (state, action) => action.payload,
})
