import PropTypes from 'prop-types/prop-types'
import { paddingPropTypes, strokePropTypes, marginPropTypes, offsetPropTypes, svgTextPropTypes } from '../propTypes/common'

export const FunnelChartConfig = {
  height: 300,
  padding: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20
  },
  bar: {
    topBottomWidthDelta: 100,
    spacing: 6,
    style: {
      strokeWidth: 6,
      strokeLinejoin: 'round',
    },
  },
  barLabel: {
    offset: {
      // offset the render time rect of the barLabel
      x: 0,
      y: 1,
      width: 60,
      height: 0
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#ffffff',
    },
  },
}

export const funnelChartConfigPropTypes = {
  height: PropTypes.number,
  padding: PropTypes.shape(paddingPropTypes),
  bar: PropTypes.shape({
    minWidth: PropTypes.number,
    topBottomWidthDelta: PropTypes.number,
    spacing: PropTypes.number,
    style: PropTypes.shape(strokePropTypes),
  }),
  barLabel: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
}
