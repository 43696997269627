import React, { useCallback, useMemo } from 'react'
import Modal from '../common/modal'
import { useRealtimeViewModal } from '../../context/realtimeViewModal'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const FilterModalContainer = (props) => {
  const {
    confirmationModal,
    onClose,
    children
  } = props

  const { isModalOpen } = useRealtimeViewModal()

  const { workingFilter, canSave: canSaveFilter } = useRealtimeFilterEditor()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageSignal
    )
  }, [checkPermissions])

  const isSharedFilter = useMemo(() => {
    return workingFilter?.userId === ''
  }, [workingFilter])

  const canSave = useMemo(() => {
    if (isSharedFilter) {
      return permissions.CanManageSignal && canSaveFilter
    } else {
      return canSaveFilter
    }
  }, [isSharedFilter, canSaveFilter, permissions])

  const onCloseInternal = useCallback(() => {
    if (canSave) {
      confirmationModal?.setOpen(true)
      return
    }
    onClose && onClose()
  }, [canSave, confirmationModal, onClose])

  return (
    <Modal
      maxWidth="lg"
      open={isModalOpen}
      handleClose={onCloseInternal}>
      {children}
    </Modal>
  )
}

export default FilterModalContainer
