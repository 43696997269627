import React from 'react'
import { Close } from '@material-ui/icons'
import GenericModal from './genericModal'
import GMLRenderer from '../renderer/gmlRenderer'

const ContentModal = (props) => {
  const {
    modal,
    tree,
    contentName,
    size = 'xl',
    title
  } = props

  const { open, handleClose } = modal

  return (
    <GenericModal
      maxWidth="lg"
      handleClose={handleClose}
      open={open}>
      <div className="w-full flex flex-col font-normal">
        <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
          <div className="text-size-22px text-color-ffffff font-bold">{title}</div>
          <button onClick={handleClose} className="focus:outline-none"><Close style={{ fill: '#ffffff' }} /></button>
        </div>
        <div className="w-full h-full px-10 py-10">
          <div className="p-10 bg-color-f9faff border border-color-edeeee rounded overflow-x-auto">
            <GMLRenderer
              tree={tree}
              debug={false}
              useContentType={false}
              contentName={contentName}
              channel="web"
              size={size}
            />
          </div>
        </div>
      </div>
    </GenericModal>
  )
}

export default ContentModal
