import React, { useCallback, useState, useMemo } from 'react'
import { cloneDeep, filter } from 'lodash'
import { getFieldRenderType } from '../fieldRenderers/helpers'
import { canonicalFieldKeys, canopyFields, fieldRenderTypes } from '../fieldRenderers/constants'
import BoolFieldDetailItem from '../fieldRenderers/boolFieldDetailItem'
import DateFieldDetailItem from '../fieldRenderers/dateFieldDetailItem'
import MultiSelectFieldDetailItem from '../fieldRenderers/multiSelectFieldDetailItem'
import NumberFieldDetailItem from '../fieldRenderers/numberFieldDetailItem'
import SingleSelectFieldDetailItem from '../fieldRenderers/singleSelectFieldDetailItem'
import StatusFieldDetailItem from '../fieldRenderers/statusFieldDetailItem'
import StringFieldDetailItem from '../fieldRenderers/stringFieldDetailItem'
import SignalsDetailItem from '../fieldRenderers/signalsDetailItem'
import StageNameFieldDetailItem from '../fieldRenderers/stageNameFieldDetailItem'
import UnknownFieldDetailItem from '../fieldRenderers/unknownFieldDetailItem'
import ReadonlyFieldDetailItem from '../fieldRenderers/readonlyFieldDetailItem'
import TimestampFieldDetailItem from '../fieldRenderers/timestampFieldDetailItem'
import LastModifiedFieldDetailItem from '../fieldRenderers/lastModifiedFieldDetailItem'
import useIsReadOnly from '../../hooks/useIsReadOnly'
import { Switch } from '@material-ui/core'
import { useDealDetail } from '../../hooks/useDealDetail'
import { useOpportunityDefinition } from '../../hooks/useOpportunityDefinition'
import classNames from 'classnames'

const DealOtherFields = (props) => {
  const {
    onFieldChanged,
    isUpdating = false,
    search,
    debug,
  } = props

  const { deal, lastModified, getField } = useDealDetail()

  const { opportunityDefinition, fieldsList } = useOpportunityDefinition()

  const { isReadOnly } = useIsReadOnly()

  const [hideWhenEmpty, setHideWhenEmpty] = useState(true)

  const renderedFields = useMemo(() => {
    if (opportunityDefinition && deal.fields) {
      const summaryFieldNames = [
        canopyFields._status.key,
        canonicalFieldKeys.nextstep,
        canopyFields._owner.key,
        canonicalFieldKeys.closedate,
        canonicalFieldKeys.amount,
        canonicalFieldKeys.stagename,
        canonicalFieldKeys.forecastcategoryname,
      ]

      let otherFields = filter(fieldsList, (f) => !summaryFieldNames.includes(f.key)).map((f, index) => {
        const fieldDefinition = cloneDeep(f)
        const field = getField(fieldDefinition.key)
        return {
          fieldDefinition,
          field,
        }
      })

      if (search.value) {
        otherFields = filter(otherFields, (f) => {
          const regex = new RegExp(search.escapedValue.toLowerCase())
          return f.fieldDefinition.label ? regex.test(f.fieldDefinition.label.toLowerCase()) : false
        })
      }
      return otherFields
    }
    return []
  }, [deal, opportunityDefinition, search.value, fieldsList, getField])

  const renderDetailItem = useCallback((fieldData, index) => {
    let component = <></>

    if (opportunityDefinition) {
      const { fieldDefinition, field } = fieldData

      const readonlyOverride = isReadOnly(opportunityDefinition, fieldDefinition)
      const fieldReference = fieldDefinition?.referencesList?.[0]

      if (!field) {
        if (fieldDefinition.key === 'lastmodifieddate') {
          component = (
            <LastModifiedFieldDetailItem
              debug={debug}
              hideWhenEmpty={hideWhenEmpty}
              readonlyOverride={readonlyOverride}
              fieldDefinition={fieldDefinition} />
          )
        } else {
          component = (
            <UnknownFieldDetailItem
              debug={debug}
              hideWhenEmpty={hideWhenEmpty}
              readonlyOverride={readonlyOverride}
              fieldDefinition={fieldDefinition}
              field={{}} />
          )
        }
      } else if (fieldReference) {
        component = (
          <SingleSelectFieldDetailItem
            debug={debug}
            hideWhenEmpty={hideWhenEmpty}
            readonlyOverride={readonlyOverride}
            opportunity={deal}
            fieldDefinition={fieldDefinition}
            field={field}
            onFieldChanged={onFieldChanged}
            fieldReference={fieldReference}
            lazyLoad={true} />
        )
      } else if (fieldDefinition) {
        if (fieldDefinition.key === canopyFields._internalupdatedat.key) {
          component = (
            <TimestampFieldDetailItem
              debug={debug}
              hideWhenEmpty={hideWhenEmpty}
              readonlyOverride={readonlyOverride}
              fieldDefinition={canopyFields._internalupdatedat}
              field={field} />
          )
        } else {
          const { value } = field
          const renderType = getFieldRenderType(fieldDefinition)
          switch (renderType) {
            case fieldRenderTypes.CHECKBOX:
              component = (
                <BoolFieldDetailItem
                  debug={debug}
                  readonlyOverride={readonlyOverride}
                  opportunity={deal}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  onFieldChanged={onFieldChanged} />
              )
              break
            case fieldRenderTypes.DATE:
              component = (
                <DateFieldDetailItem
                  debug={debug}
                  hideWhenEmpty={hideWhenEmpty}
                  readonlyOverride={readonlyOverride}
                  opportunity={deal}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  onFieldChanged={onFieldChanged} />
              )
              break
            case fieldRenderTypes.TIMESTAMP:
              component = (
                <TimestampFieldDetailItem
                  debug={debug}
                  readonlyOverride={readonlyOverride}
                  hideWhenEmpty={true}
                  opportunity={deal}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  onFieldChanged={onFieldChanged} />
              )
              break
            case fieldRenderTypes.NUMBER:
              component = (
                <NumberFieldDetailItem
                  debug={debug}
                  hideWhenEmpty={hideWhenEmpty}
                  readonlyOverride={readonlyOverride}
                  opportunity={deal}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  onFieldChanged={onFieldChanged} />
              )
              break
            case fieldRenderTypes.SINGLE_SELECT:
              if (fieldDefinition.key === 'stagename') {
                component = (
                  <StageNameFieldDetailItem
                    debug={debug}
                    hideWhenEmpty={hideWhenEmpty}
                    readonlyOverride={readonlyOverride}
                    opportunity={deal}
                    fieldDefinition={fieldDefinition}
                    field={field}
                    onFieldChanged={onFieldChanged}
                  />
                )
              } else {
                component = (
                  <SingleSelectFieldDetailItem
                    debug={debug}
                    hideWhenEmpty={hideWhenEmpty}
                    readonlyOverride={readonlyOverride}
                    opportunity={deal}
                    fieldDefinition={fieldDefinition}
                    field={field}
                    onFieldChanged={onFieldChanged} />
                )
              }
              break
            case fieldRenderTypes.MULTI_SELECT:
              component = (
                <MultiSelectFieldDetailItem
                  debug={debug}
                  readonlyOverride={readonlyOverride}
                  opportunity={deal}
                  hideWhenEmpty={hideWhenEmpty}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  value={value}
                  onFieldChanged={onFieldChanged} />
              )
              break
            case fieldRenderTypes.STRING:
              component = (
                <StringFieldDetailItem
                  debug={debug}
                  hideWhenEmpty={hideWhenEmpty}
                  readonlyOverride={readonlyOverride}
                  opportunity={deal}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  value={value}
                  onFieldChanged={onFieldChanged} />
              )
              break
            case fieldRenderTypes.CANOPY_STATUS:
              component = (
                <StatusFieldDetailItem
                  debug={debug}
                  hideWhenEmpty={hideWhenEmpty}
                  readonlyOverride={readonlyOverride}
                  fieldDefinition={fieldDefinition}
                  field={field} />
              )
              break
            case fieldRenderTypes.CANOPY_SIGNAL_COUNT:
              component = (
                <SignalsDetailItem
                  debug={debug}
                  readonlyOverride={readonlyOverride}
                  opportunity={deal}
                  fieldDefinition={fieldDefinition}
                  field={field} />
              )
              break
            case fieldRenderTypes.CANOPY_OWNER:
              component = (
                <ReadonlyFieldDetailItem
                  debug={debug}
                  hideWhenEmpty={hideWhenEmpty}
                  readonlyOverride={readonlyOverride}
                  fieldDefinition={fieldDefinition}
                  field={field}
                  path="fullName.value" />
              )
              break
            case fieldRenderTypes.UNKNOWN:
              component = (
                <UnknownFieldDetailItem
                  debug={debug}
                  readonlyOverride={readonlyOverride}
                  fieldDefinition={fieldDefinition}
                  field={field} />
              )
              break
            default:
          }
        }
      }
    }

    return (
      <div key={`field-${index}`} className={`field-${index} pr-2`}>
        {component}
      </div>
    )
  }, [debug, deal, opportunityDefinition, hideWhenEmpty, isReadOnly, onFieldChanged])

  return (
    <div className={classNames('pr-8', { 'animate-pulse-fast pointer-events-none': isUpdating })}>
      <div className="border-b border-color-d6d9e6 flex flex-row justify-between">
        <div className="text-size-14px text-color-818e93 tracking-wider font-normal py-2">
          {'Last Updated '}
          {lastModified}
        </div>

        <div>
          {'Hide empty fields: '}
          <Switch checked={hideWhenEmpty} onChange={(e, checked) => setHideWhenEmpty(checked)} />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="flex flex-col w-1/2">
          {renderedFields.map((fieldData, index) => {
            if (index % 2 === 0) {
              return renderDetailItem(fieldData, index)
            }
          })}
        </div>
        <div className="flex flex-col w-1/2">
          {renderedFields.map((fieldData, index) => {
            if (index % 2 !== 0) {
              return renderDetailItem(fieldData, index)
            }
          })}
        </div>
      </div>

    </div>
  )
}

export default DealOtherFields
