import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const FieldEmpty = (props) => {
  return (
    <div className="field text-color-c9ced0">
      Select
      <FontAwesomeIcon icon="chevron-down" color="#C9CED0" className="ml-4" />
    </div>
  )
}

export default FieldEmpty
