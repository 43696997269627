import {
  setHeader,
  removeHeader,
  clearImpersonation,
  setImpersonation,
  clearOpportunities,
  clearIntegrationObject,
} from '../actions'
import { getIntegrationObjectDefinition } from './integrationService'
import { getTenantOrganization } from './tenantService'

export const startImpersonating = (tenant, user, isDemoMode) => {
  return (dispatch) => {
    dispatch(clearOpportunities())
    dispatch(setImpersonation({ tenant, user, isDemoMode }))

    dispatch(setHeader({ name: 'ImpersonateTenantId', value: tenant.id }))
    dispatch(setHeader({ name: 'ImpersonateUserId', value: user.id }))

    if (isDemoMode) {
      dispatch(setHeader({ name: 'demo', value: '' }))
    } else {
      dispatch(removeHeader({ name: 'demo' }))
    }

    dispatch(getTenantOrganization())

    dispatch(clearIntegrationObject())
    dispatch(getIntegrationObjectDefinition('opportunity'))
  }
}

export const stopImpersonating = () => {
  return (dispatch) => {
    dispatch(removeHeader({ name: 'ImpersonateTenantId' }))
    dispatch(removeHeader({ name: 'ImpersonateUserId' }))
    dispatch(removeHeader({ name: 'demo' }))
    dispatch(clearImpersonation())
  }
}
