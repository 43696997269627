import { has } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getFeatureSettingsKey } from '../actions/settingsService'
import { featureNames } from '../constants/featureNames'
import { featureSettingSelector } from '../selectors'

export function useOutreachFeatures() {
  const apiActivity = useSelector((state) => state.apiActivity)
  const outreachSettings = useSelector((state) => featureSettingSelector(state, { key: featureNames.outreachSettings }))

  const hasFeatureSettings = useMemo(() => {
    return has(apiActivity, getFeatureSettingsKey) && apiActivity[getFeatureSettingsKey] === 'success'
  }, [apiActivity])

  const isDealHealthEnabled = useMemo(() => {
    return outreachSettings?.payload?.enableDealHealth ?? false
  }, [outreachSettings])

  const isSuccessPlansEnabled = useMemo(() => {
    return outreachSettings?.payload?.enableSuccessPlans ?? false
  }, [outreachSettings])

  const isKaiaRecordingsEnabled = useMemo(() => {
    return outreachSettings?.payload?.enableKaiaRecordings ?? false
  }, [outreachSettings])

  return {
    hasFeatureSettings,
    isDealHealthEnabled,
    isSuccessPlansEnabled,
    isKaiaRecordingsEnabled
  }
}
