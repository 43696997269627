import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useRoutes } from '../../context/routes'
import { featureSettingSelector, apiSuccessSelector, apiRequestedSelector } from '../../selectors'
import { getFeatureSettingsKey } from '../../actions/settingsService'
import { featureNames } from '../../constants/featureNames'
import { getPipelineReportOptions, getUserLastReportInput } from '../../services/reportingService'

const AnalyticsContainer = (props) => {
  const { children } = props

  const { routes } = useRoutes()

  const reportingFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.reporting }))
  const apiRequested = useSelector((state) => apiRequestedSelector(state, getFeatureSettingsKey))
  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getFeatureSettingsKey))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPipelineReportOptions({ canonicalOnly: false, useCache: true }))
    dispatch(getUserLastReportInput())
  }, [])

  if (apiRequested) {
    return <></>
  } else if (apiSuccess && reportingFeature && reportingFeature.payload.enabled) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return <Redirect to={routes.summary} />
  }
}

export default AnalyticsContainer
