import { useState, useEffect } from 'react'
import { subscribe } from '../eventBus'
import { eventTypes } from '../eventBus/eventTypes'

export const useHideToggle = (name, defaultHide) => {
  const [hideInternal, setHideInternal] = useState(defaultHide)

  useEffect(() => {
    if (!name) {
      return
    }
    const { unsubscribe } = subscribe(eventTypes.toggleHide, (args) => {
      if (args.names.includes(name)) {
        setHideInternal(!hideInternal)
      }
    })
    return () => unsubscribe()
  }, [hideInternal])

  return hideInternal
}
