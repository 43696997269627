import React, { useCallback, useMemo, useRef } from 'react'
import useRefSize from '../../hooks/useRefSize'
import { getTextWidth } from '../../gml/lib/text'
import { cloneDeep, filter, flatten, get, has, max, min } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { calloutNames } from './constants'
import { activeScenarioSelector } from '../../selectors'
import { setActiveScenarioKey } from '../../actions'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const HeatMapChart = (props) => {
  const dispatch = useDispatch()

  const { formatCurrency } = useFormatCurrency({ shortCurrency: true })

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)

  const hiddenScenarios = useSelector((state) => state.hiddenScenarios)

  const activeScenario = useSelector(activeScenarioSelector)

  const isActiveHidden = useMemo(() => {
    const { key } = activeScenario
    return hiddenScenarios[key] === true
  }, [activeScenario, hiddenScenarios])

  const otherScenarios = useMemo(() => {
    return filter(forecastSimulationWorkspace.scenariosList, (s) => s.key !== activeScenario.key && has(s, 'output'))
  }, [forecastSimulationWorkspace, activeScenario])

  const calloutValues = useMemo(() => {
    return get(activeScenario.output, 'calloutValues', {})
  }, [activeScenario])

  const svgContainerRef = useRef(null)
  const size = useRefSize(svgContainerRef)

  const lineWidth = 36
  const lineY = useMemo(() => {
    const { width, height } = size
    if (width > 0 && height > 0) {
      return (height / 2) - 10
    }
    return 0
  }, [size])

  const dataRange = useMemo(() => {
    const visibleScenarios = filter(forecastSimulationWorkspace.scenariosList, (s) => !hiddenScenarios[s.key])
    const allNumericValues = flatten(visibleScenarios.map((scenario) => get(scenario.output, 'numericValuesList', [])))
    const data = allNumericValues.map((item) => item.value)
    const minData = min(data) || 0
    const maxData = max(data) || 0
    return {
      min: minData,
      max: maxData,
      range: maxData - minData,
    }
  }, [forecastSimulationWorkspace, hiddenScenarios])

  const renderHeatMap = useCallback(() => {
    const { width, height } = size
    if (width > 0 && height > 0 && dataRange.range !== 0) {
      const fairValue = get(calloutValues, `[${calloutNames.fairValue}]`, { value: 0 })
      const bearCase = calloutValues[calloutNames.bearCase] || fairValue
      const bullCase = calloutValues[calloutNames.bullCase] || fairValue

      let greenOffset = (fairValue.value - bearCase.value) / (bullCase.value - bearCase.value)
      if (isNaN(greenOffset)) greenOffset = 0

      const segment1 = {
        x1: 0,
        x2: !bearCase ? 0 : ((bearCase.value - dataRange.min) / dataRange.range) * width,
        y1: lineY,
        y2: lineY,
      }
      const segment2 = {
        x1: segment1.x2,
        x2: !bullCase ? 0 : ((bullCase.value - dataRange.min) / dataRange.range) * width,
        y1: lineY,
        y2: lineY,
      }
      const segment3 = {
        x1: segment2.x2,
        x2: width,
        y1: lineY,
        y2: lineY,
      }

      const segments = [segment1, segment2, segment3]

      return (
        <>
          <defs>
            <linearGradient id="grad1" {...segments[0]} gradientUnits="userSpaceOnUse">
              <stop stopColor="#ff4947" offset="0" />
              <stop stopColor="#e7e136" offset="1" />
            </linearGradient>
            <linearGradient id="grad2" {...segments[1]} gradientUnits="userSpaceOnUse">
              <stop stopColor="#e7e136" offset="0" />
              <stop stopColor="#4de578" offset={greenOffset} />
              <stop stopColor="#e7e136" offset="1" />
            </linearGradient>
            <linearGradient id="grad3" {...segments[2]} gradientUnits="userSpaceOnUse">
              <stop stopColor="#e7e136" offset="0" />
              <stop stopColor="#ff4947" offset="1" />
            </linearGradient>
          </defs>
          {bearCase && <line {...segments[0]} strokeWidth={lineWidth} strokeLinecap="butt" stroke="url(#grad1)" />}
          <line {...segments[1]} strokeWidth={lineWidth} strokeLinecap="butt" stroke="url(#grad2)" />
          {bullCase && <line {...segments[2]} strokeWidth={lineWidth} strokeLinecap="butt" stroke="url(#grad3)" />}
        </>
      )
    }
  }, [size, dataRange, calloutValues])

  const renderLabels = useCallback(() => {
    const { width, height } = size
    if (width > 0 && height > 0 && dataRange.range !== 0) {
      const min = `${formatCurrency(dataRange.min)}`
      const max = `${formatCurrency(dataRange.max)}`
      const minAttributes = {
        x: 0,
        y: lineY + (lineWidth / 2) + 20,
        style: {
          textAnchor: 'start',
          fontWeight: 'normal',
          fontSize: 16,
          fontFamily: 'Proxima Nova',
          letterSpacing: 1.5,
          fill: '#a0a8bb',
        },
      }
      const maxAttributes = cloneDeep(minAttributes)
      maxAttributes.x = width
      maxAttributes.style.textAnchor = 'end'
      return (
        <>
          <text {...minAttributes}>{min}</text>
          <text {...maxAttributes}>{max}</text>
        </>
      )
    }
  }, [size, dataRange, formatCurrency])

  const pointsToPath = useCallback((points) => {
    return points.map((pt, index) => `${index > 0 ? 'L' : 'M'} ${pt.x} ${pt.y}`)
  }, [])

  const markerY = useMemo(() => {
    const { width, height } = size
    if (width > 0 && height > 0) {
      return {
        y1: lineY - (lineWidth / 2) - 10,
        y2: lineY + (lineWidth / 2) + 10,
      }
    }
    return {
      y1: 0,
      y2: 0,
    }
  }, [size])

  const onMarkerClicked = useCallback((key) => {
    dispatch(setActiveScenarioKey(key))
    window.analytics.track('scenarioPlanner.heatMap.scenarioClicked')
  }, [])

  const renderActiveScenarioMarker = useCallback((scenario) => {
    if (isActiveHidden) {
      return
    }

    const { width } = size
    const { key, label } = scenario

    const fairValue = get(calloutValues, `[${calloutNames.fairValue}]`, { value: 0 })
    const bearCase = calloutValues[calloutNames.bearCase] || fairValue
    const bullCase = calloutValues[calloutNames.bullCase] || fairValue
    const isAllSame = fairValue.value === bearCase.value && fairValue.value === bullCase.value

    let range = bearCase && bullCase ? `${formatCurrency(bearCase.value)} - ${formatCurrency(bullCase.value)}` : ''
    if (isAllSame) {
      range = formatCurrency(fairValue.value)
    }

    const x1 = !bearCase ? 0 : ((bearCase.value - dataRange.min) / dataRange.range) * width
    const x2 = !bullCase ? 0 : ((bullCase.value - dataRange.min) / dataRange.range) * width
    const inset = 8
    const activeMarker1 = [
      { x: x1 + inset, y: markerY.y1 },
      { x: x1, y: markerY.y1 },
      { x: x1, y: markerY.y2 },
      { x: x1 + inset, y: markerY.y2 },
    ]
    const activeMarker2 = [
      { x: x2 - inset, y: markerY.y1 },
      { x: x2, y: markerY.y1 },
      { x: x2, y: markerY.y2 },
      { x: x2 - inset, y: markerY.y2 },
    ]
    const textAttributes = {
      x: (!bearCase || !bullCase) ? 0 : ((((bearCase.value + ((bullCase.value - bearCase.value) / 2)) - dataRange.min)) / dataRange.range) * width,
      y: markerY.y2 + 26,
      style: {
        textAnchor: 'middle',
        fontWeight: 'normal',
        fontSize: 16,
        fontFamily: 'Proxima Nova',
        letterSpacing: 1.5,
        fill: '#09242f',
      },
    }

    const rangeAttributes = cloneDeep(textAttributes)
    rangeAttributes.style.fontSize = 18
    rangeAttributes.y += 20

    let pathAttributes = {}
    if (isAllSame) {
      const x = ((fairValue.value - dataRange.min) / dataRange.range) * width
      let textAnchor
      if (fairValue.value === dataRange.min) {
        textAnchor = 'start'
      } else {
        textAnchor = fairValue.value === dataRange.max ? 'end' : 'middle'
      }
      const marker = [
        { x, y: markerY.y1 - 4 },
        { x, y: markerY.y2 + 4 },
      ]
      pathAttributes = {
        d: pointsToPath(marker),
        strokeWidth: 4,
        strokeLinecap: 'butt',
        stroke: '#000000',
        fill: 'none',
      }
      textAttributes.x = x
      textAttributes.style.textAnchor = textAnchor
      rangeAttributes.x = x
      rangeAttributes.style.textAnchor = textAttributes.style.textAnchor
    }

    return (
      <React.Fragment key={`activeScenarioMarker-${key}`}>
        {isAllSame
          ? <path {...pathAttributes} />
          : (
            <>
              {bearCase && <path d={pointsToPath(activeMarker1)} strokeWidth={4} strokeLinecap="butt" stroke="#000000" fill="none" />}
              {bullCase && <path d={pointsToPath(activeMarker2)} strokeWidth={4} strokeLinecap="butt" stroke="#000000" fill="none" />}
            </>
          )}
        <text {...textAttributes}>{label}</text>
        <text {...rangeAttributes}>{range}</text>
      </React.Fragment>
    )
  }, [size, dataRange, calloutValues, isActiveHidden, formatCurrency])

  const renderScenarioMarker = useCallback((scenario) => {
    const { key, label, output = {}, isBaseline } = scenario
    const isHidden = hiddenScenarios[key] === true
    if (isHidden) {
      return
    }
    const { calloutValues = {} } = output
    const fairValue = get(calloutValues, `[${calloutNames.fairValue}]`, { value: 0 })
    const { width } = size
    let x = ((fairValue.value - dataRange.min) / dataRange.range) * width
    if (isNaN(x)) x = 0
    const marker = [
      { x, y: markerY.y1 - 4 },
      { x, y: markerY.y2 + 4 },
    ]
    const pathAttributes = {
      d: pointsToPath(marker),
      strokeWidth: 2,
      strokeLinecap: 'butt',
      stroke: '#000000',
      fill: 'none',
    }
    const hitAreaAttributes = {
      ...pathAttributes,
      strokeWidth: 10,
      opacity: 0,
    }
    if (isBaseline) {
      pathAttributes.strokeDasharray = '5 3'
    }
    const textAttributes = {
      x,
      y: markerY.y1 - 16,
      style: {
        textAnchor: 'middle',
        fontWeight: 'normal',
        fontSize: 16,
        fontFamily: 'Proxima Nova',
        letterSpacing: 1.5,
        fill: '#a0a8bb',
      },
    }
    const textWidth = getTextWidth(label, textAttributes.style.fontWeight, textAttributes.style.fontSize, textAttributes.style.fontFamily)
    if (textAttributes.x - (textWidth / 2) < 0) {
      textAttributes.style.textAnchor = 'start'
    } else if (textAttributes.x + (textWidth / 2) > width) {
      textAttributes.style.textAnchor = 'end'
    }
    return (
      <g key={`scenarioMarker-${key}`} className="group cursor-pointer" onClick={() => onMarkerClicked(key)}>
        <path {...pathAttributes} />
        <path {...hitAreaAttributes} />
        <text {...textAttributes} className="invisible group-hover:visible pointer-events-none">{label}</text>
      </g>
    )
  }, [size, dataRange, hiddenScenarios, onMarkerClicked])

  const renderMarkers = useCallback(() => {
    const { width, height } = size
    if (width > 0 && height > 0 && dataRange.range !== 0) {
      return (
        <>
          {otherScenarios.map((scenario) => renderScenarioMarker(scenario))}
          {renderActiveScenarioMarker(activeScenario)}
        </>
      )
    }
  }, [size, activeScenario, renderActiveScenarioMarker, renderScenarioMarker])

  return (
    <div ref={svgContainerRef} className="w-full" style={{ height: 180 }}>
      <svg width="100%" height="100%">
        {renderHeatMap()}
        {renderLabels()}
        {renderMarkers()}
      </svg>
    </div>
  )
}

export default HeatMapChart
