import React from 'react'
import classNames from 'classnames'

const Button = ({ text, icon, isCurrent, handleClick }) => {
  const classes = classNames([
    'mx-2 flex items-center px-4 py-3 focus:outline-none select-none',
    { 'bg-gradient-green rounded-full shadow': isCurrent },
    { 'pointer-events-none': isCurrent }
  ])
  const textStyle = isCurrent ? { color: '#ffffff' } : { color: '#a0a8bb' }
  return (
    <button onClick={handleClick} className={classes}>
      {icon && <span className="px-2">{icon}</span>}
      <span className="text-xl font-bold mr-2 ml-1" style={textStyle}>{text}</span>
    </button>
  )
}

export default Button
