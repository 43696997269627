import React, { useMemo } from 'react'
import Card from './card'
import HeatMapChart from './heatMapChart'
import LineChart from './lineChart'
import ScenarioSummary from './scenarioSummary'
import TextHeader from './textHeader'
import ScenarioInputs from './scenarioInputs'
import { useSelector } from 'react-redux'
import { activeScenarioSelector } from '../../selectors'
import { filter, find, get, has } from 'lodash'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const ScenarioPlannerWorkspace = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadScenarioPlanner
    )
  }, [checkPermissions])

  const { tenantId, groupId } = props

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)

  const activeScenario = useSelector(activeScenarioSelector)

  const overviewDescription = useMemo(() => {
    const descriptionMap = get(activeScenario, 'output.descriptionMapMap', [])
    const descArr = find(descriptionMap, (m) => m[0] === 'summary')
    let desc = descArr ? descArr[1] : ''
    desc = desc.replace(/{SCENARIO_NAME}/g, activeScenario.label)
    return desc
  }, [activeScenario])

  const resultDescription = useMemo(() => {
    const descriptionMap = get(activeScenario, 'output.descriptionMapMap', [])
    const descArr = find(descriptionMap, (m) => m[0] === 'result')
    let desc = descArr ? descArr[1] : ''
    desc = desc.replace(/{SCENARIO_NAME}/g, activeScenario.label)
    return desc
  }, [activeScenario])

  const otherScenarios = useMemo(() => {
    return filter(forecastSimulationWorkspace.scenariosList, (s) => s.key !== activeScenario.key && has(s, 'output'))
  }, [forecastSimulationWorkspace, activeScenario])

  return (
    <div className="flex w-full h-full">
      <div className="flex justify-between p-10 w-full h-full">
        <div className="w-1/5 h-full">

          <ScenarioInputs
            tenantId={tenantId}
            groupId={groupId} />

        </div>
        <div className="w-4/5 h-full last:pl-10">

          {permissions.CanReadScenarioPlanner && (
            <>
              <TextHeader
                header="Overview"
                description={overviewDescription} />
              <Card>
                <HeatMapChart />
              </Card>

              <TextHeader
                header="Results"
                description={resultDescription} />
              <Card>
                <LineChart />
                <ScenarioSummary
                  key={`ScenarioSummary-${activeScenario.key}`}
                  className="my-8"
                  scenario={activeScenario}
                  isActive={true} />
                {otherScenarios.length > 0 && <div className="dotted w-full h-1" />}
                {otherScenarios.map((scenario) => (
                  <ScenarioSummary
                    key={`ScenarioSummary-${scenario.key}`}
                    className="my-8"
                    scenario={scenario}
                    isActive={false} />
                ))}
              </Card>
            </>
          )}

        </div>
      </div>
    </div>
  )
}

export default ScenarioPlannerWorkspace
