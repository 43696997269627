import React, { useMemo, useState } from 'react'
import { useAuth } from '../../context/auth'
import Header from '../header/header'
import ScenarioPlannerContainer from './scenarioPlannerContainer'
import ScenarioPlannerStart from './scenarioPlannerStart'
import ScenarioPlannerWorkspace from './scenarioPlannerWorkspace'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const ScenarioPlanner = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanCreateScenarioPlanner
    )
  }, [checkPermissions])

  const [showWorkspace, setShowWorkspace] = useState(false)

  const { actingTenantId, actingUserId } = useAuth()

  const tenantId = actingTenantId
  const groupId = actingUserId

  return (
    <ScenarioPlannerContainer>

      <Header
        text="Scenario Planner"
        showSearch={false} />

      {permissions.CanCreateScenarioPlanner && (
        <>
          {showWorkspace
            ? (
              <ScenarioPlannerWorkspace
                tenantId={tenantId}
                groupId={groupId} />
            )
            : (
              <ScenarioPlannerStart
                tenantId={tenantId}
                groupId={groupId}
                setShowWorkspace={setShowWorkspace} />
            )}
        </>
      )}

    </ScenarioPlannerContainer>
  )
}

export default ScenarioPlanner
