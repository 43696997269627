import React, { useRef, useState, useMemo } from 'react'
import { animated, config, useChain, useSpring, useSprings, useTransition } from 'react-spring'
import classNames from 'classnames'

const AnimatedLoader = (props) => {
  const { className } = props

  const colors = {
    white: '#ffffff',
    green: '#7771FC',
  }

  const circleCount = 3
  const spaceBetween = 34
  const circleStrokeWidth = 3
  const circleDiameter = 60
  const circleRadius = circleDiameter / 2

  const circleAttributes = {
    cx: circleRadius,
    cy: circleRadius,
    rx: circleRadius,
    ry: circleRadius,
    fill: 'url(#greenGradient)',
    stroke: 'url(#greenGradient)',
    strokeWidth: circleStrokeWidth,
  }

  const circleNoFillAttributes = {
    ...circleAttributes,
    fill: 'none',
  }

  const width = ((circleDiameter + circleStrokeWidth) * circleCount) + (spaceBetween * (circleCount - 1))
  const height = circleDiameter + circleStrokeWidth
  const viewBox = `0, 0, ${width}, ${height}`

  const strokeStyle = { strokeLinecap: 'round', strokeLinejoin: 'round' }
  const separatorStyle = { ...strokeStyle, strokeWidth: 3, stroke: colors.green }
  const plusStyle = { ...strokeStyle, strokeWidth: 4 }
  const barStyle = { ...strokeStyle, strokeWidth: 6 }
  const arrowStyle = { ...strokeStyle, strokeWidth: 4, fill: 'none' }

  const text = [
    { title: 'Creating', subTitle: 'Setting up your simulation workspace' },
    { title: 'Calculating', subTitle: 'Gathering baseline input values and variations' },
    { title: 'Generating', subTitle: 'Running baseline simulation' },
  ]

  const [circle1Complete, setCircle1Complete] = useState(false)
  const circle1Ref = useRef()
  const circle1 = useSpring({
    ref: circle1Ref,
    immediate: circle1Complete,
    from: {
      title: text[0].title,
      subTitle: text[0].subTitle,
      opacity: 0,
      transform: 'rotate(0deg)',
      xy1: 30,
      xy2: 30,
      iconColor: colors.white,
    },
    to: async (next, cancel) => {
      await next({
        opacity: 1,
        transform: 'rotate(180deg)',
        xy1: 18,
        xy2: 42,
      })
      await next({ transform: 'rotate(0deg)' })
      await next({ transform: 'rotate(180deg)' })
    },
    config: config.wobbly,
    onRest: () => {
      setCircle1Complete(true)
    }
  })

  const [circle2Complete, setCircle2Complete] = useState(false)
  const circle2Ref = useRef()
  const circle2 = useSpring({
    ref: circle2Ref,
    immediate: circle2Complete,
    from: {
      title: text[1].title,
      subTitle: text[1].subTitle,
      circle1_opacity: 1,
      circle1_iconColor: colors.white,
      opacity: 0,
      iconColor: colors.green,
    },
    to: async (next, cancel) => {
      await next({
        circle1_opacity: 0,
        circle1_iconColor: colors.green,
        opacity: 1,
        iconColor: colors.white,
      })
    },
    onRest: () => {
      setCircle2Complete(true)
    }
  })

  const barSteps = [
    { value: 16 },
    { value: 36 },
    { value: 16 },
    { value: 36 },
    { value: 16 },
    { value: 36 },
    { value: 16 },
    { value: 36 },
    { value: 16 },
    { value: 36 },
    { value: 16 },
    { value: 36 },
  ]

  const barDuration = 275

  const bar1 = useSpring({
    from: { value: 36 },
    to: [
      ...barSteps,
      { value: 22 },
    ],
    config: { duration: barDuration },
  })

  const bar2 = useSpring({
    from: { value: 36 },
    to: [
      ...barSteps,
      { value: 16 },
    ],
    delay: 75,
    config: { duration: barDuration },
  })

  const [bar3Complete, setBar3Complete] = useState(false)
  const bar3 = useSpring({
    immediate: bar3Complete,
    from: { value: 36 },
    to: [
      ...barSteps,
      { value: 30 },
    ],
    delay: 100,
    config: { duration: barDuration },
    onRest: () => {
      setBar3Complete(true)
    }
  })

  const [circle3Complete, setCircle3Complete] = useState(false)
  const circle3Ref = useRef()
  const circle3 = useSpring({
    ref: circle3Ref,
    immediate: circle3Complete,
    from: {
      title: text[2].title,
      subTitle: text[2].subTitle,
      circle2_opacity: 1,
      circle2_iconColor: colors.white,
      opacity: 0,
      iconColor: colors.green,
    },
    to: async (next, cancel) => {
      await next({
        circle2_opacity: 0,
        circle2_iconColor: colors.green,
        opacity: 1,
        iconColor: colors.white,
      })
    },
    delay: barSteps.length * barDuration,
    onRest: () => {
      setCircle3Complete(true)
    }
  })

  const arrowDashOffset = 75
  const strokeDashOffset = useSpring({
    from: { value: arrowDashOffset },
    to: async (next, cancel) => {
      await next({ value: 0 })
      await next({ value: arrowDashOffset })
      await next({ value: 0 })
      await next({ value: arrowDashOffset })
      await next({ value: 0 })
      await next({ value: arrowDashOffset })
      await next({ value: 0 })
      await next({ value: arrowDashOffset })
      await next({ value: 0 })
      await next({ value: arrowDashOffset })
      await next({ value: 0 })
      await next({ value: arrowDashOffset })
      await next({ value: 0 })
    },
    config: { duration: 1000 },
  })

  useChain([circle1Ref, circle2Ref, circle3Ref])

  const circle1CompleteTitle = useMemo(() => {
    if (circle3Complete) {
      return (circle2Complete && bar3Complete) ? circle3.title : circle2.title
    } else {
      return circle1.title
    }
  }, [circle1Complete, circle2Complete, bar3Complete, circle3, circle2, circle1])

  const circle1CompleteSubtitle = useMemo(() => {
    if (circle3Complete) {
      return (circle2Complete && bar3Complete) ? circle3.subTitle : circle2.subTitle
    } else {
      return circle1.subTitle
    }
  }, [circle1Complete, circle2Complete, bar3Complete, circle3, circle2, circle1])

  return (
    <div className={classNames('w-full flex justify-center py-10', className)}>
      <div style={{ width, height }}>

        <svg viewBox={viewBox}>
          <defs>
            <linearGradient id="greenGradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
              <stop offset="0%" style={{ stopColor: '#9B97FF', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: colors.green, stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <line
            x1={circleDiameter + circleStrokeWidth}
            y1={height / 2}
            x2={(circleDiameter + circleStrokeWidth) + spaceBetween}
            y2={height / 2}
            style={separatorStyle} />
          <line
            x1={((circleDiameter + circleStrokeWidth) * 2) + spaceBetween}
            y1={height / 2}
            x2={((circleDiameter + circleStrokeWidth) * 2) + (spaceBetween * 2)}
            y2={height / 2}
            style={separatorStyle} />

          <g style={{ transform: `translate(${circleStrokeWidth / 2}px, ${circleStrokeWidth / 2}px)` }}>
            <animated.ellipse style={{ ...circleAttributes, opacity: circle1Complete ? circle2.circle1_opacity : circle1.opacity }} />
            <ellipse {...circleNoFillAttributes} />
            <animated.g style={{ transformOrigin: '30px 30px', transform: circle1.transform }}>
              <animated.line x1={30} x2={30} y1={circle1.xy1} y2={circle1.xy2} style={{ ...plusStyle, stroke: circle1Complete ? circle2.circle1_iconColor : circle1.iconColor }} />
              <animated.line x1={circle1.xy1} x2={circle1.xy2} y1={30} y2={30} style={{ ...plusStyle, stroke: circle1Complete ? circle2.circle1_iconColor : circle1.iconColor }} />
            </animated.g>
          </g>

          <g style={{ transform: `translate(${circleDiameter + circleStrokeWidth + spaceBetween}px, ${circleStrokeWidth / 2}px)` }}>
            <animated.ellipse style={{ ...circleAttributes, opacity: circle1Complete && circle2Complete && bar3Complete ? circle3.circle2_opacity : circle2.opacity }} />
            <ellipse {...circleNoFillAttributes} />
            <animated.line
              x1="18"
              y1={circle1Complete && circle2Complete && bar3Complete ? 22 : bar1.value}
              x2="18"
              y2="42"
              style={{ ...barStyle, stroke: circle1Complete && circle2Complete && bar3Complete ? circle3.circle2_iconColor : circle2.iconColor }} />
            <animated.line
              x1="30"
              y1={circle1Complete && circle2Complete && bar3Complete ? 16 : bar2.value}
              x2="30"
              y2="42"
              style={{ ...barStyle, stroke: circle1Complete && circle2Complete && bar3Complete ? circle3.circle2_iconColor : circle2.iconColor }} />
            <animated.line
              x1="42"
              y1={circle1Complete && circle2Complete && bar3Complete ? 30 : bar3.value}
              x2="42"
              y2="42"
              style={{ ...barStyle, stroke: circle1Complete && circle2Complete && bar3Complete ? circle3.circle2_iconColor : circle2.iconColor }} />
          </g>

          <g style={{ transform: `translate(${((circleDiameter + circleStrokeWidth) * 2) + (spaceBetween * 2)}px, ${circleStrokeWidth / 2}px)` }}>
            <animated.ellipse style={{ ...circleAttributes, opacity: circle3.opacity }} />
            <ellipse {...circleNoFillAttributes} />
            <animated.polyline
              style={{ ...arrowStyle, animationFillMode: 'forwards', stroke: circle3.iconColor, strokeDasharray: arrowDashOffset, strokeDashoffset: strokeDashOffset.value }}
              points="12,43 24,30 33,38 48,21 42,21 48,27 48,21" />
          </g>

        </svg>

        <div className="text-center py-6">
          <animated.div className="text-size-30px font-weight-600 text-color-09242f">{circle1CompleteTitle}</animated.div>
          <animated.div className="text-size-14px font-normal text-color-51636a">{circle1CompleteSubtitle}</animated.div>
        </div>

      </div>
    </div>
  )
}

export default AnimatedLoader
