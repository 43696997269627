import { createAction } from '@reduxjs/toolkit'

export const getPipelineReportKey = 'api/reporting/getPipelineReport'
export const getPipelineReportRequested = createAction(`${getPipelineReportKey}/requested`)
export const getPipelineReportSuccess = createAction(`${getPipelineReportKey}/success`)
export const getPipelineReportFailed = createAction(`${getPipelineReportKey}/failed`)

export const getPipelineReportOptionsKey = 'api/reporting/getPipelineReportOptions'
export const getPipelineReportOptionsRequested = createAction(`${getPipelineReportOptionsKey}/requested`)
export const getPipelineReportOptionsSuccess = createAction(`${getPipelineReportOptionsKey}/success`)
export const getPipelineReportOptionsFailed = createAction(`${getPipelineReportOptionsKey}/failed`)

export const getOpportunitiesSnapshotKey = 'api/reporting/getOpportunitiesSnapshot'
export const getOpportunitiesSnapshotRequested = createAction(`${getOpportunitiesSnapshotKey}/requested`)
export const getOpportunitiesSnapshotSuccess = createAction(`${getOpportunitiesSnapshotKey}/success`)
export const getOpportunitiesSnapshotFailed = createAction(`${getOpportunitiesSnapshotKey}/failed`)

export const getUserLastReportInputKey = 'api/reporting/getUserLastReportInput'
export const getUserLastReportInputRequested = createAction(`${getUserLastReportInputKey}/requested`)
export const getUserLastReportInputSuccess = createAction(`${getUserLastReportInputKey}/success`)
export const getUserLastReportInputFailed = createAction(`${getUserLastReportInputKey}/failed`)

export const getForecastSimulationsAvailableKey = 'api/reporting/getForecastSimulationsAvailable'
export const getForecastSimulationsAvailableRequested = createAction(`${getForecastSimulationsAvailableKey}/requested`)
export const getForecastSimulationsAvailableSuccess = createAction(`${getForecastSimulationsAvailableKey}/success`)
export const getForecastSimulationsAvailableFailed = createAction(`${getForecastSimulationsAvailableKey}/failed`)

export const getForecastSimulationLevelOptionsKey = 'api/reporting/getForecastSimulationLevelOptions'
export const getForecastSimulationLevelOptionsRequested = createAction(`${getForecastSimulationLevelOptionsKey}/requested`)
export const getForecastSimulationLevelOptionsSuccess = createAction(`${getForecastSimulationLevelOptionsKey}/success`)
export const getForecastSimulationLevelOptionsFailed = createAction(`${getForecastSimulationLevelOptionsKey}/failed`)

export const getForecastSimulationReportOptionsKey = 'api/reporting/getForecastSimulationReportOptions'
export const getForecastSimulationReportOptionsRequested = createAction(`${getForecastSimulationReportOptionsKey}/requested`)
export const getForecastSimulationReportOptionsSuccess = createAction(`${getForecastSimulationReportOptionsKey}/success`)
export const getForecastSimulationReportOptionsFailed = createAction(`${getForecastSimulationReportOptionsKey}/failed`)

export const runForecastSimulationKey = 'api/reporting/runForecastSimulation'
export const runForecastSimulationRequested = createAction(`${runForecastSimulationKey}/requested`)
export const runForecastSimulationSuccess = createAction(`${runForecastSimulationKey}/success`)
export const runForecastSimulationFailed = createAction(`${runForecastSimulationKey}/failed`)

export const getScenarioPlannerOptionsKey = 'api/reporting/getScenarioPlannerOptions'
export const getScenarioPlannerOptionsRequested = createAction(`${getScenarioPlannerOptionsKey}/requested`)
export const getScenarioPlannerOptionsSuccess = createAction(`${getScenarioPlannerOptionsKey}/success`)
export const getScenarioPlannerOptionsFailed = createAction(`${getScenarioPlannerOptionsKey}/failed`)
