import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Popover from '../popover'
import { useRoutes } from '../../context/routes'
import Avatar from '../avatar/avatar'
import { stopImpersonating as stopImpersonatingLegacy } from '../../services/impersonationService'
import { useAuth } from '../../context/auth'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useUserImageUrl } from '../../hooks/useUserImageUrl'
import { getClaims } from '../../lib/auth0'
import { claimNames } from '../../constants/claimNames'
import { some } from 'lodash'

const adminUrl = process.env.ADMIN_URL

const ImpersonatingUserMenu = (props) => {
  const { demo, impersonatingTenant, persistImpersonation } = useAuth()

  const { checkPermission } = usePermissions()

  const { routes, routeTenantId } = useRoutes()

  const dispatch = useDispatch()

  const { loggedInUser } = useLoggedInUser()

  const { userImageUrl } = useUserImageUrl(loggedInUser.photoUrl)

  const tenantName = useMemo(() => {
    return demo ? 'Outreach Commit' : ((impersonatingTenant && impersonatingTenant.name) || '')
  }, [demo, impersonatingTenant])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClose = useCallback((e) => {
    setAnchorEl(null)
  }, [])

  const handleStopImpersonating = useCallback((e) => {
    dispatch(stopImpersonatingLegacy())
    persistImpersonation({ user: undefined, tenant: undefined, demo: false })
    window.location.href = routes.tenants
  }, [])

  const avatarText = useMemo(() => {
    return loggedInUser && loggedInUser.name ? loggedInUser.name.toUpperCase().charAt(0) : ''
  }, [loggedInUser])

  const onAdminClick = useCallback(() => {
    const claims = getClaims()
    const tenantContexts = claims[claimNames.tenantContexts] || []
    let url = adminUrl
    if (routeTenantId && some(tenantContexts, (c) => c.tenant_id === routeTenantId)) {
      url += `/${routeTenantId}`
    }
    url += `/?nocache=${new Date().getTime()}`
    window.location = url
  }, [routeTenantId])

  return (
    <div className="flex-shrink-0">
      <button className="focus:outline-none" onClick={handleClick}>
        <div className="flex items-center rounded-full pr-6 bg-gradient-green" style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}>
          <Avatar className="flex w-8 h-8 bg-color-7771FC text-color-ffffff" imageUrl={userImageUrl} text={avatarText} isRound={true} />
          <div className="ml-2 text-left leading-none">
            <div className="text-size-12px text-color-ffffff whitespace-nowrap truncate">{loggedInUser.name || ' '}</div>
            <div className="text-size-10px text-color-ffffff whitespace-nowrap truncate">{tenantName}</div>
          </div>
        </div>
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}>
        <div className="py-2">

          {(checkPermission(permissionNames.CanAccessAdmin) || checkPermission(permissionNames.CanAccessInternalAdmin))
            && <a onClick={onAdminClick}><div className="px-6 py-2 font-weight-400 focus:outline-none cursor-pointer">Admin</div></a>}

          <button onClick={handleStopImpersonating} className="px-6 py-2 font-weight-400 focus:outline-none">Stop Impersonating</button>

        </div>
      </Popover>
    </div>
  )
}

export default ImpersonatingUserMenu
