import moment from 'moment'

export function daysSince(dateStr) {
  return moment().diff(moment(dateStr), 'days')
}

export function getMomentDaysAgo(days) {
  return moment().subtract(days, 'days').startOf('day')
}

export function getSeconds(momentObj) {
  return parseInt(momentObj.utc(true).format('X'))
}

export function secondsToFromNow(seconds) {
  return moment(seconds * 1000).fromNow()
}
