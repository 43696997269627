import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import DateRangePicker from '../../dateRangePicker/dateRangePicker'
import Popover from '../../popover'
import { reformatDate } from '../../../lib/dateFns'
import { QueryDateRangeType } from '../../../grpc/enums'
import { getDateRangeByQueryDateRangeType } from '../../dateRangePicker/helpers'

const FieldDateRange = (props) => {
  const {
    onChange,
    value,
    queryDateRangeType = QueryDateRangeType.QUERY_DATE_RANGE_TYPE__CUSTOM,
    tenantStandardParams = {}
  } = props

  const { fiscalYearStartMonth, salesPeriodLength } = tenantStandardParams

  const rangeSelection = useMemo(() => {
    if (value?.length > 1) {
      try {
        const defaultRange = {
          startDate: parseISO(value[0].value),
          endDate: parseISO(value[1].value)
        }
        const range = getDateRangeByQueryDateRangeType({ queryDateRangeType, defaultRange, fiscalYearStartMonth })
        return {
          ...range,
          key: 'selection',
          queryDateRangeType
        }
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    }
  }, [value, queryDateRangeType, fiscalYearStartMonth])

  const rangeLabel = useMemo(() => {
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_WEEK) {
      return 'This Week'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_WEEK) {
      return 'Next Week'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_WEEK) {
      return 'Last Week'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_MONTH) {
      return 'This Month'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_MONTH) {
      return 'Next Month'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_MONTH) {
      return 'Last Month'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_QUARTER) {
      return 'This Quarter'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_QUARTER) {
      return 'Next Quarter'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_QUARTER) {
      return 'Last Quarter'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__THIS_FISCAL_YEAR) {
      return 'This Fiscal Year'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__NEXT_FISCAL_YEAR) {
      return 'Next Fiscal Year'
    }
    if (queryDateRangeType === QueryDateRangeType.QUERY_DATE_RANGE_TYPE__LAST_FISCAL_YEAR) {
      return 'Last Fiscal Year'
    }
    if (value?.length > 1) {
      return `${reformatDate(value[0].value)} and ${reformatDate(value[1].value)}`
    }
    return 'Select'
  }, [value, queryDateRangeType])

  const [open, setOpen] = useState(false)

  const menu = useRef()

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onChangeInternal = useCallback((selection) => {
    const {
      startDate,
      endDate,
      queryDateRangeType = QueryDateRangeType.QUERY_DATE_RANGE_TYPE__CUSTOM
    } = selection
    onChange([
      format(startDate, 'yyyy-MM-dd'),
      format(endDate, 'yyyy-MM-dd')
    ].map((date) => ({
      value: date,
      label: date,
      queryDateRangeType
    })))
  }, [onChange])

  return (
    <div className="range-fields flex-1">
      <button
        className="field field-select"
        ref={menu}
        onClick={handleOpen}>
        <span>{rangeLabel}</span>
        <FontAwesomeIcon icon="chevron-down" color="#A6B2CF" className="ml-4" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={menu.current}
        onClose={handleClose}
        placement="bottom"
        timeout={0}>
        <div>
          <DateRangePicker
            rangeSelection={rangeSelection}
            onChange={onChangeInternal}
            fiscalYearStartMonth={fiscalYearStartMonth}
            salesPeriodLength={salesPeriodLength} />
        </div>
      </Popover>
    </div>
  )
}

export default FieldDateRange
