import React, { useMemo } from 'react'
import { dealStatus } from '../../constants/dealStatus'
import FieldDebug from './fieldDebug'

const StatusFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
  } = props

  const label = useMemo(() => {
    if (field?.value) {
      return dealStatus[field.value] ? dealStatus[field.value].label : ''
    }
    return ''
  }, [field])

  const color = useMemo(() => {
    if (field?.value) {
      return dealStatus[field.value] ? dealStatus[field.value].color : '#000'
    }
    return '#000'
  }, [field])

  return (
    <>
      <div className="StatusFieldRowItem flex flex-row items-center mr-2">
        {label ? (
          <>
            <div className="w-2 h-2 mr-1 rounded-full flex-shrink-0" style={{ backgroundColor: color }} />
            <div className="text-size-15px text-color-8097b1 font-normal flex-shrink-0">{label}</div>
          </>
        ) : ''}
      </div>
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </>
  )
}

export default StatusFieldRowItem
