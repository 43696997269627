import React, { useMemo } from 'react'
import ErrorBoundary from '../../../gml/controls/errorBoundary'
import GMLRenderer from '../../../gml/renderer/gmlRenderer'
import { getCurrentForecastCategoryCall, getPreviousForecastCategoryCall } from '../helpers'
import { resolvePipelineGML } from './pipelineParsedGML'
import { useForecasting } from '../../../context/forecasting'

const configOverride = {
  config: {
    pipelineChart: { padding: { top: 25, bottom: 0 } }
  }
}

const PipelineChart = (props) => {
  const {
    forecastCategories
  } = props

  const { forecast } = useForecasting()

  const data = useMemo(() => {
    const currentData = forecastCategories.map((fc) => {
      return {
        name: fc.name,
        amount: getCurrentForecastCategoryCall(fc.id, forecast).value,
      }
    })
    const previousData = forecastCategories.map((fc) => {
      return {
        name: fc.name,
        amount: getPreviousForecastCategoryCall(fc.id, forecast).value,
      }
    })
    return [
      previousData,
      currentData,
    ]
  }, [forecast, forecastCategories])

  const quota = useMemo(() => {
    return forecast?.current?.goalAmount ?? 0
  }, [forecast])

  return (
    <div className="pipeline-chart pt-2">
      <ErrorBoundary>
        <GMLRenderer
          tree={resolvePipelineGML(data, quota)}
          config={configOverride}
          animate={false} />
      </ErrorBoundary>
    </div>
  )
}

export default PipelineChart
