import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { find, forEach, get, has } from 'lodash'
import FieldSlider from './fieldSlider'

const SliderGroup = (props) => {
  const { paramsList, category, disabled, baselineCategory, handleSliderChange, categoriesOpen, handleCategoryToggled } = props
  const { key, fieldsList = [], maxValue = 0 } = category

  const [isOpen, setIsOpen] = useState(has(categoriesOpen, category.key) ? categoriesOpen[category.key] : true)

  useEffect(() => {
    let open = true
    if (has(categoriesOpen, category.key)) {
      open = categoriesOpen[category.key]
    }
    setIsOpen(open)
  }, [categoriesOpen])

  const toggleOpen = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setIsOpen(!isOpen)
    handleCategoryToggled(category, !isOpen)
  }, [isOpen, handleCategoryToggled])

  const label = useMemo(() => {
    return get(find(paramsList, (p) => p.key === key), 'label')
  }, [paramsList, category])

  const changeCount = useMemo(() => {
    let count = 0
    forEach(fieldsList, (field) => {
      const baselineField = find(baselineCategory.fieldsList, (f) => f.key === field.key)
      if (baselineField.value !== field.value) {
        count += 1
      }
    })
    return count
  }, [fieldsList, baselineCategory])

  return !label ? null : (
    <>

      <div className="flex items-center justify-between">
        <div className="text-color-51636a text-size-14px font-bold whitespace-nowrap truncate">
          <span>{label}</span>
        </div>
        <div className="flex items-center justify-between">
          {!isOpen && changeCount > 0
            && (
              <div
                className="flex flex-shrink-0 items-center justify-center bg-color-5951FF text-color-ffffff text-size-13px rounded-full mr-1"
                style={{ width: 20, height: 20 }}>
                {changeCount}
              </div>
            )}
          <button onClick={toggleOpen} className="py-1 pl-1 focus:outline-none">
            <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} color="#51636a" size="sm" />
          </button>
        </div>
      </div>

      {isOpen && (
        <>
          {fieldsList.map((field) => (
            <FieldSlider
              key={`Slider-${field.key}`}
              disabled={disabled}
              paramsList={paramsList}
              categoryKey={key}
              categoryMaxValue={maxValue}
              field={field}
              baselineField={find(baselineCategory.fieldsList, (f) => f.key === field.key)}
              handleSliderChange={handleSliderChange} />
          ))}
        </>
      )}

    </>
  )
}

export default SliderGroup
