import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { clearModal } from '../../actions'
import has from 'lodash/has'
import Modal from './modal'
import DealDetailModal from './dealDetailModal'
import ForecastDealOverrideModal from './forecastDealOverrideModal'
import ForecastRollupOverrideModal from './forecastRollupOverrideModal'
import PipelineReportOpportunitiesModal from './pipelineReportOpportunitiesModal'
import DealsModal from './dealsModal'
import DeliverNoteModal from './deliverNoteModal'

export const modalCallbackContainer = {}

export const modalTypes = {
  dealDetail: 'dealDetail',
  deals: 'deals',
  forecastDealOverride: 'forecastDealOverride',
  forecastRollupOverride: 'forecastRollupOverride',
  pipelineReportOpportunities: 'pipelineReportOpportunities',
  deliverNote: 'deliverNote',
}

const Modals = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const hash = queryString.parse(location.hash)

  const modal = useSelector((state) => state.modal)

  const [showModal, setShowModal] = useState(false)
  const [modalArgs, setModalArgs] = useState({})

  useEffect(() => {
    if (has(hash, 'modal')) {
      // set modal properties from the args in the url
      setShowModal(true)
      setModalArgs(JSON.parse(decodeURIComponent(hash.modal)))
    } else if (has(modal, 'type')) {
      // set modal properties from redux state
      setShowModal(true)
      setModalArgs(modal)
    } else {
      setShowModal(false)
      setModalArgs({})
    }
  }, [location, modal])

  const closeModal = useCallback(() => {
    if (has(hash, 'modal')) {
      history.push(location.pathname)
    } else {
      dispatch(clearModal())
    }
  }, [location, modal])

  const renderModalByType = () => {
    if (modalArgs.type === modalTypes.dealDetail) {
      return <DealDetailModal {...modalArgs} closeModal={closeModal} />
    } else if (modalArgs.type === modalTypes.forecastDealOverride) {
      return <ForecastDealOverrideModal {...modalArgs} closeModal={closeModal} />
    } else if (modalArgs.type === modalTypes.forecastRollupOverride) {
      return <ForecastRollupOverrideModal {...modalArgs} closeModal={closeModal} />
    } else if (modalArgs.type === modalTypes.pipelineReportOpportunities) {
      return <PipelineReportOpportunitiesModal {...modalArgs} closeModal={closeModal} />
    } else if (modalArgs.type === modalTypes.deals) {
      return <DealsModal {...modalArgs} closeModal={closeModal} />
    } else if (modalArgs.type === modalTypes.deliverNote) {
      return <DeliverNoteModal {...modalArgs} closeModal={closeModal} />
    }
  }

  return (
    <Modal isOpen={showModal}>
      {renderModalByType()}
    </Modal>
  )
}

export default Modals
