export const keyMetricsGroupFilterHeaders = [
  {
    key: 'filter|definition.name|startswith',
    value: 'metric_'
  },
  {
    key: 'filter|definition.name|nstartswith',
    value: 'metric_pipelineByStage|metric_pipelineByForecast'
  },
  {
    key: 'filter|definition.category|nin',
    value: 'summaryStatistics|dashboard'
  },
  {
    key: 'filter|definition.level|in',
    value: 'group|company'
  },
  {
    key: 'sort',
    value: 'result.score ASC'
  }
]

export const keyMetricsPersonFilterHeaders = [
  {
    key: 'filter|definition.name|nstartswith',
    value: 'metric_'
  },
  {
    key: 'filter|definition.name|nstartswith',
    value: 'metric_pipelineByStage|metric_pipelineByForecast'
  },
  {
    key: 'filter|definition.category|nin',
    value: 'summaryStatistics|dashboard'
  },
  {
    key: 'filter|definition.level|eq',
    value: 'person'
  },
  {
    key: 'sort',
    value: 'result.score ASC'
  }
]
