import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { secondsToDistanceToNow, secondsToTextDate } from '../../lib/dateFns'
import { apiRequestedSelector } from '../../selectors'
import { dealHealthDataKey } from '../../actions/searchService'
import DealHealthDetails from './dealHealthDetails'
import Tippy from '@tippyjs/react'

const DealHealthCell = (props) => {
  const {
    opportunity,
  } = props

  const { outreachOpportunities } = useSelector((state) => state.dealHealth)
  const isFetching = useSelector((state) => apiRequestedSelector(state, dealHealthDataKey))

  const currentOppDealHealth = useMemo(() => {
    return outreachOpportunities?.find((opp) => opp.crmId === opportunity.id)
  }, [opportunity.id, outreachOpportunities])

  const hasDealHealthData = useMemo(() => {
    return currentOppDealHealth !== undefined && currentOppDealHealth?.healthScore !== '' && currentOppDealHealth?.healthScore > 0
  }, [currentOppDealHealth])

  const tooltipContent = useMemo(() => {
    return (
      <div className="p-4 font-default">
        <div className="mb-2 text-size-16px font-weight-600">Health Insights</div>
        <div className="flex items-center mb-2">
          <div
            style={{ width: 32, height: 32, fontSize: 16 }}
            className={classNames(
              'inline-block text-center font-normal rounded-full leading-loose flex-shrink-0',
              { invisible: currentOppDealHealth === null },
              { 'bg-color-fdebec text-color-5e161C': currentOppDealHealth?.healthCategory === 'RED' },
              { 'bg-color-eaf7f2 text-color-154732': currentOppDealHealth?.healthCategory === 'GREEN' },
              { 'bg-color-fdf3e5 text-color-603900': currentOppDealHealth?.healthCategory === 'YELLOW' }
            )}>
            <div style={{ fontWeight: 700, transform: 'translateY(-2px)', marginTop: '2px' }}>{currentOppDealHealth?.healthScore}</div>
          </div>
          <div className="ml-2" style={{ fontWeight: 600, fontSize: 16 }}>
            {currentOppDealHealth?.healthCategory === 'RED' && (
              <div>At Risk</div>
            )}
            {currentOppDealHealth?.healthCategory === 'GREEN' && (
              <div>On Track</div>
            )}
            {currentOppDealHealth?.healthCategory === 'YELLOW' && (
              <div>Needs Review</div>
            )}
          </div>
        </div>
        <div className="bg-color-fafafb bg-opacity-50 rounded p-15px">
          <DealHealthDetails currentOppDealHealth={currentOppDealHealth} />
        </div>
        <div className="flex justify-end mt-3" style={{ fontWeight: 300, fontSize: 12 }}>
          <div>
            {secondsToTextDate(currentOppDealHealth?.updatedAt?.seconds || 0, false)}
          </div>
          <div className="ml-1">
            (
            {secondsToDistanceToNow(currentOppDealHealth?.updatedAt?.seconds || 0, false)}
            {' '}
            )
          </div>
        </div>
      </div>
    )
  }, [currentOppDealHealth])

  return (
    <>
      {isFetching ? (
        <div className="animate-pulse-fast pointer-events-none h-full">
          <div className="w-20 h-full bg-color-edeeee-60" />
        </div>
      )
        : (
          <>
            {hasDealHealthData ? (
              <Tippy
                appendTo={document.body}
                zIndex={999999999}
                offset={[10, 10]}
                duration={[0, 0]}
                maxWidth={300}
                content={tooltipContent}
                interactive={true}
                placement="right"
                theme="canopy">
                <div
                  style={{ width: 32, height: 32, marginLeft: 16 }}
                  onMouseOver={() => window.analytics.track('dealHealthHovered', {
                    opportunityName: opportunity?.fields?.name[0]?.value,
                    score: currentOppDealHealth?.healthScore
                  })}
                  className={classNames(
                    'inline-block text-center text-size-14px font-normal rounded-full leading-loose flex-shrink-0',
                    { invisible: currentOppDealHealth === null },
                    { 'bg-color-fdebec hover:bg-color-f27983 text-color-5e161C': currentOppDealHealth?.healthCategory === 'RED' },
                    { 'bg-color-eaf7f2 hover:bg-color-90D1b2 text-color-154732': currentOppDealHealth?.healthCategory === 'GREEN' },
                    { 'bg-color-fdf3e5 hover:bg-color-ffaf38 text-color-603900': currentOppDealHealth?.healthCategory === 'YELLOW' }
                  )}>
                  <div className="font-weight-700 mt-1" style={{ transform: 'translateY(-2px)' }}>{currentOppDealHealth?.healthScore}</div>
                </div>
              </Tippy>
            ) : (
              <div>{' '}</div>
            )}
          </>
        )}
    </>
  )
}

export default DealHealthCell
