import React, { useMemo } from 'react'
import Avatar from '../avatar/avatar'

const DealContactItem = (props) => {
  const { contact } = props

  const name = useMemo(() => {
    return contact?.name ?? ''
  }, [contact])

  const initials = useMemo(() => {
    return contact?.name?.charAt(0) ?? '-'
  }, [contact])

  const title = useMemo(() => {
    return contact?.title ?? ''
  }, [contact])

  const photoUrl = useMemo(() => {
    return contact?.photoUrl ?? ''
  }, [contact])

  return (
    <div className="flex items-center justify-between mt-4 first:mt-1">
      <div className="flex items-center mr-4">
        <Avatar className="w-10 h-10 mr-4 flex-shrink-0" imageUrl={photoUrl} text={initials} isRound={true} />
        <div className="mr-4 leading-tight">
          <div className="text-size-15px font-weight-300 text-color-2e384d">{name}</div>
          <div className="text-size-10px font-weight-300 text-color-4e5d7e">{title}</div>
        </div>
      </div>
    </div>
  )
}

export default DealContactItem
