import { roles } from '../../constants/roles'
import { useGroups } from '../../context/groups'
import { useHistory } from 'react-router-dom'
import { useLoggedInUser } from '../../context/loggedInUser'
import { useRoutes } from '../../context/routes'
import { useTextField } from '../../hooks/useTextField'
import GroupMenuItem from './groupMenuItem'
import React, { useCallback, useMemo } from 'react'
import SearchBox from '../search/searchBox'

const GroupMenu = (props) => {
  const {
    showReps,
    teamsSelectable,
    close
  } = props

  const history = useHistory()
  const { routes } = useRoutes()

  const {
    rootGroupId,
    findGroupById,
    subTrees = [],
    searchInTree } = useGroups()

  const { loggedInUser } = useLoggedInUser()

  const search = useTextField()

  const menu = useMemo(() => {
    if (!subTrees || !subTrees.length) {
      return {
        childrenList: [],
        membersList: []
      }
    }

    const _menu = subTrees[0]

    if (search.value.trim().length) {
      return searchInTree(search.value, _menu, showReps)
    }

    return _menu
  }, [subTrees, search.value, searchInTree])

  const baseOption = useMemo(() => {
    const { role, teamId } = loggedInUser

    if (role === roles.MANAGER && !showReps) {
      const uById = findGroupById(teamId)
      return {
        ...uById,
        name: `${uById?.name ?? ''} (Your Team)`.trim()
      }
    }
  }, [loggedInUser, teamsSelectable, rootGroupId, menu, showReps])

  const onBaseClick = useCallback(() => {
    if (teamsSelectable) {
      window.analytics.track('headerMenu.yourTeamClicked')
      const { groupViewerIdsList = [], teamId } = loggedInUser
      let summaryRoute = routes.summary
      if (teamId && groupViewerIdsList.length) {
        summaryRoute += `/${teamId}`
      }
      history.push(summaryRoute)
      close?.()
    }
  }, [teamsSelectable, close, baseOption])

  return (
    <div className="p-3">
      <div className="flex items-center justify-between px-2">
        <div className="font-bold text-size-18px text-color-09242f">{showReps ? 'Team Members' : 'Teams'}</div>
        {(loggedInUser.role === roles.MANAGER || loggedInUser.groupViewerIdsList?.length) && !showReps && (
          <button className="text-color-5951FF focus:outline-none" onClick={onBaseClick}>
            Your Team
          </button>
        )}
      </div>
      <SearchBox
        disableHint={true}
        className="m-2"
        placeholder="Search"
        value={search.value}
        onChange={search.onChange}
        onClear={search.reset}
        autoFocus={true} />
      <GroupMenuItem
        baseOptionId={baseOption?.id}
        teamsSelectable={teamsSelectable}
        showReps={showReps}
        initOpen={true}
        showOpen={!!search.value.trim().length}
        close={close}
        style={{
          width: '450px',
          maxHeight: '300px',
          overflow: 'auto'
        }}
        menu={menu} />
      {!menu.group && !menu.childrenList.length && !menu.membersList.length && (
        <div className="p-4 text-center">
          No
          {' '}
          {showReps ? 'member' : 'group'}
          {' '}
          name matches
        </div>
      )}
    </div>
  )
}

export default GroupMenu
