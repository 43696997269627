import { createReducer } from '@reduxjs/toolkit'
import { clearModalOpportunities } from '../actions'
import { getModalOpportunitiesSuccess } from '../actions/opportunityService'

const initialState = {
  opportunitiesList: [],
  totalCount: 0,
  nextPage: ''
}

export const modalOpportunities = createReducer(initialState, {
  [clearModalOpportunities.type]: (state, action) => initialState,
  [getModalOpportunitiesSuccess.type]: (state, action) => {
    const data = action.payload
    const { opportunitiesList, totalCount, nextPage } = data
    state.opportunitiesList.push(...opportunitiesList)
    state.totalCount = totalCount
    state.nextPage = nextPage
    return state
  }
})
