import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import BaseTable, { AutoResizer } from 'react-base-table'
import useKeyPressEffect from '../../hooks/useKeyPress'
import Paging from '../paging/paging'
import classNames from 'classnames'
import numeral from 'numeral'
import AnimatedLoader from '../loaders/animatedLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clearSearchedOpps } from '../../actions'
import { useDispatch } from 'react-redux'

const estimatedRowHeight = 100

const BaseTableExtended = (props) => {
  const {
    data,
    fixedColumns,
    loading = false,
    onSetPage,
    onColumnSort,
    records = {},
    scrollDisabled = true,
    sortBy,
    style,
    fixCols = true,
    useTotalRowsHeight,
    frozenData,
    rowHeight,
    changeSinceRenderer,
    ...rest
  } = props

  const dispatch = useDispatch()

  const baseTable = useRef()
  const columnManager = useRef()
  const viewWidth = useRef(0)
  const scrolledLeft = useRef(0)

  const { total = 0, skip = 0, take = 0 } = records

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1)
  const [enableNextBtn, setEnableNextBtn] = useState(false)
  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [leftFrozenOffset, setLeftFrozenOffset] = useState(0)
  const [tableWidth, setTableWidth] = useState(0)
  const [tableHeight, setTableHeight] = useState(0)
  const [leftScrollOffset, setLeftScrollOffset] = useState(0)
  const [topScrollOffset, setTopScrollOffset] = useState(0)

  useEffect(() => {
    return () => {
      dispatch(clearSearchedOpps())
    }
  }, [])

  useEffect(() => {
    if (baseTable.current) {
      columnManager.current = baseTable.current.getColumnManager()
    }
  }, [baseTable.current, loading])

  useEffect(() => {
    if (columnManager.current && columnManager.current._cached) {
      const {
        columnsWidth = 0,
        hasLeftFrozenColumns = false,
        leftFrozenColumnsWidth = 0 } = columnManager.current._cached

      if (hasLeftFrozenColumns) {
        setLeftFrozenOffset(leftFrozenColumnsWidth)
      }

      setTableWidth(columnsWidth)
    }
  }, [columnManager.current, loading])

  const scrollToRow = useCallback((dir) => {
    try {
      const newIndex = selectedRowIndex + dir
      if (selectedRowIndex > -1 && dir === -1) {
        baseTable.current.scrollToRow(newIndex)
        setSelectedRowIndex(newIndex)
      }

      if (selectedRowIndex < take && dir === 1) {
        setSelectedRowIndex(newIndex)
        baseTable.current.scrollToRow(newIndex)
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }, [baseTable.current, take, selectedRowIndex])

  const onArrowUp = useCallback(() => {
    scrollToRow(-1)
  }, [scrollToRow])

  const onArrowDown = useCallback(() => {
    scrollToRow(1)
  }, [scrollToRow])

  const onRowsRendered = useCallback(() => {
    if (baseTable.current) {
      const _height = document.querySelector('.BaseTable__table-main').clientHeight
      setTableHeight(baseTable.current.getTotalRowsHeight() - _height)
    }
  }, [baseTable.current])

  useEffect(() => {
    setLeftScrollOffset(tableWidth - viewWidth.current)
  }, [viewWidth.current, tableWidth])

  const nextPrevBtnStyle = {
    width: '48px',
    height: '48px',
    border: '1px solid rgb(227, 232, 250)',
    top: '50%',
    marginTop: '-30px',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
  }

  const sharedBtnClasses = 'flex items-center bg-color-ffffff bg-opacity-50 rounded-full cursor-pointer pointer-events-auto absolute'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, 'mr-3', 'right-0', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  const getTableHeight = useCallback((defaultHeight) => {
    let height
    if (useTotalRowsHeight && data && data.length > 0) {
      height = (data.length * 100) + 100
    } else {
      height = Math.max(defaultHeight, 200)
    }
    if (frozenData?.length) {
      height += frozenData.length * (rowHeight || estimatedRowHeight)
    }
    return height
  }, [data, useTotalRowsHeight, frozenData?.length, rowHeight])

  const page = useMemo(() => {
    const pageIndex = Math.floor(skip / take)
    return isNaN(pageIndex) ? 1 : pageIndex + 1
  }, [skip, take])

  const onTableScroll = useCallback(({ scrollLeft, scrollTop = 0 }) => {
    setTopScrollOffset(scrollTop)
    setEnablePrevBtn(scrollLeft > 5)
    setEnableNextBtn(scrollLeft <= leftScrollOffset - 5)
    scrolledLeft.current = scrollLeft
  }, [leftScrollOffset])

  useEffect(() => {
    onTableScroll({ scrollLeft: 0 })
  }, [leftScrollOffset])

  const onScrollLeft = useCallback(() => {
    if (baseTable.current) {
      const toScroll = Math.min(scrolledLeft.current, 400)
      baseTable.current.scrollToLeft(scrolledLeft.current - toScroll)
    }
  }, [scrolledLeft])

  const onScrollRight = useCallback(() => {
    if (baseTable.current) {
      const toScroll = Math.min(leftScrollOffset - scrolledLeft.current, 400)
      baseTable.current.scrollToLeft(scrolledLeft.current + toScroll)
    }
  }, [leftScrollOffset])

  const onScrollUp = useCallback(() => {
    if (baseTable.current) {
      const toScroll = Math.min(topScrollOffset, 250)
      baseTable.current.scrollToTop(topScrollOffset - toScroll)
    }
  }, [topScrollOffset])

  const onScrollDown = useCallback(() => {
    if (baseTable.current) {
      const toScroll = Math.min(tableHeight - topScrollOffset, 250)
      baseTable.current.scrollToTop(topScrollOffset + toScroll)
    }
  }, [topScrollOffset, tableHeight])

  useKeyPressEffect({
    targetKey: 'Enter',
    eventTarget: ['#searchBox'],
    onDown: () => {
      document.querySelector('#searchBox').blur()
      data[selectedRowIndex]?.enterAction?.()
    }
  }, [data, selectedRowIndex])

  useKeyPressEffect({
    targetKey: 'ArrowLeft',
    onDown: onScrollLeft
  }, [onScrollLeft])

  useKeyPressEffect({
    targetKey: 'ArrowRight',
    onDown: onScrollRight
  }, [onScrollRight])

  useKeyPressEffect({
    targetKey: 'ArrowUp',
    eventTarget: ['#searchBox'],
    onDown: onArrowUp
  }, [])

  useKeyPressEffect({
    targetKey: 'ArrowDown',
    eventTarget: ['#searchBox'],
    onDown: onArrowDown
  }, [])

  useKeyPressEffect({
    targetKey: 'PageDown',
    onDown: onScrollDown
  }, [onScrollDown])

  useKeyPressEffect({
    targetKey: 'PageUp',
    onDown: onScrollUp
  }, [onScrollUp])

  const rowClassRenderer = useCallback(({ rowIndex, rowData }) => {
    if (rowIndex === selectedRowIndex) {
      return 'selected-row'
    }
    return ''
  }, [selectedRowIndex])

  const pagingTextFormatter = useCallback((_start, _end, _total) => {
    return `${numeral(_start).format('0,0')} - ${numeral(_end).format('0,0')} of ${numeral(_total).format('0,0')}`
  }, [])

  return (
    <AutoResizer {...useTotalRowsHeight && { height: 1 }}>
      {({ width, height }) => {
        viewWidth.current = width - (style && style.marginLeft ? style.marginLeft : 0)

        return (
          <>
            <BaseTable
              ref={baseTable}
              className={classNames({ 'disable-scroll-y': useTotalRowsHeight }, { hidden: scrollDisabled })}
              width={viewWidth.current}
              overscanRowCount={20}
              estimatedRowHeight={estimatedRowHeight}
              maxHeight={getTableHeight(height)}
              fixed={fixCols}
              rowClassName={rowClassRenderer}
              onRowsRendered={onRowsRendered}
              resizable={true}
              columns={fixedColumns}
              sortBy={sortBy}
              ignoreFunctionInColumnCompare={false}
              overlayRenderer={() => {
                if (loading || !data.length) {
                  return <></>
                }
                return (
                  <>
                    <div
                      className={prevBtnClasses}
                      style={{ ...nextPrevBtnStyle, left: `${leftFrozenOffset}px` }}
                      onClick={onScrollLeft}
                      onKeyPress={({ key }) => key === 'Enter' && onScrollLeft()}
                      role="button"
                      tabIndex={0}>
                      <FontAwesomeIcon icon="chevron-left" size="2x" color="#c9ced0" className="mx-auto" />
                    </div>
                    <div
                      className={nextBtnClasses}
                      style={nextPrevBtnStyle}
                      onClick={onScrollRight}
                      onKeyPress={({ key }) => key === 'Enter' && onScrollRight()}
                      role="button"
                      tabIndex={0}>
                      <FontAwesomeIcon icon="chevron-right" size="2x" color="#c9ced0" className="mx-auto" />
                    </div>
                  </>
                )
              }}
              onColumnSort={onColumnSort}
              onScroll={onTableScroll}
              data={data}
              footerHeight={50}
              footerRenderer={(
                <Paging
                  className="flex w-full items-center justify-between py-3"
                  textClassName="text-color-151d49 text-size-15px font-weight-600"
                  navigationClassName="flex items-center pr-5"
                  total={total}
                  limit={take}
                  page={page}
                  onSetPage={onSetPage}
                  textFormatter={pagingTextFormatter}
                  changeSinceRenderer={changeSinceRenderer} />
              )}
              frozenData={frozenData}
              rowHeight={rowHeight}
              {...rest}
            />

            {loading && (
              <div
                className="w-full h-full flex justify-center items-center absolute top-0"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                <AnimatedLoader />
              </div>
            )}
          </>
        )
      }}
    </AutoResizer>
  )
}

export default BaseTableExtended
