import React, { useCallback, useMemo } from 'react'
import { useModal } from '../../../hooks/useModal'
import SubmitForecastModal from '../submitForecastModal'
import ForecastCalls from './forecastCalls'
import { usePermissions } from '../../../context/permissions'
import { permissionNames } from '../../../constants/permissionNames'
import { useForecasting } from '../../../context/forecasting'
import classNames from 'classnames'

const Footer = (props) => {
  const {
    forecastCategories,
  } = props

  const { checkPermissions } = usePermissions()
  const { isPastForecastPeriod } = useForecasting()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanCreateForecast
    )
  }, [checkPermissions])

  const modal = useModal()

  const onSubmit = useCallback(() => {
    modal.setOpen(true)
    window.analytics.track('forecasting.v2.dock.submitClicked')
  }, [modal])

  return (
    <>

      <div className="mb-3 border-t border-color-c9ced0" />

      <ForecastCalls
        forecastCategories={forecastCategories} />

      <div className="flex flex-col justify-center">
        {permissions.CanCreateForecast && (
          <button
            disabled={isPastForecastPeriod}
            onClick={onSubmit}
            className={classNames('mt-3 mx-auto px-8 py-3 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none',
              { 'opacity-25 cursor-default': isPastForecastPeriod })}>
            Submit
          </button>
        )}
        <div className="my-4 mx-auto text-size-10px text-color-b0bac9 font-weight-400 tracking-widest">
          {permissions.CanCreateForecast && (
            <>Submitting will take a snapshot of this forecast and submit it to your manager.</>
          )}
        </div>
      </div>

      <SubmitForecastModal
        modal={modal}
        isManager={false} />

    </>
  )
}

export default Footer
