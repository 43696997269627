import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useCanvasItem } from '../../context/canvasItem'
import { MoreVert } from '@getoutreach/react-icons'

const minWidth = 200
const maxWidth = 350

const FilterSelectGroupItem = (props) => {
  const {
    selectedOption,
    option,
    onChange,
    onOptions,
    popoverOption
  } = props

  const {
    defaultUserQueryParameter,
    orgDefaultUserQueryParameter
  } = useCanvasItem()

  const renderLabels = useCallback((id) => {
    return (
      <span className="text-color-818e93 pl-1">
        {id === defaultUserQueryParameter
          && (
            <span className="mr-1 last:mr-0">(default)</span>
          )}
        {id === orgDefaultUserQueryParameter
          && (
            <span className="mr-1 last:mr-0">(org default)</span>
          )}
      </span>
    )
  }, [defaultUserQueryParameter, orgDefaultUserQueryParameter])

  const onOptionsInternal = useCallback((e) => {
    onOptions && onOptions(e, option)
  }, [onOptions, option])

  return (
    <div
      onClick={() => onChange && onChange(option)}
      style={{ minWidth, maxWidth }}
      className={classNames('group flex items-center justify-between px-4 py-2',
        'text-size-14px text-left cursor-pointer focus:outline-none hover:bg-color-edeeee leading-tight',
        { 'font-weight-500 text-color-09242f ': option?.value !== selectedOption?.value },
        { 'font-bold text-color-000000': option?.value === selectedOption?.value })}>
      <span>
        {option?.label}
        {renderLabels(option?.value)}
      </span>
      <button
        onClick={onOptionsInternal}
        style={{ transform: 'translateX(13px)' }}
        className={classNames('px-1 flex items-center focus:outline-none group-hover:visible',
          { invisible: popoverOption?.value !== option?.value },
          { visible: popoverOption?.value === option?.value })}>
        <MoreVert htmlColor="#a0a8bb" />
      </button>
    </div>
  )
}

export default FilterSelectGroupItem
