import React from 'react'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import Icon from './icon'

const IconTip = (props) => {
  const { content, iconProps } = props

  return (
    <Tippy
      offset={[0, 10]}
      duration={[0, 0]}
      content={content}
      arrow={roundArrow}
      theme="canopy">
      <div><Icon {...iconProps} /></div>
    </Tippy>
  )
}

export default IconTip
