/* eslint-disable max-len */

import { controlTypes } from '../../../gml/renderer/gmlRenderer'

export const resolvePipelineGML = (data, quota) => {
  return {
    version: '1.0',
    includedList: [],
    dataList: [],
    functionsList: [
      {
        name: 'getData',
        raw: `() => ${JSON.stringify(data)}`
      },
      {
        name: 'getSeries',
        raw: '() => [functions.getData()[0].map(d => d.amount), functions.getData()[1].map(d => d.amount)]'
      },
      {
        name: 'getColors',
        raw: "() => [functions.getData()[0].map((d, index) => d.name === 'Won' ? '#4de578' : functions.getSeriesColor(index)), functions.getData()[1].map((d, index) => d.name === 'Won' ? '#4de578' : functions.getSeriesColor(index))]"
      },
      {
        name: 'getItem',
        raw: '(seriesIndex, dataIndex) => functions.getData()[seriesIndex][dataIndex]'
      },
      {
        name: 'formatAxisBarLabel',
        raw: '(data, seriesIndex) => seriesIndex === 0 ? "Last" : "Current"'
      },
      {
        name: 'formatAxisDataLabel',
        raw: "(data, len, index) => { return len > 4 ? (index === 0 || index === len - 1 ? functions.shortCurrency(data) : '') : functions.shortCurrency(data) }"
      }
    ],
    contentsList: [
      {
        name: '',
        childrenList: [
          {
            name: 'pipelineChartContainer',
            type: controlTypes.Container,
            attributesList: [
              {
                key: 'name',
                value: 'pipelineChartContainer',
                evaluate: false
              },
              {
                key: 'flow',
                value: 'vertical',
                evaluate: false
              },
              {
                key: 'styleNames',
                value: 'flex w-full',
                evaluate: false
              }
            ],
            childrenList: [
              {
                name: 'pipelineChart',
                type: controlTypes.BarChart,
                attributesList: [
                  {
                    key: 'name',
                    value: 'pipelineChart',
                    evaluate: false
                  },
                  {
                    key: 'series',
                    value: '{functions.getSeries()}',
                    evaluate: true
                  },
                  {
                    key: 'colors',
                    value: '{functions.getColors()}',
                    evaluate: true
                  },
                  {
                    key: 'orientation',
                    value: 'horizontal',
                    evaluate: false
                  },
                  {
                    key: 'stacked',
                    value: true,
                    evaluate: false
                  },
                  {
                    key: 'barLabels',
                    value: false,
                    evaluate: false
                  },
                  {
                    key: 'axisBarLabels',
                    value: true,
                    evaluate: false
                  },
                  {
                    key: 'axisBarLabelFormatter',
                    value: '{functions.formatAxisBarLabel}',
                    evaluate: true
                  },
                  {
                    key: 'axisDataLabels',
                    value: true,
                    evaluate: false
                  },
                  {
                    key: 'axisDataLabelFormatter',
                    value: '{functions.formatAxisDataLabel}',
                    evaluate: true
                  },
                  {
                    key: 'tooltips',
                    value: true,
                    evaluate: false
                  },
                  {
                    key: 'barWidth',
                    value: 18,
                    evaluate: false
                  },
                  {
                    key: 'barMargin',
                    value: { top: 6, bottom: 18 },
                    evaluate: false
                  }
                ],
                childrenList: [
                  {
                    name: '',
                    type: 23,
                    attributesList: [
                      {
                        key: 'interactive',
                        value: '{true}',
                        evaluate: true
                      }
                    ],
                    childrenList: [
                      {
                        name: '',
                        type: 1,
                        attributesList: [],
                        childrenList: [
                          {
                            name: '',
                            type: controlTypes.Container,
                            attributesList: [
                              {
                                key: 'styleNames',
                                value: 'flex flex-col leading-tight',
                                evaluate: false
                              }
                            ],
                            childrenList: [
                              {
                                name: '',
                                type: controlTypes.Text,
                                attributesList: [
                                  {
                                    key: 'styleNames',
                                    value: 'text-size-12px text-color-2e384d font-bold',
                                    evaluate: false
                                  }
                                ],
                                childrenList: [
                                  {
                                    name: '',
                                    type: controlTypes.Eval,
                                    attributesList: [],
                                    childrenList: [],
                                    text: '{functions.getItem(data._seriesIndex, data._dataIndex).name}'
                                  }
                                ],
                                text: ''
                              },
                              {
                                name: '',
                                type: 4,
                                attributesList: [
                                  {
                                    key: 'styleNames',
                                    value: 'text-size-12px text-color-2e384d font-bold',
                                    evaluate: false
                                  }
                                ],
                                childrenList: [
                                  {
                                    name: '',
                                    type: controlTypes.Eval,
                                    attributesList: [],
                                    childrenList: [],
                                    text: '{functions.currency(parseInt(functions.getItem(data._seriesIndex, data._dataIndex).amount))}'
                                    // "text": "{functions.currency(parseInt(functions.getItem(data._seriesIndex, data._dataIndex).amount)) + \" / \" + functions.plural('deal', functions.getItem(data._seriesIndex, data._dataIndex).dealCount, true)}"
                                  }
                                ],
                                text: ''
                              }
                            ],
                            text: ''
                          }
                        ],
                        text: ''
                      }
                    ],
                    text: ''
                  },
                  ...quota === undefined || quota === 0 ? [] : [
                    {
                      name: '',
                      type: controlTypes.Plugin,
                      attributesList: [
                        {
                          key: 'type',
                          value: 'lineMarker',
                          evaluate: false
                        },
                        {
                          key: 'config',
                          value: `{({ label: \`Quota \${functions.shortCurrency(${quota})}\`, value: ${quota} })}`,
                          evaluate: true
                        }
                      ],
                      childrenList: [],
                      text: ''
                    }
                  ]
                ],
                text: ''
              }
            ],
            text: ''
          }
        ],
        type: ''
      }
    ]
  }
}

/* eslint-enable max-len */
