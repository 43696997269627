import { useLayoutEffect } from 'react'

export default function useRefResize(ref, handleResize) {
  useLayoutEffect(() => {
    const currentRef = ref.current
    let req
    const resizeObserver = new ResizeObserver((entries) => {
      req = requestAnimationFrame(() => {
        const contentRect = entries?.[0]?.contentRect
        if (contentRect) {
          const { width, height } = contentRect
          handleResize({
            ref,
            width,
            height,
          })
        }
      })
    })
    if (currentRef) {
      resizeObserver.observe(currentRef)
    }
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef)
      } else {
        resizeObserver.disconnect()
      }
      req && cancelAnimationFrame(req)
    }
  }, [ref, handleResize])
}
