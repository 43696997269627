import React, { useState, useEffect, useCallback, useMemo } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Popover from '../popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import values from 'lodash/values'

const MultiSelectFilter = (props) => {
  const {
    enabled,
    menuItems = [],
    selectedItems = [],
    className,
    menuItemClassName,
    handleSelectionChanged,
    categoryClassName,
    display,
    maxHeight,
    width = 350,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [checked, setChecked] = useState({})
  const [search, setSearch] = useState('')
  const [categoriesOpened, setCategoriesOpened] = useState({})

  useEffect(() => {
    const checked = {}
    forEach(selectedItems, (item) => {
      if (item.key) {
        checked[item.key] = {
          ...item,
          checked: get(item, 'checked', null)
        }
      }
    })
    setChecked(checked)
  }, [selectedItems])

  function handleClick(event) {
    if (!enabled) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setCategoriesOpened({})
    setSearch('')
    setAnchorEl(null)
  }

  const handleSearchChange = useCallback((e) => {
    const searchText = e.target.value
    setSearch(searchText)
  }, [])

  const onClearClick = useCallback(() => {
    setCategoriesOpened({})
    setSearch('')
  }, [])

  const onApplyClick = useCallback(() => {
    const selected = filter(values(checked), (i) => i.checked)
    handleSelectionChanged && handleSelectionChanged(selected)
    handleClose()
  }, [checked])

  const onCategoryClick = useCallback((menuItem) => {
    setCategoriesOpened({
      ...categoriesOpened,
      [menuItem.category]: !categoriesOpened[menuItem.category]
    })
  }, [categoriesOpened])

  const filteredMenuItems = useMemo(() => {
    if (search.trim().length > 0) {
      const filteredItems = filter(menuItems, (m) => m.name.toLowerCase().startsWith(search.trim().toLowerCase()))
      const opened = {}
      forEach(filteredItems, (i) => {
        if (i.category) {
          opened[i.category] = true
        }
      })
      setCategoriesOpened(opened)
      return filteredItems
    }
    return menuItems
  }, [search, menuItems])

  const handleToggleChange = useCallback((e, newValue, menuItem) => {
    setChecked({
      ...checked,
      [menuItem.key]: {
        ...menuItem,
        checked: newValue
      }
    })
  }, [checked])

  const renderMenu = useCallback(() => {
    const categoriesRendered = {}

    const renderCategoryHeader = (menuItem, renderCategory) => {
      const isCategoryOpen = get(categoriesOpened, menuItem.category, false)
      return (
        <>
          {menuItem.category && renderCategory
            && (
              <div onClick={() => onCategoryClick(menuItem)} className={classNames(categoryClassName, 'cursor-pointer')}>
                <span>{menuItem.category}</span>
                <FontAwesomeIcon icon={isCategoryOpen ? 'chevron-up' : 'chevron-down'} color="#151d49" className="mt-1" />
              </div>
            )}
        </>
      )
    }

    return (
      <>

        <div className="pb-1 pl-3 pr-3 bg-color-ffffff" style={{ zIndex: 9999 }}>
          <div className="fixed bg-color-ffffff" style={{ zIndex: 9999 }}>
            <div className="pt-3">
              <div className="absolute flex justify-between w-full pointer-events-none" style={{ marginTop: 14, marginLeft: 10 }}>
                <FontAwesomeIcon icon="search" size="sm" className="text-color-a0a8bb" />
                {search.length > 0
                  && (
                    <button
                      className="absolute text-color-a0a8bb hover:text-color-151d49 focus:outline-none pointer-events-auto bg-color-ffffff"
                      onClick={onClearClick}
                      style={{ marginTop: -5, marginRight: 20, right: 0 }}>
                      <FontAwesomeIcon icon="times-circle" size="sm" className="pointer-events-none" />
                    </button>
                  )}
              </div>
              <input
                onChange={handleSearchChange}
                value={search}
                type="text"
                autoFocus
                placeholder="Search"
                className="m-0 focus:outline-none border border-color-d6d9e6 rounded pl-8 pr-6 py-2"
                style={{ minWidth: width - 24 }} />
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-auto" style={{ marginTop: 60 }}>
          {filteredMenuItems.length === 0 && <div className="mx-4 py-1 text-sm text-color-4e5d7e font-normal">No results were found</div>}
          {filteredMenuItems.map((m, i) => {
            let renderCategory = false
            if (has(m, 'category')) {
              renderCategory = !has(categoriesRendered, m.category)
              categoriesRendered[m.category] = true
            }
            const itemStyle = has(m, 'data.level') ? { marginLeft: 20 * m.data.level } : {}
            const isCategoryOpen = get(categoriesOpened, m.category, false)
            const menuItemChecked = get(checked, `['${m.key}'].checked`, null)
            return (
              <React.Fragment key={i}>

                {renderCategoryHeader(m, renderCategory)}

                <div className={classNames(menuItemClassName, { hidden: m.category && !isCategoryOpen })} style={itemStyle}>
                  <div className="flex items-center mx-2 my-1">
                    <ToggleButtonGroup
                      size="small"
                      exclusive
                      value={menuItemChecked}
                      onChange={(e, newValue) => handleToggleChange(e, newValue, m)}>
                      <ToggleButton value="include">
                        <FontAwesomeIcon icon="check"
                          size="sm"
                          className={classNames({ 'text-color-ffffff': menuItemChecked === 'include' }, { 'text-color-91959f': menuItemChecked === null })} />
                      </ToggleButton>
                      <ToggleButton value="exclude">
                        <FontAwesomeIcon icon="times"
                          size="sm"
                          className={classNames({ 'text-color-ffffff': menuItemChecked === 'exclude' }, { 'text-color-91959f': menuItemChecked === null })} />
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <div className="ml-2" style={{ marginTop: 3 }}>{m.name || '-'}</div>
                  </div>
                </div>

              </React.Fragment>
            )
          })}
        </div>

        <div className="flex justify-center py-4">
          {filteredMenuItems.length > 0
            && (
              <button
                onClick={onApplyClick}
                className="px-10 py-2 text-size-14px text-color-ffffff font-bold rounded bg-gradient-green shadow-btn focus:outline-none">
                Apply
              </button>
            )}
        </div>
      </>
    )
  }, [filteredMenuItems, checked, categoriesOpened])

  return (
    <>
      <span className={className} onClick={handleClick}>
        {display}
      </span>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        timeout={0}>
        <div className="flex flex-col" style={{ width, maxHeight }}>
          {renderMenu()}
        </div>
      </Popover>
    </>
  )
}

export default MultiSelectFilter
