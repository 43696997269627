import React, { useCallback, useMemo } from 'react'
import Icon, { iconType } from '../icon'
import pencilMd from '../../assets/pencil-md.png'
import ChangeParametersModal from './changeParametersModal'
import { useModal } from '../../hooks/useModal'
import { useSelector } from 'react-redux'
import { cloneDeep, find, get, orderBy } from 'lodash'
import { defaultSelectedFilters, defaultFormula, defaultTarget, defaultTimePeriod, timePeriodOptions } from './constants'
import moment from 'moment'
import { DateRangeOption, GlobalLevel } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import { getFilterLabels } from './helpers'
import pluralize from 'pluralize'
import Tooltip from '../common/tooltip'

const ScenarioDetails = (props) => {
  const { tenantId, groupId, baselineScenarioKey } = props

  const modal = useModal()

  const forecastSimulationLevelOptions = useSelector((state) => state.forecastSimulationLevelOptions)
  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)
  const scenarioPlannerOptions = useSelector((state) => state.scenarioPlannerOptions)

  const what = useMemo(() => {
    return forecastSimulationWorkspace.label || defaultFormula.label
  }, [forecastSimulationLevelOptions, forecastSimulationWorkspace])

  const when = useMemo(() => {
    const { dateRangeType, startDatetime, endDatetime } = forecastSimulationWorkspace
    const option = find(timePeriodOptions, (o) => o.key === dateRangeType)
    if (option) {
      if (option.key === DateRangeOption.CUSTOM) {
        const start = moment(startDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')
        const end = moment(endDatetime.seconds * 1000).utcOffset(0).format('M/D/YYYY')
        return `Between ${start} - ${end}`
      } else {
        return option.value
      }
    }
    return defaultTimePeriod.value
  }, [forecastSimulationWorkspace])

  const who = useMemo(() => {
    const { level, entityIdsList = [] } = forecastSimulationWorkspace.level || {}
    if (level === GlobalLevel.COMPANY) {
      return 'All'
    } else if (level === GlobalLevel.GROUP) {
      const group = find(forecastSimulationLevelOptions.groupLevelOptionsList, (o) => o.id === get(entityIdsList, '[0]', ''))
      if (group) {
        return group.name
      }
    } else if (level === GlobalLevel.PERSON) {
      const person = find(forecastSimulationLevelOptions.personLevelOptionsList, (o) => o.id === get(entityIdsList, '[0]', ''))
      if (person) {
        return person.name
      }
    }
    return defaultTarget.label
  }, [forecastSimulationLevelOptions, forecastSimulationWorkspace])

  const selectedFilters = useMemo(() => {
    const filters = cloneDeep(forecastSimulationWorkspace?.filtersList) ?? []
    if (filters.length === 0) {
      return defaultSelectedFilters
    } else {
      const filterGroups = orderBy(cloneDeep(scenarioPlannerOptions.filterOptionsList || []), (o) => o.label)
      const labels = getFilterLabels({ filterGroups, filters })
      return {
        key: filters,
        ...labels
      }
    }
  }, [scenarioPlannerOptions, forecastSimulationWorkspace])

  const onClick = useCallback(() => {
    modal.setOpen(true)
    window.analytics.track('scenarioPlanner.input.changeParametersModalClicked')
  }, [])

  return (
    <>
      <div className="bg-gradient-dark-gray rounded-lg pl-6 pr-4 py-4">
        <div className="flex items-center justify-between">
          <div>
            {what && <div className="text-color-ffffff leading-tight text-size-16px font-bold mb-1 last:mb-0 pr-1">{what}</div>}
            {when && <div className="text-color-ffffff leading-tight text-size-16px font-normal mb-1 last:mb-0 pr-1">{when}</div>}
            {who && <div className="text-color-ffffff leading-tight text-size-16px font-normal mb-1 last:mb-0 pr-1">{who}</div>}
            {selectedFilters.key !== -1 && selectedFilters.labels.length > 0 && (
              <div className="flex items-center text-color-ffffff leading-tight text-size-16px font-normal mb-1 last:mb-0 pr-1">
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={selectedFilters.labels.join(', ')}>
                  <div
                    className="w-5 h-5 rounded-full h bg-color-ffffff flex items-center justify-center text-color-151d49 text-size-12px mr-1 cursor-default"
                    style={{ transform: 'translate(-1px, -1px)' }}>
                    {selectedFilters.labels.length}
                  </div>
                </Tooltip>
                {pluralize('Filters', selectedFilters.labels.length)}
              </div>
            )}
          </div>
          <button onClick={onClick} className="rounded-full bg-color-ffffff p-2 flex-shrink-0 border border-color-edeeee focus:outline-none">
            <Icon type={iconType.IMAGE} src={pencilMd} />
          </button>
        </div>
      </div>
      <ChangeParametersModal
        tenantId={tenantId}
        groupId={groupId}
        baselineScenarioKey={baselineScenarioKey}
        modal={modal} />
    </>
  )
}

export default ScenarioDetails
