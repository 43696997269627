import React, { useCallback } from 'react'
import Popover from '../popover'

const ActionPopover = (props) => {
  const {
    popover,
    text,
    subText,
    cancelText = 'Cancel',
    actionText = 'Action',
    onCancel,
    onAction,
    maxWidth,
    placement = 'bottom',
    zIndex = 10000
  } = props

  const { anchorEl, open, onClose } = popover

  const onCancelInternal = useCallback(() => {
    onCancel && onCancel()
    onClose()
  }, [onCancel])

  const onActionInternal = useCallback(() => {
    onAction && onAction()
    onClose()
  }, [onAction])

  return (
    <Popover
      arrow={true}
      style={{ zIndex }}
      open={open}
      anchorEl={anchorEl}
      onClose={onCancelInternal}
      placement={placement}
      timeout={0}>
      <div className="px-7 py-6 font-normal cursor-default" style={maxWidth && { maxWidth }}>
        <div className="text-size-15px font-normal leading-tight">{text}</div>
        <div className="mt-3 mb-7 text-size-13px text-color-818e93 leading-tight">{subText}</div>
        <div className="flex justify-center">
          <div className="flex items-center">
            <button
              className="mx-2 px-6 py-2 text-size-13px text-color-ffffff font-weight-500 rounded-full shadow-btn whitespace-nowrap focus:outline-none"
              style={{ backgroundColor: '#a6b2cf' }}
              onClick={onCancelInternal}>
              {cancelText}
            </button>
            <button
              className="mx-2 px-6 py-2 text-size-13px text-color-ffffff font-weight-500 rounded-full shadow-btn whitespace-nowrap focus:outline-none"
              style={{ backgroundColor: '#5951FF' }}
              onClick={onActionInternal}>
              {actionText}
            </button>
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default ActionPopover
