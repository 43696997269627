import React, { useMemo } from 'react'
import { resolveCompanyImageUrl } from '../../lib/image'
import Avatar from '../avatar/avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parse } from 'date-fns'
import { dealStatus } from '../../constants/dealStatus'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { useDealDetail } from '../../hooks/useDealDetail'
import { canonicalFieldKeys, canopyFields } from '../fieldRenderers/constants'

const DealPreview = (props) => {
  const { opp = {} } = props

  const { getValueOrDefault } = useDealDetail(opp)

  const { formatCurrency } = useFormatCurrency()

  const name = useMemo(() => {
    return getValueOrDefault(canonicalFieldKeys.name, '')
  }, [getValueOrDefault])

  const stageName = useMemo(() => {
    return getValueOrDefault(canonicalFieldKeys.stagename, '')
  }, [getValueOrDefault])

  const amount = useMemo(() => {
    return getValueOrDefault(canonicalFieldKeys.amount, undefined)
  }, [getValueOrDefault])

  const closeDate = useMemo(() => {
    const value = getValueOrDefault(canonicalFieldKeys.closedate, '')
    if (value) {
      const date = parse(value, 'yyyy-MM-dd', new Date())
      return format(date, 'M/d/yyyy')
    }
  }, [getValueOrDefault])

  const owner = useMemo(() => {
    const value = getValueOrDefault(canopyFields._owner.key, undefined)
    return value?.name
  }, [getValueOrDefault])

  const signalCount = useMemo(() => {
    return getValueOrDefault(canopyFields._signalCount.key, undefined)
  }, [getValueOrDefault])

  const status = useMemo(() => {
    return getValueOrDefault(canopyFields._status.key, '')
  }, [getValueOrDefault])

  const companyImageUrl = useMemo(() => {
    const value = getValueOrDefault(canopyFields._account.key, undefined)
    const website = value?.website
    return website ? resolveCompanyImageUrl({ website }) : ''
  }, [getValueOrDefault])

  return (
    <>
      {opp && (
        <div className="w-full mx-auto text-center">

          <div className="w-15 mx-auto relative">
            <Avatar className="z-10 w-15 h-15 text-size-36px" imageUrl={companyImageUrl} text={name.charAt(0)} />
            {signalCount > 0 && (
              <div
                // eslint-disable-next-line max-len
                className="z-20 absolute top-0 right-0 -mt-3 -mr-3 flex items-center justify-center w-6 h-6 text-xs rounded-full leading-loose flex-shrink-0 text-color-ffffff bg-gradient-green">
                {signalCount}
              </div>
            )}
          </div>

          <div className="text-size-24px font-bold text-color-151d49 whitespace-nowrap truncate leading-tight mt-3 mb-2">{name}</div>

          {status && dealStatus[status] && (
            <div className="mx-auto">
              <div className="leading-tight text-size-14px text-color-91959f font-normal">
                <FontAwesomeIcon icon="circle" size="xs" color={dealStatus[status].color} />
                {' '}
                {dealStatus[status].label}
              </div>
            </div>
          )}

          <div className="mt-4">
            {stageName && (
              <div className="py-2 flex justify-between items-center w-full border-t border-color-e3e8ee">
                <div className="text-size-15px text-color-2e384d font-bold px-1">Stage</div>
                <div className="text-size-15px text-color-4e5d7e font-normal px-1">{stageName}</div>
              </div>
            )}
            {amount !== undefined && (
              <div className="py-2 flex justify-between items-center w-full border-t border-color-e3e8ee">
                <div className="text-size-15px text-color-2e384d font-bold px-1">Deal Size</div>
                <div className="text-size-15px text-color-4e5d7e font-normal px-1">{formatCurrency(amount)}</div>
              </div>
            )}
            {closeDate && (
              <div className="py-2 flex justify-between items-center w-full border-t border-color-e3e8ee">
                <div className="text-size-15px text-color-2e384d font-bold px-1">Close Date</div>
                <div className="text-size-15px text-color-4e5d7e font-normal px-1">{closeDate}</div>
              </div>
            )}
            <div className="py-2 flex justify-between items-center w-full border-t border-color-e3e8ee">
              <div className="text-size-15px text-color-2e384d font-bold px-1">Owner</div>
              <div className="text-size-15px text-color-4e5d7e font-normal pr-1 pl-2 whitespace-nowrap truncate">{owner}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DealPreview
