import React, { useLayoutEffect, useMemo } from 'react'
import classNames from 'classnames'
import { getFieldFromObject } from './helpers'
import { canopyFields } from './constants'
import SingleSelectFieldDetailItem from './singleSelectFieldDetailItem'

const StageNameFieldDetailItem = (props) => {
  const {
    debug = false,
    measure,
    opportunity,
    fieldDefinition,
    field,
    hideWhenEmpty = false,
    readonlyOverride = false,
    onFieldChanged,
    className = '',
    labelClassName = '',
    fieldReference,
    lazyLoad = false
  } = props

  const stageNames = useMemo(() => {
    const field = getFieldFromObject(opportunity, canopyFields._stageData.key)
    const value = field?.value ?? {}
    const { totalCount = 0 } = value
    return Array(totalCount).fill('')
  }, [opportunity])

  const stageIndex = useMemo(() => {
    const field = getFieldFromObject(opportunity, canopyFields._stageData.key)
    const value = field?.value ?? {}
    const { idx = -1, isClosed } = value
    if (isClosed || idx === -1) {
      return -1
    } else {
      return idx
    }
  }, [opportunity])

  useLayoutEffect(() => {
    const req = requestAnimationFrame(() => {
      measure && measure()
    })
    return () => {
      req && cancelAnimationFrame(req)
    }
  }, [measure, stageIndex])

  return (
    <>
      <SingleSelectFieldDetailItem {...props} />
      {stageIndex !== -1
        && (
          <div className="mt-1 mb-2 flex items-center justify-between">
            {stageNames.map((_, index) => (
              <div
                key={`stage-${index}`}
                className={classNames('flex-shrink-0 flex-1 rounded-full ml-1 mr-1 first:ml-0 last:ml-0',
                  { 'bg-color-1d4dcf': index === stageIndex },
                  { 'bg-color-8ba3e4': index < stageIndex },
                  { 'bg-color-c9ced0': index > stageIndex })}
                style={{ height: 8 }} />
            ))}
          </div>
        )}
    </>
  )
}

export default StageNameFieldDetailItem
