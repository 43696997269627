/* eslint-disable object-shorthand, func-names */
const Call = function Call(arg, initializer) {
  if (arg instanceof Call) {
    this.raw = arg.raw
    this.fmtFn = arg.formatter
    this.initializer = arg.initializer
  } else {
    this.raw = arg
    this.initializer = initializer
  }

  if (Number.isNaN(this.raw)) {
    this.raw = 0
  }

  this.initial = (this.raw && this.initializer?.up?.call(this, this.raw)) ?? this.raw
  this.value = this.initial
  return this
}

Call.prototype = Object.create(Number.prototype, {
  update: {
    value: function (raw) {
      if (parseFloat(raw)) {
        this.raw = this.initializer?.down?.call(this, raw) ?? raw
      } else {
        this.raw = raw
      }
      this.value = this.raw
    }
  },
  valueOf: {
    value: function () {
      return this.value
    }
  },
  formatter: {
    get() {
      return this.fmtFn
    },
    set(fmtFn) {
      this.fmtFn = fmtFn
    }
  },
  initialValue: {
    get() {
      return this.initial
    }
  },
  inherentValue: {
    get() {
      return this.inherent
    },
    set(inherent) {
      this.inherent = inherent
    }
  },
  toString: {
    value: function () {
      if (isFinite(this.value)) {
        return this.fmtFn?.call(this, this.value) ?? this.value.toString()
      }
      return '--'
    }
  }
})

const arr = [
  'toExponential',
  'toFixed',
  'toLocaleString',
  'toPrecision'
].forEach((name) => {
  Call.prototype[name] = function(...args) {
    return Number.prototype[name].apply(this.value, args)
  }
})

/* eslint-enable */
export default Call
