import React, { useMemo } from 'react'
import { useNotification } from '../../hooks/useNotification'
import CallCard from './callCard'
import { DisplayOption, VisibilityOption } from '../../grpc/enums'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { useForecasting } from '../../context/forecasting'
import { useForecastingFeature } from './hooks'

const CallCards = (props) => {
  const {
    isLoggedInUsersTeam,
    isIcManagerAndDirectReport,
    isManager,
  } = props

  const { checkPermissions } = usePermissions()
  const { notifyError } = useNotification()
  const { forecast, isPastForecastPeriod } = useForecasting()
  const { forecastCategories } = useForecastingFeature()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateForecast
    )
  }, [checkPermissions])

  const visibleCategories = useMemo(() => {
    return forecastCategories
      .filter((fc) => [DisplayOption.ALL, DisplayOption.BANNER, DisplayOption.BANNER_GRID].includes(fc.displayOption))
      .filter((fc) => [VisibilityOption.ALL, VisibilityOption.MANAGER].includes(fc.visibilityOption))
  }, [forecastCategories])

  const managerCalls = useMemo(() => {
    return (
      <>
        {visibleCategories.map((forecastCategory, index) => (
          <CallCard
            key={`CallCard-${forecastCategory.id}-${index}`}
            isLoggedInUsersTeam={isLoggedInUsersTeam}
            isIcManagerAndDirectReport={isIcManagerAndDirectReport}
            isManager={isManager}
            forecastCategory={forecastCategory}
            forecast={forecast}
            readonly={!permissions.CanUpdateForecast || forecastCategory.readOnly || isPastForecastPeriod}
            notifyError={notifyError} />
        ))}
      </>
    )
  }, [visibleCategories, isLoggedInUsersTeam, isIcManagerAndDirectReport, isManager, forecast, permissions.CanUpdateForecast, isPastForecastPeriod, notifyError])

  return (
    <div className="flex flex-wrap items-start mx-10 leading-tight">
      {managerCalls}
    </div>
  )
}

export default CallCards
