import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'

const SingleSelectFieldRowItemOptionItem = (props) => {
  const {
    measure,
    onClick,
    option,
    selected = false
  } = props

  const label = useMemo(() => {
    return option?.label ?? ''
  }, [option])

  useEffect(() => {
    measure && measure()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  return (
    <button
      className={classNames('w-full px-4 py-2 text-size-16px text-left text-color-09242f focus:outline-none hover:bg-color-edeeee',
        { 'bg-color-edeeee-60 pointer-events-none': selected })}
      onClick={(e) => onClick(e, option)}>
      {label}
    </button>
  )
}

export default SingleSelectFieldRowItemOptionItem
