import { find } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useGroups } from '../../context/groups'

const ForecastingBreadcrumbs = (props) => {
  const {
    userIds = [],
    forecastConfigId
  } = props

  const { routes } = useRoutes()

  const { flattened: teamFlattened } = useGroups()

  return teamFlattened.length > 0 ? (
    <div className="text-size-12px text-color-a6b2cf font-normal tracking-widest leading-none" style={{ marginBottom: '4px' }}>
      <Link to={
        routes.forecastingByConfig
          .replace(':forecastConfigId', forecastConfigId)
      }>
        Forecast Overview
      </Link>
      {userIds.slice(0, userIds.length - 1).map((userId, index) => {
        const user = find(teamFlattened, (u) => u.id === userId)
        return (
          <React.Fragment key={`breadcrumb-${userId}-${index}`}>
            <span>
              {'\u00A0'}
              |
              {'\u00A0'}
            </span>
            <Link to={routes.forecastingByTeam
              .replace(':forecastConfigId', forecastConfigId)
              .replace(':userIds', userIds.slice(0, index + 1).join('|'))}>
              {user ? user.name : userId}
            </Link>
          </React.Fragment>
        )
      })}
    </div>
  ) : null
}

export default ForecastingBreadcrumbs
