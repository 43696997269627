import React, { useEffect, useMemo, useCallback } from 'react'
import { useTenants } from '../../context/tenants'
import { useTextField } from '../../hooks/useTextField'
import { debounce } from 'lodash'
import Header from '../header/header'
import Groups from '../icons/groups'
import TenantsList from './tenantsList'
import pluralize from 'pluralize'
import SearchBox from '../search/searchBox'
import AnimatedLoader from '../loaders/animatedLoader'
import EmptyState from '../empty/emptyState'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { regionOptions } from '../../constants/regionOptions'
import TextSelectList from '../common/textSelectList'

const TenantsMain = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadTenant
    )
  }, [checkPermissions])

  const search = useTextField()

  const { isFetching, tenants, setSearch, setRegion, setPage, total } = useTenants()

  const count = useMemo(() => {
    return tenants.length
  }, [tenants])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const onChangeRegion = useCallback((event) => {
    setPage(1)
    setRegion(event.target.value)
  }, [setRegion, setPage])

  const debounceSearch = useMemo(() => {
    return debounce((searchText) => {
      setSearch(searchText)
    }, 350)
  }, [])

  useEffect(() => {
    debounceSearch(search.value)
  }, [search.value])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        showSearch={false}
        text="Tenants" />

      <div className="flex-grow overflow-auto p-10">

        <div className="flex items-center justify-between mb-3">
          <div className="text-size-20px text-color-51636a font-weight-400">{!isFetching ? pluralize('Tenant', total, true) : ''}</div>
          {permissions.CanReadTenant && (
            <div className="flex items-center">
              <TextSelectList
                defaultValue={regionOptions[0]?.value}
                onChange={onChangeRegion}
                options={regionOptions}
              />
              <SearchBox
                className="mx-2"
                value={search.value}
                onChange={search.onChange}
                onClear={search.reset}
                autoFocus={true} />
            </div>
          )}
        </div>

        {permissions.CanReadTenant && (
          <>
            {showEmptyState
              ? (
                <div className="flex justify-center my-10">
                  <EmptyState
                    iconControl={<Groups transform="scale(1.2)" />}
                    header="No Tenants"
                    subHeader="There are no tenants to display" />
                </div>
              )
              : (
                <>
                  {isFetching
                    ? (
                      <div className="flex justify-center my-10">
                        <AnimatedLoader
                          className="my-10"
                          title="Loading Tenants"
                          subTitle="Please wait..." />
                      </div>
                    )
                    : (
                      <TenantsList />
                    )}
                </>
              )}
          </>
        )}

      </div>
    </div>
  )
}

export default TenantsMain
