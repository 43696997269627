import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getHeaderMetricsByUserKey, getHeaderMetricsByTeamKey } from '../../actions/signalService'
import { apiSuccessSelector } from '../../selectors'
import GMLRenderer from '../../gml/renderer/gmlRenderer'
import ToggleMenu from '../toggleMenu/toggleMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import get from 'lodash/get'
import SignalDebug from '../signals/signalDebug'
import ErrorBoundary from '../../gml/controls/errorBoundary'
import EmptyGoals from '../empty/emptyGoals'
import { metricNames } from './constants'
import useRefResize from '../../hooks/useRefResize'

const nextPrevBtnStyle = {
  width: '48px',
  height: '48px',
  marginTop: 125,
  fontSize: '13px',
  border: '1px solid rgb(227, 232, 250)',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
}

const MetricsHeader = (props) => {
  const {
    user = {},
    level,
    ready = false,
    debug = false,
  } = props

  const mainWidth = useSelector((state) => state.mainWidth)

  const emptyMessage = useMemo(() => {
    return user.id && user.firstName ? `${user.firstName} doesn't appear to have goals set` : ''
  }, [user])

  const containerRef = useRef(null)
  const scrollView = useRef(null)
  const pipelineRef = useRef()
  const goalsRef = useRef()

  const toggleWidth = 240
  const [titleWidth, setTitleWidth] = useState(toggleWidth)
  const [pipelineHeight, setPipelineHeight] = useState(0)
  const [goalsHeight, setGoalsHeight] = useState(0)

  const [activeIndex, setActiveIndex] = useState(0)
  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [enableNextBtn, setEnableNextBtn] = useState(true)

  const headerMetrics = useSelector((state) => state.headerMetrics)

  const userApiSuccess = useSelector((state) => apiSuccessSelector(state, getHeaderMetricsByUserKey))
  const teamApiSuccess = useSelector((state) => apiSuccessSelector(state, getHeaderMetricsByTeamKey))

  const showMetrics = useMemo(() => {
    return (level === 'person' && userApiSuccess) || (level === 'group' && teamApiSuccess)
  }, [userApiSuccess, teamApiSuccess])

  const metrics = useMemo(() => {
    return {
      [metricNames.pipelineByStage]: find(headerMetrics, (x) => get(x, 'definition.name') === metricNames.pipelineByStage),
      [metricNames.pipelineByForecast]: find(headerMetrics, (x) => get(x, 'definition.name') === metricNames.pipelineByForecast),
      [metricNames.goals]: find(headerMetrics, (x) => get(x, 'definition.name') === metricNames.goals),
    }
  }, [headerMetrics])

  const hasPipelineByStage = useMemo(() => {
    return metrics[metricNames.pipelineByStage] !== undefined
  }, [metrics])

  const hasPipelineByForecast = useMemo(() => {
    return metrics[metricNames.pipelineByForecast] !== undefined
  }, [metrics])

  const hasGoals = useMemo(() => {
    return metrics[metricNames.goals] !== undefined
  }, [metrics])

  const enableSideScroller = useMemo(() => {
    return ready && showMetrics && (hasPipelineByStage || hasPipelineByForecast) && hasGoals
  }, [ready, showMetrics, hasPipelineByStage, hasPipelineByForecast, hasGoals])

  const [activePipelineMetric, setActivePipelineMetric] = useState(null)
  useEffect(() => {
    if (metrics[metricNames.pipelineByStage]) {
      setActivePipelineMetric(metrics[metricNames.pipelineByStage])
    } else if (metrics[metricNames.pipelineByForecast]) {
      setActivePipelineMetric(metrics[metricNames.pipelineByForecast])
    } else {
      setActivePipelineMetric(null)
    }
  }, [metrics])

  const onSelectedIndexChanged = useCallback((index) => {
    if (index === 0) {
      setActivePipelineMetric(metrics[metricNames.pipelineByStage])
    } else if (index === 1) {
      setActivePipelineMetric(metrics[metricNames.pipelineByForecast])
    }
  }, [metrics])

  const pipelineResize = useCallback(({ ref, width, height }) => {
    const gml = ref.current && ref.current.childNodes && ref.current.childNodes.length > 0 ? ref.current.childNodes[ref.current.childNodes.length - 1] : undefined
    if (gml) {
      const rect = gml.getBoundingClientRect()
      setPipelineHeight(rect.height)
    }
  }, [])

  useRefResize(pipelineRef, pipelineResize)

  const goalsResize = useCallback(({ ref, width, height }) => {
    const gml = ref.current && ref.current.childNodes && ref.current.childNodes.length > 0 ? ref.current.childNodes[0] : undefined
    if (gml) {
      const rect = gml.getBoundingClientRect()
      setGoalsHeight(rect.height)
    }
  }, [])

  useRefResize(goalsRef, goalsResize)

  useEffect(() => {
    const req = requestAnimationFrame(() => {
      if (metrics[metricNames.pipelineByStage] !== undefined && metrics[metricNames.pipelineByForecast] !== undefined) {
        const titleNodes = document.querySelectorAll('#title')
        if (titleNodes.length > 0) {
          setTitleWidth(titleNodes[0].clientWidth)
        }
      }
    })
    return () => {
      cancelAnimationFrame(req)
    }
  }, [metrics, pipelineHeight])

  const scrollToIndex = useCallback((index) => {
    scrollView.current && scrollView.current.scroll({ left: index * mainWidth })
  }, [mainWidth, pipelineHeight, goalsHeight])

  const debounceScrollEnded = debounce((index) => {
    setActiveIndex(index)
    scrollToIndex(index)
  }, 750)

  const handleScroll = useCallback((e) => {
    const { scrollLeft } = scrollView.current
    const index = scrollLeft * 2 > mainWidth ? 1 : 0
    setEnablePrevBtn(index > 0)
    setEnableNextBtn(index === 0)
    debounceScrollEnded(index)
  }, [mainWidth])

  const prev = useCallback(() => {
    const newIndex = Math.max(activeIndex - 1, 0)
    if (activeIndex !== newIndex) {
      setActiveIndex(newIndex)
      scrollToIndex(newIndex)
    }
  }, [activeIndex, scrollToIndex])

  const next = useCallback(() => {
    const newIndex = Math.min(activeIndex + 1, 1)
    if (activeIndex !== newIndex) {
      setActiveIndex(newIndex)
      scrollToIndex(newIndex)
    }
  }, [activeIndex, scrollToIndex])

  const sharedBtnClasses = 'flex items-center bg-color-ffffff rounded-full cursor-pointer pointer-events-auto'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, '-mr-17', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  const debugMetric = useMemo(() => {
    if ((!enableSideScroller && metrics[metricNames.goals] !== undefined)
      || (enableSideScroller && activeIndex === 1 && metrics[metricNames.goals] !== undefined)) {
      return metrics[metricNames.goals]
    }
    return activePipelineMetric
  }, [activePipelineMetric, metrics, activeIndex, enableSideScroller])

  const [containerHeight, setContainerHeight] = useState({ value: 0 })

  useEffect(() => {
    if (!ready) {
      setContainerHeight({ value: 0 })
      return
    }

    if (showMetrics && enableSideScroller) {
      switch (activeIndex) {
        case 0:
          setContainerHeight({ value: pipelineHeight })
          break
        case 1:
          setContainerHeight({ value: goalsHeight })
          break
        default:
          break
      }
    } else if (metrics[metricNames.pipelineByStage] !== undefined || metrics[metricNames.pipelineByForecast] !== undefined) {
      setContainerHeight({ value: pipelineHeight })
    } else if (metrics[metricNames.goals] !== undefined) {
      setContainerHeight({ value: goalsHeight })
    }
  }, [showMetrics, metrics, activeIndex, pipelineHeight, goalsHeight, ready])

  const showEmpty = useMemo(() => {
    const empty = showMetrics && !activePipelineMetric && !hasGoals
    if (empty) {
      setContainerHeight({ value: 57 })
    }
    return empty
  }, [showMetrics, activePipelineMetric, hasGoals, ready])

  return (
    <>

      {debug && <SignalDebug signal={debugMetric} className="px-10" />}

      <div ref={containerRef} className="relative">

        {enableSideScroller
          && (
            <div className="-ml-10 -mr-6 z-20 absolute flex justify-between w-full pointer-events-none select-none px-10">
              <div className={prevBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={prev}>
                <FontAwesomeIcon icon="chevron-left" size="2x" color="#c9ced0" className="mx-auto" />
              </div>
              <div className={nextBtnClasses} style={{ ...nextPrevBtnStyle }} onClick={next}>
                <FontAwesomeIcon icon="chevron-right" size="2x" color="#c9ced0" className="mx-auto" />
              </div>
            </div>
          )}

        <div className="overflow-y-hidden" style={{ height: containerHeight.value }}>
          <div
            ref={scrollView}
            className="inline-flex max-w-full no-scrollbar"
            style={{ scrollBehavior: 'smooth' }}
            onScroll={handleScroll}>

            <div ref={pipelineRef}
              className={classNames('px-10 relative overflow-hidden', { hidden: !hasPipelineByStage && !hasPipelineByForecast })}
              style={{ width: mainWidth, minWidth: mainWidth }}>
              {showMetrics && activePipelineMetric
                && (
                  <>
                    {hasPipelineByStage && hasPipelineByForecast && (
                      <div className="absolute" style={{ marginTop: 10, marginLeft: titleWidth - toggleWidth, width: toggleWidth }}>
                        <ToggleMenu
                          id="pipelineToggleMenu"
                          data={[
                            { text: 'By Stage' },
                            { text: 'By Forecast Category' }
                          ]}
                          itemWidthClass="w-1/2"
                          onSelectedIndexChanged={onSelectedIndexChanged} />
                      </div>
                    )}
                    <div id="pipelineHeader">
                      <ErrorBoundary>
                        <GMLRenderer
                          key={`GMLRenderer-${activePipelineMetric.id}`}
                          tree={get(activePipelineMetric, 'presentation.resolved')}
                          debug={debug}
                          animate={true} />
                      </ErrorBoundary>
                    </div>
                  </>
                )}
            </div>

            <div ref={goalsRef} className={classNames('px-10 relative overflow-hidden', { hidden: !hasGoals })} style={{ width: mainWidth, minWidth: mainWidth }}>
              {showMetrics && hasGoals && (
                <ErrorBoundary>
                  <GMLRenderer
                    key={`GMLRenderer-${metrics[metricNames.goals].id}`}
                    tree={get(metrics[metricNames.goals], 'presentation.resolved')}
                    debug={debug}
                    animate={true} />
                </ErrorBoundary>
              )}
            </div>

            {showEmpty && (
              <div className="px-10">
                <EmptyGoals message={emptyMessage} />
              </div>
            )}
          </div>
        </div>

      </div>

    </>
  )
}

export default MetricsHeader
