import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import Modals from './modals/modals'
import AnimatedLoader from './loaders/animatedLoader'
import useRefSize from '../hooks/useRefSize'

const MainContainer = (props) => {
  const loadingScreen = useSelector((state) => state.loadingScreen)

  const mainRef = useRef(null)
  const size = useRefSize(mainRef)
  const { width } = size

  return (
    <main ref={mainRef}>
      {loadingScreen.enabled
        && (
          <div className="h-full fixed top-0 bg-color-fafafa flex justify-center" style={{ width, zIndex: 9999999999 }}>
            {!loadingScreen.isEmpty
            && (
              <AnimatedLoader
                className="absolute top-0 mt-24"
                title="Loading Data"
                subTitle="Please wait..."
                maxWidth={300} />
            )}
          </div>
        )}
      <Modals {...props} />
      {props.children}
    </main>
  )
}

export default MainContainer
