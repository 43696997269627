import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetTenantInfoRequest } from '../grpc/converters'
import { setCurrencyCode } from '../lib/auth0'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { InstanceSetupStatus } from '../grpc/enums'

const TenantInfoContext = React.createContext()

export function TenantInfoProvider({ children }) {
  const { actingTenantId } = useAuth()

  const [key, setKey] = useState(guid())
  const [success, setSuccess] = useState(false)
  const [tenantInfo, setTenantInfo] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  useGrpcEffect({
    request: toGetTenantInfoRequest({
      tenantId: actingTenantId,
    }),
    onSuccess: (obj) => {
      const { currencyCode, name } = obj
      setCurrencyCode(currencyCode)
      setSuccess(true)
      setTenantInfo(obj)
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getTenantInfo',
    debug: false,
  }, [actingTenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
    setTenantInfo({})
  }, [])

  const tenantIsPending = useMemo(() => {
    const { setupStatus } = tenantInfo
    return setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_PENDING
  }, [tenantInfo, InstanceSetupStatus])

  const appHierarchyOptions = useMemo(() => {
    if (!tenantInfo.appHierarchyOptions) return
    return tenantInfo.appHierarchyOptions
  }, [tenantInfo])

  const forecastTeamSettings = useMemo(() => {
    if (!appHierarchyOptions) return
    const {
      simplifyHierarchy: simplify,
      useManagersNamesInHierarchy: useManagerNames
    } = appHierarchyOptions

    return {
      simplify,
      useManagerNames
    }
  }, [appHierarchyOptions])

  const crmName = useMemo(() => {
    const { crmType } = tenantInfo
    if (crmType === 'hubspot') {
      return 'HubSpot'
    } else {
      return 'Salesforce'
    }
  }, [tenantInfo])

  const isSalesforce = useMemo(() => {
    const { crmType } = tenantInfo
    return crmType === 'salesforce'
  }, [tenantInfo])

  const isHubSpot = useMemo(() => {
    const { crmType } = tenantInfo
    return crmType === 'hubspot'
  }, [tenantInfo])

  const isSandbox = useMemo(() => {
    const { parentTenantId } = tenantInfo
    return !!parentTenantId
  }, [tenantInfo])

  const contextValue = useMemo(() => {
    return {
      tenantInfo,
      appHierarchyOptions,
      forecastTeamSettings,
      success,
      setTenantInfo,
      key,
      invalidate,
      crmName,
      isSalesforce,
      isHubSpot,
      tenantIsPending,
      isSandbox,
      isFetching
    }
  }, [
    tenantInfo,
    appHierarchyOptions,
    forecastTeamSettings,
    success,
    setTenantInfo,
    key,
    invalidate,
    crmName,
    isSalesforce,
    isHubSpot,
    tenantIsPending,
    isSandbox,
    isFetching
  ])

  return <TenantInfoContext.Provider value={contextValue}>{children}</TenantInfoContext.Provider>
}

export function useTenantInfo() {
  const context = React.useContext(TenantInfoContext)
  if (context === undefined) {
    throw new Error('useTenantInfo must be used within a TenantInfoProvider')
  }
  return context
}
