import { createReducer } from '@reduxjs/toolkit'
import { clearNlgTester } from '../actions'
import { testNlgServiceFailed, testNlgServiceSuccess } from '../actions/nlgService'
import has from 'lodash/has'

const initialState = {}

export const nlgTester = createReducer(initialState, {
  [clearNlgTester]: (state, action) => initialState,
  [testNlgServiceFailed.type]: (state, action) => {
    if (has(action, 'payload.error')) {
      return {
        result: undefined,
        error: action.payload.error
      }
    }
    return state
  },
  [testNlgServiceSuccess.type]: (state, action) => {
    if (has(action, 'payload.result')) {
      return {
        result: action.payload.result,
        error: undefined,
      }
    }
    return state
  }
})
