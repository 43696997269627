import { useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPageContext } from '../../actions'
import { useLoggedInUser } from '../../context/loggedInUser'

const LocationTracker = (props) => {
  const dispatch = useDispatch()

  const params = useParams()
  const { loggedInUserId } = useLoggedInUser()

  useEffect(() => {
    dispatch(setPageContext({ id: params.userId || loggedInUserId }))
  }, [params, loggedInUserId])

  return null
}

export default withRouter(LocationTracker)
