import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocationData } from '../location/hooks'
import { apiSuccessSelector, opportunitiesSelector } from '../../selectors'
import { getOpportunitiesByTeamKey } from '../../actions/opportunityService'
import DealsList from './dealsList'
import { clearOpportunities } from '../../actions'
import { getOpportunitiesByTeam } from '../../services/opportunityService'
import { Waypoint } from 'react-waypoint'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDealFilters } from './hooks'

const TeamDeals = (props) => {
  const dispatch = useDispatch()

  const { hash } = useLocationData()
  const filterHeaders = useDealFilters(hash)

  const filteredDealCount = useSelector((state) => state.filteredDealCount)
  const opportunities = useSelector(opportunitiesSelector)
  const dealCount = opportunities.length
  const apiSuccess = useSelector((state) => apiSuccessSelector(state, getOpportunitiesByTeamKey))
  const showEmptyList = apiSuccess && opportunities.length === 0

  const showEndOfListLoader = dealCount < filteredDealCount
  // console.log('dealCount', dealCount, 'out of', filteredDealCount)
  const handleWaypointEntered = useCallback(() => {
    window.analytics.track('Load More Team Deals')
    dispatch(getOpportunitiesByTeam(dealCount, 20, filterHeaders))
  }, [filterHeaders, opportunities, dealCount])

  useEffect(() => {
    dispatch(clearOpportunities())
    dispatch(getOpportunitiesByTeam(0, 20, filterHeaders))
  }, [filterHeaders])

  return (
    <>
      <DealsList
        key="TeamDeals"
        opportunities={opportunities}
        enableSorting={true}
        enableFilters={false}
        showEmptyList={showEmptyList}
        emptyStateSubHeader="There are no deals to show." />

      {showEndOfListLoader && (
        <>
          <div className="flex justify-center my-4">
            <FontAwesomeIcon icon="spinner" size="2x" className="spinner mx-2" />
          </div>
          <Waypoint onEnter={handleWaypointEntered} />
        </>
      )}
    </>
  )
}

export default TeamDeals
