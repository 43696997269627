import React, { useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { setDock } from '../../../actions'
import ForecastComparison from './forecastComparison'
import { secondsToFormattedDate } from '../../../lib/dateFns'
import PipelineChart from './pipelineChart'
import { getCurrentForecastCategoryCall, getPreviousForecastCategoryCall } from '../helpers'
import { useFormatCurrency } from '../../../hooks/useFormatCurrency'
import { useForecasting } from '../../../context/forecasting'
import ForecastSelector from './forecastSelector'
import { orderBy, sum } from 'lodash'
import NoForecast from '../noForecast'
import { useForecastConfigs } from '../../../context/forecastConfigs'
import ForecastPeriodSelector from '../forecastPeriodSelector'
import { featureSettingSelector } from '../../../selectors'
import { featureNames } from '../../../constants/featureNames'

const Header = (props) => {
  const {
    forecastCategories
  } = props

  const { forecast } = useForecasting()

  const { configsList } = useForecastConfigs()

  const forecastingPeriodFeature = useSelector((state) => featureSettingSelector(state, { key: featureNames.forecastingPeriod }))

  const showNoForecast = useMemo(() => {
    return configsList.length === 0
  }, [configsList])

  const { formatCurrency } = useFormatCurrency()

  const dispatch = useDispatch()

  const forecastPeriodEnabled = useMemo(() => {
    return forecastingPeriodFeature?.payload?.enabled ?? false
  }, [forecastingPeriodFeature])

  const handleClose = useCallback(() => {
    dispatch(setDock({ forecasting: { enabled: false } }))
  }, [dispatch])

  const currentForecastTitle = useMemo(() => {
    let lastSubmit
    const seconds = forecast?.previous?.submittedAt?.value?.seconds
    if (seconds) {
      try {
        lastSubmit = secondsToFormattedDate(seconds, 'M/d/yyyy h:mmaaa')
      } catch (err) {
        console.log(`Unable to parse unix time ${seconds}`)
      }
    }
    return (
      <div>
        <div className="text-size-18px text-color-09242f font-bold">
          Current Forecast
        </div>
        {lastSubmit && (
          <div className="text-size-10px text-color-b0bac9 font-weight-400 tracking-widest pt-1">
            Last Submit
            {' '}
            {lastSubmit}
          </div>
        )}
      </div>
    )
  }, [forecast])

  const overview = useMemo(() => {
    if (!forecast.previous) {
      return <></>
    }
    const categoriesWithDiff = orderBy(forecastCategories.map((fc) => {
      const currentCall = Math.round(getCurrentForecastCategoryCall(fc.id, forecast))
      const previousCall = Math.round(getPreviousForecastCategoryCall(fc.id, forecast))
      return {
        id: fc.id,
        name: fc.name,
        diff: currentCall - previousCall
      }
    }), (o) => Math.abs(o.diff), ['desc'])
    const diffSum = sum(categoriesWithDiff.map((fc) => fc.diff))
    const avgDiff = diffSum / forecastCategories.length
    let statement = <></>
    if (avgDiff === 0) {
      statement = <span>the same as</span>
    } else if (avgDiff > 0) {
      statement = (
        <span>
          {formatCurrency(Math.round(Math.abs(avgDiff)))}
          {' '}
          above
        </span>
      )
    } else if (avgDiff < 0) {
      statement = (
        <span>
          {formatCurrency(Math.round(Math.abs(avgDiff)))}
          {' '}
          below
        </span>
      )
    }
    const text = (
      <span>
        This forecast is, on average,
        {' '}
        {statement}
        {' '}
        your previous forecast
      </span>
    )
    if (categoriesWithDiff[0] && avgDiff !== 0) {
      const diff = categoriesWithDiff[0].diff
      return (
        <span>
          {text}
          , with the biggest difference being in
          {' '}
          {categoriesWithDiff[0].name}
          {' '}
          where it is
          {' '}
          {formatCurrency(Math.round(Math.abs(categoriesWithDiff[0].diff)))}
          {' '}
          {diff < 0 ? 'lower' : 'higher'}
          .
        </span>
      )
    } else {
      return (
        <span>
          {text}
          .
        </span>
      )
    }
  }, [forecast, forecastCategories, formatCurrency])

  return (
    <>

      <div className="flex items-start justify-between">
        <div className="w-full flex items-start justify-between">
          {showNoForecast ? <div /> : <ForecastSelector />}
          <div className="flex items-center justify-between">
            <button onClick={handleClose} className="focus:outline-none">
              <FontAwesomeIcon icon="times" className="text-color-151d49" style={{ width: 16, height: 16 }} />
            </button>
          </div>
        </div>
      </div>

      {forecastPeriodEnabled && (
        <div>
          <ForecastPeriodSelector
            fieldset={false} />
        </div>
      )}

      {showNoForecast
        ? (
          <NoForecast />
        )
        : (
          <>
            <div className="mt-3">
              <div className="text-size-12px text-color-09242f font-weight-400">{overview}</div>
            </div>
            {forecast.previous
              && (
                <>
                  <ForecastComparison
                    forecastCategories={forecastCategories} />
                  <PipelineChart
                    forecastCategories={forecastCategories} />
                </>
              )}
            <div className="my-3 border-t border-color-c9ced0" />
            {currentForecastTitle}
          </>
        )}

    </>
  )
}

export default Header
