import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const SummaryHeader = (props) => {
  const { header, description, isBaseline, isActive } = props
  return (
    <>
      <div className="flex items-center">
        <div className="flex justify-center">
          {isBaseline
            ? (
              <>
                <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
                <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
                <span style={{ paddingLeft: 2, color: isActive ? '#2e5bff' : '#e6e8e9' }}>-</span>
              </>
            )
            : <FontAwesomeIcon icon="circle" color={isActive ? '#2e5bff' : '#e6e8e9'} style={{ width: 12, height: 12 }} />}
        </div>
        {header && <div className="text-color-09242f text-size-28px font-bold pl-2">{header}</div>}
      </div>
      {description && <div className="text-color-09242f text-size-18px font-weight-500">{description}</div>}
    </>
  )
}

export default SummaryHeader
