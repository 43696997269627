import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePopover } from '../../hooks/usePopover'
import { useCanvasItem } from '../../context/canvasItem'
import { useRealtimeDraftView } from '../../context/realtimeDraftView'
import FilterSelectPopover from './filterSelectPopover'
import { useRealtimeViewModal } from '../../context/realtimeViewModal'
import classNames from 'classnames'
import { cloneDeep, find } from 'lodash'
import { moveCursorToEnd } from '../../lib/input'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import ActionModal from '../common/actionModal'
import { useModal } from '../../hooks/useModal'

const FilterName = () => {
  const nameRef = useRef()

  const confirmationModal = useModal()
  const [actionCallback, setActionCallback] = useState(undefined)
  const [confirmFilterChange, setConfirmFilterChange] = useState(undefined)

  const popover = usePopover()
  const { setAnchorEl, onClose } = popover

  const { name, userQueryParametersList, pendingFilter, setPendingFilter } = useCanvasItem()
  const { isEditingName, setIsEditingName, nameTextField } = useRealtimeViewModal()
  const { renameWorkingFilter, canSave } = useRealtimeFilterEditor()

  const {
    clearError,
    selectedFilter,
    setSelectedFilter,
    setOverrideParametersList
  } = useRealtimeDraftView()

  useEffect(() => {
    return () => {
      setIsEditingName(false)
    }
  }, [])

  const onClick = useCallback((e) => {
    if (pendingFilter) {
      return
    }
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [pendingFilter])

  useEffect(() => {
    if (confirmFilterChange && !confirmationModal.open) {
      setSelectedFilter(confirmFilterChange)
      setConfirmFilterChange(undefined)
    }
  }, [confirmFilterChange, confirmationModal.open])

  const onSelectedOptionChanged = useCallback((filter) => {
    onClose()
    if (canSave) {
      setActionCallback(() => {
        clearError()
        setOverrideParametersList(undefined)
        setConfirmFilterChange(filter)
        window.analytics.track('realtimeMetrics.metricSelectList.optionClicked', { name, filter: filter?.label })
      })
      confirmationModal.setOpen(true)
      return
    }
    clearError()
    setOverrideParametersList(undefined)
    setSelectedFilter(filter)
    window.analytics.track('realtimeMetrics.metricSelectList.optionClicked', { name, filter: filter?.label })
  }, [canSave, setOverrideParametersList])

  useEffect(() => {
    if (pendingFilter?.editName) {
      nameTextField.setValue(pendingFilter?.name ?? '')
      setIsEditingName(true)
    } else if (userQueryParametersList.length > 0) {
      const filter = find(userQueryParametersList, (uqp) => uqp.id === selectedFilter?.id)
      nameTextField.setValue(filter?.label ?? '')
    }
  }, [userQueryParametersList, selectedFilter, pendingFilter])

  useEffect(() => {
    if (isEditingName) {
      const input = nameRef.current
      input.focus()
      moveCursorToEnd(input)
    }
  }, [isEditingName])

  const onBlur = useCallback(() => {
    setIsEditingName(false)
    const newName = nameRef.current.innerText.trim()
    if (pendingFilter) {
      const filter = cloneDeep(pendingFilter)
      filter.name = newName
      filter.editName = false
      setPendingFilter(filter)
    } else {
      renameWorkingFilter(newName)
    }
  }, [renameWorkingFilter, nameRef, pendingFilter])

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const input = nameRef.current
      input.blur()
    }
  }, [])

  return (
    <>
      <div onClick={onClick} className="flex items-center cursor-pointer">
        <div
          ref={nameRef}
          className={classNames('whitespace-nowrap truncate text-color-09242f text-size-20px font-weight-700', { 'px-1': isEditingName })}
          style={{ ...isEditingName && { transform: 'translateX(-4px)' } }}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          contentEditable={isEditingName}
          suppressContentEditableWarning={true}>
          {nameTextField.value}
        </div>
        {!pendingFilter && (
          <FontAwesomeIcon
            icon="chevron-down"
            className="mx-2 text-color-09242f"
            size="sm"
            style={{ ...isEditingName && { transform: 'translateX(-8px)' } }} />
        )}
      </div>
      <FilterSelectPopover
        popover={popover}
        options={userQueryParametersList}
        selectedOption={selectedFilter}
        onSelectedOptionChanged={onSelectedOptionChanged}
        disablePortal={true} />
      <ActionModal
        modal={confirmationModal}
        onAction={actionCallback} />
    </>
  )
}

export default FilterName
