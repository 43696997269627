import { dealStatus } from '../../constants/dealStatus'

export const fieldRenderTypes = {
  UNKNOWN: 'UNKNOWN',
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  TIMESTAMP: 'TIMESTAMP',
  NUMBER: 'NUMBER',
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  STRING: 'STRING',
  CANOPY_STATUS: 'CANOPY_STATUS',
  CANOPY_SIGNAL_COUNT: 'CANOPY_SIGNAL_COUNT',
  CANOPY_OWNER: 'CANOPY_OWNER',
  _STATUS: '_STATUS',
  _SIGNAL_COUNT: '_SIGNAL_COUNT',
  _OWNER: '_OWNER',
}

export const formatTypes = {
  currency: 'currency',
  date: 'date',
  float: 'float',
  int: 'int',
  location: 'location',
  multiselect: 'multiselect',
  percent: 'percent',
  plaintextarea: 'plaintextarea',
  richtextarea: 'richtextarea',
  select: 'select',
  text: 'text',
  timestamp: 'timestamp',
  url: 'url',
  email: 'email',
  canopy_status: 'canopy_status',
  canopy_signalcount: 'canopy_signalcount',
  canopy_owner: 'canopy_owner',
  _status: '_status',
  _signalcount: '_signalcount',
  _owner: '_owner',
}

export const fieldTypes = {
  STRING: 'STRING',
  FLOAT: 'FLOAT',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  TIMESTAMP: 'TIMESTAMP',
  INTEGER: 'INTEGER',
  GEOGRAPHY: 'GEOGRAPHY',
}

export const canopyFields = {
  canopy_status: {
    label: 'Status',
    key: 'canopy_status',
    format: formatTypes.canopy_status,
    options: {
      itemsList: [
        { sort: 0, key: dealStatus.lost.id, label: dealStatus.lost.label, value: btoa(`"${dealStatus.lost.id}"`) },
        { sort: 1, key: dealStatus.offTrack.id, label: dealStatus.offTrack.label, value: btoa(`"${dealStatus.offTrack.id}"`) },
        { sort: 2, key: dealStatus.onTrack.id, label: dealStatus.onTrack.label, value: btoa(`"${dealStatus.onTrack.id}"`) },
        { sort: 3, key: dealStatus.pushed.id, label: dealStatus.pushed.label, value: btoa(`"${dealStatus.pushed.id}"`) },
        { sort: 4, key: dealStatus.stalled.id, label: dealStatus.stalled.label, value: btoa(`"${dealStatus.stalled.id}"`) },
        { sort: 5, key: dealStatus.won.id, label: dealStatus.won.label, value: btoa(`"${dealStatus.won.id}"`) },
      ],
    },
  },
  canopy_signalcount: {
    label: 'Signals',
    key: 'canopy_signalcount',
    format: formatTypes.canopy_signalcount,
  },
  canopy_owner: {
    label: 'Owner',
    key: 'canopy_owner',
    format: formatTypes.canopy_owner,
  },
  _status: {
    label: 'Status',
    key: '_status',
    format: formatTypes._status,
    options: {
      itemsList: [
        { sort: 0, key: dealStatus.lost.id, label: dealStatus.lost.label, value: btoa(`"${dealStatus.lost.id}"`) },
        { sort: 1, key: dealStatus.offTrack.id, label: dealStatus.offTrack.label, value: btoa(`"${dealStatus.offTrack.id}"`) },
        { sort: 2, key: dealStatus.onTrack.id, label: dealStatus.onTrack.label, value: btoa(`"${dealStatus.onTrack.id}"`) },
        { sort: 3, key: dealStatus.pushed.id, label: dealStatus.pushed.label, value: btoa(`"${dealStatus.pushed.id}"`) },
        { sort: 4, key: dealStatus.stalled.id, label: dealStatus.stalled.label, value: btoa(`"${dealStatus.stalled.id}"`) },
        { sort: 5, key: dealStatus.won.id, label: dealStatus.won.label, value: btoa(`"${dealStatus.won.id}"`) },
      ],
    },
  },
  _signalCount: {
    label: 'Signals',
    key: '_signalCount',
    format: formatTypes._signalcount,
  },
  _owner: {
    label: 'Owner',
    key: '_owner',
    format: formatTypes._owner,
  },
  _account: {
    label: 'Account',
    key: '_account',
  },
  _stageData: {
    label: 'Stage Data',
    key: '_stageData',
  },
  _reportsTo: {
    label: 'Reports To',
    key: '_reportsTo',
  },
  _internalupdatedat: {
    label: 'Last Updated',
    key: '_internalupdatedat',
    format: formatTypes.timestamp,
  },
  ownerId: {
    label: 'Owner',
    key: 'ownerId',
  },
  'owner.id': {
    key: 'owner.id',
    label: 'Owner',
    format: formatTypes.select,
  },
}

export const canonicalFieldKeys = {
  name: 'name',
  nextstep: 'nextstep',
  closedate: 'closedate',
  amount: 'amount',
  stagename: 'stagename',
  forecastcategoryname: 'forecastcategoryname',
  lastmodifieddate: 'lastmodifieddate'
}
