import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Editable, withReact, useSlate, Slate, ReactEditor, useSelected, useFocused } from 'slate-react'
import { Editor, Transforms, createEditor, Node, Text, Range } from 'slate'
import { withHistory } from 'slate-history'
import { withTitleLayout } from './plugins/withTitleLayout'
import { withOrderedListAttributes } from './plugins/withOrderedListAttributes'
import { withMentions } from './plugins/withMentions'
import { Portal } from '../portal'
import classNames from 'classnames'
import isHotkey from 'is-hotkey'
import get from 'lodash/get'
import { noteSearchOpportunities } from '../../services/opportunityService'
import { noteSearchedOpportunitiesSelector } from '../../selectors'
import { clearNoteSearchedOpportunities } from '../../actions'
import { Popover } from '@material-ui/core'
import Icon, { iconType } from '../icon'
import iconBold from '../../assets/icon_bold.png'
import iconItalic from '../../assets/icon_italic.png'
import iconUnderline from '../../assets/icon_underline.png'
import iconOL from '../../assets/icon_ol.png'
import iconUL from '../../assets/icon_ul.png'
import { ResourceTypes } from '../../services/noteService'
import SilentErrorBoundary from '../silentErrorBoundary'
import { subscribe } from '../../gml/eventBus'
import { eventTypes } from '../../gml/eventBus/eventTypes'

const insertMention = (editor, mentionText, resourceId, resourceType) => {
  const mention = { type: 'mention', mentionText, resourceId, resourceType, children: [{ text: '' }] }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

const Element = (props) => {
  const { attributes, children, element, onClick } = props
  switch (element.type) {
    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>
    case 'unordered-list':
      return <ul {...attributes}>{children}</ul>
    case 'list-item':
      return <li {...attributes} data-depth={get(element, 'depth', 0)} data-depth-reset={get(element, 'depthReset', false)}>{children}</li>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'title':
      return <h2 {...attributes} className="title">{children}</h2>
    case 'mention':
      return <MentionElement {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const MentionElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      contentEditable={false}
      className=""
      style={{
        padding: '1px 3px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#eee',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
      }}>
      #
      {get(element, 'mentionText', '')}
      {children}
    </span>
  )
}

function espaceSpecialChars(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const RichTextEditor = (props) => {
  const {
    defaultValue = [],
    value,
    onChange,
    toolbarPosition = 'top',
    className = 'w-full h-full bg-color-ffffff border border-color-2e5bff-08 rounded-lg shadow-md font-normal',
    editorWrapperClassName = 'p-8 h-full',
    placeholder = 'Untitled',
    useTitleLayout = false,
    useMentions = false,
    readOnly = false,
    statusText,
  } = props

  const dispatch = useDispatch()
  const portalRef = useRef()
  const [val, setVal] = useState(defaultValue)
  const [target, setTarget] = useState()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [readOnlyToolbar, setReadOnlyToolbar] = useState(false)

  const opportunities = useSelector(noteSearchedOpportunitiesSelector)

  const editor = useMemo(() => {
    let e = withOrderedListAttributes(withHistory(withReact(createEditor())))
    if (useTitleLayout) {
      e = withTitleLayout(e)
    }
    if (useMentions) {
      e = withMentions(e)
    }
    return e
  }, [])

  useEffect(() => {
    if (value.length > 0) {
      setVal(value)
    }
  }, [value])

  useEffect(() => {
    if (!readOnly) {
      setTimeout(() => {
        ReactEditor.focus(editor)
        Transforms.select(editor, [value.length - 1])
      }, 500)
    }
  }, [readOnly])

  const renderElement = useCallback((props) => <Element {...props} />, [])

  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  const onOpportunityClick = useCallback((e, index) => {
    e.preventDefault()
    if (target) {
      Transforms.select(editor, target)
      insertMention(editor, get(opportunities, `[${index}].name`, ''), get(opportunities, `[${index}].id`, ''), ResourceTypes.OPPORTUNITY)
      setTarget(null)
    }
  }, [search, target, opportunities])

  const scrollIntoView = useCallback((parent, child) => {
    try {
      const parentRect = parent.getBoundingClientRect()
      const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
      }
      const childRect = child.getBoundingClientRect()
      const isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height)
      if (!isViewable) {
        parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  const isBlockActive = useCallback((format) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === format,
    })
    return !!match
  }, [])

  const toggleBlock = useCallback((format) => {
    const LIST_TYPES = ['ordered-list', 'unordered-list']
    const isList = LIST_TYPES.includes(format)
    const active = isBlockActive(format)

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes(n.type),
      split: true,
    })

    let editorType
    if (active) {
      editorType = 'paragraph'
    } else {
      editorType = isList ? 'list-item' : format
    }

    Transforms.setNodes(editor, {
      type: editorType
    })

    if (!active && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }, [])

  const onKeyDown = useCallback((e) => {
    if (target) {
      let downIndex
      let upIndex
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault()
          downIndex = index >= opportunities.length - 1 ? 0 : index + 1
          portalRef && scrollIntoView(portalRef.current, document.getElementById(`oppMenuItem-${downIndex}`))
          setIndex(downIndex)
          break
        case 'ArrowUp':
          e.preventDefault()
          upIndex = index <= 0 ? opportunities.length - 1 : index - 1
          portalRef && scrollIntoView(portalRef.current, document.getElementById(`oppMenuItem-${upIndex}`))
          setIndex(upIndex)
          break
        case 'Tab':
        case 'Enter':
          e.preventDefault()
          Transforms.select(editor, target)
          insertMention(editor, get(opportunities, `[${index}].name`, ''), get(opportunities, `[${index}].id`, ''), ResourceTypes.OPPORTUNITY)
          setTarget(null)
          break
        case 'Escape':
          e.preventDefault()
          setTarget(null)
          break
        default:
      }
    } else {
      const HOTKEYS = {
        'mod+b': 'bold',
        'mod+i': 'italic',
        'mod+u': 'underline',
      }

      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, e)) {
          e.preventDefault()
          const format = HOTKEYS[hotkey]
          const marks = Editor.marks(editor)
          const active = marks ? marks[format] === true : false
          if (active) {
            Editor.removeMark(editor, format)
          } else {
            Editor.addMark(editor, format, true)
          }
        }
      }

      if (e.key === 'Tab') {
        e.preventDefault()

        const [li] = Editor.nodes(editor, { match: (n) => n.type === 'list-item' })
        if (li) {
          const maxDepth = 4
          const depth = get(li, '[0].depth', 0)

          const props = {
            depth: isHotkey('shift+tab', e) ? Math.max(0, depth - 1) : Math.min(maxDepth, depth + 1),
          }

          const [_, childPath] = li
          Transforms.setNodes(editor, props, { at: childPath })
        } else {
          // TODO: implement tabs within text
        }
      } else if (e.key === 'Enter') {
        const [li] = Editor.nodes(editor, { match: (n) => n.type === 'list-item' })
        if (li) {
          const depth = get(li, '[0].depth', 0)
          const [node, childPath] = li
          const text = get(node, 'children[0].text')
          if (!text) {
            e.preventDefault()
            if (depth === 0) {
              if (isBlockActive('ordered-list')) toggleBlock('ordered-list')
              else if (isBlockActive('unordered-list')) toggleBlock('unordered-list')
            } else {
              Transforms.setNodes(editor, { depth: Math.max(0, depth - 1) }, { at: childPath })
            }
          }
        } else {
          try {
            const domPoint = ReactEditor.toDOMPoint(editor, editor.selection.focus)
            const node = get(domPoint, '[0]')
            if (node) {
              const parent = get(node, 'parentElement')
              if (parent && parent.getBoundingClientRect && parent.getBoundingClientRect().top + 40 > window.innerHeight) {
                parent.scrollIntoView()
              }
            }
          } catch (err) {
            console.log(err)
          }
        }
      }
    }
  }, [index, search, target, opportunities, portalRef])

  useEffect(() => {
    if (target && portalRef.current) {
      const el = portalRef.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [editor, portalRef, target, index, search])

  useEffect(() => {
    dispatch(clearNoteSearchedOpportunities())
    if (search && search.length > 2) {
      const searchText = espaceSpecialChars(search)
      dispatch(noteSearchOpportunities(0, 10, searchText))
    }
  }, [search])

  const onChangeInternal = useCallback((newValue) => {
    setVal(newValue)
    onChange && onChange(newValue)

    const { selection } = editor

    const isSelectionInTitle = get(selection, 'anchor.path[0]') === 0 || get(selection, 'focus.path[0]') === 0
    setReadOnlyToolbar(isSelectionInTitle)

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/^#(\w+)?$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange)
        setSearch(beforeMatch[1])
        setIndex(0)
        return
      }
    }

    setTarget(null)
  }, [])

  const onClick = useCallback((e) => {
    if (!e.target.attributes['data-slate-node']) {
      return
    }

    // move cursor to end if user did not click into an existng node
    if (!editor.selection && !readOnly) {
      try {
        const lastNodeChildren = get(value[value.length - 1], 'children', [])
        if (lastNodeChildren.length) {
          const node = lastNodeChildren[lastNodeChildren.length - 1]
          const offset = Text.isText(node) ? node.text.length : 0
          Transforms.select(editor, { offset, path: [Math.max(value.length - 1, 0), 0] })
        } else {
          Transforms.select(editor, { offset: 0, path: [Math.max(value.length - 1, 0), 0] })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [value, editor, readOnly])

  const [textSelectorText, setTextSelectorText] = useState('Normal')
  const onTextSelectorChange = useCallback((format) => {
    switch (format) {
      case 'heading-one': setTextSelectorText('Heading 1'); break
      case 'heading-two': setTextSelectorText('Heading 2'); break
      default: setTextSelectorText('Normal'); break
    }
  }, [])

  const onBlur = useCallback((e) => {
    e.preventDefault()
    try {
      Transforms.select(editor, { offset: 0, path: [0, 0] })
      ReactEditor.blur(editor)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    const { unsubscribe } = subscribe(eventTypes.newNote, (args) => {
      setTimeout(() => {
        try {
          ReactEditor.focus(editor)
          Transforms.select(editor, [1])
        } catch (err) {
          console.log(err)
        }
      }, 500)
    })

    return () => unsubscribe()
  }, [])

  return (
    <div onClick={onClick} className={className}>
      <SilentErrorBoundary>
        <Slate editor={editor} value={val} onChange={onChangeInternal}>
          {toolbarPosition === 'top' && (
            <Toolbar
              toolbarPosition={toolbarPosition}
              textSelectorText={textSelectorText}
              statusText={statusText}
              readOnly={readOnlyToolbar}
              onTextSelectorChange={onTextSelectorChange} />
          )}
          <div className={editorWrapperClassName}>
            <Editable
              className="slate-editor h-full"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              spellCheck
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              readOnly={readOnly} />
            {target
              && (
                <Portal>
                  <div
                    ref={portalRef}
                    className="overflow-y-auto"
                    style={{
                      top: '-9999px',
                      left: '-9999px',
                      position: 'absolute',
                      zIndex: 999999999,
                      background: 'white',
                      borderRadius: '4px',
                      boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                      maxHeight: 225,
                      minHeight: 40,
                    }}>
                    {opportunities.length === 0 && <div className="px-3 py-2" style={{}}>...</div>}
                    {opportunities.map((o, i) => (
                      <div
                        key={`oppMenuItem-${i}`}
                        id={`oppMenuItem-${i}`}
                        onMouseOver={() => setIndex(null)}
                        onMouseDown={(e) => onOpportunityClick(e, i)}
                        className={classNames('px-3 py-2 hover:bg-gradient-green hover:text-color-ffffff',
                          { 'bg-color-transparent': i !== index },
                          { 'bg-gradient-green text-color-ffffff': i === index })}>
                        {get(o, 'name')}
                      </div>
                    ))}
                  </div>
                </Portal>
              )}
          </div>
          {toolbarPosition === 'bottom' && (
            <Toolbar
              toolbarPosition={toolbarPosition}
              textSelectorText={textSelectorText}
              onTextSelectorChange={onTextSelectorChange}
              readOnly={readOnly} />
          )}
        </Slate>
      </SilentErrorBoundary>
    </div>
  )
}

const Toolbar = ({ textSelectorText, onTextSelectorChange, toolbarPosition = 'top', readOnly = false, statusText }) => {
  const className = classNames(
    'flex items-center justify-between bg-gradient-dark-gray text-color-ffffff px-4',
    { 'rounded-t-lg': toolbarPosition === 'top' },
    { 'rounded-b-lg': toolbarPosition === 'bottom' }
  )
  return (
    <div className={className}>
      <div className={classNames('flex items-center', { 'pointer-events-none opacity-50': readOnly })} style={{ height: 50 }}>
        <TextSelector text={textSelectorText} onChange={onTextSelectorChange} />
        <div className="flex items-center h-full mx-1">
          <MarkButton format="bold" />
          <MarkButton format="italic" />
          <MarkButton format="underline" />
        </div>
        <div className="flex items-center h-full mx-1">
          <BlockButton format="ordered-list" />
          <BlockButton format="unordered-list" />
        </div>
      </div>
      {statusText && <div className="ml-2 whitespace-nowrap truncate text-size-12px text-color-edeeee-60">{statusText}</div>}
    </div>
  )
}

const MarkButton = ({ format }) => {
  const editor = useSlate()

  const icon = useMemo(() => {
    switch (format) {
      case 'bold': return iconBold
      case 'italic': return iconItalic
      case 'underline': return iconUnderline
      default:
    }
  }, [format])

  const isActive = useCallback(() => {
    const marks = Editor.marks(editor)
    const active = marks ? marks[format] === true : false
    return active
  }, [])

  const toggleMark = useCallback(() => {
    if (isActive()) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }, [])

  const onClick = useCallback((e) => {
    e.preventDefault()
    toggleMark()
  }, [toggleMark])

  return (
    <button
      onMouseDown={onClick}
      className={classNames('h-full flex justify-center items-center', { 'bg-color-e0e7ff-20 hover:bg-color-e0e7ff-30': isActive() })}
      style={{ width: 50 }}>
      {icon && <Icon type={iconType.IMAGE} src={icon} style={{ height: '40%' }} />}
    </button>
  )
}

const BlockButton = ({ format }) => {
  const editor = useSlate()

  const icon = useMemo(() => {
    switch (format) {
      case 'ordered-list': return iconOL
      case 'unordered-list': return iconUL
      default:
    }
  }, [format])

  const isActive = useCallback(() => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === format,
    })
    return !!match
  }, [])

  const toggleBlock = useCallback(() => {
    const LIST_TYPES = ['ordered-list', 'unordered-list']
    const isList = LIST_TYPES.includes(format)
    const active = isActive()

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes(n.type),
      split: true,
    })

    let editorType
    if (active) {
      editorType = 'paragraph'
    } else {
      editorType = isList ? 'list-item' : format
    }

    Transforms.setNodes(editor, {
      type: editorType
    })

    if (!active && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }, [])

  const onClick = useCallback((e) => {
    e.preventDefault()
    toggleBlock()
  }, [toggleBlock])

  return (
    <button
      onMouseDown={onClick}
      className={classNames('h-full flex justify-center items-center', { 'bg-color-e0e7ff-20 hover:bg-color-e0e7ff-30': isActive() })}
      style={{ width: 50 }}>
      {icon && <Icon type={iconType.IMAGE} src={icon} style={{ height: '40%' }} />}
      {!icon && <span>H</span>}
    </button>
  )
}

const TextSelector = ({ text, onChange }) => {
  const editor = useSlate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const width = 200
  const maxHeight = 500

  const isActive = useCallback((format) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === format,
    })
    return !!match
  }, [])

  const toggleBlock = useCallback((format) => {
    const LIST_TYPES = ['ordered-list', 'unordered-list']
    const isList = LIST_TYPES.includes(format)
    const active = isActive(format)

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes(n.type),
      split: true,
    })

    let formatType
    if (active) {
      formatType = 'paragraph'
    } else {
      formatType = isList ? 'list-item' : format
    }

    Transforms.setNodes(editor, {
      type: formatType
    })

    if (!active && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }, [])

  const handleClick = useCallback((e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onItemClick = useCallback((e, format) => {
    e.preventDefault()

    onChange && onChange(format)

    if (isActive('heading-one')) {
      if (format === 'normal') {
        toggleBlock('heading-one')
      } else if (format === 'heading-two') {
        toggleBlock('heading-one')
        toggleBlock('heading-two')
      }
    } else if (isActive('heading-two')) {
      if (format === 'normal') {
        toggleBlock('heading-two')
      } else if (format === 'heading-one') {
        toggleBlock('heading-two')
        toggleBlock('heading-one')
      }
    } else {
      toggleBlock(format)
    }

    handleClose()
  }, [])

  useEffect(() => {
    if (onChange) {
      if (isActive('heading-one')) onChange('heading-one')
      else if (isActive('heading-two')) onChange('heading-two')
      else onChange('normal')
    }
  })

  return (
    <div style={{ width: 100 }}>
      <div onMouseDown={handleClick} className="flex items-center h-full mr-2 select-none" style={{ height: 50 }}>
        <div className="text-color-ffffff whitespace-nowrap">{text}</div>
        <Icon type={iconType.FONTAWESOME} iconName="chevron-down" size="xs" className="ml-2" />
      </div>
      <Popover
        style={{ zIndex: 9999999999, marginTop: 18 }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableScrollLock={true}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="flex flex-col" style={{ width, maxHeight }}>
          <div
            onMouseDown={(e) => onItemClick(e, 'normal')}
            className={classNames('m-0 px-3 py-2 hover:bg-gradient-green hover:text-color-ffffff cursor-pointer', { 'text-color-2e5bff': false })}>
            <span>Normal</span>
          </div>
          <div
            onMouseDown={(e) => onItemClick(e, 'heading-one')}
            className={classNames('m-0 px-3 py-2 hover:bg-gradient-green hover:text-color-ffffff cursor-pointer', { 'text-color-2e5bff': isActive('heading-one') })}>
            <h1 className="m-0 p-0">Heading 1</h1>
          </div>
          <div
            onMouseDown={(e) => onItemClick(e, 'heading-two')}
            className={classNames('m-0 px-3 py-2 hover:bg-gradient-green hover:text-color-ffffff cursor-pointer', { 'text-color-2e5bff': isActive('heading-two') })}>
            <h2 className="m-0 p-0">Heading 2</h2>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default RichTextEditor
