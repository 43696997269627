import React, { useEffect, useCallback, useRef, useState } from 'react'
import DealCard from './dealCard'
import debounce from 'lodash/debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useDeals } from '../../context/deals'
import SectionHeader from '../common/sectionHeader'

const DealsSlider = (props) => {
  const [enablePrevBtn, setEnablePrevBtn] = useState(false)
  const [enableNextBtn, setEnableNextBtn] = useState(false)

  const { isFetching, deals } = useDeals()

  const scrollView = useRef(null)
  const [canShowNextPrevBtns, setCanShowNextPrevBtns] = useState(false)

  const getNearestCardIndex = useCallback(() => {
    const { scrollLeft } = scrollView.current
    return Math.round(scrollLeft / cardScrollViewSettings.cardOffsetWidth)
  }, [])

  const scrollToCardIndex = useCallback((index) => {
    scrollView.current && scrollView.current.scroll({ left: index * cardScrollViewSettings.cardOffsetWidth })
  }, [])

  const debounceScrollEnded = debounce(scrollToCardIndex, 750)

  const handleScroll = useCallback((e) => {
    const { scrollLeft, offsetWidth, scrollWidth } = scrollView.current
    const enableNext = offsetWidth + scrollLeft < scrollWidth
    setEnablePrevBtn(scrollLeft > 0)
    setEnableNextBtn(enableNext)
    if (enableNext) {
      debounceScrollEnded(getNearestCardIndex())
    }
  }, [])

  useEffect(() => {
    if (deals.length > 0) {
      setCanShowNextPrevBtns(!isFetching && (scrollView.current && scrollView.current.scrollWidth > scrollView.current.clientWidth))
      handleScroll()
    }
  }, [deals, isFetching, scrollView])

  const prev = useCallback(() => {
    window.analytics.track('Deals Scroller', { action: 'Back' })
    scrollToCardIndex(getNearestCardIndex() - 1)
  }, [])

  const next = useCallback(() => {
    window.analytics.track('Deals Scroller', { action: 'Forward' })
    scrollToCardIndex(getNearestCardIndex() + 1)
  }, [])

  const sharedBtnClasses = 'flex items-center bg-color-fcfcfc rounded-full cursor-pointer pointer-events-auto'
  const prevBtnClasses = classNames(sharedBtnClasses, 'ml-3', { invisible: !enablePrevBtn }, { visible: enablePrevBtn })
  const nextBtnClasses = classNames(sharedBtnClasses, '-mr-13', { invisible: !enableNextBtn }, { visible: enableNextBtn })

  return (
    <div className="relative">

      {canShowNextPrevBtns && (
        <div className="-ml-10 -mr-6 z-20 absolute flex justify-between w-full pointer-events-none select-none" style={{ bottom: 86 }}>
          <div
            className={prevBtnClasses}
            style={{ ...nextPrevBtnStyle }}
            onClick={prev}>
            <FontAwesomeIcon
              icon="chevron-left"
              size="2x"
              color="#c9ced0"
              className="mx-auto" />
          </div>
          <div
            className={nextBtnClasses}
            style={{ ...nextPrevBtnStyle }}
            onClick={next}>
            <FontAwesomeIcon
              icon="chevron-right"
              size="2x"
              color="#c9ced0"
              className="mx-auto" />
          </div>
        </div>
      )}

      <div className="-ml-10 -mr-6 my-6">
        <SectionHeader
          className="mx-10"
          title="Deals to Review"
          description="Deals that need your attention based on insights and historical data." />
        <div
          ref={scrollView}
          className="mt-3 pb-2 inline-flex max-w-full overflow-y-hidden no-scrollbar"
          style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}
          onScroll={handleScroll}>
          {deals.map((opportunity, index) => (
            <DealCard
              index={index}
              key={`dealsSlider-dealCard-${opportunity.id}-${index}`}
              opportunity={opportunity} />
          ))}
        </div>
      </div>

    </div>
  )
}

export default DealsSlider

const cardWidth = 325
const cardHeight = 200
const cardMarginRight = 16

const cardScrollViewSettings = {
  cardWidth,
  cardHeight,
  cardMarginRight,
  cardOffsetWidth: cardWidth + cardMarginRight
}

const nextPrevBtnStyle = {
  width: '48px',
  height: '48px',
  fontSize: '13px',
  border: '1px solid rgb(227, 232, 250)',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
}
