import React, { useRef, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import ChartText from './chartText'
import { getMinMax } from '../lib/gChart'
import { getTextWidth } from '../lib/text'
import { FunnelChartConfig, funnelChartConfigPropTypes } from '../config/funnelChartConfig'
import ConditionalWrapper from './conditionalWrapper'
import Tooltip from './tooltip'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import get from 'lodash/get'
import merge from 'lodash/merge'
import sum from 'lodash/sum'
import { createTooltipContent, controlTypes } from '../renderer/gmlRenderer'
import { FunnelChartFormatterError } from '../../errors'
import useRefResize from '../../hooks/useRefResize'

const FunnelChart = (props) => {
  const {
    getRendererProps,
    config = {},
    name,
    styleNames,
    childIndex,
    hide = false,
    series = [],
    barLabels = false,
    barLabelFormatter,
    tooltips = false,
    colors,
  } = props

  PropTypes.checkPropTypes(FunnelChart.propTypes, props, 'prop', 'FunnelChart')

  const containerRef = useRef()
  const svgRef = useRef()
  const [svgWidth, setSvgWidth] = useState(0)
  const [svgHeight, setSvgHeight] = useState(0)

  const mergedConfig = useMemo(() => {
    const c = cloneDeep(FunnelChartConfig)
    c.orientation = 'vertical'

    merge(c, config)

    setSvgHeight(c.height)

    return c
  }, [])

  const seriesMinMax = useMemo(() => {
    const minMax = getMinMax(series)
    return {
      min: 0,
      max: minMax.max
    }
  }, [series])

  const measured = useMemo(() => {
    const c = mergedConfig
    const m = {
      barLabels: {},
      barLabelMaxWidth: 0,
    }

    function getMeasuredText(data, formatter, style, seriesIndex, dataIndex) {
      const { fontWeight, fontSize, fontFamily } = style
      let text = ''
      try {
        text = formatter ? formatter(data, seriesIndex, dataIndex) : `${seriesIndex}`
      } catch (err) {
        throw new FunnelChartFormatterError(formatter.toString(), err)
      }
      const width = getTextWidth(text, fontWeight, fontSize, fontFamily)
      return {
        text,
        width,
        data,
        seriesIndex,
        dataIndex
      }
    }

    series.map((data, seriesIndex) => {
      if (barLabels) {
        const label = getMeasuredText(data, barLabelFormatter, c.barLabel.style, seriesIndex)
        m.barLabels[seriesIndex] = label
        m.barLabelMaxWidth = Math.max(m.barLabelMaxWidth, label.width)
      }
    })

    if (barLabels) {
      m.barLabelMaxWidth += c.barLabel.offset.width
    }

    return m
  }, [svgWidth, mergedConfig, seriesMinMax])

  const chartRect = useMemo(() => {
    const rect = {
      x: 0,
      y: mergedConfig.padding.top,
      width: 0, // calculated later
      height: mergedConfig.height - (mergedConfig.padding.top + mergedConfig.padding.bottom),
    }

    rect.width = Math.max(rect.width, 0)
    rect.height = Math.max(rect.height, 0)

    return rect
  }, [svgWidth, mergedConfig, measured])

  const barData = useMemo(() => {
    const b = []
    const chartRectOverride = {}
    const strokeWidth = mergedConfig.bar.style.strokeWidth
    let x = chartRect.x + (strokeWidth / 2)
    let y = chartRect.y + chartRect.height - (strokeWidth / 2)
    const height = chartRect.height - ((strokeWidth / 2) * series.length) - (mergedConfig.bar.spacing * (series.length - 1))
    const seriesSum = sum(series)
    const delta = mergedConfig.bar.topBottomWidthDelta / 2
    const barAreaWidth = Math.max(measured.barLabelMaxWidth + mergedConfig.bar.topBottomWidthDelta)

    const l1 = chartRect.x
    const l2 = chartRect.x + delta
    const r1 = chartRect.x + barAreaWidth - delta
    const r2 = chartRect.x + barAreaWidth
    function extrapolatePoint(p1, p2, y, offsetX = 0) {
      const perc = (y - chartRect.y) / (chartRect.height - (strokeWidth / 2))
      return p1 + ((p2 - p1) * perc) + offsetX
    }

    series.map((data, seriesIndex) => {
      const perc = data / seriesSum
      const y1 = y
      const y2 = y1 - (perc * height) + (strokeWidth / 2)
      const pt1 = { x: extrapolatePoint(l1, l2, y1, (strokeWidth / 2)), y: y1 } // bottom left
      const pt2 = { x: extrapolatePoint(l1, l2, y2, (strokeWidth / 2)), y: y2 } // top left
      const pt3 = { x: extrapolatePoint(r2, r1, y2), y: y2 } // top right
      const pt4 = { x: extrapolatePoint(r2, r1, y1), y: y1 } // bottom right

      b.push({
        data,
        pt1,
        pt2,
        pt3,
        pt4,
        style: mergedConfig.bar.style,
        seriesIndex,
        dataIndex: undefined
      })

      y = y2 - strokeWidth - mergedConfig.bar.spacing
    })

    x += barAreaWidth

    chartRectOverride.width = x - chartRect.x
    const translateX = (svgWidth - chartRectOverride.width) / 2
    chartRectOverride.x = chartRect.x + translateX
    b.map((bar) => {
      bar.pt1.x += translateX
      bar.pt2.x += translateX
      bar.pt3.x += translateX
      bar.pt4.x += translateX
    })

    return {
      bars: b,
      chartRectOverride
    }
  }, [svgWidth, mergedConfig, measured, chartRect, seriesMinMax])

  // the main config object
  const cfg = useMemo(() => {
    const c = mergedConfig
    c.props = props
    c.seriesMinMax = seriesMinMax
    c.measured = measured
    c.bars = barData.bars
    c.chartRect = {
      ...chartRect,
      ...barData.chartRectOverride,
    }

    PropTypes.checkPropTypes(funnelChartConfigPropTypes, c, 'prop', 'FunnelChart')

    // console.log(c)
    return c
  }, [svgWidth, mergedConfig, seriesMinMax, measured, chartRect, barData])

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-funnelchart',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const renderBars = useCallback(() => {
    let interactive = false
    const { currentObject } = getRendererProps()
    const obj = find(get(currentObject, 'childrenList', get(currentObject, 'children', [])), (c) => c.type === controlTypes.TooltipContent)
    if (obj && find(get(obj, 'attributesList', get(obj, 'attributes', [])), (attr) => attr.key === 'interactive' && attr.value === '{true}')) {
      interactive = true
    }

    return barData.bars.map((bar) => {
      const { seriesIndex, dataIndex, data, pt1, pt2, pt3, pt4, style } = bar

      const result = tooltips ? createTooltipContent(getRendererProps, { data, _seriesIndex: seriesIndex, _dataIndex: dataIndex }) : { tree: {} }
      const { tree, content } = result
      const disabled = get(find(tree.attributesList, (a) => a.key === 'disabled') || {}, 'value', false)

      const polygonStyle = { ...style }

      if (typeof colors === 'string') {
        polygonStyle.fill = colors
        polygonStyle.stroke = colors
      } else if (Array.isArray(colors)) {
        const color = get(colors, `[${seriesIndex}]`, '#000000')
        polygonStyle.fill = color
        polygonStyle.stroke = color
      }

      return (
        <ConditionalWrapper
          key={`${name}-bar-${seriesIndex}-${dataIndex}`}
          condition={tooltips && content}
          wrapper={(children) => <Tooltip disabled={disabled} content={content} interactive={interactive}>{children}</Tooltip>}>
          <g className="g-funnelchart-bar-group focus:outline-none">
            <polygon
              className={`g-funnelchart-bar series-${seriesIndex} data-${dataIndex}`}
              points={`${pt1.x} ${pt1.y} ${pt2.x} ${pt2.y} ${pt3.x} ${pt3.y} ${pt4.x} ${pt4.y}`}
              style={polygonStyle} />
          </g>
        </ConditionalWrapper>
      )
    })
  }, [barData])

  const renderBarLabelsInternal = useCallback(() => {
    if (svgRef.current && barLabels) {
      const bars = get(cfg, 'bars', [])
      return (
        bars.map((bar) => {
          const { seriesIndex, dataIndex } = bar
          const label = cfg.props.grouped ? cfg.measured.barLabels[`${seriesIndex}-${dataIndex}`].text : cfg.measured.barLabels[seriesIndex].text
          const attributes = {
            style: {
              ...cfg.barLabel.style
            }
          }
          attributes.x = bar.pt1.x + ((bar.pt4.x - bar.pt1.x) / 2) + cfg.barLabel.offset.x
          attributes.y = bar.pt2.y + ((bar.pt1.y - bar.pt2.y) / 2) + cfg.barLabel.offset.y
          return (
            <g key={`barLabel-${seriesIndex}-${dataIndex}`} className="g-charttext-group">
              <ChartText {...attributes}>{label}</ChartText>
            </g>
          )
        })
      )
    }
  }, [svgRef, cfg])

  const handleResize = useCallback(({ width }) => {
    setSvgWidth(width)
  }, [])

  useRefResize(containerRef, handleResize)

  return (
    <div ref={containerRef} {...attributes} style={{ height: svgHeight }}>
      <svg ref={svgRef} width="100%" height={svgHeight}>
        {props.debug && (
          <rect
            x={cfg.chartRect.x}
            y={cfg.chartRect.y}
            width={cfg.chartRect.width}
            height={cfg.chartRect.height}
            style={{ fill: 'red', opacity: 0.5 }} />
        )}

        {renderBars()}
        {renderBarLabelsInternal()}
      </svg>
    </div>
  )
}

FunnelChart.propTypes = {
  getRendererProps: PropTypes.func,
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.number),
  barLabels: PropTypes.bool,
  barLabelFormatter: PropTypes.func,
  tooltips: PropTypes.bool,
  colors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

export default FunnelChart
