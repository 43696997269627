import React from 'react'
import Header from '../header/header'
import RepPipelineCanvas from '../canvas/repPipelineCanvas'
import RepScorecard from '../scorecard/repScorecard'
import { useLoggedInUser } from '../../context/loggedInUser'

const PerformanceForRep = (props) => {
  const { loggedInUserId: userId } = useLoggedInUser()

  return (
    <>
      <Header
        text="Performance"
        showSearch={false} />

      <div className="main-body" style={{ minHeight: '100vh' }}>

        <RepPipelineCanvas
          userId={userId} />

        <RepScorecard
          userId={userId} />

      </div>
    </>
  )
}

export default PerformanceForRep
