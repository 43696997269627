import { useEffect, useRef } from 'react'
import { useDrag, useDrop, useDragLayer } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

export function useDragAndDrop({ key, index, type, onMove, horizontal = true, disabled = false }) {
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      if (horizontal) {
        // Determine rectangle on screen
        const hoverBoundingRect = (ref.current && ref.current.getBoundingClientRect()) || {}

        // Get vertical middle
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientX = (clientOffset).x - hoverBoundingRect.left

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging left, only move when the cursor is below 50%
        // When dragging right, only move when the cursor is above 50%

        // Dragging right
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return
        }

        // Dragging left
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return
        }
      } else {
        // Determine rectangle on screen
        const hoverBoundingRect = (ref.current && ref.current.getBoundingClientRect()) || {}

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
      }

      // Time to actually perform the action
      onMove && onMove(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: () => {
      const rect = ref.current?.getBoundingClientRect() ?? { width: 0, height: 0 }
      const { width, height } = rect
      return {
        id: key,
        index,
        size: {
          width,
          height,
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const dragLayer = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
    }
  })

  useEffect(() => {
    // this hides the preview so that you can create a custom drag layer
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  if (!disabled) {
    // Join the 2 refs together into one (both draggable and can be dropped on)
    drag(drop(ref))
  }

  return {
    ref,
    handlerId,
    isDragging,
    dragLayer,
  }
}
