import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getFieldFromObject } from '../components/fieldRenderers/helpers'
import { useAuth } from '../context/auth'
import { find } from 'lodash'
import useRoles from './useRoles'
import useCanWrite from './useCanWrite'
import { usePermissions } from '../context/permissions'
import { permissionNames } from '../constants/permissionNames'
import { canopyFields } from '../components/fieldRenderers/constants'

const useIsReadOnly = () => {
  const { checkPermission } = usePermissions()
  const { isManagerRole } = useRoles()
  const { canWrite } = useCanWrite()
  const { actingUserId } = useAuth()

  const objectDefinitions = useSelector((state) => state.objectDefinitions)

  const fieldPermissionsList = useMemo(() => {
    return objectDefinitions?.opportunity?.fieldPermissionsList ?? []
  }, [objectDefinitions])

  const isReadOnly = useCallback((
    opportunity,
    fieldDefinition
  ) => {
    const ownerField = getFieldFromObject(opportunity, canopyFields._owner.key)
    const dealOwnerId = ownerField?.value?.id ?? ''
    const isOwnersDeal = dealOwnerId === actingUserId
    const permission = find(fieldPermissionsList, (p) => p.field === fieldDefinition.to && p.integrationSourceType === fieldDefinition.integrationSourceType)
    const canManagerEdit = permission?.canManagerEdit ?? false
    const canEdit = (isOwnersDeal && ((permission?.canEdit) || false)) || (canManagerEdit && isManagerRole)
    const canUpdateDeal = checkPermission(permissionNames.CanUpdateDeal)
    return !canUpdateDeal || !canWrite || !canEdit
  }, [canWrite, isManagerRole, actingUserId, fieldPermissionsList, checkPermission])

  return {
    canWrite,
    isReadOnly
  }
}

export default useIsReadOnly
