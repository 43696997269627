import React from 'react'
import Chart from '../svg/chart'

const EmptyGoals = (props) => {
  const { message } = props
  return (
    <div className="flex justify-start">
      <div className="flex justify-between items-center">
        <div className="mr-4">
          <Chart />
        </div>
        <div className="flex flex-col">
          <div className="text-size-24px text-color-151d49 whitespace-nowrap truncate">No Goals</div>
          {message && <div className="text-size-14px text-color-4e5d7e whitespace-nowrap truncate">{message}</div>}
        </div>
      </div>
    </div>
  )
}

export default EmptyGoals
