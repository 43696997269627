import React from 'react'
import PropTypes from 'prop-types/prop-types'
import ChartText from '../controls/chartText'
import { pluginTypes } from '.'
import { strokePropTypes, offsetPropTypes, svgTextPropTypes } from '../propTypes/common'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import { getTextWidth } from '../lib/text'

export const defaultHorizontalConfig = {
  marker: {
    offset: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    style: {
      stroke: '#333333',
      strokeOpacity: 0.5,
      strokeLinecap: 'round',
      strokeWidth: 5,
    },
  },
  label: {
    offset: {
      x: 0,
      y: -5,
    },
    style: {
      textAnchor: 'middle',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#000',
    },
  },
}

export const defaultVerticalConfig = {
  marker: {
    offset: {
      x: 0,
      y: 0,
      width: 20,
      height: 0,
    },
    style: {
      stroke: '#333333',
      strokeOpacity: 0.5,
      strokeLinecap: 'round',
      strokeWidth: 5,
    },
  },
  label: {
    offset: {
      x: -8,
      y: 0,
    },
    style: {
      textAnchor: 'end',
      fontWeight: 'bold',
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fill: '#000',
    },
  },
}

const pluginConfigPropTypes = {
  marker: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(strokePropTypes),
  }),
  label: PropTypes.shape({
    offset: PropTypes.shape(offsetPropTypes),
    style: PropTypes.shape(svgTextPropTypes),
  }),
  hide: PropTypes.bool,
}

export const LineMarkerPlugin = (config) => {
  const { props, bars = [], chartRect, axisData } = config

  const { name, maxData, pluginPadding } = props

  const pluginPaddingY = pluginPadding ?? 0

  const orientation = get(props, 'orientation', 'horizontal')
  const grouped = get(props, 'grouped', false)
  const stacked = get(props, 'stacked', false)
  const c = orientation === 'horizontal' ? { ...defaultHorizontalConfig } : { ...defaultVerticalConfig }
  const pluginConfig = {
    ...c,
    ...get(config, `plugins.${pluginTypes.lineMarker}`, {})
  }
  PropTypes.checkPropTypes(pluginConfigPropTypes, pluginConfig, 'prop', 'LineMarkerPlugin')

  const { marker, label } = pluginConfig

  const positiveBars = filter(bars, (b) => !b.isNegativeAxis)
  const lineMarkerValue = get(pluginConfig, 'config.value')
  const maxLine = maxBy(positiveBars, (b) => get(b, 'data', 0))

  let maxValue = maxData
  if (!maxData) {
    if (stacked) {
      maxValue = axisData.max
    } else {
      maxValue = get(maxLine, 'data', 0)
    }
  }

  const textAttributes = {
    style: {
      ...label.style
    }
  }

  const hide = get(pluginConfig, 'config.hide', false)

  if (hide) {
    return <React.Fragment key={`linemarker-${name}`} />
  } else if (Array.isArray(lineMarkerValue)) {
    if (stacked) {
      if (orientation === 'vertical') {
        return lineMarkerValue.map((v, index) => {
          const bar = find(bars, (b) => b.seriesIndex === index)
          if (!bar) {
            return <React.Fragment key={`linemarker-${name}-${index}-${v}`} />
          }
          let perc = maxValue === 0 ? 0 : v / maxValue
          if (isNaN(perc)) perc = 0

          let x1
          let x2
          const h = chartRect.height
          const y = chartRect.y + (h * (1 - perc))

          x1 = get(bar, 'pt1.x', 0) - (bar.style.strokeWidth / 2) - get(pluginConfig, 'marker.offset.width', 0)
          x2 = get(bar, 'pt1.x', 0) + (bar.style.strokeWidth / 2) + get(pluginConfig, 'marker.offset.width', 0)

          const y1 = y > pluginPaddingY ? y : (pluginPaddingY - y)
          const y2 = y > pluginPaddingY ? y : (pluginPaddingY - y)

          textAttributes.x = x1 + label.offset.x
          textAttributes.y = y1 + label.offset.y

          if (pluginConfig.config.marker && pluginConfig.config.marker.offset && pluginConfig.config.marker.offset.x) {
            x1 += pluginConfig.config.marker.offset.x
            x2 += pluginConfig.config.marker.offset.x
            textAttributes.x += pluginConfig.config.marker.offset.x
          }

          const labelText = get(pluginConfig, `config.label[${index}]`, '')

          return (
            <g key={`linemarker-${name}-${index}-${v}`} className="g-plugin-linemarker g-plugin-linemarker-grouped-horizontal">
              <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                className="focus:outline-none pointer-events-none"
                style={{ ...marker.style && marker.style }} />
              {labelText && <ChartText {...textAttributes}>{labelText}</ChartText>}
            </g>
          )
        })
      }
    } else if (grouped) {
      if (orientation === 'horizontal') {
        return lineMarkerValue.map((v, index) => {
          const seriesBars = filter(bars, (b) => b.seriesIndex === index)
          const firstBar = get(seriesBars, '[0]')
          const lastBar = get(seriesBars, `[${seriesBars.length - 1}]`, firstBar)
          if (!firstBar) {
            return <React.Fragment key={`linemarker-${name}-${index}-${v}`} />
          }

          let perc = maxValue === 0 ? 0 : v / maxValue
          if (isNaN(perc)) perc = 0

          const x = chartRect.x + (chartRect.width * perc)

          const x1 = x
          const x2 = x
          const y1 = firstBar.pt1.y - firstBar.style.strokeWidth
          const y2 = lastBar.pt1.y + lastBar.style.strokeWidth

          textAttributes.x = x + label.offset.x
          textAttributes.y = chartRect.y + label.offset.y - (label.style.fontSize / 2)

          const labelText = get(pluginConfig, `config.label[${index}]`, '')

          return (
            <g key={`linemarker-${name}-${index}-${v}`} className="g-plugin-linemarker g-plugin-linemarker-grouped-vertical">
              <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                className="focus:outline-none pointer-events-none"
                style={{ ...marker.style && marker.style }} />
              {labelText && <ChartText {...textAttributes}>{labelText}</ChartText>}
            </g>
          )
        })
      }
    }
  } else {
    const v = get(pluginConfig, 'config.value', 0)
    const perc = maxValue === 0 ? 0 : v / maxValue

    let x1; let y1; let x2; let
      y2

    if (orientation === 'horizontal') {
      const x = chartRect.x + (chartRect.width * perc)

      x1 = x
      x2 = x
      y1 = chartRect.y + marker.offset.y
      y2 = chartRect.y + chartRect.height + marker.offset.height

      textAttributes.x = x + label.offset.x
      textAttributes.y = chartRect.y + label.offset.y - (label.style.fontSize / 2)

      const { fontWeight, fontSize, fontFamily } = textAttributes.style
      const width = getTextWidth(pluginConfig.config.label, fontWeight, fontSize, fontFamily)
      if (textAttributes.x - (width / 2) < 0) {
        textAttributes.style.textAnchor = 'begin'
      } else if (textAttributes.x + (width / 2) > chartRect.x + chartRect.width) {
        textAttributes.style.textAnchor = 'end'
      }
    } else if (orientation === 'vertical') {
      const h = positiveBars[0] && positiveBars[0].pt1 ? positiveBars[0].pt1.y : chartRect.height
      const y = (h * (1 - perc))

      let textWidth = 0
      try {
        const { fontWeight, fontSize, fontFamily } = label.style
        const text = pluginConfig.config.label
        textWidth = getTextWidth(text, fontWeight, fontSize, fontFamily)
      } catch (err) {
        console.log(err)
      }

      if (chartRect.x + marker.offset.x > textWidth - label.offset.x) {
        x1 = chartRect.x + marker.offset.x
        x2 = chartRect.x + chartRect.width + marker.offset.width
      } else {
        x1 = textWidth - label.offset.x

        const lastBar = get(bars, `[${get(bars, 'length', 0) - 1}]`, { pt2: {} })
        if (lastBar) {
          x2 = get(lastBar, 'pt2.x', 0) + marker.style.strokeWidth
        } else {
          x2 = chartRect.x + chartRect.width - marker.offset.width + marker.style.strokeWidth
        }
      }
      y1 = y > pluginPaddingY ? y : (pluginPaddingY - y)
      y2 = y > pluginPaddingY ? y : (pluginPaddingY - y)

      textAttributes.x = Math.max(chartRect.x + label.offset.x, textWidth)
      textAttributes.y = y1 + label.offset.y
    }

    let className = 'g-plugin-linemarker'
    if (stacked) {
      if (orientation === 'horizontal') {
        className += ' g-plugin-linemarker-stacked-vertical'
      } else {
        className += ' g-plugin-linemarker-stacked-horizontal'
      }
    } else if (orientation === 'horizontal') {
      className += ' g-plugin-linemarker-vertical'
    } else {
      className += ' g-plugin-linemarker-horizontal'
    }

    return (
      <g key={`linemarker-${name}-${v}`} className={className}>
        <line
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          className="focus:outline-none"
          style={{ ...marker.style && marker.style }} />
        <ChartText {...textAttributes}>{pluginConfig.config.label}</ChartText>
      </g>
    )
  }
}
