import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import pluralize from 'pluralize'

const Plural = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    count = 0,
    rawValue,
    children
  } = props

  PropTypes.checkPropTypes(Plural.propTypes, props, 'prop', 'Plural')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-plural',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const value = (children && pluralize(children, count)) || ''

  return rawValue ? value : <span {...attributes}>{value}</span>
}

Plural.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  count: PropTypes.number,
  rawValue: PropTypes.bool,
  children: PropTypes.string
}

export default Plural
