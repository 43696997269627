import React from 'react'
import classNames from 'classnames'

const circleDiameter = 40
const circleRadius = circleDiameter / 2

const Marker = (props) => {
  const {
    className = 'absolute z-1 bg-color-ffffff rounded-full top-0 right-0 text-center text-size-12px p-1',
    count,
    color = 'text-color-2e5bff'
  } = props

  return count !== undefined ? (
    <div
      className={classNames(className, color)}
      style={{ width: 20, height: 20, lineHeight: 1.1, transform: `translate(${circleRadius - 2}px, -${circleRadius + 2}px)` }}>
      {count}
    </div>
  ) : null
}

export default Marker
