import React, { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import Header from '../header/header'
import { useDispatch, useSelector } from 'react-redux'
import { testNlgService } from '../../services/nlgService'
import { apiRequestedSelector, apiFailedSelector, apiSuccessSelector } from '../../selectors'
import { testNlgServiceKey } from '../../actions/nlgService'
import AnimatedLoader from '../loaders/animatedLoader'
import Icon, { iconType } from '../icon'
import { clearNlgTester } from '../../actions'
import { get } from 'lodash'

const NLGTester = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearNlgTester())
    return () => {
      dispatch(clearNlgTester())
    }
  }, [])

  const [template, setTemplate] = useState(`- name = data.name
- if (name==null) {throw new Error("template error: name not defined")};
mixin structure
  | the name is "#{name}" and "#{name}" is awesome!
| #[+structure]`)

  const [dataJson, setDataJson] = useState(`{
  "name": "Canopy"
}`)

  const [flags, setFlags] = useState(`{
  "dateFormat": "D MMMM, YYYY"
}`)

  const [result, setResult] = useState('')

  const handleTemplateChange = useCallback((e) => {
    setTemplate(e.target.value)
  }, [])

  const handleDataJsonChange = useCallback((e) => {
    setDataJson(e.target.value)
  }, [])

  const handleFlagsChange = useCallback((e) => {
    setFlags(e.target.value)
  }, [])

  const isRunning = useSelector((state) => apiRequestedSelector(state, testNlgServiceKey))
  const success = useSelector((state) => apiSuccessSelector(state, testNlgServiceKey))
  const failed = useSelector((state) => apiFailedSelector(state, testNlgServiceKey))

  const handleTestClick = useCallback(() => {
    // const encoder = new TextEncoder("utf-8")
    // const dataJsonBinary = encoder.encode(dataJson)
    // const flagsBinary = encoder.encode(flags)
    const dataJsonBinary = btoa(dataJson)
    const flagsBinary = btoa(flags)
    dispatch(testNlgService(template, dataJsonBinary, flagsBinary))
  }, [template, dataJson, flags])

  const nlgTesterState = useSelector((state) => state.nlgTester)

  useEffect(() => {
    if (success) {
      setResult(JSON.stringify(get(nlgTesterState, 'result', {}), null, 2))
    } else if (failed) {
      setResult(JSON.stringify(get(nlgTesterState, 'error', {}), null, 2))
    }
  }, [nlgTesterState, success, failed])

  const textareaStyle = {
    fontFamily: 'Courier',
    fontSize: 12,
    whiteSpace: 'pre',
    overflowWrap: 'normal',
    overflow: 'scroll'
  }

  return (
    <>
      <Header text="NLG Tester" />
      <div className="main-body">
        <div className="flex justify-between w-full">
          <div className="flex-1 py-2">
            <div className="py-1">Template</div>
            <textarea
              disabled={isRunning}
              value={template}
              onChange={handleTemplateChange}
              className="w-full border border-color-d8d8d8 focus:outline-none p-2"
              rows={10}
              style={textareaStyle} />
          </div>
          <div className="flex-1 py-2 ml-6">
            <div className="py-1">Data JSON</div>
            <textarea
              disabled={isRunning}
              value={dataJson}
              onChange={handleDataJsonChange}
              className="w-full border border-color-d8d8d8 focus:outline-none p-2"
              rows={10}
              style={textareaStyle} />
          </div>
        </div>
        <div className="w-full">
          <div className="py-1">Flags</div>
          <textarea
            disabled={isRunning}
            value={flags}
            onChange={handleFlagsChange}
            className="w-full border border-color-d8d8d8 focus:outline-none p-2"
            rows={2}
            style={textareaStyle} />
        </div>
        <div className="flex justify-center my-2">
          <button
            className={classNames(
              'px-10 py-2 text-size-14px text-color-ffffff font-bold rounded-full bg-gradient-green shadow-btn focus:outline-none',
              { 'pointer-events-none opacity-50': isRunning }
            )}
            onClick={handleTestClick}>
            Test
          </button>
        </div>

        <div className="flex justify-center">
          {isRunning
            ? <AnimatedLoader className="mt-24" />
            : (
              <>
                <div className="w-full">
                  <div className="flex items-center py-1">
                    <span>Result</span>
                    {failed && <Icon type={iconType.FONTAWESOME} iconName="exclamation-circle" size="sm" className="ml-1 mb-1" />}
                  </div>
                  <textarea
                    value={result}
                    className="w-full border border-color-d8d8d8 focus:outline-none p-2"
                    rows={10}
                    style={textareaStyle} />
                </div>
              </>
            )}
        </div>
      </div>
    </>
  )
}

export default NLGTester
