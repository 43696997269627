import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { permissionNames } from '../../constants/permissionNames'
import { useRoutes } from '../../context/routes'
import { usePermissions } from '../../context/permissions'
import { useNearlineFeatures } from '../../hooks/useNearlineFeatures'
import useRoles from '../../hooks/useRoles'
import ManagerSummary from './managerSummary'
import ManagerSummaryLegacy from './managerSummaryLegacy'
import RepSummary from './repSummary'

const Summary = (props) => {
  const {
    isFetching: isFetchingRoles,
    isIndividualContributorRole,
    isManagerRole,
  } = useRoles()

  const { routes } = useRoutes()

  const {
    isFetching: isFetchingPermissions,
    checkPermissions
  } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin
    )
  }, [checkPermissions])

  const {
    isAccessNearlineCheckReady,
    canAccessNearlineFeature,
    featureNames
  } = useNearlineFeatures()

  const isFetching = useMemo(() => {
    return isFetchingPermissions && isFetchingRoles
  }, [isFetchingPermissions, isFetchingRoles])

  if (isFetching) {
    return <></>
  } else if (isManagerRole) {
    if (!isAccessNearlineCheckReady) {
      return <></>
    } else if (canAccessNearlineFeature({ featureName: featureNames.summary })) {
      return <ManagerSummary {...props} />
    } else {
      return <ManagerSummaryLegacy {...props} />
    }
  } else if (isIndividualContributorRole) {
    return <RepSummary {...props} />
  } else if (permissions.CanAccessInternalAdmin) {
    return <Redirect to={routes.tenants} />
  } else {
    return <Redirect to={routes.unauthorized} />
  }
}

export default Summary
