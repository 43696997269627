import React, { useMemo } from 'react'
import classNames from 'classnames'
import { calloutNames } from './constants'
import SummaryHeader from './summaryHeader'
import { find, get } from 'lodash'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const ScenarioSummary = (props) => {
  const {
    className,
    scenario,
    isActive,
  } = props

  const { label, output = {}, isBaseline } = scenario
  const { calloutValues = {} } = output

  const { formatCurrency } = useFormatCurrency()

  const description = useMemo(() => {
    const descriptionMap = get(output, 'descriptionMapMap', [])
    const descArr = find(descriptionMap, (m) => m[0] === 'description')
    let desc = descArr ? descArr[1] : ''
    desc = desc.replace(/{SCENARIO_NAME}/g, label)
    return desc
  }, [label, output])

  return (
    <div className={classNames(className, '')}>
      <SummaryHeader
        header={label}
        description={description}
        isBaseline={isBaseline}
        isActive={isActive} />

      <div className="mt-6 mb-8">
        <div className="flex flex-row justify-between">
          <div className="p-2 w-1/3 border-b border-r last:border-r-0 border-color-c9ced0 text-size-16px text-color-a0a8bb text-center font-weight-500 uppercase tracking-widest">
            {calloutNames.bearCase}
          </div>
          <div className="p-2 w-1/3 border-b border-r last:border-r-0 border-color-c9ced0 text-size-16px text-color-a0a8bb text-center font-weight-500 uppercase tracking-widest">
            {calloutNames.fairValue}
          </div>
          <div className="p-2 w-1/3 border-b border-r last:border-r-0 border-color-c9ced0 text-size-16px text-color-a0a8bb text-center font-weight-500 uppercase tracking-widest">
            {calloutNames.bullCase}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="p-2 w-1/3 border-r last:border-r-0 border-color-c9ced0 text-size-24px text-color-09242f text-center font-bold">
            {calloutValues[calloutNames.bearCase] ? formatCurrency(calloutValues[calloutNames.bearCase].value) : 'N/A'}
          </div>
          <div className="p-2 w-1/3 border-r last:border-r-0 border-color-c9ced0 text-size-24px text-color-09242f text-center font-bold">
            {calloutValues[calloutNames.fairValue] ? formatCurrency(calloutValues[calloutNames.fairValue].value) : 'N/A'}
          </div>
          <div className="p-2 w-1/3 border-r last:border-r-0 border-color-c9ced0 text-size-24px text-color-09242f text-center font-bold">
            {calloutValues[calloutNames.bullCase] ? formatCurrency(calloutValues[calloutNames.bullCase].value) : 'N/A'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScenarioSummary
