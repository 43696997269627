import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { baselineName, defaultSelectedFilters } from './constants'
import moment from 'moment'
import { getForecastSimulationReportOptions } from '../../services/reportingService'
import { clearChangedInputs, clearHiddenScenarios, setForecastSimulationWorkspace } from '../../actions'
import { cloneDeep, find, remove } from 'lodash'
import { DateRangeOption } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import { guid } from '../../lib/guid'
import { getFilterLabels, resolveQueryParamFieldName, resolveQueryParamKey } from './helpers'

function getDateRangeOptionName(option) {
  for (const key in DateRangeOption) {
    if (DateRangeOption[key] === option) {
      return key
    }
  }
  return 'UNKNOWN'
}

export function useFormulaInputs(props) {
  const { tenantId, groupId, defaultFormula, defaultTimePeriod, defaultTarget, defaultFilters } = props

  const dispatch = useDispatch()

  const [selectedFormula, setSelectedFormula] = useState(defaultFormula)
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(defaultTimePeriod)
  const [selectedTarget, setSelectedTarget] = useState(defaultTarget)
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters)

  const [startDate, setStartDate] = useState(new Date().toLocaleDateString('en-US'))
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString('en-US'))

  const onFormulaSelect = useCallback((option) => {
    setSelectedFormula(option)
    window.analytics.track('scenarioPlanner.start.input.formula', { name: option.key })
  }, [])

  const onTimePeriodSelect = useCallback((option) => {
    const { key, start, end } = option
    setSelectedTimePeriod(option)
    setStartDate(start)
    setEndDate(end)
    window.analytics.track('scenarioPlanner.start.input.scenarioDateRange', { name: getDateRangeOptionName(key) })
  }, [])

  const onTargetSelect = useCallback((option) => {
    setSelectedTarget(option)
    window.analytics.track('scenarioPlanner.start.input.scenarioTarget', { name: option })
  }, [])

  const onClearFilters = useCallback(() => {
    setSelectedFilters(defaultSelectedFilters)
  }, [])

  const onFilterSelected = useCallback(({ filterGroups, filterGroup, option, checked }) => {
    let filters = selectedFilters.key === -1 ? [] : cloneDeep(selectedFilters.key)
    const filter = find(filters, (f) => resolveQueryParamFieldName(f.fieldName) === resolveQueryParamFieldName(filterGroup.field))
    if (checked) {
      if (filter) {
        filter.comparisonList[0].valuesList.push(option.key)
      } else {
        filters = [
          ...filters,
          {
            key: resolveQueryParamKey(filterGroup.field),
            fieldName: resolveQueryParamFieldName(filterGroup.field),
            type: filterGroup.fieldtype,
            title: filterGroup.label,
            comparisonList: [
              {
                op: 'IN',
                valuesList: [option.key],
                weight: 100
              }
            ]
          }
        ]
      }
    } else if (filter) {
      remove(filter.comparisonList[0].valuesList, (val) => val === option.key)
      if (filter.comparisonList[0].valuesList.length === 0) {
        remove(filters, (f) => resolveQueryParamFieldName(f.fieldName) === resolveQueryParamFieldName(filterGroup.field))
      }
    }
    if (filters.length === 0) {
      setSelectedFilters(defaultSelectedFilters)
    } else {
      const labels = getFilterLabels({ filterGroups, filters })
      const selected = {
        key: filters,
        ...labels
      }
      setSelectedFilters(selected)
    }
    window.analytics.track('scenarioPlanner.start.input.filtersChanged')
  }, [selectedFilters])

  const forecastSimulationWorkspace = useSelector((state) => state.forecastSimulationWorkspace)

  const forecastSimulationsAvailable = useSelector((state) => state.forecastSimulationsAvailable)

  const [startClicked, setStartClicked] = useState(false)

  const onStartClick = useCallback(() => {
    dispatch(clearChangedInputs())
    dispatch(clearHiddenScenarios())

    setStartClicked(true)

    const w = find(forecastSimulationsAvailable, (f) => f.key === selectedFormula.key)
    if (w) {
      const workspace = {
        ...cloneDeep(w),
        ...selectedTimePeriod.key === DateRangeOption.CUSTOM && {},
        dateRangeType: selectedTimePeriod.key,
        level: {
          level: selectedTarget.level,
          entityIdsList: selectedTarget.key ? [selectedTarget.key] : [],
        },
        scenario: {
          key: `${tenantId}|${groupId}|${guid()}`,
          label: baselineName,
        },
        scenariosList: cloneDeep(forecastSimulationWorkspace.scenariosList),
      }

      if (selectedTimePeriod.key === DateRangeOption.CUSTOM) {
        workspace.startDatetime = {
          seconds: parseInt(moment(startDate).utc(true).format('X')),
          nanos: 0
        }
        workspace.endDatetime = {
          seconds: parseInt(moment(endDate).utc(true).format('X')),
          nanos: 0
        }
      }

      if (selectedFilters.key !== -1) {
        workspace.filtersList = selectedFilters.key
      }

      // console.log('getForecastSimulationReportOptions', workspace)

      dispatch(setForecastSimulationWorkspace(workspace))

      dispatch(getForecastSimulationReportOptions({ tenantId, groupId }, workspace))

      window.analytics.track('scenarioPlanner.start.input.startClicked', {
        formula: selectedFormula.key,
        dateRange: getDateRangeOptionName(selectedTimePeriod.key),
        target: selectedTarget
      })
    }
  }, [forecastSimulationWorkspace, forecastSimulationsAvailable, selectedFormula, selectedTimePeriod, selectedTarget, selectedFilters, startDate, endDate])

  const canStart = useMemo(() => {
    return selectedFormula.key !== -1
      && selectedTimePeriod.key !== -1
      && selectedTarget.key !== -1
  }, [selectedFormula, selectedTimePeriod, selectedTarget])

  return {
    selectedFormula,
    setSelectedFormula,
    onFormulaSelect,
    selectedTimePeriod,
    setSelectedTimePeriod,
    onTimePeriodSelect,
    selectedTarget,
    setSelectedTarget,
    onTargetSelect,
    onClearFilters,
    selectedFilters,
    onFilterSelected,
    canStart,
    startClicked,
    setStartClicked,
    onStartClick,
  }
}
