import { grpcInvoke } from '../grpc'
import { cloneDeep, find, findIndex, forEach, keys, noop, remove } from 'lodash'
import {
  searchOppsRequested,
  searchOppsSuccess,
  searchOppsFailed,
  getOppDetailByIdRequested,
  getOppDetailByIdSuccess,
  getOppDetailByIdFailed,
  patchSearchItemRequested,
  patchSearchItemSuccess,
  patchSearchItemFailed,
  getForecastOppsRequested,
  getForecastOppsSuccess,
  getForecastOppsFailed,
  dealHealthDataSuccess,
  dealHealthDataRequested,
  dealHealthDataFailed,
  dealHealthDataByIdSuccess,
  dealHealthDataByIdRequested,
  dealHealthDataByIdFailed,
} from '../actions/searchService'
import { toGetSearchItemByIDRequest, toPatchSearchItemRequest, toSearchRequest, toSearchOpportunitiesRequest } from '../grpc/converters'
import { FilterOperation, IntegrationSourceTypeStrings, PatchSearchItemErrType, PatchSearchItemStatus, SearchCombineAction, SearchFieldType } from '../grpc/enums'
import { clearDealHealthById, setCurrentFilters, setViewInitialized, updateOppAfterPatch } from '../actions'
import { toSavedFilters } from '../components/pipeline/helpers'
import { defaultSortItem, lastModifiedDateSortItem, ownerIdSortItem, statusSortItem } from '../components/pipeline/constants'
import { addDays, addMonths, addYears, endOfMonth, format, getMonth, getYear, startOfMonth } from 'date-fns'
import { parseDate } from '../lib/dateFns'
import moment from 'moment'
import { canonicalFieldKeys } from '../components/fieldRenderers/constants'
import { captureException } from '../lib/sentry'

const decodeHtml = (input) => {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

export function toSortItem(fieldsList, key) {
  if (!key) {
    return defaultSortItem
  } else if (key.toLowerCase() === defaultSortItem.name.toLowerCase()) {
    return defaultSortItem
  } else if (key.toLowerCase() === lastModifiedDateSortItem.name.toLowerCase()) {
    return {
      ...lastModifiedDateSortItem,
      name: canonicalFieldKeys.lastmodifieddate
    }
  } else if (key.toLowerCase() === ownerIdSortItem.name.toLowerCase()) {
    return ownerIdSortItem
  } else if (key.replace('canopy_', '').toLowerCase() === statusSortItem.name.toLowerCase()) {
    return statusSortItem
  } else {
    const fieldDefinition = find(fieldsList, (f) => f.key.toLowerCase() === key.toLowerCase())
    if (fieldDefinition) {
      return {
        name: fieldDefinition.to,
        source: IntegrationSourceTypeStrings[fieldDefinition.integrationSourceType],
        type: SearchFieldType[fieldDefinition.toType],
      }
    } else {
      return defaultSortItem
    }
  }
}

export const getSearchObject = (
  q,
  options,
  objectDefinitions,
  organization,
  changeSince,
  skip = 0,
  take = 10000
) => {
  const { availableFields = [], fields = [], searchText, sortKey, sortDirection, groupIds, userIds, excludeUserIds } = options

  const obj = {
    objectName: 'opportunity',
    fieldsList: [
      'name',
      'signalCount',
      'isClosed',
      ...fields.filter((f) => availableFields.includes(f.toLowerCase())).map((f) => f.replace('canopy_', '')),
    ],
    paging: {
      sortList: [
        {
          item: toSortItem(objectDefinitions.opportunity.fieldsList, sortKey),
          desc: !sortDirection || sortDirection === 'DESC',
        }
      ],
      skip,
      take,
    },
    query: q,
  }

  if (searchText) {
    if (!obj.query) {
      obj.query = {}
    }
    if (!obj.query.children) {
      obj.query.children = {
        combineAction: SearchCombineAction.AND,
        valuesList: [],
      }
    }
    obj.query.children.valuesList.push({
      node: {
        item: toSortItem(objectDefinitions.opportunity.fieldsList, 'name'),
        valuesList: [`.*${searchText}.*`],
        operation: FilterOperation.LIKE,
        _filter: {
          fieldDefinition: {
            key: '_search_',
          },
        }
      },
    })
  }

  if (changeSince) {
    obj.changeSince = changeSince
  }

  if (groupIds) {
    if (!obj.query) {
      obj.query = {}
    }
    if (!obj.query.children) {
      obj.query.children = {
        combineAction: SearchCombineAction.AND,
        valuesList: [],
      }
    }
    obj.query.children.valuesList.push({
      node: {
        item: {
          name: '_reportsTo',
          source: 'canopy',
          type: 0,
        },
        valuesList: groupIds,
        operation: FilterOperation.IN,
      },
    })
  }

  if (userIds) {
    if (!obj.query) {
      obj.query = {}
    }
    if (!obj.query.children) {
      obj.query.children = {
        combineAction: SearchCombineAction.AND,
        valuesList: [],
      }
    }
    obj.query.children.valuesList.push({
      node: {
        item: ownerIdSortItem,
        valuesList: userIds,
        operation: FilterOperation.IN,
      },
    })
  }

  if (excludeUserIds) {
    if (!obj.query) {
      obj.query = {}
    }
    if (!obj.query.children) {
      obj.query.children = {
        combineAction: SearchCombineAction.AND,
        valuesList: [],
      }
    }
    obj.query.children.valuesList.push({
      node: {
        item: ownerIdSortItem,
        valuesList: excludeUserIds,
        operation: FilterOperation.NIN,
      },
    })
  }

  if (obj.query && obj.query.children && obj.query.children.valuesList) {
    // remove nodes that have field names that are not in the availableFields
    remove(obj.query.children.valuesList, (v) => {
      if (v.node) {
        const name = v.node.item?.name ?? ''
        return !availableFields.includes(name.toLowerCase())
      } else {
        return false
      }
    })

    obj.query.children.valuesList.forEach((v) => {
      if (v.children && v.children.valuesList) {
        // remove nodes inside of the the top level children that have field names that are not in the availableFields
        remove(v.children.valuesList, (v) => {
          if (v.node) {
            const name = v.node.item?.name ?? ''
            return !availableFields.includes(name.toLowerCase())
          } else {
            return false
          }
        })
      }
    })

    // check for dynamic date filters and reformat timestamps to local time
    forEach(obj.query.children.valuesList, (v) => {
      const filter = v?.node?._filter ?? {}
      if (filter.type === 'DateRangeFilter') {
        const rangeByName = (v.node._filter && v.node._filter.data && v.node._filter.data.rangeByName) || ''
        if (rangeByName) {
          if (rangeByName) {
            if (rangeByName === 'thisMonth') {
              v.node.valuesList = [
                format(startOfMonth(new Date()), 'yyyy-MM-dd'),
                format(endOfMonth(new Date()), 'yyyy-MM-dd'),
              ]
              if (v.node._filter && v.node._filter.data && v.node._filter.data.isTimestamp) {
                v.node.valuesList[0] += 'T00:00:00.000'
                v.node.valuesList[1] += 'T23:59:59.999'
              }
            } else if (rangeByName === 'nextMonth') {
              v.node.valuesList = [
                format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd'),
                format(endOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd'),
              ]
              if (v.node._filter && v.node._filter.data && v.node._filter.data.isTimestamp) {
                v.node.valuesList[0] += 'T00:00:00.000'
                v.node.valuesList[1] += 'T23:59:59.999'
              }
            } else if (rangeByName === 'thisPeriod') {
              const { periodStart, periodEnd } = organization
              v.node.valuesList = [
                periodStart,
                periodEnd,
              ]
              if (v.node._filter && v.node._filter.data && v.node._filter.data.isTimestamp) {
                v.node.valuesList[0] += 'T00:00:00.000'
                v.node.valuesList[1] += 'T23:59:59.999'
              }
            } else if (rangeByName === 'nextPeriod') {
              const { periodStart, periodEnd } = organization
              const salesPeriodLength = (organization && organization.organization && organization.organization.salesPeriodLength) || 1
              v.node.valuesList = [
                format(addMonths(parseDate(periodStart), salesPeriodLength), 'yyyy-MM-dd'),
                format(endOfMonth(addMonths(parseDate(periodEnd), salesPeriodLength)), 'yyyy-MM-dd'),
              ]
              if (v.node._filter && v.node._filter.data && v.node._filter.data.isTimestamp) {
                v.node.valuesList[0] += 'T00:00:00.000'
                v.node.valuesList[1] += 'T23:59:59.999'
              }
            } else if (rangeByName === 'thisYear') {
              const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
              const month = getMonth(new Date()) + 1
              const year = getYear(new Date())
              let start
              let end
              if (month >= fiscalYearStartMonth) {
                start = parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d')
                end = addDays(addYears(start, 1), -1)
              } else {
                start = parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d')
                end = addDays(addYears(start, 1), -1)
              }
              v.node.valuesList = [
                format(start, 'yyyy-MM-dd'),
                format(end, 'yyyy-MM-dd'),
              ]
              if (v.node._filter && v.node._filter.data && v.node._filter.data.isTimestamp) {
                v.node.valuesList[0] += 'T00:00:00.000'
                v.node.valuesList[1] += 'T23:59:59.999'
              }
            } else if (rangeByName === 'nextYear') {
              const fiscalYearStartMonth = (organization && organization.organization && organization.organization.fiscalYearStartMonth) || 1
              const month = getMonth(new Date()) + 1
              const year = getYear(new Date())
              let start
              let end
              if (month >= fiscalYearStartMonth) {
                start = addYears(parseDate(`${year}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), 1)
                end = addDays(addYears(start, 1), -1)
              } else {
                start = addYears(parseDate(`${year - 1}-${fiscalYearStartMonth}-1`, 'yyyy-M-d'), 1)
                end = addDays(addYears(start, 1), -1)
              }
              v.node.valuesList = [
                format(start, 'yyyy-MM-dd'),
                format(end, 'yyyy-MM-dd'),
              ]
              if (v.node._filter && v.node._filter.data && v.node._filter.data.isTimestamp) {
                v.node.valuesList[0] += 'T00:00:00.000'
                v.node.valuesList[1] += 'T23:59:59.999'
              }
            }
          }
        }

        if (v.node._filter && v.node._filter.data && v.node.valuesList.length > 1) {
          if (v.node._filter.data.isTimestamp) {
            v.node.valuesList[0] += moment(v.node.valuesList[0]).format('Z')
            v.node.valuesList[1] += moment(v.node.valuesList[1]).format('Z')
          } else {
            if (v.node.valuesList[0].length >= 10) {
              v.node.valuesList[0] = v.node.valuesList[0].substring(0, 10)
            }
            if (v.node.valuesList[1].length >= 10) {
              v.node.valuesList[1] = v.node.valuesList[1].substring(0, 10)
            }
          }
        }
      }
    })
  }
  return obj
}

export const searchOpps = (skip, take, query, options, changeSince, initialViewLoad) => {
  return async (dispatch, getState) => {
    const { debug, actingTenantId, fetchDealHealthData = false } = options

    const state = getState()
    const { pipelineGridFields, objectDefinitions } = state

    if (!objectDefinitions.opportunity) {
      return
    }

    const q = cloneDeep(query)

    // if the search query has an ownerId filter, rebuild the search container to include the ownerId OR the ownerId's of the team
    if (q.children && q.children.valuesList) {
      const index = findIndex(q.children.valuesList, (s) => s.node && s.node.item && s.node.item.name === 'ownerId')
      if (index !== -1) {
        const currentSearchContainer = q.children.valuesList[index]
        const newSearchContainer = {
          children: {
            combineAction: 2,
            valuesList: [
              currentSearchContainer,
              {
                node: {
                  item: {
                    name: '_reportsTo',
                    source: 'canopy',
                    type: 0,
                    cache: true
                  },
                  valuesList: (currentSearchContainer && currentSearchContainer.node && currentSearchContainer.node.valuesList) || [],
                  operation: 11,
                },
              },
            ],
          }
        }
        q.children.valuesList[index] = newSearchContainer
      }
    }

    const getDealHealthData = (crmIdsList) => {
      const request = toSearchOpportunitiesRequest({
        tenantId: actingTenantId,
        crmIdsList
      })

      grpcInvoke({
        request,
        onFetch: () => {
          dispatch(dealHealthDataRequested())
        },
        onSuccess: (obj) => {
          dispatch(dealHealthDataSuccess(obj))
        },
        onError: (err) => {
          dispatch(dealHealthDataFailed(err))
        },

        grpcMethod: 'searchOutreachOpportunities',
        debug: false
      }, [])
    }

    const { organization } = getState()

    const searchObject = getSearchObject(q, options, objectDefinitions, organization, changeSince, skip, take)

    const request = toSearchRequest(searchObject)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(searchOppsRequested())
      },
      onSuccess: (obj) => {
        obj.skip = skip
        obj.take = take
        obj.storeFields = skip === 0 && keys(pipelineGridFields).length === 0
        dispatch(searchOppsSuccess(obj))
        dispatch(setCurrentFilters(toSavedFilters({ query })))
        initialViewLoad && dispatch(setViewInitialized(true))
        if (fetchDealHealthData) {
          getDealHealthData(obj.valuesList.map((opp) => opp.id.toString().replace('opportunity+', '')))
        }
      },
      onError: (err) => {
        dispatch(searchOppsFailed(err))
      },
      grpcMethod: 'search',
      grpcMethodName: 'searchOpps',
      debug
    })
  }
}

export const getOppDetailById = (id, debug, fetchDealHealthData = false, actingTenantId) => {
  return async (dispatch, getState) => {
    const obj = {
      id,
      objectName: 'opportunity',
      fieldsList: [
        '*',
      ],
    }

    const getDealHealthDataById = (id) => {
      const request = toSearchOpportunitiesRequest({
        tenantId: actingTenantId,
        crmIdsList: [id]
      })

      grpcInvoke({
        request,
        onFetch: () => {
          dispatch(clearDealHealthById())
          dispatch(dealHealthDataByIdRequested())
        },
        onSuccess: (obj) => {
          dispatch(dealHealthDataByIdSuccess(obj))
        },
        onError: (err) => {
          dispatch(dealHealthDataByIdFailed(err))
        },

        grpcMethod: 'searchOutreachOpportunities',
        debug: false
      }, [])
    }

    const request = toGetSearchItemByIDRequest(obj)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getOppDetailByIdRequested())
      },
      onSuccess: (obj) => {
        dispatch(getOppDetailByIdSuccess(obj))
        if (fetchDealHealthData) {
          getDealHealthDataById(obj.id)
        }
      },
      onError: (err) => {
        dispatch(getOppDetailByIdFailed(err))
      },
      grpcMethod: 'getSearchItemById',
      grpcMethodName: 'getOppDetailById',
      debug,
    })
  }
}

export const patchSearchItem = (opp, patchObj, notifyError, isCloseDateChange, debug = false, onComplete = noop) => {
  return async (dispatch, getState) => {
    const state = getState()
    const request = toPatchSearchItemRequest(patchObj)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(patchSearchItemRequested())
      },
      onSuccess: (obj) => {
        let error = false
        dispatch(patchSearchItemSuccess(obj))
        if (notifyError) {
          if (obj.errorType === PatchSearchItemErrType.VALIDATION) {
            notifyError(decodeHtml(obj.errorMessage))
            error = true
          } else if (obj.errorMessage) {
            notifyError(decodeHtml(obj.errorMessage))
            captureException(new Error(obj.errorMessage), undefined, 'patchSearchItem')
            error = true
          }
        }
        const { dock } = getState()
        if (error) {
          // revert the opp using the original opp object that was passed in
          const opportunity = cloneDeep(opp)
          if (opportunity?.id) {
            if (isCloseDateChange) {
              opportunity.isCloseDateChange = true
              opportunity.searchFilters = state.searchFilters
            }
            dispatch(updateOppAfterPatch(opportunity))
            if (dock.dealDetail && dock.dealDetail.enabled && dock.dealDetail.opportunity && dock.dealDetail.opportunity.id === opportunity.id) {
              dispatch(getOppDetailByIdSuccess(cloneDeep(opportunity)))
            }
          }
        } else {
          // update the opp with the opp object from the patch response
          const opportunity = cloneDeep(obj.item)
          if (opportunity?.id) {
            if (isCloseDateChange) {
              opportunity.isCloseDateChange = true
              opportunity.searchFilters = state.searchFilters
            }
            dispatch(updateOppAfterPatch(opportunity))
            if (dock.dealDetail && dock.dealDetail.enabled && dock.dealDetail.opportunity && dock.dealDetail.opportunity.id === opportunity.id) {
              dispatch(getOppDetailByIdSuccess(cloneDeep(opportunity)))
            }
          }
        }
        onComplete()
      },
      onError: (err) => {
        dispatch(patchSearchItemFailed(err))
        onComplete()
      },
      grpcMethod: 'patchSearchItem',
      debug,
    })
  }
}

export const getForecastOpps = (ids, debug) => {
  return async (dispatch, getState) => {
    if (!ids || ids.length === 0) {
      return
    }
    // build the children prop of the SearchContainer proto
    const children = {
      combineAction: SearchCombineAction.AND,
      valuesList: [],
    }
    children.valuesList.push({
      node: {
        item: {
          name: 'id',
          type: 0,
        },
        valuesList: ids,
        operation: FilterOperation.IN,
      },
    })
    const obj = {
      objectName: 'opportunity',
      fieldsList: [
        'name',
        'amount',
        'forecastCategoryName',
        'owner',
      ],
      paging: {
        skip: 0,
        take: -1,
      },
      query: children.valuesList.length > 0 ? { children } : {},
    }
    const request = toSearchRequest(obj)
    grpcInvoke({
      request,
      onFetch: () => {
        dispatch(getForecastOppsRequested())
      },
      onSuccess: (obj) => {
        dispatch(getForecastOppsSuccess(obj))
      },
      onError: (err) => {
        dispatch(getForecastOppsFailed(err))
      },
      grpcMethod: 'search',
      grpcMethodName: 'getForecastOpps',
      debug,
    })
  }
}
