export const featureNames = {
  access: 'feature.access',
  activityTrends: 'feature.activityTrends',
  coaching: 'feature.coaching',
  dashboard: 'feature.dashboard',
  dealsToReview: 'feature.dealsToReview',
  forecasting: 'feature.forecasting',
  forecastingPeriod: 'feature.forecastingPeriod',
  pipeline: 'feature.pipeline',
  reporting: 'feature.reporting',
  scenarioPlanner: 'feature.scenarioPlanner',
  userFavorites: 'feature.userFavorites',
  dealChangeWindow: 'feature.dealChangeWindow',
  nearlineFeatures: 'feature.nearlineFeatures',
  outreachSettings: 'feature.outreachSettings',
}
