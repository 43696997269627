import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { guid } from '../lib/guid'
import { DataRequest } from '../protos/src/common/data_request_pb'
import { get, uniqBy } from 'lodash'
import { grpcCodes } from '../grpc/grpcCodes'
import { number } from '../gml/lib/number'
import { useAuth } from './auth'
import { useTenantInfo } from './tenantInfo'
import { useRoutes } from './routes'
import { setUser } from '../lib/sentry'

const LoggedInUserContext = React.createContext()

export function LoggedInUserProvider({ children }) {
  const { actingUserId, isImpersonatingUser } = useAuth()
  const { tenantInfo } = useTenantInfo()
  const { routeTenantId } = useRoutes()

  const [key, setKey] = useState(guid())
  const [loggedInUser, setLoggedInUser] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  useGrpcEffect({
    request: new DataRequest(),
    onError: (err) => {
      if (err.code === grpcCodes.NOT_FOUND) {
        process.env.NODE_ENV === 'local' && console.log('getSelf returned NOT_FOUND')
      }
      setLoggedInUser({})
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      const { goalDataList = [] } = obj
      obj.goals = uniqBy(goalDataList.map((g) => {
        const label = get(g, 'category.name', 'N/A')
        let format = get(g, 'category.format')
        if (format === 'currency') format = 'shortCurrency'
        const key = get(g, 'evaluation.key', undefined)
        let actualValue = get(g, 'evaluation.actualValue', undefined)
        let baselineValue = get(g, 'evaluation.baselineValue', undefined)
        if (format === 'percent') {
          if (actualValue !== undefined) actualValue /= 100
          if (baselineValue !== undefined) baselineValue /= 100
        }
        const formattedValue = actualValue === undefined ? 'N/A' : `${number(actualValue, format)}/${number(baselineValue, format)}`
        return {
          key,
          label,
          value: formattedValue,
          format,
          actualValue,
          baselineValue,
        }
      }), (g) => g.key)
      obj.hasChildren = true
      setLoggedInUser(obj)
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getSelf',
    grpcMethodName: 'getLoggedInUser',
    debug: false,
  }, [actingUserId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useEffect(() => {
    const { name } = tenantInfo
    const { id } = loggedInUser
    if (id && name) {
      const isSandbox = routeTenantId !== undefined
      const companyName = isImpersonatingUser && !isSandbox ? 'InternalAdmin' : name
      setUser(undefined, { companyName, role: loggedInUser.role, isSandbox })
    }
  }, [tenantInfo, isImpersonatingUser, loggedInUser, routeTenantId])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      loggedInUser,
      loggedInUserId: loggedInUser.id || '',
      setLoggedInUser,
      key,
      invalidate,
    }
  }, [isFetching, loggedInUser])

  return <LoggedInUserContext.Provider value={contextValue}>{children}</LoggedInUserContext.Provider>
}

export function useLoggedInUser() {
  const context = React.useContext(LoggedInUserContext)
  if (context === undefined) {
    throw new Error('useLoggedInUser must be used within an LoggedInUserProvider')
  }
  return context
}
