import { createSelector } from 'reselect'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import has from 'lodash/has'
import orderBy from 'lodash/orderBy'
import { enrichUser } from '../reducers/team'
import { cloneDeep, uniqBy } from 'lodash'

export const apiRequestedSelector = (state, key) => has(state.apiActivity, key) && state.apiActivity[key] === 'requested'
export const apiSuccessSelector = (state, key) => has(state.apiActivity, key) && state.apiActivity[key] === 'success'
export const apiFailedSelector = (state, key) => has(state.apiActivity, key) && state.apiActivity[key] === 'failed'

export const strengthsSelector = (state) => state.strengths
export const weaknessesSelector = (state) => state.weaknesses
export const oppSettingsSelector = (state) => state.oppSettings
export const opportunityByIdSelector = (state, id) => find(state.opportunities, (o) => o.id === id)
export const signalTotalCountByIdSelector = (state, id) => (has(state.signalCounters, id) ? state.signalCounters[id].totalCount : 0)
export const keyMetricsTotalCountByIdSelector = (state, id) => (has(state.keyMetricsCounters, id) ? state.keyMetricsCounters[id].totalCount : 0)

export const opportunitiesSelector = (state) => state.opportunities
export const noteSearchedOpportunitiesSelector = (state) => state.noteSearchedOpportunities

export const uniqForecastCategoriesSelector = createSelector(
  (state) => state.oppSettings,
  (oppSettings) => {
    return orderBy(filter(oppSettings.forecastCategoriesList, (f) => !f.toLowerCase().includes('closed')))
  }
)

export const strengthsAndWeaknessesSelector = createSelector(
  strengthsSelector,
  weaknessesSelector,
  (strengths, weaknesses) => {
    const result = []
    const len = Math.min(strengths.length, weaknesses.length)
    for (let i = 0; i < len; i += 1) {
      result.push(strengths[i], weaknesses[i])
    }
    result.push(...strengths.slice(len), ...weaknesses.slice(len))
    return result
  }
)

export const peopleContextOrderedByRankSelector = createSelector(
  (state) => state.people,
  (state) => state.featureSettings,
  (state) => state.pageContext,
  (people, featureSettings, pageContext) => {
    if (!pageContext.id || people.length === 0) {
      return []
    }

    let peopleFlattened = people.map((person) => enrichUser(cloneDeep(person)))
    peopleFlattened = orderBy(filter(peopleFlattened, (u) => !u.hasChildren), ['rank'])

    const userFavoritesFeature = find(featureSettings, (f) => f.key === 'feature.userFavorites')
    const include = get(userFavoritesFeature, 'payload.include', [])
    const exclude = get(userFavoritesFeature, 'payload.exclude', [])

    if (include.length > 0) {
      peopleFlattened = filter(peopleFlattened, (u) => include.includes(u.id))
    }
    if (exclude.length > 0) {
      peopleFlattened = filter(peopleFlattened, (u) => !exclude.includes(u.id))
    }

    return uniqBy(peopleFlattened, (u) => u.id)
  }
)

export const featureSettingSelector = createSelector(
  (state) => state.featureSettings,
  (_, args) => args,
  (featureSettings, args) => {
    const f = find(featureSettings, (f) => f.key === args.key)
    return f
  }
)

export const ownerMenuItemsSelector = createSelector(
  (state) => state.people,
  (people) => {
    return people.map((p) => { return { label: p.name, id: p.id } })
  }
)

export const activeScenarioSelector = createSelector(
  (state) => state.activeScenarioKey,
  (state) => state.forecastSimulationWorkspace,
  (activeScenarioKey, forecastSimulationWorkspace) => {
    return find(forecastSimulationWorkspace.scenariosList, (s) => s.key === activeScenarioKey) || {}
  }
)

export const baselineScenarioSelector = createSelector(
  (state) => state.forecastSimulationWorkspace,
  (forecastSimulationWorkspace) => {
    return find(forecastSimulationWorkspace.scenariosList, (s) => s.isBaseline) || {}
  }
)
