import moment from 'moment'
import numeral from 'numeral'

export const formatNumberK = (value) => numeral(value).format('0.[0]a')

export const formatDate = (timestamp, formatString) => moment(timestamp * 1000).utc().format(formatString || 'MM/DD/YYYY')

export const formatDateUtc = (timestamp, formatString) => moment(timestamp).utc().format(formatString || 'MM/DD/YYYY')

export const currentQuarter = `Q${moment().quarter()}`
