import { createReducer } from '@reduxjs/toolkit'
import { setPageContext } from '../actions'

const initialState = {
  id: '',
}

export const pageContext = createReducer(initialState, {
  [setPageContext.type]: (state, action) => action.payload,
})
