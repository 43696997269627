import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'

const Column = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    children
  } = props

  PropTypes.checkPropTypes(Column.propTypes, props, 'prop', 'Column')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-column',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  return (
    <div {...attributes}>{children}</div>
  )
}

Column.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  children: PropTypes.node,
}

export default Column
