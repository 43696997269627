import React from 'react'
import MainTemplateError from './mainTemplateError'
import { captureException } from '../../lib/sentry'

class MainTemplateErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: '' }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error) {
    this.setState({ error })
    captureException(error, undefined, 'mainTemplateErrorBoundary')
  }

  render() {
    if (this.state.hasError) {
      return (
        <MainTemplateError
          title="Something went wrong!"
          subTitle={<a className="text-color-5951FF cursor-pointer" onClick={() => location.reload()}>Reload Page</a>}
          error={{
            message: this.state.error.message,
            stack: this.state.error.stack,
          }} />
      )
    }

    return this.props.children
  }
}

export default MainTemplateErrorBoundary
