import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    type = 'circle',
    color,
  } = props

  PropTypes.checkPropTypes(Icon.propTypes, props, 'prop', 'Icon')

  const hideInternal = useHideToggle(name, hide)

  const className = useMemo(() => {
    return classNames(
      name,
      'g-icon focus:outline-none',
      `child-${childIndex}`,
      { [styleNames]: !!styleNames },
      { hidden: hideInternal }
    )
  }, [hideInternal])

  return (
    <FontAwesomeIcon className={className} icon={type} color={color} />
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
}

export default Icon
