import { useCallback } from 'react'

export const useUserInput = () => {
  const encodeString = useCallback((str) => {
    let encoded = str

    if (typeof str === 'string') {
      encoded = encoded.trim()
    }

    try {
      encoded = btoa(unescape(encodeURIComponent(JSON.stringify(encoded))))
    } catch (e) {
      console.log('Unable to encode user input', e)
    }
    return encoded
  }, [])

  const decodeString = useCallback((str) => {
    let decoded = str
    try {
      decoded = JSON.parse(decodeURIComponent(escape(atob(decoded))))
    } catch (e) {
      console.log('Unable to decode string from provider', e)
    }
    return decoded
  }, [])

  return {
    encodeString,
    decodeString
  }
}
