import React, { useCallback, useMemo, useState } from 'react'
import { filter, has } from 'lodash'
import { useSelector } from 'react-redux'
import { useTextField } from '../../hooks/useTextField'
import SearchBox from '../search/searchBox'
import { GlobalLevel } from '../../protos/src/common/reporting/futureforecasting/future_forecast_pb'
import { targetAll } from './constants'
import { useVirtualizedList } from '../virtualized/hooks'
import VirtualizedList from '../virtualized/virtualizedList'
import TargetSelectOption from './targetSelectOption'

const TargetSelect = (props, ref) => {
  const { selectedOption, onSelect, onClose } = props

  const search = useTextField()

  const [globalLevel, setGlobalLevel] = useState(GlobalLevel.GROUP)

  const forecastSimulationLevelOptions = useSelector((state) => state.forecastSimulationLevelOptions)

  const teamOptions = useMemo(() => {
    const options = forecastSimulationLevelOptions.groupLevelOptionsList.map((x) => ({ key: x.id, value: x.name }))
    return search.valueChanged ? filter(options, (o) => o.value.toLowerCase().includes(search.value.toLowerCase())) : options
  }, [forecastSimulationLevelOptions, search.value, search.valueChanged])

  const personOptons = useMemo(() => {
    const options = forecastSimulationLevelOptions.personLevelOptionsList.map((x) => ({ key: x.id, value: x.name }))
    return search.valueChanged ? filter(options, (o) => o.value.toLowerCase().includes(search.value.toLowerCase())) : options
  }, [forecastSimulationLevelOptions, search.value, search.valueChanged])

  const getToggleClass = useCallback((level) => {
    return globalLevel === level
      // eslint-disable-next-line max-len
      ? 'w-full py-2 flex justify-center rounded first:rounded-r-none last:rounded-l-none focus:outline-none border border-color-7771FC text-color-ffffff text-size-12px bg-gradient-green'
      : 'w-full py-2 flex justify-center rounded first:rounded-r-none last:rounded-l-none focus:outline-none border border-color-7771FC text-color-7771FC text-size-12px'
  }, [globalLevel])

  const handleToggle = useCallback((e, level) => {
    e.preventDefault()
    search.reset()
    setGlobalLevel(level)
  }, [search.reset])

  const onSelectInternal = useCallback((option) => {
    onSelect && onSelect({
      ...option,
      ...!has(option, 'level') && { level: globalLevel },
    })
    onClose()
  }, [onSelect, globalLevel])

  const options = useMemo(() => {
    const o = []
    if (globalLevel === GlobalLevel.GROUP) {
      o.push(targetAll)
      o.push(...teamOptions)
    } else if (globalLevel === GlobalLevel.PERSON) {
      o.push(...personOptons)
    }
    if (search.valueChanged) {
      return filter(o, (option) => option.value.toLowerCase().includes(search.value.toLowerCase()))
    } else {
      return o
    }
  }, [globalLevel, teamOptions, personOptons, search])

  const renderOption = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { registerChild, measure } = cellMeasurerArgs
    const option = options[index]
    return (
      <div ref={registerChild} style={style}>
        <TargetSelectOption
          measure={measure}
          selectedOption={selectedOption}
          onSelect={onSelectInternal}
          option={option}
        />
      </div>
    )
  }, [globalLevel, options, selectedOption, onClose, onSelectInternal])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: 40,
    rowRenderer: renderOption,
  })

  return (
    <div className="flex flex-col rounded" style={{ maxHeight: 800 }} ref={ref}>
      <div className="rounded-t bg-color-ffffff" style={{ minWidth: 400 }}>
        <div className="mx-3 mt-3">
          <div className="flex justify-between">
            <button onClick={(e) => handleToggle(e, GlobalLevel.GROUP)} className={getToggleClass(GlobalLevel.GROUP)}>Teams</button>
            <button onClick={(e) => handleToggle(e, GlobalLevel.PERSON)} className={getToggleClass(GlobalLevel.PERSON)}>Individuals</button>
          </div>
        </div>
        <div className="px-3 w-full flex justify-center">
          <SearchBox
            className="my-3 w-full"
            value={search.value}
            onChange={search.onChange}
            onClear={search.reset}
            autoFocus={true}
            disabled={false}
            disableHint={true} />
        </div>
      </div>
      <div className="flex flex-grow flex-col overflow-auto" style={{ minWidth: 400, minHeight: 115 + (5 * 40) }}>
        <VirtualizedList
          className="focus:outline-none"
          deferredMeasurementCache={cellMeasurerCache}
          rowHeight={cellMeasurerCache.rowHeight}
          rowCount={options.length}
          rowRenderer={rowRenderer} />
      </div>
    </div>
  )
}

export default React.forwardRef(TargetSelect)
