import { createReducer } from '@reduxjs/toolkit'
import { clearOpportunities } from '../actions'
import { getOpportunitiesByTeamSuccess } from '../actions/opportunityService'

const initialState = 0

export const filteredDealCount = createReducer(initialState, {
  [clearOpportunities.type]: (state, action) => initialState,
  [getOpportunitiesByTeamSuccess.type]: (state, action) => action.payload.totalCount
})
