import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowTrendUp,
  faArrowUp,
  faBars,
  faBookmark,
  faBorderAll,
  faBullseye,
  faCaretDown,
  faCaretLeft,
  faChartBar,
  faCheck,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircle,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faFileInvoiceDollar,
  faFilter,
  faInfoCircle,
  faMinusCircle,
  faMinus,
  faPencilAlt,
  faPlus,
  faRedoAlt,
  faSave,
  faSearch,
  faShare,
  faSlidersH,
  faSortAmountDown,
  faSortAmountUpAlt,
  faSpinner,
  faStar,
  faTh,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlock,
  faUserCircle,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowTrendUp,
  faArrowUp,
  faBars,
  faBookmark,
  faBorderAll,
  faBullseye,
  faCaretDown,
  faCaretLeft,
  faChartBar,
  faCheck,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircle,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faFileInvoiceDollar,
  faFilter,
  faInfoCircle,
  faMinusCircle,
  faMinus,
  faPencilAlt,
  faPlus,
  faRedoAlt,
  faSave,
  faSearch,
  faShare,
  faSlidersH,
  faSortAmountDown,
  faSortAmountUpAlt,
  faSpinner,
  faStar,
  faTh,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlock,
  faUserCircle,
  faUsers
)
