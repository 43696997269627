import { Transforms, Node } from 'slate'
import get from 'lodash/get'

// this plugin adds a data-depth-reset=true attribute on the first <li> elements at each depth
export const withOrderedListAttributes = (editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      for (const [child, childPath] of Node.children(editor, path)) {
        const depthCounters = {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
        }
        let lastCounter = 0
        if (child.type === 'ordered-list') {
          // eslint-disable-next-line no-use-before-define
          for (const [li, childPath] of Node.children(editor, childPath)) {
            const depth = get(li, 'depth', 0)
            depthCounters[depth] += 1
            Transforms.setNodes(editor, { depthReset: lastCounter >= depthCounters[depth] }, { at: childPath })
            lastCounter = depthCounters[depth]
          }
        }
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}
