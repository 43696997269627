import React, { useCallback, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { validate } from 'uuid'

const RoutesContext = React.createContext()

let routeTenantIdExternal = null

export function RoutesProvider({ children }) {
  const { pathname } = window.location

  const [key, setKey] = useState(guid())

  const baseRoutes = useMemo(() => ({
    noop: '/noop',
    eventBusRedirect: '/eb-redirect',
    connected: '/connected',
    slackAuthComplete: '/slack-auth-complete',
    login: '/login',
    authRedirect: '/auth-redirect',
    logout: '/logout',
    analytics: '/analytics',
    dashboard: '/dashboard',
    default: '/',
    deal: '/deal/:opportunityId',
    deals: '/deals',
    myForecastingHistory: '/forecasting-history',
    myForecastingHistoryByConfig: '/forecasting-history/:forecastConfigId',
    forecastingHistory: '/forecasting-history/:userId',
    forecastingHistoryByConfig: '/forecasting-history/:forecastConfigId/:userId',
    forecasting: '/forecasting',
    forecastingByConfig: '/forecasting/:forecastConfigId',
    forecastingByTeam_TeamDeals: '/forecasting/:forecastConfigId/:userIds/team-deals',
    forecastingByTeam_Deals: '/forecasting/:forecastConfigId/:userIds/deals',
    forecastingByTeam: '/forecasting/:forecastConfigId/:userIds',
    favorites: '/summary#favorites',
    nlgTester: '/nlg-tester',
    rep: '/rep/:userId',
    repHome: '/rep',
    repCoaching: '/rep/:userId/coaching',
    repSignals: '/rep/:userId/signals',
    repDeals: '/rep/:userId/deals',
    repScorecard: '/rep/:userId/scorecard',
    scenarioPlanner: '/scenario-planner',
    status: '/status',
    summary: '/summary',
    userSummary: '/summary/:userId',
    tenants: '/tenants',
    tenantUsers: '/tenants/:tenantId/users',
    performanceByUser: '/performance/:userId',
    performanceByUserCoach: '/performance/:userId/coach',
    performanceByUserSignals: '/performance/:userId/signals',
    performanceByUserDeals: '/performance/:userId/deals',
    performance: '/performance',
    pipeline: '/pipeline',
    previewForecastingByConfig: '/preview/forecasting/:forecastConfigId',
    previewTenantUsers: '/preview/tenants/:tenantId/users',
    unauthorized: '/unauthorized',
  }), [])

  const isPreview = useMemo(() => {
    const previewRegExp = new RegExp(/\/preview\//)
    return previewRegExp.test(pathname)
  }, [pathname])

  const routeTenantId = useMemo(() => {
    const [, root = ''] = pathname.split('/')

    const isGuid = validate(root)

    const regexp = new RegExp(`^/${root}`)
    const basePaths = Object.values(baseRoutes)
    const rootPathExists = basePaths.find((basePath) => basePath.match(regexp))

    if (!rootPathExists && isGuid) {
      routeTenantIdExternal = root
      return root
    }

    routeTenantIdExternal = null
  }, [pathname, baseRoutes])

  const routes = useMemo(() => {
    // route matches original, no tenantId supplied
    if (!routeTenantId && !isPreview) {
      return baseRoutes
    }

    const prefixes = []

    if (routeTenantId) {
      prefixes.push(routeTenantId)
    }

    if (isPreview) {
      prefixes.push('preview')
    }

    const prefixPath = prefixes.join('/')

    const prefixedRoutes = {}
    Object.keys(baseRoutes).forEach((routeName) => {
      switch (routeName) {
        case 'login':
        case 'logout':
          prefixedRoutes[routeName] = `${baseRoutes[routeName]}`
          break
        default:
          prefixedRoutes[routeName] = `/${prefixPath}${baseRoutes[routeName]}`
      }
    })

    return prefixedRoutes
  }, [baseRoutes, routeTenantId, isPreview])

  const invalidate = useCallback(() => {
    window.location.replace('/')
  }, [])

  const contextValue = useMemo(() => {
    return {
      baseRoutes,
      invalidate,
      isPreview,
      routes,
      routeTenantId
    }
  }, [baseRoutes, routes, routeTenantId, invalidate, isPreview])

  return <RoutesContext.Provider value={contextValue}>{children}</RoutesContext.Provider>
}

export function useRoutes() {
  const context = React.useContext(RoutesContext)
  if (context === undefined) {
    throw new Error('useRoutes must be used within a RoutesProvider')
  }
  return context
}

export const getRouteTenantId = () => routeTenantIdExternal
