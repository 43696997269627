import { createReducer } from '@reduxjs/toolkit'
import { getFeatureSettingsSuccess } from '../actions/settingsService'

const initialState = []

export const featureSettings = createReducer(initialState, {
  [getFeatureSettingsSuccess.type]: (state, action) => {
    return [...action.payload.settingsList.map((s) => {
      const data = JSON.parse(atob(s.payload))
      s.payload = data
      return s
    })]
  }
})
