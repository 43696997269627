import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcCallback } from '../grpc'
import { useAuth } from './auth'
import { cloneDeep, filter, find, has, orderBy } from 'lodash'
import { toRunRealtimeQueriesRequest } from '../grpc/converters'
import { useCanvas } from './canvas'
import { RealtimeQueryStatus } from '../grpc/enums'

const RepRatingsContext = React.createContext()

export function RepRatingsProvider({ userId, headers, children }) {
  const { actingTenantId } = useAuth()
  const { canvas, tenantStandardParams } = useCanvas()

  const [key, setKey] = useState(guid())
  const [repRatings, setRepRatings] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const runRealtimeQueriesForRepRatings = useGrpcCallback({
    ...headers && { headers },
    onSuccess: (obj) => {
      setIsFetching(false)
      const { responsesMap = [] } = obj
      const signals = filter(responsesMap.map((m) => {
        const response = m[1]
        response.name = find(canvas?.tileContainersList ?? [], (t) => t.tileKey === response.queryKey)?.tile?.name ?? ''
        return response
      }), (value) => value !== undefined && value.status === RealtimeQueryStatus.SUCCESS)
      setRepRatings(signals)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'runRealtimeQueries',
    grpcMethodName: 'runRealtimeQueriesForRepRatings',
    debug: false
  }, [canvas, headers])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const realtimeQueriesMap = useMemo(() => {
    if (!canvas) {
      return []
    }
    const canvasTilesList = cloneDeep(canvas?.canvasLayout?.canvasTilesList ?? [])
    const orderedTiles = orderBy(canvasTilesList, (t, i) => t?.layout?.colIdx ?? i)
    const tileContainersList = canvas?.tileContainersList ?? []
    const orderedTileContainersList = filter(orderedTiles.map((tile) => {
      tile.tile = find(tileContainersList, (t) => tile.tileKey === t.tileKey)?.tile ?? undefined
      return tile
    }), (tile) => tile.tile !== undefined)
    const map = orderedTileContainersList.map((tile) => {
      const parametersList = tile?.tile?.realtimeView?.view?.query?.availableParameters?.parametersList ?? []
      return [
        tile.tileKey,
        {
          queryKey: tile?.tileKey,
          params: {
            parametersList
          },
          nlgMap: tile?.tile?.realtimeView?.view?.nlgMap ?? [],
          gml: tile?.tile?.realtimeView?.view?.view
        }
      ]
    })
    return map
  }, [canvas])

  useEffect(() => {
    if (key && canvas && userId) {
      const request = toRunRealtimeQueriesRequest({
        tenantId: actingTenantId,
        personId: userId,
        realtimeQueriesMap,
        ...tenantStandardParams && { tenantStandardParams }
      })
      runRealtimeQueriesForRepRatings(request)
    }
  }, [key, runRealtimeQueriesForRepRatings, canvas, userId, realtimeQueriesMap])

  const contextValue = useMemo(() => {
    return {
      userId,
      isFetching,
      repRatings,
      invalidate
    }
  }, [userId, isFetching, repRatings])

  return <RepRatingsContext.Provider value={contextValue}>{children}</RepRatingsContext.Provider>
}

export function useRepRatings() {
  const context = React.useContext(RepRatingsContext)
  if (context === undefined) {
    throw new Error('useRepRatings must be used within a RepRatingsProvider')
  }
  return context
}
