import { createReducer } from '@reduxjs/toolkit'
import { getPipelineReportSuccess } from '../actions/reportingService'
import { clearPipelineReport } from '../actions'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'

const initialState = {}

export const pipelineReport = createReducer(initialState, {
  [clearPipelineReport.type]: (state, action) => initialState,
  [getPipelineReportSuccess.type]: (state, action) => {
    state = action.payload

    const data = []
    forEach(state.output.pipelineGroupsList, (x) => data.push(...x.endGroupsList))
    const totalEndGroupsList = uniqBy(data.map((x) => { return { id: x.id, label: x.label, opportunityIdsList: [] } }), (x) => `${x.id}|${x.label}`)
    forEach(totalEndGroupsList, (x) => {
      const d = filter(data, (d) => d.id === x.id)
      x.totalAmount = sumBy(d, (x) => x.totalAmount)
      x.totalDeals = sumBy(d, (x) => x.totalDeals)
      forEach(d, (y) => {
        y.opportunityIdsList && x.opportunityIdsList.push(...y.opportunityIdsList)
      })
    })
    state.output.id = 'total'
    state.output.label = 'Total'
    state.output.endGroupsList = totalEndGroupsList

    return state
  }
})
