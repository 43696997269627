import React, { useMemo, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { some } from 'lodash'

const MultiSelectFilterItem = (props) => {
  const {
    measure,
    index,
    option,
    onChange,
    selected
  } = props

  const label = useMemo(() => {
    return option?.label ?? ''
  }, [option])

  const value = useMemo(() => {
    return option?.value
  }, [option])

  useEffect(() => {
    measure?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  return (
    <FormControlLabel
      key={`MultiSelectFilterOption-${option.value}-${index}`}
      disabled={false}
      control={(
        <Checkbox
          checked={some(selected, (s) => s.value === value)}
          onChange={(e) => onChange({ ...option, checked: e.target.checked })}
          style={{
            transform: 'scale(0.8)',
            color: '#1d4dcf'
          }} />
      )}
      label={label} />
  )
}

export default MultiSelectFilterItem
