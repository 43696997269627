import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { apiRequestedSelector } from '../../selectors'
import { dealHealthDataByIdKey } from '../../actions/searchService'
import DateFieldDetailItem from './dateFieldDetailItem'
import { useTenantInfo } from '../../context/tenantInfo'
import { Skeleton } from '@material-ui/lab'
import classNames from 'classnames'
import { secondsToDate } from '../../lib/dateFns'
import format from 'date-fns/format'
import { useOutreachFeatures } from '../../hooks/useOutreachFeatures'

const ActivityDetailItem = (props) => {
  const {
    lastActivityDate,
    debug,
    opportunity,
    onFieldChanged
  } = props
  const { currentOppDealHealth } = useSelector((state) => state.dealHealthById)
  const isFetchingDealHealth = useSelector((state) => apiRequestedSelector(state, dealHealthDataByIdKey))

  const { tenantInfo } = useTenantInfo()
  const { outreachOrg = {} } = tenantInfo || {}
  const { bento = '' } = outreachOrg

  const outreachUrl = process.env.OUTREACH_URL

  const hasKaiaDate = useMemo(() => {
    return currentOppDealHealth?.lastKaiaMeeting && currentOppDealHealth?.lastKaiaMeeting?.seconds > 0
  }, [currentOppDealHealth?.lastKaiaMeeting])

  const hasLastActivity = useMemo(() => {
    const value = lastActivityDate?.field?.value ?? '0000-00-00'
    return value !== '0000-00-00'
  }, [lastActivityDate?.field?.value])

  const hasSuccessPlan = useMemo(() => {
    return !!currentOppDealHealth?.id && currentOppDealHealth?.mapLink !== ''
  }, [currentOppDealHealth?.id, currentOppDealHealth?.mapLink])

  const lastKaiaDate = useMemo(() => {
    return hasKaiaDate ? format(secondsToDate(currentOppDealHealth?.lastKaiaMeeting?.seconds ? currentOppDealHealth?.lastKaiaMeeting?.seconds : 0), 'MMMM dd, yyyy') : ''
  }, [currentOppDealHealth?.lastKaiaMeeting?.seconds, hasKaiaDate])

  const { hasFeatureSettings, isKaiaRecordingsEnabled, isSuccessPlansEnabled } = useOutreachFeatures()

  return (
    <>

      {isFetchingDealHealth
        && (
          <>
            <div className="mt-4 mb-3">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
              <Skeleton animation="wave" height={16} />
            </div>
          </>
        )}

      {(hasLastActivity || (hasFeatureSettings && (isSuccessPlansEnabled || isKaiaRecordingsEnabled))) && (
        <>
          <div className="mt-3 mb-6px text-color-151d49 text-size-18px font-weight-700">Activity</div>
          <div className="border-t border-color-d6d9e6 my-2" />
        </>
      )}

      {hasLastActivity && (
        <DateFieldDetailItem
          debug={debug}
          opportunity={opportunity}
          {...lastActivityDate}
          onFieldChanged={onFieldChanged}
          enableChangeHistory={true}
          className="flex flex-row flex-wrap my-1"
          labelClassName="mr-2" />
      )}

      {isKaiaRecordingsEnabled && hasKaiaDate && (
        <>
          <div className="mt-2 flex flex-row">
            <div className="mr-2 text-color-09242f text-size-16px font-weight-600">Last Kaia Meeting</div>
            <div className="text-color-09242f text-size-16px font-weight-400">{lastKaiaDate}</div>
          </div>
          <a
            className={classNames('text-color-2e5bff text-size-14px font-weight-500 cursor-pointer mt-1 mb-2', { 'opacity-50 pointer-events-none': !bento })}
            onClick={() => {
              window.analytics.track('deal.kaiaRecordingButtonClicked', { dealName: opportunity?.fields?.name[0]?.value })
            }}
            href={`https://${bento}.${outreachUrl}/opportunities/${currentOppDealHealth?.id}/kaia-recordings`}
            target="_blank"
            rel="noreferrer">
            View Recording
          </a>
        </>
      )}

      {isSuccessPlansEnabled && hasSuccessPlan && (
        <>
          <div className="mt-2 flex flex-row">
            <div className="mr-2 text-color-151d49 text-size-16px font-weight-600">Plan Status</div>
            <div className="text-color-09242f text-size-16px font-weight-400">{currentOppDealHealth?.mapStatus}</div>
          </div>
          <div className="mr-2 mt-3 text-color-151d49 text-size-16px font-weight-600">Plan Next Step</div>
          <div className="text-color-09242f text-size-14px font-weight-400">{currentOppDealHealth?.mapNextSteps}</div>
          <a
            className={classNames('text-color-2e5bff text-size-14px font-weight-500 cursor-pointer', { 'opacity-50 pointer-events-none': !bento })}
            style={{ transform: 'translateY(-2px)' }}
            onClick={() => {
              window.analytics.track('deal.viewPlanButtonClicked', { dealName: opportunity?.fields?.name[0]?.value })
            }}
            href={`https://${bento}.${outreachUrl}/opportunities/${currentOppDealHealth?.id}/plan`}
            target="_blank"
            rel="noreferrer">
            View Plan
          </a>
        </>
      )}

      {isSuccessPlansEnabled && !hasSuccessPlan && (
        <div>
          <div className="mt-2 flex flex-row">
            <div className="mr-2 text-color-151d49 text-size-16px font-weight-600">Plan Status</div>
            <div className="text-color-09242f text-size-16px font-weight-400">No Plan</div>
          </div>
          <a
            className={classNames('text-color-2e5bff text-size-14px font-weight-500 cursor-pointer', { 'opacity-50 pointer-events-none': !bento })}
            style={{ transform: 'translateY(-2px)' }}
            onClick={() => {
              window.analytics.track('deal.viewPlanButtonClicked', { dealName: opportunity?.fields?.name[0]?.value })
            }}
            href={`https://${bento}.${outreachUrl}/opportunities/${currentOppDealHealth?.id}/plan`}
            target="_blank"
            rel="noreferrer">
            Create Plan
          </a>
        </div>
      )}

    </>
  )
}

export default ActivityDetailItem
