import React, { useMemo } from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import { useHideToggle } from '../hooks/useHideToggle'
import { number } from '../lib/number'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

const Number = (props) => {
  const {
    name,
    styleNames,
    childIndex,
    hide = false,
    format,
    children
  } = props

  PropTypes.checkPropTypes(Number.propTypes, props, 'prop', 'Number')

  const hideInternal = useHideToggle(name, hide)

  const attributes = useMemo(() => {
    return {
      ...name && { id: name },
      className: classNames(
        'g-number',
        `child-${childIndex}`,
        { [styleNames]: !!styleNames },
        { hidden: hideInternal }
      )
    }
  }, [hideInternal])

  const { formatCurrency } = useFormatCurrency()
  const { formatCurrency: formatShortCurrency } = useFormatCurrency({ shortCurrency: true })

  const value = useMemo(() => {
    if (format === 'currency') {
      return formatCurrency(children)
    } else if (format === 'shortCurrency') {
      return formatShortCurrency(children)
    } else {
      return number(children, format)
    }
  }, [children, format, formatCurrency, formatShortCurrency])

  return (
    <span {...attributes}>{value}</span>
  )
}

Number.propTypes = {
  name: PropTypes.string,
  styleNames: PropTypes.string,
  childIndex: PropTypes.number,
  hide: PropTypes.bool,
  format: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])
}

export default Number
