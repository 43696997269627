import React, { useCallback } from 'react'
import Icon, { iconType } from '../icon'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'
import { roundArrow, hideAll } from 'tippy.js'

const NoteListItem = (props) => {
  const {
    note,
    active,
    onClick,
    onSend,
    onDelete,
    canRead,
    canDelete,
    canShare
  } = props

  const { title = 'Untitled', createdAtLabel, preview } = note

  const onDeleteInternal = useCallback(() => {
    hideAll()
    onDelete && onDelete(note)
  }, [note])

  return (
    <div className={classNames('group relative border-b border-color-e0e7ff justify-between flex', { 'bg-color-edeeee-60': active })} style={{ minWidth: '100%' }}>
      <div className="cursor-pointer py-3" style={{ minWidth: '100%' }}>
        <div className="px-4 text-size-16px text-color-09242f font-bold whitespace-nowrap truncate">{title}</div>
        {createdAtLabel && <div className="px-4 text-size-14px text-color-a0a8bb font-normal whitespace-nowrap truncate">{createdAtLabel}</div>}
        <div className="px-4 text-size-14px text-color-a0a8bb font-normal whitespace-nowrap truncate">{preview || <>&nbsp;</>}</div>
      </div>
      <div
        onClick={onClick}
        className={classNames('absolute w-full h-full bg-color-transparent focus:outline-none',
          { 'cursor-pointer pointer-events-auto': canRead },
          { 'cursor-default pointer-events-none': !canRead })} />
      <div className={classNames('pointer-events-none absolute w-full h-full flex justify-end', { 'invisible group-hover:visible ': !active })}>
        <div className="pointer-events-auto mr-6 flex items-center">
          {/* removed ability to share on 7/21/2022 as requested by Max Layman and Will Jaynes */}
          {canShare && false && (
            <button
              onMouseUp={onSend}
              className="ml-2 px-1 cursor-pointer pointer-events-auto focus:outline-none">
              <Icon type={iconType.FONTAWESOME} iconName="share" size="sm" className="text-color-a0a8bb hover:text-color-151d49" />
            </button>
          )}
          {canDelete && (
            <Tippy
              offset={[0, 0]}
              duration={[0, 0]}
              maxWidth={800}
              content={<Confirm title={title} onCancel={hideAll} onDelete={onDeleteInternal} />}
              arrow={roundArrow}
              trigger="click"
              interactive={true}
              theme="canopy">
              <div className="ml-2 px-1 cursor-pointer pointer-events-auto focus:outline-none">
                <Icon type={iconType.FONTAWESOME} iconName="trash" size="sm" className="text-color-a0a8bb hover:text-color-151d49" />
              </div>
            </Tippy>
          )}
        </div>
      </div>
      <div className="pointer-events-none absolute w-full h-full flex justify-end">
        <div className="mt-8 mr-1">
          {active && <Icon type={iconType.FONTAWESOME} iconName="chevron-right" color="#151d49" size="xs" style={{ marginLeft: 1 }} />}
        </div>
      </div>
    </div>
  )
}

const Confirm = ({ title, onCancel, onDelete }) => {
  return (
    <div className="px-7 py-6 font-normal">
      <div className="text-size-16px">
        Are you sure you want to delete
        <br />
        <span className="font-bold">{title}</span>
        ?
      </div>
      <div className="mt-3 mb-7 text-size-14px text-color-4e5d7e">It can't be undone.</div>
      <div className="flex justify-center">
        <div className="flex items-center">
          <button
            onClick={onCancel}
            className="mx-3 px-8 py-2 text-size-14px text-color-ffffff font-weight-600 rounded-full bg-color-a0a8bb shadow-btn focus:outline-none">
            Cancel
          </button>
          <button
            onClick={onDelete}
            className="mx-3 px-8 py-2 text-size-14px text-color-ffffff font-weight-600 rounded-full bg-gradient-green shadow-btn focus:outline-none">
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default NoteListItem
