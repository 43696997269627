import React, { useCallback, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import arrowLeft from '../../../assets/arrow-left.png'
import arrowRight from '../../../assets/arrow-right.png'
import { getCurrentForecastCategoryCall, getPreviousForecastCategoryCall } from '../helpers'
import { useFormatCurrency } from '../../../hooks/useFormatCurrency'
import { useForecasting } from '../../../context/forecasting'

const ForecastComparison = (props) => {
  const {
    forecastCategories
  } = props

  const { forecast } = useForecasting()
  const { formatCurrency } = useFormatCurrency()

  const scrollViewRef = useRef()
  const [currentIndex, setCurrentIndex] = useState(0)

  const count = useMemo(() => {
    return forecastCategories.length
  }, [forecastCategories])

  const onPrevClick = useCallback((e) => {
    let newIndex = currentIndex - 1
    if (newIndex < 0) newIndex = count - 1
    setCurrentIndex(newIndex)
    scrollViewRef.current.scroll({ left: newIndex * (scrollViewRef.current.offsetWidth) })
    window.analytics.track('forecasting.v2.dock.forecastComparison.prevClicked')
  }, [count, scrollViewRef, currentIndex])

  const onNextClick = useCallback((e) => {
    let newIndex = currentIndex + 1
    if (newIndex > count - 1) newIndex = 0
    setCurrentIndex(newIndex)
    scrollViewRef.current.scroll({ left: newIndex * (scrollViewRef.current.offsetWidth) })
    window.analytics.track('forecasting.v2.dock.forecastComparison.nextClicked')
  }, [scrollViewRef, currentIndex, count])

  return (
    <div className="w-full">
      <div className="w-full relative">

        {count > 1 && (
          <div className="z-20 absolute flex justify-between w-full">
            <div
              className="mt-3 cursor-pointer bg-color-ffffff"
              onClick={onPrevClick}>
              <img src={arrowLeft} />
            </div>
            <div
              className="mt-3 cursor-pointer bg-color-ffffff"
              onClick={onNextClick}
              style={{ transform: 'translateX(5px)' }}>
              <img src={arrowRight} />
            </div>
          </div>
        )}

        <div
          ref={scrollViewRef}
          className="inline-flex w-full overflow-y-hidden no-scrollbar rounded mt-2 overflow-visible"
          style={{ overscrollBehaviorX: 'none', scrollBehavior: 'smooth' }}>

          {forecastCategories.map((fc, index) => {
            const currentCall = Math.round(getCurrentForecastCategoryCall(fc.id, forecast))
            const previousCall = Math.round(getPreviousForecastCategoryCall(fc.id, forecast))
            const currentClassName = classNames('text-center text-size-22px font-bold whitespace-nowrap truncate',
              { 'text-color-09242f': currentCall === previousCall },
              { 'text-color-7771FC': currentCall > previousCall },
              { 'text-color-fb6c6a': currentCall < previousCall })
            return (
              <div key={`fc-${fc.id}-${index}`} className="w-full flex flex-shrink-0 items-center justify-between py-2">
                <div className="w-1/2 leading-tight px-1">
                  <div className="text-center text-color-b0bac9 text-size-12px font-weight-600 whitespace-nowrap truncate">Last Forecast</div>
                  <div className="text-center text-color-b0bac9 text-size-12px font-normal-600 whitespace-nowrap truncate">{fc.name}</div>
                  <div className="text-center text-color-09242f text-size-22px font-bold whitespace-nowrap truncate">{formatCurrency(previousCall)}</div>
                </div>
                <div className="border-r border-color-c9ced0 h-full" />
                <div className="w-1/2 leading-tight px-1">
                  <div className="text-center text-color-b0bac9 text-size-12px font-weight-600 whitespace-nowrap truncate">Current Forecast</div>
                  <div className="text-center text-color-b0bac9 text-size-12px font-normal-600 whitespace-nowrap truncate">{fc.name}</div>
                  <div className={currentClassName}>{formatCurrency(currentCall)}</div>
                </div>
              </div>
            )
          })}

        </div>
      </div>
    </div>
  )
}

export default ForecastComparison
