import React from 'react'

const Chart = () => {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40">
      <defs>
        <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="0%" style={{ stopColor: '#9B97FF', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#7771FC', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Elements/Summary/Pipeline-Empty" transform="translate(-277.000000, -246.000000)" fill="url(#grad)" fillRule="nonzero">
          <g id="Group" transform="translate(277.000000, 238.000000)">
            <path d="M35.5555556,8 L4.44444444,8 C2,8 0,10 0,12.4444444 L0,43.5555556 C0,46 2,48 4.44444444,48 L35.5555556,48 C38,48 40,46 40,43.5555556 L40,12.4444444 C40,10 38,8 35.5555556,8 Z M11.1111111,39.1111111 C9.88888889,39.1111111 8.88888889,38.1111111 8.88888889,36.8888889 L8.88888889,25.7777778 C8.88888889,24.5555556 9.88888889,23.5555556 11.1111111,23.5555556 C12.3333333,23.5555556 13.3333333,24.5555556 13.3333333,25.7777778 L13.3333333,36.8888889 C13.3333333,38.1111111 12.3333333,39.1111111 11.1111111,39.1111111 Z M20,39.1111111 C18.7777778,39.1111111 17.7777778,38.1111111 17.7777778,36.8888889 L17.7777778,19.1111111 C17.7777778,17.8888889 18.7777778,16.8888889 20,16.8888889 C21.2222222,16.8888889 22.2222222,17.8888889 22.2222222,19.1111111 L22.2222222,36.8888889 C22.2222222,38.1111111 21.2222222,39.1111111 20,39.1111111 Z M28.8888889,39.1111111 C27.6666667,39.1111111 26.6666667,38.1111111 26.6666667,36.8888889 L26.6666667,32.4444444 C26.6666667,31.2222222 27.6666667,30.2222222 28.8888889,30.2222222 C30.1111111,30.2222222 31.1111111,31.2222222 31.1111111,32.4444444 L31.1111111,36.8888889 C31.1111111,38.1111111 30.1111111,39.1111111 28.8888889,39.1111111 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Chart
