import React, { useMemo } from 'react'
import { findIndex } from 'lodash'
import { useFieldDefinitionOptions, useVersionsTooltip } from './hooks'
import classNames from 'classnames'
import pencilSm from '../../assets/pencil-sm.png'
import Icon, { iconType } from '../icon'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import { useReferenceFieldOptionsLabels } from '../../context/referenceFieldOptionsLabels'

const SingleSelectFieldRowItemDisplay = (props) => {
  const {
    fieldDefinition,
    field,
    readonlyOverride = false,
    selectedOption,
    onClick,
    lazyLoad = false
  } = props

  const { referenceFields } = useReferenceFieldOptionsLabels()

  const { canEdit } = fieldDefinition
  const { versions = [] } = field
  const options = useFieldDefinitionOptions(fieldDefinition)

  const readonly = useMemo(() => {
    return readonlyOverride || !canEdit
  }, [readonlyOverride, canEdit])

  const className = useMemo(() => {
    let movingUp = false
    let movingDown = false
    if (versions.length > 1 && options.length > 0) {
      const latest = versions[0]
      const next = versions[1]
      if (latest.value && next.value) {
        const latestIndex = findIndex(options, (o) => o.value === latest.value)
        const nextIndex = findIndex(options, (o) => o.value === next.value)
        if (latestIndex !== -1 && nextIndex !== -1) {
          movingUp = latestIndex > nextIndex
          movingDown = latestIndex < nextIndex
        }
      }
    }
    return classNames('text-color-2e384d text-size-15px font-normal max-lines-2',
      { 'text-color-1dcf83 font-bold': movingUp },
      { 'text-color-fb6c6a font-bold': movingDown })
  }, [versions, options])

  const versionsTooltip = useVersionsTooltip(versions, (val) => (val || '-'))

  const displayValue = useMemo(() => {
    if (lazyLoad) {
      return referenceFields.find((f) => f.key === (field?.originalValue ?? ''))?.label ?? ''
    } else if (field?.value) {
      return field.value
    } else {
      return selectedOption?.label ?? '-'
    }
  }, [lazyLoad, referenceFields, field, selectedOption])

  return (
    <div
      className={classNames('flex items-center text-left', { 'group cursor-pointer': !readonly })}>
      <Tippy
        onShow={() => versions.length > 1}
        offset={[0, 4]}
        duration={[0, 0]}
        maxWidth={800}
        content={versionsTooltip}
        arrow={roundArrow}
        theme="canopy">
        <div
          {...!readonly && { onClick }}
          className={className}>
          {displayValue}
        </div>
      </Tippy>
      <div {...!readonly && { onClick }} className="invisible group-hover:visible" style={{ transform: 'translate(4px, 0px)' }}>
        <Icon type={iconType.IMAGE} src={pencilSm} />
      </div>
    </div>
  )
}

export default SingleSelectFieldRowItemDisplay
