import Chartist from 'chartist'
import tippy, { roundArrow } from 'tippy.js'

export default function labelValueTooltip(options) {
  return function labelValueTooltip(chart) {
    const defaultOptions = {
      labels: [],
      values: []
    }

    options = Chartist.extend({}, defaultOptions, options)

    const template = `
<div id="tippy-template" class="px-2 py-1" style={{ display: 'none' }}>
  <div class="flex items-center justify-between my-2">
    <div class="text-size-15px text-color-2e384d font-bold px-1">[LABEL]</div>
    <div class="text-size-15px text-color-4e5d7e font-bold px-1">[VALUE]</div>
  </div>
</div>`

    chart.on('created', (data) => {
      options.labels.forEach((_, index) => {
        const opts = {
          animation: 'fade',
          offset: options.offset || [0, 20],
          duration: [0, 0],
          delay: [0, 50],
          allowHTML: true,
          placement: 'top',
          hideOnClick: false,
          arrow: roundArrow,
          maxWidth: 200,
          theme: 'canopy',
          appendTo: document.querySelector(options.appendToSelector)
        }
        tippy(options.target.replace('{index}', index), {
          ...opts,
          content: () => {
            let t = template
            t = t.replace('[LABEL]', options.labels[index])
            t = t.replace('[VALUE]', options.values[index])
            return t
          }
        })
      })
    })
  }
}
