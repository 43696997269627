export const SYNCED_ACTIVITY_COLUMN_WIDTH = 110
export const UNSYNCED_ACTIVITY_COLUMN_WIDTH = 70

export const TimePeriod = {
  DAILY: 0,
  WEEKLY: 1,
}

export const timePeriodOptions = [
  { label: 'Daily', value: TimePeriod.DAILY },
  { label: 'Weekly', value: TimePeriod.WEEKLY },
]
