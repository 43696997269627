import React, { useMemo } from 'react'
import FieldDebug from './fieldDebug'
import { format, parseISO } from 'date-fns'
import { getFieldFromObject } from './helpers'
import { daysUntil } from '../../lib/date'
import pluralize from 'pluralize'

const TimestampFieldRowItem = (props) => {
  const {
    debug,
    opportunity,
    fieldDefinition,
    field,
    showDaysRelative,
  } = props

  const showSubLabel = useMemo(() => {
    const field = getFieldFromObject(opportunity, 'isclosed')
    const closed = field && field.value
    return !closed
  }, [opportunity])

  const daysRelativeLabel = useMemo(() => {
    let label
    if (showDaysRelative && field) {
      const date = parseISO(field.value, 'yyyy-MM-dd', new Date())
      const days = daysUntil(date)
      if (days > 0) {
        label = (
          <div className="text-color-91959f text-size-12px font-normal">
            {days}
            {' '}
            {pluralize('day', days)}
            {' '}
            left
          </div>
        )
      } else if (days < 0) {
        label = (
          <div className="text-color-91959f text-size-12px font-normal">
            {Math.abs(days)}
            {' '}
            {pluralize('day', Math.abs(days))}
            {' '}
            ago
          </div>
        )
      } else {
        label = <div className="text-color-91959f text-size-12px font-normal">Today</div>
      }
    }
    return label
  }, [showDaysRelative, field])

  const value = useMemo(() => {
    if (field && field.value) {
      try {
        const d = parseISO(field.value)
        return format(d, 'M/d/yyyy')
      } catch (err) {
        console.log(err)
      }
    }

    return '-'
  }, [field])

  return (
    <div className="TimestampFieldRowItem">
      <div className="text-color-2e384d text-size-15px font-normal">{value}</div>
      {(showSubLabel && daysRelativeLabel) && daysRelativeLabel}
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </div>
  )
}

export default TimestampFieldRowItem
