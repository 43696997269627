import React, { useMemo } from 'react'
import CallCards from './callCards'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import ForecastSelector from './forecastSelector'

const Forecast = (props) => {
  const {
    children,
    isIcManagerAndDirectReport,
    isLoggedInUsersTeam,
    user
  } = props

  const { checkPermissions } = usePermissions()

  const { id: userId } = user

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadForecast,
      permissionNames.CanCreateForecast
    )
  }, [checkPermissions])

  return (
    <div className="flex-none flex flex-col pt-10">
      {children}
      <div className="flex items-center justify-end mb-2">
        <ForecastSelector {...props} />
      </div>
      {permissions.CanReadForecast && (
        <CallCards
          key={`CallCards-${userId}`}
          isLoggedInUsersTeam={isLoggedInUsersTeam}
          isIcManagerAndDirectReport={isIcManagerAndDirectReport}
          isManager={user.info && user.info.hasChildren} />
      )}
    </div>
  )
}

export default Forecast
