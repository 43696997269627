import React, { useEffect } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { RemoveScroll } from 'react-remove-scroll'

const Modal = (props) => {
  const { isOpen, children } = props

  useEffect(() => {
    if (isOpen) {
      const documentWidth = document.documentElement.clientWidth
      const windowWidth = window.innerWidth
      const scrollBarWidth = windowWidth - documentWidth
      document.body.style.paddingRight = scrollBarWidth
      document.body.classList.add('no-scrollbar')
    } else {
      document.body.style.paddingRight = 0
      document.body.classList.remove('no-scrollbar')
    }
  }, [isOpen])

  return (
    <RemoveScroll enabled={isOpen}>
      <div
        className={classNames('fixed w-screen h-screen top-0 left-0', { 'pointer-events-none': !isOpen })}
        style={{ zIndex: 1000000 }}>
        {isOpen && <div className="absolute modal-bg w-full h-full cursor-default" />}
        <div className="absolute w-full">
          <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="modal">
            <div className="pointer-events-auto">
              {isOpen && children}
            </div>
          </CSSTransition>
        </div>
      </div>
    </RemoveScroll>
  )
}

export default Modal
