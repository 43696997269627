import React, { useMemo } from 'react'
import { typeCheck } from 'type-check'
import FieldDebug from './fieldDebug'

const UnknownFieldRowItem = (props) => {
  const {
    debug = false,
    opportunity,
    fieldDefinition,
    field,
  } = props

  const { value } = field

  const renderedValue = useMemo(() => {
    if (typeCheck('Object', value)) {
      return JSON.stringify(value)
    } else {
      return value || '-'
    }
  }, [])

  return (
    <div className="UnknownFieldRowItem">
      <div className="text-color-2e384d text-size-15px font-normal">{renderedValue}</div>
      <FieldDebug
        debug={debug}
        data={{ opportunity, fieldDefinition, field }} />
    </div>
  )
}

export default UnknownFieldRowItem
