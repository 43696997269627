import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import UnsyncedActivityColumn from './unsyncedActivityColumn'
import useRefResize from '../../hooks/useRefResize'
import { timePeriodOptions, UNSYNCED_ACTIVITY_COLUMN_WIDTH, TimePeriod } from './constants'
import { addDays, addWeeks, endOfWeek, format, isWithinInterval, parse, startOfDay, startOfWeek, fromUnixTime } from 'date-fns'
import { filter } from 'lodash'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTenantInfo } from '../../context/tenantInfo'
import ActivityModal from './activityModal'
import UpdateModal from './updateModal'
import { useModal } from '../../hooks/useModal'
import { hideAll } from 'tippy.js'
import { useDebug } from '../../context/debug'
import TimePeriodSelector from './timePeriodSelector'
import { useGrpcCallback } from '../../grpc'
import { toGetActivityTimelineRequest } from '../../grpc/converters'
import { secondsToFormattedDate } from '../../lib/dateFns'
import AnimatedLoader from '../loaders/animatedLoader'
import { useUserPrefs } from '../../context/userPrefs'

const columnHeight = 130

const UnsyncedActivity = (props) => {
  const { debug } = useDebug()
  const modalActivity = useModal()
  const modalUpdate = useModal()
  const params = useParams()
  const containerRef = useRef()
  const { tenantInfo } = useTenantInfo()
  const { crmType } = tenantInfo
  const { savePref, getPref } = useUserPrefs()

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(getPref('timeline', 'scope') ?? timePeriodOptions[1])
  const [dayStart, setDayStart] = useState(undefined)
  const [dayEnd, setDayEnd] = useState(undefined)
  const [columnCount, setColumnCount] = useState(undefined)
  const [activityTimeline, setActivityTimeline] = useState({})
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [loading, setLoading] = useState()
  const [activityId, setActivityId] = useState(undefined)
  const [partitionTs, setPartitionTs] = useState(undefined)
  const [update, setUpdate] = useState(undefined)

  const opportunityId = useMemo(() => {
    return params.opportunityId || props.opportunityId
  }, [params])

  const {
    activitiesList = [],
    opportunitiesList = [],
  } = activityTimeline ?? {}

  const [containerWidth, setContainerWidth] = useState(0)
  useRefResize(containerRef, ({ width }) => setContainerWidth(width))

  const getActivityTimeline = useGrpcCallback({
    onFetch: () => setLoading(true),
    onSuccess: (res) => {
      setActivityTimeline(res)
      setLoading(false)
    },
    onError: console.log,
    grpcMethod: 'getActivityTimeline',
    debug,
  }, [])

  useEffect(() => {
    savePref('timeline', 'scope', selectedTimePeriod)
  }, [selectedTimePeriod])

  const createGetTimelineRequest = useCallback((startDate, endDate) => {
    const request = toGetActivityTimelineRequest({
      opportunityId,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    })
    getActivityTimeline(request)
    setStartDate(startDate)
    setEndDate(startDate)
  }, [getActivityTimeline, opportunityId])

  const showActivityDetails = useCallback((activity) => {
    const { id, partitionTs } = activity
    setActivityId(id)
    setPartitionTs(partitionTs)
    modalActivity.setOpen(true)
    hideAll()
  }, [modalActivity])

  const showUpdateDetails = useCallback((updateDiff, date) => {
    setUpdate({ updateDiff, date })
    modalUpdate.setOpen(true)
    hideAll()
  }, [modalUpdate])

  const onJump = useCallback(() => {
    if (columnCount) {
      const timePeriodOffset = (columnCount % 2 === 1) ? 0 : 1
      const previousTimePeriods = Math.floor(columnCount / 2)
      const futureTimePeriods = Math.floor(columnCount / 2) - timePeriodOffset

      let startDate; let
        endDate
      if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
        const day = startOfWeek(Date.now())
        startDate = addWeeks(day, -previousTimePeriods)
        endDate = endOfWeek(addWeeks(day, futureTimePeriods))
      } else {
        const today = startOfDay(Date.now())
        startDate = addDays(today, -previousTimePeriods)
        endDate = addDays(today, futureTimePeriods)
      }

      setDayStart(startDate)
      setDayEnd(endDate)
      createGetTimelineRequest(startDate, endDate)
    }
  }, [columnCount, selectedTimePeriod, createGetTimelineRequest])

  const onPrev = useCallback(() => {
    if (dayStart && columnCount) {
      let startDate; let
        endDate
      if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
        startDate = addWeeks(dayStart, -columnCount)
        endDate = addDays(dayStart, -1)
      } else {
        startDate = addDays(dayStart, -columnCount)
        endDate = addDays(dayStart, -1)
      }

      setDayStart(startDate)
      setDayEnd(endDate)
      createGetTimelineRequest(startDate, endDate)
    }
  }, [dayStart, dayEnd, columnCount, selectedTimePeriod, createGetTimelineRequest])

  const onNext = useCallback(() => {
    if (dayStart && columnCount) {
      let startDate; let
        endDate
      if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
        startDate = addDays(dayEnd, 1)
        endDate = endOfWeek(addWeeks(startDate, columnCount))
      } else {
        startDate = addDays(dayStart, columnCount)
        endDate = addDays(dayStart, columnCount * 2)
      }

      setDayStart(startDate)
      setDayEnd(endDate)
      createGetTimelineRequest(startDate, endDate)
    }
  }, [dayStart, dayEnd, columnCount, selectedTimePeriod, createGetTimelineRequest])

  useEffect(() => {
    if (containerWidth > 0) {
      const columnCount = Math.floor(containerWidth / UNSYNCED_ACTIVITY_COLUMN_WIDTH)
      const timePeriodOffset = (columnCount % 2 === 1) ? 0 : 1
      const previousTimePeriods = Math.floor(columnCount / 2)
      const futureTimePeriods = Math.floor(columnCount / 2) - timePeriodOffset

      let startDate; let
        endDate
      if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
        const day = startOfWeek(Date.now())
        startDate = addWeeks(day, -previousTimePeriods)
        endDate = endOfWeek(addWeeks(day, futureTimePeriods))
      } else {
        const today = startOfDay(Date.now())
        startDate = addDays(today, -previousTimePeriods)
        endDate = addDays(today, futureTimePeriods)
      }

      setColumnCount(columnCount)
      setDayStart(startDate)
      setDayEnd(endDate)
      createGetTimelineRequest(startDate, endDate)
    }
  }, [containerWidth, createGetTimelineRequest, selectedTimePeriod])

  const timeRange = useMemo(() => {
    if (startDate && endDate) {
      return `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')}`
    }
    return '\u00A0'
  }, [startDate, endDate])

  const activity = useMemo(() => {
    if (startDate && endDate) {
      const data = []
      for (let i = 0; i < columnCount; i += 1) {
        if (selectedTimePeriod.value === TimePeriod.WEEKLY) {
          const date = addWeeks(startDate, i)
          const timeRange = { start: date, end: endOfWeek(date) }
          data.push({
            date,
            activities: filter(activitiesList, ({ completedAt = { valid: false } }) => {
              return completedAt.valid && isWithinInterval(fromUnixTime(completedAt.value?.seconds), timeRange)
            }).sort((a, b) => a.completedAt.value.seconds - b.completedAt.value.seconds),
            opportunities: filter(opportunitiesList, ({ diff }) => {
              return diff.targetTs && isWithinInterval(fromUnixTime(diff.targetTs?.seconds), timeRange)
            }).sort((a, b) => a.diff.targetTs.seconds - b.diff.targetTs.seconds),
          })
        } else {
          const date = addDays(startDate, i)
          data.push({
            date,
            activities: filter(activitiesList, ({ completedAt = { valid: false } }) => {
              return completedAt.valid && format(date, 'MM/dd/yyyy') === secondsToFormattedDate(completedAt.value?.seconds ?? 0, 'MM/dd/yyyy')
            }).sort((a, b) => a.completedAt.value.seconds - b.completedAt.value.seconds),
            opportunities: filter(opportunitiesList, ({ diff }) => {
              return diff.targetTs && format(date, 'MM/dd/yyyy') === secondsToFormattedDate(diff.targetTs?.seconds ?? 0, 'MM/dd/yyyy')
            }).sort((a, b) => a.diff.targetTs.seconds - b.diff.targetTs.seconds),
          })
        }
      }
      return data.map((d, index) => (
        <UnsyncedActivityColumn
          key={`ActivityColumn-${format(d.date, 'T')}`}
          data={d}
          loading={loading}
          activityTimeline={activityTimeline}
          selectedTimePeriod={selectedTimePeriod}
          height={columnHeight}
          showUpdateDetails={showUpdateDetails}
          showActivityDetails={showActivityDetails} />
      ))
    }
    return null
  }, [activitiesList, startDate, endDate, columnCount, selectedTimePeriod, showActivityDetails])

  return (
    <div className="rounded-lg bg-color-ffffff border border-color-2e5bff-08 px-8 py-6">

      <UpdateModal
        update={update}
        modal={modalUpdate} />

      <ActivityModal
        activityId={activityId}
        partitionTs={partitionTs}
        crmType={crmType}
        modal={modalActivity} />

      <div className="flex items-center justify-between" style={{ height: 45 }}>
        <div>
          <div className="text-size-24px text-color-09242f font-bold leading-tight">Timeline</div>
          <div className="text-size-14px text-color-818e93 font-weight-400 leading-tight tracking-widest">{timeRange}</div>
        </div>
        <div className="flex items-center justify-between">
          <button onClick={onPrev} className="pointer-events-auto rounded-full focus:outline-none" style={{ width: 32, height: 32 }}>
            <FontAwesomeIcon icon="chevron-left" color="#c9ced0" />
          </button>
          <button onClick={onJump} className="flex items-center justify-between border border-color-d6d9e6 rounded focus:outline-none">
            <div className="text-color-a0a8bb text-size-14px font-weight-500 mx-3 py-1">{selectedTimePeriod.value === TimePeriod.WEEKLY ? 'This Week' : 'Today'}</div>
          </button>
          <button onClick={onNext} className="pointer-events-auto rounded-full focus:outline-none" style={{ width: 32, height: 32 }}>
            <FontAwesomeIcon icon="chevron-right" color="#c9ced0" />
          </button>
          <TimePeriodSelector
            selectedOption={selectedTimePeriod}
            onChange={(o) => setSelectedTimePeriod(o)} />
        </div>
      </div>

      <div className="flex flex-row mt-4">
        <div className="flex flex-col mr-4" style={{ marginTop: 30 }}>
          <div className="text-size-16px font-weight-600 text-color-09242f text-right" style={{ marginBottom: 35 }}>Updates</div>
          <div className="text-size-16px font-weight-600 text-color-09242f text-right" style={{ marginBottom: 35 }}>Activity</div>
        </div>

        <div ref={containerRef} className="w-full flex flex-row pointer-events-none relative">
          <div className="w-full absolute">
            <button
              onClick={onPrev}
              className="absolute bg-color-fafafa pointer-events-auto rounded-full focus:outline-none"
              style={{ width: 40, height: 40, top: (columnHeight / 2) - 15, left: -(40 / 2) }}>
              <FontAwesomeIcon icon="chevron-left" color="#c9ced0" />
            </button>
          </div>
          <div className="w-full absolute">
            <button
              onClick={onNext}
              className="absolute bg-color-fafafa pointer-events-auto rounded-full focus:outline-none"
              style={{ width: 40, height: 40, top: (columnHeight / 2) - 15, left: containerWidth - (40 / 2) }}>
              <FontAwesomeIcon icon="chevron-right" color="#c9ced0" />
            </button>
          </div>
          {loading && (
            <div
              className="w-full h-full flex justify-center items-center top-0 absolute z-50"
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
              <AnimatedLoader />
            </div>
          )}
          {activity}
        </div>

      </div>

    </div>
  )
}

export default UnsyncedActivity
