// source: src/services/tenant/tenant.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_tenant_mapping_v2_object_mapping_pb = require('../../../src/common/tenant/mapping/v2/object_mapping_pb.js');
goog.object.extend(proto, src_common_tenant_mapping_v2_object_mapping_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_orchard_person_pb = require('../../../src/common/orchard/person_pb.js');
goog.object.extend(proto, src_common_orchard_person_pb);
var src_common_tenant_tenant_pb = require('../../../src/common/tenant/tenant_pb.js');
goog.object.extend(proto, src_common_tenant_tenant_pb);
var src_common_liftoff_liftoff_pb = require('../../../src/common/liftoff/liftoff_pb.js');
goog.object.extend(proto, src_common_liftoff_liftoff_pb);
var src_common_orchard_app_options_pb = require('../../../src/common/orchard/app_options_pb.js');
goog.object.extend(proto, src_common_orchard_app_options_pb);
var src_common_datasync_bulk_export_pb = require('../../../src/common/datasync/bulk_export_pb.js');
goog.object.extend(proto, src_common_datasync_bulk_export_pb);
var src_common_datasync_data_sync_pb = require('../../../src/common/datasync/data_sync_pb.js');
goog.object.extend(proto, src_common_datasync_data_sync_pb);
var src_services_tightbeam_global_tightbeam_global_pb = require('../../../src/services/tightbeam_global/tightbeam_global_pb.js');
goog.object.extend(proto, src_services_tightbeam_global_tightbeam_global_pb);
var src_services_forecast_v3_forecast_pb = require('../../../src/services/forecast/v3/forecast_pb.js');
goog.object.extend(proto, src_services_forecast_v3_forecast_pb);
goog.exportSymbol('proto.loupe.services.tenant.BustObjectMappingCacheRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.BustObjectMappingCacheResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.CreateSandboxRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.CreateSandboxResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.CreateTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.CreateTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.DeleteTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.DeleteTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetByTenantIdRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetLinkedAccountStatusRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetLinkedAccountStatusResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantAuthData', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantAuthTokenRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantAuthTokenResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantByIdRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantByIdResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantCRMTypeRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantCRMTypeResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantDataSyncStatusRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantDataSyncStatusResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantInfoRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantInfoResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantLastSyncRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantLastSyncResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantLicenseInfoRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantLicenseInfoResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantNameRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantNameResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantObjectDefinitionResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantObjectRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantObjectsLastRunResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantProvisionedUsersRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantProvisionedUsersResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.GetTenantsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.ListChildTenantsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.ListChildTenantsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.TriggerDataSyncRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.TriggerDataSyncResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpdateTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpdateTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpdateTenantStatusRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpdateTenantStatusResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpsertObjectMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpsertObjectMappingResponse', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest', null, global);
goog.exportSymbol('proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantByIdRequest.displayName = 'proto.loupe.services.tenant.GetTenantByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantByIdResponse.displayName = 'proto.loupe.services.tenant.GetTenantByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetByTenantIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.GetByTenantIdRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.GetByTenantIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetByTenantIdRequest.displayName = 'proto.loupe.services.tenant.GetByTenantIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantMappingsResponse.displayName = 'proto.loupe.services.tenant.GetTenantMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantObjectsLastRunResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.displayName = 'proto.loupe.services.tenant.GetTenantObjectsLastRunResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantLastSyncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantLastSyncRequest.displayName = 'proto.loupe.services.tenant.GetTenantLastSyncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantLastSyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantLastSyncResponse.displayName = 'proto.loupe.services.tenant.GetTenantLastSyncResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.displayName = 'proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.displayName = 'proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantObjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantObjectRequest.displayName = 'proto.loupe.services.tenant.GetTenantObjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantObjectDefinitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.displayName = 'proto.loupe.services.tenant.GetTenantObjectDefinitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.displayName = 'proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.displayName = 'proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantAuthData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantAuthData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantAuthData.displayName = 'proto.loupe.services.tenant.GetTenantAuthData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.displayName = 'proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.displayName = 'proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpsertObjectMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpsertObjectMappingRequest.displayName = 'proto.loupe.services.tenant.UpsertObjectMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpsertObjectMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpsertObjectMappingResponse.displayName = 'proto.loupe.services.tenant.UpsertObjectMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantCRMTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantCRMTypeRequest.displayName = 'proto.loupe.services.tenant.GetTenantCRMTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantCRMTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantCRMTypeResponse.displayName = 'proto.loupe.services.tenant.GetTenantCRMTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantNameRequest.displayName = 'proto.loupe.services.tenant.GetTenantNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantNameResponse.displayName = 'proto.loupe.services.tenant.GetTenantNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetLinkedAccountStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetLinkedAccountStatusRequest.displayName = 'proto.loupe.services.tenant.GetLinkedAccountStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetLinkedAccountStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetLinkedAccountStatusResponse.displayName = 'proto.loupe.services.tenant.GetLinkedAccountStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantProvisionedUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.displayName = 'proto.loupe.services.tenant.GetTenantProvisionedUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantProvisionedUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.displayName = 'proto.loupe.services.tenant.GetTenantProvisionedUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantsRequest.displayName = 'proto.loupe.services.tenant.GetTenantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.GetTenantsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantsResponse.displayName = 'proto.loupe.services.tenant.GetTenantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantInfoRequest.displayName = 'proto.loupe.services.tenant.GetTenantInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantInfoResponse.displayName = 'proto.loupe.services.tenant.GetTenantInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpdateTenantStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpdateTenantStatusRequest.displayName = 'proto.loupe.services.tenant.UpdateTenantStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpdateTenantStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpdateTenantStatusResponse.displayName = 'proto.loupe.services.tenant.UpdateTenantStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.displayName = 'proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.displayName = 'proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantDataSyncStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.displayName = 'proto.loupe.services.tenant.GetTenantDataSyncStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantDataSyncStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.displayName = 'proto.loupe.services.tenant.GetTenantDataSyncStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.TriggerDataSyncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.TriggerDataSyncRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.TriggerDataSyncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.TriggerDataSyncRequest.displayName = 'proto.loupe.services.tenant.TriggerDataSyncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.TriggerDataSyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.TriggerDataSyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.TriggerDataSyncResponse.displayName = 'proto.loupe.services.tenant.TriggerDataSyncResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.CreateTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.CreateTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.CreateTenantRequest.displayName = 'proto.loupe.services.tenant.CreateTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.CreateTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.CreateTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.CreateTenantResponse.displayName = 'proto.loupe.services.tenant.CreateTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpdateTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpdateTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpdateTenantRequest.displayName = 'proto.loupe.services.tenant.UpdateTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.UpdateTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.UpdateTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.UpdateTenantResponse.displayName = 'proto.loupe.services.tenant.UpdateTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.displayName = 'proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.displayName = 'proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.CreateSandboxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.CreateSandboxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.CreateSandboxRequest.displayName = 'proto.loupe.services.tenant.CreateSandboxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.CreateSandboxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.CreateSandboxResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.CreateSandboxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.CreateSandboxResponse.displayName = 'proto.loupe.services.tenant.CreateSandboxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.DeleteTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.DeleteTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.DeleteTenantRequest.displayName = 'proto.loupe.services.tenant.DeleteTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.DeleteTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.DeleteTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.DeleteTenantResponse.displayName = 'proto.loupe.services.tenant.DeleteTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.ListChildTenantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.ListChildTenantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.ListChildTenantsRequest.displayName = 'proto.loupe.services.tenant.ListChildTenantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.ListChildTenantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tenant.ListChildTenantsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tenant.ListChildTenantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.ListChildTenantsResponse.displayName = 'proto.loupe.services.tenant.ListChildTenantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.BustObjectMappingCacheRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.BustObjectMappingCacheRequest.displayName = 'proto.loupe.services.tenant.BustObjectMappingCacheRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.BustObjectMappingCacheResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.BustObjectMappingCacheResponse.displayName = 'proto.loupe.services.tenant.BustObjectMappingCacheResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantAuthTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantAuthTokenRequest.displayName = 'proto.loupe.services.tenant.GetTenantAuthTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantAuthTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantAuthTokenResponse.displayName = 'proto.loupe.services.tenant.GetTenantAuthTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantLicenseInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantLicenseInfoRequest.displayName = 'proto.loupe.services.tenant.GetTenantLicenseInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tenant.GetTenantLicenseInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tenant.GetTenantLicenseInfoResponse.displayName = 'proto.loupe.services.tenant.GetTenantLicenseInfoResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantByIdRequest}
 */
proto.loupe.services.tenant.GetTenantByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantByIdRequest;
  return proto.loupe.services.tenant.GetTenantByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantByIdRequest}
 */
proto.loupe.services.tenant.GetTenantByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantByIdRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantByIdRequest} returns this
 */
proto.loupe.services.tenant.GetTenantByIdRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenant: (f = msg.getTenant()) && src_common_tenant_tenant_pb.Tenant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantByIdResponse}
 */
proto.loupe.services.tenant.GetTenantByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantByIdResponse;
  return proto.loupe.services.tenant.GetTenantByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantByIdResponse}
 */
proto.loupe.services.tenant.GetTenantByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.setTenant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.Tenant tenant = 1;
 * @return {?proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.tenant.GetTenantByIdResponse.prototype.getTenant = function() {
  return /** @type{?proto.loupe.common.tenant.Tenant} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {?proto.loupe.common.tenant.Tenant|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantByIdResponse} returns this
*/
proto.loupe.services.tenant.GetTenantByIdResponse.prototype.setTenant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantByIdResponse} returns this
 */
proto.loupe.services.tenant.GetTenantByIdResponse.prototype.clearTenant = function() {
  return this.setTenant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantByIdResponse.prototype.hasTenant = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.GetByTenantIdRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetByTenantIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetByTenantIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetByTenantIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetByTenantIdRequest}
 */
proto.loupe.services.tenant.GetByTenantIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetByTenantIdRequest;
  return proto.loupe.services.tenant.GetByTenantIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetByTenantIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetByTenantIdRequest}
 */
proto.loupe.services.tenant.GetByTenantIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addObjectNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetByTenantIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetByTenantIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetByTenantIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetByTenantIdRequest} returns this
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string object_names = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.getObjectNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.tenant.GetByTenantIdRequest} returns this
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.setObjectNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tenant.GetByTenantIdRequest} returns this
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.addObjectNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.GetByTenantIdRequest} returns this
 */
proto.loupe.services.tenant.GetByTenantIdRequest.prototype.clearObjectNamesList = function() {
  return this.setObjectNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mappingsMap: (f = msg.getMappingsMap()) ? f.toObject(includeInstance, proto.loupe.common.tenant.mapping.v2.ObjectMapping.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantMappingsResponse}
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantMappingsResponse;
  return proto.loupe.services.tenant.GetTenantMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantMappingsResponse}
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getMappingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinaryFromReader, "", new proto.loupe.common.tenant.mapping.v2.ObjectMapping());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMappingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.tenant.mapping.v2.ObjectMapping.serializeBinaryToWriter);
  }
};


/**
 * map<string, loupe.common.tenant.mapping.v2.ObjectMapping> mappings = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.tenant.mapping.v2.ObjectMapping>}
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.prototype.getMappingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.common.tenant.mapping.v2.ObjectMapping));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.tenant.GetTenantMappingsResponse} returns this
 */
proto.loupe.services.tenant.GetTenantMappingsResponse.prototype.clearMappingsMap = function() {
  this.getMappingsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantObjectsLastRunResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampsMap: (f = msg.getTimestampsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Timestamp.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantObjectsLastRunResponse}
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantObjectsLastRunResponse;
  return proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantObjectsLastRunResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantObjectsLastRunResponse}
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getTimestampsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Timestamp.deserializeBinaryFromReader, "", new proto.google.protobuf.Timestamp());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantObjectsLastRunResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Timestamp.serializeBinaryToWriter);
  }
};


/**
 * map<string, google.protobuf.Timestamp> timestamps = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Timestamp>}
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.prototype.getTimestampsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Timestamp>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.google.protobuf.Timestamp));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.tenant.GetTenantObjectsLastRunResponse} returns this
 */
proto.loupe.services.tenant.GetTenantObjectsLastRunResponse.prototype.clearTimestampsMap = function() {
  this.getTimestampsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantLastSyncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncRequest}
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantLastSyncRequest;
  return proto.loupe.services.tenant.GetTenantLastSyncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncRequest}
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantLastSyncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncRequest} returns this
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncRequest} returns this
 */
proto.loupe.services.tenant.GetTenantLastSyncRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantLastSyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastSync: (f = msg.getLastSync()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncResponse}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantLastSyncResponse;
  return proto.loupe.services.tenant.GetTenantLastSyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncResponse}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSync(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantLastSyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastSync();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_sync = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.getLastSync = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncResponse} returns this
*/
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.setLastSync = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncResponse} returns this
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.clearLastSync = function() {
  return this.setLastSync(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.hasLastSync = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string crm_type = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantLastSyncResponse} returns this
 */
proto.loupe.services.tenant.GetTenantLastSyncResponse.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentTxId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest;
  return proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest} returns this
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string current_tx_id = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.prototype.getCurrentTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest} returns this
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncRequest.prototype.setCurrentTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastSync: (f = msg.getLastSync()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse;
  return proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastSync();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_sync = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.prototype.getLastSync = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse} returns this
*/
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.prototype.setLastSync = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse} returns this
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.prototype.clearLastSync = function() {
  return this.setLastSync(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantLastFullDataSyncResponse.prototype.hasLastSync = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantObjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantObjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionalSource: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantObjectRequest}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantObjectRequest;
  return proto.loupe.services.tenant.GetTenantObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantObjectRequest}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantObjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionalSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantObjectRequest} returns this
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantObjectRequest} returns this
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string optional_source = 3;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.getOptionalSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantObjectRequest} returns this
 */
proto.loupe.services.tenant.GetTenantObjectRequest.prototype.setOptionalSource = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectDefinition: (f = msg.getObjectDefinition()) && src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject(includeInstance, f),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantObjectDefinitionResponse;
  return proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.setObjectDefinition(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectDefinition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.tenant.mapping.v2.ObjectMapping object_definition = 1;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.getObjectDefinition = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 1));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectMapping|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse} returns this
*/
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.setObjectDefinition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse} returns this
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.clearObjectDefinition = function() {
  return this.setObjectDefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.hasObjectDefinition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_test_instance = 2;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse} returns this
 */
proto.loupe.services.tenant.GetTenantObjectDefinitionResponse.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rawSchema: msg.getRawSchema_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest;
  return proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRawSchema_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest} returns this
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest} returns this
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes raw_schema = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.getRawSchema = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes raw_schema = 3;
 * This is a type-conversion wrapper around `getRawSchema()`
 * @return {string}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.getRawSchema_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawSchema()));
};


/**
 * optional bytes raw_schema = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawSchema()`
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.getRawSchema_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawSchema()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest} returns this
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaRequest.prototype.setRawSchema = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse;
  return proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectSettingRawSchemaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantAuthData.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantAuthData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantAuthData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantAuthData.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantAuthData}
 */
proto.loupe.services.tenant.GetTenantAuthData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantAuthData;
  return proto.loupe.services.tenant.GetTenantAuthData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantAuthData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantAuthData}
 */
proto.loupe.services.tenant.GetTenantAuthData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantAuthData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantAuthData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantAuthData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantAuthData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthData.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthData} returns this
 */
proto.loupe.services.tenant.GetTenantAuthData.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest;
  return proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest} returns this
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string source = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest} returns this
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    refreshToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse;
  return proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string refresh_token = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse} returns this
 */
proto.loupe.services.tenant.GetTenantIntegrationAuthDataResponse.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpsertObjectMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpsertObjectMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objectMap: (f = msg.getObjectMap()) && src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingRequest}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpsertObjectMappingRequest;
  return proto.loupe.services.tenant.UpsertObjectMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpsertObjectMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingRequest}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.setObjectMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpsertObjectMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpsertObjectMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjectMap();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingRequest} returns this
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingRequest} returns this
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.tenant.mapping.v2.ObjectMapping object_map = 3;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.getObjectMap = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 3));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectMapping|undefined} value
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingRequest} returns this
*/
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.setObjectMap = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingRequest} returns this
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.clearObjectMap = function() {
  return this.setObjectMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.UpsertObjectMappingRequest.prototype.hasObjectMap = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpsertObjectMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpsertObjectMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingResponse}
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpsertObjectMappingResponse;
  return proto.loupe.services.tenant.UpsertObjectMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpsertObjectMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpsertObjectMappingResponse}
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpsertObjectMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpsertObjectMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpsertObjectMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantCRMTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantCRMTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantCRMTypeRequest}
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantCRMTypeRequest;
  return proto.loupe.services.tenant.GetTenantCRMTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantCRMTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantCRMTypeRequest}
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantCRMTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantCRMTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantCRMTypeRequest} returns this
 */
proto.loupe.services.tenant.GetTenantCRMTypeRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantCRMTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantCRMTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantCRMTypeResponse}
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantCRMTypeResponse;
  return proto.loupe.services.tenant.GetTenantCRMTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantCRMTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantCRMTypeResponse}
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantCRMTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantCRMTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantCRMTypeResponse} returns this
 */
proto.loupe.services.tenant.GetTenantCRMTypeResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantNameRequest}
 */
proto.loupe.services.tenant.GetTenantNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantNameRequest;
  return proto.loupe.services.tenant.GetTenantNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantNameRequest}
 */
proto.loupe.services.tenant.GetTenantNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantNameRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantNameRequest} returns this
 */
proto.loupe.services.tenant.GetTenantNameRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantNameResponse}
 */
proto.loupe.services.tenant.GetTenantNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantNameResponse;
  return proto.loupe.services.tenant.GetTenantNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantNameResponse}
 */
proto.loupe.services.tenant.GetTenantNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantNameResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantNameResponse} returns this
 */
proto.loupe.services.tenant.GetTenantNameResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetLinkedAccountStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetLinkedAccountStatusRequest;
  return proto.loupe.services.tenant.GetLinkedAccountStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetLinkedAccountStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest} returns this
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusRequest} returns this
 */
proto.loupe.services.tenant.GetLinkedAccountStatusRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetLinkedAccountStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetLinkedAccountStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusResponse}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetLinkedAccountStatusResponse;
  return proto.loupe.services.tenant.GetLinkedAccountStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusResponse}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetLinkedAccountStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_active = 1;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetLinkedAccountStatusResponse} returns this
 */
proto.loupe.services.tenant.GetLinkedAccountStatusResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantProvisionedUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersRequest}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantProvisionedUsersRequest;
  return proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantProvisionedUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersRequest}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantProvisionedUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersRequest} returns this
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    provisionedUsersList: jspb.Message.toObjectList(msg.getProvisionedUsersList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantProvisionedUsersResponse;
  return proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addProvisionedUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvisionedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.orchard.Person provisioned_users = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.prototype.getProvisionedUsersList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse} returns this
*/
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.prototype.setProvisionedUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.prototype.addProvisionedUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.GetTenantProvisionedUsersResponse} returns this
 */
proto.loupe.services.tenant.GetTenantProvisionedUsersResponse.prototype.clearProvisionedUsersList = function() {
  return this.setProvisionedUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusFilter: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nameFilter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    includeChildren: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantsRequest}
 */
proto.loupe.services.tenant.GetTenantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantsRequest;
  return proto.loupe.services.tenant.GetTenantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantsRequest}
 */
proto.loupe.services.tenant.GetTenantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameFilter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNameFilter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIncludeChildren();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string status_filter = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.getStatusFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantsRequest} returns this
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.setStatusFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name_filter = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.getNameFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantsRequest} returns this
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.setNameFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantsRequest} returns this
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantsRequest} returns this
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool include_children = 5;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.getIncludeChildren = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantsRequest} returns this
 */
proto.loupe.services.tenant.GetTenantsRequest.prototype.setIncludeChildren = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.GetTenantsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantsList: jspb.Message.toObjectList(msg.getTenantsList(),
    src_common_tenant_tenant_pb.Tenant.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantsResponse}
 */
proto.loupe.services.tenant.GetTenantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantsResponse;
  return proto.loupe.services.tenant.GetTenantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantsResponse}
 */
proto.loupe.services.tenant.GetTenantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.addTenants(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.tenant.Tenant tenants = 1;
 * @return {!Array<!proto.loupe.common.tenant.Tenant>}
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.getTenantsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.Tenant>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.Tenant>} value
 * @return {!proto.loupe.services.tenant.GetTenantsResponse} returns this
*/
proto.loupe.services.tenant.GetTenantsResponse.prototype.setTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.tenant.Tenant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.addTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.tenant.Tenant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.GetTenantsResponse} returns this
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.clearTenantsList = function() {
  return this.setTenantsList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantsResponse} returns this
 */
proto.loupe.services.tenant.GetTenantsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantInfoRequest}
 */
proto.loupe.services.tenant.GetTenantInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantInfoRequest;
  return proto.loupe.services.tenant.GetTenantInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantInfoRequest}
 */
proto.loupe.services.tenant.GetTenantInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoRequest} returns this
 */
proto.loupe.services.tenant.GetTenantInfoRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastSuccessfulSync: (f = msg.getLastSuccessfulSync()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isCurrentlySyncing: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    currentFiscalStart: (f = msg.getCurrentFiscalStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentFiscalEnd: (f = msg.getCurrentFiscalEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentPeriodStart: (f = msg.getCurrentPeriodStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentPeriodEnd: (f = msg.getCurrentPeriodEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fiscalStartMonth: jspb.Message.getFieldWithDefault(msg, 10, 0),
    monthPeriodRange: jspb.Message.getFieldWithDefault(msg, 11, 0),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    parentTenantId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    appHierarchyOptions: (f = msg.getAppHierarchyOptions()) && src_common_orchard_app_options_pb.AppOptions.toObject(includeInstance, f),
    canWriteBack: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 16, ""),
    description: jspb.Message.getFieldWithDefault(msg, 17, ""),
    initialType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    setupStatus: jspb.Message.getFieldWithDefault(msg, 19, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 20, ""),
    outreachOrg: (f = msg.getOutreachOrg()) && src_services_tightbeam_global_tightbeam_global_pb.Org.toObject(includeInstance, f),
    licenseType: jspb.Message.getFieldWithDefault(msg, 22, 0),
    licenseTier: jspb.Message.getFieldWithDefault(msg, 23, 0),
    region: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantInfoResponse;
  return proto.loupe.services.tenant.GetTenantInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSuccessfulSync(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrentlySyncing(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentFiscalStart(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentFiscalEnd(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentPeriodStart(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentPeriodEnd(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFiscalStartMonth(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthPeriodRange(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentTenantId(value);
      break;
    case 14:
      var value = new src_common_orchard_app_options_pb.AppOptions;
      reader.readMessage(value,src_common_orchard_app_options_pb.AppOptions.deserializeBinaryFromReader);
      msg.setAppHierarchyOptions(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanWriteBack(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 18:
      var value = /** @type {!proto.loupe.common.tenant.SandboxType} */ (reader.readEnum());
      msg.setInitialType(value);
      break;
    case 19:
      var value = /** @type {!proto.loupe.common.liftoff.InstanceSetupStatus} */ (reader.readEnum());
      msg.setSetupStatus(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 21:
      var value = new src_services_tightbeam_global_tightbeam_global_pb.Org;
      reader.readMessage(value,src_services_tightbeam_global_tightbeam_global_pb.Org.deserializeBinaryFromReader);
      msg.setOutreachOrg(value);
      break;
    case 22:
      var value = /** @type {!proto.loupe.common.tenant.LicenseType} */ (reader.readEnum());
      msg.setLicenseType(value);
      break;
    case 23:
      var value = /** @type {!proto.loupe.common.tenant.LicenseTier} */ (reader.readEnum());
      msg.setLicenseTier(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastSuccessfulSync();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsCurrentlySyncing();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCurrentFiscalStart();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentFiscalEnd();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPeriodStart();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPeriodEnd();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFiscalStartMonth();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMonthPeriodRange();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getParentTenantId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAppHierarchyOptions();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      src_common_orchard_app_options_pb.AppOptions.serializeBinaryToWriter
    );
  }
  f = message.getCanWriteBack();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getInitialType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getSetupStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getOutreachOrg();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      src_services_tightbeam_global_tightbeam_global_pb.Org.serializeBinaryToWriter
    );
  }
  f = message.getLicenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getLicenseTier();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string crm_type = 3;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_successful_sync = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getLastSuccessfulSync = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setLastSuccessfulSync = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearLastSuccessfulSync = function() {
  return this.setLastSuccessfulSync(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasLastSuccessfulSync = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_currently_syncing = 5;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getIsCurrentlySyncing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setIsCurrentlySyncing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp current_fiscal_start = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCurrentFiscalStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCurrentFiscalStart = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearCurrentFiscalStart = function() {
  return this.setCurrentFiscalStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasCurrentFiscalStart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp current_fiscal_end = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCurrentFiscalEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCurrentFiscalEnd = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearCurrentFiscalEnd = function() {
  return this.setCurrentFiscalEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasCurrentFiscalEnd = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp current_period_start = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCurrentPeriodStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCurrentPeriodStart = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearCurrentPeriodStart = function() {
  return this.setCurrentPeriodStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasCurrentPeriodStart = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp current_period_end = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCurrentPeriodEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCurrentPeriodEnd = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearCurrentPeriodEnd = function() {
  return this.setCurrentPeriodEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasCurrentPeriodEnd = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 fiscal_start_month = 10;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getFiscalStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setFiscalStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 month_period_range = 11;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getMonthPeriodRange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setMonthPeriodRange = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool is_test_instance = 12;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string parent_tenant_id = 13;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getParentTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setParentTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional loupe.common.orchard.AppOptions app_hierarchy_options = 14;
 * @return {?proto.loupe.common.orchard.AppOptions}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getAppHierarchyOptions = function() {
  return /** @type{?proto.loupe.common.orchard.AppOptions} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_app_options_pb.AppOptions, 14));
};


/**
 * @param {?proto.loupe.common.orchard.AppOptions|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setAppHierarchyOptions = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearAppHierarchyOptions = function() {
  return this.setAppHierarchyOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasAppHierarchyOptions = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool can_write_back = 15;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCanWriteBack = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCanWriteBack = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string currency_code = 16;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string description = 17;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional loupe.common.tenant.SandboxType initial_type = 18;
 * @return {!proto.loupe.common.tenant.SandboxType}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getInitialType = function() {
  return /** @type {!proto.loupe.common.tenant.SandboxType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.loupe.common.tenant.SandboxType} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setInitialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional loupe.common.liftoff.InstanceSetupStatus setup_status = 19;
 * @return {!proto.loupe.common.liftoff.InstanceSetupStatus}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getSetupStatus = function() {
  return /** @type {!proto.loupe.common.liftoff.InstanceSetupStatus} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.loupe.common.liftoff.InstanceSetupStatus} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setSetupStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string timezone = 20;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional loupe.services.tightbeam_global.Org outreach_org = 21;
 * @return {?proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getOutreachOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.Org} */ (
    jspb.Message.getWrapperField(this, src_services_tightbeam_global_tightbeam_global_pb.Org, 21));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.Org|undefined} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
*/
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setOutreachOrg = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.clearOutreachOrg = function() {
  return this.setOutreachOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.hasOutreachOrg = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional loupe.common.tenant.LicenseType license_type = 22;
 * @return {!proto.loupe.common.tenant.LicenseType}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getLicenseType = function() {
  return /** @type {!proto.loupe.common.tenant.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.loupe.common.tenant.LicenseType} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setLicenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional loupe.common.tenant.LicenseTier license_tier = 23;
 * @return {!proto.loupe.common.tenant.LicenseTier}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getLicenseTier = function() {
  return /** @type {!proto.loupe.common.tenant.LicenseTier} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.loupe.common.tenant.LicenseTier} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setLicenseTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional string region = 24;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantInfoResponse.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpdateTenantStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpdateTenantStatusRequest}
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpdateTenantStatusRequest;
  return proto.loupe.services.tenant.UpdateTenantStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpdateTenantStatusRequest}
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.tenant.TenantStatus} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpdateTenantStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpdateTenantStatusRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.tenant.TenantStatus new_status = 2;
 * @return {!proto.loupe.common.tenant.TenantStatus}
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.prototype.getNewStatus = function() {
  return /** @type {!proto.loupe.common.tenant.TenantStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.tenant.TenantStatus} value
 * @return {!proto.loupe.services.tenant.UpdateTenantStatusRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantStatusRequest.prototype.setNewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpdateTenantStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpdateTenantStatusResponse}
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpdateTenantStatusResponse;
  return proto.loupe.services.tenant.UpdateTenantStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpdateTenantStatusResponse}
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpdateTenantStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest;
  return proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    src_common_datasync_bulk_export_pb.BulkExport.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse;
  return proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_datasync_bulk_export_pb.BulkExport;
      reader.readMessage(value,src_common_datasync_bulk_export_pb.BulkExport.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_datasync_bulk_export_pb.BulkExport.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.datasync.BulkExport history = 1;
 * @return {!Array<!proto.loupe.common.datasync.BulkExport>}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.loupe.common.datasync.BulkExport>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_datasync_bulk_export_pb.BulkExport, 1));
};


/**
 * @param {!Array<!proto.loupe.common.datasync.BulkExport>} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse} returns this
*/
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.datasync.BulkExport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.datasync.BulkExport}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.datasync.BulkExport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantDataSyncStatusRequest;
  return proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantDataSyncStatusResponse;
  return proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.datasync.DataSyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional loupe.common.datasync.DataSyncStatus status = 1;
 * @return {!proto.loupe.common.datasync.DataSyncStatus}
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.datasync.DataSyncStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.datasync.DataSyncStatus} value
 * @return {!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse} returns this
 */
proto.loupe.services.tenant.GetTenantDataSyncStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.TriggerDataSyncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.TriggerDataSyncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.TriggerDataSyncRequest}
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.TriggerDataSyncRequest;
  return proto.loupe.services.tenant.TriggerDataSyncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.TriggerDataSyncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.TriggerDataSyncRequest}
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.loupe.common.datasync.ExportPart>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addParts(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.TriggerDataSyncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.TriggerDataSyncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.TriggerDataSyncRequest} returns this
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated loupe.common.datasync.ExportPart parts = 2;
 * @return {!Array<!proto.loupe.common.datasync.ExportPart>}
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.getPartsList = function() {
  return /** @type {!Array<!proto.loupe.common.datasync.ExportPart>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.loupe.common.datasync.ExportPart>} value
 * @return {!proto.loupe.services.tenant.TriggerDataSyncRequest} returns this
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.setPartsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.loupe.common.datasync.ExportPart} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tenant.TriggerDataSyncRequest} returns this
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.addParts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.TriggerDataSyncRequest} returns this
 */
proto.loupe.services.tenant.TriggerDataSyncRequest.prototype.clearPartsList = function() {
  return this.setPartsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.TriggerDataSyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.TriggerDataSyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.TriggerDataSyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.TriggerDataSyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.TriggerDataSyncResponse}
 */
proto.loupe.services.tenant.TriggerDataSyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.TriggerDataSyncResponse;
  return proto.loupe.services.tenant.TriggerDataSyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.TriggerDataSyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.TriggerDataSyncResponse}
 */
proto.loupe.services.tenant.TriggerDataSyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.TriggerDataSyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.TriggerDataSyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.TriggerDataSyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.TriggerDataSyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.CreateTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.CreateTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    outreachOrg: (f = msg.getOutreachOrg()) && src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair.toObject(includeInstance, f),
    region: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.CreateTenantRequest}
 */
proto.loupe.services.tenant.CreateTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.CreateTenantRequest;
  return proto.loupe.services.tenant.CreateTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.CreateTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.CreateTenantRequest}
 */
proto.loupe.services.tenant.CreateTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.tenant.TenantStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    case 6:
      var value = new src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair;
      reader.readMessage(value,src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair.deserializeBinaryFromReader);
      msg.setOutreachOrg(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.CreateTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.CreateTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOutreachOrg();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.tenant.TenantStatus status = 2;
 * @return {!proto.loupe.common.tenant.TenantStatus}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.tenant.TenantStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.tenant.TenantStatus} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string crm_type = 4;
 * @return {string}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_test_instance = 5;
 * @return {boolean}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional loupe.services.tightbeam_global.OrgBentoIdPair outreach_org = 6;
 * @return {?proto.loupe.services.tightbeam_global.OrgBentoIdPair}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getOutreachOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.OrgBentoIdPair} */ (
    jspb.Message.getWrapperField(this, src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair, 6));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.OrgBentoIdPair|undefined} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
*/
proto.loupe.services.tenant.CreateTenantRequest.prototype.setOutreachOrg = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.clearOutreachOrg = function() {
  return this.setOutreachOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.hasOutreachOrg = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string region = 7;
 * @return {string}
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateTenantRequest} returns this
 */
proto.loupe.services.tenant.CreateTenantRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.CreateTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.CreateTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.CreateTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenant: (f = msg.getTenant()) && src_common_tenant_tenant_pb.Tenant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.CreateTenantResponse}
 */
proto.loupe.services.tenant.CreateTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.CreateTenantResponse;
  return proto.loupe.services.tenant.CreateTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.CreateTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.CreateTenantResponse}
 */
proto.loupe.services.tenant.CreateTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.setTenant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.CreateTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.CreateTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.CreateTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.Tenant tenant = 1;
 * @return {?proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.tenant.CreateTenantResponse.prototype.getTenant = function() {
  return /** @type{?proto.loupe.common.tenant.Tenant} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {?proto.loupe.common.tenant.Tenant|undefined} value
 * @return {!proto.loupe.services.tenant.CreateTenantResponse} returns this
*/
proto.loupe.services.tenant.CreateTenantResponse.prototype.setTenant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.CreateTenantResponse} returns this
 */
proto.loupe.services.tenant.CreateTenantResponse.prototype.clearTenant = function() {
  return this.setTenant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.CreateTenantResponse.prototype.hasTenant = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpdateTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpdateTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outreachOrg: (f = msg.getOutreachOrg()) && src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest}
 */
proto.loupe.services.tenant.UpdateTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpdateTenantRequest;
  return proto.loupe.services.tenant.UpdateTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpdateTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest}
 */
proto.loupe.services.tenant.UpdateTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.tenant.TenantStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair;
      reader.readMessage(value,src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair.deserializeBinaryFromReader);
      msg.setOutreachOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpdateTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpdateTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutreachOrg();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.tenant.TenantStatus status = 3;
 * @return {!proto.loupe.common.tenant.TenantStatus}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.tenant.TenantStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.tenant.TenantStatus} value
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional loupe.services.tightbeam_global.OrgBentoIdPair outreach_org = 5;
 * @return {?proto.loupe.services.tightbeam_global.OrgBentoIdPair}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.getOutreachOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.OrgBentoIdPair} */ (
    jspb.Message.getWrapperField(this, src_services_tightbeam_global_tightbeam_global_pb.OrgBentoIdPair, 5));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.OrgBentoIdPair|undefined} value
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest} returns this
*/
proto.loupe.services.tenant.UpdateTenantRequest.prototype.setOutreachOrg = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.UpdateTenantRequest} returns this
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.clearOutreachOrg = function() {
  return this.setOutreachOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.UpdateTenantRequest.prototype.hasOutreachOrg = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.UpdateTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.UpdateTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.UpdateTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenant: (f = msg.getTenant()) && src_common_tenant_tenant_pb.Tenant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.UpdateTenantResponse}
 */
proto.loupe.services.tenant.UpdateTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.UpdateTenantResponse;
  return proto.loupe.services.tenant.UpdateTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.UpdateTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.UpdateTenantResponse}
 */
proto.loupe.services.tenant.UpdateTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.setTenant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.UpdateTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.UpdateTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.UpdateTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.UpdateTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.Tenant tenant = 1;
 * @return {?proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.tenant.UpdateTenantResponse.prototype.getTenant = function() {
  return /** @type{?proto.loupe.common.tenant.Tenant} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {?proto.loupe.common.tenant.Tenant|undefined} value
 * @return {!proto.loupe.services.tenant.UpdateTenantResponse} returns this
*/
proto.loupe.services.tenant.UpdateTenantResponse.prototype.setTenant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.UpdateTenantResponse} returns this
 */
proto.loupe.services.tenant.UpdateTenantResponse.prototype.clearTenant = function() {
  return this.setTenant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.UpdateTenantResponse.prototype.hasTenant = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest;
  return proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} returns this
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} returns this
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse;
  return proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_url = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse} returns this
 */
proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.CreateSandboxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.CreateSandboxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateSandboxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest}
 */
proto.loupe.services.tenant.CreateSandboxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.CreateSandboxRequest;
  return proto.loupe.services.tenant.CreateSandboxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.CreateSandboxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest}
 */
proto.loupe.services.tenant.CreateSandboxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.tenant.SandboxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.CreateSandboxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.CreateSandboxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateSandboxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest} returns this
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.tenant.SandboxType type = 2;
 * @return {!proto.loupe.common.tenant.SandboxType}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.getType = function() {
  return /** @type {!proto.loupe.common.tenant.SandboxType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.tenant.SandboxType} value
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest} returns this
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest} returns this
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest} returns this
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest} returns this
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_test_instance = 6;
 * @return {boolean}
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.CreateSandboxRequest} returns this
 */
proto.loupe.services.tenant.CreateSandboxRequest.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.CreateSandboxResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.CreateSandboxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.CreateSandboxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateSandboxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sandbox: (f = msg.getSandbox()) && src_common_tenant_tenant_pb.Tenant.toObject(includeInstance, f),
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    src_services_forecast_v3_forecast_pb.ForecastConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.CreateSandboxResponse}
 */
proto.loupe.services.tenant.CreateSandboxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.CreateSandboxResponse;
  return proto.loupe.services.tenant.CreateSandboxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.CreateSandboxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.CreateSandboxResponse}
 */
proto.loupe.services.tenant.CreateSandboxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.setSandbox(value);
      break;
    case 2:
      var value = new src_services_forecast_v3_forecast_pb.ForecastConfig;
      reader.readMessage(value,src_services_forecast_v3_forecast_pb.ForecastConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.CreateSandboxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.CreateSandboxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.CreateSandboxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSandbox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_services_forecast_v3_forecast_pb.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.Tenant sandbox = 1;
 * @return {?proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.getSandbox = function() {
  return /** @type{?proto.loupe.common.tenant.Tenant} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {?proto.loupe.common.tenant.Tenant|undefined} value
 * @return {!proto.loupe.services.tenant.CreateSandboxResponse} returns this
*/
proto.loupe.services.tenant.CreateSandboxResponse.prototype.setSandbox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tenant.CreateSandboxResponse} returns this
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.clearSandbox = function() {
  return this.setSandbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.hasSandbox = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.services.forecast.v3.ForecastConfig configs = 2;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfig>}
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_services_forecast_v3_forecast_pb.ForecastConfig, 2));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfig>} value
 * @return {!proto.loupe.services.tenant.CreateSandboxResponse} returns this
*/
proto.loupe.services.tenant.CreateSandboxResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.forecast.v3.ForecastConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.CreateSandboxResponse} returns this
 */
proto.loupe.services.tenant.CreateSandboxResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.DeleteTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.DeleteTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.DeleteTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.DeleteTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.DeleteTenantRequest}
 */
proto.loupe.services.tenant.DeleteTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.DeleteTenantRequest;
  return proto.loupe.services.tenant.DeleteTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.DeleteTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.DeleteTenantRequest}
 */
proto.loupe.services.tenant.DeleteTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.DeleteTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.DeleteTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.DeleteTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.DeleteTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.DeleteTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.DeleteTenantRequest} returns this
 */
proto.loupe.services.tenant.DeleteTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.DeleteTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.DeleteTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.DeleteTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.DeleteTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.DeleteTenantResponse}
 */
proto.loupe.services.tenant.DeleteTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.DeleteTenantResponse;
  return proto.loupe.services.tenant.DeleteTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.DeleteTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.DeleteTenantResponse}
 */
proto.loupe.services.tenant.DeleteTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.DeleteTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.DeleteTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.DeleteTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.DeleteTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.ListChildTenantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.ListChildTenantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.ListChildTenantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.ListChildTenantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.ListChildTenantsRequest}
 */
proto.loupe.services.tenant.ListChildTenantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.ListChildTenantsRequest;
  return proto.loupe.services.tenant.ListChildTenantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.ListChildTenantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.ListChildTenantsRequest}
 */
proto.loupe.services.tenant.ListChildTenantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.ListChildTenantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.ListChildTenantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.ListChildTenantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.ListChildTenantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.ListChildTenantsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.ListChildTenantsRequest} returns this
 */
proto.loupe.services.tenant.ListChildTenantsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tenant.ListChildTenantsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.ListChildTenantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.ListChildTenantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.ListChildTenantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.ListChildTenantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantsList: jspb.Message.toObjectList(msg.getTenantsList(),
    src_common_tenant_tenant_pb.Tenant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.ListChildTenantsResponse}
 */
proto.loupe.services.tenant.ListChildTenantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.ListChildTenantsResponse;
  return proto.loupe.services.tenant.ListChildTenantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.ListChildTenantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.ListChildTenantsResponse}
 */
proto.loupe.services.tenant.ListChildTenantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.addTenants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.ListChildTenantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.ListChildTenantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.ListChildTenantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.ListChildTenantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.tenant.Tenant tenants = 1;
 * @return {!Array<!proto.loupe.common.tenant.Tenant>}
 */
proto.loupe.services.tenant.ListChildTenantsResponse.prototype.getTenantsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.Tenant>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.Tenant>} value
 * @return {!proto.loupe.services.tenant.ListChildTenantsResponse} returns this
*/
proto.loupe.services.tenant.ListChildTenantsResponse.prototype.setTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.tenant.Tenant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.tenant.ListChildTenantsResponse.prototype.addTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.tenant.Tenant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tenant.ListChildTenantsResponse} returns this
 */
proto.loupe.services.tenant.ListChildTenantsResponse.prototype.clearTenantsList = function() {
  return this.setTenantsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.BustObjectMappingCacheRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.BustObjectMappingCacheRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.BustObjectMappingCacheRequest}
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.BustObjectMappingCacheRequest;
  return proto.loupe.services.tenant.BustObjectMappingCacheRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.BustObjectMappingCacheRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.BustObjectMappingCacheRequest}
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.BustObjectMappingCacheRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.BustObjectMappingCacheRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.BustObjectMappingCacheRequest} returns this
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.BustObjectMappingCacheRequest} returns this
 */
proto.loupe.services.tenant.BustObjectMappingCacheRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.BustObjectMappingCacheResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.BustObjectMappingCacheResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.BustObjectMappingCacheResponse}
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.BustObjectMappingCacheResponse;
  return proto.loupe.services.tenant.BustObjectMappingCacheResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.BustObjectMappingCacheResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.BustObjectMappingCacheResponse}
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.BustObjectMappingCacheResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.BustObjectMappingCacheResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.BustObjectMappingCacheResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantAuthTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgGuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    localRefreshToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currentAccessToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    forceRefresh: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantAuthTokenRequest;
  return proto.loupe.services.tenant.GetTenantAuthTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalRefreshToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentAccessToken(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceRefresh(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantAuthTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocalRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrentAccessToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getForceRefresh();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org_guid = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string local_refresh_token = 3;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.getLocalRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.setLocalRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string current_access_token = 4;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.getCurrentAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.setCurrentAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool force_refresh = 5;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.getForceRefresh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.setForceRefresh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_test_instance = 6;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenRequest} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenRequest.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantAuthTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instanceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orgGuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    instanceUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantAuthTokenResponse;
  return proto.loupe.services.tenant.GetTenantAuthTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantAuthTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getInstanceUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instance_id = 2;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string org_guid = 3;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_test_instance = 4;
 * @return {boolean}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string instance_url = 5;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.getInstanceUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantAuthTokenResponse} returns this
 */
proto.loupe.services.tenant.GetTenantAuthTokenResponse.prototype.setInstanceUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantLicenseInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantLicenseInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoRequest}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantLicenseInfoRequest;
  return proto.loupe.services.tenant.GetTenantLicenseInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantLicenseInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoRequest}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantLicenseInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantLicenseInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoRequest} returns this
 */
proto.loupe.services.tenant.GetTenantLicenseInfoRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tenant.GetTenantLicenseInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    licenseTier: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tenant.GetTenantLicenseInfoResponse;
  return proto.loupe.services.tenant.GetTenantLicenseInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.tenant.LicenseType} */ (reader.readEnum());
      msg.setLicenseType(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.tenant.LicenseTier} */ (reader.readEnum());
      msg.setLicenseTier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tenant.GetTenantLicenseInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLicenseTier();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.tenant.LicenseType license_type = 1;
 * @return {!proto.loupe.common.tenant.LicenseType}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.prototype.getLicenseType = function() {
  return /** @type {!proto.loupe.common.tenant.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.tenant.LicenseType} value
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.prototype.setLicenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.tenant.LicenseTier license_tier = 2;
 * @return {!proto.loupe.common.tenant.LicenseTier}
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.prototype.getLicenseTier = function() {
  return /** @type {!proto.loupe.common.tenant.LicenseTier} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.tenant.LicenseTier} value
 * @return {!proto.loupe.services.tenant.GetTenantLicenseInfoResponse} returns this
 */
proto.loupe.services.tenant.GetTenantLicenseInfoResponse.prototype.setLicenseTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.loupe.services.tenant);
